import ApiService from "../../../../shared/services/apiService";
import { ReplaySubject } from 'rxjs';
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class ProfileConsentService {

 static getAgreementConsentAsOBS = (_tenantId, _userId, _modalAgNode) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/GetAgreementConsent/${_tenantId}/${_modalAgNode.prid}/${_userId}?pageNum=1&pageSize=100`,
    );
  };
  static getTransactionConsentAsOBS = (_tenantId, _userId, _modalAgNode, _currencyId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/GetTransactionConsent/${_tenantId}/${_modalAgNode.prid}/${_userId}?userCurrency=${_currencyId}&pageNumber=1&pageSize=100`,
    );
  };

  static getAgreementColumnDefs = (_componentThisRef, _isReadOnly, _agGridUtils) => {
    return [
      AgGridColumnExt.GET(false, "text", "companyAgreementID", "Company Agreement Id").fixedWidth(144).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "porzioGSTAgreementID", "Porzio GST Agreement ID").fixedWidth(144).headerClass("center-text").centerAlignText().cellRenderer("agreementDetailCellRendererComponent").SET(),

      AgGridColumnExt.GET(false, "text", "consentExecutedDate", "Executed Date").flexWeight(1).SET(),
      AgGridColumnExt.GET(false, "text", "consentStartDate", "Start Date").flexWeight(1).SET(),
      AgGridColumnExt.GET(false, "text", "consentEndDate", "End Date").flexWeight(1).SET(),

      AgGridColumnExt.GET(false, "text", "consentRevocationExecutedDate", "Revocation Executed Date").flexWeight(1).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "consentRevocationStartDate", "Revocation Start Date").flexWeight(1).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "consentRevocationEndDate", "Revocation End Date").flexWeight(1).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "agreemeentConsentLastModifiedDate", "Last Modified Date").flexWeight(1).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "agreementConsent", "Consent").flexWeight(1).SET(),
    ];
  };

  static getTransactionColumnDefs = (_componentThisRef, _isReadOnly, _agGridUtils) => {
    return [
      AgGridColumnExt.GET(false, "text", "companyTransactionID", "Company Transaction Id").fixedWidth(144).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "porzioGSTTransactionID", "Porzio GST Transaction ID").fixedWidth(144).headerClass("center-text").centerAlignText().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "text", "form", "Form").flexWeight(1).SET(),
      AgGridColumnExt.GET(false, "text", "purpose", "Purpose").flexWeight(1).SET(),
      AgGridColumnExt.GET(false, "text", "transactionDate", "Transaction Date").flexWeight(1).SET(),
      AgGridColumnExt.GET(false, "text", "convertedTotalAmount", "Converted Total Amount").flexWeight(1).SET(),
      AgGridColumnExt.GET(false, "text", "transactionConsent", "Consent").flexWeight(1).SET(),
      AgGridColumnExt.GET(false, "text", "consentUpdatedDate", "Consent Last Modified Date").flexWeight(1).SET(),
      //AgGridColumnExt.GET(false, "text", "form", "Consent").flexWeight(1).SET(),
    ];
  }
}