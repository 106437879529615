import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../shared/store/authProvider";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SectionComponent from "../../../shared/components/sectionComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: 250,
    height: 500,
    overflow: "auto",
    border: "0.5px solid black",
    margin: "30px",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function FieldConfigurationComponent(props) {
  const authConsumer = useContext(AuthContext);
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [isSaveClicked, setIsSaveClicked] = React.useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (value.isRequired === false) {
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    };
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetch(`${process.env.REACT_APP_CORE_HTTP}/SourceSystems/GetAvailableFieldsByEntity/${authConsumer.user.tenantId}/${props.modalAgNode.sourceTypeId}/${props.modalAgNode.systemId}`);
        const json = await result.json();
        setLeft(json.map(el => ({
          id: el.fieldid,
          text: el.fielD_NAME,
          isRequired: el.iS_PLSREQUIRED,
        })));
      } catch {
        console.log("fetch /SourceSystems/GetAvailableFieldsByEntity/ failed");
      }
    };
    fetchData();
  }, [authConsumer.user.tenantId, props.modalAgNode.sourceTypeId, props.modalAgNode.systemId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetch(`${process.env.REACT_APP_CORE_HTTP}/SourceSystems/GetSourceFieldsByEntity/${authConsumer.user.tenantId}/${props.modalAgNode.sourceTypeId}/${props.modalAgNode.systemId}`);
        const json = await result.json();
        setRight(json.map(el => ({
          id: el.fieldid,
          text: el.fielD_NAME,
          isRequired: el.iS_PLSREQUIRED,
        })));
      } catch {
        console.log("fetch /SourceSystems/GetSourceFieldsByEntity/ failed");
      }
    };
    fetchData();
  }, [authConsumer.user.tenantId, props.modalAgNode.sourceTypeId, props.modalAgNode.systemId]);


  useEffect(() => {
    const fetchData = async () => {

      let strFieldValues = JSON.stringify(right.map(el => el.id));
      strFieldValues = strFieldValues.substring(1, strFieldValues.length - 1);
      console.log(strFieldValues);

      const result = await fetch(
        `${process.env.REACT_APP_CORE_HTTP}/SourceSystems/SaveSourceFields/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sourceId: props.modalAgNode.systemId,
            //fieldValues: "5,15,1,17",
            fieldValues: strFieldValues,
            tenantId: authConsumer.user.tenantId,
            createdBy: authConsumer.user.userId
          }),
        });
      //const json = await result.json();
    };
    if (isSaveClicked) {
      fetchData();
      props.onClose(false);
    }
  }, [isSaveClicked, right, props, authConsumer.user.tenantId, props.modalAgNode.sourceTypeId, props.modalAgNode.systemId]);

  const handleFieldConfigSaveSubmit = () => {
    setIsSaveClicked(true);
  }

  const customList = (items) => (
    // <Paper className={classes.paper}>
    <Paper style={{ border: "0.5px solid black", overflow: "auto", height: "65vh", }}>
      <List dense component="div" role="list">
        {items.map((el) => {
          const labelId = `transfer-list-item-${el.id}-label`;

          return (
            <ListItem key={el.id} role="listitem" button onClick={handleToggle(el)}>
              <ListItemIcon>
                {
                  el.isRequired ?
                    <Checkbox checked={true} tabIndex={-1} disabled={el.isRequired} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                    :
                    <Checkbox checked={checked.map(chel => { return chel.id }).indexOf(el.id) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                }
              </ListItemIcon>
              <ListItemText id={labelId} primary={el.text} />
            </ListItem>
          );

        })}
        <ListItem />
      </List>
    </Paper>
  );

  const getProps = () => {
    return props.isReadOnly ? {} : {
      save: true,
      onSaveClick: () => { handleFieldConfigSaveSubmit(); }
    }
  }
  return (
    <>
      <SectionComponent classes={classes} {...getProps()} />

      <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
        <Grid item xs={5}><Typography variant="h6" className={classes.root}>Available Fields</Typography>{customList(left)}</Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Button disabled={props.isReadOnly} variant="outlined" size="small" className={classes.button} onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label="move selected right">
              &gt;
            </Button>
            <Button disabled={props.isReadOnly} variant="outlined" size="small" className={classes.button} onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label="move selected left">
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}><Typography variant="h6" className={classes.root}>Displayed Fields</Typography>{customList(right)}</Grid>
      </Grid>
    </>
  );
}
