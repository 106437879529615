import React from 'react';
import './circleComponent.css'


const STYLES = [
   "circle-error",
   "circle-warning",
   "circle-unmatched",
   "circle-eligible",
   "circle-approval"
]

export const CircleComponent = ({ value, circleStyle }) => {
   const customCircleStyles = STYLES.includes(circleStyle) ? circleStyle : STYLES[0];
   return (
      <div className={`commonCircleStyles ${customCircleStyles}`} >
         {value}
      </div>
   )
}

