import { ReplaySubject } from 'rxjs';
import { DataService } from "../../../../shared/services/dataService";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridColumnExt } from '../../../../shared/services/ag-grid/agGridColumnExt';

export default class IdentifierService {

  static getProfileIdentifierAsOBS = (_tenantId, _userId, _modalAgNode) => {
    const targetUrl = `/Profiles/FetchProfileIdentifier?tenantId=${_tenantId}&prid=${_modalAgNode.prid}&pageNumber=1&pageSize=1000`;
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      targetUrl,
    );
  };

  static fetchEntityLovDataAsOBS = (_tenantId,) => {
    const targetUrl = `/Entity/FetchEntityLOVData?fieldNames=PorzioGSTIdentifierType&tenantId=${_tenantId}&entityId=1&screenName=PorzioFieldsLOV_1376`;
    return ApiService.getOBS(API_ENDPOINT.CORE, targetUrl);
  };

  //#region Identifiers Grid

  static getIdentifiersRows = (_identifierData) => {
    const identifiersRows = DataService.hasValue(_identifierData) ? _identifierData : [];
    // identifiersRows.forEach(row => {
    //   row["countryId"] = row["countrylist"].countryId;
    // });
    return identifiersRows;
  }

  static getIdentifiersObject = (
    _porzioGSTIdentifierTypeId = 0,
    _companyIdentifierID = "",
    _countryId = "",
    _countryInfo = { "countryId": "", "countryName": "", "countryCode": "" },
    _identifierDescription = "",
    _identifierValue = "",
    _porzioGSTIdentifierType = { "lovId": 0, "lovKey": "", "ordinal": 0, "isdefault": false, "fieldName": "", "localLovKey": "" },
    _porzioGSTIdentifierID = "",
    _profileIdentifierId = 0,
  ) => {
    return {
      "porzioGSTIdentifierTypeId": _porzioGSTIdentifierTypeId,
      "companyIdentifierID": _companyIdentifierID,
      "countryId": _countryId,
      "countryInfo": {
        "countryId": _countryInfo.countryId,
        "countryName": _countryInfo.countryName,
        "countryCode": _countryInfo.countryCode
      },
      "identifierDescription": _identifierDescription,
      "identifierValue": _identifierValue,
      "porzioGSTIdentifierType": {
        "lovId": _porzioGSTIdentifierType.lovId,
        "lovKey": _porzioGSTIdentifierType.lovKey,
        "ordinal": _porzioGSTIdentifierType.ordinal,
        "isdefault": _porzioGSTIdentifierType.isdefault,
        "fieldName": _porzioGSTIdentifierType.fieldName,
        "localLovKey": _porzioGSTIdentifierType.localLovKey
      },
      "porzioGSTIdentifierID": _porzioGSTIdentifierID,
      "profileIdentifierId": _profileIdentifierId,
    };
  }

  static isNewIdentifierRow = (e) => {
    return e.data.companyIdentifierID === "" || e.data.companyIdentifierID <= 0;
  }


  static validateIdentifiersRowEditOBS = (_cellRef) => {
    var oSubject = new ReplaySubject(); // 1st

    // static/async validation logic should trigger the below
    oSubject.next({ isSuccess: true }); // true <- success

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
   * @param _identifiersFieldConfigs config Array
  */
  static getColumnDefs = (_componentThisRef, _isReadOnly, _showDelete, _agGridUtils) => {
    const arrSort = _componentThisRef.state.porzioGstIdentifierTypeList.filter(k => k.lovKey.substring(0, 3) !== "US:").map(i => i.lovId); // this preserves the alphabetical sort order of the drop down
    let oRET = [
      // edit button column
      _agGridUtils.getInlineEditActionColumn(_showDelete, this.validateIdentifiersRowEditOBS),

      // other columns
      // Note this first key must match the default focus key in this Service's corresponding Component: new AgGridUtil()
      AgGridColumnExt.GET(true, "text", "porzioGSTIdentifierTypeId", "Porzio GST Identifier Type").minAndDefaultWidth(384).makeDropDown(_componentThisRef.state.porzioGstIdentifierTypeCellSource, {}, "agSelectCellEditor", ["", ...arrSort]).SET(),
      AgGridColumnExt.GET(true, "text", "countryId", "Country").minAndDefaultWidth(192).makeDropDown({ 0: " ", ..._componentThisRef.state.countryCellSource }).SET(),

      AgGridColumnExt.GET(true, "text", "identifierValue", "Identifier Value").minAndDefaultWidth(192).tooltip().SET(),
      AgGridColumnExt.GET(true, "text", "identifierDescription", "Identifier Description").minAndDefaultWidth(130).flexWeight(1).tooltip().SET(),

      // AgGridColumnExt.GET(false, "text", "identifierValue", "Identitifier Value 1").minAndDefaultWidth(192).tooltip().SET(),
      // AgGridColumnExt.GET(false, "text", "identifierDescription", "Identitifier Description").minAndDefaultWidth(128).tooltip().SET(),

      AgGridColumnExt.GET(false, "text", "companyIdentifierID", "Company Identifier Id").fixedWidth(128).headerClass("center-text").centerAlignText()
        .validateEditablity((e) => { return this.isNewIdentifierRow(e) }).SET(),
      AgGridColumnExt.GET(false, "text", "porzioGSTIdentifierID", "Porzio GST Identifier Id").fixedWidth(144).headerClass("center-text").centerAlignText()
        .validateEditablity((e) => { return this.isNewIdentifierRow(e) }).SET(),
    ];
    return oRET;
  }

  //#endregion Identifiers Grid

}