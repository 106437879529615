import React from "react";
import { combineLatest, of } from "rxjs";
import { Box, Typography, Grid } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";


import { AuthContext } from "../../../../shared/store/authProvider";
import { API_ENDPOINT, CrudAction, ENTITY_TYPE, ResultStatus } from "../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import LookupService from "../../../../shared/services/lookupService";
import LayoutService from "../../../../shared/services/layoutService";

import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import IdentifierService from "./identifierService";
import ApiService from "../../../../shared/services/apiService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class IdentifierComponent extends React.Component {

  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      fetchState: ResultStatus.NOT_LOADED,
      // isReadOnly: true, NA since it will be sent as a props by the parent Container
      isNew: this.props.inputAction === CrudAction.CREATE,
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      selProfileId: !props.modalAgNode ? "" : props.modalAgNode.prid,
      // 1) source-name
      fetchSourceSystemsResult: ResultStatus.NOT_LOADED,
      selSourceSystemId: !props.modalAgNode ? "" : props.modalAgNode.sourceid,
      sourceSystemList: [],

      agGridUtils: new AgGridUtil("porzioGSTIdentifierTypeId", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
      }),


    };
  }

  componentWillUnmount() { }
  componentDidMount() { this.fetchData(); }

  fetchData = () => {

    this.oSubscriptions.cancelAll();

    this._setFetchState(ResultStatus.LOADING, null, null, null, () => {
      this.oSubscriptions.add(
        combineLatest([
          (this.state.isNew ? of(null) : IdentifierService.getProfileIdentifierAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode)),
          IdentifierService.fetchEntityLovDataAsOBS(this.context.user.tenantId),
          LookupService.getFormattedCountriesAsOBS(this.context, null),
        ]).subscribe(
          // success result
          ([_identifierData, _porzioGstIdentifierTypeList, _countryList]) => {
            this._setFetchState(ResultStatus.LOADED, _identifierData, _countryList,
              _porzioGstIdentifierTypeList.slice().sort((a, b) => (a.lovKey > b.lovKey) ? 1 : ((b.lovKey > a.lovKey) ? -1 : 0)),
              () => { });
          },
          // on error
          (err) => { this._setFetchState(ResultStatus.ERROR, null, null, null, () => { }); }
        )
      );
    });
  }

  _setFetchState = (_fetchState, _identifierData, _countryList, _porzioGstIdentifierTypeList,
    _callback = () => { }) => {

    this.setState({
      fetchState: _fetchState,
      identifiersRowData: _identifierData,

      countryList: _countryList,
      countryCellSource: DataService.arrayToObject(_countryList, "id", "value"),

      porzioGstIdentifierTypeList: _porzioGstIdentifierTypeList,
      porzioGstIdentifierTypeCellSource: DataService.arrayToObject(_porzioGstIdentifierTypeList, "lovId", "lovKey"),
    }, _callback);
  }

  // render
  TAB_PERMISSIONS = RolePermissionService.PROFILE_IDENTITIFERS;
  render() {
    const { classes } = this.props;
    // set the props for parent's validation & post logic
    this.props.tabConfig.ref = this; // 1/4) required by parent component

    if (this.TAB_PERMISSIONS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    }
    else {
      const isReadOnly = this.props.isReadOnly || this.TAB_PERMISSIONS.cannotEdit;
      this.state.agGridUtils.setReadOnlyMode(isReadOnly);

      switch (this.state.fetchState) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label="Loading Identifier Details" />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label="Saving Identifier Details" />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }}>
                <div id="MainRoleGrid">
                  <Grid container direction="row" justify="space-between" alignItems="center">
                    <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8 }}></Typography>
                    {LayoutService.getIconButton((this.props.isReadOnly || this.TAB_PERMISSIONS.cannotCreate), MatIconService.ADD_CIRCLE_OUTLINE, "Add New Identifier", () => {
                      if (this.state.agGridUtils.isNotEditing()) { this.state.agGridUtils.addNewRow(IdentifierService.getIdentifiersObject()); }
                    })}
                  </Grid>
                  <div {...LayoutService.getAgGridStyles(320)} {...LayoutService.getAgGridTheme()}>
                    <AgGridReact
                      pagination={true}
                      paginationPageSize={50}
                      rowData={this.state.identifiersRowData}
                      columnDefs={IdentifierService.getColumnDefs(this, this.state.isReadOnly, this.TAB_PERMISSIONS.canDelete, this.state.agGridUtils)}
                      frameworkComponents={this.state.agGridUtils.frameworkComponents}
                      suppressClickEdit={true}
                      gridOptions={{
                        context: { componentParent: this },
                        //suppressContextMenu: true,
                        ...AgGridColumnExt.getGridOptions(40),
                        ...this.state.agGridUtils.bindInlineEditEvents(),
                      }}
                      onGridReady={(params) => {
                        this.state.agGridUtils.setGridParams(params, false);
                        this.state.agGridUtils.setReadOnlyMode(this.props.isReadOnly);
                      }}
                    />
                  </div>
                </div>
              </Box>
            </React.Fragment>
          );
        case ResultStatus.ERROR:
        default:
          return (<DialogErrorFragmentComponent classes={classes} description="Error Loading Identifier Details" onRetry={() => { this.fetchSourceSystems(); }} />);
      }
    }
  }

  //#endregion

  /** 1/3 Required */
  isDirtyCallback = () => {
    // do any additional checkings if needed
    // TODO: return true if there are any changes to the grid
    return false;
  }
  /** 2/3 Required in Parent */
  resetCallback = () => {
    // TODO: reset the grid
    // do any additional resetting if needed
  }

  /** 3/3 Required in Parent */
  postCallbackOBS = () => {
    // loding animation is handled in the parent componenet
    // this.setState({ fetchSourceSystemsResult: ResultStatus.SAVING });

    //---Mandatory-Fields---------------
    var dataToPost = [];

    //---Grid---------------
    // identifiers-grid
    const updatedIdentifiersList = this.state.agGridUtils.getUpdatedRowData();

    // Delete Identifiers
    let rowIds = updatedIdentifiersList.map(x => x.porzioGSTIdentifierID);
    let deletedIdentifierIds = this.state.identifiersRowData.filter((x) => {
      if (!rowIds.includes(x.porzioGSTIdentifierID)) {
        return x.porzioGSTIdentifierID;
      }
    });
    this.oSubscriptions.cancelAll();

    this.oSubscriptions.add(ApiService.deleteOBS(API_ENDPOINT.CORE, `/Profiles/DeleteIdentifierData/${this.context.user.tenantId}/${this.props.modalAgNode.prid}/${this.context.user.userId}`, `"${deletedIdentifierIds.map(x => x.porzioGSTIdentifierID).toString()}"`)
      .subscribe(
        (_successResult) => {
          this.setState({ fetchState: ResultStatus.SAVING });
          if (_successResult) {
            this.setState({ fetchState: ResultStatus.LOADED });
          }
        },
        (_error) => {
          this.setState({ fetchState: ResultStatus.ERROR });
        }
      ));

    updatedIdentifiersList.forEach(rowData => {

      const lovPorzioGSTIdentifierType = DataService.getFirstOrDefault(this.state.porzioGstIdentifierTypeList.filter(x => x.lovId === rowData["porzioGSTIdentifierTypeId"]));
      const objPorzioGSTIdentifierType = lovPorzioGSTIdentifierType ? { "lovId": lovPorzioGSTIdentifierType.lovId, "lovKey": lovPorzioGSTIdentifierType.lovKey } : { "lovId": 0, "lovKey": "" };

      const lovCountryInfo = DataService.getFirstOrDefault(this.state.countryList.filter(x => x.id === rowData["countryId"]));
      const objCountryInfo = lovCountryInfo ? { "countryId": lovCountryInfo.id, "countryName": lovCountryInfo.value } : { "countryId": "", "countryName": "" };

      const objIdentifier = IdentifierService.getIdentifiersObject(
        rowData["porzioGSTIdentifierTypeId"],
        rowData["companyIdentifierID"],
        rowData["countryId"],
        objCountryInfo,
        rowData["identifierDescription"],
        rowData["identifierValue"],
        objPorzioGSTIdentifierType,
        rowData["porzioGSTIdentifierID"],
        rowData["profileIdentifierId"],
      );
      dataToPost.push(objIdentifier);
    });

    //---Post---------------
    const stringifiedJson = JSON.stringify(dataToPost);
    return ApiService.postOBS(API_ENDPOINT.CORE, `/Profiles/SaveIdentifierData?tenantId=${this.context.user.tenantId}&prid=${this.props.modalAgNode.prid}&userId=${this.context.user.userId}&uId=${"DEV"}`, stringifiedJson);
  }
}
export default LayoutService.getHocComponenet(IdentifierComponent);