import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { AllCommunityModules } from "ag-grid-community";
import { AuthContext } from "../../../shared/store/authProvider";
import SalesRepLinkRendererComponent from "./salesRepLinkRendererComponent";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import IsActiveIconRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import { Dialog } from "@material-ui/core";

import SalesRepDetailsComponent from "./sales-rep-details/salesRepDetailsComponent.jsx";
import SalesRepService from "./salesRepService";
import LayoutService from "../../../shared/services/layoutService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class SalesRepComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = SalesRepService;
  constructor(props) {
    super(props);
    this.state = {
      modules: AllCommunityModules,
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        salesRepLinkRendererComponent: SalesRepLinkRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      modalShow: false,
      modalAgNode: null,
      inputAction: CrudAction.NONE,
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();

  }

  formatSalesRepList = (_salesRepList, _countryList) => {
    let countryList = [{ id: 0, value: "" }, ..._countryList];

    let salesRepList = _salesRepList.map(sr => {
      const found = countryList.find(c => c.id === sr.countryId);
      return found ? { ...sr, countryName: countryList.find(c2 => c2.id === sr.countryId).value, } : { ...sr, countryId: 0, countryName: "", };
    });

    console.log("_salesRepList", _salesRepList);
    console.log("salesRepList", salesRepList);
    return (salesRepList);
  }

  fetchData = (_ignoreCache = false) => {
    this.setState({ fetchResult: ResultStatus.LOADING });

    this.STORE.clearAll();

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their reusult in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        SalesRepService.getSalesRepsAsOBS(this.context),
        SalesRepService.getCountriesAsOBS(this.context),
      ]).subscribe(([_salesRepList, _countryList]) => {
        this.setState(
          {
            // salesRepList: _salesRepList.map(el => ({ ...el, countryName: [{ id: 0, value: "" }, ..._countryList].find(c => c.id === el.countryId).value })),
            salesRepList: this.formatSalesRepList(_salesRepList, _countryList),
            countryList: _countryList,
          },
          () => { // change the state after all the above are assigned
            this.setState({ fetchResult: ResultStatus.LOADED });
          }
        );
      })
    );
  }

  methodFromParent = (cell, node) => {
    this.setState({ modalShow: true, });
    this.setState({ inputAction: CrudAction.UPDATE });
    this.setState({ modalAgNode: node });
  };

  handleCreateModal = () => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.CREATE });
    this.setState({ modalAgNode: { srId: 0, }, });
  };

  render() {
    const { classes } = this.props;

    if (RolePermissionService.SALESREP.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              classes={classes}
              label="Loading SalesRep"
            />
          );
        case ResultStatus.LOADED:
          return (
            <div id="MainGrid">
              <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
              <div className="IconBg marginTop10px tableTitleHead">
                {/* Header Componenet */}
                {<PageDynamicHeaderComponent classes={classes} label="SalesRep" rightPadding={16}
                  leftActions={[{ icon: MatIconService.BACK, title: "Back", iconColor: "secondary", onClick: this.props.history.goBack }]}
                  rightActions={[
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.handleCreateModal, isReadOnly: RolePermissionService.SALESREP_DETAIL.cannotCreate },
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                />}
              </div>
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.salesRepList}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                  onGridReady={(event) => {
                    event.api.sizeColumnsToFit();
                  }}
                ></AgGridReact>
              </div>
              {this.state.modalShow ?
                <Dialog open={this.state.modalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
                  <SalesRepDetailsComponent
                    open={this.state.modalShow || false}
                    onClose={() => this.setState({ modalShow: false })}
                    modalAgNode={this.state.modalAgNode}
                    inputAction={this.state.inputAction}
                    refreshGrid={() => { this.fetchData(true) }}
                  />
                </Dialog>

                : null}
            </div>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              label="Error Loading SalesRep"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(SalesRepComponent);
