import { ReplaySubject } from "rxjs";
import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";
import { API_ENDPOINT, GST_API } from "../../../shared/types/enums";
import { DataService } from "../../../shared/services/dataService";

export default class TaskListService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  static _ALLUSERS = [];
  static getAllUsersAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(TaskListService._ALLUSERS)) {
      oSubject.next(TaskListService._ALLUSERS);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT,
        `/User/GetAllUsers/${_context.user.tenantId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        TaskListService._ALLUSERS = _data;
        oSubject.next(TaskListService._ALLUSERS);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();

    return ApiService.fetchHTTP_API(GST_API.TenantConfig, `/Task/FetchTasks?tenantId=${clientAuthContext.user.tenantId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderClause: "",
        whereClause: "",
        userId: clientAuthContext.user.userId,
        tenantId: clientAuthContext.user.tenantId,
        screenId: 0,
        rolePermissionId: 0,
        isPorzio: true,
        pageNumber: 1,
        pageSize: 1000
      }),
    });
  }

}
