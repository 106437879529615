import React from 'react';
import ReactDOM from 'react-dom';

export default class CustomButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            izState: props.izState,
            isIzendaRendered: false,
            isButtonRendered: false,
            isObserving: false,
            portalElement: document.createElement('div')
        };
    }

    componentDidMount() {
        this.checkBtnControl();
    }

    componentDidUpdate() {
        this.checkBtnControl();
    }

    /**
    * Check if Izenda has started rendering, if the button already is appended, or if we already have an observable declared
    */
    checkBtnControl() {
        if (this.props.izState.isIzendaRendering && !this.state.isButtonRendered && !this.state.isObserving) {
            let dom = document.getElementsByClassName('izenda-ActionButton')[0];
            if (dom) {
                this.updateBtnState(dom);
            } else {
                this.setState(() => ({ isObserving: true }));
                let observer = new MutationObserver(function (mutations) {
                    dom = document.getElementsByClassName('izenda-ActionButton')[0];
                    if (dom && !this.state.isButtonRendered) {
                        observer.disconnect();
                        this.updateBtnState(dom);
                    }
                }.bind(this));
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });
            }
        }
    }

    updateBtnState(dom) {
        this.setState(() => ({ portalElement: dom, isIzendaRendering: false, isButtonRendered: true, isObserving: false }));
    }

    render() {
        return ReactDOM.createPortal(
            <ButtonChild />,
            this.state.portalElement
        );
    }
}

function ButtonChild() {
    const customBtnEvent = () => {
        alert("This is your custom button's custom event.");
    }
    return (
        <button onClick={customBtnEvent} className="btn btn-primary btn-report-default" id="customButton">Custom</button>
    );
}
