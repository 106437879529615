import { of, ReplaySubject } from "rxjs";
import { DataService } from "../../../../shared/services/dataService";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, } from "../../../../shared/types/enums";

export default class EventDetailsService {
    /** Step-1/3
     * make sure the lookup observable is mentioned here,
     * this is used to fetch commonly used observables after the user logs in,
     * using the rxJs's combine latest operator
     *  */
    static fetchEventDetailsLookupsOBS() {
        return [
            EventDetailsService.getEventByIdAsOBS(),
        ];
    }

    /** STEP-2/3
     * Clear the cache
     * This function should be called after the user Login/Logout,
     * The reson for clering the cache on user login is the lookups are populated for context.user.tenantId,
     */
    static clearAll() {
        EventDetailsService._ASSIGNEDTOFULLUSER = [];
    }

    /** STEP 3/3
     * Define the fetch logic below
     */
    // _BUSINESSRULE
    static _EVENTBYID = [];
    static getEventByIdAsOBS = (_context, eventId) => {
        var oSubject = new ReplaySubject();
        // if local_cache is not Empty
        if (DataService.hasElements(EventDetailsService._EVENTBYID)) {
            oSubject.next(EventDetailsService._EVENTBYID);
        } else {
            // fetch and subscribe to the result
            ApiService.getOBS(
                API_ENDPOINT.TENANT,
                `/Calendar/GetEventById/${_context.user.tenantId}/${eventId}`
            ).subscribe((_data) => {
                // store it in the local cache, then trigger the subscription at the client side
                EventDetailsService._EVENTBYID = _data;
                oSubject.next(EventDetailsService._EVENTBYID);
            });
        }
        // return the subject as observable
        return oSubject.asObservable();
    };

}
