import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class ComplianceReportMappingService {

  static getFieldLOVByIdsAsOBS = (_tenantId, _fieldId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetFieldLOVByIds/${_tenantId}/${_fieldId}`
    );
  };

}
