import ApiService from "../../shared/services/apiService";
import Cache from "../../shared/store/cache";
import { API_ENDPOINT } from "../../shared/types/enums";

export default class NonEntityService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext,0);
    } else {
      return this.CACHE.getOBS();
    }
  }

  static fetchFromApiObs = (clientAuthContext, _lovEntityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetNonEntityLOVsByID/${clientAuthContext.user.tenantId}/${6}`
      //`/Util/GetNonEntityLOVsByID/${clientAuthContext.user.tenantId}/${_lovEntityId}`
    );
  };


  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Entity Type",
        field: "lovEntityDesc",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 125,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Entity Name",
        field: "lovKey",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 125,
      },
      // {
      //   headerName: "Ordinal",
      //   field: "ordinal",
      //   sortable: true,
      //   filter: "agTextColumnFilter",
      //   cellStyle: { "border-right": "1px solid lightgray" },
      //   resizable: true,
      //   width: 125,
      // },
      {
        headerName: "Active?",
        field: "isActive",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        cellRenderer: "nonEntityIsActiveRendererComponent",
        width: 65,
      },
    ];
  }
}
