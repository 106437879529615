import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid, Box, Typography, AppBar, Toolbar, IconButton, InputBase } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { CrudAction, ENTITY_TYPE, ResultStatus, ReportLibrary } from "../../../../shared/types/enums";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ReportLibraryViewService from "./reportLibraryViewService";
import ReportSaveService from "../../report-save/reportSaveService";
import DownloadReportViaImageCellRenderer from "./downloadReportViaImageCellRenderer";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { AgGridErroredCheckboxCellRenderer } from "../../../../shared/components/ag-grid/error-cell-renderers/agGridErroredCheckboxCellRenderer";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import ToastService from "../../../../shared/services/toastService";
import ChildMessageRendererComponent from "./childMessageRendererComponent";
import EditReportDetailDialogComponent from "../edit-report-detail/editReportDetailDialogComponent";

class ReportLibraryViewComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      reportLibraryLov: null,
      selectedReportLibraries: [],
      editReportDetailDialogShow: false,
      modalAgNode: null,
      //isReadOnly: true,//this.props.inputAction === CrudAction.UPDATE,//TODO : handle props.readOnly too
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("reportDetailName", {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,
        downloadReportViaImageCellRenderer: DownloadReportViaImageCellRenderer,
        childMessageRendererComponent: ChildMessageRendererComponent,
      }),
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.getReportLibraryId();

    ReportSaveService.reportSavedSubject.subscribe(
      (_reportSaved) => {
        this.getReportLibraryId();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getReportLibraryId = () => {
    this.apiSubscriptions.add(
      LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 304)
        .subscribe(
          (_reportLibraryLov) => {
            this.setState({
              reportLibraryLov: _reportLibraryLov,
            }, () => {
              this.fetchData();
            });
          },
          (error) => {
            console.log(error);
            this.setState({
              fetchResult: ResultStatus.ERROR,
            });
          }
        ));
  }

  handleDeleteClick = () => {
    this.setState({
      openDeleteConfirm: true
    });
  }

  handleReloadClick = () => {
    this.getReportLibraryId();
  }

  handleDeleteConfirm = () => {
    this.setState({ openDeleteConfirm: false });
    let idsToDelete = this.state.selectedReportLibraries.map(r => r.reportDetailID)?.join(",");
    this.apiSubscriptions.add(ReportLibraryViewService.deleteReportLibrary(this.context.user.tenantId, this.context.user.userId, idsToDelete.toString())
      .subscribe(
        (_successResult) => {
          ToastService.showSuccess("Report library/libraries deleted successfully");
          this.fetchData();
        },
        (_errorResult) => {
          ToastService.showError("Error occured while deleting");
        }
      ));
    this.setState({ selectedReportLibraries: [] });
  }

  toggleSubmitButton = (_cellRef, _data) => {
    let hasSelected = false;
    let selectedReportLibraries = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedReportLibraries.push(rowNode.data);
        }
      });
    }

    this.setState({
      selectedReportLibraries: selectedReportLibraries,
      isReadOnly: !hasSelected,
    });
  }

  methodFromParent = (cell, node) => {
    this.setState({ editReportDetailDialogShow: true });
    this.setState({ modalAgNode: node });
  };

  handleDownloadAg = async (
    agNodeData,
    fileExtension,
    fileType
  ) => {
    const result = await fetch(
      `${process.env.REACT_APP_REPORTS_HTTP}/Reports/Download/${this.context.user.tenantId}/${agNodeData.reportDetailID}/${fileExtension}?fileType=${fileType}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.context.authToken}`,
        },
      }
    );
    const blob = await result.blob();
    return blob;
  };

  fetchData = () => {
    let reportLibraryId = this.props.reportLibraryType == ReportLibrary.IN_REVIEW ?
      this.state.reportLibraryLov.find(l => l.lovKey === "In Review")?.lovId :
      this.state.reportLibraryLov.find(l => l.lovKey === "Approved")?.lovId;
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    this.apiSubscriptions.add(ReportLibraryViewService.getReportsByLibraryId(this.context.user.tenantId, this.context.user.userId, reportLibraryId)
      .subscribe(
        (success) => {
          this.setState({
            fetchResult: ResultStatus.SUCCESS,
            data: success,
          });
        },
        (error) => {
          console.log(error);
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      ));
  }

  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component
    const componentType = this.props.reportLibraryType == ReportLibrary.IN_REVIEW ? "In Review Library" : "Approved Library";
    this.state.agGridUtils.setEditingMode(this.props.isReadOnly);

    const cannotDelete = this.props.isReadOnly || this.state.selectedReportLibraries.length === 0;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
      case ResultStatus.SAVING:
        return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            <DialogTitle disableTypography id="dialogTitle" />
            <DialogContent>
              <Box style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  {LayoutService.getIconButton(false, MatIconService.RELOAD, "Reload", () => { this.handleReloadClick() }, "secondary")}
                  <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8, flexGrow: 1 }}></Typography>
                  {LayoutService.getIconButton(cannotDelete, cannotDelete ? MatIconService.DELETE : MatIconService.DELETE_RED, "Delete", () => { this.handleDeleteClick() }, "default")}
                </Grid>
                <div {...LayoutService.getAgGridStyles(288)}>
                  <AgGridReact
                    columnDefs={ReportLibraryViewService.getColumnDefs(this)}
                    rowData={this.state.data}
                    pagination={true}
                    paginationPageSize={50}
                    frameworkComponents={this.state.agGridUtils.frameworkComponents}
                    suppressClickEdit={true}
                    suppressRowClickSelection={true}
                    gridOptions={{
                      context: { componentParent: this },
                      rowHeight: 48,
                      ...AgGridColumnExt.getGridOptions(56)
                    }}
                    onGridReady={event => {
                      event.api.closeToolPanel();
                      // event.api.sizeColumnsToFit();
                      this.state.agGridUtils.setGridParams(event, true);
                      this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                    }}
                    sideBar={true}
                  ></AgGridReact>
                </div>

              </Box>
            </DialogContent>
            {/* Delete dialog */}
            {this.state.openDeleteConfirm ?
              <Dialog open={this.state.openDeleteConfirm || false} onClose={() => this.setState({ openDeleteConfirm: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                  <AppBar position="static">
                    <Toolbar>
                      <Typography variant="h6" className={classes.root}>Delete Confirmation</Typography>
                      {LayoutService.getIconButton(false, MatIconService.OK, "Delete", this.handleDeleteConfirm, "inherit", "keyActionDelete")}
                      {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => this.setState({ openDeleteConfirm: false }), "secondary", "keyActionCancel")}
                    </Toolbar>
                  </AppBar>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.root} align="center">Are you sure you want to delete the selected report library/libraries? This action cannot be reversed.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.root} align="center">{this.state.selectedReportLibraries.map(r => r.reportDetailName).join(",")}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
              : <></>}

            {/* Edit dialog */}
            {this.state.editReportDetailDialogShow ?
              <Dialog open={this.state.editReportDetailDialogShow || false} scroll={true ? "paper" : "body"}>
                <EditReportDetailDialogComponent onClose={() => this.setState({ editReportDetailDialogShow: false })} modalAgNode={this.state.modalAgNode} refreshReportLibrary={this.fetchData} inputAction={CrudAction.UPDATE} />
              </Dialog>
              : <></>}
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (
          // TODO : add proper close calls
          <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
            onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
        );
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(ReportLibraryViewComponent);
