import { of } from "rxjs";
import { DataService } from "../services/dataService";

export default class Cache {
  _cachedResult = [];
  _oSubscription = null;
  _apiFetchObsCallback = null;
  _lastSetUtc = null;
  _expiryTimeout = 0;

  /** if set this.STORE.CACHE.EXISTS() will return false */
  _aboutToExpire = false;
  _aboutToExpireTimeout = 500; // give a very small value

  /**
   *
   * @param { Api fetch observable} apiFetchObs
   * @param { milliseconds } expiryTimeout
   */
  constructor(apiFetchObsCallback = () => {}, expiryTimeout = null) {
    this._apiFetchObsCallback = apiFetchObsCallback;
    this._aboutToExpire = false;
    // if _expiresAfter is  set
    if (expiryTimeout > this._aboutToExpireTimeout) {
      this._expiryTimeout = expiryTimeout;
      // start a momentTimer that is (_expiresAfter - _aboutToExpireTimeout)
      // -> and set the _aboutToExpire flag
      // then start another moment timer and call clearCache on it expiry
    }
  }

  // can be used to do prefetch during appload
  FETCH() {
    this._oSubscription = this._apiFetchObsCallback().subscribe((o) => {
      this.SET(o);
      // TODO: call the below after a timeout
      this._unSubscribe();
    });
  }

  _unSubscribe() {
    if (this._oSubscription) {
      this._oSubscription.unsubscribe();
    }
  }

  NOT_EXISTS() {
    return !this.EXISTS();
  }

  /**
   * Will return false if cache is about to expire
   * are left for cache Expiry
   */
  EXISTS() {
    if (this._aboutToExpire) {
      return false;
    } else {
      return DataService.hasElements(this._cachedResult);
    }
  }

  /**
   * Clears the cache result
   * */
  CLEAR() {
    this._aboutToExpire = false;
    this._cachedResult = [];
    this._lastSetUtc = null;
  }

  /**
   * Sets the cache and sets the time
   * @param {} _result
   */
  SET(_result) {
    this.CLEAR();
    this._cachedResult = _result;
    // TODO cancel the current timeouts
    // and restart
  }

  /**
   * returns the _cached result
   */
  GET() {
    console.log("Loaded from Cache", this._cachedResult);
    return this._cachedResult;
  }
  /**
   * returns the _cached result as observable
   */
  getOBS() {
    return of(this.GET());
  }
}
