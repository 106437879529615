import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AuthContext } from "../../../shared/store/authProvider";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { API_ENDPOINT, CrudAction, ResultStatus } from "../../../shared/types/enums";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import SectionComponent from "../../../shared/components/sectionComponent";
import { withStyles, Grid, Button } from "@material-ui/core";
import BusinessRulesListService from "./businessRulesListService";
import IsActiveIconRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";
import AgGridChipCellRendererComponent from "../../../shared/components/ag-grid/agGridChipCellRendererComponent";
import LayoutService from "../../../shared/services/layoutService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import CheckBoxCellRendererComponent from "./checkBoxCellRenderer";
import ApiService from "../../../shared/services/apiService";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import SessionService from "../../../shared/services/sessionService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class BusinessRulesListComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = BusinessRulesListService;
  constructor(props) {
    super(props);
    this.state = {
      frameworkComponents: {
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
        checkBoxCellRendererComponent: CheckBoxCellRendererComponent,
        agGridChipCellRendererComponent: AgGridChipCellRendererComponent,
      },
      data: [],
      unassignedData: [],
      fetchResult: ResultStatus.NOT_LOADED,
      selAssigned: [],
      selUnassigned: [],
    };
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    _ignoreCache = true;
    this.oSubscriptions.add(
      combineLatest([
        this.STORE.getObs(_ignoreCache, this.props.systemId, 1, this.context),
        this.STORE.getObs(_ignoreCache, this.props.systemId, 2, this.context),
      ]).subscribe(
        ([_assigned, _unassigned]) => {
          if (_assigned) {
            if (!Array.isArray(_assigned)) {
              _assigned = new Array(_assigned);
            }
            _assigned.map((x) => {
              x.isAssigned = true;
              x.isChecked = false;
              return x;
            });
          }
          if (_unassigned) {
            if (!Array.isArray(_unassigned)) {
              _unassigned = new Array(_unassigned);
            }
            // _unassigned.map((x) => (x.isAssigned = false));
            _unassigned.map((x) => {
              x.isAssigned = false;
              x.isChecked = false;
              return x;
            });
          }
          this.STORE.CACHE.SET(_assigned, 0);
          this.STORE.CACHE.SET(_unassigned, 0);
          const leftOver = _unassigned.filter(u => !_assigned.some(a => u.businessRuleId === a.businessRuleId));
          this.setState(
            {
              data: _assigned.map(o => ({ ...o, errorOrWarn: o.categoryName === "Data Processing" ? "notApplicable" : o.isWarning ? "isWarning" : "isError" })),
              unassignedData: leftOver.map(o => ({ ...o, errorOrWarn: o.categoryName === "Data Processing" ? "notApplicable" : o.isWarning ? "isWarning" : "isError" })),
            },
            // change the state after all the above are assigned
            () => {
              if (_assigned === null || _unassigned === null) {
                this.setState({ fetchResult: ResultStatus.ERROR });
              } else {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
              }
            }
          );
        },
        ([_assigned, _unassigned]) => {
          //console.log("Error:", _assigned);
          // onResultStatus.ERROR
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.setDomLayout("autoHeight");
  };
  methodFromParent = (cell, data) => {
    if (data.isAssigned) {
      let prev = this.state.selAssigned;
      data.isChecked
        ? prev.push(data)
        : prev.filter((x) => x.businessRuleId !== data.businessRuleId);
      this.setState({ selAssigned: prev });
    } else {
      let prev = this.state.selUnassigned;
      data.isChecked
        ? prev.push(data)
        : prev.filter((x) => x.businessRuleId !== data.businessRuleId);
      this.setState({ selUnassigned: prev });
    }
  };

  moveToAssigned = () => {
    let currAssigned = this.state.data;
    let currUnassigned = this.state.unassignedData;
    currUnassigned = currUnassigned.filter(
      (x) => !this.state.selUnassigned.includes(x)
    );
    currAssigned = [...currAssigned, ...this.state.selUnassigned];
    currAssigned.forEach((x) => {
      x.isChecked = false;
      x.isAssigned = true;
      return x;
    });
    this.setState(
      {
        data: currAssigned,
        selUnassigned: [],
        unassignedData: currUnassigned,
      });
  };
  moveToUnassigned = () => {
    let currAssigned = this.state.data;
    let currUnassigned = this.state.unassignedData;
    currAssigned = currAssigned.filter(
      (x) => !this.state.selAssigned.includes(x)
    );
    currUnassigned = [...currUnassigned, ...this.state.selAssigned];
    currUnassigned.forEach((x) => {
      x.isChecked = false;
      x.isAssigned = false;
      return x;
    });
    this.setState(
      {
        data: currAssigned,
        selAssigned: [],
        unassignedData: currUnassigned,
      });
  };

  getKeyValueCollection = (_objectArray, _keyProp, _valueProp) => {
    if (_objectArray.length !== 0) {
      var _newAssigned = _objectArray
        .filter((x) => x[_keyProp] !== 0)
        .map((el) => ({
          businessRuleId: "" + el[_keyProp],
          versionId: "" + el[_valueProp],
        }));
    }
    // return
    return _newAssigned;
  };
  handleSubmit = () => {
    let newAssigned = this.getKeyValueCollection(
      this.state.data,
      "businessRuleId",
      "versionNumber"
    );
    newAssigned = newAssigned ?? [];
    // const _usermodel = SessionService.getUserModelFromStorage();
    this.oSubscriptions.add(
      ApiService.postOBS(
        API_ENDPOINT.CORE,
        `/SourceSystems/SaveBusinessRuleDetailsBySourceId/${this.context.user.tenantId}/${this.props.systemId}`,
        JSON.stringify(newAssigned)
      ).subscribe(
        (status) => {
          this.setState({
            fetchResult: ResultStatus.LOADING,
          });
          this.fetchData();
        },
        (status) => {
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };

  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent classes={classes} label="Loading Business Rule Summary" />
        );
      case ResultStatus.SUCCESS:
        return (
          <div id="MainUsersGrid">

            <SectionComponent marginTop="0px" classes={classes}
              enableEditJsx={RolePermissionService.SOURCE_FILE_DETAIL.canEdit ? LayoutService.getReadOnlyActionsSolo(this, false, () => { this.handleSubmit() }) : <></>}
            />

            <SectionComponent classes={classes} label={"Assigned Rules"} marginTop="16px" />
            <div style={{ height: `296px`, width: `100%` }} {...LayoutService.getAgGridTheme()}>
              <AgGridReact
                columnDefs={this.STORE.getColumnDefs(this)}
                rowData={this.state.data}
                pagination={true}
                paginationPageSize={25}
                frameworkComponents={this.state.frameworkComponents}
                gridOptions={{
                  context: { componentParent: this },
                  ...AgGridColumnExt.getGridOptions(40),
                }}
                onGridReady={(event) => {
                  // event.api.sizeColumnsToFit();
                }}
              ></AgGridReact>
            </div>

            <Grid container direction="row" alignItems="center" justify="center" style={{ marginTop: 4 }}>
              <Button disabled={RolePermissionService.SOURCE_FILE_DETAIL.cannotEdit} variant="contained" color="secondary" onClick={() => { this.moveToAssigned(); }}>{MatIconService.MOVE_UP}</Button>
              <Button disabled={RolePermissionService.SOURCE_FILE_DETAIL.cannotEdit} style={{ marginLeft: 8 }} variant="contained" color="secondary" onClick={() => { this.moveToUnassigned(); }}>{MatIconService.MOVE_DOWN}</Button>
            </Grid>

            <SectionComponent classes={classes} label={"Unassigned Rules"} marginTop="4px" />
            <div style={{ height: `296px`, width: `100%` }} {...LayoutService.getAgGridTheme()}>
              <AgGridReact
                columnDefs={this.STORE.getColumnDefs(this)}
                rowData={this.state.unassignedData}
                pagination={true}
                paginationPageSize={50}
                frameworkComponents={this.state.frameworkComponents}
                gridOptions={{
                  context: { componentParent: this },
                  ...AgGridColumnExt.getGridOptions(40),
                }}
                onGridReady={(event) => {
                  // event.api.sizeColumnsToFit();
                }}
              ></AgGridReact>
            </div>
          </div>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent label="Error Loading Business Rules" classes={classes}
            onRetry={() => { this.fetchData(true); }} onClose={this.props.onClose} />
        );
    }
  }
}
export default withStyles(MatClassService)(BusinessRulesListComponent);
