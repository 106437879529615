import React from "react";
import { Typography, Paper, Toolbar, IconButton, AppBar, Divider } from "@material-ui/core";
import MatThemeService from "../../services/theme/matThemeService";


/* <TabDetailsToolbarComponent label="" classes={classes}
        leftControls={[]}
        rightControls={[
            { icon: MatIconService.DELETE, color: "secondary", onClick: (e) => { console.log('Delete clicked') } },
            { icon: MatIconService.OK, color: "primary", onClick: (e) => { console.log('Save clicked') } },
        ]}
    />
*/
export default class TabDetailsToolbarComponent extends React.Component {
    render() {
        const { classes, label, leftControls, rightControls, contentJsx } = this.props;

        return (
            <React.Fragment>
                <Paper elevation={0} style={{ marginTop: "0px" }}>
                    <AppBar position="static" elevation={0} style={{ backgroundColor: "primary" }}>
                        <Toolbar variant="dense" style={{ height: "40px", minHeight: "40px", backgroundColor: MatThemeService.isDarkMode() ? "#333333" : "#f1f1f1" }}>
                            {/* controls to be rendered in the left side of the toolbar*/}
                            {
                                leftControls.map((x, index) =>
                                    <IconButton key={"leftIcon" + index} color={x.color ? x.color : "inherit"} edge="start" onClick={x.onClick}>{x.icon}</IconButton>
                                )
                            }
                            {/* Label*/}
                            <Typography variant="h6" color="inherit" className={classes.root}>{label}</Typography>
                            {/* controls to be rendered in the right side of the toolbar*/}
                            {
                                rightControls.map((x, index) =>
                                    <IconButton key={"rightIcon" + index} color={x.color ? x.color : "inherit"} edge="start" onClick={x.onClick}>{x.icon}</IconButton>
                                )
                            }
                        </Toolbar>
                    </AppBar>
                    {/* content to be rendered belwo the toolbar */}
                    {contentJsx}
                </Paper>
                <Divider />
            </React.Fragment>
        );
    }
}