import React, { Component } from "react";
import { Dialog } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { CrudAction, ResultStatus, ENTITY_TYPE } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import ProfileCenterService from "./profileCenterService";
import ProfileExpandedViewComponent from "./expanded-view/profileExpandedViewComponent";
import ProfileCountCellRendererComponent from "./expanded-view/profileCountCellRendererComponent";
import ProfileErrorCellRendererComponent from "./expanded-view/profileErrorCellRendererComponent";
import ProfileWarningCellRendererComponent from "./expanded-view/profileWarningCellRendererComponent";
import ProfileUnmatchedCellRendererComponent from "./expanded-view/profileUnmatchedCellRendererComponent";
import DialogErrorFragmentComponent from "../../shared/components/page/dialogErrorFragmentComponent";
import LayoutService from "../../shared/services/layoutService";
import ProfileDetailDialogComponent from "./profile-detail/profileDetailDialogComponent";
import AgGridActionCellRendererComponent from "../../shared/components/ag-grid/agGridActionCellRendererComponent";
import RerunValidationProfileComponent from "./action-menu/rerunValidationProfileComponent";
import AddEditCommentComponent from "../../shared/components/add-edit-comments/addEditCommentComponent";
import UnmatchedProfileComponent from "./header-menu/unmatched-profiles-list/unmatchedProfileComponent";
import AgGridAppendCommentIconCellRendererComponent from "../../shared/components/ag-grid/agGridAppendCommentIconCellRendererComponent";
import ToastService from "../../shared/services/toastService";
import ProfilesSearchDialogComponent from "./header-menu/profile-search/profilesSearchDialogComponent";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";

class ProfileCenterComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = ProfileCenterService;

  state = {
    data: [],
    fetchResult: ResultStatus.NOT_LOADED,
    showProfileDetailDialog: false,
    showUnMatchedProfileDialog: false,
    showProfileSearchDialog: false,
    frameworkComponents: {
      actionCellRendererComponent: AgGridActionCellRendererComponent,
      agGridAppendCommentIconCellRendererComponent: AgGridAppendCommentIconCellRendererComponent,
      profileCountCellRendererComponent: ProfileCountCellRendererComponent,
      profileErrorCellRendererComponent: ProfileErrorCellRendererComponent,
      profileWarningCellRendererComponent: ProfileWarningCellRendererComponent,
      profileUnmatchedCellRendererComponent: ProfileUnmatchedCellRendererComponent,
    },
    modalAgNode: null,
    openCommentFile: false,
  };

  constructor(props) {
    super(props);
    // bindings
    ProfileCenterService.INIT(this);
    this.fetchData.bind(this);
  }

  componentWillUnmount() { this.oSubscriptions.cancelAll(); }
  componentDidMount() { this.fetchData(); }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (successResult) => { // onResultStatus.SUCCESS
          if (!Array.isArray(successResult)) { successResult = new Array(successResult); }
          this.STORE.CACHE.SET(successResult, 0);
          this.setState({
            data: successResult.map(obj => (obj.profileCount === 0 ? { ...obj, errorCount: 0, warningCount: 0, unmatchedCount: 0 } : obj)),
            fetchResult: ResultStatus.SUCCESS,
          }, () => { ProfileCenterService.showProfileDetailDialogIfExists(); });
        },
        (errorResult) => { // onResultStatus.ERROR
          ToastService.showError("Error while fetching Profiles.");
          this.setState({ fetchResult: ResultStatus.ERROR }, () => {
            ProfileCenterService.showProfileDetailDialogIfExists();
          });
        }
      )
    );
  };

  handleRerunValidationConfirm = (_cellRef) => {
    this.setState({
      openRerunConfirm: true,
      agCellData: _cellRef.props.data,
    });
  }

  loadProfileExpandedViewComponent = (_healthIndicator, _modalAgNode) => {
    if (RolePermissionService.PROFILE_EXPANDED_VIEW.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    }
    else if (
      (_healthIndicator === '1' && _modalAgNode['error'] === '0') ||
      (_healthIndicator === '2' && _modalAgNode['warning'] === '0') ||
      (_healthIndicator === '3' && _modalAgNode['unmatched'] === '0')
    ) {
      ToastService.showWarning('No data found.!');
      return;
    } else {
      this.closeAllDialogs(
        () => {
          this.setState({
            modalAgNode: _modalAgNode,
            healthIndicator: _healthIndicator,
            showProfileExpandedDialog: true
          })
        }
      );
    }
  }

  closeAllDialogs = (_afterCallback) => {
    this.setState({
      showProfileDetailDialog: false,
      showUnMatchedProfileDialog: false,
      showProfileSearchDialog: false,
      showProfileCountDialog: false,
      showProfileErrorDialog: false,
      showProfileWarningDialog: false,
      showProfileUnmatchedDialog: false,
      showProfileExpandedDialog: false
    }, _afterCallback);
  }

  profileCountMethodFromParent(cell, node) { this.loadProfileExpandedViewComponent("", node); }
  handleProfileCountHide = () => { this.closeAllDialogs(); }

  profileErrorMethodFromParent(cell, node) { this.loadProfileExpandedViewComponent("1", node); }
  handleProfileErrorHide = () => { this.closeAllDialogs(); }

  profileWarningMethodFromParent(cell, node) { this.loadProfileExpandedViewComponent("2", node); }
  handleProfileWarningHide = () => { this.closeAllDialogs(); }

  profileUnmatchedMethodFromParent(cell, node) { this.loadProfileExpandedViewComponent("3", node); }
  handleProfileUnmatchedHide = () => { this.closeAllDialogs(); }

  handleProfileExpandedHide = (_params) => {
    this.closeAllDialogs();
    if (_params !== false) {
      this.fetchData(true);
    }
  }

  handleAddEditCommentFile = (_cellRef) => {
    this.setState({
      openCommentFile: true,
      agCellData: _cellRef.props.data,
    });
  }

  onAddClick = () => {
    this.setState({ showProfileDetailDialog: true });
  }

  onUnMatchedClick = () => {
    this.setState({ showUnMatchedProfileDialog: true });
  }

  onSearchClick = () => {
    this.setState({ showProfileSearchDialog: true });
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
      fileName: `Profiles ${new Date().toDateString()}`
    };
  }

  render() {
    const { classes } = this.props;

    if (RolePermissionService.PROFILE_CENTER.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent classes={classes} label="Loading Profiles" />);
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <div id="drawer-container">

                {/* Header Componenet */}
                {<PageDynamicHeaderComponent classes={classes} label="Profiles" rightPadding={16}
                  leftActions={[
                    // { title: "back", icon: MatIconService.BACK, onClick: () => { this.props.history.goBack() } },
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                  rightActions={[
                    { icon: MatIconService.DOWNLOAD, title: "Download to Excel", iconColor: "primary", onClick: this.onDownloadClick, },
                    { icon: MatIconService.NOT_LISTED, title: "Unmatched to Reference Data", iconColor: "primary", onClick: this.onUnMatchedClick, },
                    { icon: MatIconService.SEARCH, title: "Search", iconColor: "primary", onClick: this.onSearchClick, },
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.onAddClick, isReadOnly: RolePermissionService.PROFILE_DETAILS.cannotCreate },
                  ]}
                />}

                {/* Grid Component */}
                <div {...LayoutService.getAgGridStyles(160)}>
                  {/* Grid Componenet */}
                  <AgGridReact
                    columnDefs={this.STORE.getColumnDefs(this)}
                    rowData={this.state.data}
                    pagination={true}
                    paginationPageSize={100}
                    frameworkComponents={this.state.frameworkComponents}
                    sideBar={true}
                    gridOptions={{
                      headerHeight: 48,
                      suppressContextMenu: true,
                      context: { componentParent: this },
                    }}
                    onGridReady={event => {
                      this.gridApi = event.api;
                      this.gridColumnApi = event.columnApi;
                      event.api.closeToolPanel();
                    }}
                  ></AgGridReact>
                </div>
              </div>

              {/* ExpandedView Dialog rendered as Page */}
              <Dialog {...LayoutService.getContainedDialogProps()} open={this.state.showProfileExpandedDialog || false} >
                <ProfileExpandedViewComponent inputAction={CrudAction.NONE} modalAgNode={this.state.modalAgNode}
                  healthIndicator={this.state.healthIndicator} screenId={43}
                  onClose={this.handleProfileExpandedHide} />
              </Dialog>

              {
                this.state.openRerunConfirm && this.state.agCellData ?
                  <RerunValidationProfileComponent
                    entityType={ENTITY_TYPE.PROFILE}
                    open={this.state.openRerunConfirm || false}
                    handleClose={() => this.setState({ openRerunConfirm: false })}
                    agCellData={this.state.agCellData}
                  />
                  : null
              }

              {/* Show Profile-Detail Dialog as a Page */}
              {this.state.showProfileDetailDialog ?
                <ProfileDetailDialogComponent standAloneProps={LayoutService.getContainedDialogProps(false)}
                  inputAction={ProfileCenterService.profileDetailParams ? CrudAction.UPDATE : CrudAction.CREATE}
                  modalAgNode={ProfileCenterService.profileDetailParams ? ProfileCenterService.profileDetailParams.modalAgNode : {}}
                  open={this.state.showProfileDetailDialog || false}
                  onClose={() => {
                    ProfileCenterService.profileDetailParams = null; // cleanup resedue params
                    this.setState({ showProfileDetailDialog: false });
                  }} />
                : <></>}

              <ProfilesSearchDialogComponent
                open={this.state.showProfileSearchDialog || false}
                onClose={() => this.setState({ showProfileSearchDialog: false })}
              />

              <UnmatchedProfileComponent open={this.state.showUnMatchedProfileDialog || false}
                onClose={() => this.setState({ showUnMatchedProfileDialog: false })} />

              {/* Action Menu Items */}
              {
                this.state.openCommentFile && this.state.agCellData ?
                  <AddEditCommentComponent
                    entityType={ENTITY_TYPE.PROFILE}
                    open={this.state.openCommentFile || false}
                    handleClose={(_reloadRequired) => {
                      this.setState({ openCommentFile: false });
                      if (_reloadRequired) {
                        this.fetchData(true);
                      }
                    }}
                    agCellData={this.state.agCellData}
                  />
                  : null
              }
            </React.Fragment >

          );


        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title="Error Loading Profile List" description="Error Loading Profile List"
              classes={classes} onClose={this.handleProfileExpandedHide} onRetry={() => { this.fetchData(true); }}
            />
          );
      }
    }
  }
}
export default LayoutService.getHocComponenet(ProfileCenterComponent);
