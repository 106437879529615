import React from "react";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import { withStyles } from "@material-ui/core";
import { Grid, Dialog, Typography, List, ListItem, ListItemText, ListItemIcon, Divider } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { AuthContext } from "../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../shared/services/dataService";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../shared/services/layoutService";
import EventDetailsComponent from "./event-details/eventDetailsComponent";
import EventCalendarStore from "./eventCalendarStore";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class EventCalendarComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = EventCalendarStore;

  state = {
    data: [],
    dataToDisplay: [],
    fetchResult: ResultStatus.NOT_LOADED,
    selectedDate: new Date(),
    showDetailsDialog: false,
    inputAction: CrudAction.CREATE,
    updateData: null,
  };

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false, oDate) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    if (oDate === undefined) {
      oDate = new Date();
    }
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context, oDate).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          this.STORE.CACHE.SET(o);
          this.setState({
            data: o,
            dataToDisplay: o,
            selectedDate: oDate,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          // onResultStatus.ERROR
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };

  handleDetailsDialogClose = (_result) => {
    if (_result) {
      // relod the componenet if needed
    }
    this.setState({
      showDetailsDialog: false,
    });
  };

  handleCreate = () => {
    this.setState({
      showDetailsDialog: true,
      inputAction: CrudAction.CREATE,
      updateData: null,
    });
  }

  handleUpdate = (_updateData) => {
    this.setState({
      showDetailsDialog: true,
      inputAction: CrudAction.UPDATE,
      updateData: _updateData,
    });
  }

  /**Render */
  render() {
    const { classes } = this.props;

    if (RolePermissionService.EVENT.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <PageDynamicHeaderComponent classes={classes} label="Calendar" divider rightPadding={16}
                leftActions={[
                  { icon: MatIconService.MENU, onClick: () => { }, title: "Menu", iconColor: "primary" },
                ]}
                rightActions={[
                  { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Create", iconColor: "secondary", onClick: this.handleCreate, isReadOnly: RolePermissionService.EVENT_DETAIL.cannotCreate },
                  { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                ]}
              />
              <Grid container direction="column" justify="flex-start" alignItems="center" style={{ marginTop: 16 }}>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker autoOk disableToolbar={false} orientation="portrait" variant="static" value={this.state.selectedDate}
                      onChange={(oDate) => {
                        this.setState({
                          selectedDate: oDate
                        },
                          () => {
                            this.fetchData(true, oDate);
                          });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid>
                <List dense={MatSpecService.useDenseLayout} style={{ maxHeight: 'calc(90vh - 102px)', overflow: 'auto' }}>
                  {this.state.dataToDisplay.map((o, index, array) => (
                    <React.Fragment key={LayoutService.getKey(o.eventId)}>
                      <ListItem button onClick={() => this.handleUpdate(o)} alignItems="flex-start">
                        <ListItemIcon>{MatIconService.EVENT_ALARM}</ListItemIcon>
                        <ListItemText
                          primary={o.eventTitle}
                          secondary={
                            <React.Fragment>
                              <Typography component="span" variant="body1" className={classes.inline} color="textPrimary">
                                {o.eventStartDate.substring(0, 10)}
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" className={classes.inline} color="error">
                                {o.eventStartTime}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />

                      {/* {true && index < array.length - 1 ? <Divider variant="inset" component="li" /> : null} */}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
              {/* Details-Modal */}
              <Dialog
                open={this.state.showDetailsDialog || false}
                scroll={true ? "paper" : "body"}
                maxWidth="md"
              >
                <EventDetailsComponent onClose={this.handleDetailsDialogClose} inputAction={this.state.inputAction} refreshEventList={this.fetchData} updateData={this.state.updateData} />
              </Dialog>

            </React.Fragment>
          );

        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label="Loading Events" />;
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading Events"
              classes={classes}
              onRetry={() => {
                this.fetchData(false);
              }}
            />
          );
      }
    }
  }
}

/** HOC */
export default withStyles(MatClassService)(EventCalendarComponent);
