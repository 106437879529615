import React from "react";
import RolePermissionService from "./rolePermissionService";

/**
 * Author : Pradeep_Rajendran@epam.com
 */
export default class RolePermissionInfo {

    /** componenet Id (i.e) permssion Id */
    permissionId = 0;
    /** componenet Name (i.e) permssion Name*/
    componenetName = "";

    canCreate = false;
    cannotCreate = true;

    canView = false;
    cannotView = true;

    canEdit = false;
    cannotEdit = true;

    canDelete = false;
    cannotDelete = true;

    canConfigure = false;
    cannotConfigure = true;

    canViewDeleted = false;
    cannotViewDeleted = true;

    canCreateOrEdit = false;
    cannotCreateOrEdit = true;

    canViewOrEdit = false;
    cannotViewOrEdit = true;

    /**
     * 
     * @param {*} _permissionId Componenet (Permsssion) Id
     * @param {*} _componenetName Component Name
     */
    constructor(_permissionId, _componenetName, fullPermission = false) {
        this.permissionId = _permissionId;
        this.componenetName = _componenetName;

        // -1 to ignore
        if (RolePermissionService.IS_TENANT_ADMIN || _permissionId === -1 || RolePermissionService.isValidPermissionId(_permissionId)) {
            // tenantAdmin has full privileges

            this.canCreate = RolePermissionService.IS_TENANT_ADMIN || fullPermission || RolePermissionService.canCreate(this.permissionId);
            this.cannotCreate = !this.canCreate;

            this.canView = RolePermissionService.IS_TENANT_ADMIN || fullPermission || RolePermissionService.canView(this.permissionId);
            this.cannotView = !this.canView;

            this.canEdit = RolePermissionService.IS_TENANT_ADMIN || fullPermission || RolePermissionService.canEdit(this.permissionId);
            this.cannotEdit = !this.canEdit;


            this.canDelete = RolePermissionService.IS_TENANT_ADMIN || fullPermission || RolePermissionService.canDelete(this.permissionId);
            this.cannotDelete = !this.canDelete;

            this.canConfigure = RolePermissionService.IS_TENANT_ADMIN || fullPermission || RolePermissionService.canConfigure(this.permissionId);
            this.cannotConfigure = !this.canConfigure;

            this.canViewDeleted = RolePermissionService.IS_TENANT_ADMIN || fullPermission || RolePermissionService.canViewDeleted(this.permissionId);
            this.cannotViewDeleted = !this.canViewDeleted;

            //---derived--------------------

            this.canCreateOrEdit = this.canCreate || this.canEdit;
            this.cannotCreateOrEdit = !this.canCreateOrEdit;

            this.canViewOrEdit = this.canView || this.canEdit;
            this.cannotViewOrEdit = !this.canViewOrEdit;
        }

    }

    /**
     * 
     * @param {*} _permissionId Componenet (Permsssion) Id
     * @param {*} _componenetName Component Name
     */
    static GET = (_permissionId, _componenetName) => {
        return new RolePermissionInfo(_permissionId, _componenetName);
    }

    /**
     * Returns an uninitialized object for providing the developer intelisense during implementation
     * @returns 
     */
    static INIT = (_fullPermission = false) => {
        return new RolePermissionInfo(-1, "uninitialized", _fullPermission);
    }

    //---

}