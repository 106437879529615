import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";
import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, InputBase, Box } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../../../shared/store/authProvider";
import { ResultStatus } from "../../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../../shared/services/dataService";
import LookupService from "../../../../../shared/services/lookupService";
import LayoutService from "../../../../../shared/services/layoutService";
import { AgGridColumnExt } from "../../../../../shared/services/ag-grid/agGridColumnExt";
import PageLoadingComponent from "../../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../../shared/components/page/dialogErrorFragmentComponent";
import { MatIconService } from "../../../../../shared/services/theme/matIconService";
import RecipientSearchDialogService from "./recipientSearchDialogService";
import AgGridRadioButtonCellRendererComponent from "../../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import { AgGridUtil } from "../../../../../shared/services/ag-grid/agGridUtil";

class RecipientSearchDialogComponent extends React.Component {

    static contextType = AuthContext;

    // ept till component's unMount
    scopedSubscriptions = new SubscriptionArray();
    // apiSubscriptions are canclled before retry also
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        // init state
        this.state = {
            searchResult: ResultStatus.NOT_LOADED,
            profileList: [],
            selectedProfile: null,
            lastSearchedValue: '',
            errorMessage: null,
            agGridRecipientUtils: new AgGridUtil("lastName", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        this.scopedSubscriptions.cancelAll();
        this.apiSubscriptions.cancelAll();
    }

    searchSubject = new BehaviorSubject("");

    componentDidMount() {
        var searchOBS = this.searchSubject.pipe(
            debounceTime(750),
            distinctUntilChanged(),
            mergeMap((_searchValue) => from(this.searchRecipient(_searchValue)))
        );

        this.scopedSubscriptions.add(searchOBS.subscribe());
    }

    searchRecipient = (_searchValue) => {
        this.apiSubscriptions.cancelAll();

        if (DataService.stringShouldBe(_searchValue, 3)) {
            this.setState({ searchResult: ResultStatus.LOADING, profileList: [], selectedProfile: null, lastSearchedValue: _searchValue });
            this.apiSubscriptions.add(
                combineLatest([
                    RecipientSearchDialogService.searchOBS(this.context.user.tenantId, _searchValue),
                    LookupService.getFormattedCountriesAsOBS(this.context, null),
                ]
                ).subscribe(
                    ([_profileList, _countryList]) => {
                        if (DataService.hasElements(_profileList)) {
                            _profileList.forEach(x => { x.isSelected = false; });
                        }
                        this.setState({
                            searchResult: DataService.hasElements(_profileList) ? ResultStatus.SUCCESS : ResultStatus.LOADED,//-> no records
                            profileList: _profileList,
                        });
                    },
                    (err) => { this.setState({ searchResult: ResultStatus.ERROR }); }
                )
            );
        } else {
            this.setState({ searchResult: ResultStatus.NOT_LOADED });
        }
        return [];
    }

    // render
    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Search Recipient</Typography>
                            {/* Search */}
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>{MatIconService.SEARCH}</div>
                                <InputBase autoFocus={true} placeholder="Search…" classes={{ root: classes.inputRoot, input: classes.inputInput }} inputProps={{ "aria-label": "search" }}
                                    onChange={(e) => { this.searchSubject.next(e.target.value); }} />
                            </div>
                            {LayoutService.getIconButton(DataService.isNullOrUndefined(this.state.selectedProfile), MatIconService.OK, "Save", () => {


                                if (DataService.hasValue(this.state.selectedProfile)) {
                                    var retObj = {};
                                    const keys = Object.keys(this.state.selectedProfile);
                                    keys.forEach(key => { retObj[key.toLowerCase()] = this.state.selectedProfile[key]; });
                                    this.setState({
                                        searchResult: ResultStatus.NOT_LOADED, //-> no records
                                        profileList: [],
                                    });

                                    this.props.onClose(retObj);
                                } else { this.props.onClose(null) }
                            }, "inherit", "keyActionSave")}
                            {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => { this.props.onClose(null) }, "secondary", "keyActionCancel1")}
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent>
                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, minHeight: "60vh", maxHeight: "60vh" }}>
                        {this.renderContent(classes)}
                    </Box>
                </DialogContent>
            </Dialog >
        );
    }

    renderContent = (classes) => {
        switch (this.state.searchResult) {
            case ResultStatus.SAVING: return <PageLoadingComponent small classes={classes} label="Saving..." />;
            case ResultStatus.LOADING: return <PageLoadingComponent small classes={classes} label="Searching..." />;
            case ResultStatus.NOT_LOADED: return <DialogErrorFragmentComponent small classes={classes} description="Please enter atleast 3 characters to search" />;
            case ResultStatus.LOADED: return <DialogErrorFragmentComponent small classes={classes} description="No Records Found" />;
            case ResultStatus.SUCCESS:
                return (<div id="MainRoleGrid">
                    <div style={{ height: `59vh`, width: `100%` }} {...LayoutService.getAgGridTheme()}>
                        <AgGridReact
                            rowData={this.state.profileList}
                            columnDefs={RecipientSearchDialogService.getColumnDefs(this, (_data) => {
                                this.setState({ selectedProfile: _data });
                            })}
                            suppressClickEdit={true}
                            frameworkComponents={this.state.agGridRecipientUtils.frameworkComponents}
                            onGridReady={(params) => { this.state.agGridRecipientUtils.setGridParams(params, true); }}
                            gridOptions={{
                                ...AgGridColumnExt.getGridOptions(56),
                                context: { componentParent: this },
                                suppressContextMenu: true,
                                rowHeight: 32,
                            }}
                        />
                    </div>
                </div>);
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={classes} description="An error occured while Searching" onRetry={() => { this.searchRecipient(this.state.lastSearchedValue); }} />);
        }
    }

}
export default LayoutService.getHocComponenet(RecipientSearchDialogComponent);