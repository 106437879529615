import React, { Component } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { DataService } from "../../../services/dataService";
import { AgGridBulkEditUtil } from "../bluk-edit/agGridBulkEditUtil";

export default class AgGridErroredDropdownCellRenderer extends Component {

    constructor(props) {
        super(props);

        const _errorOrWarningConfig = this.props.getErrorOrWarningInfoCallback(this);
        this.state = {
            //|---> Mandatory Fields
            isEditing: false,
            isParentReadOnly: this.props.isParentReadOnly,
            isColumnReadOnly: this.props.isColumnReadOnly,
            value: this.props.node.data[this.props.column.colDef.field],
            parentRef: this.props.componentThisRef,
            errorOrWarningConfig: _errorOrWarningConfig,
            hasErrorOrWarning: AgGridBulkEditUtil.hasErrorOrWarning(this.props.componentThisRef.state.agGridBulkEditUtil, this.props, _errorOrWarningConfig),
            //<---|

            // should be passed through cellRendererParams
            dataSource: this.props.dataSource || [],
            valueProp: this.props.valueProp,
            displayProp: this.props.displayProp,

        };
    }

    render() {

        return AgGridBulkEditUtil.getRenderer(this,
            // formattedValue callback
            () => {
                const selectedObj = DataService.getFirstOrDefault(this.state.dataSource.filter(x => x[this.state.valueProp] === this.state.value));
                return (selectedObj ? selectedObj[this.state.displayProp] : "");
            },
            // editor callback
            () => {
                return (
                    <Select value={this.state.value} open={this.state.isEditing} onClose={() => { AgGridBulkEditUtil.handleClose(this); }}
                        inputProps={{ style: { fontSize: 14, fontWeight: 400, lineHeight: 1.9 } }}
                        onChange={(e) => {
                            const lovId = e.target.value;
                            let lovTextValue = null;
                            let lovObj = DataService.getFirstOrDefault(this.state.dataSource.filter(x => x[this.state.valueProp] === lovId));
                            if (lovObj !== null) { lovTextValue = lovObj[this.state.displayProp]; }
                            AgGridBulkEditUtil.applyChange(this, lovId,
                                this.props.colFieldConfig.lovTextColumnName, lovTextValue);
                        }}>
                        {this.state.dataSource.map((x, index) => {
                            return (<MenuItem key={'item' + index} value={x[this.state.valueProp]}>{x[this.state.displayProp]}</MenuItem>);
                        })}
                    </Select>
                );
            }

        );
    }







    //---
}