import React, { Component } from "react";
import { AuthContext } from "../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import ChildMessageRendererComponent from "../childMessageRendererComponent";
import IsActiveIconRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";

import { Dialog } from "@material-ui/core";
import CreateRoleModalComponent from "./create-role/createRoleModalComponent";
import EditRoleModalComponent from "./role-details/editRoleModalComponent";
import RoleManagementService from "./roleManagementService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import LayoutService from "../../../shared/services/layoutService";
import ToastService from "../../../shared/services/toastService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import it from "date-fns/esm/locale/it/index.js";

class RoleManagementComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = RoleManagementService;
  constructor(props) {
    super(props);
    this.handleHideEditRoleModal = this.handleHideEditRoleModal.bind(this);
    this.handleCreateRoleModal = this.handleCreateRoleModal.bind(this);
    this.handleHideCreateRoleModal = this.handleHideCreateRoleModal.bind(this);
    this.fetchData.bind(this);

    this.state = {
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      createRoleModalShow: false,
      editRoleModalShow: false,
      rowData: [],
      modalAgNode: null,
      fetchResult: ResultStatus.NOT_LOADED,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, rowData: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (successObj) => {
          if (!Array.isArray(successObj)) {
            successObj = new Array(successObj);
          }
          successObj.sort(this.compareFn);
          this.setState({
            rowData: successObj,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (errorObj) => {
          console.log("Error:", errorObj);
          ToastService.showError("Error while saving");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  compareFn = (a, b) => {
    if (a.roleName < b.roleName) {
      return -1;
    } else if (a.roleName > b.roleName) {
      return 1;
    } else {
      return 0;
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.setDomLayout("autoHeight");
    this.gridApi.sizeColumnsToFit();
  };

  methodFromParent = (cell, node) => {
    if (RolePermissionService.ROLE_DETAIL.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      this.setState({ modalAgNode: node, editRoleModalShow: true });
    }
  };

  handleCreateRoleModal() {
    if (RolePermissionService.ROLE_DETAIL.cannotCreate) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      this.setState({
        modalAgNode: {
          roleId: 100,
          tenantId: this.context.user.tenantId,
          roleName: "",
          roleDescription: "",
          roleType: "Customer",
          isPlsRequired: true,
          isActive: true,
          effectiveDate: "",
        },
        createRoleModalShow: true,
      });
    }
  }

  handleHideCreateRoleModal() {
    this.setState({ createRoleModalShow: false });
  }

  handleHideEditRoleModal() {
    this.setState({ editRoleModalShow: false });
  }

  removeTenantAdminRole = (rowData) => {
    let filteredData = rowData.filter((item) => {
      return item.roleName !== "Tenant Admin";
    });
    return filteredData;
  };

  render() {
    const { classes } = this.props;
    if (RolePermissionService.ROLE_LIST.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => {
        this.props.history.goBack();
      });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent classes={classes} label="Loading Roles" />
          );
        case ResultStatus.SUCCESS:
          return (
            <div id="MainRoleGrid">
              <div
                style={{ backgroundColor: "#ddd", textAlign: "right" }}
              ></div>
              <div className="IconBg marginTop10px tableTitleHead">
                <PageDynamicHeaderComponent
                  classes={classes}
                  label="Role Management"
                  rightPadding={16}
                  leftActions={[
                    {
                      title: "Go Back",
                      icon: MatIconService.BACK,
                      onClick: () => {
                        this.props.history.goBack();
                      },
                    },
                    {
                      title: "Reload",
                      icon: MatIconService.RELOAD,
                      iconColor: "secondary",
                      onClick: () => {
                        this.fetchData(true);
                      },
                    },
                  ]}
                  rightActions={[
                    {
                      icon: MatIconService.DOWNLOAD,
                      title: "Export",
                      onClick: () => {},
                      isReadOnly:
                        RolePermissionService.ROLE_LIST_EXPORT.cannotView,
                    },
                    {
                      icon: MatIconService.ADD_CIRCLE_OUTLINE,
                      title: "Create User",
                      onClick: this.handleCreateRoleModal,
                      isReadOnly:
                        RolePermissionService.ROLE_DETAIL.cannotCreate,
                    },
                  ]}
                />
              </div>
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.removeTenantAdminRole(this.state.rowData)}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    tooltipShowDelay: 0,
                    context: { componentParent: this },
                  }}
                  onGridReady={this.onGridReady}
                ></AgGridReact>
              </div>
              <Dialog
                open={this.state.createRoleModalShow || false}
                scroll={true ? "paper" : "body"}
                maxWidth="md"
              >
                <CreateRoleModalComponent
                  onClose={this.handleHideCreateRoleModal}
                  modalAgNode={this.state.modalAgNode}
                  refreshRolesList={this.fetchData}
                  inputAction={CrudAction.CREATE}
                />
              </Dialog>
              <Dialog
                open={this.state.editRoleModalShow || false}
                scroll={true ? "paper" : "body"}
                maxWidth="md"
              >
                <EditRoleModalComponent
                  onClose={this.handleHideEditRoleModal}
                  modalAgNode={this.state.modalAgNode}
                  refreshRolesList={this.fetchData}
                  inputAction={CrudAction.UPDATE}
                />
              </Dialog>
            </div>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              label="Error Loading Role"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }
  }

  //---
}
export default LayoutService.getHocComponenet(RoleManagementComponent);
