import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import { DataService } from "../../services/dataService";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";

export default class AgGridCheckboxCellRendererComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isChecked: this.props.data[this.props.colDef.field] };
    }

    render() {
        const specialObj = this.props.data[AgGridUtil.specialProperty];
        const notHasSpecialProperty = DataService.isNullOrUndefined(specialObj);
        const isEditing = notHasSpecialProperty ? true : specialObj[AgGridUtil.editingProperty] === true;
        const isReadOnly = notHasSpecialProperty ? (!this.props.column.colDef.editable) : specialObj[AgGridUtil.readOnlyProperty] === true;
        
        let forceReadOnly = false;
        if (this.props.isCellEnabledCallback && !this.props.isCellEnabledCallback(this)) {
            forceReadOnly= true;
        }

        return (<Checkbox disabled={forceReadOnly || (isReadOnly || !isEditing)} color="secondary" size="small" style={{ padding: 0 }} indeterminate={false}
            checked={this.state.isChecked} onClick={() => { this.onStateChangeRequested(); }}
        />);
    }

    onStateChangeRequested = () => {
        this.setState({ isChecked: !this.state.isChecked }, // invert the checked state
            () => {
                // this.props.data[this.props.colDef.field] = this.state.isChecked; // update the datasource
                this.props.node.setDataValue(this.props.colDef.field, this.state.isChecked);  // update the datasource

                // call the callback
                if (this.props.onChange) {
                    this.props.onChange(this, this.state.isChecked);
                }
            }
        );
    }
}
