import React from "react";
import { AppBar, Box, Dialog,  DialogContent, DialogTitle, Divider, LinearProgress, Tab, Tabs, TextField, Toolbar, Typography, Accordion, AccordionSummary, AccordionDetails, Select, Grid, Chip, InputBase, makeStyles, Button, Menu, MenuItem, Paper, FormControl, FormHelperText } from "@material-ui/core";
import { MatIconService } from "../../shared/services/theme/matIconService";
import LayoutService from "../../shared/services/layoutService";
import { CrudAction } from "../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../shared/services/dataService";
import ActionDialog from "../../shared/components/dialog/actionDialog";
import ProfileSearchResultComponent from "./profile-search-result/profileSearchResultComponent";
import TransactionSearchResultComponent from "./transaction-search-result/transactionSearchResultComponent";
import AgreementSearchResultComponent from "./agreement-search-result/agreementSearchResultComponent";
import { AuthContext } from "../../shared/store/authProvider";

class GlobalSearchContainerComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
  }

  onClose = (_reloadRequired = false) => {
    this.props.close();
  }

  _changeTab = (event, _newTabIndex) => {
    this.setState({
      selectedTabIndex: _newTabIndex,
      isReadOnly: true // on tab change make it readonly
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {/* discard action dialog */}
        <ActionDialog showDialog={this.state.showConfirmDialog} title="Discard changes?" description="There are unsaved changes" actions={[
          {
            color: "secondary", text: "Discard", icon: null,
            callback: () => {
              this.setState({ showConfirmDialog: false }, () => {
                this._changeTab(this.state.newTabIndex);
              })
            }
          },
          {
            color: "inherit", text: "Cancel", icon: null,
            callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
          }
        ]} />

        {/* tab dialog */}
        {/* DialogTitle */}
        <React.Fragment>
          <DialogTitle disableTypography id="dialogTitle">
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" className={classes.root}>Global Search</Typography>
                {/* {LayoutService.getReadOnlyActions(this, false, () => { this.onClose(false) }, () => { this.onSave(); })} */}
                {LayoutService.getIconButton(false, MatIconService.CANCEL, "Discard Changes", () => { this.onClose(false) }, "secondary", "keyActionCancel")}
              </Toolbar>
            </AppBar>
          </DialogTitle>
          {/* DialogContent */}
          <DialogContent>
            <Tabs value={this.state.selectedTabIndex} onChange={this._changeTab} indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto">
              <Tab label="Profiles" id="profilesTab" />
              <Tab label="Transactions" id="transactionsTab" />
              <Tab label="Agreements" id="agreementsTab" />
            </Tabs>
            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id={"profileTabPanel"} >
              <Divider />
              <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                <ProfileSearchResultComponent searchValue={this.props.searchValue} />
              </Box>
            </div>
            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 1} id={"transactionTabPanel"} >
              <Divider />
              <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                <TransactionSearchResultComponent searchValue={this.props.searchValue} />
              </Box>
            </div>
            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 2} id={"agreementTabPanel"} >
              <Divider />
              <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                <AgreementSearchResultComponent searchValue={this.props.searchValue} />
              </Box>
            </div>
          </DialogContent>
        </React.Fragment>
      </>
    );
  }
}

export default LayoutService.getHocComponenet(GlobalSearchContainerComponent);