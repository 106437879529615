import React from "react";
import { AppBar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Tab, Tabs, TextField, Toolbar, Typography, DialogContentText, Grid } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import { AuthContext } from "../../../shared/store/authProvider";
import TabDetailsToolbarComponent from "../../../shared/components/tab/tabDetailsToolbarComponent";
import { CrudAction, API_ENDPOINT, ENTITY_TYPE } from "../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import ActionDialog from "../../../shared/components/dialog/actionDialog";
import ConsentComponent from "./consents/consentComponent";
import DocumentsComponent from "./documents/documentsComponent";
import RuleSummaryComponent from "./rule-summary/ruleSummaryComponent";
import AgreementTabComponent from "./agreement-tab/agreementTabComponent";
import TransactionsComponent from "./transactions/transactionsComponent";
import ApiService from "../../../shared/services/apiService";
import ReportReferenceComponent from "./report-reference/reportReferenceComponent";
import AgreementDetailDeleteConfirmComponent from "./agreementDetailDeleteConfirmComponent";
import ToastService from "../../../shared/services/toastService";
import RolePermissionInfo from "../../../shared/role-permissions/rolePermissionInfo";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import MatThemeService from "../../../shared/services/theme/matThemeService";

class AgreementDetailDialogComponent extends React.Component {
    oSubscriptions = new SubscriptionArray();
    static contextType = AuthContext;
    constructor(props) {
        super(props);

        this.tabIndexMap = new Map();
        this.tabIndexMap.set(0, { ref: null });
        this.tabIndexMap.set(1, { ref: null });
        this.tabIndexMap.set(2, { ref: null });
        this.tabIndexMap.set(3, { ref: null });
        this.tabIndexMap.set(4, { ref: null });
        this.tabIndexMap.set(5, { ref: null });
        this.tabIndexMap.set(6, { ref: null });

        this.state = {
            isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
            isNew: this.props.inputAction === CrudAction.CREATE,
            isEditing: this.props.inputAction === CrudAction.UPDATE,

            isDialog: this.props.standAloneProps ? false : true,
            derivedProps: this.props.standAloneProps ? this.props.standAloneProps : { fullWidth: true, maxWidth: "lg" },

            selectedTabIndex: 0,
            openDeleteConfirm: false,
            agreementTabLoaded: false,
            selectedAgreement: null
        };
    }

    onClose = (_reloadRequired) => {
        this.setState({ isReadOnly: true }, () => {
            this.props.onClose(_reloadRequired);
        });
    }

    _changeTab = (_newTabIndex) => {
        this.setState({
            selectedTabIndex: _newTabIndex,
            isReadOnly: true // on tab change make it readonly
        });
    }

    onTabChange = (event, _newTabIndex) => {
        const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;

        if (currentTabRef) {
            if (currentTabRef.isDirtyCallback()) {
                this.setState({ showConfirmDialog: true, newTabIndex: _newTabIndex });
            } else {
                this.setState({ showConfirmDialog: false }, () => {
                    this._changeTab(_newTabIndex);
                });
            }
        } else {
            this.setState({ showConfirmDialog: false }, () => {
                this._changeTab(_newTabIndex);
            });
        }
    };

    onSave = () => {
        console.log("this.state.selectedTabIndex: ", this.state.selectedTabIndex);
        this.oSubscriptions.cancelAll();
        const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;

        if (currentTabRef.postCallbackOBS) {
            this.setState({ isSaving: true });
            this.oSubscriptions.add(currentTabRef.postCallbackOBS().subscribe(
                (_successResult) => {
                    this.setState({ isSaving: false, saveErrorInfo: null });
                    if (this.state.isNew) {
                        this.onClose(true); // close this and reload the AgreementCenter
                    } else {
                        // stay on the same screen as per PP2-871
                    }
                    currentTabRef.resetCallback(true);
                },
                (_error) => {
                    console.log("Error while Saving", _error);
                    this.setState({ isSaving: false, saveErrorInfo: _error }, () => {
                        setTimeout(() => { this.setState({ saveErrorInfo: null }); }, 5000);
                    });
                }
            ));
        }
    }

    onDelete = () => {
        this.oSubscriptions.cancelAll();

        this.setState({ isSaving: true });
        this.oSubscriptions.add(this.getDeleteOBS().subscribe(
            (_successResult) => {
                this.setState({ isSaving: false, saveErrorInfo: null });
                if (_successResult === "validation_error") {
                    this.flashError("Validation Failed");
                } else if (_successResult === "save_error") {
                    this.flashError("Delete Failed");
                } else {
                    ToastService.showSuccess("Agreement Deleted Successfully");
                    this.onClose(true);
                }
            },
            (_error) => {
                this.flashError("Error Occured while Deleting");
            }
        ));
    }

    getDeleteOBS = () => {
        return ApiService.deleteOBS(API_ENDPOINT.CORE,
            `/Agreement/DeleteAgreement?tenantId=${this.context.user.tenantId}&userId=${this.context.user.userId}&agreementId=${this.props.modalAgNode.agreementId}`);
    }

    handleAgreementTabLoaded = (_selectedAgreement) => {
        this.setState({
            selectedAgreement: _selectedAgreement,
            agreementTabLoaded: true
        });
    }

    render() {
        const { classes } = this.props;
        const TAB_REF = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
        const TAB_PERMISSIONS = TAB_REF ? (TAB_REF.TAB_PERMISSIONS ? TAB_REF.TAB_PERMISSIONS : RolePermissionInfo.INIT()) : RolePermissionInfo.INIT();

        return (
            <>
                {/* discard action dialog */}
                <ActionDialog showDialog={this.state.showConfirmDialog} title="Discard changes?" description="There are unsaved changes" actions={[
                    {
                        color: "secondary", text: "Discard", icon: null,
                        callback: () => {
                            const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
                            currentTabRef.resetCallback();

                            this.setState({ showConfirmDialog: false }, () => {
                                this._changeTab(this.state.newTabIndex);
                            })
                        }
                    },
                    {
                        color: "inherit", text: "Cancel", icon: null,
                        callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
                    }
                ]} />

                {/* tab dialog */}
                <Dialog {...this.state.derivedProps} open={this.props.open || false} onClose={() => { this.onClose(false); }} >
                    {/* DialogTitle */}
                    {this.state.isDialog ?
                        <DialogTitle disableTypography id="dialogTitle">
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" className={classes.root}>Agreement Details</Typography>
                                    {LayoutService.getIconButton(this.state.isNew, MatIconService.DELETE, "Delete", () => this.setState({
                                        openDeleteConfirm: true, agreementId: this.props.modalAgNode.agreementId
                                    }), "inherit", "keyActionDelete")}
                                    {LayoutService.getReadOnlyActions(this, (this.props.inputAction === CrudAction.CREATE), () => { this.onClose(false) }, () => { this.onSave(); })}
                                </Toolbar>
                            </AppBar>
                        </DialogTitle>
                        :
                        <PageDynamicHeaderComponent classes={classes} label="Agreement Details" divider rightPadding={16}
                            leftActions={[
                                { icon: MatIconService.BACK, onClick: () => { this.onClose(false); }, title: "Go back", iconColor: "secondary" },
                            ]}
                            rightActions={[
                                {
                                    icon: this.state.selectedTabIndex !== 0 || RolePermissionService.AGREEMENT_DETAIL.cannotDelete ? MatIconService.DELETE : MatIconService.DELETE_RED,
                                    isReadOnly: this.state.selectedTabIndex !== 0 || RolePermissionService.AGREEMENT_DETAIL.cannotDelete, // this delete is for entire agreement
                                    title: "Delete Agreement", onClick: () => this.setState({
                                        openDeleteConfirm: true, agreementId: this.props.modalAgNode.agreementId
                                    }),
                                },
                                this.state.isReadOnly ?
                                    { icon: MatIconService.EDIT, title: "Edit", onClick: () => { this.setState({ isReadOnly: false }); }, isReadOnly: TAB_PERMISSIONS.cannotEdit } :
                                    { icon: MatIconService.OK, title: "Save", iconColor: "primary", onClick: () => { this.onSave(); }, isReadOnly: TAB_PERMISSIONS.cannotCreateOrEdit },
                            ]}
                        />
                    }

                    {this.state.isSaving ? <LinearProgress color="secondary" /> : null}
                    {this.state.saveErrorInfo ? <Typography style={{ backgroundColor: "#FF8A8A", color: "#6E0101" }} variant="h6" align="center">{this.state.saveErrorInfo}</Typography> : null}

                    {/* DialogContent */}
                    <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto"
                        style={{ backgroundColor: this.state.isDialog ? null : MatThemeService.getTabBG() }}>
                        <Tab label="Agreements" id="agreementsTab" />
                        <Tab hidden={!this.state.isEditing || RolePermissionService.AGREEMENT_DETAIL.cannotViewOrEdit} label="Rule Summary" id="ruleSummaryTab" />
                        <Tab hidden={!this.state.isEditing || RolePermissionService.AGREEMENT_CONSENT.cannotViewOrEdit} label="Consent" id="consentTab" />
                        <Tab hidden={!this.state.isEditing || RolePermissionService.AGREEMENT_TRANSACTIONS.cannotViewOrEdit} label="Transactions" id="transactionsTab" />
                        <Tab hidden={!this.state.isEditing || RolePermissionService.AGREEMENT_DOCUMENTS.cannotViewOrEdit} label="Documents" id="documentsTab" />
                        <Tab hidden={!this.state.isEditing || RolePermissionService.AGREEMENT_DETAIL.cannotViewOrEdit} label="Report References" id="reportReferencesTab" />
                    </Tabs>
                    <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id={"agreementsTabPanel"} >
                        <Divider />
                        <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                            <AgreementTabComponent tabConfig={this.tabIndexMap.get(0)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode}
                                onAgreementTabLoaded={(_selectedAgreement) => { this.handleAgreementTabLoaded(_selectedAgreement) }} />
                        </Box>
                    </div>
                    {this.state.agreementTabLoaded ?
                        <React.Fragment>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 1} id={"ruleSummaryTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                                    <RuleSummaryComponent tabConfig={this.tabIndexMap.get(1)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 2} id={"consentTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                                    <ConsentComponent onClose={this.onClose} tabConfig={this.tabIndexMap.get(2)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 3} id={"transactionsTabPanel"} >
                                <Divider />
                                {!this.state.isNew ?
                                    <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                                        <TransactionsComponent tabConfig={this.tabIndexMap.get(3)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} selectedAgreement={this.state.selectedAgreement} />
                                    </Box>
                                    : null}
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 4} id={"documentsTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                                    <DocumentsComponent tabConfig={this.tabIndexMap.get(4)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} entityType={3} agreementId={this.props.modalAgNode?.agreementId} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 5} id={"reportReferencesTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >
                                    <ReportReferenceComponent tabConfig={this.tabIndexMap.get(5)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                    {/* <LinkedProfilesComponent tabConfig={this.tabIndexMap.get(5)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} /> */}
                                </Box>
                            </div>
                        </React.Fragment>
                        : <></>}
                </Dialog >
                {/* delete dialog */}
                {this.state.openDeleteConfirm ?
                    <AgreementDetailDeleteConfirmComponent
                        open={this.state.openDeleteConfirm || false}
                        onClose={() => this.setState({ openDeleteConfirm: false })}
                        handleDeleteDismiss={() => this.setState({ openDeleteConfirm: false })}
                        handleDeleteConfirm={() => {
                            this.setState({ openDeleteConfirm: false });
                            this.onDelete();
                            this.onClose(true);
                        }}
                        porzioGstAgreementId={this.props.modalAgNode.porzioGstAgreementId} />
                    : <></>}
            </>
        );
    }
}
export default LayoutService.getHocComponenet(AgreementDetailDialogComponent);