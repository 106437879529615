export default class RecordValidationSummaryService {
  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Rule Severity",
        field: "businessRuleSeverity",
        sortable: true,
        filter: "boolean",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "Rule Name",
        field: "businessRuleName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
      {
        headerName: "No of Rule Violations",
        field: "numberOfBusinessRuleViolations",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
      },
    ];
  }
}
