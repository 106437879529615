import React from "react";
import { withRouter } from "react-router";
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { Grid, withStyles } from "@material-ui/core";
import { ResultStatus } from "../../shared/types/enums";
import OktaAuthService from "./oktaAuthService";
import ToastService from "../../shared/services/toastService";
import MultiTenantSelectionComponent from "./multiTenantSelectionComponent";
import { MatClassService } from "../../shared/services/theme/matClassService";

class OktaLoginWidgetComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userEmail: null,
            fetchState: ResultStatus.NOT_LOADED,
        }
    }

    componentDidMount() {
        OktaAuthService.showSignInWidget(this.loginContainerRef,
            (successObj) => {
                //OktaAuthService.authWidget.authClient.handleLoginRedirect(successObj.tokens);
                this.setState({ userEmail: successObj.tokens.idToken.claims.email, fetchState: ResultStatus.SUCCESS });
            },
            (errorObj) => { ToastService.showError("Login Failed. Please try again."); }
        );
    }

    render() {
        switch (this.state.fetchState) {
            case ResultStatus.SUCCESS:
                return <MultiTenantSelectionComponent userEmail={this.state.userEmail} topBarColor={this.props.topBarColor} onThemeChange={this.props.onThemeChange} />
            default:
                return (
                    <Grid container direction="column" justifyContent="start" alignItems="center" style={{ height: '100vh' }}>
                        <Grid container direction="column" justifyContent="center" alignItems="center" >
                            <div ref={(div) => { this.loginContainerRef = div; }} />
                        </Grid>
                    </Grid >
                );
        }
    }

    //---
}
export default withStyles(MatClassService)(withRouter(OktaLoginWidgetComponent));