import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { ResultStatus, CrudAction } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { withStyles } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import StudiesListService from "./studiesListService";
import IsActiveIconRendererComponent from "../../shared/components/ag-grid/isActiveIconRendererComponent";
import ChildMessageRendererComponent from "../../shared/components/childMessageRendererComponent";
import { Dialog } from "@material-ui/core";
import StudyDetailsComponent from "./study-details/studyDetailsComponent";
import LayoutService from "../../shared/services/layoutService";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";

class StudiesListComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = StudiesListService;
  constructor(props) {
    super(props);

    this.state = {
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      modalShow: false,
      modalAgNode: null,
      inputAction: CrudAction.NONE,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  formatStudyList = (_studyList, _delayIndicatorData) => {
    let delayIndicatorData = [
      { lovId: 0, lovKey: "" },
      { lovId: 1, lovKey: "1-R&D on a New Product or New Use/Indication of an Existing Product" },
      { lovId: 2, lovKey: "2-Clinical Investication on a New Product" },
      { lovId: 3, lovKey: "3-No delay" },
      ..._delayIndicatorData
    ];

    let studyList = _studyList.map(sl => {
      const found = delayIndicatorData.find(d => String(d.lovId) === sl.delayIndicator);
      return found ? { ...sl, delayIndicatorName: delayIndicatorData.find(d2 => String(d2.lovId) === sl.delayIndicator).lovKey, } : { ...sl, delayIndicator: "", delayIndicatorName: "", };
    });

    return (studyList);
  }

  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        StudiesListService.getAllStudiesAsOBS(this.context.user.tenantId),
        StudiesListService.getNonEntityLOVsByIDAsOBS(this.context.user.tenantId, 5),
        StudiesListService.getNonEntityLOVsByIDAsOBS(this.context.user.tenantId, 4),
      ]).subscribe(
        ([_studyList, _researchTypeData, _delayIndicatorData,]) => {
          this.setState(
            {
              studyList: this.formatStudyList(_studyList, _delayIndicatorData),
              researchTypeData: _researchTypeData,
              delayIndicatorData: _delayIndicatorData,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        (_exception) => {
          console.log(_exception);
        }
      )
    );
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };
  methodFromParent = (cell, node) => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.UPDATE });
    this.setState({ modalAgNode: node });
  };

  handleCreateModal = () => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.CREATE });
    this.setState({ modalAgNode: { srId: 0 } });
  };

  handleHide = () => {
    this.setState({ modalShow: false });
  }
  render() {
    const { classes } = this.props;

    if (RolePermissionService.STUDY.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              classes={classes}
              label="Loading Study Master"
            />
          );
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <div id="MainRoleGrid">
              <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
              <div className="IconBg marginTop10px tableTitleHead">
                {<PageDynamicHeaderComponent classes={classes} label="Study Master" rightPadding={16}
                  leftActions={[{ icon: MatIconService.BACK, title: "Back", iconColor: "secondary", onClick: this.props.history.goBack }]}
                  rightActions={[
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.handleCreateModal, isReadOnly: RolePermissionService.STUDY_DETAIL.cannotCreate },
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                />}
              </div>
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.studyList}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    tooltipShowDelay: 0,
                    context: { componentParent: this },
                    ...AgGridColumnExt.getGridOptions()
                  }}
                  onGridReady={this.onGridReady}
                ></AgGridReact>
              </div>
              <Dialog
                open={this.state.modalShow || false}
                scroll={true ? "paper" : "body"}
                maxWidth="md"
              >
                <StudyDetailsComponent
                  onClose={this.handleHide}
                  modalAgNode={this.state.modalAgNode}
                  inputAction={this.state.inputAction}
                  refreshGrid={this.fetchData}
                />
              </Dialog>
            </div>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              label="Error Loading Study Master"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }
  }
}

export default LayoutService.getHocComponenet(StudiesListComponent);
