import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";
import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, InputBase, Box } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AuthContext } from "../../../store/authProvider";
import { ResultStatus, CrudAction } from "../../../types/enums";
import { DataService, SubscriptionArray } from "../../../services/dataService";
import LayoutService from "../../../services/layoutService";
import PageLoadingComponent from "../../page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../page/dialogErrorFragmentComponent";
import { MatIconService } from "../../../services/theme/matIconService";
import AgreementsSearchDialogService from "./agreementsSearchDialogService";
import AgGridRadioButtonCellRendererComponent from "../../elements/agGridRadioButtonCellRendererComponent";
import { AgGridUtil } from "../../../services/ag-grid/agGridUtil";
import LookupService from "../../../services/lookupService";
import AgreementDetailCellRendererComponent from "../../../../private/agreements/agreementDetailCellRendererComponent";
import AgreementDetailDialogComponent from "../../../../private/agreements/agreement-detail/agreementDetailDialogComponent";

class AgreementsSearchDialogComponent extends React.Component {
    static contextType = AuthContext;
    //searchSubject = new BehaviorSubject("");

    // ept till component's unMount
    // = new SubscriptionArray();
    // apiSubscriptions are canclled before retry also
    apiSubscriptions = new SubscriptionArray();
    //scopedSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            searchResult: ResultStatus.LOADING,
            agreementList: [],
            selectedAgreement: null,
            //lastSearchedValue: '',
            errorMessage: null,
            agGridUtils: new AgGridUtil("lastName", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent,
                agreementDetailCellRendererComponent: AgreementDetailCellRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        //this.scopedSubscriptions.cancelAll();
        this.apiSubscriptions.cancelAll();
    }

    searchSubject = new BehaviorSubject("");
    componentDidMount() {
        this.searchAgreement();

        // var searchOBS = this.searchSubject.pipe(
        //     debounceTime(750),
        //     distinctUntilChanged(),
        //     mergeMap((_searchValue) => from(this.searchRecipient(_searchValue)))
        // );

        // this.scopedSubscriptions.add(searchOBS.subscribe());
    }

    onSearch = (event) => {
        this.setState({ selectedSearchValue: event.target.value });
        this.gridApi.setQuickFilter(event.target.value);
    }

    searchAgreement = () => {
        this.apiSubscriptions.cancelAll();

        this.setState({ searchResult: ResultStatus.LOADING, agreementList: [], selectedAgreement: null });
        this.apiSubscriptions.add(
            combineLatest([
                AgreementsSearchDialogService.searchOBS(this.context.user.tenantId, this.props.selectedPorzioGSTProfileId),
                LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 180),//agreementDocumentTypeId
            ]).subscribe(
                ([_agreementList, _documentTypeList]) => {
                    if (DataService.hasElements(_agreementList)) {
                        _agreementList = _agreementList.map(ag => ({
                            ...ag,
                            isSelected: false,
                            agreementdocuments: _documentTypeList.find(f => f.lovId === ag.agreementDocumentTypeId)?.lovKey
                        }));
                    }
                    this.setState({
                        searchResult: ResultStatus.SUCCESS,
                        agreementList: DataService.hasElements(_agreementList) ? _agreementList : [],
                    });
                },
                (err) => { this.setState({ searchResult: ResultStatus.ERROR }); }
            )
        );
        return [];
    }

    // called on row-cell click
    methodFromParent = (row_col, node) => {
        //if (this.state.agGridUtils.isNotEditing()) {
        this.setState({ showAgreementDetailDialog: true, inputAction: CrudAction.UPDATE, modalAgNode: node });
        //}
    };

    // render
    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Agreement Search</Typography>
                            {/* Search */}
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>{MatIconService.SEARCH}</div>
                                <InputBase autoFocus={true} placeholder="Search…" classes={{ root: classes.inputRoot, input: classes.inputInput }} inputProps={{ "aria-label": "search" }}
                                    onChange={(e) => { this.onSearch(e) }} />
                            </div>
                            {LayoutService.getIconButton(DataService.isNullOrUndefined(this.state.selectedAgreement), MatIconService.LINK, "Link", () => {
                                this.props.onSubmitClick(this.state.selectedAgreement);
                            }, "inherit", "keyActionSave")}
                            {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => { this.props.onClose("", "") }, "secondary", "keyActionCancel1")}
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                <DialogContent>
                    <Box style={{ padding: "8px" }}>
                        {this.renderContent(classes)}
                    </Box>
                </DialogContent>
                <AgreementDetailDialogComponent inputAction={this.state.inputAction} modalAgNode={this.state.modalAgNode}
                    open={this.state.showAgreementDetailDialog || false}
                    onClose={(_reloadRequired) => {
                        this.setState({ showAgreementDetailDialog: false });
                        if (_reloadRequired) {
                            this.fetchData(true);
                        }
                    }}
                />
            </Dialog >
        );
    }

    renderContent = (classes) => {
        switch (this.state.searchResult) {
            case ResultStatus.SAVING: return <PageLoadingComponent small classes={classes} label="Saving..." />;
            case ResultStatus.LOADING: return <PageLoadingComponent small classes={classes} label="Searching..." />;
            //case ResultStatus.NOT_LOADED: return <DialogErrorFragmentComponent small classes={classes} description="Please enter atleast 3 characters to search" />;
            case ResultStatus.LOADED: return <DialogErrorFragmentComponent small classes={classes} description="No Records Found" />;
            case ResultStatus.SUCCESS:
                return (<div id="MainRoleGrid">
                    <div {...LayoutService.getAgGridStyles(320)}>
                        <AgGridReact
                            rowData={this.state.agreementList}
                            columnDefs={AgreementsSearchDialogService.getColumnDefs(this, (_data) => {
                                this.setState({ selectedAgreement: _data });
                            })}
                            suppressClickEdit={true}
                            frameworkComponents={this.state.agGridUtils.frameworkComponents}
                            onGridReady={(params) => {
                                this.state.agGridUtils.setGridParams(params, true);
                                this.gridApi = params.api
                            }}
                            gridOptions={{
                                context: { componentParent: this },
                                suppressContextMenu: true,
                                rowHeight: 32,
                            }}
                        />
                    </div>
                </div>);
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={classes} description="An error occured while searching" onRetry={() => { this.searchAgreement(); }} />);
        }
    }

}
export default LayoutService.getHocComponenet(AgreementsSearchDialogComponent);