import React, { Component } from "react";
import {
  withStyles,
  TextField,
  InputLabel,
  Input,
  Select,
  FormControlLabel,
  Switch,
  FormGroup,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Formik } from "formik";
import * as Yup from "yup";
import { ResultStatus, CrudAction } from "../../../../shared/types/enums";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import LayoutService from "../../../../shared/services/layoutService";
import {
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import { AuthContext } from "../../../../shared/store/authProvider";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import ToastService from "../../../../shared/services/toastService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class CreateRoleModalComponent extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.onValueChange = this.onValueChange.bind(this);

    this.state = {
      data: [],
      selectedTabIndex: 0,
      fetchResult: ResultStatus.NOT_LOADED,
      roleType: "Customer",
      isActive: true,
      isPlsRequired: true,
    };
    console.log(this.props);
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.SUCCESS });
  }

  onValueChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  getInitialValues = () => {
    return {
      roleType: "Customer",
      isPlsRequired: "true",
      roleName: "",
      roleDescription: "",
      isActive: "true",
      effectiveDate: "2022-01-01",
    };
  };

  validationSchema = Yup.object().shape({});
  getValidationSchema = () => {
    this.validationSchema = Yup.object({
      roleType: Yup.string().required("Required"),
      roleName: Yup.string()
        .min(3, "Must be atleast 3 characters long")
        .max(256, "Must be 256 characters or less")
        .required("Required")
        .test(
          "checkDuplicateRoleName",
          "Role Name already exists",
          async (_formikValue) => {
            try {
              const result = await fetch(
                `${process.env.REACT_APP_TENANT_HTTP}/Role/GetAllRoles/${this.context.user.tenantId}`
              );
              const roleList = await result.json();
              const role = roleList.find(
                (role) =>
                  role.roleName.toLowerCase() === _formikValue.toLowerCase()
              );

              if (role) {
                return false;
              }
              return true;
            } catch (error) {
              console.log("Role Exists Error:", error);
              this.setState({
                fetchResult: ResultStatus.ERROR,
              });
            }
          }
        ),
      roleDescription: Yup.string().required("Required"),
      effectiveDate: Yup.date()
        .min("01/01/2019", "On or after 01/01/2019")
        .max("1/1/2051", "On or before 1/1/2051")
        .required("Required")
        .nullable(),
    });
    return this.validationSchema;
  };

  fetchCreateRolePost = async (_formikProps) => {
    this.setState({ fetchResult: ResultStatus.SAVING });
    try {
      const roleObj = JSON.stringify({
        roleId: 0,
        tenantId: this.context.user.tenantId,
        roleName: _formikProps.values.roleName,
        roleDescription: _formikProps.values.roleDescription,
        roleType: this.state.roleType,
        isPlsRequired: this.state.isPlsRequired,
        isActive: this.state.isActive,
        effectiveDate: _formikProps.values.effectiveDate,
      });
      const result = await fetch(
        `${process.env.REACT_APP_TENANT_HTTP}/Role/AddRole/${this.context.user.tenantId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: roleObj,
        }
      );
      if (result.status === 201) {
        this.setState({ fetchResult: ResultStatus.SUCCESS });
        this.props.refreshRolesList(true);
        this.props.onClose(false);
      } else {
        ToastService.showError("Error while creating Role Post.");
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    } catch (error) {
      ToastService.showError("Error while creating Role Post.");
      this.setState({ fetchResult: ResultStatus.ERROR });
    }
  };

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema
        .validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          this.fetchCreateRolePost(_formikProps);
          this.setState({ fetchResult: ResultStatus.LOADING });
          _formikProps.setSubmitting(true);
          setTimeout(() => {
            this.setState({ fetchResult: ResultStatus.SUCCESS });
            _formikProps.setSubmitting(false);
          }, 3000);
        })
        .catch((erroObj) => {
          ToastService.showError("Please Recheck your input.");
          erroObj.inner &&
            erroObj.inner.forEach((err) => {
              _formikProps.setFieldError(err.path, err.message);
            });
        });
    }
  };

  render() {
    const { classes } = this.props;
    if (RolePermissionService.ROLE_DETAIL.cannotCreate) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => {
        this.props.onClose(false);
      });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Create Role"
            />
          );
        case ResultStatus.SAVING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Saving Role Details"
            />
          );
        case ResultStatus.SUCCESS:
          return (
            <Formik
              initialValues={this.getInitialValues()}
              validationSchema={this.getValidationSchema()}
            >
              {(fProps) => (
                <form>
                  {/* Dialog Title */}
                  <DialogTitle disableTypography id="dialogTitle">
                    <AppBar position="static">
                      <Toolbar variant="dense">
                        <Typography variant="h6" className={classes.root}>
                          Edit Role
                        </Typography>
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            this.handleSubmit(fProps);
                          }}
                        >
                          {" "}
                          {MatIconService.OK}{" "}
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            this.props.onClose(false);
                          }}
                        >
                          {" "}
                          {MatIconService.CANCEL}{" "}
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  {/* Dialog Content */}
                  <DialogContent>
                    <Box
                      style={{
                        paddingLeft: 16,
                        paddingRight: 32,
                        paddingTop: 16,
                        paddingBottom: 32,
                        minWidth: "40vh",
                        maxWidth: "60vh",
                      }}
                    >
                      <TextField
                        {...LayoutService.getInputProps(
                          this.state.isReadOnly,
                          classes,
                          fProps,
                          "roleName",
                          "Role Name"
                        )}
                        fullWidth
                      />
                      <TextField
                        {...LayoutService.getInputProps(
                          this.state.isReadOnly,
                          classes,
                          fProps,
                          "roleDescription",
                          "Role Description"
                        )}
                        fullWidth
                      />
                      {/* <InputLabel
                        {...LayoutService.getInputProps(
                          this.state.isReadOnly,
                          classes,
                          fProps,
                          "roleTypeLabel",
                          "Role Type"
                        )}
                        fullWidth
                      >
                        Role Type
                      </InputLabel>
                      <Select
                        value={fProps.values["roleType"]}
                        className="form-control"
                        fullWidth
                        input={<Input />}
                      >
                        <option value={"Customer"}>Customer</option>
                      </Select>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          {...LayoutService.getDateProps(
                            this.state.isReadOnly,
                            classes,
                            fProps,
                            "effectiveDate",
                            "Effective Date",
                            true
                          )}
                          format="yyyy-MM-dd"
                          fullWidth
                        />
                      </MuiPickersUtilsProvider> */}
                      {/* <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.isPlsRequired}
                              onChange={this.onValueChange}
                              name="isPlsRequired"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                          label="PLS Required"
                        />
                      </FormGroup> */}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.isActive}
                              onChange={this.onValueChange}
                              name="isActive"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                          label="Active"
                        />
                      </FormGroup>
                    </Box>
                  </DialogContent>
                </form>
              )}
            </Formik>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading Role Details"
              classes={classes}
              onRetry={() => {
                this.SERVICE.fetchData(this, true);
              }}
            />
          );
      }
    }
  }

  //---
}
export default LayoutService.getHocComponenet(CreateRoleModalComponent);
