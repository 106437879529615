import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";

export default class agGridAppendCommentIconCellRendererComponent extends Component {

    render() {
        return (
            <div style={{ display: "flex" }}>
                <Typography style={{
                    fontSize: 14, fontWeight: 400, lineHeight: 1.9,
                    overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",
                    cursor: "pointer"
                }}>{this.props.value}</Typography>
                {this.props.node.data?.hasComments ? <div style={{ paddingLeft: 4 }}>{MatIconService.FILE_COMMENT_ON}</div> : null }
            </div>
        );
    }

    //---
}