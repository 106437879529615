import React from "react";
import { Dialog, AppBar, Toolbar, DialogContentText, withStyles, DialogTitle, DialogContent, Box, Divider, Typography, Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { combineLatest, of, ReplaySubject } from "rxjs";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { CrudAction, ResultStatus, PROFILE_CATEGORY } from "../../../../shared/types/enums";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import ReferenceDataService from "./referenceDataService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import ToastService from "../../../../shared/services/toastService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ReferenceDataSearchDialogComponent from "./reference-data-search-dialog/referenceDataSearchDialogComponent";
import ProfileDetailCellRendererComponent from "../../profile-detail/profileDetailCellRendererComponent";
import IsActiveIconRendererComponent from "../../../../shared/components/ag-grid/isActiveIconRendererComponent";
import ProfileDetailDialogComponent from "../../profile-detail/profileDetailDialogComponent";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { AgGridErroredCheckboxCellRenderer } from "../../../../shared/components/ag-grid/error-cell-renderers/agGridErroredCheckboxCellRenderer";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import RolePermissionInfo from "../../../../shared/role-permissions/rolePermissionInfo";
import { Refresh } from "@material-ui/icons";

class ReferenceDataComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  refDataToDelete = [];// this will store saved/unsaved deleted reference data
  nppesDataToDelete = [];

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      nppesData: [],
      fetchResult: ResultStatus.NOT_LOADED,
      isReadOnly: this.props.inputAction === CrudAction.UPDATE,
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      selectedPorzioGSTProfileId: this.props.modalAgNode.porziogstprofileid,
      showSearchReferenceDataDialog: false,
      selectedProfiles: [],
      selectedProfilesNppes: [],
      updatedRefData: [],
      updatedNppesRefData: [],
      nppesAction: false,
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("porziogstprofileid", {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
        profileDetailCellRendererComponent: ProfileDetailCellRendererComponent
      }),
      agGridBulkEditUtilNppes: new AgGridBulkEditUtil(),
      agGridUtilsNppes: new AgGridUtil("porziogstprofileid", {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
        profileDetailCellRendererComponent: ProfileDetailCellRendererComponent
      }),
    };
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    if (this.state.isEditing) {
      this.setState({ fetchResult: ResultStatus.LOADING });
      this.fetchData();
    }
  }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      combineLatest([
        ReferenceDataService.getMatchedReferenceDataForProfile(this.context.user.tenantId, this.props.modalAgNode.prid),
        LookupService.getProfileCategoriesAsOBS(this.context)])
        .subscribe(
          // success
          ([_data, _profileCategoryList]) => {
            let convertedData = DataService.convertToLowerCasedKeyObject(_data[0]);
            let convertedNppesData = DataService.convertToLowerCasedKeyObject(_data[1]);
            this.setState({
              data: DataService.hasElements(convertedData) ? convertedData : [],
              nppesData: DataService.hasElements(convertedNppesData) ? convertedNppesData : [],
              profileCategoryList : _profileCategoryList,
              selectedProfileCategory: DataService.hasElements(_profileCategoryList) ? _profileCategoryList.find(p => p.lovId == this.props.selectedProfile?.profilecategoryid)?.lovKey : ""
            },
              // change the state after all the above are assigned
              () => {
                this.setState({ fetchResult: ResultStatus.LOADED });
                //this.state.agGridUtils.sizeColumnsToFit();
              });
          },
          // onError
          (error) => {
            console.log("Error:", error);
            this.setState({ fetchResult: ResultStatus.ERROR });
          }
        )
    );
  };


  updateGrid = (selectedReferenceData) => {
    let updatedRefData = this.state.updatedRefData;
    let newObj = {};
    if (this.state.selectedProfileCategory === PROFILE_CATEGORY.ORGANIZATION) {
      newObj = {
        isactive: null,
        porziogstprofileid: this.props.modalAgNode.porziogstprofileid,
        source_file: selectedReferenceData.SOURCE_FILE,
        th_year: selectedReferenceData.TH_YEAR,
        tin: selectedReferenceData.TIN,
        organization_name: selectedReferenceData.HOSPITAL_NAME,
        street_address: selectedReferenceData.Street_Address,
        city: selectedReferenceData.City,
        state: selectedReferenceData.State,
        match_date: selectedReferenceData.match_date
      };
    } else {
      newObj = {
        isactive: null,
        porziogstprofileid: this.props.modalAgNode.porziogstprofileid,
        npinumber: selectedReferenceData.NPI_ID,
        source_file: selectedReferenceData.SOURCE_FILE,
        firstname: selectedReferenceData.FIRST_NAME,
        middlename: selectedReferenceData.MIDDLE_NAME,
        lastname: selectedReferenceData.LAST_NAME,
        suffix: selectedReferenceData.SUFFIX,
        prmry_txnmy_code: selectedReferenceData.PRMRY_TXNMY_CODE,
        prmry_txnmy_prvdr_type: selectedReferenceData.PRMRY_TXNMY_PRVDR_TYPE,
        statelicense: selectedReferenceData.STATE_1,
        statelicensenum: selectedReferenceData.LICENSE_1,
        match_date: selectedReferenceData.match_date
      };
    }
    this.state.agGridUtils.addNewRow(newObj);
    updatedRefData.push(newObj);

    this.setState({
      updatedRefData: updatedRefData
    });
  }

  updateNppesGrid = (selectedReferenceData) => {
    let updatedNppesRefData = this.state.updatedNppesRefData;
    let newObj = {};
    if (this.state.selectedProfileCategory === PROFILE_CATEGORY.ORGANIZATION) {
      newObj = {
        isactive: null,
        porziogstprofileid: this.props.modalAgNode.porziogstprofileid,
        source_file: selectedReferenceData.SOURCE_FILE,
        th_year: selectedReferenceData.TH_YEAR,
        npiid: selectedReferenceData.NPI_ID,
        organization_name: selectedReferenceData.HOSPITAL_NAME,
        street_address: selectedReferenceData.Street_Address,
        city: selectedReferenceData.City,
        state: selectedReferenceData.State,
        match_date: selectedReferenceData.match_date,
        PO_BOX: selectedReferenceData.Zip_Code,
        country: selectedReferenceData.Provider_Business_Practice_Location_Address_Country_Code_If_outside_US
      };
    } else {
      newObj = {
        isactive: null,
        porziogstprofileid: this.props.modalAgNode.porziogstprofileid,
        npinumber: selectedReferenceData.NPI_ID,
        source_file: selectedReferenceData.SOURCE_FILE,
        firstname: selectedReferenceData.FIRST_NAME,
        middlename: selectedReferenceData.MIDDLE_NAME,
        lastname: selectedReferenceData.LAST_NAME,
        suffix: selectedReferenceData.SUFFIX,
        prmry_txnmy_code: selectedReferenceData.PRMRY_TXNMY_CODE,
        prmry_txnmy_prvdr_type: selectedReferenceData.PRMRY_TXNMY_PRVDR_TYPE,
        statelicense: selectedReferenceData.STATE_1,
        statelicensenum: selectedReferenceData.LICENSE_1,
        match_date: selectedReferenceData.match_date,
        state_2: selectedReferenceData.Provider_License_Number_State_Code_2,
        state_3: selectedReferenceData.Provider_License_Number_State_Code_3,
        state_4: selectedReferenceData.Provider_License_Number_State_Code_4,
        state_5: selectedReferenceData.Provider_License_Number_State_Code_5,
        license_2: selectedReferenceData.Provider_License_Number_2,
        license_3: selectedReferenceData.Provider_License_Number_3,
        license_4: selectedReferenceData.Provider_License_Number_4,
        license_5: selectedReferenceData.Provider_License_Number_5,
        credentials: selectedReferenceData.Provider_Credential_Text,
        street_address: selectedReferenceData.Provider_First_Line_Business_Practice_Location_Address,
        city: selectedReferenceData.Provider_Business_Practice_Location_Address_City_Name,
        po_box: selectedReferenceData.Provider_Business_Practice_Location_Address_Postal_Code,
        country: selectedReferenceData.Provider_Business_Practice_Location_Address_Country_Code_If_outside_US,
        state: selectedReferenceData.state,
      };
    }
    this.state.agGridUtilsNppes.addNewRow(newObj);
    updatedNppesRefData.push(newObj);

    this.setState({
      updatedNppesRefData: updatedNppesRefData
    });
  }

  handleSubmitClick = (_isIndividualProfileCategory, selectedReferenceData, _isNppes) => {
    this.setState({
      showSearchReferenceDataDialog: false,
      isIndividualProfileCategory: _isIndividualProfileCategory,
      //updatedData: _selectedReferenceData
    }, () => {
      !_isNppes ? this.updateGrid(selectedReferenceData) : this.updateNppesGrid(selectedReferenceData);
    });
  }

  handleSearchClick = (_isNppes = false) => {
    this.setState({ showSearchReferenceDataDialog: true, isNppes: _isNppes });
  }

  /** 2/4 Required in Parent */
  isDirtyCallback = () => {//TODO : need to check if unsaved
    return false;
    // do any additional checkings if needed
    // if (this.fPropsDynamic) {
    //   return this.fPropsDynamic.dirty;
    // } else {
    //   return false;
    // }
  }

  /** 3/4 Required in Parent */
  resetCallback = () => {
    // if (this.fPropsDynamic) {
    //   this.fPropsDynamic.resetForm();
    // }
    // do any additional resetting if needed
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    this.setState({ searchResult: ResultStatus.SAVING });
    let oReturnSubject = new ReplaySubject();

    //Delete ref data
    let isDeleted = true;
    if (DataService.hasElements(this.refDataToDelete)) {
      for (let data of this.refDataToDelete) {
        if (data.isactive) {
          ReferenceDataService.deleteMatchedProfile(this.context.user.tenantId, data.porziogstprofileid)
            .subscribe(
              (_successResult) => {
                isDeleted = isDeleted && _successResult;
                Refresh =  this.fetchData();
              },
              (_errorResult) => {
                console.error(_errorResult);
              }
            );
        }
      }
      if (isDeleted) {
        oReturnSubject.next(true);
      } else {
        oReturnSubject.next("delete_error");
      }
    }

    //Delete nppes data
    let isDeletedNppes = true;
    if (DataService.hasElements(this.nppesDataToDelete)) {
      for (let data of this.nppesDataToDelete) {
        if (data.isactive) {
          ReferenceDataService.deleteMatchedProfileNppes(this.context.user.tenantId, data.porziogstprofileid)
            .subscribe(
              (_successResult) => {
                isDeletedNppes = isDeletedNppes && _successResult;
              },
              (_errorResult) => {
                console.error(_errorResult);
              }
            );
        }
      }
      if (isDeletedNppes) {
        oReturnSubject.next(true);
      } else {
        oReturnSubject.next("delete_error");
      }
    }

    //Save new row data
    let isSaved = true;
    this.state.updatedRefData.map(s => {
      ReferenceDataService.saveReferenceDataManually(this.context.user.tenantId, this.state.isIndividualProfileCategory,
        s, this.state.selectedPorzioGSTProfileId)
        .subscribe(
          (_successResult) => {
            isSaved = isSaved && _successResult;
            //Refresh = this.fetchData();
          },
          (_errorResult) => {
            console.error(_errorResult);
          }
        );
      if (isSaved) {
        ToastService.showSuccess("Reference data saved successfully");

        //Additional step to delete data from Profie_Xreference table only if previous status 'Reviewed'
        if (this.props.profileStatus && this.props.profileStatus === 'REVIEWED') {
          ReferenceDataService.changeProfileFromReviewedToUnmatch(this.context.user.tenantId, this.state.selectedPorzioGSTProfileId)
            .subscribe(
              (_successResult) => {
                console.log(_successResult);
              },
              (_errorResult) => {
                console.log(_errorResult);
              }
            )
        }
        oReturnSubject.next(true);
      } else {
        ToastService.showError("Error occured while saving");
        oReturnSubject.next("save_error");
      }
    });

    //Save new nppes data
    let isNppesSaved = true;
    this.state.updatedNppesRefData.map(s => {
      ReferenceDataService.saveNppesReferenceDataManually(this.context.user.tenantId, this.state.isIndividualProfileCategory,
        s, this.state.selectedPorzioGSTProfileId)
        .subscribe(
          (_successResult) => {
            isNppesSaved = isNppesSaved && _successResult;
           // Refresh = this.fetchData();
          },
          (_errorResult) => {
            console.error(_errorResult);
          }
        );
      if (isNppesSaved) {
        ToastService.showSuccess("Reference data saved successfully");

        //Additional step to delete data from Profie_Xreference table only if previous status 'Reviewed'
        if (this.props.profileStatus && this.props.profileStatus === 'REVIEWED') {
          ReferenceDataService.changeProfileFromReviewedToUnmatch(this.context.user.tenantId, this.state.selectedPorzioGSTProfileId)
            .subscribe(
              (_successResult) => {
                console.log(_successResult);
              },
              (_errorResult) => {
                console.log(_errorResult);
              }
            )
        }
        oReturnSubject.next(true);

      } else {
        ToastService.showError("Error occured while saving");
        oReturnSubject.next("save_error");
      }
    });
    return oReturnSubject.asObservable();
  }

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({ modalAgNode: node, showProfileDetailDialog: true, inputAction: CrudAction.UPDATE });
  }

  handleDeleteClick = (_isNppes) => {
    this.setState({
      openDeleteConfirm: true,
      nppesAction: _isNppes
    });
  }

  handleDeleteConfirm = (_isNppes) => {
    this.setState({ openDeleteConfirm: false, selectedProfiles: [] });
    if (!this.state.nppesAction) {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          this.refDataToDelete.push(rowNode.data);
          this.state.agGridUtils.gridApi.applyTransaction({ remove: [rowNode.data] });
        }
      });
    } else {
      this.state.agGridUtilsNppes.gridApi.forEachNode((rowNode) => {
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          this.nppesDataToDelete.push(rowNode.data);
          this.state.agGridUtilsNppes.gridApi.applyTransaction({ remove: [rowNode.data] });
        }
      });
    }
  }

  toggleSubmitButton = (_cellRef, _data) => {
    let hasSelected = false;
    let selectedProfiles = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedProfiles.push(rowNode.data);
        }
      });
    }


    this.setState({
      selectedProfiles: selectedProfiles,
      isReadOnly: !hasSelected,
      nppesAction: false,
    });
  }

  toggleSubmitButtonForNppes = (_cellRef, _data) => {
    let hasSelected = false;
    let selectedProfilesNppes = [];
    if (this.state.agGridBulkEditUtilNppes.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtilsNppes.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedProfilesNppes.push(rowNode.data);
        }
      });
    }
    this.setState({
      selectedProfilesNppes: selectedProfilesNppes,
      isReadOnly: !hasSelected,
      nppesAction: true
    });
  }

  isEnabled = (_cellRef, data) => {
    console.log(data);
  }

  TAB_PERMISSIONS = RolePermissionService.PROFILE_REFERENCE_DATA;
  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this;
    const componentType = "Reference Data";
    
    if (this.TAB_PERMISSIONS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    } else {
      const cannotDelete = this.TAB_PERMISSIONS.cannotDelete || this.props.isReadOnly || this.state.selectedProfiles.length === 0;
      const cannotDeleteNppes = this.TAB_PERMISSIONS.cannotDelete || this.props.isReadOnly || this.state.selectedProfilesNppes.length === 0;
      this.state.agGridUtils.setEditingMode(this.props.isReadOnly || this.TAB_PERMISSIONS.cannotEdit);

      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle" />
              <DialogContent>
                <React.Fragment key={"addressFragment"}>
                  <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }}>
                    <div id="MainRoleGrid">
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8, flexGrow: 1 }}></Typography>
                        {LayoutService.getIconButton(cannotDelete, cannotDelete ? MatIconService.DELETE : MatIconService.DELETE_RED, "Delete", () => {
                          this.handleDeleteClick(false)
                        }, "inherit")}
                        {LayoutService.getIconButton(this.props.isReadOnly || this.TAB_PERMISSIONS.cannotCreate, MatIconService.ADD_CIRCLE_OUTLINE, "Add", () => {
                          this.handleSearchClick(false)
                        }, "inherit")}
                      </Grid>
                      <div {...LayoutService.getAgGridStyles(520)}>
                        <AgGridReact
                          rowData={this.state.data}
                          columnDefs={ReferenceDataService.getColumnDefs(this, false)}
                          frameworkComponents={this.state.agGridUtils.frameworkComponents}
                          suppressClickEdit={true}
                          pagination={true}
                          paginationPageSize={50}
                          suppressRowClickSelection={true}
                          rowSelection="multiple"
                          gridOptions={{
                            headerHeight: 70,
                            context: { componentParent: this },
                            suppressContextMenu: true,
                            ...AgGridColumnExt.getGridOptions(56)
                          }}
                          onGridReady={(params) => {
                            this.state.agGridUtils.setGridParams(params, true);
                            this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                          }}
                        />
                      </div>
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8, flexGrow: 1 }}></Typography>
                        {LayoutService.getIconButton(cannotDeleteNppes, cannotDeleteNppes ? MatIconService.DELETE : MatIconService.DELETE_RED, "Delete", () => {
                          this.handleDeleteClick(true)
                        }, "inherit")}
                        {LayoutService.getIconButton(this.props.isReadOnly || this.TAB_PERMISSIONS.cannotCreate, MatIconService.ADD_CIRCLE_OUTLINE, "Add", () => {
                          this.handleSearchClick(true)
                        }, "inherit")}
                      </Grid>
                      <div {...LayoutService.getAgGridStyles(520)}>
                        <AgGridReact
                          rowData={this.state.nppesData}
                          columnDefs={ReferenceDataService.getColumnDefs(this, true)}
                          frameworkComponents={this.state.agGridUtilsNppes.frameworkComponents}
                          suppressClickEdit={true}
                          pagination={true}
                          paginationPageSize={50}
                          suppressRowClickSelection={true}
                          rowSelection="multiple"
                          gridOptions={{
                            headerHeight: 70,
                            context: { componentParent: this },
                            suppressContextMenu: true,
                            ...AgGridColumnExt.getGridOptions(56)
                          }}
                          onGridReady={(params) => {
                            this.state.agGridUtilsNppes.setGridParams(params, true);
                            this.state.agGridUtilsNppes.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Divider />
                </React.Fragment>
                <ReferenceDataSearchDialogComponent open={this.state.showSearchReferenceDataDialog || false}
                  onClose={(_reloadRequired) => {
                    this.setState({ showSearchReferenceDataDialog: false });
                    if (_reloadRequired) {
                      this.fetchData();
                    }
                  }}
                  isNppes={this.state.isNppes}
                  selectedProfile={this.props.selectedProfile}
                  selectedProfileCategory={this.state.selectedProfileCategory}
                  selectedPorzioGSTProfileId={this.state.selectedPorzioGSTProfileId}
                  profileCategoryList={LookupService._PROFILE_CATEGORIES}
                  onSubmitClick={(_isIndividualProfileCategory, _selectedReferenceData, _isNppes) => {
                    this.handleSubmitClick(_isIndividualProfileCategory, _selectedReferenceData, _isNppes);
                  }}
                />
                {/* Show Profile-Detail Dialog */}
                <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                  open={this.state.showProfileDetailDialog || false}
                  onClose={() => {
                    this.setState({ showProfileDetailDialog: false });
                    this.fetchData(true);
                  }} />
              </DialogContent>

              {/* Delete dialog */}
              {this.state.openDeleteConfirm ?
                <Dialog open={this.state.openDeleteConfirm || false} onClose={() => this.setState({ openDeleteConfirm: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">
                    <AppBar position="static">
                      <Toolbar>
                        <Typography variant="h6" className={classes.root}>Delete Confirmation</Typography>
                        {LayoutService.getIconButton(false, MatIconService.OK, "Delete", this.handleDeleteConfirm, "inherit", "keyActionDelete")}
                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => this.setState({ openDeleteConfirm: false }), "secondary", "keyActionCancel")}
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">Are you sure you want to delete the selected reference data for Profile</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">{this.state.selectedPorzioGSTProfileId}?</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
                : <></>}
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading Reference Data"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }

  }
}

/** HOC */
export default withStyles(MatClassService)(ReferenceDataComponent);
