// import React from "react";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
//import { blue, lightBlue, pink, indigo, grey, yellow, green, lightGreen } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { pink, teal } from "@material-ui/core/colors";

const DARK = "dark";
const LIGHT = "light";
export default class MatThemeService {
  // TODO: get UserPreference from the OS (refer below), now i am facing a hook issue
  // static prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // https://material-ui.com/customization/palette/
  static inputVariant = "standard"; // standard | outlined
  static userSystemPrefersDarkMode = false;
  static currentMode = DARK;
  static setMode(_mode = DARK | LIGHT) {
    this.currentMode = _mode;
  }
  static toggleMode() {
    this.currentMode = this.currentMode === DARK ? LIGHT : DARK;
  }
  static setDarkMode() {
    this.setMode(DARK);
  }
  static setLightMode() {
    this.setMode(LIGHT);
  }

  static currentThemeIndex = 0;
  // static themeColors = ["#3F3F49", "#3A3D4D", "#6200EE", "#724067", "#03823F", "#2A6343", "#2A4163"];
  static themeColors = ["#005589", "#6f7c7c", "#00acbb", "#6dbe4b"]; // Porzio Color Pallette per Brand Guideline PDF
  static cycleTheme() {
    if (this.currentThemeIndex >= this.themeColors.length - 1) {
      this.currentThemeIndex = 0;
    } else {
      this.currentThemeIndex++;
    }
    return this.appTheme();
  }

  static isDarkMode() {
    return this.currentMode === DARK;
  }
  static isLightMode() {
    return this.currentMode === LIGHT;
  }

  static borderStyle = 'solid 1px rgba(0,0,0,.2)';

  static getTopBarColor() {
    if (this.isDarkMode()) {
      return "default";
    } else {
      return "primary";
    }
  }

  static getTheme() {
    return this.appTheme();
  }

  static getCellHeaderBG = () => {
    return MatThemeService.isDarkMode() ? "#000" : "#DBDBDB";
  }

  static getControllerBG = () => {
    return MatThemeService.isDarkMode() ? "#2C2C2E" : "#F5F5F5";
  }
  static getAlternatingBG = (_rowIndex) => {
    if (this.isDarkMode()) { return _rowIndex % 2 === 0 ? "#1C1C1E" : "#2C2C2E"; }
    else { return _rowIndex % 2 === 0 ? "#FFFFFF" : "#F5F5F5"; }
  }
  static getReverseAlternatingBG = (_rowIndex) => {
    if (this.isDarkMode()) { return _rowIndex % 2 === 0 ? "#2C2C2E" : "#1C1C1E"; }
    else { return _rowIndex % 2 === 0 ? "#F5F5F5" : "#FFFFFF"; }
  }

  static getBaseBG = () => {
    return MatThemeService.isDarkMode() ? "#303030" : "#e7e8ea";
  }

  static getTabBG = () => {
    return MatThemeService.isDarkMode() ? "#2C2C2E" : "#FFF";
  }

  static appTheme = () => {
    return createMuiTheme({
      // Theme
      palette: {
        type: this.currentMode === DARK || this.currentMode === LIGHT ? this.currentMode : this.userSystemPrefersDarkMode ? DARK : LIGHT,
        // primary: indigo,
        primary: {
          light: "#7986cb",
          main: this.themeColors[this.currentThemeIndex],
          dark: "#303f9f",
          contrastText: "#fff",
        },
        secondary: {
          light: teal[100],
          main: teal[500],
          dark: teal[900],
        },
        background: {
          paper: this.isDarkMode() ? "#424242" : "#fff",
          default: this.isDarkMode() ? "#303030" : "#e7e8ea", //#f5f5f6"
        },
        text: {
          disabled: this.isDarkMode() ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, .87)', // for better visibility         rgba(0, 0, 0, 0.38)
          primary: this.isDarkMode() ? '#fff' : 'rgba(0, 0, 0, .97)', // for better visibility                             rgba(0, 0, 0, 0.87)
          //   secondary: this.isDarkMode() ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.77)', // for better visibility       rgba(0, 0, 0, 0.54)
          //   hint: this.isDarkMode() ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0.57)', // for better visibility      rgba(0, 0, 0, 0.38)
        }
      },
      status: {
        danger: "orange",
      },

      //Overrides
      overrides: {
        MuiFormLabel: {
          root: {
            color: this.isDarkMode() ? 'rgba(255, 255, 255, .54)' : 'rgba(0, 0, 0, .54)',
            "&$disabled": { // increase the specificity for the pseudo class
              color: this.isDarkMode() ? 'rgba(255, 255, 255, .54)' : "rgba(0, 0, 0, .54)"
            }
          },
        },
        MuiFormControl: {
          root: {
            margin: 8,
          },
        },
        MuiDialogTitle: {
          root: {
            padding: 0,
          },
        },
        MuiDialogContent: {
          root: {
            padding: 0,
          },
        },
        MuiListItem: {
          // For ListItem, change this to MuiListItem
          root: {
            "&$selected": {
              color: "#FFF",
              // this is to refer to the prop provided by M-UI
              backgroundColor: this.themeColors[this.currentThemeIndex], // updated backgroundColor
            },
          },
        },
      },

      //
      props: {
        MuiButtonBase: {
          TouchRippleProps: {
            style: { color: this.isDarkMode() ? "#FFF" : this.themeColors[this.currentThemeIndex] },
          },
        },
      },
    });
  }
}

// const theme = {
//   "palette": {
//     "common": { "black": "#000", "white": "#fff" },
//     "background": { "paper": "#fff", "default": "#fafafa" },
//     "primary": { LIGHT: "#7986cb", "main": "#3f51b5", DARK: "#303f9f", "contrastText": "#fff" },
//     "secondary": { LIGHT: "#ff4081", "main": "#f50057", DARK: "#c51162", "contrastText": "#fff" },
//     "error": { LIGHT: "#e57373", "main": "#f44336", DARK: "#d32f2f", "contrastText": "#fff" },
//     "text": { "primary": "rgba(0, 0, 0, 0.87)", "secondary": "rgba(0, 0, 0, 0.54)", "disabled": "rgba(0, 0, 0, 0.38)", "hint": "rgba(0, 0, 0, 0.38)" }
//   }
// }

/* !!!REFERENCE!!!
https://material-ui.com/customization/default-theme/
breakpoints: Object
keys: Array(5)
0: "xs"
1: "sm"
2: "md"
3: "lg"
4: "xl"
values: Object
xs: 0
sm: 600
md: 960
lg: 1280
xl: 1920
up: f d()
down: f down()
between: f p()
only: f only()
width: f width()
direction: "ltr"
mixins: Object
gutters: f gutters()
toolbar: Object
minHeight: 56
@media (min-width:0px) and (orientation: landscape): Object
minHeight: 48
@media (min-width:600px): Object
minHeight: 64
overrides: Object
palette: Object
common: Object
black:
#000
white:
#fff
type: DARK
primary: Object
light:
#7986cb
main:
#3f51b5
dark:
#303f9f
contrastText:
#fff
secondary: Object
light:
#ff4081
main:
#f50057
dark:
#c51162
contrastText:
#fff
error: Object
light:
#e57373
main:
#f44336
dark:
#d32f2f
contrastText:
#fff
warning: Object
light:
#ffb74d
main:
#ff9800
dark:
#f57c00
contrastText:
rgba(0, 0, 0, 0.87)
info: Object
light:
#64b5f6
main:
#2196f3
dark:
#1976d2
contrastText:
#fff
success: Object
light:
#81c784
main:
#4caf50
dark:
#388e3c
contrastText:
rgba(0, 0, 0, 0.87)
grey: Object
50:
#fafafa
100:
#f5f5f5
200:
#eeeeee
300:
#e0e0e0
400:
#bdbdbd
500:
#9e9e9e
600:
#757575
700:
#616161
800:
#424242
900:
#212121
A100:
#d5d5d5
A200:
#aaaaaa
A400:
#303030
A700:
#616161
contrastThreshold: 3
getContrastText: f V()
augmentColor: f E()
tonalOffset: 0.2
text: Object
primary:
#fff
secondary:
rgba(255, 255, 255, 0.7)
disabled:
rgba(255, 255, 255, 0.5)
hint:
rgba(255, 255, 255, 0.5)
icon:
rgba(255, 255, 255, 0.5)
divider:
rgba(255, 255, 255, 0.12)
background: Object
paper:
#424242
default:
#303030
action: Object
active:
#fff
hover:
rgba(255, 255, 255, 0.08)
hoverOpacity: 0.08
selected:
rgba(255, 255, 255, 0.16)
selectedOpacity: 0.16
disabled:
rgba(255, 255, 255, 0.3)
disabledBackground:
rgba(255, 255, 255, 0.12)
disabledOpacity: 0.38
focus:
rgba(255, 255, 255, 0.12)
focusOpacity: 0.12
activatedOpacity: 0.24
props: Object
shadows: Array(25)
0: "none"
1: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
2: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
3: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)"
4: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
5: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
6: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)"
7: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)"
8: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
9: "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)"
10: "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)"
11: "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)"
12: "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)"
13: "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)"
14: "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)"
15: "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)"
16: "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)"
17: "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)"
18: "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)"
19: "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)"
20: "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)"
21: "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)"
22: "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)"
23: "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)"
24: "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
typography: Object
htmlFontSize: 16
pxToRem: f ()
round: f w()
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontSize: 14
fontWeightLight: 300
fontWeightRegular: 400
fontWeightMedium: 500
fontWeightBold: 700
h1: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 300
fontSize: "6rem"
lineHeight: 1.167
letterSpacing: "-0.01562em"
h2: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 300
fontSize: "3.75rem"
lineHeight: 1.2
letterSpacing: "-0.00833em"
h3: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "3rem"
lineHeight: 1.167
letterSpacing: "0em"
h4: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "2.125rem"
lineHeight: 1.235
letterSpacing: "0.00735em"
h5: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "1.5rem"
lineHeight: 1.334
letterSpacing: "0em"
h6: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 500
fontSize: "1.25rem"
lineHeight: 1.6
letterSpacing: "0.0075em"
subtitle1: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "1rem"
lineHeight: 1.75
letterSpacing: "0.00938em"
subtitle2: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 500
fontSize: "0.875rem"
lineHeight: 1.57
letterSpacing: "0.00714em"
body1: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "1rem"
lineHeight: 1.5
letterSpacing: "0.00938em"
body2: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "0.875rem"
lineHeight: 1.43
letterSpacing: "0.01071em"
button: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 500
fontSize: "0.875rem"
lineHeight: 1.75
letterSpacing: "0.02857em"
textTransform: "uppercase"
caption: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "0.75rem"
lineHeight: 1.66
letterSpacing: "0.03333em"
overline: Object
fontFamily: ""Roboto", "Helvetica", "Arial", sans-serif"
fontWeight: 400
fontSize: "0.75rem"
lineHeight: 2.66
letterSpacing: "0.08333em"
textTransform: "uppercase"
spacing: f e()
mui: true
shape: Object
borderRadius: 4
transitions: Object
easing: Object
easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)"
easeOut: "cubic-bezier(0.0, 0, 0.2, 1)"
easeIn: "cubic-bezier(0.4, 0, 1, 1)"
sharp: "cubic-bezier(0.4, 0, 0.6, 1)"
duration: Object
shortest: 150
shorter: 200
short: 250
standard: 300
complex: 375
enteringScreen: 225
leavingScreen: 195
create: f create()
getAutoHeightDuration: f getAutoHeightDuration()
zIndex: Object
mobileStepper: 1000
speedDial: 1050
appBar: 1100
drawer: 1200
modal: 1300
snackbar: 1400
tooltip: 1500

*/
