import React from "react";
import {  AppBar,Typography, Button,Dialog,DialogTitle, Grid, Toolbar,IconButton ,withStyles} from "@material-ui/core";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
class PageErrorComponent extends React.Component {
  
  state = {
    modalShow : true
  }
  handleHide=()=>{
      this.setState({modalShow:false});
      this.props.onClose();      
  }
  render() {
    const { classes, small, label} = this.props;
    return (
      <React.Fragment>
         <Dialog
              open={this.state.modalShow || false}
              onClose={()=>this.handleHide()}
              scroll={true ? "paper" : "body"}
              maxWidth="md"
            >
          <DialogTitle disableTypography id="dialogTitle">
                <AppBar position="static">
                <Toolbar variant="dense">
                <Typography variant="h6" className={classes.root}>
                </Typography>
                    <IconButton color="secondary" onClick={() => { this.handleHide(false); }}> {MatIconService.CANCEL} </IconButton>
                  </Toolbar>
                </AppBar>  
          </DialogTitle> 
          <Grid direction="column" container justify="center" alignItems="center" style={{ minHeight: "50vh" }}>
            <Typography variant={small ? "h6" : "h3"} className={classes.pageErrorHeader}>{label}</Typography>
            <Button variant="contained" color="secondary" className={classes.buttonError} onClick={this.props.onRetry}>Retry</Button>
          </Grid>
        </Dialog> 
      </React.Fragment> 
    );
  }
}
export default withStyles(MatClassService)(PageErrorComponent);
