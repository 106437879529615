import React from "react";
import { useLocation } from 'react-router-dom'
import { of, combineLatest } from "rxjs";
import { DialogTitle, DialogContent, Box, Grid, Tooltip, IconButton, InputBase } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import AgGridEditButtonCellRendererComponent from "../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import ProfileDetailCellRendererComponent from "../../profile-center/profile-detail/profileDetailCellRendererComponent";
import { AgGridUtil } from "../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import { CrudAction, ENTITY_TYPE, ResultStatus } from "../../../shared/types/enums";
import LayoutService from "../../../shared/services/layoutService";
import LookupService from "../../../shared/services/lookupService";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import ProfileDetailDialogComponent from "../../profile-center/profile-detail/profileDetailDialogComponent";
import ProfileSearchService from "./profileSearchService";

class ProfileSearchResultComponent extends React.Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            data: [],
            fetchResult: ResultStatus.NOT_LOADED,
            isEditing: true,
            porzioSearch: "",
            agGridUtils: new AgGridUtil("lastname", {
                inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
                profileDetailCellRendererComponent: ProfileDetailCellRendererComponent,
            }),
        };
    }
    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }
    componentDidMount() {
        if (this.state.isEditing) {
            this.setState({ fetchResult: ResultStatus.LOADING });
            this.fetchData();
        }
    }

    onDownloadClick = () => {
        let api = this.gridApi, params = this.getParams();
        api.exportDataAsExcel(params);
    }

    getParams() {
        return {
            // allColumns: true,
            columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
            fileName: this.getFileName()
        };
    }

    getFileName() {
        return `Profiles ${new Date().toDateString()}`;
    }

    /** API Fetch */
    fetchData = () => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
        this.apiSubscriptions.add(
            combineLatest([
                ProfileSearchService.searchOBS(this.context.user.tenantId, this.props.searchValue),
                LookupService.getFormattedCountriesAsOBS(this.context, null),
            ]).subscribe(
                // success
                ([_data, _countryList]) => {
                    this.setState(
                        {
                            data: DataService.hasElements(_data) ? _data.map(r => ({ ...r, countryName: _countryList.find(c => c.id === r.country)?.value, })) : [],
                        },
                        // change the state after all the above are assigned
                        () => {
                            //this.state.agGridUtils.sizeColumnsToFit();
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    // called on row-cell click
    methodFromParent = (row_col, node) => {
        this.setState({ modalAgNode: node, showProfileDetailDialog: true, inputAction: CrudAction.UPDATE });
    };

    render() {
        const { classes } = this.props;
        //this.props.tabConfig.ref = this; // 1/4) required by parent component
        const componentType = "Profiles";

        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
            case ResultStatus.SAVING:
                return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <React.Fragment>
                        <DialogTitle disableTypography id="dialogTitle" />
                        <DialogContent>
                            <Box style={{ padding: "8px" }}>
                                <Grid className={classes.flexNoShrink} container justify="flex-end">
                                    <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                                        <IconButton onClick={this.onDownloadClick} color="secondary">
                                            {MatIconService.DOWNLOAD}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <div {...LayoutService.getAgGridStyles(320)}>
                                    <AgGridReact
                                        pagination={true}
                                        paginationPageSize={100}
                                        rowData={this.state.data}
                                        columnDefs={ProfileSearchService.getColumnDefs(this)}
                                        frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                        suppressClickEdit={true}

                                        gridOptions={{
                                            context: { componentParent: this },
                                            ...AgGridColumnExt.getGridOptions(40),
                                            ...this.state.agGridUtils.bindInlineEditEvents(),
                                        }}
                                        onGridReady={(params) => {
                                            this.gridApi = params.api;
                                            this.gridColumnApi = params.columnApi;
                                            this.state.agGridUtils.setGridParams(params, false);
                                        }}>
                                    </AgGridReact>
                                </div>
                            </Box>
                        </DialogContent>
                        {/* Show Profile-Detail Dialog */}
                        <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                            open={this.state.showProfileDetailDialog || false}
                            onClose={() => {
                                this.setState({ showProfileDetailDialog: false });
                            }}
                            profileStatus={this.state.unmatchedChecked ? "UNMATCHED" : "REVIEWED"}
                            closeParentCallback={() => {
                                this.setState({ showProfileDetailDialog: false });
                            }}
                        />
                    </React.Fragment>
                );

            case ResultStatus.ERROR:
            default:
                return (
                    <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
                        onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
                );
        }
    }
}

/** HOC */
export default LayoutService.getHocComponenet(ProfileSearchResultComponent);
