import React, { useEffect } from 'react';
import IzendaIntegrate from './config/izenda.integrate'

const ReportingExport = (props) => {
    const izIntegrate = new IzendaIntegrate();

    useEffect(() => {
        const dom = document.getElementById('reporting-export-reportpart');
        const reportPartId = props.match.params.id;
        console.log(dom);
        console.log(reportPartId);
        izIntegrate.RenderExport(dom, reportPartId);
        // On unmount
        return () => {
            izIntegrate.DestroyDom(dom);
        }
    });

    /** 
    In the case that we cannot have this component available in the top of the router
    Then we need to dynamically remove the side & header panel from the dom.
    Or we can just attach to the root div 
    */
    const removePorzioSidebar = () => {
        let rootDom = document.getElementById('root');
        let children = Array.from(rootDom.children);
        children.map(child => {child.style.display = 'none';});
        return rootDom;
    } 

    return (
        <div className='export' id='reporting-export-reportpart'></div>
    );
}

export default ReportingExport;
