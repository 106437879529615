import React, { Component } from "react";
import { Input } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns/esm";
import MatThemeService from "../../../services/theme/matThemeService";
import { AgGridBulkEditUtil } from "../bluk-edit/agGridBulkEditUtil";

export default class AgGridErroredDatePickerCellRenderer extends Component {

    constructor(props) {
        super(props);

        const _errorOrWarningConfig = this.props.getErrorOrWarningInfoCallback(this);
        this.state = {
            //|---> Mandatory Fields
            isEditing: false,
            isParentReadOnly: this.props.isParentReadOnly,
            isColumnReadOnly: this.props.isColumnReadOnly,
            value: this.props.node.data[this.props.column.colDef.field],
            parentRef: this.props.componentThisRef,
            errorOrWarningConfig: _errorOrWarningConfig,
            hasErrorOrWarning: AgGridBulkEditUtil.hasErrorOrWarning(this.props.componentThisRef.state.agGridBulkEditUtil, this.props, _errorOrWarningConfig),
            //<---|

            format: this.props.format || "yyyy-MM-dd",
        };
    }

    render() {

        return AgGridBulkEditUtil.getRenderer(this,
            // formattedValue callback
            () => { return this.state.value ? format(new Date(this.state.value), this.state.format) : ""; },
            // editor callback
            () => {
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker onChange={(e) => { AgGridBulkEditUtil.applyChange(this, e); }} value={this.state.value}
                            open={this.state.isEditing} onClose={() => { AgGridBulkEditUtil.handleClose(this); }}
                            autoOk={true} format={this.state.format} size="small" variant="dialog" inputVariant={MatThemeService.inputVariant} clearable={false}
                            TextFieldComponent={(iProps) => {
                                return <Input type="text" inputProps={{ style: { fontSize: 14, fontWeight: 400, lineHeight: 1.9 } }} onClick={iProps.onClick} value={iProps.value} onChange={iProps.onChange} />
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            }

        );
    }

    //---
}