import React, { Component } from "react";
import { Grid, Paper, Popper, Tooltip, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import MomentService from "../../shared/services/momentService";
import MatThemeService from "../../shared/services/theme/matThemeService";
import ApplicationService from "../../shared/services/applicationService";
import { MatIconService } from "../../shared/services/theme/matIconService";

const footerStyleLight = {
  backgroundColor: "#005589",
  fontSize: "12px",
  color: "rgba(255,255,255,.87)",
  //   borderTop: "1px solid #E7E7E7",
  textAlign: "right",
  padding: "5px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "30px",
  width: "100%",
};
const footerStyleDark = {
  backgroundColor: "#212121",
  fontSize: "12px",
  color: "rgba(255,255,255,.87)",
  //   borderTop: "1px solid #E7E7E7",
  textAlign: "right",
  padding: "5px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "30px",
  width: "100%",
};

const phantomStyle = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%"
};


class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      utcPopperAnchorEl: null,
      utcTime: MomentService.getUtcTime(),
    };
  }

  componentDidMount() {

  }

  utcTimeIntervalRef = null;
  startUtcCountDown = () => {
    this.setState({ utcTime: MomentService.getUtcTime() });

    this.utcTimeIntervalRef = setInterval(() => {
      this.setState({ utcTime: MomentService.getUtcTime() });
    }, 60000);
  }

  render() {
    const { classes } = this.props;
    return <div>
      <Popper style={{ zIndex: "5000" }} open={this.state.utcPopperAnchorEl ? true : false} anchorEl={this.state.utcPopperAnchorEl} placement='top'>
        <Paper style={{ backgroundColor: MatThemeService.isDarkMode() ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8)' }}>
          <Typography style={{
            paddingBlock: 8, paddingInline: 16,
            color: MatThemeService.isDarkMode() ? '#000' : '#FFF',
          }}>
            <span style={{ color: "rgb(168,168,168)" }}>UTC Time:</span> {this.state.utcTime}
          </Typography>
        </Paper>
      </Popper>

      {/* <div style={phantomStyle} /> */}
      <div style={MatThemeService.isDarkMode() ? footerStyleDark : footerStyleLight}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingLeft: 88, paddingRight: 16 }}>
          <Typography variant="body2">{ApplicationService.appVersionString}</Typography>
          <Typography variant="body2">{ApplicationService.copyrightRightsReservedString}</Typography>
          {/* Right */}
          <Grid item >
            <Grid container flexDirection="row" alignItems="center">
              <Tooltip title={this.state.utcPopperAnchorEl ? '' : `UTC Time: ${MomentService.getUtcTime()}`}>
                <div style={{ cursor: 'pointer', marginRight: 8 }}
                  onClick={(e) => {
                    const isPopperShowing = this.state.utcPopperAnchorEl ? true : false;
                    if (isPopperShowing) {
                      clearInterval(this.utcTimeIntervalRef);
                    } else {
                      this.startUtcCountDown();
                    }
                    this.setState({ utcPopperAnchorEl: isPopperShowing ? null : e.currentTarget })
                  }}>
                  {this.state.utcPopperAnchorEl ? MatIconService.CLOCK_SELECTED : MatIconService.CLOCK}
                </div>
              </Tooltip>
              <Tooltip title={MatThemeService.isDarkMode() ? 'Day mode' : 'Night mode'}>
                <div style={{ cursor: 'pointer', marginRight: 8 }} onClick={() => {
                  MatThemeService.toggleMode();
                  this.props.onThemeChange();
                }}>
                  {MatThemeService.isDarkMode() ? MatIconService.SUN : MatIconService.MOON}
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div >
  }


  //---
}
export default Footer;