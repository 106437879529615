import React, { Component } from "react";
import clsx from "clsx";
import { Typography, Divider, Grid, Tooltip, IconButton, MenuItem, Select } from "@material-ui/core";

import LayoutService from "../../../../shared/services/layoutService";
import MatSpecService from "../../../../shared/services/theme/matSpecService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";

class CompliancePageHeaderComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportSelectData: this.props.reportSelectData,
            yearList: this.props.yearList,
        }      
    }

    render() {
        const { small, classes, history, label, back, menu, search, reportComplianceSelect, yearComplianceSelect, countrySelect, importTemplate, uploadFile, save, add, addCircle, view, reload, ok, cancel, noDivider, noDividerSpacing, download } = this.props;
        const showButtons = back || menu || search || countrySelect || importTemplate || uploadFile || save || add || addCircle || view || reload || ok || cancel || download;

        console.log("this.props.reportSelectData", JSON.stringify(this.props.reportSelectData, null, 2));

        return (
            <React.Fragment>
                <Grid className={classes.flexNoShrink} container direction="column">
                    <Grid container direction="row" alignItems="center">
                        {back ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Back" placement="bottom" arrow>
                                <IconButton onClick={() => { history.goBack(); }}>{MatIconService.BACK}</IconButton>
                            </Tooltip>
                        ) : null}

                        <Typography variant={small ? "h6" : "h5"} className={classes.headerTitle}>
                            {label}
                        </Typography>

                        {reportComplianceSelect ? (
                            <>
                                <Typography>Report Name:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="reportComplianceSelectLabel"
                                    id="reportComplianceSelect"
                                    value={this.props.selectedReportId}
                                    onChange={this.props.onReportSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.state.reportSelectData.map((el) => (
                                        <MenuItem key={el.id} value={el.text}>
                                            {el.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {yearComplianceSelect ? (
                            <>
                                <Typography>Year:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="yearComplianceSelectLabel"
                                    id="yearComplianceSelect"
                                    value={this.props.selectedYearId}
                                    onChange={this.props.onYearSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.state.yearList.map((el) => (
                                        <MenuItem key={el.id} value={el.id}>
                                            {el.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {download ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                                <IconButton onClick={this.props.onDownloadClick} color="secondary">
                                    {MatIconService.DOWNLOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {add || addCircle ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add" placement="bottom" arrow>
                                <IconButton onClick={this.props.onAddClick} color="secondary">
                                    {addCircle ? MatIconService.ADD_CIRCLE_OUTLINE : MatIconService.ADD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {reload ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Reload" placement="bottom" arrow>
                                <IconButton onClick={this.props.onReloadClick} color="secondary">
                                    {MatIconService.RELOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Grid>
                    {noDivider || noDividerSpacing ? (
                        <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    ) : (
                            <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                        )}

                    {/* <Grid item xs={12} sm={12} md={6}><pre>{JSON.stringify(this.props, null, 2)}</pre></Grid>
                    <Grid item xs={12} sm={12} md={6}><pre>{JSON.stringify(this.state, null, 2)}</pre></Grid> */}


                </Grid>
            </React.Fragment>
        );
    }
}

export default LayoutService.getHocComponenet(CompliancePageHeaderComponent);
