import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, ReportCategory } from "../../../../shared/types/enums";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class StandardTemplatesService {
    static getTemplates(_tenantId, _roleId, _categoryId) {
        const _isViewable = true;
        const _isSubscribe = false;
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/${_tenantId}/${ReportCategory.STANDARD_TEMPLATES}/${_roleId}?pageNo=1&pageSize=1000&isViewable=${_isViewable}&isSubscribe=${_isSubscribe}`);
    }

    static getColumnDefs() {
        return [
            AgGridColumnExt.GET(false, true, "reportName", "").fixedWidth(70).headerClass("center-text").cellRenderer("reportLaunchAgGridRenderer", {
                isCellEnabledCallback: (_cellRef) => {
                    return true; //TODO: uncomment later
                    // return _cellRef.props.data.izendaReportId != "";
                }
            }).pivot().SET(),
            AgGridColumnExt.GET(false, true, "reportName", "Template Name").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
            AgGridColumnExt.GET(false, true, "templateYear", "Year").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
            AgGridColumnExt.GET(false, true, "jurisdiction", "Jurisdiction").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
            AgGridColumnExt.GET(false, true, "reportDescription", "Description").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
        ];
    }
}