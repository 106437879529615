import React from "react";
import { Typography, CircularProgress, Grid } from "@material-ui/core";
export default class PageLoadingComponent extends React.Component {
  render() {
    const { classes, small, minHeight } = this.props;
    return (
      <Grid direction="column" container justify="center" alignItems="center" style={{ minHeight: minHeight ? minHeight :"60vh" }}>
        <Typography variant={small ? "h6" : "h4"} className={classes.pageLoadingHeader}>
          {this.props.label ? this.props.label : "Loading"}
        </Typography>
        <CircularProgress thickness={small ? 3 : 3} size={small ? "3rem" : "9rem"} color="secondary" />
      </Grid>
    );
  }
}
