export default class ApplicationService {
  static applicationName = "Porzio Life Sciences";
  static appVersion = `${process.env.APP_VERSION}`;
  static appVersionString = `Porzio GST v${process.env.REACT_APP_VERSION}`;
  static appGstVersionString = `GST v${process.env.REACT_APP_VERSION}`;
  static OktaRequestContext = `${process.env.REACT_APP_OKTA_REQ_CONTEXT}`;
  static copyrightString = `© Copyright 2024 Porzio Life Sciences, LLC.`;
  static copyrightStringRLDatix = `Porzio Life Sciences, LLC is an RLDatix Company.`;
  static copyrightRightsReservedString = `© 2024 Porzio Life Sciences, LLC. All rights reserved. Porzio Life Sciences, LLC is an RLDatix Company.`;
}
