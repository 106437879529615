import React, { Component } from "react";
import { Box, Grid } from "@material-ui/core";

import LayoutService from "../../shared/services/layoutService";
import PowerBIDemoComponent from "./powerBIDemoComponent";

class PowerBIDemoContainerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPowerBI: this.props.hide ?? true,
        };
    }

    render() {

        return (
            // <Grid container spacing={1}>
            //     <Grid item xs={12} sm="auto">
                    <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }} >

                        {this.state.showPowerBI ?
                            <PowerBIDemoComponent />
                            : null}
                    </Box>
            //     </Grid>
            // </Grid>
        )
    }
}

// export default LayoutService.getHocComponenet(PowerBIDemoContainerComponent);
export default PowerBIDemoContainerComponent;


