import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { AppBar, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, ListItemIcon, Paper, Toolbar, Typography } from "@material-ui/core";
import { AuthContext } from "../../store/authProvider";
import { SubscriptionArray } from "../../services/dataService";
import LayoutService from "../../services/layoutService";
import PageLoadingComponent from "../page/pageLoadingComponent";
import { MatIconService } from "../../services/theme/matIconService";
import { ResultStatus, CrudAction } from "../../types/enums";
import DialogErrorFragmentComponent from "../page/dialogErrorFragmentComponent";
import FieldConfigurationService from "./fieldConfigurationService";
import ApiService from "../../services/apiService";
import { API_ENDPOINT } from "../../types/enums";
import ToastService from "../../services/toastService";

class FieldConfigurationComponent extends Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();


  constructor(props) {
    super(props);
    this.state = {
      left: [],
      right: [],
      checked: [],
      submitCreateWasClicked: false,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        FieldConfigurationService.getDataCenterAvailableFieldsAsOBS(this.context.user.tenantId, this.props.fileId, this.props.sourceId, this.props.entityId, this.context.user.userId, this.props.screenId),
        FieldConfigurationService.getDataCenterDisplayedFieldsAsOBS(this.context.user.tenantId, this.props.sourceId, this.props.entityId, this.context.user.userId, this.props.screenId),
      ]).subscribe(([_availableColumns, _displayedColumns,]) => {
        this.setState(
          {
            left: _availableColumns.map(el => ({
              id: el.fieldId,
              text: el.fieldAliasName,
              isRequired: false,
            })),
            right: _displayedColumns.map(el => ({
              id: el.fieldId,
              text: el.fieldAliasName,
              isRequired: el.isExpViewDefault,
            })),
          },
          () => {
            // change the state after all the above are assigned
            this.setState({ fetchResult: ResultStatus.LOADED });
          }
        );
      }
      )
    );
  }

  not = (a, b) => {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  intersection = (a, b) => {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  handleToggle = (value) => () => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (value.isRequired === false) {
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
    }

    this.setState({ checked: newChecked }, () => {
      console.log(this.state.checked)
    });
  };

  handleCheckedRight = () => {
    this.setState({
      right: this.state.right.concat(this.intersection(this.state.checked, this.state.left)),
      left: this.not(this.state.left, this.intersection(this.state.checked, this.state.left)),
      checked: this.not(this.state.checked, this.intersection(this.state.checked, this.state.left)),
    });
  };

  handleCheckedLeft = () => {
    this.setState({
      left: this.state.left.concat(this.intersection(this.state.checked, this.state.right)),
      right: this.not(this.state.right, this.intersection(this.state.checked, this.state.right)),
      checked: this.not(this.state.checked, this.intersection(this.state.checked, this.state.right)),
    });
  };

  customList = (items, _classes) => (
    // <Paper className={_classes.paper}>
    <Paper style={{ border: "0.5px solid black", overflow: "auto", height: 525, }}>
      <List dense component="div" role="list">
        {items.map((el) => {
          const labelId = `transfer-list-item-${el.id}-label`;

          return (
            <ListItem key={el.id} role="listitem" button onClick={this.handleToggle(el)}>
              <ListItemIcon>
                {
                  el.isRequired ?
                    <Checkbox checked={true} tabIndex={-1} disabled={el.isRequired} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                    :
                    <Checkbox checked={this.state.checked.map(chel => { return chel.id }).indexOf(el.id) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                }
              </ListItemIcon>
              <ListItemText id={labelId} primary={el.text} />
            </ListItem>
          );

        })}
        <ListItem />
      </List>
    </Paper>
  );

  handleSubmit = () => {
    this.setState({ fetchResult: ResultStatus.SAVING });
    var mappedObj = {
      selected: this.state.right.map(x => x.id).toString(),
      screenId: this.props.screenId,
      sourceId: this.props.sourceId,
      userId: this.context.user.userId
    }
    ApiService.postOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/SaveFields/${this.context.user.tenantId}?entityId=${this.props.entityId}`,
      JSON.stringify(mappedObj)
    ).subscribe(
      (successResult) => {
        if (successResult) {
          this.setState({ fetchResult: ResultStatus.SUCCESS });
          this.props.refreshList(true);
          this.props.onClose(false);
        } else {
          ToastService.showError("Error: falsey successResult while saving Calender Event.");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      },
      (errorResult) => {
        ToastService.showError("Error while saving Calender Event.");
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    );
  }
  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <Dialog open={this.props.open || false} scroll={true ? "paper" : "body"} maxWidth="md" fullWidth={true}>
            <DialogTitle disableTypography id="dialogTitle">
              <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6" className={classes.root}>Configuration</Typography>
                  <IconButton color="secondary" onClick={() => { this.props.onClose() }}>{MatIconService.CANCEL}</IconButton>
                </Toolbar>
              </AppBar>
            </DialogTitle>
            <DialogContent>
              <PageLoadingComponent classes={classes} label="Loading Configuration...." />
            </DialogContent>
          </Dialog>
        );
      case ResultStatus.SAVING:
        return (
          <Dialog open={this.props.open || false} scroll={true ? "paper" : "body"} maxWidth="md" fullWidth={true}>
            <PageLoadingComponent classes={classes} label="Saving Configuration...." />);
          </Dialog>
        );
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <Dialog open={this.props.open || false} scroll={true ? "paper" : "body"} maxWidth="md" fullWidth={true}>

            <DialogTitle disableTypography id="dialogTitle">
              <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6" className={classes.root}>Configuration</Typography>
                  <IconButton color="inherit" onClick={() => { this.setState({ submitCreateWasClicked: true }); this.handleSubmit(); }}>{MatIconService.OK}</IconButton>
                  <IconButton color="secondary" onClick={() => { this.props.onClose() }}>{MatIconService.CANCEL}</IconButton>
                </Toolbar>
              </AppBar>
            </DialogTitle>

            <DialogContent style={{ padding: 24 }}>
              <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                <Grid item xs={5}><Typography variant="h6" className={classes.root}>Available Fields</Typography>{this.customList(this.state.left, classes)}</Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" alignItems="center">
                    <Button variant="outlined" size="small" className={classes.button} onClick={this.handleCheckedRight} disabled={this.intersection(this.state.checked, this.state.left).length === 0} aria-label="move selected right">&gt;</Button>
                    <Button variant="outlined" size="small" className={classes.button} onClick={this.handleCheckedLeft} disabled={this.intersection(this.state.checked, this.state.right).length === 0} aria-label="move selected left">&lt;</Button>
                  </Grid>
                </Grid>
                <Grid item xs={5}><Typography variant="h6" className={classes.root}>Displayed Fields</Typography>{this.customList(this.state.right, classes)}</Grid>
              </Grid>
            </DialogContent>
          </Dialog >
        );
      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent title="Error" description="Error in Field Configuration" classes={classes}
            onClose={() => { this.props.onClose(false); }} onRetry={() => { console.log("Retry Clicked"); }}
          />
        );
    }
  }
}

export default LayoutService.getHocComponenet(FieldConfigurationComponent);

