import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";
import {
  withStyles,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AgGridUtil } from "../../../shared/services/ag-grid/agGridUtil";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import { AuthContext } from "../../../shared/store/authProvider";
import LookupService from "../../../shared/services/lookupService";
import { ResultStatus } from "../../../shared/types/enums";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { SubscriptionArray } from "../../../shared/services/dataService";
import AgGridEditButtonCellRendererComponent from "../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import HealthIndicatorCellRendererComponent from "../../../shared/components/elements/healthIndicator/healthIndicatorCellRendererComponent";
import FieldConfigurationComponent from "../../../shared/components/field-configuration/fieldConfigurationComponent";
import DataDetailCellRendererComponent from "../data-detail/dataDetailCellRendererComponent";
import DataCountCellRendererComponent from "./dataExpandedViewCellRendererComponent";
import DataDetailDialogComponent from "../data-detail/dataDetailDialogComponent";
import DataExpandedViewService from "./dataExpandedViewService";
import LayoutService from "../../../shared/services/layoutService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

const fetchProfilesBySearchBoxText = async (searchBoxParams) => {
  const rowData = await fetch(
    `${process.env.REACT_APP_CORE_HTTP}/DataCenter/ExpandedView`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourceId: searchBoxParams.sourceId,
        entityId: searchBoxParams.sourceTypeId,
        fileId: Number(searchBoxParams.fileId),
        healthIndicator: searchBoxParams.healthIndicator,
        tenantId: searchBoxParams.user.tenantId,
        isActive: true,
        isDeleted: false,
        searchValue: searchBoxParams.newValue,
        filterQuery: "",
        screenId: 0,
        userId: searchBoxParams.user.userId,
        taskId: 0,
        rowsPerPage: 25000,
        pageNumber: 1,
      }),
    }
  ).then((result) => result.json());
  return rowData.map(obj => Object.entries(obj).reduce((a, [key, value]) => {
    a[key.toLowerCase()] = value;
    return a;
  }, {}));
};
let searchSubject = new BehaviorSubject("");
let SearchResultObservable = searchSubject.pipe(
  filter((val) => val.hasOwnProperty('newValue')),
  debounceTime(750),
  distinctUntilChanged(),
  mergeMap((val) => from(fetchProfilesBySearchBoxText(val)))
);

class DataExpandedViewComponent extends React.Component {
  static contextType = AuthContext;
  scopedSubscriptions = new SubscriptionArray();
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      fetchResult: ResultStatus.NOT_LOADED,
      porzioSearch: "",
      modalAgNode: {},
      agGridUtils: new AgGridUtil("lastname", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        dataCountCellRendererComponent: DataCountCellRendererComponent,
        dataDetailCellRendererComponent: DataDetailCellRendererComponent,
        healthIndicatorCellRendererComponent: HealthIndicatorCellRendererComponent,
      }),
      canViewExpandedView: this.props.modalAgNode.sourceTypeId === 1
        ? RolePermissionService.PROFILE_EXPANDED_VIEW_DATACENTER.canView
        : RolePermissionService.TRANSACTION_EXPANDED_VIEW_DATACENTER.canView
    };
  }

  componentWillUnmount() {
    this.scopedSubscriptions.cancelAll();
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });
    this.fetchData();
  }

  fetchData = () => {
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        DataExpandedViewService.postDataCenterExpandedViewAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode, this.props.healthIndicator, this.props.screenId),
        DataExpandedViewService.getDataCenterDisplayedFieldsAsOBS(this.context.user.tenantId, this.props.modalAgNode.sourceId, this.props.modalAgNode.sourceTypeId, this.context.user.userId, this.props.screenId),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
      ]).subscribe(
        ([_agGridData, _agGridColumns, _countryList,]) => {
          this.setState(
            {
              agGridData: _agGridData.map(obj => Object.entries(obj).reduce((a, [key, value]) => {
                a[key.toLowerCase()] = value;
                return a;
              }, {})),
              agGridColumns: _agGridColumns,
              countryList: _countryList,
            },
            () => {
              // change the state after all the above are assigned
              //this.state.agGridUtils.sizeColumnsToFit();
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        (_exception) => {
          console.log(_exception);
        }
      )
    );
  }

  // handleFilterChange = () => {
  //   fetch(`${process.env.REACT_APP_BASE_HTTP}/Profiles/${this.context.user.tenantId}/FetchProfiles`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       pageNum: 1,
  //       pageSize: 25,
  //       source: "Profiles",
  //       searchBoxText: "",
  //       recycle: "1",
  //       sourceId: 24,
  //       permissions: null,
  //       userType: 3,
  //       fileId: 5069,
  //       healthIndicatorId: null,
  //       SearchString: "",
  //       UserId: this.context.user.userId,
  //       ScreenId: 43,
  //       TaskId: 0,
  //     }),
  //   })
  //     .then((result) => result.json())
  //     .then((rowData) => this.setState({ data: rowData }))
  //     .then((rowData) => console.log(rowData))
  //     .catch((err) => console.log(err));
  // };

  handleSearchChange = (e) => {
    const newValue = e.target.value;
    this.setState({ porzioSearch: newValue });

    if (!this.state.searchSubscriptionAdded) {
      this.setState(
        { searchSubscriptionAdded: true },
        () => {
          this.scopedSubscriptions.add(
            SearchResultObservable.subscribe((searchResultJson) => {
              this.setState({ agGridData: searchResultJson });
            })
          );
        }
      );
    } else {
      searchSubject.next({ newValue, ...this.props.modalAgNode, ...this.context, healthIndicator: this.props.healthIndicator });
    }
  }

  clearSearch = () => {
    searchSubject.next({ newValue: "", ...this.props.modalAgNode, ...this.context, healthIndicator: this.props.healthIndicator });
  }

  dataDetailMethodFromParent = (cell, node) => {
    if (this.state.agGridUtils.isNotEditing()) {
      this.setState({ showDataDetailDialog: true });
      this.setState({ modalAgNode: node });
    }
  };

  render() {
    const { classes } = this.props;

    if (!this.state.canViewExpandedView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Data Center Expanded View"
            />
          );
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <>
              <DialogTitle disableTypography id="dialogTitle">
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6" className={classes.root}>
                      Data Count
                    </Typography>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>{MatIconService.SEARCH}</div>
                      <InputBase
                        placeholder="Search…"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{ "aria-label": "search" }}
                        value={this.state.porzioSearch}
                        onChange={this.handleSearchChange}
                      />
                    </div>
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        this.setState({ showFieldConfigurationDialog: true });
                      }}>
                      {MatIconService.MENU}
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        this.clearSearch();
                        this.props.onClose(false);
                      }}
                    >
                      {MatIconService.CANCEL}
                    </IconButton>

                  </Toolbar>
                </AppBar>
              </DialogTitle>
              <DialogContent>
                <Box style={{ padding: "16px" }}>
                  <div {...LayoutService.getAgGridStyles(264)}>
                    <AgGridReact
                      pagination={true}
                      paginationPageSize={100}
                      rowData={this.state.agGridData}
                      columnDefs={DataExpandedViewService.getColumnDefs(this)}
                      frameworkComponents={this.state.agGridUtils.frameworkComponents}
                      suppressClickEdit={true}
                      gridOptions={{
                        ...AgGridColumnExt.getGridOptions(56),
                        context: { componentParent: this },
                        rowHeight: 32,
                        ...this.state.agGridUtils.bindInlineEditEvents(),
                        defaultColDef: {
                          minWidth: 136,
                          defaultWidth: 136,
                          headerClass: "center-text",
                          flex: 1,
                          editable: false,
                          filter: true,
                          cellStyle: { 'text-align': 'center' }
                        },
                      }}
                      onGridReady={(params) => {
                        //params.api.setDomLayout("autoHeight");
                        this.state.agGridUtils.setGridParams(params, true);
                      }}
                    ></AgGridReact>
                  </div>
                </Box>
                <DataDetailDialogComponent open={this.state.showDataDetailDialog || false} onClose={() => this.setState({ showDataDetailDialog: false })}
                  modalAgNode={this.state.modalAgNode} entityId={this.props.modalAgNode.sourceTypeId} />
                {this.state.showFieldConfigurationDialog ?
                  <FieldConfigurationComponent open={this.state.showFieldConfigurationDialog || false}
                    onClose={() => this.setState({ showFieldConfigurationDialog: false })}
                    entityId={this.props.modalAgNode.sourceTypeId}
                    modalAgNode={this.props.modalAgNode}
                    fileId={this.props.modalAgNode.fileId}
                    sourceId={this.props.modalAgNode.sourceId}
                    screenId={this.props.screenId}
                    refreshList={this.fetchData} />
                  : null}
              </DialogContent>
            </>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading Data Count"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }
  }
}

/** HOC */
export default withStyles(MatClassService)(DataExpandedViewComponent);
