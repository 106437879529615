import React, { Component } from "react";
import { MatIconService } from "../../../shared/services/theme/matIconService";

export default class CommentAvailableImageRenderer extends Component {
    render() {
        if (this.props.data && this.props.data.hasComments) { 
          return (
            <div style={{ display: "flex",justifyContent: "center" }}>{MatIconService.FILE_COMMENT}</div>
              );
        } else {
            return (
                <></>
              );
        }
    }
}