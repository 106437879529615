import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles, Grid, CircularProgress, Typography, Paper, Box, Divider } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { ThemeProvider } from "@material-ui/core/styles";
import MatThemeService from "../../shared/services/theme/matThemeService";
import { MatClassService } from "../../shared/services/theme/matClassService";
import ToastService from "../../shared/services/toastService";

import AuthProvider, { useAuthState, AuthContext } from "../../shared/store/authProvider";

import porzio_gst_icon from "../../public/images/porzio_global_spend_transparency_logo_small.png";
import { ResultStatus } from "../../shared/types/enums";
import OktaAuthService from "./oktaAuthService";
import OktaLoginWidgetComponent from "./oktaLoginWidgetComponent";
import MultiTenantSelectionComponent from "./multiTenantSelectionComponent";
import ApplicationService from "../../shared/services/applicationService";


class OktaSingleSignOnComponent extends React.Component {

    constructor(props) {
        super(props);

        // required;
        OktaAuthService.INIT();
        MatThemeService.setLightMode();

        this.state = {
            userEmail: null,
            showToast: false,
            currentTheme: MatThemeService.getTheme(),
            topBarColor: MatThemeService.getTopBarColor(),
            statusMessage: '',
            fetchState: ResultStatus.NOT_LOADED,
        }
    }

    componentDidMount() {
        this.setState({ userEmail: null, statusMessage: 'Initializing OKTA...', fetchState: ResultStatus.LOADING });
        OktaAuthService.checkSingleSignOn(
            (successObj) => {
                this.setState({
                    userEmail: successObj.tokens.idToken.claims.email, statusMessage: 'Session Exists.!', fetchState: ResultStatus.SUCCESS
                });
            },
            (errorObj) => { this.setState({ statusMessage: 'Session Not Exists.!', fetchState: ResultStatus.ERROR }); }
        )
    }

    changeTheme = () => {
        this.setState({
            currentTheme: MatThemeService.getTheme(),
            topBarColor: MatThemeService.getTopBarColor(),
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={this.state.currentTheme}>
                <AuthProvider>
                    {this.renderAppEssentials()}
                    {this.renderComponents()}
                </AuthProvider>
            </ThemeProvider >
        );
    }

    renderComponents = () => {
        switch (this.state.fetchState) {
            case ResultStatus.SUCCESS:
                return <MultiTenantSelectionComponent userEmail={this.state.userEmail} topBarColor={this.state.topBarColor} onThemeChange={this.changeTheme} />
            case ResultStatus.ERROR:
                return <OktaLoginWidgetComponent topBarColor={this.state.topBarColor} onThemeChange={this.changeTheme} />
            default:
                return (
                    <Grid container direction="column" justifyContent="start" alignItems="center" style={{ height: '100vh' }}>
                        <Paper style={{ border: MatThemeService.borderStyle, marginTop: 102, width: 440, height: 428, textAlign: "center", }} >
                            <img src={porzio_gst_icon} style={{ marginBlock: 16, width: 252, height: 81 }} alt="Porzio Global Spend Transparency Logo" />
                            <Divider />
                            <Typography variant="h6" style={{ marginTop: 16 }} color="primary">{this.state.statusMessage}</Typography>
                            {this.state.fetchState === ResultStatus.LOADING ? <CircularProgress style={{ marginTop: 56, height: 128, width: 128 }} /> : null}

                            <Box style={{ marginTop: "48px", paddingBottom: "16px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography noWrap variant="caption" style={{ fontStyle: "italic" }}>{ApplicationService.copyrightString}</Typography>
                            </Box>
                        </Paper>
                    </Grid >
                );
        }
    }

    renderAppEssentials = () => {
        // !!!.Don't Remove the Snack Bar!!! 
        // It is required to show the Toast across the application *
        ToastService.setComponentREF(this);
        return (
            <Snackbar open={this.state.showToast} anchorOrigin={ToastService.anchorOrigin} autoHideDuration={ToastService.autoHideDuration} onClose={ToastService.closeToast}>
                <MuiAlert elevation={ToastService.elevation} variant={ToastService.variant} onClose={ToastService.closeToast} severity={ToastService.toastType}>{ToastService.message}</MuiAlert>
            </Snackbar>
        );
    }


    //---
}
export default withStyles(MatClassService)(withRouter(OktaSingleSignOnComponent));