import { CELL_ACTION } from "../../../shared/types/enums";
import { DataService } from "../dataService";
import LayoutService from "../layoutService";
import ToastService from "../toastService";

/**
 * Author : Pradeep_Rajendran@epam.com
 */
export class AgGridUtil {

  gridParams = null;
  /** derived from gridParams (equivalent of this.gridParams.api) */
  gridApi = null;
  /** derived from gridParams (equivalent of this.gridParams.columnApi) */
  gridColumnApi = null; // derived from gridParams

  gridColumnNameMap = new Map();

  defaultColumnName = "";
  frameworkComponents = {};

  editingRowIndex = -1;
  lastEditedRowIndex = -1;

  /**
   * will be called when client-component is re-rendered
   */
  resetIndexes() {
    this.editingRowIndex = -1;
    this.lastEditedRowIndex = -1;
  }

  getStyles() {
    return LayoutService.getStyles();
  }


  /**
   * @param _defaultColumnName Column to focus when EditIcon is Clicked
   */
  constructor(_defaultColumnName, _frameworkComponents) {
    this.defaultColumnName = _defaultColumnName;
    this.frameworkComponents = _frameworkComponents;
    this.resetIndexes();
  }

  /**
   * Usage:
   * <AgGridReact
   * ...
   * onGridReady={(params) => { this.STORE.agGridUtils.setGridParams(params, true); }}
   * ...
   * @param _gridParams pass the grid params through onGridReady prop of the AgGridReact
   * @param _sizeColumnsToFit if set to true, calls the gridParams.api.sizeColumnsToFit()
   */
  setGridParams = (_gridParams, _sizeColumnsToFit) => {
    this.gridParams = _gridParams;
    this.gridApi = _gridParams.api;
    this.gridColumnApi = _gridParams.columnApi;

    this.gridColumnApi.getAllColumns().forEach((column) => {
      this.gridColumnNameMap.set(column.colId.toLowerCase(), column);
    })


    if (_sizeColumnsToFit) { this.gridApi.sizeColumnsToFit(); }
    // params.setDomLayout("autoHeight");
  };

  getSelectedRows = (_selectColumnName) => {
    let oRET = [];
    this.gridApi.forEachNode((rowNode) => {
      // !!! this is very important as we cannot rely on the rowNode.rowIndex after a sort, so we are using the id to localte the correct index of the row
      const sortAndFilterAwareRowNode = this.gridApi.getRowNode(rowNode.id);
      if (sortAndFilterAwareRowNode.data[_selectColumnName] === true) {
        oRET.push(sortAndFilterAwareRowNode);
      }
    });
    return oRET;
  }

  sizeColumnsToFit = () => {
    if (this.gridParams && this.gridParams.api) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  autoSizeColumns = (_skipHeader = false) => {
    var allColumnIds = [];
    this.gridParams.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridParams.columnApi.autoSizeColumns(allColumnIds, _skipHeader);
  }

  /**
   * Used to spread the default events for enabling the Inline-Edit
   * USAGE:
   * <AgGridReact
   * ...
   * gridOptions={{ ...this.STORE.agGridUtils.bindInlineEditEvents(), }}
   * ...
   */
  bindInlineEditEvents = () => {
    this.resetIndexes();
    return {
      editType: "fullRow",
      suppressClickEdit: true,
      stopEditingWhenGridLosesFocus: false,
      // suppressCellSelection: true,
      // suppressRowClickSelection: true,
      onRowClicked: (_event) => {
        this._handleRowClick(_event);
      },
      onRowEditingStopped: (_event) => {
        // To prevent this geting triggered recursively
        if (this.lastEditedRowIndex !== _event.rowIndex) {
          this.lastEditedRowIndex = _event.rowIndex;
          this._handleRowEditingStopped(_event);
        }
      },
    };
  };

  /**
   * Not Implemented yet
   * @param {*} _parentThisRef 
   * @param {*} _agGridUtils 
   * @param {*} _rowData 
   * @param {*} _columnDefs 
   * @param {*} _frameworkComponents 
   * @param {*} _rowHeight 
   */
  static getInlineRowEditGridProps = (_parentThisRef, _agGridUtils, _rowData, _columnDefs, _frameworkComponents, _rowHeight = 32) => {
    return {
      rowData: _rowData,
      columnDefs: _columnDefs,
      frameworkComponents: _frameworkComponents,
      suppressClickEdit: true,
      gridOptions: {
        context: { componentParent: _parentThisRef },
        suppressContextMenu: true,
        rowHeight: _rowHeight,
        ..._agGridUtils.bindInlineEditEvents(),
      },
      onGridReady: (_params) => {
        _agGridUtils.setGridParams(_params, true);
      }
    };
  }

  /**
   * Returns an edit Icon column for the AgGrid's ColumnDef with all the necessacry logics to handle Edit/Cancel/Accept button
   * @param _showDelete Shows/Hides delete Icon
   * @param _onAcceptObsCallback called when accept button is clicked after an edit, the obs isSuccess determines to hide the accept button or not
   * @param _headerText Column Header Text of the agGrid
   * @param _frameworkCellRenderer eg: frameworkComponents: { inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent }
        <AgGridReact frameworkComponents={this.state.frameworkComponents} ...
   * @param _width column width in the agGrid, default is 128
   * @param _cellStyle default is {"border-right": "1px solid lightgray"}
   */
  getInlineEditActionColumn = (
    _showDelete,
    _onAcceptObsCallback,
    _headerText = "Edit",
    _frameworkCellRenderer = "inlineEditButtonCellRendererComponent",
    _width = 88,
    _cellStyle = { "border-right": "1px solid lightgray" }
  ) => {
    return {
      headerName: _headerText,
      width: _width,
      minWidth: _width,
      maxWidth: _width,
      cellStyle: _cellStyle,
      headerClass: "text-center",
      field: AgGridUtil.specialProperty,
      cellRenderer: _frameworkCellRenderer,
      cellRendererParams: {
        showDelete: _showDelete,
        onClick: (_cellRef, _action) => {
          switch (_action) {
            case CELL_ACTION.DELETE:
              if (this.isNotEditing()) {
                // TODO: ask for confirmation
                this.gridApi.applyTransaction({ remove: [_cellRef.props.data] });
                this._cancelRowEdit();
              }
              break;
            case CELL_ACTION.EDIT:
              if (this.isNotEditing()) {
                this.setEditingProperty(_cellRef.props.data); // make sure it exists
                // redraw the specific cells only
                this.refreshRows(_cellRef.props.api, [_cellRef.props.node]); // applies to other columns as well
                // set the row as editing row
                this._setEditingRow(_cellRef.props.rowIndex);
              }
              break;
            case CELL_ACTION.ACCEPT:
              _onAcceptObsCallback(_cellRef).subscribe((_result) => {
                if (_result.isSuccess) {
                  this.clearPinnedColumns(); // clear pinned column
                  this.deleteEditingProperty(_cellRef.props.data); // make sure it exists
                  this._acceptRowEdit();
                  // redraw the specific cells only
                  this.refreshRows(_cellRef.props.api, [_cellRef.props.node]);
                  this.focusEditCell(_cellRef.props.rowIndex);
                } else {
                  // focus the _result.invalidCell
                  ToastService.showError(_result.invalidMessage);
                  if (_result.invalidCellRef) { this.gridApi.setFocusedCell(_result.invalidCellRef.props.rowIndex, _result.invalidCellRef.props.column.colId); }
                }
              });
              break;
            case CELL_ACTION.CANCEL:
              this.clearPinnedColumns(); // clear pinned column
              this.deleteEditingProperty(_cellRef.props.data); // make sure it exists
              // redraw the specific cells only
              this.refreshRows(_cellRef.props.api, [_cellRef.props.node]); // applies to other columns as well
              this._cancelRowEdit();
              this.focusEditCell(_cellRef.props.rowIndex);
              break;

            default:
              break;
          }
        },
      },
    };
  };

  focusEditCell = (_rowIndex) => {
    if (_rowIndex >= 0) {
      setTimeout(() => {
        this.gridApi.ensureColumnVisible(AgGridUtil.specialProperty);
        this.gridApi.setFocusedCell(_rowIndex, AgGridUtil.specialProperty); // focus the default cell
      }, 0);
    }
  }

  //#region SPECIAL-PROPERTY-ZONE
  static specialProperty = "specialProperty";
  static editingProperty = "isEditing";
  static readOnlyProperty = "isReadOnly";

  ensureSpecialProperty = (_rowData) => {
    if (!_rowData[AgGridUtil.specialProperty]) {
      _rowData[AgGridUtil.specialProperty] = {};
      // add the properties
      _rowData[AgGridUtil.specialProperty][AgGridUtil.editingProperty] = false;
      _rowData[AgGridUtil.specialProperty][AgGridUtil.readOnlyProperty] = false;
    }
    return _rowData;
  }
  deleteSpecialProperty = (_rowData) => {
    this.ensureSpecialProperty(_rowData); // make sure it exists
    delete _rowData[AgGridUtil.specialProperty]; // simply delete the property
  }

  deleteEditingProperty = (_rowData) => {
    this.ensureSpecialProperty(_rowData); // make sure it exists
    delete _rowData[AgGridUtil.specialProperty][AgGridUtil.editingProperty]; // simply delete the property
  }

  setEditingProperty = (_rowData) => {
    this.pinColumn(AgGridUtil.specialProperty);     // ensure pinned on edit
    this.ensureSpecialProperty(_rowData); // make sure it exists
    _rowData[AgGridUtil.specialProperty][AgGridUtil.editingProperty] = true;
  }

  pinColumn = (_colName, _side = "left") => {
    this.gridColumnApi.applyColumnState({
      state: [{ colId: _colName, pinned: _side }],
      defaultState: { pinned: null },
    });
  }
  clearPinnedColumns = () => {
    this.gridColumnApi.applyColumnState({ defaultState: { pinned: null } });
  }

  deleteReadOnlyProperty = (_rowData) => {
    this.ensureSpecialProperty(_rowData); // make sure it exists
    delete _rowData[AgGridUtil.specialProperty][AgGridUtil.readOnlyProperty]; // simply delete the property
  }

  /**
   * Should be implemented in 2 places:
   * 1/2) before render()'s return stmt : <this.state.agGridUtils.setReadOnlyMode(this.state.isReadOnly);>
   * 2/2) inside agGrid's onGridReady() : <this.state.agGridUtils.setReadOnlyMode(this.state.isReadOnly);>
   * @param {*} _isReadOnly If handled in the component then pass <this.state.isReadOnly> else if handled in the parent then pass <this.props.isReadOnly>
   */
  setReadOnlyMode = (_isReadOnly) => {
    if (this.gridParams) {
      this.gridApi.forEachNode((rowNode) => {
        this.ensureSpecialProperty(rowNode.data); // ensure the property exists
        rowNode.data[AgGridUtil.specialProperty][AgGridUtil.readOnlyProperty] = _isReadOnly;
        //rowNode.data[AgGridUtil.specialProperty][AgGridUtil.editingProperty] = !_isReadOnly;
      });

      // rerender the cells
      this.refreshAllCells(this.gridParams.api);
    }
  }

  setEditingMode = (_isReadOnly) => {
    if (this.gridParams) {
      this.gridApi.forEachNode((rowNode) => {
        this.ensureSpecialProperty(rowNode.data); // ensure the property exists
        //rowNode.data[AgGridUtil.specialProperty][AgGridUtil.readOnlyProperty] = _isReadOnly;
        rowNode.data[AgGridUtil.specialProperty][AgGridUtil.editingProperty] = !_isReadOnly;
      });

      // rerender the cells
      this.refreshAllCells(this.gridParams.api);
    }
  }

  /**
   * Should be implemented in 2 places:
   * 1/2) before render()'s return stmt : <this.state.agGridUtils.disableEditability(this.state.isReadOnly);>
   * 2/2) inside agGrid's onGridReady() : <this.state.agGridUtils.disableEditability(this.state.isReadOnly);>
   * @param {*} _value If handled in the component then pass <this.state.isReadOnly> else if handled in the parent then pass <this.props.isReadOnly>
   */
  disableEditability(_value) {
    if (this.gridParams) {
      this.gridApi.forEachNode((rowNode) => {
        this.ensureSpecialProperty(rowNode.data); // ensure the property exists
        rowNode.data[AgGridUtil.specialProperty][AgGridUtil.readOnlyProperty] = _value;
        rowNode.data[AgGridUtil.specialProperty][AgGridUtil.editingProperty] = !_value;
      });

      // rerender the cells
      this.refreshAllCells(this.gridParams.api);
    }
  }

  //#endregion

  /** @returns true if the Grid is in EditMode */
  isEditing = () => {
    return this.editingRowIndex > -1;
  };
  /** @returns true if the Grid is Not in EditMode */
  isNotEditing = () => {
    return this.editingRowIndex === -1;
  };

  autoSizeAllColumns = (_skipHeader = false) => {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach((col) => { allColumnIds.push(col.colId); });
    this.gridColumnApi.autoSizeColumns(allColumnIds, _skipHeader);
  };

  /**
   * @returns the Array of modified row data
   */
  getUpdatedRowData = () => {
    const oRET = [];
    this.gridApi.forEachNode((rowNode) => {
      this.deleteSpecialProperty(rowNode.data); // remove temporary specialProperty Field
      oRET.push(rowNode.data);
    });
    return oRET;
  }

  /**
  * @returns the Array of modified Row Nodes
  */
  getUpdatedRowNodes = () => {
    const oRET = [];
    this.gridApi.forEachNode((rowNode) => {
      this.deleteSpecialProperty(rowNode.data); // remove temporary specialProperty Field
      oRET.push(rowNode);
    });
    return oRET;
  }

  /**
   * Refreshes the cells of the specified columns and rows
   * @param {*} _gridApi 
   * @param {*} _rowNodes pass the array of row nodes
   * @param {*} _fieldNames pass the array of column names
   */
  refreshCells = (_gridApi, _rowNodes, _fieldNames) => {
    _gridApi.refreshCells({
      rowNodes: _rowNodes, // specify rows, or all rows by default
      columns: _fieldNames, // specify columns, or all columns by default
      force: true,
    });
  };

  /**
   * Refreshes all Rows cells of the specified columns
   * @param {*} _gridApi 
   * @param {*} _fieldNames pass column names as array
   */
  refreshColumns = (_gridApi, _fieldNames) => {
    _gridApi.refreshCells({ columns: _fieldNames, force: true, });
  };

  /**
   * Refreshes all Columns cells of the specified Rows
   * @param {*} _gridApi 
   * @param {*} _rowNodes pass the row nodes as array
   */
  refreshRows = (_gridApi, _rowNodes) => {
    _gridApi.refreshCells({
      rowNodes: _rowNodes, // specify rows, or all rows by default
      force: true
    });
  };

  /**
   * Refreshes all cells of the Grid
   * @param {*} _gridApi 
   */
  refreshAllCells = (_gridApi) => {
    _gridApi.refreshCells({ force: true });
  };

  _lastEditedRowNode = null;
  /**
   * Called when the Edit-Icon is clicked
   * cancells the currently editing row if any,
   * makes the rowIndex Editable, and focuses on the defaultColumnName
   * @param _rowIndex to make editable
   */
  _setEditingRow = (_rowIndex) => {
    this.gridApi.stopEditing(true); // cancells any previous editing row
    this.editingRowIndex = _rowIndex; // set the current editing row Index
    this.gridApi.setFocusedCell(_rowIndex, this.defaultColumnName); // focus the default cell
    this.gridApi.startEditingCell({
      rowIndex: _rowIndex,
      colKey: this.defaultColumnName,
    }); // start editing the row

    // cache 
    if (!this._lastEditedRowNode || this._lastEditedRowNode.rowIndex !== _rowIndex) {
      // TODO: for new node add, the below get row node returns obj with no keys, as a result an error is thrown,.. handle it 
      // may be we need to hanfle new node differently
      this._lastEditedRowNode = Object.assign({}, this.gridApi.getRowNode(_rowIndex)); // shallow copy the node
      this._lastEditedRowNode.data = JSON.parse(JSON.stringify(this._lastEditedRowNode.data)); // deepclone data part
    }
  };

  /**
   * Called when the Cancel-Icon is clicked,
   * Discards any changes made
   */
  _cancelRowEdit = () => {
    this.gridApi.stopEditing(true); // true <- true <- cancel modifications and stops edit

    // if cancelled is a new row, then delete it from the grid
    if (this.addedRow && this.addedRow.rowIndex === this.editingRowIndex) {
      this.cancelNewRow();
    } else if (this.isEditing() && this._lastEditedRowNode) {
      var oldData = JSON.parse(JSON.stringify(this._lastEditedRowNode.data));
      this.ensureSpecialProperty(oldData); // make sure property exists
      oldData[AgGridUtil.specialProperty][AgGridUtil.editingProperty] = false; // set the editing mode as false
      this._lastEditedRowNode = null; // clear the cache

      var rowNode = this.gridApi.getRowNode(this.editingRowIndex);
      rowNode.data = oldData;

      var res = this.gridApi.applyTransaction({ update: [rowNode.data] });

    }

    // clear all others
    this.editingRowIndex = -1; // clears the editing Flag to allow navigation
  };

  /**
   * Helps to track the newly added row
   * Tracking will be removed if:
   * 1) Changes accepted by clicking the Accept Button after validation
   * 2) Changes Cancelled by clicking the Cancel Button
   */
  addedRow = null;

  /**
   * Adds a new row at the end of the grid and sets the focus to it.
   * Call this when other row is not editing and when add button is clicked
   * @param _newRowData pass the empty row Object with all keys
   */
  addNewRow = (_newRowData) => {
    // ensure specialProperty Exists
    this.ensureSpecialProperty(_newRowData);
    // set the esiting property
    _newRowData[AgGridUtil.specialProperty][AgGridUtil.editingProperty] = true;
    // const keys = Object.keys(_newRowData);
    // if (!keys.includes("isEditing")) {
    //   _newRowData.isEditing = true;
    // }

    this.addedRow = this.gridApi.applyTransaction({
      add: [_newRowData],
    }).add[0];
    this._setEditingRow(this.addedRow.rowIndex); // set this as the new row to edit
  };

  /**
   * This deletes the newely added row from the UI & the Underlying DataSource
   */
  cancelNewRow = () => {
    if (this.addedRow) {
      this.gridApi.applyTransaction({ remove: [this.addedRow.data] });
      this.addedRow = null; // remove tracking
    }
  };

  /**
   * Called when the Accept-Icon is clicked
   * Note: Should be called after validating the current changes
   */
  _acceptRowEdit = () => {
    this._lastEditedRowNode = null; // clear the cached values
    this.addedRow = null; // once validated, the added row becomes applied, so no longer track it
    this.editingRowIndex = -1; // clears the editing Flag to allow navigation
    this.gridApi.stopEditing(); // stops the editing
  };

  /** Prevents Editing Row from Losing focus, when the use clicks on an another row leaving the edit mode open */
  _handleRowClick = (_event) => {
    if (
      // To prevent this geting triggered if we are navigation inside the sameRow
      this.editingRowIndex !== _event.rowIndex &&
      // if Accept/Cancel is not clicked (>-1), but a new row receives focus
      this.isEditing()
    ) {
      this._setEditingRow(this.editingRowIndex); // move the focus back to the editing row
    }
  };

  /** Used to Prevent Cancellation of the EditMode of a Row Abruptly
   * Aceives this by reverting back the editing mode if the editing mode is lost
   * because of an <Esc> keyPress (or) MouseClick on other Rows.
   * */
  _handleRowEditingStopped = (_event) => {
    // editingRowIndex will be -1 only if Accept/Cancel is clicked
    if (this.isEditing()) {
      this._setEditingRow(this.editingRowIndex); // move the focus back to the editing row
    }
  };

  /**
   * 
   * @param {*} _rowsToUpdate pass array of rows to update
   */
  updateRows = (_rowsToUpdate) => {
    this.gridParams.api.applyTransaction({ update: _rowsToUpdate });
  }

  /**
   * 
   * @param {*} columnValueMaxLengths {'colName1': 50, 'colName2' : 5 }
   * @returns 
   */
  static truncateOnMaxCharLengthExceeds = (columnValueMaxLengths) => {
    return {
      onCellEditingStopped: (e) => {
        if (!columnValueMaxLengths) { columnValueMaxLengths = {}; }
        const columnNames = Object.keys(columnValueMaxLengths);

        if (columnNames.includes(e.column.colDef.field)) {
          let maxLength = columnValueMaxLengths[e.column.colDef.field];
          maxLength = maxLength > 0 ? maxLength : 256;
          const originalValue = e.value + "";
          if (originalValue.length > maxLength) {
            e.node.setDataValue(e.column.colId, originalValue.substr(0, maxLength));
            ToastService.showWarning(`Cannot exceed ${maxLength} Characters`);
          }
        }

      }
    }
  }

  /**
   * Determines if restrictions are applied via SpecialProperty
   * @param {*} _cellProps 
   */
  static isEditingAllowed = (_cellProps) => {
    const specialObj = (_cellProps || {}).data[AgGridUtil.specialProperty];
    const isEditing = DataService.isNullOrUndefined(specialObj) ? false : specialObj[AgGridUtil.editingProperty] === true;
    const isReadOnly = DataService.isNullOrUndefined(specialObj) ? false : specialObj[AgGridUtil.readOnlyProperty] === true;

    // if specialObject not exits then allow editing, else it should not be readonly and should be in editing Mode
    const _allowEditing = DataService.isNullOrUndefined(specialObj) || (!isReadOnly && isEditing);

    // return
    return _allowEditing;
  }

  static isEditingDisabled = (_cellProps) => {
    return !this.isEditingAllowed(_cellProps);
  }
  //---
}
