import React, { Component, createRef } from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  FormHelperText,
  Input,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../../shared/store/authProvider";
import {
  ResultStatus,
  CrudAction,
  API_ENDPOINT,
} from "../../../../shared/types/enums";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import { DataService } from "../../../../shared/services/dataService";
import ApiService from "../../../../shared/services/apiService";
import ToastService from "../../../../shared/services/toastService";
import NewsFeedDetailsService from "./newsFeedDetailsService";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class NewsfeedDetailsComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  lastValidatedMaterialName = null;
  lastValidatedMaterialResult = false;
  link = createRef();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      newsfeedTypeList: [],
      isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      fetchResult: ResultStatus.NOT_LOADED,
      editorState: EditorState.createEmpty(),
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });

    if (this.props.inputAction === CrudAction.CREATE) {
      try {
        this.oSubscriptions.add(
          combineLatest([
            LookupService.getNewsfeedTypesAsOBS(this.context),
            LookupService.getActiveTenantsAsObs(true)
          ]).subscribe(([_newsfeedTypeList, _companies]) => {
            _companies = _companies.filter(x => x.tenantid !== 1);
            this.setState({ newsfeedTypeList: _newsfeedTypeList },
              () => {
                var _companyList = DataService.getKeyValueCollection(_companies, "tenantid", "tenantName", false);
                if (_companyList.length > 0) { _companyList = DataService.addElementAll(_companyList, "id"); }

                var _selCompanyList = [];
                _selCompanyList = DataService.getKeyValueCollection(_selCompanyList, "id", "name", false);
                // change the state after all the above are assigned
                this.setState({
                  selCompanyList: _selCompanyList,
                  companyList: _companyList,
                  fetchResult: ResultStatus.LOADED,
                });
              }
            );
          })
        );
      } catch { this.setState({ fetchResult: ResultStatus.ERROR }); }
    } else {
      try {
        this.oSubscriptions.add(
          combineLatest([
            LookupService.getNewsfeedTypesAsOBS(this.context),
            NewsFeedDetailsService.getNewsFeedByIdAsOBS(this.context, this.props.updateData.newsfeedId),
            LookupService.getUserTenantsAsOBS(this.context)
          ]).subscribe(([_newsfeedTypeList, _newsFeedById, _companies]) => {
            const blocksFromHtml = htmlToDraft(_newsFeedById.newsfeedText);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const newEditorState = EditorState.createWithContent(contentState);
            _companies = _companies.filter(x => x.tenantid !== 1);
            this.setState(
              {
                newsfeedTypeList: _newsfeedTypeList,
                newsFeedById: _newsFeedById,
                editorState: newEditorState
              },
              () => {
                var _companyList = DataService.getKeyValueCollection(_companies, "tenantid", "tenantName", false);
                if (_companyList.length > 0) { _companyList = DataService.addElementAll(_companyList, "id"); }
                var _selCompanyList = [];
                _selCompanyList = DataService.getKeyValueCollection(_selCompanyList, "id", "name", false);
                // change the state after all the above are assigned
                this.setState({
                  selCompanyList: _selCompanyList,
                  companyList: _companyList,
                  fetchResult: ResultStatus.LOADED,
                });
              }
            );
          })
        );
      } catch { this.setState({ fetchResult: ResultStatus.ERROR }); }
    }
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        newsfeedType: "",
        selCompanyList: [],
        newsfeedTitle: "",
        newsfeedText: "",
        startDate: null,
        endDate: null,
        isActive: true,
      };
    } else {
      return {
        newsfeedType: this.state.newsFeedById.newsfeedTypeId,
        selCompanyList: this.state.newsFeedById.companyList[0] === -1 ? [{ id: 0, text: "All" }] :
          this.state.companyList.filter(x => this.state.newsFeedById.companyList.includes(x.id)),
        newsfeedTitle: this.state.newsFeedById.newsfeedTitle,
        newsfeedText: this.state.newsFeedById.newsfeedText,
        startDate: new Date(this.state.newsFeedById.newsfeedStartDate),
        endDate: new Date(this.state.newsFeedById.newsfeedEndDate),
        isActive: true,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      newsfeedTitle: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 12 characters or less"),
      startDate: Yup.date().nullable(true).required("Required"),
      endDate: Yup.date()
        .when("startDate",
          (startDate, yup) => {
            if (startDate) {
              var d = startDate;
              d.setHours(0, 0, 0, 0);
              return yup.nullable(true).required("Required").min(d, "Due date cannot be before Start date")
            } else {
              return yup.nullable(true).required("Required")
            }
          }),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          const formData = new FormData();
          formData.append("newsfeedTypeId", _formikProps.values.newsfeedType);
          formData.append("createdBy", this.context.user.userId);
          formData.append(
            "newsfeedTypeText",
            this.state.newsfeedTypeList.find(
              (x) => x.newsfeedTypeId === _formikProps.values.newsfeedType
            ).newsfeedTypeText
          );
          formData.append("newsfeedTypeIcon", "");
          if (this.context.user.tenantId === 1) {
            let tempCompList = DataService.getScopeKeys(this.state.selCompanyList, this.state.companyList, "id");
            tempCompList.forEach((item) => formData.append("companyList[]", item));
          } else {
            formData.append("companyList", [this.context.user.tenantId]);
          }
          formData.append("newsfeedTitle", _formikProps.values.newsfeedTitle);
          formData.append("tenantId", this.context.user.tenantId);
          formData.append("userId", this.context.user.userId);
          formData.append("newsfeedText", draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
          formData.append("newsfeedStartDate", this.props.inputAction !== CrudAction.CREATE ? this.state.newsFeedById.newsfeedStartDate !== _formikProps.values.startDate ?
            _formikProps.values.startDate.toISOString().substring(0, 10) : this.state.newsFeedById.newsfeedStartDate : _formikProps.values.startDate.toISOString().substring(0, 10));
          formData.append("newsfeedEndDate", this.props.inputAction !== CrudAction.CREATE ? this.state.newsFeedById.newsfeedEndDate !== _formikProps.values.endDate ?
            _formikProps.values.endDate.toISOString().substring(0, 10) : this.state.newsFeedById.newsfeedEndDate : _formikProps.values.endDate.toISOString().substring(0, 10));
          formData.append("attachment", this.state.selectedFileName);
          formData.append("feedStatus", true);
          formData.append("blobName", "");
          formData.append("isPorzioNewsfeed", true);
          formData.append("isNewsFeedTypeViewPermission", true);
          formData.append("isNewsFeedTypeEditPermission", true);
          formData.append("isNewsFeedTypeDeletePermission", true);
          formData.append("isNewsFeedTypeViewDeletePermission", true);
          formData.append("fieldLovId", 0);
          formData.append("updatedDate", "2020-08-19");
          formData.append("efile", this.state.selectedFile);

          // 3) determine the action and assign the appropriate props
          const actionVerb = "POST";
          const targetUrl = `${process.env.REACT_APP_TENANT_HTTP}/Newsfeed/SaveNewsfeed?tenantId=${this.context.user.tenantId}&isPorzioAdmin=true`;
          if (this.props.inputAction === CrudAction.CREATE) {
            // CREATE
            formData.append("newsfeedId", 0);
          } else {
            // UPDATE
            formData.append("newsfeedId", this.state.newsFeedById.newsfeedId);
          }

          // Do not set the Content-type in the headers below.  Let the browser set it. For example
          // Content-Type: multipart/form-data; boundary=----WebKitFormBoundary3sy40ozPAfPAnNRy
          // The browser handles and controls the random string at the end of the boundary
          const headers = {
            Authorization: `Bearer ${this.context.authToken}`
          };

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, targetUrl, formData, headers).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.onRefresh(true);
                this.props.onClose(false);
                ToastService.showSuccess("Newsfeed Saved");
              } else {
                console.error("Error: falsey successResult while saving Newsfeed", successResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
                ToastService.showError("An Error occured while saving the Newsfeed");
              }
            },
            (errorResult) => {
              console.error("Error while saving Newsfeed", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
              ToastService.showError("An Error occured while saving the Newsfeed");
            }
          );
        })
        .catch((erroObj) => {
          console.error("Error caught while saving Newsfeed", erroObj);
          erroObj.inner && erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }


  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  onCompanyChanged = async (_formikProps, _newValues) => {
    // 1) set the newely selUsersList
    this.setState({ selCompanyList: _newValues }); // this is not formik equivalent, but updating just for reference
    // 2) clear others if <All> is selected
    if (DataService.hasElements(_newValues)) {
      await this._clearOthersIfAllIsSelected(_newValues, _formikProps, "selCompanyList");
    }
  };

  // Utils
  _clearOthersIfAllIsSelected = async (_newValues, _formikProps, _propName) => {
    // check for <All> Item
    const itemAll = DataService.getFirstOrDefault(
      _newValues.filter((x) => x.id === LookupService.allElement.id)
    );
    // if <all> is selected and is not the only selected item, then clear others
    if (itemAll) {
      // then clear the others except All
      await _formikProps.setFieldValue(_propName, [itemAll], true);
      this.setState({ [_propName]: [{ id: -1 }] });
    }
  };
  handleDownload = async () => {
    ToastService.showInfo(this.state.newsFeedById.blobName);

    if (this.link.current.href) { return }
    const result = await fetch(
      `${process.env.REACT_APP_TENANT_HTTP}/Newsfeed/DownloadNewsfeedDocument/${this.context.user.tenantId}?blobName=${this.state.newsFeedById.blobName}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.context.authToken}`,
        },
      }
    );
    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);
    this.link.current.download = this.state.newsFeedById.blobName;
    this.link.current.href = href;
    this.link.current.click();
    return;
  };
  render() {
    const { classes } = this.props;

    if (RolePermissionService.NEWSFEED_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent small classes={classes} label="Loading Newsfeed" />);
        case ResultStatus.SAVING:
          return (<PageLoadingComponent small classes={classes} label="Saving Newsfeed" />);
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <>
              <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()}>
                {(fProps) => (
                  <form>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* Dialog Title */}
                      <DialogTitle disableTypography id="dialogTitle">
                        <AppBar position="static">
                          <Toolbar variant="dense">
                            <Typography variant="h6" className={classes.root}>Newsfeed Details</Typography>
                            {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.NEWSFEED_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                              () => { this.props.onClose(false) }, "secondary")
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            }
                          </Toolbar>
                        </AppBar>
                      </DialogTitle>
                      {/* Dialog Content */}
                      <DialogContent>
                        <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 16, paddingBottom: 32, minWidth: "80vh", maxWidth: "80vh", }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "newsfeedType", "Newsfeed Type", this.state.newsfeedTypeList, "newsfeedTypeId", "newsfeedTypeText", "98%")}</Grid>
                            {this.context.user.tenantId === 1 ? <Grid item xs={12} sm={6}> {LayoutService.getChipSelect(this.state.isReadOnly, classes, fProps, "selCompanyList", "Company", this.state.companyList, "id", "text", this.onCompanyChanged, "98%")}</Grid> : null}
                            <Grid item xs={12} sm={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "newsfeedTitle", "Newsfeed Title")} fullWidth /></Grid>
                            <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "startDate", "Start Date", true)} format="yyyy-MM-dd" style={{ minWidth: "98%" }} /></Grid>
                            <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "endDate", "End Date", true)} format="yyyy-MM-dd" style={{ minWidth: "98%" }} /></Grid>

                            <Grid item xs={12} sm={12} style={{ marginTop: 28, marginLeft: 10, marginRight: 0, borderStyle: "solid", borderWidth: 1, borderColor: "#0000004A", height: 312, overflow: "auto" }}>
                              <Editor wrapperClassName="wrapper-class" editorClassName="editor-class" toolbarClassName="toolbar-class" editorState={this.state.editorState} onEditorStateChange={this.onEditorStateChange} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ marginTop: 16 }}>
                              <FormHelperText>Attachment</FormHelperText>
                              <Input type="file" fullWidth onChange={(event) =>
                                this.setState({
                                  selectedFile: event.target.files[0],
                                  selectedFileName: event.target.files[0].name,
                                })
                              } />

                              <div style={{ display: "flex" }}>
                                <Typography variant="body1" className={classes.root} color="secondary" display="inline" >Attached File:</Typography>
                                <Typography variant="body1" className={classes.root} display="inline" >{this.props.inputAction === CrudAction.UPDATE ? this.state.newsFeedById.attachment : ""}</Typography>
                                <a role='button'
                                  style={{ textDecoration: "underline" }}
                                  onClick={this.handleDownload}
                                  ref={this.link}
                                >{LayoutService.getIconButton(this.state.isReadOnly, MatIconService.DOWNLOAD, "Download", () => { }, "secondary", "keyActionCancel")}</a>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormGroup style={{ paddingLeft: "32px", paddingTop: "24px" }}>{LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isActive", "Active")}</FormGroup>
                            </Grid>
                          </Grid>
                        </Box>
                      </DialogContent>
                    </MuiPickersUtilsProvider>
                  </form>
                )}
              </Formik>
            </>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title="Error" description="Error in Newsfeed" classes={classes}
              onClose={() => { this.props.onClose(false); }}
              onRetry={() => { }}
            />
          );
      }
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(NewsfeedDetailsComponent);