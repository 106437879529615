import { of, ReplaySubject } from "rxjs";
import { DataService } from "../../../../shared/services/dataService";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, } from "../../../../shared/types/enums";

export default class NewsFeedDetailsService {
  /** Step-1/3
   * make sure the lookup observable is mentioned here,
   * this is used to fetch commonly used observables after the user logs in,
   * using the rxJs's combine latest operator
   *  */
  static fetchNewsFeedDetailsLookupsOBS() {
    return [
      NewsFeedDetailsService.getNewsFeedByIdAsOBS(),
    ];
  }

  /** STEP-2/3
   * Clear the cache
   * This function should be called after the user Login/Logout,
   * The reson for clering the cache on user login is the lookups are populated for context.user.tenantId,
   */
  static clearAll() {
    NewsFeedDetailsService._ASSIGNEDTOFULLUSER = [];
  }

  /** STEP 3/3
   * Define the fetch logic below
   */
  // _BUSINESSRULE
  static _NEWSFEEDBYID = [];
  static getNewsFeedByIdAsOBS = (_context, newsfeedId) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(NewsFeedDetailsService._NEWSFEEDBYID)) {
      oSubject.next(NewsFeedDetailsService._NEWSFEEDBYID);
    } else {
      // fetch and subscribe to the result
      ApiService.postOBS(
        API_ENDPOINT.TENANT,
        `/Newsfeed/FetchNewsfeedById?tenantId=${_context.user.tenantId}&newsfeedId=${newsfeedId}&isPorzioAdmin=${true}`,
        JSON.stringify({
        }),
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        NewsFeedDetailsService._NEWSFEEDBYID = _data;
        oSubject.next(NewsFeedDetailsService._NEWSFEEDBYID);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

}
