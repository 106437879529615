import React from "react";
import { of, combineLatest, ReplaySubject } from "rxjs";
import { DialogTitle, DialogContent, Box, Typography, Grid, Divider, Dialog, AppBar, Toolbar, DialogContentText } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";
import { AgGridBulkEditUtil } from "../../components/ag-grid/bluk-edit/agGridBulkEditUtil";
import { AuthContext } from "../../store/authProvider";
import { DataService, SubscriptionArray } from "../../services/dataService";
import { CrudAction, ENTITY_TYPE, ResultStatus } from "../../types/enums";
import { MatIconService } from "../../services/theme/matIconService";
import LayoutService from "../../services/layoutService";
import PageLoadingComponent from "../../components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../components/page/dialogErrorFragmentComponent";
import AgreementsService from "./agreementsService";
import { AgGridErroredCheckboxCellRenderer } from "../../components/ag-grid/error-cell-renderers/agGridErroredCheckboxCellRenderer";
import AgGridCheckboxCellRendererComponent from "../../components/elements/agGridCheckboxCellRendererComponent";
import { AgGridColumnExt } from "../../services/ag-grid/agGridColumnExt";
import ToastService from "../../services/toastService";
import AgreementsSearchDialogComponent from "./agreements-search-dialog/agreementsSearchDialogComponent";
import AgreementDetailCellRendererComponent from "../../../private/agreements/agreementDetailCellRendererComponent";
import AgreementDetailDialogComponent from "../../../private/agreements/agreement-detail/agreementDetailDialogComponent";
import RolePermissionService from "../../role-permissions/rolePermissionService";

class AgreementsComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();
  agreementsToDelete = [];// this will store saved/unsaved deleted Agreements

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      selectedAgreements: [],
      fetchResult: ResultStatus.NOT_LOADED,
      isReadOnly: this.props.isReadOnly,
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      porzioSearch: "",
      showSearchAgreementsDialog: false,
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("porzioGSTAgreementID", {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer
      }),
      frameworkComponents: {
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,
        agreementDetailCellRendererComponent: AgreementDetailCellRendererComponent
      },
      selectedPorzioGSTProfileId: this.props.entityType === ENTITY_TYPE.TRANSACTION && this.props.selectedTransaction ?
        this.props.selectedTransaction.porziogstprofileid : null
    };
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    if (this.props.inputAction !== CrudAction.CREATE) {
      this.setState({ fetchResult: ResultStatus.LOADING });
      this.fetchData();
    }
  }

  /** API Fetch */
  fetchData = () => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

    this.apiSubscriptions.add(
      combineLatest([
        // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
        AgreementsService.getAgreementsAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode, this.props.entityType),
      ]).subscribe(
        // success
        ([_data]) => {
          this.setState({
            data: DataService.hasElements(_data) ? _data : [],
            originalAgreementIds: DataService.hasElements(_data) ? _data.map(d => d['porzioGSTAgreementID']) : [],
          },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
              this.state.agGridUtils.sizeColumnsToFit();
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    //if (this.state.agGridUtils.isNotEditing()) {
    this.setState({ showAgreementDetailDialog: true, inputAction: CrudAction.UPDATE, modalAgNode: node });
    //}
  };

  updateGrid = (_selectedAgreement) => {
    let newObj = {};
    newObj = {
      is_selected: false,
      companyAgreementId: _selectedAgreement.companyAgreementId,
      porzioGSTAgreementID: _selectedAgreement.porzioGstAgreementId,
      agreementName: _selectedAgreement.agreementName,
      //agreementType: _selectedAgreement.agreementType,//TODO: pending
      startdate: _selectedAgreement.startDate,
      enddate: _selectedAgreement.endDate,
      executeddate: _selectedAgreement.executedDate,
      // agreementConsent: _selectedAgreement.agreementConsent, //TODO: pending
      agreementdocuments: _selectedAgreement.agreementdocuments //TODO: pending
    };
    this.state.agGridUtils.addNewRow(newObj);
  }

  toggleSubmitButton = (_cellRef, _data) => {
    let hasSelected = false;
    let selectedAgreements = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedAgreements.push(rowNode.data);
        }
      });
    }

    this.setState({
      selectedAgreements: selectedAgreements,
      isReadOnly: !hasSelected,
    });
  }

  handleSubmitClick = (_selectedAgreement) => {
    this.setState({
      showSearchAgreementsDialog: false,
    }, () => {
      this.updateGrid(_selectedAgreement);
    });
  }

  /** 2/4 Required in Parent */
  isDirtyCallback = () => {
    return false;
    // do any additional checkings if needed
    // if (this.fPropsDynamic) {
    //   return this.fPropsDynamic.dirty;
    // } else {
    //   return false;
    // }
  }

  /** 3/4 Required in Parent */
  resetCallback = () => {
    // if (this.fPropsDynamic) {
    //   this.fPropsDynamic.resetForm();
    // }
    // do any additional resetting if needed
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    this.setState({ searchResult: ResultStatus.SAVING });
    var oReturnSubject = new ReplaySubject(); // 1st

    let agIdsToDelete = [];
    if (DataService.hasElements(this.agreementsToDelete)) {
      this.agreementsToDelete.forEach(agmnt => {
        if (this.props.entityType === ENTITY_TYPE.TRANSACTION) {
          agIdsToDelete.push(agmnt);
        }
        else {
          if (this.state.originalAgreementIds.includes(agmnt.porzioGSTAgreementID)) {
            agIdsToDelete.push(agmnt.porzioGSTAgreementID);
          }
        }
      });
    }

    let porzioGSTAgreementIDsToSave = [], companyAgreementIdsToSave = [];
    this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
      if (rowNode.data && rowNode.data.porzioGSTAgreementID && !this.state.originalAgreementIds.includes(rowNode.data.porzioGSTAgreementID)) {
        porzioGSTAgreementIDsToSave.push(rowNode.data.porzioGSTAgreementID);
        companyAgreementIdsToSave.push(rowNode.data.companyAgreementId);
      }
    });

    if (DataService.hasElements(agIdsToDelete)) {
      AgreementsService.getDeleteOBS(this.context.user.tenantId, agIdsToDelete.join(","), this.props.entityType)
        .subscribe(
          (_successResult) => {
            ToastService.showSuccess("Agreement(s) Deleted");
            oReturnSubject.next(true);
          },
          (_errorResult) => {
            ToastService.showError("Error occured while deleting");
            oReturnSubject.next("save_error");
          }
        );
    }

    if (DataService.hasElements(porzioGSTAgreementIDsToSave)) {
      if (this.props.entityType === ENTITY_TYPE.PROFILE) {
        AgreementsService.saveProfileAgreements(this.context.user.tenantId, this.props.modalAgNode.prid, porzioGSTAgreementIDsToSave.join(","))
          .subscribe(
            (_successResult) => {
              ToastService.showSuccess("Agreements Saved");
              oReturnSubject.next(true);
            },
            (_errorResult) => {
              ToastService.showError("Error occured while saving");
              oReturnSubject.next("save_error");
            }
          );
      } else if (this.props.entityType === ENTITY_TYPE.TRANSACTION) {
        AgreementsService.saveTransactionAgreements(this.context.user.tenantId, this.props.modalAgNode.trid, companyAgreementIdsToSave.join(","), porzioGSTAgreementIDsToSave.join(","))
          .subscribe(
            (_successResult) => {
              ToastService.showSuccess("Agreements Saved");
              oReturnSubject.next(true);
            },
            (_errorResult) => {
              ToastService.showError("Error occured while saving");
              oReturnSubject.next("save_error");
            }
          );
      }
    }
    return oReturnSubject.asObservable();
  }

  handleDeleteClick = () => {
    const cannotDelete = ((this.props.entityType === ENTITY_TYPE.PROFILE && RolePermissionService.PROFILE_AGREEMENTS.cannotDelete)
      || (this.props.entityType === ENTITY_TYPE.TRANSACTION && RolePermissionService.TRANSACTION_AGREEMENTS.cannotDelete)
    );
    if (cannotDelete) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      this.setState({ openDeleteConfirm: true });
    }
  }

  handleDeleteConfirm = () => {
    this.setState({ openDeleteConfirm: false, selectedAgreements: [] });
    this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
      if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
        this.props.entityType === ENTITY_TYPE.TRANSACTION ?
          this.agreementsToDelete.push(this.props.modalAgNode.transactionid)
          : this.agreementsToDelete.push(rowNode.data);
        this.state.agGridUtils.gridApi.applyTransaction({ remove: [rowNode.data] });
      }
    });
  }

  // render
  TAB_PERMISSIONS = (this.props.entityType === ENTITY_TYPE.PROFILE ? RolePermissionService.PROFILE_AGREEMENTS : RolePermissionService.TRANSACTION_AGREEMENTS);
  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component
    const componentType = "Agreements";

    const cannotView = (this.TAB_PERMISSIONS.cannotView);
    const isReadOnly = (this.props.isReadOnly || this.TAB_PERMISSIONS.cannotEdit);

    if (cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    }
    else {
      this.state.agGridUtils.disableEditability(isReadOnly);
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle" />
              <DialogContent>
                <React.Fragment key={"addressFragment"}>
                  <Box style={this.props.entityType === ENTITY_TYPE.TRANSACTION ? { padding: "8px", paddingTop: "0px", minHeight: "70vh", maxHeight: "70vh" } :
                    { padding: "8px", paddingTop: "48px", minHeight: "70vh", maxHeight: "70vh" }}>
                    <div id="MainRoleGrid">
                      <Grid container direction="row" justify="flex-end" alignItems="center">
                        {this.props.entityType === ENTITY_TYPE.TRANSACTION ? LayoutService.getIconButton(this.props.isReadOnly || this.TAB_PERMISSIONS.cannotDelete || this.state.selectedAgreements?.length === 0, MatIconService.UNLINK, "Unlink Agreements", this.handleDeleteClick, "secondary", "keyActionDelete") : <></>}
                        {this.props.entityType === ENTITY_TYPE.TRANSACTION ? LayoutService.getIconButton(this.props.isReadOnly || this.TAB_PERMISSIONS.cannotCreate, MatIconService.ADD_CIRCLE_OUTLINE, "Add", () => { this.setState({ showSearchAgreementsDialog: true }) }, "primary") : <></>}
                      </Grid>
                      <div {...LayoutService.getAgGridStyles(400)} >
                        <AgGridReact
                          rowData={this.state.data}
                          columnDefs={AgreementsService.getColumnDefs(this)}
                          frameworkComponents={this.state.frameworkComponents}
                          suppressClickEdit={true}
                          pagination={true}
                          paginationPageSize={100}
                          rowSelection="multiple"
                          suppressRowClickSelection={true}
                          gridOptions={{
                            context: { componentParent: this },
                            suppressContextMenu: true,
                            ...AgGridColumnExt.getGridOptions(40),
                            //...this.state.agGridUtils.bindInlineEditEvents(),
                          }}
                          onGridReady={(params) => {
                            this.state.agGridUtils.sizeColumnsToFit();
                            this.state.agGridUtils.setGridParams(params, true);
                            this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                            this.state.agGridUtils.disableEditability(isReadOnly);
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Divider />
                </React.Fragment>
                {this.state.showSearchAgreementsDialog ?
                  this.state.selectedPorzioGSTProfileId ?
                    <AgreementsSearchDialogComponent
                      open={this.state.showSearchAgreementsDialog || false}
                      onClose={(_reloadRequired) => {
                        this.setState({ showSearchAgreementsDialog: false });
                        if (_reloadRequired) {
                          this.fetchData();
                        }
                      }}
                      onSubmitClick={(_selectedTransaction) => {
                        this.handleSubmitClick(_selectedTransaction);
                      }}
                      selectedPorzioGSTProfileId={this.state.selectedPorzioGSTProfileId}
                    />
                    :
                    <Dialog open={this.state.showSearchAgreementsDialog || false} onClose={(_reloadRequired) => this.setState({ showSearchAgreementsDialog: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                      <DialogTitle id="alert-dialog-title">
                        <AppBar position="static">
                          <Toolbar>
                            <Typography variant="h6" className={classes.root}>Agreement Search</Typography>
                            {LayoutService.getIconButton(false, MatIconService.CANCEL, "Close", () => this.setState({ showSearchAgreementsDialog: false }), "secondary", "keyActionCancel")}
                          </Toolbar>
                        </AppBar>
                      </DialogTitle>
                      <DialogContent>
                        <DialogErrorFragmentComponent small classes={classes} minHeight={'20vh'} description="Profile details unavailable for this transaction" />
                      </DialogContent>
                    </Dialog>
                  : <></>}
              </DialogContent>

              {/* Unlink dialog */}
              {this.state.openDeleteConfirm ?
                <Dialog open={this.state.openDeleteConfirm || false} onClose={() => this.setState({ openDeleteConfirm: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                  <DialogTitle id="alert-dialog-title">
                    <AppBar position="static">
                      <Toolbar>
                        <Typography variant="h6" className={classes.root}>Unlink Confirmation</Typography>
                        {LayoutService.getIconButton(false, MatIconService.OK, "Unlink", this.handleDeleteConfirm, "inherit", "keyActionDelete")}
                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => this.setState({ openDeleteConfirm: false }), "secondary", "keyActionCancel")}
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">Are you sure you want to unlink the selected Agreement(s)?</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h6" className={classes.root} align="center">{this.state.selectedAgreements.map(t => t.porzioGSTAgreementID).join(",")}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
                : <></>}
              {this.state.showAgreementDetailDialog ? <AgreementDetailDialogComponent inputAction={this.state.inputAction} modalAgNode={this.state.modalAgNode}
                open={this.state.showAgreementDetailDialog || false}
                onClose={(_reloadRequired) => {
                  this.setState({ showAgreementDetailDialog: false });
                  if (_reloadRequired) {
                    this.fetchData(true);
                  }
                }}
              /> : null}
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
              onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
          );
      }
    }
  }

}
/** HOC */
export default LayoutService.getHocComponenet(AgreementsComponent);
