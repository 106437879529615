import { of, ReplaySubject } from "rxjs";
import { DataService } from "../../../shared/services/dataService";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT, TenantSelectList } from "../../../shared/types/enums";
import LookupService from "../../../shared/services/lookupService";

export default class EditRulesService {
  /** Step-1/3
   * make sure the lookup observable is mentioned here,
   * this is used to fetch commonly used observables after the user logs in,
   * using the rxJs's combine latest operator
   *  */
  static fetchEditRulesLookupsOBS() {
    return [
      EditRulesService.getBusinessRuleAsOBS(),
      EditRulesService.getJurisdictionAsOBS(),
      EditRulesService.getRuleCategoryAsOBS(),
      EditRulesService.getRuleFieldAsOBS(),
    ];
  }

  /** STEP-2/3
   * Clear the cache
   * This function should be called after the user Login/Logout,
   * The reson for clering the cache on user login is the lookups are populated for context.user.tenantId,
   */
  static clearAll() {
    EditRulesService._BUSINESSRULE = [];
    EditRulesService._JURISDICTION = [];
    EditRulesService._RULECATEGORY = [];
    EditRulesService._RULEFIELDPORZIO = [];
    EditRulesService._RULEFIELDCUSTOM = [];
  }

  /** STEP 3/3
   * Define the fetch logic below
   */
  // _BUSINESSRULE
  static _BUSINESSRULE = [];
  static getBusinessRuleAsOBS = (_context, ruleId) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(EditRulesService._BUSINESSRULE)) {
      oSubject.next(EditRulesService._BUSINESSRULE);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/BusinessRules/FetchBusinessRuleByID?tenantId=${_context.user.tenantId}&ruleId=${ruleId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        EditRulesService._BUSINESSRULE = _data;
        oSubject.next(EditRulesService._BUSINESSRULE);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _JURISDICTION
  static _JURISDICTION = [];
  static getJurisdictionAsOBS = (_context) => {
    var oSubject = new ReplaySubject(); // 1st

    // if local_cache is not Empty
    if (DataService.hasElements(EditRulesService._JURISDICTION)) {
      oSubject.next(EditRulesService._JURISDICTION); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.COUNTRY}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        EditRulesService._JURISDICTION = _data;
        oSubject.next(EditRulesService._JURISDICTION);
      });
    }

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  // _RULECATEGORY
  static _RULECATEGORY = [];
  static getRuleCategoryAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(EditRulesService._RULECATEGORY)) {
      oSubject.next(EditRulesService._RULECATEGORY);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.CURRENCY}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        EditRulesService._RULECATEGORY = _data;
        oSubject.next(EditRulesService._RULECATEGORY);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  //AVAILABLESOURCES
  static _AVAILABLESOURCES = [];
  static getAvailableSourcesAsOBS = (_context, _entityId) => {
    var oSubject = new ReplaySubject();
    ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/SourceSystems/GetSourceByEntity/${_context.user.tenantId}/${_entityId}`
    ).subscribe((_data) => {
      // store it in the local cache, then trigger the subscription at the client side
      EditRulesService._AVAILABLESOURCES = _data;
      oSubject.next(EditRulesService._AVAILABLESOURCES);
    });
    // return the subject as observable
    return oSubject.asObservable();
  };
  // _RULEFIELDPORZIO
  static _RULEFIELDPORZIO = [];
  static getRuleFieldPorzioAsOBS = (_context, _entityId) => {
    var oSubject = new ReplaySubject();

    ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/EntityField/FetchPagedFields?tenantId=${_context.user.tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 200,
        entityId: _entityId,
        searchText: "",
        isCustom: false,
        companyId: 0,
      })
    ).subscribe((_data) => {
      // store it in the local cache, then trigger the subscription at the client side
      EditRulesService._RULEFIELDPORZIO = _data;
      oSubject.next(EditRulesService._RULEFIELDPORZIO);
    });

    // return the subject as observable
    return oSubject.asObservable();
  };

  // _RULEFIELDCUSTOM
  static _RULEFIELDCUSTOM = [];
  static getRuleFieldCustomAsOBS = (_context, _entityId) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    // if (DataService.hasElements(EditRulesService._RULEFIELDCUSTOM)) {
    //   oSubject.next(EditRulesService._RULEFIELDCUSTOM);
    // } else {
    // fetch and subscribe to the result
    ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/EntityField/FetchPagedFields?tenantId=${_context.user.tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 200,
        entityId: _entityId,
        searchText: "",
        isCustom: true,
        companyId: 0,
      })
    ).subscribe((_data) => {
      // store it in the local cache, then trigger the subscription at the client side
      EditRulesService._RULEFIELDCUSTOM = _data;
      oSubject.next(EditRulesService._RULEFIELDCUSTOM);
    });
    //}
    // return the subject as observable
    return oSubject.asObservable();
  };

  // UTILS
  static getValuesByKey(_array, _key = "id") {
    let oRET = [];
    _array.forEach((obj) => {
      oRET.push(obj[_key]);
    });
    return oRET;
  }
}
