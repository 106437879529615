import { AgGridColumnExt } from "../../services/ag-grid/agGridColumnExt";
import { AgGridService } from "../../services/ag-grid/agGridService";
import ApiService from "../../services/apiService"
import { API_ENDPOINT } from "../../types/enums"

export default class PossibleMatchesDialogService {

    static getPossibleMatches = (_tenantId, _recordid, _entityId) => {
        return ApiService.setOBS("GET", API_ENDPOINT.CORE, `/Profiles/FetchPossibleMatches/${_tenantId}/${_recordid}`);
    }

    static getAsMatchCenterRow = (_profileObj = {}) => {
        return [
            {
                "companyProfileId": _profileObj["companyProfileId"],
                "porziogstprofileid": _profileObj["porziogstprofileid"],
                "matchScore": _profileObj["matchScore"],
                "matchKey": _profileObj["matchKey"],
                "profileType": _profileObj["profileType"],
                "lastName": _profileObj["lastName"],
                "firstName": _profileObj["firstName"],
                "middleName": _profileObj["middleName"],
                "usnpinumber": _profileObj["usnpinumber"],
                "uslicensestate": _profileObj["uslicensestate"],
                "usstatelicensenumber": _profileObj["usstatelicensenumber"],
                "usTaxIdNumber": _profileObj["usTaxIdNumber"],
                "organizationName": _profileObj["organizationName"],
                "city": _profileObj["city"],
                "province": _profileObj["province"],
                "postalCode": _profileObj["postalCode"],
                "countryName": _profileObj["countryName"],
                "profileStatusKey": _profileObj["profileStatusKey"],
                "porzioGstIdentifierType": _profileObj["porzioGstIdentifierType"],
                "identifierValue": _profileObj["identifierValue"],
            }
        ];
    }

    static getUnmatchedProfileColumnDefs = (_componentThisRef) => {
        return [
            AgGridColumnExt.GET(false, "text", "companyProfileId", "Company Profile ID").defaultWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").defaultWidth(150).cellRenderer("childMessageRendererComponent").headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "profileType", "Profile Type").defaultWidth(150).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").defaultWidth(150).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").defaultWidth(150).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "middleName", "Middle Name").flexWeight(1).minWidth(136).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "usnpinumber", "US NPI Number").defaultWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "uslicensestate", "US License State").defaultWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usstatelicensenumber", "US License Number").defaultWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usTaxIdNumber", "US Tax ID Number").defaultWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").defaultWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "city", "City").defaultWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "province", "Province").defaultWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "postalCode", "Postal Code").defaultWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "countryName", "Country").defaultWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porzioGstIdentifierType", "Identifier Type").defaultWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "identifierValue", "Identifier Value").defaultWidth(104).headerClass("center-text").centerAlignText().SET(),
        ];
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            {
                headerName: "Select",
                field: "isSelected",
                editable: true,
                width: 80,
                minWidth: 80,
                cellStyle: { "border-right": "1px solid lightgray" },
                cellRenderer: "isSelectedCellRenderer",
                cellRendererParams: { onChange: (_cellRef, _data) => { _onSelectCallback(_data); } }
            },
            AgGridColumnExt.GET(false, "text", "companyProfileId", "Company Profile ID").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").minWidth(150).cellRenderer("childMessageRendererComponent").headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "matchScore", "Match Rate").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "matchKey", "Match Rule").minWidth(250).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "profileType", "Profile Type").minWidth(150).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").minWidth(150).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").minWidth(150).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "middleName", "Middle Name").flexWeight(1).minWidth(136).headerClass("center-text").SET(),
            AgGridColumnExt.GET(false, "text", "usnpinumber", "US NPI Number").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "uslicensestate", "US License State").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usstatelicensenumber", "US License Number").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usTaxIdNumber", "US Tax ID Number").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").minWidth(150).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "city", "City").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "province", "Province").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "postalCode", "Postal Code").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "countryName", "Country").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "profileStatusKey", "Profile Status").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porzioGstIdentifierType", "Identifier Type").minWidth(104).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "identifierValue", "Identifier Value").minWidth(104).headerClass("center-text").centerAlignText().SET(),
        ];
    }

    //---
}