import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, ENTITY_TYPE } from "../../../../shared/types/enums";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class RuleSummaryService {

    static getRuleSummaryAsOBS = (_tenantId, _agreementId) => {//TODO : fileid : need to figure out and pass

        const targetUrl = `​/RuleSummary/FetchRuleSummaryByEntity/${_tenantId}/${ENTITY_TYPE.AGREEMENT}?id=${_agreementId}&pageNumber=1&pageSize=5000`;

        return ApiService.getOBS(
            API_ENDPOINT.CORE,
            targetUrl,
        );
    };

    /**
     * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
     * @param _componentThisRef pass {this} keyword from the calling componenet
     */
    static getColumnDefs(_componentThisRef) {
        return [
            AgGridColumnExt.GET(false, "text", "businessRuleSeverity", "Rule Severity").minAndDefaultWidth(144).valueSetter(this.setRuleViolations).SET(),
            AgGridColumnExt.GET(false, "text", "businessRuleMessage", "Rule Message").flexWeight(6).tooltip().valueSetter(this.setRuleViolations).SET(),
            AgGridColumnExt.GET(false, "text", "fieldValue", "Bad Value").flexWeight(6).tooltip().valueSetter(this.setRuleViolations).SET(),
            AgGridColumnExt.GET(false, "text", "businessRuleName", "Rule Name").flexWeight(4).tooltip().valueSetter(this.setRuleViolations).SET(),
        ];
    }
}