import React, { Component } from "react";
import { AuthContext } from "../../shared/store/authProvider";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../shared/types/enums";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AllCommunityModules } from "ag-grid-enterprise";
import { withStyles, Dialog } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import UserMappingsService from "./userMappingsService";
import LayoutService from "../../shared/services/layoutService";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import AgGridChildMessageRendererComponent from "../../shared/components/ag-grid/agGridChildMessageRendererComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";
import UserMappingDetailComponent from "./user-mapping-details/userMappingDetailComponent";
import IsActiveIconRendererComponent from "../../shared/components/ag-grid/isActiveIconRendererComponent";

class UserMappingsComponent extends Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();
    STORE = UserMappingsService;
    constructor(props) {
        super(props);
        this.handleHide = this.handleHide.bind(this);
        this.state = {
            modules: AllCommunityModules,
            data: [],
            fetchResult: ResultStatus.NOT_LOADED,
            frameworkComponents: {
                isActiveCellRenderer: IsActiveIconRendererComponent,
                childMessageRendererComponent: AgGridChildMessageRendererComponent,
            },
            modalShow: false,
            modalAgNode: null,
            inputAction: CrudAction.NONE,
        };
        this.fetchData.bind(this);
    }

    componentWillUnmount() {
        this.oSubscriptions.cancelAll();
    }
    componentDidMount() {
        this.fetchData();
    }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.oSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
        // save the subscription object
        this.oSubscriptions.add(
            this.STORE.getObs(_ignoreCache, this.context).subscribe(
                (o) => {
                    // onResultStatus.SUCCESS
                    if (!Array.isArray(o)) {
                        o = new Array(o);
                    }
                    this.STORE.CACHE.SET(o, 0);
                    this.setState({
                        data: o.filter(x => x.isPorzioUser),
                        fetchResult: ResultStatus.SUCCESS,
                    });
                },
                (o) => {
                    console.log("Error:", o);
                    // onResultStatus.ERROR
                    this.setState({
                        fetchResult: ResultStatus.ERROR,
                    });
                }
            )
        );
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout("autoHeight");
    };

    methodFromParent = (cell, node) => {
        this.setState({ modalShow: true });
        this.setState({ inputAction: CrudAction.UPDATE });
        this.setState({ modalAgNode: node });
    };

    handleHide() {
        this.setState({ modalShow: false });
    }

    render() {
        const { classes } = this.props;
        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return (
                    <PageLoadingComponent
                        classes={classes}
                        label="Loading User Mappings"
                    />
                );
            case ResultStatus.SUCCESS:
                return (
                    <div id="MainUserMappingsGrid">
                        <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
                        <div className="IconBg marginTop10px tableTitleHead">
                            {/* Header Componenet */}
                            {<PageDynamicHeaderComponent classes={classes} label="User Mappings" rightPadding={16}
                                leftActions={[{ icon: MatIconService.BACK, title: "Back", iconColor: "secondary", onClick: this.props.history.goBack }]}
                                rightActions={[
                                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                                ]}
                            />}
                        </div>
                        <div {...LayoutService.getAgGridStyles()} >
                            {/* <button onClick={this.onButtonClick}>Get selected rows</button> */}
                            <AgGridReact
                                columnDefs={this.STORE.getColumnDefs(this)}
                                rowData={this.state.data}
                                pagination={true}
                                paginationPageSize={50}
                                frameworkComponents={this.state.frameworkComponents}
                                gridOptions={{
                                    context: { componentParent: this },
                                }}
                                onGridReady={event => { event.api.sizeColumnsToFit() }}
                            ></AgGridReact>
                        </div>
                        <Dialog
                            open={this.state.modalShow || false}
                            scroll={true ? "paper" : "body"}
                            maxWidth="md"
                        >
                            <UserMappingDetailComponent
                                onClose={this.handleHide} modalAgNode={this.state.modalAgNode} inputAction={this.state.inputAction}
                                refreshProductList={this.fetchData}
                            />
                        </Dialog>
                    </div>
                );

            case ResultStatus.ERROR:
            default:
                return (
                    <PageErrorComponent
                        label="Error Loading User Mappings"
                        classes={classes}
                        onRetry={() => {
                            this.fetchData(true);
                        }}
                    />
                );
        }
    }
}
export default LayoutService.getHocComponenet(UserMappingsComponent);
