import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";

export default class TransactionsService {

  static getTransactionsByAgreementId(agreementId, userId, tenantId) {
    //const targetUrl = `/Agreement/GetTransactionsByAgreementId?agreementId=${agreementId}&userId=${userId}&tenantId=${tenantId}`;
    const targetUrl = `/Transactions/GetTransactionsByAgreementId/${agreementId}/${userId}/${tenantId}`;
    return ApiService.getOBS(API_ENDPOINT.CORE,targetUrl);
  }

  static getDeleteOBS = (tenantId, transactionId) => {
    return ApiService.deleteOBS(API_ENDPOINT.CORE,
        `/Transactions/DeleteTransactionAgreements?tenantId=${tenantId}&selectedTransactionId=${transactionId}`);
  }

  static saveTransactionData = (postObj) => {
    let url = `/Agreement/SaveAgreementTransactions`;
    return ApiService.postOBS(API_ENDPOINT.CORE, url, JSON.stringify(postObj));
  }

  static getColumnDefs = (_parentThis) => {
    return [
        //TODO : getSelectColumn not being set to editable correctly. Hardcoding it to 'true' for now
        //AgGridBulkEditUtil.getSelectColumn(!_parentThis.props.isReadOnly, _parentThis.toggleSubmitButton),
        //AgGridBulkEditUtil.getSelectColumn(true, _parentThis.toggleSubmitButton),
        AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
            .cellRenderer("isCheckedCellRenderer", { onChange:  _parentThis.toggleSubmitButton }).SET(),
        AgGridColumnExt.GET(false, "text", "companytransactionid", "Company Transaction ID").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "porziogsttransactionid", "Porzio GST Transaction ID").minAndDefaultWidth(136).flexWeight(1).headerClass("center-text").centerAlignText().cellRenderer("childMessageRendererComponent").SET(),
        AgGridColumnExt.GET(false, "text", "form", "Form").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "purpose", "Purpose").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "transactiondate", "Transaction Date").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "number", "convertedtotalamount", "Converted Total Amount").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "transactionconsent", "Transaction Consent").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "transactionDocumnet", "Transaction Document").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
      ];
  }
}
