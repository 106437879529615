import React from "react";
import { userModel } from "../models/userModel";

export const AuthContext = React.createContext();

export function useAuthState() {
  const authStateFromContext = React.useContext(AuthContext);
  // console.log("useAuthState() authStateFromContext: ", authStateFromContext);
  const isPending = authStateFromContext.status === "pending";
  const isError = authStateFromContext.status === "error";
  const isSuccess = authStateFromContext.status === "success";
  const isAuthenticated = authStateFromContext.user && isSuccess;
  if (authStateFromContext.user.username === "elmo") {
    return null;
  } else {
    return {
      ...authStateFromContext,
      isPending,
      isError,
      isSuccess,
      isAuthenticated,
    };
  }
}

function AuthProvider({ children }) {

  let initialUserState = {
    status: "pending",
    error: null,
    user: new userModel(0, "elmo", 0, []),
    firstTime: true,
    authToken: "",
  };

  const [userState, setUserState] = React.useState(initialUserState);

  const setViaLogin = (user, authToken) => setUserState({ status: "success", error: null, user, authToken });

  return (
    <AuthContext.Provider value={{ ...userState, setViaLogin }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
