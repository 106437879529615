import React, { Component } from "react";
import { Dialog } from "@material-ui/core";

import { AllEnterpriseModules } from "ag-grid-enterprise";
import { AuthContext } from "../../shared/store/authProvider";
import ChildMessageRendererComponent from "./childMessageRendererComponent";
import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../shared/services/dataService";
import { CrudAction, ResultStatus, ENTITY_TYPE } from "../../shared/types/enums";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import LayoutService from "../../shared/services/layoutService";
import TransactionExpandedViewComponent from "./expanded-view/transactionsExpandedViewComponent.jsx";
import TransactionCenterService from "./transactionCenterService";
import TransactionDetailDialogComponent from "./transaction-detail/transactionDetailDialogComponent";
import DeleteFileComponent from "../../shared/components/dialog/deleteFileComponent";
import RerunValidationComponent from "./action-menu/rerunValidationComponent";
import AgGridActionCellRendererComponent from "../../shared/components/ag-grid/agGridActionCellRendererComponent";
import AddEditCommentComponent from "../../shared/components/add-edit-comments/addEditCommentComponent";
import CommentAvailableImageRenderer from "../../shared/components/add-edit-comments/commentAvailableImageRenderer";
import AgGridIconTextRendererComponent from "../../shared/components/ag-grid/agGridIconTextRendererComponent";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";
import TransactionSearchDialogComponent from "./action-menu/transaction-search/transactionSearchDialogComponent";
import ToastService from "../../shared/services/toastService";

class transactionCenterComponent extends Component {
  oSubscriptions = new SubscriptionArray();
  static contextType = AuthContext;
  STORE = TransactionCenterService;

  constructor(props) {
    super(props);
    this.state = {
      modules: AllEnterpriseModules,
      data: [],
      fileID: 0,
      col: "",
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        commentIconTextRenderer: AgGridIconTextRendererComponent,
        actionCellRendererComponent: AgGridActionCellRendererComponent,
        childMessageRendererComponent: ChildMessageRendererComponent,
        commentAvailableImageRenderer: CommentAvailableImageRenderer,
      },

      showTransactionExpandedDialog: false,
      showTransactionDetailDialog: false,
      showTransactionSearchDialog: false,
      openCommentFile: false,
      openDeleteFile: false,
      openRerunConfirm: false,
      openAddToRerunValidationQueueConfirm: false,
      agCellData: null,
    };
  }
  componentWillUnmount() { this.oSubscriptions.cancelAll(); }
  componentDidMount() { this.fetchData(); }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) { o = new Array(o); }
          o.map((tran) =>
            tran.transactionFromDate !== undefined
              ? (tran.transactionRange = tran.transactionFromDate + "--" + tran.transactionToDate) : ""
          );
          this.STORE.CACHE.SET(o, 0);
          this.setState({ data: o, fetchResult: ResultStatus.SUCCESS });
        },
        (o) => {
          console.log("Error:" + o);
          // onResultStatus.ERROR
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  methodFromParent = (cell, node) => {
    let _healthIndicator = null;
    switch (cell) {
      case ("error"): _healthIndicator = 1; break;
      case ("warning"): _healthIndicator = 2; break;
      case ("unmatched"): _healthIndicator = 3; break;
      case ("eligible"): _healthIndicator = 4; break;
      case ("nonLicensed"): _healthIndicator = 5; break;
      default: _healthIndicator = null;
    }
    if (RolePermissionService.TRANSACTION_EXPANDED_VIEW.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    } else if (
      (_healthIndicator === 1 && node['error'] === 0) ||
      (_healthIndicator === 2 && node['warning'] === 0) ||
      (_healthIndicator === 3 && node['unmatched'] === 0) ||
      (_healthIndicator === 4 && node['eligible'] === 0) ||
      (_healthIndicator === 5 && node['nonLicensed'] === 0)
    ) {
      ToastService.showWarning('No data found.!');
      return;
    }
    else {
      this.setState({
        healthIndicator: _healthIndicator,
        fileID: node.fileID,
        col: cell,
        modalAgNode: node,
        showTransactionExpandedDialog: true,
      });
    }
  };

  // these are called by the triple-dot Action Menu
  handleAddEditCommentFile = (_cellRef) => {
    this.setState({
      openCommentFile: true,
      agCellData: _cellRef.props.data,
    });
  }
  handleDeleteFile = (_cellRef) => {
    this.setState({
      openDeleteFile: true,
      agCellData: _cellRef.props.data,
    });
  }
  handleRerunValidationConfirm = (_cellRef) => {
    this.setState({
      openRerunConfirm: true,
      agCellData: _cellRef.props.data,
    });
  }
  handleAddToRerunFileValidationQueueConfirm = (_cellRef) => {
    this.setState({
      openAddToRerunValidationQueueConfirm: true,
      agCellData: _cellRef.props.data,
    });
  }

  handleHide = () => { this.setState({ showTransactionDetailDialog: false, showTransactionExpandedDialog: false }); }
  onAddClick = (_transactionToClone = null) => {
    this.setState({
      transactionToClone: _transactionToClone,
      showTransactionDetailDialog: true,
      showTransactionExpandedDialog: false
    });
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
      fileName: `Transactions ${new Date().toDateString()}`
    };
  }
  onSearchClick = () => {
    this.setState({ showTransactionSearchDialog: true });
  }

  render() {
    const { classes } = this.props;

    if (RolePermissionService.TRANSACTION_CENTER.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
        case ResultStatus.SAVING:
          return (<PageLoadingComponent classes={classes} label="Loading Transactions" />);
        case ResultStatus.SUCCESS:
          return (
            <div id="MainTransactionGrid">
              <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
              <div className="IconBg marginTop10px tableTitleHead">
                {/* Header Componenet */}
                {<PageDynamicHeaderComponent classes={classes} label="Transactions" rightPadding={16}
                  leftActions={[
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                  rightActions={[
                    { icon: MatIconService.DOWNLOAD, title: "Download to Excel", iconColor: "primary", onClick: this.onDownloadClick, },
                    { icon: MatIconService.SEARCH, title: "Search", iconColor: "primary", onClick: this.onSearchClick, },
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.onAddClick, isReadOnly: RolePermissionService.TRANSACTION_DETAILS.cannotCreate },
                  ]}
                />}

              </div>
              <div {...LayoutService.getAgGridStyles(160)}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.data}
                  pagination={true}
                  paginationPageSize={100}
                  frameworkComponents={this.state.frameworkComponents}
                  sideBar={true}
                  gridOptions={{
                    headerHeight: 48,
                    tooltipShowDelay: 0,
                    suppressContextMenu: true,
                    context: { componentParent: this },
                  }}
                  onGridReady={(event) => {
                    this.gridApi = event.api;
                    this.gridColumnApi = event.columnApi;
                    event.api.closeToolPanel();
                  }}
                ></AgGridReact>
              </div>

              {/* ExpandedView Dialog rendered as Page */}
              <Dialog {...LayoutService.getContainedDialogProps()} open={this.state.showTransactionExpandedDialog || false}>
                <TransactionExpandedViewComponent inputAction={CrudAction.NONE} modalAgNode={this.state.modalAgNode}
                  fileID={this.state.fileID} column={this.state.col}
                  healthIndicator={this.state.healthIndicator} screenId={39}
                  onClose={(_reloadRequired) => {
                    this.handleHide();
                    if (_reloadRequired) { this.fetchData(true); }
                  }}
                  // clone stuff
                  onClone={(_transactionToClone) => {
                    this.handleHide();
                    this.onAddClick(_transactionToClone);
                  }}
                />
              </Dialog>

              {/* Transaction Dialog as a page On AddNew Click */}
              {!this.state.showTransactionDetailDialog ? null :
                <TransactionDetailDialogComponent standAloneProps={LayoutService.getContainedDialogProps(false)}
                  inputAction={CrudAction.CREATE} modalAgNode={{}}
                  open={this.state.showTransactionDetailDialog || false}
                  onClose={() => this.setState({ showTransactionDetailDialog: false })}
                  // clone stuff
                  onClone={null} // hides the clone icon
                  transactionToClone={this.state.transactionToClone}
                />
              }

              {/* Action Menu Items */}
              {this.state.openCommentFile && this.state.agCellData ?
                <AddEditCommentComponent
                  entityType={ENTITY_TYPE.TRANSACTION}
                  open={this.state.openCommentFile || false}
                  handleClose={(_reloadRequired) => {
                    this.setState({ openCommentFile: false });
                    if (_reloadRequired) {
                      this.fetchData(true);
                    }
                  }}
                  agCellData={this.state.agCellData}
                />
                : null}
              {this.state.openDeleteFile && this.state.agCellData ?
                <DeleteFileComponent
                  entityType={ENTITY_TYPE.TRANSACTION}
                  open={this.state.openDeleteFile || false}
                  handleClose={(isRefresh) => { this.setState({ openDeleteFile: false }); isRefresh && this.fetchData(true); }}
                  agCellData={this.state.agCellData}
                />
                : null}
              {(this.state.openRerunConfirm || this.state.openAddToRerunValidationQueueConfirm) && this.state.agCellData ?
                <RerunValidationComponent
                  reRunFileValidation={this.state.openRerunConfirm}
                  entityType={ENTITY_TYPE.TRANSACTION}
                  open={this.state.openRerunConfirm || this.state.openAddToRerunValidationQueueConfirm}
                  handleClose={() => this.setState({ openRerunConfirm: false, openAddToRerunValidationQueueConfirm: false })}
                  agCellData={this.state.agCellData}
                />
                : null}
              <TransactionSearchDialogComponent
                open={this.state.showTransactionSearchDialog || false}
                onClose={() => this.setState({ showTransactionSearchDialog: false })}
              />
            </div>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent label="Error Loading Transaction Details" classes={classes} onRetry={() => { this.fetchData(true); }} />);
      }
    }
  }
}
export default LayoutService.getHocComponenet(transactionCenterComponent);