import React, { Component } from "react";
import { CircleComponent } from "../../../shared/components/elements/circleComponent/circleComponent.jsx"

export default class DataUnmatchedCellRendererComponent extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        this.props.context.componentParent.dataUnmatchedMethodFromParent(
            `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`, this.props.node.data
        );
    }

    render() {
        let isCellDisabled = false;
        if (this.props.isCellEnabledCallback && !this.props.isCellEnabledCallback(this)) {
            isCellDisabled = true;
        }

        return (
            <span onClick={!isCellDisabled && this.invokeParentMethod} style={isCellDisabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                <CircleComponent value={this.props.value} circleStyle={"circle-unmatched"} />
            </span>
        );
    }
}
