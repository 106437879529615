import { ReplaySubject } from "rxjs";
import { AgGridService } from "../../../../shared/services/ag-grid/agGridService";
import { DataService } from "../../../../shared/services/dataService";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, } from "../../../../shared/types/enums";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class TaskDetailsService {
  /** Step-1/3
   * make sure the lookup observable is mentioned here,
   * this is used to fetch commonly used observables after the user logs in,
   * using the rxJs's combine latest operator
   *  */
  static fetchTaskDetailsLookupsOBS() {
    return [
      TaskDetailsService.getAssignedToFullUserAsOBS(),
      TaskDetailsService.getAllUsersAsOBS(),
    ];
  }

  /** STEP-2/3
   * Clear the cache
   * This function should be called after the user Login/Logout,
   * The reson for clering the cache on user login is the lookups are populated for context.user.tenantId,
   */
  static clearAll() {
    TaskDetailsService._ASSIGNEDTOFULLUSER = [];
    TaskDetailsService._ALLUSERS = [];
    TaskDetailsService._TASKBYID = [];
    TaskDetailsService._TASKCOMMENTSBYID = [];
  }

  /** STEP 3/3
   * Define the fetch logic below
   */
  // _BUSINESSRULE
  static _ASSIGNEDTOFULLUSER = [];
  static getAssignedToFullUserAsOBS = (_context, assignedToUserId) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(TaskDetailsService._ASSIGNEDTOFULLUSER)) {
      oSubject.next(TaskDetailsService._ASSIGNEDTOFULLUSER);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/User/GetFullUser?tenantId=${_context.user.tenantId}&userId=${assignedToUserId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        TaskDetailsService._ASSIGNEDTOFULLUSER = _data;
        oSubject.next(TaskDetailsService._ASSIGNEDTOFULLUSER);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _ENTITY
  static _ALLUSERS = [];
  static getAllUsersAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(TaskDetailsService._ALLUSERS)) {
      oSubject.next(TaskDetailsService._ALLUSERS);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT,
        `/User/GetAllUsers/${_context.user.tenantId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        TaskDetailsService._ALLUSERS = _data;
        oSubject.next(TaskDetailsService._ALLUSERS);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _TASKPRORITY
  static _TASKPRORITY = [];
  static getTaskPrioritiesAsOBS = (_context, _fieldName) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(TaskDetailsService._TASKPRORITY)) {
      oSubject.next(TaskDetailsService._TASKPRORITY);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT,
        `/Util/GetFieldLOVByName/${_context.user.tenantId}/${_fieldName}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        TaskDetailsService._TASKPRORITY = _data;
        oSubject.next(TaskDetailsService._TASKPRORITY);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };


  static _TASKBYID = [];
  static getTaskByIdAsOBS = (_context, taskId) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(TaskDetailsService._TASKBYID)) {
      oSubject.next(TaskDetailsService._TASKBYID);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Task/FetchTaskById?tenantId=${_context.user.tenantId}&taskId=${taskId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        TaskDetailsService._TASKBYID = _data;
        oSubject.next(TaskDetailsService._TASKBYID);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  static _TASKCOMMENTSBYID = [];
  static getTaskGetCommentsByIdAsOBS = (_context, taskId) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(TaskDetailsService._TASKCOMMENTSBYID)) {
      oSubject.next(TaskDetailsService._TASKCOMMENTSBYID);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Task/FetchComments?tenantId=${_context.user.tenantId}&taskId=${taskId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        TaskDetailsService._TASKCOMMENTSBYID = _data;
        oSubject.next(TaskDetailsService._TASKCOMMENTSBYID);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  //#region Address Grid
  static validateEntityFieldRowEditOBS = (_cellRef) => {
    var oSubject = new ReplaySubject(); // 1st

    // static/async validation logic should trigger the below
    oSubject.next({ isSuccess: true }); // true <- success

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
};

static getColumnDefs = (_componentThisRef, _isReadOnly, _agGridUtils) => {
    return [
      // edit button column
      _agGridUtils.getInlineEditActionColumn(false, this.validateEntityFieldRowEditOBS),
      AgGridColumnExt.GET(false, "text", "date", "Date").flexWeight(1).minWidth(90).headerClass("center-text").centerAlignText().tooltip().cellRenderer("dateCellRenderer").SET(),
      AgGridColumnExt.GET(false, "text", "eventType", "Event Type").flexWeight(1).minWidth(110).headerClass("center-text").centerAlignText().tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "user", "User").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().tooltip().SET(),
      AgGridColumnExt.GET(true, "text", "description", "Description").flexWeight(2).minWidth(136).headerClass("center-text").centerAlignText().tooltip().SET(),
    ];
  };

}
