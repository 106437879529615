import Cache from "../../shared/store/cache";
import { ResultStatus, GST_API } from "../../shared/types/enums";
import { SubscriptionArray, DataService } from "../../shared/services/dataService";
import ApiService from "../../shared/services/apiService";


export default class MaterialsListService {
  // subscriptions
  static oSUBSCRIPTIONS = new SubscriptionArray();

  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, context) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(context);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(context) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    return ApiService.fetchHTTP_API(
      GST_API.TenantConfig,
      `/Materials/GetAllMaterials/${context.user.tenantId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  /** API Fetch */
  static fetchData(_component, _ignoreCache = false) {
    this.oSUBSCRIPTIONS.cancelAll();
    _component.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSUBSCRIPTIONS.add(
      this.getObs(_ignoreCache).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          this.CACHE.SET(o);
          _component.setState({
            data: o,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          // onResultStatus.ERROR
          _component.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  }

  static getRows() {
    return [];
  }

  static dateFormatter(params) {
    return new Date(params.value).toLocaleDateString();
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Material Name",
        field: "materialName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        cellRenderer: "childMessageRendererComponent",
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Unit Cost",
        field: "unitCost",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Currency",
        field: "currencyName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Created Date",
        field: "createdDate",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Active?",
        field: "isActive",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        cellRenderer: "isActiveIconRendererComponent",
      },
    ];
  }
}
