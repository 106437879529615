import React from "react";
import { Typography, Box, Toolbar, IconButton, AppBar } from "@material-ui/core";
import { MatIconService } from "../services/theme/matIconService";

export default class SectionComponent extends React.Component {
  render() {
    const { classes, label, menu, add, edit, remove, save, contentJsx, enableEditJsx, marginTop, marginBottom, color } = this.props;
    const _marginTop = marginTop ? marginTop : "10px";
    const _marginBottom = marginBottom ? marginBottom : "0px";
    const _color = color ? color : "primary";

    return (
      <React.Fragment>
        <Box style={{ marginTop: _marginTop, marginBottom: _marginBottom }}>
          <AppBar position="static" elevation={0} color={_color}>
            <Toolbar variant="dense" style={{ height: "30px", minHeight: "30px" }}>
              {menu ? (<IconButton color="primary" edge="start" onClick={this.props.onMenuClick}>{MatIconService.MENU}</IconButton>) : null}
              <Typography align={this.props.align || "left"} variant="h6" color="inherit" className={classes.root}>{label}</Typography>
              {remove ? (<IconButton color="secondary" edge="end" onClick={this.props.onRemoveClick}>{MatIconService.DELETE_RED}</IconButton>) : null}
              {edit ? (<IconButton color="secondary" edge="end" onClick={this.props.onEditClick}>{MatIconService.EDIT}</IconButton>) : null}
              {save ? (<IconButton color="inherit" edge="end" onClick={this.props.onSaveClick}>{MatIconService.OK}</IconButton>) : null}
              {add ? (<IconButton color="secondary" edge="end" onClick={this.props.onAddClick}>{MatIconService.ADD_CIRCLE_OUTLINE}</IconButton>) : null}
              {enableEditJsx}
            </Toolbar>
          </AppBar>
          {contentJsx}
        </Box>
      </React.Fragment>
    );
  }
}