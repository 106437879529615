import React from "react";
import { combineLatest, from, BehaviorSubject } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";

import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, Box, Grid, LinearProgress } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import LayoutService from "../../services/layoutService";
import { SubscriptionArray } from "../../services/dataService";
import PageLoadingComponent from "../page/pageLoadingComponent";
import { API_ENDPOINT, ENTITY_TYPE, ResultStatus } from "../../types/enums";
import { MatIconService } from "../../services/theme/matIconService";
import DialogErrorFragmentComponent from "../page/dialogErrorFragmentComponent";
import LookupService from "../../services/lookupService";
import ToastService from "../../services/toastService";
import { Entity } from "draft-js";
import ApiService from "../../services/apiService";
import { AuthContext } from "../../store/authProvider";


class StatusOverrideComponenet extends React.Component {

    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        // init state
        this.state = {
            apiResult: ResultStatus.LOADED,
            currentStatusId: this.props.currentValue,
            newStausId: this.props.currentValue,
            entityId: this.props.entityId,
            dropDownLabel: this.props.entityId === ENTITY_TYPE.PROFILE ? "Profile Status" : "Transaction Status",
            entityName: this.props.entityId === ENTITY_TYPE.PROFILE ? "Profile" : "Transaction"
        };
    }


    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.setState({ apiResult: ResultStatus.LOADING });

        this.apiSubscriptions.cancelAll();
        this.apiSubscriptions.add(
            combineLatest([
                this.state.entityId === ENTITY_TYPE.PROFILE ? LookupService.getProfileStatusesAsOBS(this.context)
                    : LookupService.getTransactionStatusesAsOBS(this.context)
            ]).subscribe(
                // success
                ([_statuses]) => {
                    this.setState({
                        statuses: _statuses.filter(x => x.id === 5 || x.id === 6),
                        apiResult: ResultStatus.LOADED
                    });
                },
                // error
                (errorObj) => {
                    ToastService.showError("Error while Loading");
                    this.props.onClose(false);
                }
            )
        );
    }

    saveProfileStatus = () => {
        this.setState({ apiResult: ResultStatus.SAVING });

        this.apiSubscriptions.cancelAll();
        setTimeout(() => {
            ToastService.showSuccess("Successfully Saved.");
            this.setState({ apiResult: ResultStatus.SUCCESS });
            this.props.onClose(true);
        }, 5000);
    }
    saveTransactionStatus = () => {
        this.setState({ apiResult: ResultStatus.SAVING });

        this.apiSubscriptions.cancelAll();
        // setTimeout(() => {
        //     ToastService.showSuccess("Successfully Saved.");
        //     this.setState({ apiResult: ResultStatus.SUCCESS });
        //     this.props.onClose(true);
        // }, 5000);
        let ids = this.props.selectedTransactions !== undefined ? [...this.props.selectedTransactions] : [this.props.id];
        let targetUrl = "";
        const selTransIds = ids.join();
        targetUrl = `/Transactions/UpdateTransactionStatus/${this.context.user.tenantId}/${this.state.newStausId}?userId=${this.context.user.userId}`
        ApiService.postOBS(API_ENDPOINT.CORE, targetUrl,JSON.stringify(selTransIds))
            .subscribe(
                (successResult) => {
                    if (successResult) {
                        ToastService.showSuccess("Successfully Saved.");
                        this.setState({ apiResult: ResultStatus.SUCCESS });
                        this.props.onClose(false);
                        this.props.refreshTab();
                    }
                    else {
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                },
                (errorResult) => {
                    ToastService.showError("Error while saving Transaction status");
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            );
        // let targetUrl = `/Transactions/UpdateTransactionStatus/${this.context.user.tenantId}/${this.props.id}?userId=${this.context.user.userId}&transactionStatusId=${this.state.newStausId}`
        // ApiService.postOBS(API_ENDPOINT.CORE, targetUrl)
        //     .subscribe(
        //         (successResult) => {
        //             if (successResult) {
        //                 ToastService.showSuccess("Successfully Saved.");
        //                 this.setState({ apiResult: ResultStatus.SUCCESS });
        //                 this.props.onClose(false);
        //                 this.props.refreshTab();
        //             }
        //             else {
        //                 this.setState({ fetchResult: ResultStatus.ERROR });
        //             }
        //         },
        //         (errorResult) => {
        //             ToastService.showError("Error while saving Transaction status");
        //             this.setState({ fetchResult: ResultStatus.ERROR });
        //         }
        //     );
    }


    // formik
    getInitialValues = () => {
        return { statusId: this.state.currentStatusId ? this.state.currentStatusId : "" };
    }

    validationSchemaObj = Yup.object().shape({});
    getValidationSchema = () => {
        this.validationSchemaObj = Yup.object().shape({
            statusId: LayoutService.getNullableValidation(Yup.number().required("Required"))
        });
        return this.validationSchemaObj;
    }


    render() {
        const { classes } = this.props;
        return (
            <Dialog open={true} scroll={"paper"} fullWidth={true} maxWidth="sm">
                {/* Title */}
                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Override {this.state.entityName} Status</Typography>
                            {LayoutService.getIconButton((this.state.currentStatusId === this.state.newStausId || this.state.apiResult !== ResultStatus.LOADED),
                                MatIconService.OK, "Save", () => {
                                    this.state.entityId === ENTITY_TYPE.PROFILE ? this.saveProfileStatus() : this.saveTransactionStatus()
                                }, "inherit", "keyActionSave")}
                            {LayoutService.getIconButton(this.state.apiResult === ResultStatus.SAVING, MatIconService.CANCEL, "Cancel", () => { this.props.onClose(false); }, "secondary", "keyActionCancel1")}
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                {/* Content */}
                <DialogContent>
                    {this.renderContent(classes)}
                </DialogContent>
            </Dialog>
        );

    }

    renderContent = (classes) => {
        switch (this.state.apiResult) {
            case ResultStatus.LOADING:
            case ResultStatus.NOT_LOADED:
                return <PageLoadingComponent small classes={classes} label="Loading..." />;
            case ResultStatus.SAVING:
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (<>
                    {this.state.apiResult === ResultStatus.SAVING ? <LinearProgress color="secondary" style={{ height: 6 }} /> : null}
                    {/* Form */}
                    <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                        {(fProps) => (
                            <form>
                                <Box style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24, paddingBottom: 16 }}>
                                    {LayoutService.getDropDown(this.state.apiResult !== ResultStatus.LOADED, classes.dialogControl, classes.menuPaper, fProps, this.validationSchemaObj, "statusId", this.state.dropDownLabel,
                                        this.state.statuses, "id", "value", "", false, "98%",
                                        (_fProps, _newStatusId) => {
                                            this.setState({ newStausId: _newStatusId })
                                        })
                                    }
                                </Box>
                            </form>
                        )}
                    </Formik >
                    {/* Alert */}
                    {/* <Grid container direction="column" justify="flex-start" alignItems="stretch" style={{ padding: 0 }}>
                        <Alert severity="warning">
                            <AlertTitle>Note: This is only a temporary override of the Profile Status.</AlertTitle>
                            Any <strong>changes</strong> to the profile will <strong>Reset this Override</strong> and the profile status will be <strong>newley set</strong> based on the <strong>Profile Status matching logic.</strong>
                        </Alert>
                    </Grid> */}
                </>);
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={classes} description="An error occured while Saving" onRetry={() => {
                    this.state.entityId === ENTITY_TYPE.PROFILE ? this.saveProfileStatus() : this.saveTransactionStatus()
                }} />);
        }

    }

    //---
}
export default LayoutService.getHocComponenet(StatusOverrideComponenet);