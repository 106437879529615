import React, { Component } from "react";
import icon_docdownload from "../../public/images/icon_docdownload.png";

export default class DataCenterImportSummaryRenderer extends Component {
    constructor(props) {
        super(props);
        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        this.props.context.componentParent.dataImportSummaryMethodFromParent(
            `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`, this.props.node.data
        );
    }

    render() {
        return <img src={icon_docdownload} alt="Icon Document Download" onClick={this.invokeParentMethod}
            style={{
                marginBottom: 20 + "px", width: 30 + "px",
                display: "block", marginLeft: "auto", marginRight: "auto"
            }}
        />
    }
}
