import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../shared/services/apiService";
import Cache from "../../shared/store/cache";
import { GST_API } from "../../shared/types/enums";

export default class SourceService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();

    return ApiService.fetchHTTP_API(GST_API.Core, `/SourceSystems?tenantId=${clientAuthContext.user.tenantId}&pageNumber=1&rowsPerPage=250`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      AgGridColumnExt.GET(false, "text", "sourceLabel", "Source").flexWeight(2).minWidth(112).tooltip().headerClass("center-text").cellRenderer("childMessageRenderer").SET(),
      AgGridColumnExt.GET(false, true, "sourceTypeName", "Source Type").flexWeight(1).minWidth(112).SET(),
      AgGridColumnExt.GET(false, "text", "businessThreshold", "Business Threshold").fixedWidth(128).headerClass("center-text").centerAlignText().SET(),

      AgGridColumnExt.GET(false, "text", "businessRuleThresholdPercentage", "Business Rule Threshold Percentage").fixedWidth(192).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "unmatchedRecipientThreshold", "Unmatched Recipient Threshold").fixedWidth(176).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "unmatchedRecipientThresholdPercentage", "Unmatched Recipient Threshold Percentage").fixedWidth(192).headerClass("center-text").centerAlignText().SET(),
   
      AgGridColumnExt.GET(false, "text", "active", "Active?").fixedWidth(96).cellRenderer("isActiveIconRendererComponent").SET(),
    ];
  }
}