import React from "react";
import { of, combineLatest } from "rxjs";
import { Dialog, DialogTitle, DialogContent, Box, Typography, AppBar, Toolbar, IconButton, InputBase } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { CrudAction, ENTITY_TYPE, ResultStatus, ReportCategory } from "../../../../shared/types/enums";
import ToastService from "../../../../shared/services/toastService";
import LayoutService from "../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import ComplianceTemplatesService from "./complianceTemplatesService";
import ReportLaunchAgGridRenderer from "../../reportLaunchAgGridRendererComponent";
import ReportSaveComponent from "../../report-save/reportSaveComponent";
import ReportSettingsComponent from "../../report-settings/reportSettingsComponent";

class ComplianceTemplatesComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        reportLaunchAgGridRenderer: ReportLaunchAgGridRenderer
      },
      openSaveReport: false,
    };
  }
  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchReportsData();
  }

  fetchReportsData = () => {
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    ComplianceTemplatesService.getTemplates(this.context.user.tenantId, this.context.user.roleId).subscribe(
      (o) => { this.setState({ data: o, fetchResult: ResultStatus.SUCCESS }); },
      (o) => {
        ToastService.showError("Reports Center Component API Fetch Error:");
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    );
  }

  handleSaveReport = (cell, node) => {
    this.setState({
      modalAgNode: node,
      openSaveReport: true,
    });
  }

  refreshToReportLocation = (_reportId) => {
    console.log("refreshToReportLocation: ", _reportId);
    this.props.history.push(`/reporting/viewer/${_reportId}`);
  }

  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component
    const componentType = "Compliance Templates";

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
      case ResultStatus.SAVING:
        return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            <DialogTitle disableTypography id="dialogTitle" />
            <DialogContent>
              <Box style={{ paddingTop: "8px", paddingBottom: "8px" }}>
                <div {...LayoutService.getAgGridStyles(240)}>
                  <AgGridReact
                    columnDefs={ComplianceTemplatesService.getColumnDefs(this)}
                    rowData={this.state.data}
                    sideBar={true}
                    pagination={true}
                    paginationPageSize={50}
                    frameworkComponents={this.state.frameworkComponents}
                    gridOptions={{
                      context: { componentParent: this },
                      rowHeight: 48,
                    }}
                    onGridReady={event => {
                      event.api.closeToolPanel();
                    }}
                  ></AgGridReact>
                </div>
                {this.state.openSaveReport ?
                  <Dialog open={this.state.openSaveReport || false} fullWidth={true} maxWidth='md' scroll="paper"
                    onClose={() => this.setState({ openSaveReport: false })}
                  >
                    {this.state.modalAgNode?.isGemboxReport ?
                      <ReportSettingsComponent
                        open={this.state.openSaveReport || false}
                        handleClose={() => this.setState({ openSaveReport: false })}
                        modalAgNode={this.state.modalAgNode}
                        category={ReportCategory.COMPLIANCE_TEMPLATES}
                      />
                      :
                      <ReportSaveComponent
                        open={this.state.openSaveReport || false}
                        handleClose={() => this.setState({ openSaveReport: false })}
                        modalAgNode={this.state.modalAgNode}
                        category={ReportCategory.COMPLIANCE_TEMPLATES}
                      />
                    }
                  </Dialog>
                  : null}
              </Box>
            </DialogContent>
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (
          // TODO : add proper close calls
          <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
            onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchReportsData(); }} />
        );
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(ComplianceTemplatesComponent);