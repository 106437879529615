import React from "react";
import { Typography, ListItemIcon, MenuItem, Divider, Menu } from "@material-ui/core";
import { MatIconService } from "../services/theme/matIconService";
import { SELECT_MODE } from "../types/enums";
import { DataService } from "../services/dataService";

export default class AgGridSelectMenuComponent extends React.Component {

    applySelection = (_selectMode) => {
        let rowNodesToRefresh = [];
        
        this.props.agGridUtils.gridApi.forEachNodeAfterFilter((node) => {
            switch (_selectMode) {
                case SELECT_MODE.INVERT:
                    node.data[this.props.selectionColumnName] = !node.data[this.props.selectionColumnName];
                    rowNodesToRefresh.push(node);
                    break;
                case SELECT_MODE.ALL:
                    if (node.data[this.props.selectionColumnName] !== true) {
                        node.data[this.props.selectionColumnName] = true;
                        rowNodesToRefresh.push(node);
                    }
                    break;
                case SELECT_MODE.PAGE:            
                    let api = this.props.agGridUtils.gridApi;
                    const pageSize = api.paginationGetPageSize();
                    const currentPage = api.paginationGetCurrentPage();
                    const startRow = (currentPage ) * pageSize;
                    const endRow = Math.min((currentPage +1) * pageSize, api.paginationGetRowCount());
                    if (node.rowIndex >= startRow && node.rowIndex < endRow) {
                        node.data[this.props.selectionColumnName] = true;
                        rowNodesToRefresh.push(node);
                    }
                        break;
                case SELECT_MODE.NONE:
                default:
                    if (node.data[this.props.selectionColumnName] !== false) {
                        node.data[this.props.selectionColumnName] = false;
                        rowNodesToRefresh.push(node);
                    }
                    break;
            }
        });
        if (DataService.hasElements(rowNodesToRefresh)) {
            this.props.agGridUtils.refreshCells(this.props.agGridUtils.gridApi, rowNodesToRefresh, [this.props.selectionColumnName]);
            if (this.props.onSelectionChange) { this.props.onSelectionChange(); }
        }
    }

    render() {
        return (
            <Menu id="menu-appbar" anchorEl={this.props.anchorEl} keepMounted
                PaperProps={{ style: { width: 256 } }}
                open={this.props.openSelectMenu || false}
                onClose={() => { this.props.onClose(); }}
            >
                <MenuItem onClick={(event) => { this.applySelection(SELECT_MODE.ALL); this.props.onClose(); }}>
                    <ListItemIcon color="primary">{MatIconService.SELECT_ALL}</ListItemIcon>
                    <Typography variant="inherit">Select All</Typography>
                </MenuItem>
                <MenuItem onClick={(event) => { this.applySelection(SELECT_MODE.PAGE); this.props.onClose(); }}>
                    <ListItemIcon color="secondary">{MatIconService.SELECT_PAGE}</ListItemIcon>
                    <Typography variant="inherit">Select Page</Typography>
                </MenuItem>
                <MenuItem onClick={(event) => { this.applySelection(SELECT_MODE.NONE); this.props.onClose(); }}>
                    <ListItemIcon color="secondary">{MatIconService.SELECT_NONE}</ListItemIcon>
                    <Typography variant="inherit">Clear Selection</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={(event) => { this.applySelection(SELECT_MODE.INVERT); this.props.onClose(); }}>
                    <ListItemIcon>{MatIconService.SELECT_PARTIAL}</ListItemIcon>
                    <Typography variant="inherit">Invert Selection</Typography>
                </MenuItem>
            </Menu>
        );
    }
}