import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { AuthContext } from "../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, SELECT_MODE } from "../../../shared/types/enums";

import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import LayoutService from "../../../shared/services/layoutService";
import TenantReportTemplateSubscriptionService from "./tenantReportTemplateSubscriptionService"
import ToastService from "../../../shared/services/toastService";
import AgGridCheckboxCellRendererComponent from "../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

import { FormControl, InputLabel, Select, MenuItem, Divider, } from "@material-ui/core";
import { AgGridUtil } from "../../../shared/services/ag-grid/agGridUtil";
import AgGridSelectMenuComponent from "../../../shared/components/agGridSelectMenuComponent";

class TenantReportTemplateSubscriptionComponent extends Component {

    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            isReadOnly: true,
            fetchResult: ResultStatus.NOT_LOADED,
            complianceTemplateRows: [],

            anchorEl: null,
            openSelectMenu: false,
            agGridUtils: new AgGridUtil("reportName", {
                isViewableCellRenderer: AgGridCheckboxCellRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        this.oSubscriptions.cancelAll();
    }
    componentDidMount() { this.fetchTenants(); }

    fetchTenants = () => {
        this.oSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, selTenantId: '', complianceTemplateRows: [] });

        // save the subscription object
        this.oSubscriptions.add(
            combineLatest([
                TenantReportTemplateSubscriptionService.getActiveTenants(),
            ]).subscribe(
                ([_tenantList]) => {
                    this.setState({
                        tenantList: _tenantList,
                        fetchResult: ResultStatus.SUCCESS
                    });
                },
                (_error) => {
                    ToastService.showError("Error Occured while fetching Tenant List.");
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    }

    /** API Fetch */
    isGridReady = false;
    fetchData = (_tenantId) => {
        this.isGridReady = false;
        this.oSubscriptions.cancelAll();
        this.setState({ isReadOnly: true, fetchResult: ResultStatus.LOADING, complianceTemplateRows: [] });
        if (this.state.selTenantId !== _tenantId) { this.setState({ selTenantId: _tenantId }) }

        if (DataService.isValidNumber(_tenantId)) {
            // save the subscription object
            this.oSubscriptions.add(
                combineLatest([
                    TenantReportTemplateSubscriptionService.fetchTemplates(_tenantId, RolePermissionService.USER_ROLE_ID),
                ]).subscribe(
                    ([_complianceTemplateRows]) => {
                        this.setState({
                            complianceTemplateRows: _complianceTemplateRows,
                            fetchResult: ResultStatus.SUCCESS
                        });
                    },
                    (_error) => {
                        ToastService.showError("Error Occured while fetching reports for the tenant.");
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                )
            );
        }

    };


    getStateIcon = () => {
        if (this.state.selTenantId > 0 && RolePermissionService.IS_TENANT_ADMIN) {
            return this.state.isReadOnly ?
                { title: "edit", icon: MatIconService.EDIT, onClick: () => { this.setState({ isReadOnly: false }) } }
                : { title: "save", icon: MatIconService.OK, iconColor: "primary", onClick: () => { this.postData(); } }
        } else {
            return {};
        }
    }

    render() {
        const { classes } = this.props;
        if (RolePermissionService.isTenantReportSubscriptionsNotAllowed()) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.push(""); },
                "Access Denied", "Logged in Tenant/Role do not have the privileges to access this component. Please contact your Administrator.");
        } else {
            if (this.isGridReady) { this.state.agGridUtils.disableEditability(this.state.isReadOnly); }
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (<PageLoadingComponent classes={classes} label="Loading Report Template Subscriptions" />);
                case ResultStatus.SAVING:
                    return (<PageLoadingComponent classes={classes} label="Saving Report Template Subscriptions" />);
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <div id="MainVendorGrid">
                            <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
                            <div className="IconBg marginTop10px tableTitleHead">
                                <AgGridSelectMenuComponent anchorEl={this.state.anchorEl} openSelectMenu={this.state.openSelectMenu}
                                    agGridUtils={this.state.agReportGridUtils} selectionColumnName={"isViewable"}
                                    onClose={() => { this.setState({ anchorEl: null, openSelectMenu: false }); }}
                                />
                                <PageDynamicHeaderComponent small classes={classes} label="Report Template Subscriptions" rightPadding={24}
                                    leftActions={[
                                        { title: "back", icon: MatIconService.BACK, onClick: () => { this.props.history.goBack() } },
                                        { title: "reload", icon: MatIconService.RELOAD, iconColor: "secondary", onClick: () => { this.fetchData(this.state.selTenantId); } },
                                        {
                                            title: "select", icon: MatIconService.MENU, onClick: (e) => { this.setState({ anchorEl: e.currentTarget, openSelectMenu: true }); },
                                            isReadOnly: this.state.isReadOnly || !this.isGridReady
                                        }
                                    ]}
                                    rightActions={[
                                        {
                                            jsxElement: <FormControl required style={{ minWidth: "384px", marginRight: 24 }}>
                                                <InputLabel>Tenant</InputLabel>
                                                <Select value={this.state.selTenantId || ''}
                                                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    onChange={(e) => this.fetchData(e.target.value)}>
                                                    {(this.state.tenantList || []).map(
                                                        (x, index) => { return <MenuItem value={x.tenantid}>{x.tenantName}</MenuItem> }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        },
                                        { ...this.getStateIcon() },

                                    ]}
                                />
                            </div>

                            {!DataService.isValidNumber(this.state.selTenantId) ?
                                (<>
                                    <Divider />
                                    <DialogErrorFragmentComponent small classes={classes} description={"Please select a Tenant."} />
                                </>)
                                : <div {...LayoutService.getAgGridStyles()}>
                                    {/* Grid Componenet */}
                                    <AgGridReact
                                        columnDefs={TenantReportTemplateSubscriptionService.getColumnDefs(this)}
                                        rowData={this.state.complianceTemplateRows}
                                        pagination={true}
                                        paginationPageSize={50}
                                        frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                        sideBar={true}
                                        gridOptions={{
                                            headerHeight: 48,
                                            suppressContextMenu: true,
                                            context: { componentParent: this },
                                        }}
                                        onGridReady={(params) => {
                                            params.api.closeToolPanel();
                                            this.isGridReady = true;
                                            this.state.agGridUtils.setGridParams(params, true);
                                            this.state.agGridUtils.disableEditability(this.state.isReadOnly);
                                        }}
                                    ></AgGridReact>
                                </div>
                            }
                        </div>
                    );
                case ResultStatus.ERROR:
                default:
                    return (<PageErrorComponent label="Error Loading Report Templates" classes={classes} onRetry={() => { this.fetchData(this.state.selTenantId); }} />);
            }
        }
    }

    currentCategoryId = 1;
    postData = () => {
        this.oSubscriptions.cancelAll();
        let selectedIds = [];
        let allIds = [];
        this.state.agGridUtils.gridApi.forEachNode((node) => {
            allIds.push(node.data["reportId"]);
            if (node.data["isViewable"] === true) {
                selectedIds.push(node.data["reportId"]);
            }
        });

        if (DataService.hasNoElements(allIds)) {
            ToastService.showWarning("No Data to Save");
        } else {
            this.setState({ fetchResult: ResultStatus.SAVING });
            this.oSubscriptions.add(
                TenantReportTemplateSubscriptionService.saveComplianceTemplatesAsOBS(this.state.selTenantId, this.context.user.userId, allIds.join(), selectedIds.join()).subscribe(
                    (success) => {
                        ToastService.showSuccess("Successfully Saved");
                        this.fetchData(this.state.selTenantId);
                    },
                    (error) => {
                        ToastService.showError("Save Failed");
                        this.setState({ fetchResult: ResultStatus.LOADED }); // just show the UI
                    }
                )
            );
        }

    }
}
/** HOC */
export default LayoutService.getHocComponenet(TenantReportTemplateSubscriptionComponent);