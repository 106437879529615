import React from 'react';
import { AppBar, Box, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Toolbar, Typography } from "@material-ui/core";
import { AuthContext } from "../../store/authProvider";
import { MatIconService } from "../../services/theme/matIconService";
import LayoutService from "../../services/layoutService";
import ApiService from "../../services/apiService";
import ToastService from "../../services/toastService";
import PageErrorComponent from "../page/pageErrorComponent";
import { ResultStatus, API_ENDPOINT, ENTITY_TYPE } from "../../types/enums";

class DeleteFileComponent extends React.Component {
    static contextType = AuthContext;

    state = {
        fetchResult: ResultStatus.SUCCESS,
    };

    handleDeleteFile = () => {
        // 1) set the status
        this.setState({ fetchResult: ResultStatus.SAVING });

        var pathParams = "";
        switch (this.props.entityType) {
            case ENTITY_TYPE.TRANSACTION:
                pathParams = `/DataCenter/DeleteFile/${this.context.user.tenantId}/${this.props.agCellData.fileID}`;
                break;
            case ENTITY_TYPE.DATA:
                pathParams = `/DataCenter/DeleteFile/${this.context.user.tenantId}/${this.props.agCellData.fileId}`;
                break;
            default:
                this.setState({ fetchResult: ResultStatus.ERROR });
        }

        // 4) save to Api and subscribe for the result
        ApiService.setOBS("DELETE", API_ENDPOINT.CORE, pathParams, JSON.stringify({})).subscribe(
            (successResult) => {
                if (successResult) {
                    ToastService.showSuccess("Delete File Successful");
                    this.props.handleClose(true);
                } else {
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            },
            (errorResult) => {
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }

    render() {
        const { classes } = this.props;

        switch (this.state.fetchResult) {
            case ResultStatus.SUCCESS:
            case ResultStatus.SAVING:
                return (
                    <div>
                        <Dialog
                            open={this.props.open || false}
                            onClose={() => this.props.handleClose(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <AppBar position="static">
                                    <Toolbar>
                                        <Typography variant="h6" className={classes.root}>Delete File</Typography>
                                        {LayoutService.getIconButton(false, MatIconService.OK, "Delete File", this.handleDeleteFile, "inherit", "keyActionDeleteFile")}
                                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => this.props.handleClose(false), "secondary", "keyActionCancel")}
                                    </Toolbar>
                                </AppBar>
                            </DialogTitle>

                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }}  >
                                        <Grid container spacing={1} style={{ minHeight: "30vh" }}>

                                            {this.state.fetchResult === ResultStatus.SUCCESS  ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.root} align="center">Are you sure you want to Delete this File?</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.root} align="center">{this.props.agCellData.fileName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.root} align="center">This action cannot be reversed</Typography>
                                                    </Grid>
                                                </>
                                                : null}

                                            {this.state.fetchResult === ResultStatus.SAVING ?
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.root} align="center">Delete File for file:</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className={classes.root} align="center">{this.props.agCellData.fileName}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Grid direction="column" container justify="center" alignItems="center">
                                                            <CircularProgress thickness={3} size={"3rem"} color="secondary" />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                : null}

                                        </Grid>
                                    </Box>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </div>
                );
            case ResultStatus.ERROR:
            default:
                ToastService.showSuccess("Delete File Successful");
                this.props.handleClose(true);
                return <></>;
        }
    }
}

export default LayoutService.getHocComponenet(DeleteFileComponent);