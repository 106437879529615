import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class ComplianceReportMappingService {

  static getNonEntityLOVsByIDAsOBS = (_tenantId, _lovEntityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetNonEntityLOVsByID/${_tenantId}/${6}`
    );
  };
  static getNonEntityByTenantIdAsOBS = (_tenantId, _lovEntityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/NonEntityFields/${_tenantId}`
    );
  };
};
