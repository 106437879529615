import { ReplaySubject } from "rxjs";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT, ENTITY_TYPE } from "../../../shared/types/enums";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import DynamicControlService from "../../../shared/services/dynamicControlService";
import { AgGridBulkEditUtil } from "../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import TransactionTabService from "../transaction-detail/transaction-tab/transactionTabService";
import ToastService from "../../../shared/services/toastService";
import { DataService } from "../../../shared/services/dataService";
import { AgGridService } from "../../../shared/services/ag-grid/agGridService";

export default class TransactionExpandedViewService {

  static createBatchProfiles = (_context, _profileSourceId, _selectedTrIds) => {
    //Transactions/CreateRecipientsForTransactions/1376/90
    return ApiService.postOBS(API_ENDPOINT.CORE, `/Transactions/CreateRecipientsForTransactions/${_context.user.tenantId}/${_profileSourceId}/${_context.user.uid}`,
      JSON.stringify(_selectedTrIds),
    );
  };


  static postTransactionCenterExpandedViewAsOBS = (_context, _modalAgNode, _healthIndicator, _screenId) => {

    return ApiService.postOBS(
      API_ENDPOINT.CORE,
      `/Transactions/GetExpandedViewDetails`,
      JSON.stringify({
        tenantId: _context.user.tenantId,
        fileId: parseInt(_modalAgNode.fileID),
        userId: _context.user.userId,
        userType: _context.user.userTypeId,
        healthIndicatorId: parseInt(_healthIndicator),
        sourceId: parseInt(_modalAgNode.sourceID),
        screenId: parseInt(_screenId),
        page: 1,
        pageSize: 100000
      }),
    );
  };

  static getTransactionCenterDisplayedFieldsAsOBS = (_tenantId, _sourceId, _entityId, _userId, screenId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/DisplayedFields/${_tenantId}/${_sourceId}/${_entityId}/${_userId}/${screenId}`
    );
  };

  static getTransactionRuleSummaryAsOBS = (_tenantId, _fileId) => {
    return ApiService.getOBS(API_ENDPOINT.CORE, `/RuleSummary/FetchRuleSummaryByEntity/${_tenantId}/${ENTITY_TYPE.TRANSACTION}?id=0&fileId=${_fileId}&pageNumber=1&pageSize=5000`);
  }

  static validateRowEditOBS = (_cellRef) => {
    var oSubject = new ReplaySubject(); // 1st

    // static/async validation logic should trigger the below
    oSubject.next({ isSuccess: true }); // true <- success

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  static getDeleteOBS = (tenantId, transactionId, userId) => {
    return ApiService.deleteOBS(API_ENDPOINT.CORE,
      `/Transactions/DeleteTransaction/${tenantId}/${transactionId}/${userId}?taskId=0`);
  }

  /** Refer -> https://porzioproducts.atlassian.net/browse/PP2-739 */
  static readonlyColumnNamesInLowerCase = ["profiletype", "profiletypeid", "organizationname", "firstname", "lastname", "middlename", "suffix", "prefix",
    "recipientidentifiercountry", "recipientidentifiercountryid", "recipientidentifiertype", "recipientidentifiertypeid", "recipientidentifiervalue", "recipientcategory", "recipientcategoryid",
    "address1", "address2", "address3", "address4", "city", "province", "postalcode", "country", "countryid", "countryname", "companyaddressid", "porziogstaddressid",
    "credentialsid", "credentials", "specialtyid", "specialty", 'speciality', "specialityid",
    "usstatelicensenumber", "usstatelicensenumber2", "usstatelicensenumber3", "usstatelicensenumber4", "usstatelicensenumber5", "usstatelicensenumber6",
    "uslicensestate", "uslicensestate2", "uslicensestate3", "uslicensestate4", "uslicensestate5", "uslicensestate6",
    "ustaxidnumber", "ustaxidnum", "usnpinumber", "companyprofileid", "customermasterid"
  ];

  // TODO:- sorting for LOVs
  // custom sorting logic for ProfileType field
  // customComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  //   if (DataService.isNullOrUndefined(nodeA.data.profiletype))
  //     return 1;
  //   else if (DataService.isNullOrUndefined(nodeB.data.profiletype))
  //     return -1;
  //   else if (nodeA.data.profiletype === nodeB.data.profiletype) return 0;
  //   return (nodeA.data.profiletype > nodeB.data.profiletype) ? 1 : -1;
  // };

  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
  */
  static getColumnDefs(_componentThisRef, _isParentReadOnly) {

    // console.log(_componentThisRef.state.displayedColumnConfigs);

    let dynamicColumns = [];

    if (RolePermissionService.TRANSACTION_EXPANDED_VIEW.canEdit) {
      dynamicColumns.push(AgGridBulkEditUtil.getSelectColumn(RolePermissionService.TRANSACTION_EXPANDED_VIEW.canEdit, _componentThisRef.toggleSubmitButton));
    }
    // other columns
    dynamicColumns.push(AgGridColumnExt.GET(false, "text", "healthindicator", "Health Indicator", false).fixedWidth(104).headerClass("center-text").cellRenderer("healthIndicatorCellRenderer", {
      hasApprovalFlag: (_props) => { return _props.data.transactionstatusid === 3; } // 3 -> Approved
    }).SET());
    dynamicColumns.push(AgGridColumnExt.GET(false, "text", "ruleviolations", "Rule Violations").minWidth(136).valueSetter((params) => { params.data.ruleviolations = params.newValue; return true; }).SET());
    dynamicColumns.push(AgGridColumnExt.GET(false, "set", "sourcename", "Source System").minWidth(136).flexWeight(1).tooltip().SET());
    dynamicColumns.push(AgGridColumnExt.GET(false, "text", "filename", "File Name").minWidth(136).flexWeight(1).tooltip().SET());
    dynamicColumns.push(AgGridColumnExt.GET(false, "set", "transactionstatus", "Transaction Status").minWidth(136).flexWeight(1).tooltip().SET());

    // dynamic columns
    (_componentThisRef.state.displayedColumnConfigs || []).forEach(colFieldConfig => {

      // make columns readonly
      if (TransactionExpandedViewService.readonlyColumnNamesInLowerCase.includes(colFieldConfig.mappedFieldNameLower)) {
        colFieldConfig.isReadOnly = true;

      }

      if (
        // don't render recipient information fields if restricted
        (RolePermissionService.TRANSACTION_EXPANDED_RECIPIENT_INFORMATION_FIELDS.cannotView &&
          colFieldConfig.grouprenderid === TransactionTabService.recipientInformationFieldGroupKey)
      ) {
        // role have limitted access
      } else {
        const sortableCols = ["piid1", "piid2", "piid3", "piid4", "piid5", "usstatelicensenumber", "companytransactionid", "porziogsttransactionid",
          "transactiondate", "totalamount", "currency", "organizationname", "profiletypeid", "firstname", "lastname", "usnpinumber", "country", "amountinvested"];

        // adding custom comparator for sorting to LOV fields
        // const customSortCols = ["profiletypeid"];
        // if (customSortCols.includes(colFieldConfig.mappedFieldNameLower)) {
        //   colFieldConfig.comparator = this.customComparator;
        // }

        // common configurations
        var colProps = DynamicControlService.getAgGridColumn(_componentThisRef, _isParentReadOnly, colFieldConfig, _componentThisRef.state.transactionRuleSummaries, sortableCols);

        // field specific configurations
        switch (colFieldConfig.mappedFieldNameLower) {
          case "porziogsttransactionid": colProps.editable(false).minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("childMessageRendererComponent"); break;
          case "country": colProps.minWidth(136); break;
          default: break;
        }

        colProps.cellClassRules({
          excelErrorClassName: (params) => AgGridService.getBusinessRuleSeverity(_componentThisRef.state.transactionRuleSummaries, params.colDef.field, params.data.recordid) === 'error',
          excelWarningClassName: (params) => AgGridService.getBusinessRuleSeverity(_componentThisRef.state.transactionRuleSummaries, params.colDef.field, params.data.recordid) === 'warning',
        });

        // finalize and push to the array
        dynamicColumns.push(colProps.SET());
      }


    });

    // RETURN
    return dynamicColumns;
  }


  //---
}