import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";
import { DataService } from "../../../shared/services/dataService";
import { AgGridService } from "../../../shared/services/ag-grid/agGridService";

export default class DataExpandedViewService {

  static postDataCenterExpandedViewAsOBS = (_tenantId, _userId, _modalAgNode, _healthIndicator, _screenId) => {
    return ApiService.postOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/ExpandedView`,
      JSON.stringify({
        sourceId: _modalAgNode.sourceId,
        entityId: _modalAgNode.sourceTypeId,
        fileId: _modalAgNode.fileId,
        healthIndicator: _healthIndicator,
        tenantId: _tenantId,
        isActive: true,
        isDeleted: false,
        searchValue: "",
        filterQuery: "",
        screenId: _screenId,
        userId: _userId,
        taskId: 0,
        rowsPerPage: 100000,
        pageNumber: 1,
      }),
    );
  };

  static getDataCenterDisplayedFieldsAsOBS = (_tenantId, _sourceId, _entityId, _userId,_screenId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/DisplayedFields/${_tenantId}/${_sourceId}/${_entityId}/${_userId}/${_screenId}`
    );
  };

  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
   */
  static getColumnDefs(_componentThisRef) {
    return [
      {
        headerName: "Health Indicator",
        field: "healthindicator",
        editable: false,
        resizable: true,
        valueSetter: (params) => {
          params.data.healthindicator = Number(params.newValue);
          return true;
        },
        cellRenderer: "healthIndicatorCellRendererComponent",
      },
      {
        headerName: "Rule Violations",
        field: "ruleviolations",
        editable: false,
        valueSetter: (params) => {
          params.data.ruleviolations = params.newValue;
          return true;
        },
        resizable: true,
        filter: "text",
      },
      // dynamic column from the Api
      ...AgGridService.getDynamicColumns(_componentThisRef.state.agGridColumns, true, ["porziogstprofileid", "porziogsttransactionid"], [{ crId: "porziogstprofileid", crVal: "dataDetailCellRendererComponent" },{ crId: "porziogsttransactionid", crVal: "dataDetailCellRendererComponent" }]),
      // dropdown column"
      //{ ...AgGridService.getDropdownColumn("Country", "country", DataService.arrayToObject(_componentThisRef.state.countryList, "id", "value")) }
    ];
  }

}
