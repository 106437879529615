import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AuthContext } from "../../../shared/store/authProvider";
import ChildMessageRendererComponent from "../childMessageRendererComponent";
import UserDialogComponent from "./user-details/userDialogComponent";
import UserActiveRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import LayoutService from "../../../shared/services/layoutService";
import { Dialog } from "@material-ui/core";
import UserListStore from "./userListStore";
import ToastService from "../../../shared/services/toastService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class UserListComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = UserListStore;
  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.handleCreateUserModal = this.handleCreateUserModal.bind(this);
    this.handleHideCreateUserModal = this.handleHideCreateUserModal.bind(this);
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        userActiveRendererComponent: UserActiveRendererComponent,
      },
      personalSettingsModalShow: false,
      inputAction: CrudAction.NONE,
    };
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) { o = new Array(o); }
          this.STORE.CACHE.SET(o, 0);
          this.setState({ data: o, fetchResult: ResultStatus.SUCCESS });
        },
        (o) => {
          console.log("Error:", o);
          ToastService.showError("Error while fetching users.");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.setDomLayout("autoHeight");
    this.gridApi.sizeColumnsToFit();
  };

  methodFromParent = (cell, data) => {
    if (RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      this.setState({
        personalSettingsModalShow: true,
        userId: data.id,
        inputAction: CrudAction.UPDATE,
      });
    }
  };

  handleCreateUserModal() {
    if (RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION.cannotCreate) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      this.setState({ personalSettingsModalShow: true, inputAction: CrudAction.CREATE });
    }
  }
  handleHideCreateUserModal() {
    this.setState({ personalSettingsModalShow: false });
  }

  handleHide() {
    this.setState({ personalSettingsModalShow: false });
  }

  render() {
    const { classes, history } = this.props;
    if (RolePermissionService.USER_LIST.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack(); });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent classes={classes} label="Loading Users" />;
        case ResultStatus.SUCCESS:
          return (
            <div id="MainUsersGrid">
              <div className="IconBg marginTop10px tableTitleHead">
                <PageDynamicHeaderComponent classes={classes} label="Users" rightPadding={16}
                  leftActions={[
                    { title: "Go Back", icon: MatIconService.BACK,  onClick: () => { this.props.history.goBack(); } },
                    { title: "Reload", icon: MatIconService.RELOAD, iconColor: "secondary", onClick: () => { this.fetchData(true); } }
                  ]}
                  rightActions={[
                    { icon: MatIconService.DOWNLOAD, title: "Export", onClick: () => { }, isReadOnly: RolePermissionService.USER_LIST_EXPORT.cannotView },
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Create User", onClick: this.handleCreateUserModal, isReadOnly: RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION.cannotCreate }
                  ]}
                />

              </div>
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.data}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  onGridReady={this.onGridReady}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                ></AgGridReact>
              </div>
              <Dialog open={this.state.personalSettingsModalShow || false} scroll={true ? "paper" : "body"} maxWidth="md" fullWidth style={{ minHeight: "80vh", maxHeight: "80vh", }}>
                <UserDialogComponent onClose={this.handleHide} userId={this.state.userId} refreshParentGrid={this.fetchData} inputAction={this.state.inputAction} />
              </Dialog>
            </div>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent label="Error Loading User List" classes={classes} onRetry={() => { this.fetchData(true); }} />);
      }
    }
  }
}

export default LayoutService.getHocComponenet(UserListComponent);
