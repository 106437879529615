import React from "react";
import { AppBar, Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import ProfileTransactionComponent from "./profile-transaction/profileTransactionComponent";
import LayoutService from "../../../shared/services/layoutService";
import TaskDetailsComponent from "../../home/task/task-details/taskDetailsComponent";
import ProfileTabComponent from "./tabs/profileTabComponent";
import RuleSummaryComponent from "./rule-summary/ruleSummaryComponent";
import AgreementsComponent from "../../../shared/components/agreements/agreementsComponent";
import ProfileConsentComponent from "./consent/profileConsentComponent";
import LinkedProfilesComponent from "./linked-profiles/linkedProfilesComponent";
import IdentifierComponent from "./identifiers/identifierComponent";
import DocumentsComponent from "./documents/documentsComponent";
import ReferenceDataComponent from "./reference-data/referenceDataComponent";
import { CrudAction, TaskLauncher, ENTITY_TYPE, API_ENDPOINT } from "../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import ActionDialog from "../../../shared/components/dialog/actionDialog";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import PossibleMatchesDialogComponent from "../../../shared/components/possible-matches/possibleMatchesDialogComponent";
import MatThemeService from "../../../shared/services/theme/matThemeService";
import LinkedProfilesService from "./linked-profiles/linkedProfilesService";
import ApiService from "../../../shared/services/apiService";
import ToastService from "../../../shared/services/toastService";
import { AuthContext } from "../../../shared/store/authProvider";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import RolePermissionInfo from "../../../shared/role-permissions/rolePermissionInfo";
import LookupService from "../../../shared/services/lookupService";

class ProfileDetailDialogComponent extends React.Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        this.tabIndexMap = new Map();
        this.tabIndexMap.set(0, { ref: null });
        this.tabIndexMap.set(1, { ref: null });
        this.tabIndexMap.set(2, { ref: null });
        this.tabIndexMap.set(3, { ref: null });
        this.tabIndexMap.set(4, { ref: null });
        this.tabIndexMap.set(5, { ref: null });
        this.tabIndexMap.set(6, { ref: null });
        this.tabIndexMap.set(7, { ref: null });

        this.state = {
            isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
            isNew: this.props.inputAction === CrudAction.CREATE,
            isEditing: this.props.inputAction === CrudAction.UPDATE,

            isDialog: this.props.standAloneProps ? false : true,
            derivedProps: this.props.standAloneProps ? this.props.standAloneProps : { fullWidth: true, maxWidth: "lg" },

            selectedTabIndex: 0,
            showTaskDialog: false,
            showPossibleMatchesDialog: false,
            profileTabLoaded: false,
            selectedProfile: null,
            showPossibleMatchesIcon: false,

            parentProfile: "",
            selectedPossibleMatchProfile: ""
        };
    }

    onClose = (_param) => {
        this.setState({ isReadOnly: true, selectedProfile: null }, () => {
            this.props.onClose(_param);
        });
    }

    setReadOnlyMode = (_value) => {
        this.setState({
            isReadOnly: _value // on tab change make it readonly
        });
    }

    _changeTab = (_newTabIndex) => {
        this.setState({
            selectedTabIndex: _newTabIndex,
            isReadOnly: true // on tab change make it readonly
        });
    }
    _changeToReadOnlyMode = () => {
        this.setState({ isReadOnly: true });
    }

    returnToProfileCenter = () => {
        this.setState({ isReadOnly: true }, () => {
            this.props.closeParentCallback();
        });

    }

    onTabChange = (event, _newTabIndex) => {
        const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
        if (currentTabRef) {
            if (currentTabRef.isDirtyCallback()) {
                this.setState({ showConfirmDialog: true, newTabIndex: _newTabIndex });
            } else {
                this.setState({ showConfirmDialog: false }, () => {
                    this._changeTab(_newTabIndex);
                });
            }
        } else {
            this.setState({ showConfirmDialog: false }, () => {
                this._changeTab(_newTabIndex);
            });
        }
    };

    onSave = () => {
        this.oSubscriptions.cancelAll();
        const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;

        if (currentTabRef.postCallbackOBS) {
            this.setState({ isSaving: true });
            this.oSubscriptions.add(currentTabRef.postCallbackOBS().subscribe(
                (_successResult) => {
                    this.setState({ isSaving: false, saveErrorInfo: null });
                    if (_successResult === "validation_error") {
                        this.flashError("Validation Failed");
                    } else if (_successResult === "save_error") {
                        this.flashError("Save Failed");
                    } else if (_successResult === "duplicate_error") {
                        //this.flashError("Profile with this Company Profile ID already exists");
                        this.setState({ isReadOnly: false, saveErrorInfo: "Profile with this Company Profile ID already exists" });
                    } else {
                        if (this.state.isNew) {
                            this.onClose(_successResult); // close this and pass the successResult to the caller
                        } else {
                            // stay on the same screen as per PP2-871
                        }
                        currentTabRef.resetCallback(true);
                    }
                },
                (_error) => {
                    this.flashError("Error Occured while Saving");
                }
            ));
        }
        this._changeToReadOnlyMode();
    }

    linkSelectedProfile = (_selectedProfile) => {
        this.setState({ isSaving: true });
        this.oSubscriptions.add(
            LinkedProfilesService.getLinkedProfilesAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode, this.state.parentProfile, this.state.selectedPossibleMatchProfile)
                .subscribe(
                    (_linkedProfilesSet) => {
                        this.saveForPossibleMatches(_linkedProfilesSet);
                        this.markAsPermenant();
                    }, (_errorResult) => {
                        this.setState({ isSaving: false });
                        ToastService.showError("Save Failed");
                        this.flashError("Error Occured while Saving");
                    }
                )
        );
    }

    saveForPossibleMatches = (_linkedProfilesSet) => {
        let t = this;
        let profileJson = _linkedProfilesSet.map(function (entry) {
            entry.RECORDID = t.props.modalAgNode.recordid;
            return entry;
        });

        const stringifiedJson = DataService.addEscapeCharactersToJsonString(profileJson);
        return this.oSubscriptions.add(
            ApiService.postOBS(
                API_ENDPOINT.CORE,
                `/Profiles/SaveLinkedProfiles?prid=${this.state.parentProfile}&tenantId=${this.context.user.tenantId}&userId=${this.context.user.userId}`,
                stringifiedJson
            ).subscribe(
                (_successResult) => {
                    this.setState({ isSaving: false });
                    ToastService.showSuccess("Saved Successfully");
                    this.returnToProfileCenter();
                }, (_errorResult) => {
                    this.setState({ isSaving: false });
                    ToastService.showError("Save Failed");
                    this.flashError("Error Occured while Saving");
                }
            )
        );
    }

    markAsPermenant = () => {
        this.setState({ isSaving: true, showPossibleMatchesDialog: false });
        this.oSubscriptions.add(
            ApiService.postOBS(
                API_ENDPOINT.CORE,
                `/Profiles/UpdateProfileStatus/${this.context.user.tenantId}/${this.props.modalAgNode.prid}?userId=${this.context.user.userId}`)
                .subscribe(
                    (_successResult) => {
                        this.setState({ isSaving: false, isReadOnly: true });
                        ToastService.showSuccess("Saved Successfully");
                    }, (_errorResult) => {
                        this.setState({ isSaving: false });
                        ToastService.showError("Save Failed");
                        this.flashError("Error Occured while Saving");
                    }
                )
        );
    }

    flashError = (_error) => {
        console.log("Error while Saving", _error);
        this.setState({ isSaving: false, saveErrorInfo: _error }, () => {
            setTimeout(() => { this.setState({ saveErrorInfo: null }); }, 5000);
        });
    }

    handleProfileTabLoaded = (_selectedProfile) => {
        this.setState({
            profileTabLoaded: true,
            selectedProfile: _selectedProfile,
            showPossibleMatchesIcon: this.props.inputAction === CrudAction.UPDATE && _selectedProfile?.isrecipientmatch === false
        });
    }

    getUnmatchedProfileObj = (_selectedProfile) => {
        // this.state.selectedProfile
        // const profileType = ProfileTabService.recipinetProfileTypeLovOptions.find(x => x.lovId === this.fPropsDynamic.values["profiletypeid"]);
        // const recipientIdentifierType = ProfileTabService.recipinetIdentifierTypeLovOptions.find(x => x.lovId === this.fPropsDynamic.values["recipientidentifiertypeid"]);

        // object for PossibleMatchesDialogService->getAsMatchCenterRow()
        if (DataService.isNullOrUndefined(_selectedProfile)) {
            return {};
        } else {
            let primaryAddr = _selectedProfile.addresslist.find(x => x.isprimary);
            const countryObj = LookupService._COUNTRIES.find(c => c.id === primaryAddr?.countryId);
            let identifierInfo = DataService.hasElements(_selectedProfile.identifierlist) ?
                _selectedProfile.identifierlist.reduce((p, c) => (+p.profileIdentifierId > +c.profileIdentifierId) ? p : c) : [];

            return {
                "companyProfileId": DataService.getStringOrDefault(_selectedProfile.companyprofileid),
                "porziogstprofileid": DataService.getStringOrDefault(_selectedProfile.porziogstprofileid),
                "prid": DataService.getStringOrDefault(_selectedProfile.profileid),
                "matchScore": "",
                "matchKey": "",
                "profileType": DataService.getStringOrDefault(_selectedProfile.profiletype),
                "lastName": DataService.getStringOrDefault(_selectedProfile.lastname),
                "firstName": DataService.getStringOrDefault(_selectedProfile.firstname),
                "middleName": DataService.getStringOrDefault(_selectedProfile.middlename),
                "usnpinumber": DataService.getStringOrDefault(_selectedProfile.usnpinumber),
                "uslicensestate": DataService.getStringOrDefault(_selectedProfile.uslicensestate),
                "usstatelicensenumber": DataService.getStringOrDefault(_selectedProfile.usstatelicensenumber),
                "usTaxIdNumber": DataService.getStringOrDefault(_selectedProfile.ustaxidnumber),
                "organizationName": DataService.getStringOrDefault(_selectedProfile.organizationname),
                "city": DataService.getStringOrDefault(primaryAddr?.city),
                "province": DataService.getStringOrDefault(primaryAddr?.province),
                "postalCode": DataService.getStringOrDefault(primaryAddr?.postalcode),
                "countryName": DataService.getStringOrDefault(countryObj?.value),
                "profileStatusKey": DataService.getStringOrDefault(_selectedProfile.profilestatus),
                "porzioGstIdentifierType": DataService.getStringOrDefault(identifierInfo?.porzioGSTIdentifierType?.localLovKey),
                "identifierValue": DataService.getStringOrDefault(identifierInfo?.identifierValue),
            };
        }

    }


    render() {
        const { classes } = this.props;
        const TAB_REF = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
        const TAB_PERMISSIONS = TAB_REF ? (TAB_REF.TAB_PERMISSIONS ? TAB_REF.TAB_PERMISSIONS : RolePermissionInfo.INIT()) : RolePermissionInfo.INIT();
        const ref = document.getElementById('drawer-container');

        return (
            <>
                {/* tab dialog */}
                <Dialog {...this.state.derivedProps} open={this.props.open || false} onClose={() => { this.onClose(false) }}>

                    {/* DialogTitle */}
                    {this.state.isDialog ?
                        <DialogTitle disableTypography id="dialogTitle">
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" className={classes.root}>Profile Details</Typography>
                                    {this.state.showPossibleMatchesIcon && this.state.profileTabLoaded ?
                                        LayoutService.getIconButton(this.props.isReadOnly, MatIconService.IDEA_BULB_32, "Manual Match Center", () => {
                                            this.setState({ showPossibleMatchesDialog: true })
                                        })
                                        : <></>}
                                    <IconButton color="inherit" onClick={() => { this.setState({ showTaskDialog: true }); }}>{MatIconService.TASK_ADD}</IconButton>
                                    {LayoutService.getReadOnlyActions(this, (this.props.inputAction === CrudAction.CREATE), () => { this.onClose(false) }, () => { this.onSave(); })}
                                </Toolbar>
                            </AppBar>
                        </DialogTitle>
                        :
                        <PageDynamicHeaderComponent classes={classes} label="Profile Details" divider rightPadding={16}
                            leftActions={[
                                { icon: MatIconService.BACK, onClick: () => { this.props.onClose(false);this.state.selectedProfile=null; }, title: "Go back", iconColor: "secondary" },
                            ]}
                            rightActions={[
                                this.state.showPossibleMatchesIcon && this.state.profileTabLoaded ?
                                    {
                                        icon: MatIconService.IDEA_BULB_32, title: "Manual Match Center", onClick: () => { this.setState({ showPossibleMatchesDialog: true }); },
                                        isReadOnly: RolePermissionService.PROFILE_DETAILS.cannotEdit
                                    } : null,
                                {
                                    icon: MatIconService.TASK_ADD, title: "Create Task", onClick: () => { this.setState({ showTaskDialog: true }); },
                                    isReadOnly: RolePermissionService.PROFILE_TASK.cannotCreate
                                },
                                this.state.isReadOnly ?
                                    { icon: MatIconService.EDIT, title: "Edit", onClick: () => { this.setState({ isReadOnly: false }); }, isReadOnly: TAB_PERMISSIONS.cannotCreateOrEdit } :
                                    { icon: MatIconService.OK, title: "Save", iconColor: "primary", onClick: () => { this.onSave(); }, isReadOnly: TAB_PERMISSIONS.cannotCreateOrEdit },
                            ]}
                        />
                    }

                    {this.state.isSaving ? <LinearProgress color="secondary" /> : null}
                    {this.state.saveErrorInfo ? <Typography style={{ backgroundColor: "#FF8A8A", color: "#6E0101" }} variant="h6" align="center">{this.state.saveErrorInfo}</Typography> : null}

                    {/* DialogContent */}
                    <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto"
                        style={{ backgroundColor: this.state.isDialog ? null : MatThemeService.getTabBG() }}>

                        <Tab label="Profile" id="profileTab" />
                        <Tab disabled={!this.state.isEditing} hidden={true} label="Rule Summary" id="ruleSummaryTab" />
                        {/* <Tab hidden={!this.state.isEditing || RolePermissionService.PROFILE_AFFILICATIONS.cannotView} label="Affiliations" id="affiliationsTab" /> */}
                        <Tab disabled={!this.state.isEditing} hidden={!this.state.isEditing} label="Agreements" id="agreementsTab" />
                        <Tab disabled={!this.state.isEditing} hidden={!this.state.isEditing} label="Consents" id="consentTab" />
                        <Tab disabled={!this.state.isEditing} hidden={!this.state.isEditing} label="Transactions" id="transactionsTab" />
                        <Tab disabled={!this.state.isEditing} hidden={!this.state.isEditing} label="Linked Profiles" id="linkedProfilesTab" />
                        <Tab hidden={!this.state.isEditing} label="Reference Data" id="referenceDataTab"
                            disabled={(this.state.selectedProfile && this.state.selectedProfile.profilecategoryid == null) || !this.state.isEditing} />
                        <Tab disabled={!this.state.isEditing} hidden={!this.state.isEditing} label="Documents" id="documentsTab" />
                        {/* <Tab hidden={!this.state.isEditing} label="Identifiers" id="identifiersTab" /> */}
                    </Tabs>
                    <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id={"profileTabPanel"} >
                        <Divider />
                        <Box style={{ padding: "0px" }} >
                            <ProfileTabComponent tabConfig={this.tabIndexMap.get(0)} isReadOnly={!this.state.isNew && this.state.isReadOnly}
                                recipientProfileInfo={this.props.recipientProfileInfo}
                                inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} from={"Profiles"}
                                onProfileTabLoaded={(_selectedProfile) => { this.handleProfileTabLoaded(_selectedProfile) }}
                                setReadOnlyMode={this.setReadOnlyMode}
                            />
                        </Box>
                    </div>
                    {(this.state.isEditing) ?
                        <React.Fragment>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 1} id={"ruleSummaryTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <RuleSummaryComponent tabConfig={this.tabIndexMap.get(1)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 2} id={"agreementsTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <AgreementsComponent entityType={ENTITY_TYPE.PROFILE} tabConfig={this.tabIndexMap.get(2)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 3} id={"consentTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <ProfileConsentComponent onClose={this.onClose} tabConfig={this.tabIndexMap.get(3)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 4} id={"transactionsTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <ProfileTransactionComponent tabConfig={this.tabIndexMap.get(4)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>

                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 5} id={"linkedProfilesTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <LinkedProfilesComponent tabConfig={this.tabIndexMap.get(5)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} changeToReadOnlyMode={this._changeToReadOnlyMode} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 6} id={"referenceDataTabPanel"} >
                                <Divider />
                                {this.state.selectedProfile ?
                                    <Box style={{ padding: "0px" }} >
                                        <ReferenceDataComponent tabConfig={this.tabIndexMap.get(6)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} changeToReadOnlyMode={this._changeToReadOnlyMode} selectedProfile={this.state.selectedProfile} />
                                    </Box>
                                    : null}
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 7} id={"documentsTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <DocumentsComponent tabConfig={this.tabIndexMap.get(7)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} entityType={ENTITY_TYPE.PROFILE} />
                                </Box>
                            </div>

                            {/* Possible Matches Dialog */}
                            <Dialog open={this.state.showPossibleMatchesDialog || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                                <PossibleMatchesDialogComponent
                                    unmatchedProfileObj={this.getUnmatchedProfileObj(this.state.selectedProfile)}
                                    recordId={this.props.modalAgNode !== undefined ? "" + this.props.modalAgNode.recordid : ""}
                                    entityId={ENTITY_TYPE.PROFILE}
                                    markAsPermenant={this.markAsPermenant}
                                    onClose={(_selectedProfile) => {
                                        if (DataService.isNullOrUndefined(_selectedProfile)) {
                                            this.setState({ showPossibleMatchesDialog: false });
                                        }
                                        else {
                                            this.setState({
                                                showPossibleMatchesDialog: false, parentProfile: _selectedProfile["parentprid"] === null ?
                                                    _selectedProfile["prid"] + "" : _selectedProfile["parentprid"] + "",
                                                selectedPossibleMatchProfile: "" + _selectedProfile["prid"]
                                            }, () => {
                                                this.linkSelectedProfile(_selectedProfile);
                                            });
                                        }
                                    }}
                                />
                            </Dialog>
                        </React.Fragment>
                        : <></>}


                </Dialog>

                {/* taskDialog */}
                {
                    this.state.showTaskDialog ?
                        <TaskDetailsComponent showTaskDialog={this.state.showTaskDialog} inputAction={CrudAction.CREATE}
                            taskLauncher={TaskLauncher.PROFILE_DETAILS} refreshTaskList={() => { }} updateData={null}
                            onClose={() => this.setState({ showTaskDialog: false })}
                            porzioGstIds={this.props.modalAgNode ? [this.props.modalAgNode.porziogstprofileid] : []} />
                        : null
                }


                {/* discard action dialog */}
                <ActionDialog showDialog={this.state.showConfirmDialog} title="Discard changes?" description="There are unsaved changes" actions={[
                    {
                        color: "secondary", text: "Discard", icon: null,
                        callback: () => {
                            const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
                            
                            currentTabRef.resetCallback();

                            this.setState({ showConfirmDialog: false }, () => {
                                this._changeTab(this.state.newTabIndex);
                            })
                        }
                    },
                    {
                        color: "inherit", text: "Cancel", icon: null,
                        callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
                    }
                ]} />
            </>
        );
    }
}
export default LayoutService.getHocComponenet(ProfileDetailDialogComponent);