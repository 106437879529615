import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, DialogTitle, DialogContent, Typography, AppBar, Toolbar, IconButton, TextField, Grid, FormGroup } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT, } from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import LayoutService from "../../../shared/services/layoutService";
import LookupService from "../../../shared/services/lookupService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../shared/services/apiService";

class NewTenantComponent extends React.Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    // vendor name validations
    lastValidatedTenantName = null;
    lastValidatedTenantResult = false;
    lastValidatedUIDName = null;
    lastValidatedUIDResult = false;

    constructor(props) {
        super(props);
        // init state
        this.state = {
            isReadOnly: false,
            isEditing: true,
            fetchResult: ResultStatus.NOT_LOADED
        };
    }

    componentWillUnmount() { this.oSubscriptions.cancelAll(); }
    componentDidMount() {

        this.setState({ fetchResult: ResultStatus.LOADING });

        /**
         * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
         * this operator is very handy as we don't have to independently wait for each result
         */
        this.oSubscriptions.add(
            combineLatest([
                LookupService.getFormattedCountriesAsOBS(this.context, null),
                LookupService.getLanguagesAsOBS(this.context),
                LookupService.getFormattedCurrenciesAsOBS(this.context, null),
                LookupService.getTimezonesAsOBS(this.context)
            ]).subscribe(([_countryList, _languageList, _currencyList, _timeZoneList]) => {
                this.setState(
                    {
                        countryList: _countryList,
                        languageList: _languageList,
                        currencyList: _currencyList,
                        timeZoneList: _timeZoneList,
                    },
                    () => {
                        // change the state after all the above are assigned
                        this.setState({ fetchResult: ResultStatus.LOADED });
                    }
                );
            }
            )
        );
    }

    handleSubmit = async (_formikProps) => {
        if (!_formikProps.isSubmitting && _formikProps.isValid) {
            await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
                .then((x) => {
                    this.setState({ fetchResult: ResultStatus.SAVING });
                    var mappedObj = {
                        tenantid: _formikProps.values.tenantid,
                        tenantName: _formikProps.values.tenantName,
                        tenantDescription: _formikProps.values.tenantDescription,
                        uid: _formikProps.values.uid,
                        tenantType: _formikProps.values.tenantType,
                        tenantAddress1: _formikProps.values.tenantAddress1,
                        tenantCity: _formikProps.values.tenantCity,
                        tenantProvince: _formikProps.values.tenantProvince,
                        tenantPostalcode: _formikProps.values.tenantPostalcode,
                        tenantCountryid: _formikProps.values.tenantCountryid,
                        tenantLanguageid: _formikProps.values.tenantLanguageid,
                        tenantCurrencyid: _formikProps.values.tenantCurrencyid,
                        tenantTimezoneid: _formikProps.values.tenantTimezoneid,
                        createdBy: _formikProps.values.createdBy,
                    };

                    var actionVerb = "";
                    // CREATE
                    actionVerb = "POST";
                    console.log("List of Object"+JSON.stringify(mappedObj));
                    ApiService.setOBS(actionVerb, API_ENDPOINT.IDENTITY, `/NewTenant/CreateNewTenant`, JSON.stringify(mappedObj)).subscribe(
                        (successResult) => {
                            if (successResult) {
                                this.setState({ fetchResult: ResultStatus.SUCCESS }, () => {
                                    this.props.onClose(false);
                                });
                            } else {
                                this.setState({ fetchResult: ResultStatus.ERROR });
                            }
                        },
                        (errorResult) => {
                            console.error("Error while saving Tenant details", errorResult);
                            this.setState({ fetchResult: ResultStatus.ERROR });
                        }
                    );
                })
                .catch((erroObj) => {
                    console.log(erroObj);
                    if (erroObj.inner) {
                        erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
                    }
                });
        }
    }
    getInitialValues() {
        return {
            tenantid: 0,
            tenantName: "",
            tenantDescription: "",
            uid: "",
            tenantType: "US",
            tenantAddress1: "",
            tenantCity: "",
            tenantProvince: "",
            tenantPostalcode: "",
            tenantCountryid: 106,
            tenantLanguageid: 1,
            tenantCurrencyid: 35,
            tenantTimezoneid: 15,
            createdBy: this.context.user.userId,
        };
    }
    getListofTenantTypes()
    {
        return [{id:"USGST", value:"USGST"},
                ,{id:"OUSGST", value:"OUSGST"},
                ,{id:"GLOBALGST", value:"GLOBALGST"}]; 
    }
    validatedTenantName = "";
    validatedUid = "";
    validationSchema = Yup.object().shape({});
    getValidationSchema() {
        this.validationSchema = Yup.object().shape({
            tenantDescription: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
            tenantAddress1: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
            tenantCity: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
            tenantProvince: Yup.string().required("Required").min(2, "Must be at least 2 characters long").max(256, "Must be 256 characters or less"),
            tenantPostalcode: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
            tenantName: Yup.string().matches(/^[a-zA-Z0-9 ]+$/, 'Cannot contain special characters').required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less")
                .test("checkDuplicateTenantName", "Tenant name already exists", async (_formikValue) => {
                    if (_formikValue !== this.validatedTenantName) {
                        // new tenant name (or) tenantName updated
                        try {
                            const result = await fetch(`${process.env.REACT_APP_IDENTITY_HTTP}/NewTenant/CheckTenantName/${_formikValue}`);
                            const isExists = await result.json();
                            console.log("is tenant name exists: ", !isExists);
                            this.lastValidatedTenantResult = isExists;
                            this.validatedTenantName = _formikValue;
                            return this.lastValidatedTenantResult;
                        } catch {
                            this.setState({ fetchResult: ResultStatus.ERROR });
                            return false;
                        }
                    } else { return true; }
                }),
            uid: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(3, "Must be 3 characters or less")
                .test("checkDuplicateTenantAcronymName", "Tenant UID already exists", async (_formikValue) => {
                   try {
                            const result = await fetch(`${process.env.REACT_APP_IDENTITY_HTTP}/NewTenant/CheckUID/${_formikValue}`);
                            const isExists = await result.json();
                            console.log("UID exists: ", !isExists);
                            this.lastValidatedUIDResult = isExists;
                            this.validatedUid = _formikValue;
                            return this.lastValidatedUIDResult;
                        } catch {
                            this.setState({ fetchResult: ResultStatus.ERROR });
                            return false;
                        }
                    } 
                ),
            tenantCountryid: Yup.number().required("Required").typeError("Must be a number"),
            tenantType: Yup.string().required("Required").typeError("Must be a string"),
            tenantLanguageid: Yup.number().required("Required").typeError("Must be a number"),
            tenantCurrencyid: Yup.number().required("Required").typeError("Must be a number"),
            tenantTimezoneid: Yup.number().required("Required").typeError("Must be a number"),
        });
        return this.validationSchema;
    }

    render() {
        const { classes } = this.props;
        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING: return (<PageLoadingComponent small classes={classes} label="Loading Tenant Details" />);
            case ResultStatus.SAVING: return (<PageLoadingComponent small classes={classes} label="Saving Tenant Details" />);
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <>
                        <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                            {(fProps) => (
                                <form>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        {/* Dialog Title */}
                                        <DialogTitle disableTypography id="dialogTitle">
                                            <AppBar position="static">
                                                <Toolbar variant="dense">
                                                    <Typography variant="h6" className={classes.root}>Create New Tenant</Typography>
                                                    {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })}
                                                </Toolbar>
                                            </AppBar>
                                        </DialogTitle>
                                        {/* Dialog Content */}
                                        <DialogContent>
                                            <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 16, paddingBottom: 32, minWidth: "80vh", maxWidth: "80vh", }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "tenantName", "Tenant Name")} fullWidth /> </Grid>
                                                    <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "tenantDescription", "Tenant Description")} fullWidth />{" "}</Grid>
                                                    <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "uid", "Tenant UID")} fullWidth />{" "}</Grid>                                                    
                                                    <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "tenantType", "Type", this.getListofTenantTypes(), "id", "value", "98%")}</Grid>
                                                    <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "tenantAddress1", "Address 1")} fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "tenantCity", "City")} fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "tenantProvince", "Province/State")} fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "tenantPostalcode", "Postal Code")} fullWidth /></Grid>
                                                    <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "tenantCountryid", "Country Name*", this.state.countryList, "id", "value", "98%")}</Grid>
                                                    <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "tenantLanguageid", "Language*", this.state.languageList, "id", "value", "98%",true)}</Grid>
                                                    <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "tenantCurrencyid", "Currency*", this.state.currencyList, "id", "value", "98%")}</Grid>
                                                    <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "tenantTimezoneid", "Time Zone*", this.state.timeZoneList, "id", "value", "98%")}</Grid>
                                                </Grid>
                                            </Box>
                                        </DialogContent>
                                    </MuiPickersUtilsProvider>
                                </form>
                            )}
                        </Formik>
                    </>
                );
            case ResultStatus.ERROR:
            default:
                return (
                    <DialogErrorFragmentComponent title="Error" description="Error in Tenant details"
                        classes={classes} onClose={() => { this.props.onClose(false); }} onRetry={() => { console.log("Retry Clicked"); }} />
                );
        }
    }
}

/** HOC */
export default LayoutService.getHocComponenet(NewTenantComponent);