import React from "react";
import { AppBar, Box, Dialog, DialogTitle, Divider, LinearProgress, Tab, Tabs, Toolbar, Typography } from "@material-ui/core";
import LayoutService from "../../../shared/services/layoutService";
import TransactionTabComponent from "./transaction-tab/transactionTabComponent";
import RuleSummaryComponent from "./rule-summary-tab/ruleSummaryComponent";
import AgreementsComponent from "../../../shared/components/agreements/agreementsComponent";
import DocumentsComponent from "./documents-tab/documentsComponent";
import ReportReferencesComponent from "./report-references-tab/reportReferencesComponent";
import RelatedTransactionsComponent from "./related-transactions-tab/relatedTransactionsComponent";
import { AuthContext } from "../../../shared/store/authProvider";
import { API_ENDPOINT, CrudAction, ENTITY_TYPE, TaskLauncher } from "../../../shared/types/enums";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { DataService, SubscriptionArray } from "../../../shared/services/dataService";
import ActionDialog from "../../../shared/components/dialog/actionDialog";
import ApiService from "../../../shared/services/apiService";
import TransactiondetailDeleteConfirmComponent from "./transactiondetailDeleteConfirmComponent";
import TransactionDetailEditConfirmComponent from "./transactionDetailEditConfirmComponent";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import MatThemeService from "../../../shared/services/theme/matThemeService";
import TaskDetailsComponent from "../../home/task/task-details/taskDetailsComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import RolePermissionInfo from "../../../shared/role-permissions/rolePermissionInfo";
import RelatedTransactionEditConfirmationDialog from "./transaction-tab/relatedTransactionEditConfirmationDialog";

class TransactionDetailDialogComponent extends React.Component {
    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        this.tabIndexMap = new Map();
        this.tabIndexMap.set(0, { ref: null });
        this.tabIndexMap.set(1, { ref: null });
        this.tabIndexMap.set(2, { ref: null });
        this.tabIndexMap.set(3, { ref: null });
        this.tabIndexMap.set(4, { ref: null });
        this.tabIndexMap.set(5, { ref: null });

        this.state = {
            isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
            isNew: this.props.inputAction === CrudAction.CREATE,
            isEditing: this.props.inputAction === CrudAction.UPDATE,

            isDialog: this.props.standAloneProps ? false : true,
            derivedProps: this.props.standAloneProps ? this.props.standAloneProps : { fullWidth: true, maxWidth: "lg" },

            selectedTabIndex: 0,
            openDeleteConfirm: false,
            porziogsttransactionid: "",
            transactionTabLoaded: false,
            selectedTransaction: null,

            openEditConfirm: false,
            showTransactionEditConfirmationDialog: false
        };
    }

    onClose = (_param) => {
        this.setState({ isReadOnly: true }, () => {
            this.props.onClose(_param);
        });
    }

    _changeTab = (_newTabIndex) => {
        this.setState({
            selectedTabIndex: _newTabIndex,
            isReadOnly: true // on tab change make it readonly
        });
    }

    setReadOnlyMode = (_value) => {
        this.setState({
            isReadOnly: _value // on tab change make it readonly
        });
    }

    onTabChange = (event, _newTabIndex) => {
        const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
        if (currentTabRef) {
            if (currentTabRef.isDirtyCallback()) {
                this.setState({ showConfirmDialog: true, newTabIndex: _newTabIndex });
            } else {
                this.setState({ showConfirmDialog: false }, () => {
                    this._changeTab(_newTabIndex);
                });
            }
        } else {
            this.setState({ showConfirmDialog: false }, () => {
                this._changeTab(_newTabIndex);
            });
        }
    };

    onEdit = () => {
        if (this.state.selectedTransaction && this.state.selectedTransaction.hasreportreferences) {
            this.setState({ openEditConfirm: true });
        }
    }

    onSave = () => {
        this.oSubscriptions.cancelAll();
        const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;

        if (currentTabRef.postCallbackOBS) {
            this.setState({ isSaving: true });
            this.oSubscriptions.add(currentTabRef.postCallbackOBS().subscribe(
                (_successResult) => {
                    this.setState({ isSaving: false, saveErrorInfo: null });
                    if (_successResult === "validation_error") {
                        this.flashError("Validation Failed");
                    } else if (_successResult === "save_error") {
                        this.flashError("Save Failed");
                    } else {
                        if (this.state.isNew) {
                            this.onClose(_successResult); // close this and pass the successResult to the caller
                        } else {
                            // stay on the same screen as per PP2-871
                        }
                        this.setReadOnlyMode(true);
                        currentTabRef.resetCallback(true);
                    }
                },
                (_error) => {
                    this.flashError("Error Occured while Saving");
                }
            ));
        }
    }

    onDelete = () => {
        this.oSubscriptions.cancelAll();

        if (this.getDeleteOBS) {
            this.setState({ isSaving: true });
            this.oSubscriptions.add(this.getDeleteOBS().subscribe(
                (_successResult) => {
                    this.setState({ isSaving: false, saveErrorInfo: null });
                    if (_successResult === "validation_error") {
                        this.flashError("Validation Failed");
                    } else if (_successResult === "save_error") {
                        this.flashError("Delete Failed");
                    } else {
                        this.onClose(true); // close this and reload the TransactionCount
                    }
                },
                (_error) => {
                    this.flashError("Error Occured while Deleting");
                }
            ));
        }
    }

    getDeleteOBS = () => {
        return ApiService.deleteOBS(API_ENDPOINT.CORE, `/Transactions/DeleteTransaction/${this.context.user.tenantId}/${this.props.modalAgNode.trid}/${this.context.user.userId}?taskId=0`);
    }

    flashError = (_error) => {
        console.log("Error while Saving", _error);
        this.setState({ isSaving: false, saveErrorInfo: _error }, () => {
            setTimeout(() => { this.setState({ saveErrorInfo: null }); }, 5000);
        });
    }

    handleTransactionTabLoaded = (_selectedTransaction, _relatedTransactions) => {
        this.setState({
            selectedTransaction: _selectedTransaction,
            relatedTransactions: _relatedTransactions,
            transactionTabLoaded: true
        });
    }

    beforeSave = () => {
        if (this.state.selectedTabIndex === 0 && DataService.hasElements(this.state.relatedTransactions)) {
            const transactionTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
            const dataToPost = transactionTabRef.getDataToPost();
            if (dataToPost.hasRelatedTransactionUpdates) {
                this.setState({ showTransactionEditConfirmationDialog: true });
            } else {
                this.onSave();
            }
        } else {
            this.onSave();
        }

    }

    setIsSaving = (_isSaving = false) => {
        this.setState({ isSaving: _isSaving });
    }

    render() {
        const { classes } = this.props;
        const TAB_REF = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
        const TAB_PERMISSIONS = TAB_REF ? (TAB_REF.TAB_PERMISSIONS ? TAB_REF.TAB_PERMISSIONS : RolePermissionInfo.INIT()) : RolePermissionInfo.INIT();

        return (
            <>
                {/* tab dialog */}
                <Dialog {...this.state.derivedProps} open={this.props.open || false} onClose={() => { this.onClose(false); }} >

                    {/* DialogTitle */}
                    {this.state.isDialog ?
                        <DialogTitle disableTypography id="dialogTitle">
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" className={classes.root}>Transaction Details</Typography>
                                    {LayoutService.getIconButton(false, MatIconService.DELETE_RED, "Delete", () => this.setState({ openDeleteConfirm: true, porziogsttransactionid: this.props.modalAgNode.porziogsttransactionid }), "inherit", "keyActionDelete")}
                                    {LayoutService.getReadOnlyActions(this, (this.props.inputAction === CrudAction.CREATE), () => { this.onClose(false) }, () => { this.beforeSave() }, () => { this.onEdit() },)}
                                </Toolbar>
                            </AppBar>
                        </DialogTitle>
                        :
                        <PageDynamicHeaderComponent classes={classes} label="Transaction Details" divider rightPadding={16}
                            leftActions={[
                                { icon: MatIconService.BACK, onClick: () => { this.props.onClose(false); }, title: "Go back", iconColor: "secondary" },
                            ]}
                            rightActions={[
                                this.state.isEditing ? {
                                    icon: RolePermissionService.TRANSACTION_DETAILS.cannotDelete ? MatIconService.DELETE : MatIconService.DELETE_RED, title: "Delete", isReadOnly: RolePermissionService.TRANSACTION_DETAILS.cannotDelete,
                                    onClick: () => { this.setState({ openDeleteConfirm: true, porziogsttransactionid: this.props.modalAgNode.porziogsttransactionid }); },
                                } : null,
                                { icon: MatIconService.TASK_ADD, onClick: () => { this.setState({ showCreateTaskDialog: true }); }, title: "Create Task", isReadOnly: RolePermissionService.TRANSACTION_TASK.cannotCreate, },
                                // clone stuff
                                this.props.onClone ?
                                    {
                                        icon: MatIconService.COPY, isReadOnly: RolePermissionService.TRANSACTION_CLONE_OR_COPY.cannotCreate,
                                        title: "Clone to New", onClick: () => { this.props.onClone(this.state.selectedTransaction); },
                                    } : null,
                                this.state.isReadOnly ?
                                    { icon: MatIconService.EDIT, onClick: () => { this.setState({ isReadOnly: false }); }, title: "Edit", isReadOnly: TAB_PERMISSIONS.cannotCreateOrEdit }
                                    : { icon: MatIconService.OK, onClick: () => { this.beforeSave(); }, title: "Save", iconColor: "primary", isReadOnly: TAB_PERMISSIONS.cannotCreateOrEdit }
                            ]}
                        />
                    }

                    <div>{this.state.isSaving ? <LinearProgress color="secondary" /> : null}</div>
                    {this.state.saveErrorInfo ? <Typography style={{ backgroundColor: "#FF8A8A", color: "#6E0101" }} variant="h6" align="center">{this.state.saveErrorInfo}</Typography> : null}


                    {/* DialogContent */}
                    <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto"
                        style={{ backgroundColor: this.state.isDialog ? null : MatThemeService.getTabBG() }}>

                        <Tab label="Transaction" id="transactionTab" />
                        <Tab hidden={!this.state.isEditing} label="Rule Summary" id="ruleSummaryTab" />
                        <Tab hidden={!this.state.isEditing} label="Related Transactions" id="relatedTransactionsTab" />
                        <Tab hidden={!this.state.isEditing} label="Agreements" id="agreementsTab" />
                        <Tab hidden={!this.state.isEditing} label="Documents" id="documentsTab" />
                        <Tab hidden={!this.state.isEditing} label="Report References" id="reportReferencesTab" />
                    </Tabs>
                    <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id={"transactionTabPanel"} >
                        <Divider />
                        <Box style={{ padding: "0px" }} >
                            <TransactionTabComponent tabConfig={this.tabIndexMap.get(0)}
                                isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction}
                                modalAgNode={this.props.modalAgNode} preloadedRecipientProfileId={this.props.preloadedRecipientProfileId}
                                onTransactionTabLoaded={(_selectedTransaction, _relatedTransactions) => { this.handleTransactionTabLoaded(_selectedTransaction, _relatedTransactions) }}
                                transactionToClone={this.props.transactionToClone}
                                setReadOnlyMode={this.setReadOnlyMode}
                                fromGlobalSearch={this.props.fromGlobalSearch}
                            />
                        </Box>
                    </div>
                    {this.state.transactionTabLoaded ?
                        <React.Fragment>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 1} id={"ruleSummaryTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <RuleSummaryComponent tabConfig={this.tabIndexMap.get(1)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 2} id={"relatedTransactionsTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <RelatedTransactionsComponent tabConfig={this.tabIndexMap.get(2)} isReadOnly={!this.state.isNew && this.state.isReadOnly}
                                        inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode}
                                        changeToReadOnlyMode={() => { this.setReadOnlyMode(true) }}
                                        setIsSaving={(_isSaving) => { this.setIsSaving(_isSaving) }} />
                                </Box>
                            </div>
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 3} id={"agreementsTabPanel"} >
                                <Divider />
                                {this.state.selectedTransaction ?
                                    <Box style={{ padding: "0px" }} >
                                        <AgreementsComponent entityType={ENTITY_TYPE.TRANSACTION} tabConfig={this.tabIndexMap.get(3)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} selectedTransaction={this.state.selectedTransaction} />
                                    </Box>
                                    : null}
                            </div>
                            {this.props.inputAction === CrudAction.UPDATE ?
                                <div role="tabpanel" hidden={this.state.selectedTabIndex !== 4} id={"documentsTabPanel"} >
                                    <Divider />
                                    <Box style={{ padding: "0px" }} >
                                        <DocumentsComponent tabConfig={this.tabIndexMap.get(4)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} entityType={ENTITY_TYPE.TRANSACTION}  />
                                    </Box>
                                </div>
                                : null}
                            <div role="tabpanel" hidden={this.state.selectedTabIndex !== 5} id={"reportReferencesTabPanel"} >
                                <Divider />
                                <Box style={{ padding: "0px" }} >
                                    <ReportReferencesComponent tabConfig={this.tabIndexMap.get(5)} isReadOnly={!this.state.isNew && this.state.isReadOnly} inputAction={this.props.inputAction} modalAgNode={this.props.modalAgNode} />
                                </Box>
                            </div>
                        </React.Fragment>
                        : <></>}
                </Dialog >

                {/* taskDialog */}
                {
                    this.state.showCreateTaskDialog ?
                        <TaskDetailsComponent showTaskDialog={this.state.showCreateTaskDialog} inputAction={CrudAction.CREATE}
                            taskLauncher={TaskLauncher.TRANSACTION_DETAILS} refreshTaskList={() => { }} updateData={null}
                            onClose={() => this.setState({ showCreateTaskDialog: false })}
                            porzioGstIds={this.props.modalAgNode ? [this.props.modalAgNode.porziogsttransactionid] : []} />
                        : null
                }

                {/* Delete Confirm Dialog */}
                <TransactiondetailDeleteConfirmComponent
                    open={this.state.openDeleteConfirm || false}
                    onClose={() => this.setState({ openDeleteConfirm: false })} h
                    handleDeleteDismiss={() => this.setState({ openDeleteConfirm: false })}
                    handleDeleteConfirm={() => {
                        this.setState({ openDeleteConfirm: false });
                        this.onDelete();
                        this.onClose(true);
                    }}
                    porziogsttransactionid={this.state.porziogsttransactionid} />

                {/* Edit Confirm Dialog */}
                <TransactionDetailEditConfirmComponent open={this.state.openEditConfirm || false}
                    onClose={() => this.setState({ openEditConfirm: false })}
                    handleEditDismiss={() => {
                        this.setState({
                            isReadOnly: true,
                            isEditing: false,
                            openEditConfirm: false
                        });
                    }}
                    handleEditConfirm={() => {
                        this.setState({ openEditConfirm: false });
                    }} />

                {/* Related Transaction Confirm Dialog */}
                <RelatedTransactionEditConfirmationDialog open={this.state.showTransactionEditConfirmationDialog || false}
                    relatedTransactions={this.state.relatedTransactions}
                    onClose={() => this.setState({ showTransactionEditConfirmationDialog: false })}
                    onConfirm={() => { this.setState({ showTransactionEditConfirmationDialog: false }, () => { this.onSave() }); }}
                />

                {/* discard action dialog */}
                <ActionDialog showDialog={this.state.showConfirmDialog} title="Discard changes?" description="There are unsaved changes" actions={[
                    {
                        color: "secondary", text: "Discard", icon: null,
                        callback: () => {
                            const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
                            currentTabRef.resetCallback();

                            this.setState({ showConfirmDialog: false }, () => {
                                this._changeTab(this.state.newTabIndex);
                            })
                        }
                    },
                    {
                        color: "inherit", text: "Cancel", icon: null,
                        callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
                    }
                ]} />
            </>
        );
    }
}
export default LayoutService.getHocComponenet(TransactionDetailDialogComponent);