import React from "react";
import clsx from "clsx";
import { Typography, Divider, Grid, Tooltip, IconButton, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../services/theme/matSpecService";
import { MatIconService } from "../../services/theme/matIconService";
import LayoutService from "../../services/layoutService";

class PageHeaderComponent extends React.Component {
  render() {
    const { small, classes, history, label, back, menu, search, entitySelect, countrySelect, importTemplate, uploadFile, save, edit, add, addCircle, view,
      reload, ok, cancel, noDivider, noDividerSpacing, unmatched, download, isDownloadReadOnly } = this.props;
    const showButtons = back || menu || search || entitySelect || countrySelect || importTemplate || uploadFile
      || save || edit || add || addCircle || view || reload || ok || cancel || unmatched || download;

    return (
      <React.Fragment>
        <Grid className={classes.flexNoShrink} container direction="column">
          <Grid container direction="row" alignItems="center">
            {back ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Back" placement="bottom" arrow>
                <IconButton onClick={() => { history.goBack(); }}>{MatIconService.BACK}</IconButton>
              </Tooltip>
            ) : null}

            {menu ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Menu" placement="bottom" arrow>
                <IconButton onClick={this.props.onMenuClick} color="primary">
                  {MatIconService.MENU}
                </IconButton>
              </Tooltip>
            ) : null}

            <Typography style={{ paddingLeft: 8 }} variant={small ? "h6" : "h5"} className={classes.headerTitle}>
              {label}
            </Typography>

            {entitySelect ? (
              <>
                <Typography>Entity:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={this.props.selectedEntityId}
                  onChange={this.props.onEntitySelectClick}
                  style={{ width: 150 }}
                >
                  {this.props.entitySelectData.map((el) => (
                    <MenuItem key={el.entityid} value={el.entityid}>
                      {el.entityname}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}

            {download ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                <IconButton disabled={isDownloadReadOnly} onClick={this.props.onDownloadClick} color="secondary">
                  {MatIconService.DOWNLOAD}
                </IconButton>
              </Tooltip>
            ) : null}

            {unmatched ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Unmatched to Reference Data" placement="bottom" arrow>
                <IconButton onClick={this.props.onUnMatchedClick} color="secondary">
                  {MatIconService.NOT_LISTED}
                </IconButton>
              </Tooltip>
            ) : null}

            {search ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Search" placement="bottom" arrow>
                <IconButton onClick={this.props.onSearchClick} color="secondary">
                  {MatIconService.SEARCH}
                </IconButton>
              </Tooltip>
            ) : null}

            {countrySelect ? (
              <>
                <Typography>Jurisdiction:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={this.props.selectedCountryId}
                  onChange={this.props.onCountrySelectClick}
                  style={{ width: 150 }}
                >
                  {this.props.countrySelectData.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}
            {importTemplate ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Import Template" placement="bottom" arrow>
                <IconButton onClick={this.props.onImportTemplateClick} color="primary">
                  {MatIconService.IMPORT_TEMPLATE}
                </IconButton>
              </Tooltip>
            ) : null}

            {uploadFile ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Upload File" placement="bottom" arrow>
                <IconButton onClick={this.props.onUploadFileClick} color="primary">
                  {MatIconService.CLOUD_UPLOAD}
                </IconButton>
              </Tooltip>
            ) : null}

            {edit ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Edit" placement="bottom" arrow>
                <IconButton onClick={this.props.onEditClick}>
                  {MatIconService.EDIT}
                </IconButton>
              </Tooltip>
            ) : null}

            {add || addCircle ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add" placement="bottom" arrow>
                <IconButton onClick={this.props.onAddClick} color="secondary">
                  {addCircle ? MatIconService.ADD_CIRCLE_OUTLINE : MatIconService.ADD}
                </IconButton>
              </Tooltip>
            ) : null}

            {save ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Save" placement="bottom" arrow>
                <IconButton onClick={this.props.onSaveClick} color="primary">
                  {MatIconService.SAVE}
                </IconButton>
              </Tooltip>
            ) : null}

            {view ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="View" placement="bottom" arrow>
                <IconButton onClick={this.props.onViewClick} color="primary">
                  {MatIconService.VIEW_LIST}
                </IconButton>
              </Tooltip>
            ) : null}

            {reload ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Reload" placement="bottom" arrow>
                <IconButton onClick={this.props.onReloadClick} color="secondary">
                  {MatIconService.RELOAD}
                </IconButton>
              </Tooltip>
            ) : null}

            {cancel ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Cancel" placement="bottom" arrow>
                <IconButton onClick={this.props.onCancelClick} color="secondary">
                  {MatIconService.CANCEL}
                </IconButton>
              </Tooltip>
            ) : null}

            {ok ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Ok" placement="bottom" arrow>
                <IconButton onClick={this.props.onOkClick} color="primary">
                  {MatIconService.OK}
                </IconButton>
              </Tooltip>
            ) : null}
          </Grid>
          {noDivider || noDividerSpacing ? (
            <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
          ) : (
            <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
export default LayoutService.getHocComponenet(PageHeaderComponent);