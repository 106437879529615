import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";

export default class CheckBoxCellRendererComponent extends Component {
  constructor(props) {
    super(props);

    this.invokeParentMethod = this.invokeParentMethod.bind(this);
    this.state = { isChecked: false };
  }

  invokeParentMethod() {
    this.props.context.componentParent.methodFromParent(
      `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`,
      this.props.node.data
    );
    console.log(this.props.node.data.isChecked);
  }

  render() {
    return (
      <Checkbox
        //color="primary"
        disabled={this.props.checkDisabled && this.props.checkDisabled(this.props)}
        size="small"
        style={{ padding: 0 }}
        // indeterminate={this.state.isIndeterminate} // determined based on the child
        indeterminate={false}
        checked={this.props.node.data.isChecked}
        onClick={() => {
          this.setState({ isChecked: !this.state.isChecked });
          this.props.node.data.isChecked = !this.props.node.data.isChecked;
          this.invokeParentMethod();
        }}
      />
    );
  }
}
