import { ReplaySubject } from 'rxjs';
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridService } from "../../../../shared/services/ag-grid/agGridService";
import { AgGridColumnExt } from '../../../../shared/services/ag-grid/agGridColumnExt';

export default class EditReportDetailDialogService {
  static getReportDetailsById = (_tenantId, _reportDetailId) => {
    return ApiService.getOBS(
      API_ENDPOINT.REPORTS,
      `/Reports/FetchReportDetailView/${_tenantId}/${_reportDetailId}`
    );
  };

  static updateReportDetails = (_tenantId, _jsonStringifiedBody, _userId) => {
    return ApiService.putOBS(
      API_ENDPOINT.REPORTS,
      `/Reports/UpdateReportDetail?tenantId=${_tenantId}&userId=${_userId}`,
      _jsonStringifiedBody
    );
  };
}
