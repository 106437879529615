import React from "react";
import MomentService from "./momentService";
import {
  withStyles, InputLabel, Tooltip, IconButton, TextField, Switch, FormControlLabel, InputAdornment, FormControl,
  Select, FormHelperText, InputBase, Typography, ListItemIcon, MenuItem, Divider, Menu
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";

import { withRouter } from "react-router-dom";

import { MatClassService } from "../../shared/services/theme/matClassService";
import MatThemeService from "./theme/matThemeService";
import { MatIconService } from "./theme/matIconService";
import MatSpecService from "./theme/matSpecService";
import { DataService } from "../services/dataService";


export default class LayoutService {
  /**
   * yup error to formik
   * refer
   * https://stackoverflow.com/questions/54473643/dont-validate-untouched-fields-in-formik-and-yup-on-submitting-if-field-is-not
   * @param {*} err
   */
  static mapYupErrorsToFormikErrors = (err) => {
    return err.inner
      .filter((i) => !!i.path)
      .reduce(
        (curr, next) =>
          Object.assign(Object.assign({}, curr), {
            [next.path]: next.errors[0],
          }),
        {}
      );
  };

  static submitIfValid = async (
    _thisRef,
    _formikProps,
    _validationSchema,
    _validationSuccessCallback
  ) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await _validationSchema
        .validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // if validation is successfull
          _validationSuccessCallback();
        })
        .catch((erroObj) => {
          // set the error to the form fields
          erroObj.inner.forEach((err) => {
            _formikProps.setFieldError(err.path, err.message);
          });
        });
    }
  };

  static isRequired = (_validationSchema, _fieldName) => {
    // return _validationSchema.fields[_fieldName]._exclusive.required;
    return DataService.hasElements(
      _validationSchema.fields[_fieldName]?.tests.filter((test) => {
        return test.OPTIONS.name === "required";
      })
    );
  };

  static getFormikProps = (_initialValues, _validationSchema, _showMultiFieldErros = false, _validateOnchange = false, _validateOnBlur = false) => {
    return {
      initialValues: _initialValues,
      validationSchema: _validationSchema,
      validationSchemaOptions: { showMultipleFieldErrors: _showMultiFieldErros },
      validateOnChange: _validateOnchange,
      validateOnBlur: _validateOnBlur
    };
  }

  //#region New-Impl
  static getDropDown = (
    _isReadOnly,
    _formClassName, // _classes.dialogControl
    _menuClassName, // _classes.menuPaper
    _formikProps,
    _validationSchema,
    _key,
    _label,
    _options,
    _optionKey,
    _optionLabel,
    _defaultValue = null,
    _showEmpty = false,
    _minWidth = "47%",
    _onChangeCallback = () => { },
    _size = "small",
    _otherSelectOptions = {},
    _otherFormControlOptions = {},
    _otherInputLabelOptions = {}
  ) => {
    const formControlKey = "fromControl" + _key;
    const labelKey = "inputLabel" + _key;
    const selectKey = "select" + _key;

    // Assign default value to underlying formik
    if (_defaultValue && _formikProps.values[_key] !== _defaultValue) {
      _formikProps.setFieldValue(_key, _defaultValue, false);
    }

    return (
      <FormControl
        disabled={_isReadOnly}
        {..._otherFormControlOptions} // other options for formcontrol
        size={_size}
        variant={MatThemeService.inputVariant}
        id={formControlKey}
        key={formControlKey}
        name={formControlKey}
        required={this.isRequired(_validationSchema, _key)} // checks if the field is set as required in the validations schema
        style={{ minWidth: _minWidth }}
        className={_formClassName}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
      >
        <InputLabel
          {..._otherInputLabelOptions}
          id={labelKey}
          key={labelKey}
          name={labelKey}
        >
          {_label}
        </InputLabel>
        <Select
          {..._otherSelectOptions} // spread other options
          id={selectKey}
          key={selectKey}
          name={selectKey}
          labelId={labelKey} // link to label
          MenuProps={{ classes: { paper: _menuClassName } }}
          value={_defaultValue || _formikProps.values[_key]}
          onBlur={_formikProps.handleBlur} // let formik handle the onBlurEvent
          onChange={async (e) => {
            // Override formik's onChangeEvent Handler
            const _value = e.target.value;
            await _formikProps.setFieldValue(_key, _value, false); // shouldValidate: false -> don't trigger validations for all fields
            await _formikProps.setFieldTouched(_key, true, false); // shouldValidate: false -> don't trigger validations for all fields
            // validate only this field
            _formikProps.validateField(_key);

            /** At this point <_onChangeCallback()> don't rely on _formikProps for new Values, instead use the _value
             * because formik's setFieldValue is a promise and is  not updated yet even with await,
             * a console.log(_formikProps) at this point will still show the oldValues,
             * so use the _value directly instead of relying on the formik props,
             */
            _onChangeCallback(_formikProps, _value);
          }}
        >
          {_showEmpty ? (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          ) : null}
          {_options.map((option) => (
            <MenuItem key={option[_optionKey]} value={option[_optionKey]}>
              {option[_optionLabel]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{_formikProps.errors[_key]}</FormHelperText>
      </FormControl>
    );
  }

  static getSimpleDropDown = (
    _key,
    _value,
    _label,
    _options,
    _optionKey,
    _optionLabel,
    _onChangeCallback = () => { },
    _styles = {},
  ) => {
    const formControlKey = "fromControl" + _key;
    const labelKey = "inputLabel" + _key;
    const selectKey = "select" + _key;

    return (
      <FormControl
        size={"small"}
        variant={MatThemeService.inputVariant}
        id={formControlKey}
        key={formControlKey}
        name={formControlKey}
        style={_styles}
      >
        <InputLabel
          id={labelKey}
          key={labelKey}
          name={labelKey}
        >
          {_label}
        </InputLabel>
        <Select
          id={selectKey}
          key={selectKey}
          name={selectKey}
          labelId={labelKey} // link to label
          value={_value}
          onChange={_onChangeCallback}
        >
          {/* {_showEmpty ? ( <MenuItem value=""><em>None</em></MenuItem>) : null} */}
          {(_options || []).map((option) => (
            <MenuItem key={option[_optionKey]} value={option[_optionKey]}>
              {option[_optionLabel]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }


  static getMultiSelect(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _options,
    _optionKey,
    _optionLabel,
    _onChangeCallback = () => { },
    _minwidth = "47%",
    _noOptionsText = "Not Found",
  ) {
    const labelKey = _key + "label";
    const fcKey = _key + "fc";

    return (
      <FormControl
        key={fcKey}
        disabled={_isReadOnly}
        style={{ minWidth: _minwidth, marginLeft: 0 }}
        className={_classes.dialogControl}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
      >
        <Autocomplete
          labelid={labelKey}
          id={_key}
          key={_key}
          disabled={_isReadOnly}
          multiple
          // default value
          value={_formikProps.values[_key]}
          // options
          options={_options}
          noOptionsText={_noOptionsText}
          // set selected values
          getOptionLabel={(option) => option[_optionLabel]}
          getOptionSelected={(option, value) => option[_optionKey] === value[_optionKey]}
          // getOptionSelected={(option) =>
          //   _formikProps.values[_key].find(
          //     (v) => v[_optionKey] === option[_optionKey]
          //   )
          // }
          // handle on change callback
          onChange={async (e, newValues, reason) => {
            await _formikProps.setFieldValue(_key, newValues, true);
            await _formikProps.setFieldTouched(_key, true, false);
            /** At this point <_onChangeCallback()> don't rely on _formikProps for new Values, instead use the _newValues
             * because formik's setFieldValue is a promise and is  not updated yet even with await,
             * a console.log(_formikProps) at this point will still show the oldValues,
             * so use the newValues directly instead of relying on the formik props,
             */
            if (_onChangeCallback) { _onChangeCallback(_key, _formikProps, newValues); }
          }}
          // render in the text in the chip
          renderInput={(params) => (
            <TextField {...params} variant={MatThemeService.inputVariant} label={_label} />
          )}
        />
        <FormHelperText>{_formikProps.errors[_key]}</FormHelperText>
      </FormControl>
    );
  }

  static getTextBox = (
    _isReadOnly,
    _className,
    _formikProps,
    _validationSchema,
    _key,
    _label,
    _type = "text",
    _minWidth = "47%",
    _onChangeDebouncedTextFieldFormikValidator = null,
    _onClickCallback = null,
    _onChangeCallback = null,
    _size = "small",
    _inputProps = {},
    _otherOptions = {},
  ) => {

    if (_onClickCallback) {
      const hasInputProps = DataService.hasValue(_inputProps) && _inputProps != {};
      const linkStyle = { cursor: "pointer", textDecoration: "underline", color: "blue" };
      if (hasInputProps) { // append style property to the input props
        _inputProps["style"] = linkStyle;
        _inputProps["readOnly"] = _isReadOnly;
      } else {
        _inputProps = { style: linkStyle , readOnly:  _isReadOnly};
      }
    }

    return (
      <TextField
        //Incase of textfield with links, disabling the field is not invoking the click event,
        //so we have used readonly instead for fields with links which open a dialog
        disabled={_isReadOnly && !_onClickCallback}
        {..._otherOptions} // spread other options
        id={_key}
        key={_key}
        name={_key}
        label={_label}
        type={_type}
        size={_size}
        variant={MatThemeService.inputVariant}

        style={{ minWidth: _minWidth }}
        className={_className}
        required={this.isRequired(_validationSchema, _key)} // checks if the field is set as required in the validations schema
        helperText={_formikProps.errors[_key]}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
        value={_formikProps.values[_key]}
        onBlur={_formikProps.handleBlur} // let formik handle the onBlurEvent
        onClick={() => { if (_onClickCallback) { _onClickCallback({ "value": _formikProps.values[_key] }); } }}
        inputProps={_inputProps}
        InputProps={_inputProps}
        onChange={async (e) => {
          if (_onChangeCallback) {
            // Override formik's onChangeEvent Handler
            const _value = e.target.value;
            await _formikProps.setFieldValue(_key, _value, false); // shouldValidate: false -> don't trigger validations for all fields
            await _formikProps.setFieldTouched(_key, true, false); // shouldValidate: false -> don't trigger validations for all fields
            // validate only this field
            if (
              DataService.hasValue(_onChangeDebouncedTextFieldFormikValidator)
            ) {
              // passing the formik props, because the caller's formik props is not updated yet
              _onChangeDebouncedTextFieldFormikValidator.observable.next({ _formikProps, _key, _value });
            }

            /** At this point <_onChangeCallback()> don't rely on _formikProps for new Values, instead use the _value
             * because formik's setFieldValue is a promise and is  not updated yet even with await,
             * a console.log(_formikProps) at this point will still show the oldValues,
             * so use the _value directly instead of relying on the formik props,
             */
            _onChangeCallback(_formikProps, _value);
          }
        }}
      />
    );
  }


  static getInputTextBox = (
    _isReadOnly,
    _multiline,
    _noOfRows,
    _className,
    _formikProps,
    _validationSchema,
    _key,
    _label,
    _type = "text",
    _minWidth = "47%",
    _onChangeCallback = null,
    _size = "small",
    _inputProps = {},
    _otherOptions = {},
  ) => {

    return (
      <TextField
        multiline={_multiline}
        rows={_noOfRows}
        disabled={_isReadOnly}
        {..._otherOptions} // spread other options
        id={_key}
        key={_key}
        name={_key}
        label={_label}
        type={_type}
        size={_size}
        variant={MatThemeService.inputVariant}

        style={{ minWidth: _minWidth }}
        className={_className}
        required={this.isRequired(_validationSchema, _key)} // checks if the field is set as required in the validations schema
        helperText={_formikProps.errors[_key]}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
        value={_formikProps.values[_key]}
        onBlur={_formikProps.handleBlur} // let formik handle the onBlurEvent
        InputProps={_inputProps}

        onChange={async (e) => {
          // Override formik's onChangeEvent Handler
          const _value = e.target.value;
          await _formikProps.setFieldValue(_key, _value, false); // shouldValidate: false -> don't trigger validations for all fields
          await _formikProps.setFieldTouched(_key, true, false); // shouldValidate: false -> don't trigger validations for all fields

          if (_onChangeCallback) {
            /** At this point <_onChangeCallback()> don't rely on _formikProps for new Values, instead use the _value
             * because formik's setFieldValue is a promise and is  not updated yet even with await,
             * a console.log(_formikProps) at this point will still show the oldValues,
             * so use the _value directly instead of relying on the formik props,
             */
            _onChangeCallback(_formikProps, _value);
          }
        }}
      />
    );
  }

  static getCheckBox = (
    _isReadOnly,
    _className,
    _formikProps,
    _validationSchema,
    _key,
    _label,
    _minWidth = "47%",
    _onChangeCallback = () => { },
    _otherCheckboxOptions = {},
    _otherFormControlOptions = {},
    _otherFormControlLabelOptions = {},
  ) => {
    const formControlKey = "formControl" + _key;
    const formControlLabelKey = "formControlLabel" + _key;
    const checkboxKey = "checkBox" + _key;

    return (
      <FormControl className={_className} disabled={_isReadOnly} component="fieldset" id={formControlKey} key={formControlKey} name={formControlKey} style={{ minWidth: _minWidth }} {..._otherFormControlOptions}>
        <FormControlLabel {..._otherFormControlLabelOptions} id={formControlLabelKey} key={formControlLabelKey} name={formControlLabelKey} style={{ paddingLeft: "4px" }}
          label={_label}
          control={
            <Switch {..._otherCheckboxOptions} id={checkboxKey} key={checkboxKey} name={checkboxKey}
              required={this.isRequired(_validationSchema, _key)} // checks if the field is set as required in the validations schema
              // size={_size} 
              // checked={this.parseBoolean(_formikProps.values[_key])}
              color="secondary"
              checked={DataService.parseBoolean(_formikProps.values[_key])}
              onChange={(e) => {
                const _value = !DataService.parseBoolean(_formikProps.values[_key]); // inverse the value

                // Override formik's onChangeEvent Handler
                _formikProps.setFieldValue(_key, _value, false); // shouldValidate: false -> don't trigger validations for all fields
                _formikProps.setFieldTouched(_key, true, false); // shouldValidate: false -> don't trigger validations for all fields
                // validate only this field
                _formikProps.validateField(_key);

                /** At this point <_onChangeCallback()> don't rely on _formikProps for new Values, instead use the _value
                 * because formik's setFieldValue is a promise and is  not updated yet even with await,
                 * a console.log(_formikProps) at this point will still show the oldValues,
                 * so use the _value directly instead of relying on the formik props,
                 */
                _onChangeCallback(_formikProps, _value);
              }}
            />
          }
        />
        <FormHelperText error>{_formikProps.errors[_key]}</FormHelperText>
      </FormControl>
    );
  }

  static getDatePicker = (
    _isReadOnly,
    _className,
    _formikProps,
    _validationSchema,
    _key,
    _label,
    _minDate = null,
    _maxDate = null,
    _minWidth = "47%",
    _onChangeCallback = () => { },
    _variant = "dialog",
    _size = "small",
    _format = "yyyy-MM-dd",
    _otherOptions = {},
    _disableFutureDates = false
  ) => {
    return (
      <DatePicker
        disabled={_isReadOnly}
        {..._otherOptions} // spread other options
        autoOk // close after click
        id={_key}
        key={_key}
        name={_key}
        label={_label}
        format={_format}
        variant={_variant}
        clearable={true} // works only if the above variant is dialog
        inputVariant={MatThemeService.inputVariant}
        size={_size}
        style={{ minWidth: _minWidth }}
        className={_className}
        required={this.isRequired(_validationSchema, _key)} // checks if the field is set as required in the validations schema
        helperText={_formikProps.errors[_key]}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
        value={_formikProps.values[_key]}
        onBlur={_formikProps.handleBlur} // let formik handle the onBlurEvent
        onChange={async (_value) => {
          // Override formik's onChangeEvent Handler
          // const _value = e.target.value;
          await _formikProps.setFieldValue(_key, _value, false); // shouldValidate: false -> don't trigger validations for all fields
          await _formikProps.setFieldTouched(_key, true, false); // shouldValidate: false -> don't trigger validations for all fields

          /** At this point <_onChangeCallback()> don't rely on _formikProps for new Values, instead use the _value
           * because formik's setFieldValue is a promise and is  not updated yet even with await,
           * a console.log(_formikProps) at this point will still show the oldValues,
           * so use the _value directly instead of relying on the formik props,
           */
          _onChangeCallback(_formikProps, _value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {MatIconService.DATE}
            </InputAdornment>
          ),
        }}
        disableFuture={_disableFutureDates}
      />
    );
  }

  static getIconButton = (_isReadOnly, _icon, _tooltip, _onClick, _color = "secondary", _key = undefined, _tooltipPlacement = "bottom", _styles = {}) => {
    return (
      <Tooltip key={_key} enterDelay={MatSpecService.tooltipEnterDelay} title={_tooltip} placement={_tooltipPlacement} arrow>
        <span>
          <IconButton style={_styles} disabled={_isReadOnly} color={_color} onClick={_onClick}>{_icon}</IconButton>
        </span>
      </Tooltip>
    );
  }

  static getSearchText = (_classes, _key, _value, _onChangeCallback) => {
    return (
      <div key={"div" + _key} className={_classes.searchOnBody}>
        <div key={"divIcon" + _key} className={_classes.searchIconOnBody}>{MatIconService.SEARCH}</div>
        <InputBase key={"divInputBase" + _key} placeholder="Search…" value={_value} onChange={_onChangeCallback}
          classes={{ root: _classes.inputRootOnBody, input: _classes.inputInputOnBody }}
          inputProps={{ "aria-label": "search" }} />
      </div>
    );
  }

  /**
   * 
   * @param _parentThisRef <this> keyword of the Parent-Ccomponent
   * @param {*} _onCancelClick Function to call when cancel button is clicked
   * @param {*} _onSaveClick *Function to call when cancel button is clicked, inside it Make sure to <this.setState({ isReadOnly: false });> after save success
   * @param {*} _onEditClick incase if there are any* additional actions to perform on onEdit Button Click
   */
  static getReadOnlyActions = (_parentThisRef, _isNew, _onCancelClick, _onSaveClick, _onEditClick = () => { }) => {
    if (_isNew) {
      return [
        LayoutService.getIconButton(false, MatIconService.OK, "Create", _onSaveClick, "inherit", "keyActionSave"),
        LayoutService.getIconButton(false, MatIconService.CANCEL, "Discard Changes", _onCancelClick, "secondary", "keyActionCancel")
      ];
    } else {
      return [
        !_parentThisRef.state.isReadOnly ? null : LayoutService.getIconButton(false, MatIconService.EDIT, "Edit", () => { _parentThisRef.setState({ isReadOnly: false }); _onEditClick(); }, "inherit", "keyActionEdit"),
        _parentThisRef.state.isReadOnly ? null : LayoutService.getIconButton(false, MatIconService.OK, "Save", _onSaveClick, "inherit", "keyActionSave"),
        LayoutService.getIconButton(false, MatIconService.CANCEL, _parentThisRef.state.isReadOnly ? "Close" : "Discard Changes", _onCancelClick, "secondary", "keyActionCancel")
      ];
    }
  }

  /**
   * 
   * @param _parentThisRef <this> keyword of the Parent-Ccomponent
   * @param {*} _isPermitted Permission check
   * @param {*} _isNew New or update
   * @param {*} _onCancelClick Function to call when cancel button is clicked
   * @param {*} _onSaveClick *Function to call when cancel button is clicked, inside it Make sure to <this.setState({ isReadOnly: false });> after save success
   * @param {*} _onEditClick incase if there are any* additional actions to perform on onEdit Button Click
   */
  static getControlledReadOnlyActions = (_parentThisRef, _isPermitted, _isNew, _onCancelClick, _onSaveClick, _onEditClick = () => { }) => {
    if (_isNew) {
      return [
        LayoutService.getIconButton(false, MatIconService.OK, "Create", _onSaveClick, "inherit", "keyActionSave"),
        LayoutService.getIconButton(false, MatIconService.CANCEL, "Discard Changes", _onCancelClick, "secondary", "keyActionCancel")
      ];
    } else {
      return !_isPermitted ?
        [LayoutService.getIconButton(false, MatIconService.CANCEL, _parentThisRef.state.isReadOnly ? "Close" : "Discard Changes", _onCancelClick, "secondary", "keyActionCancel")]
        : [
          !_parentThisRef.state.isReadOnly ? null : LayoutService.getIconButton(false, MatIconService.EDIT, "Edit", () => { _parentThisRef.setState({ isReadOnly: false }); _onEditClick(); }, "inherit", "keyActionEdit"),
          _parentThisRef.state.isReadOnly ? null : LayoutService.getIconButton(false, MatIconService.OK, "Save", _onSaveClick, "inherit", "keyActionSave"),
          LayoutService.getIconButton(false, MatIconService.CANCEL, _parentThisRef.state.isReadOnly ? "Close" : "Discard Changes", _onCancelClick, "secondary", "keyActionCancel")
        ];
    }
  }

  /**
 * 
 * @param _parentThisRef <this> keyword of the Parent-Ccomponent
 * @param {*} _onCancelClick Function to call when cancel button is clicked
 * @param {*} _onSaveClick *Function to call when cancel button is clicked, inside it Make sure to <this.setState({ isReadOnly: false });> after save success
 * @param {*} _onEditClick incase if there are any* additional actions to perform on onEdit Button Click
 */
  static getReadOnlyHeaderActions = (_parentThisRef, _isNew, _onCancelClick, _onSaveClick, _onEditClick = () => { }) => {
    if (_isNew) {
      return [
        { icon: MatIconService.OK, iconColor: "primary", isReadOnly: false, onClick: _onSaveClick, title: "Create" },
        { icon: MatIconService.CANCEL, iconColor: "secondary", isReadOnly: false, onClick: _onCancelClick, title: "Discard Changes" },
      ];
    } else {
      return [
        !_parentThisRef.state.isReadOnly ? null :
          {
            icon: MatIconService.EDIT, isReadOnly: false, title: "Edit", onClick: () => {
              _parentThisRef.setState({ isReadOnly: false });
              _onEditClick();
            }
          },
        _parentThisRef.state.isReadOnly ? null : { icon: MatIconService.OK, isReadOnly: false, onClick: _onSaveClick, title: "Save" },
        { icon: MatIconService.CANCEL, iconColor: "secondary", isReadOnly: false, onClick: _onCancelClick, title: _parentThisRef.state.isReadOnly ? "Close" : "Discard Changes" },
      ];
    }
  }


  static getReadOnlyActionsSolo = (_parentThisRef, _isNew, _onSaveClick, _onEditClick = () => { }) => {
    if (_isNew) {
      return [
        LayoutService.getIconButton(false, MatIconService.OK, "Create", _onSaveClick, "inherit", "keyActionSave"),
      ];
    } else {
      return [
        !_parentThisRef.state.isReadOnly ? null :
          LayoutService.getIconButton(false, MatIconService.EDIT, "Edit", () => {
            _parentThisRef.setState({ isReadOnly: false });
            _onEditClick();
          }, "inherit", "keyActionEdit"),
        _parentThisRef.state.isReadOnly ? null : LayoutService.getIconButton(false, MatIconService.OK, "Save", _onSaveClick, "inherit", "keyActionSave"),
      ];
    }
  }

  /**
   * // Fix for Issue: https://github.com/jquense/yup/issues/500 
   * @param {*} _yupValidationObj 
   */
  static getNullableValidation = (_yupValidationObj) => {
    // Fix for Issue: https://github.com/jquense/yup/issues/500
    return _yupValidationObj.nullable(true).transform((n, o) => { return (o === '' || o === "" || n === NaN) ? null : n; })
  }


  /**
   * Dialog rto be rendered inside a container componenet
   * @param {*} _container Dialog can be render inside the container
   */
  static getContainedDialogProps = (_scroll = true, _container = document.getElementById("divContainerWrapper")) => {
    //const divContainerWrapper = document.getElementById("divContainerWrapper");
    return {
      disableEnforceFocus: true, //  disables the mechanism that prevents focus leaving the dialog
      fullScreen: true,
      scroll: _scroll ? "paper" : "body",
      style: { position: 'absolute' },
      container: _container, // inside which this dialog should render
      BackdropProps: {
        style: { position: 'absolute', backgroundColor: MatThemeService.getBaseBG() }
      },
      PaperProps: {
        style: { backgroundColor: MatThemeService.getBaseBG() }
      }
    }
  }

  //#endregion



  static getKey(id) {
    return "Key" + id + MomentService.getRandomTimeStamp();
  }

  static buildWithErrorModel(_model) {
    const modelKeys = Object.keys(_model);
    modelKeys.forEach((modelKey) => {
      _model[modelKey + "_error"] = null;
    });
    return _model;
  }
  static removeErrorModel(_model) {
    const errorKeys = Object.keys(_model).filter((k) => k.endsWith("_error"));
    errorKeys.forEach((_errorKey) => {
      delete _model[_errorKey];
    });
    return _model;
  }

  static getHocComponenet(component) {
    return withStyles(MatClassService)(withRouter(component));
  }

  /**
   * Make Sure to import -dark theme in the component :
    import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
   * 
   */
  static getAgGridStyles(
    _excludeHeight = 184,
    _totalVh = 100,
    _width = "100%"
  ) {
    return {
      style: {
        height: `calc(${_totalVh}vh - ${_excludeHeight}px)`,
        width: `${_width}`,
      },
      className: MatThemeService.isDarkMode()
        ? "ag-theme-balham-dark"
        : "ag-theme-balham",
    };
  }

  static getAgGridTheme() {
    return {
      className: MatThemeService.isDarkMode()
        ? "ag-theme-balham-dark"
        : "ag-theme-balham",
    };
  }

  static getInputProps(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _isRequired = true,
    _size = "small"
  ) {
    return {
      disabled: _isReadOnly,
      className: _classes.dialogControl,
      id: _key,
      name: _key,
      label: _label,
      value: _formikProps.values[_key],
      onChange: _formikProps.handleChange,
      onBlur: _formikProps.handleBlur,
      error:
        _formikProps.errors[_key] !== undefined &&
        _formikProps.errors[_key] !== null,
      helperText: _formikProps.errors[_key],
      variant: MatThemeService.inputVariant,
      required: _isRequired,
      size: _size,
    };
  }

  static getInputProps2(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _isRequired = true,
    _size = "small",
    _helperText = "",
  ) {
    return {
      disabled: _isReadOnly,
      className: _classes.dialogControl,
      id: _key,
      name: _key,
      label: _label,
      value: _formikProps.values[_key],
      onBlur: _formikProps.handleBlur,
      error:
        _formikProps.errors[_key] !== undefined &&
        _formikProps.errors[_key] !== null,
      helperText: _helperText,
      variant: MatThemeService.inputVariant,
      required: _isRequired,
      size: _size,
    };
  }

  static getDateProps(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _isRequired = true,
    _variant = "dialog",
    _size = "small"
  ) {
    return {
      disabled: _isReadOnly,
      className: _classes.dialogControl,
      id: _key,
      name: _key,
      label: _label,
      value: _formikProps.values[_key],
      onChange: (e) => {
        _formikProps.setFieldValue(_key, e, true);
        _formikProps.setFieldTouched(_key, true, false);
      },
      onBlur: _formikProps.handleBlur,
      error:
        _formikProps.errors[_key] !== undefined &&
        _formikProps.errors[_key] !== null,
      helperText: _formikProps.errors[_key],
      variant: _variant,
      clearable: true,  // works only if the above variant is dialog
      required: _isRequired,
      size: _size,
      inputVariant: MatThemeService.inputVariant
    };
  }

  static getTimeProps(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _isRequired = true,
    _variant = "dialog",
    _size = "small"
  ) {
    return {
      disabled: _isReadOnly,
      className: _classes.dialogControl,
      id: _key,
      name: _key,
      label: _label,
      value: _formikProps.values[_key],
      onChange: (e) => {
        //e = e.toTimeString().substring(0, 8);
        _formikProps.setFieldValue(_key, e, true);
        _formikProps.setFieldTouched(_key, true, false);
      },
      onBlur: _formikProps.handleBlur,
      error:
        _formikProps.errors[_key] !== undefined &&
        _formikProps.errors[_key] !== null,
      helperText: _formikProps.errors[_key],
      variant: _variant,
      clearable: true,  // works only if the above variant is dialog
      required: _isRequired,
      size: _size,
      inputVariant: MatThemeService.inputVariant
    };
  }

  static handleFormikValueChange(e, input, formikProps) {
    formikProps.formikHandleChange(e);
  }

  /**
   * Depricated, use getDropDown() instead -> the function with callback
   */
  static getSelectControl(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _options,
    _optionKey,
    _optionLabel,
    _minwidth = "47%",
    _showEmpty = false,
  ) {
    // console.log(_label, _formikProps.values[_key]);
    const labelKey = _key + "label";
    const formControlKey = _key + "form_control";
    return (
      <FormControl
        key={formControlKey}
        disabled={_isReadOnly}
        style={{ minWidth: _minwidth }}
        className={_classes.dialogControl}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
      >
        <InputLabel id={labelKey}>{_label}</InputLabel>
        <Select
          id={_key}
          labelId={labelKey}
          disabled={_isReadOnly}
          MenuProps={{ classes: { paper: _classes.menuPaper } }}
          value={_formikProps.values[_key]}
          onChange={(e) => {
            _formikProps.setFieldValue(_key, e.target.value, true);
            _formikProps.setFieldTouched(_key, true, false);
          }}
        >
          {_showEmpty ? (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          ) : null}
          {(_options || []).map((option) => (
            <MenuItem key={option[_optionKey]} value={option[_optionKey]}>
              {option[_optionLabel]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{_formikProps.errors[_key]}</FormHelperText>
      </FormControl>
    );
  }

  /**
 * Depricated, use getDropDown() instead -> the function with callback
 */
  static getSelectControlOutlined(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _options,
    _optionKey,
    _optionLabel,
    _minwidth = "47%",
    _showEmpty = false,
  ) {
    // console.log(_label, _formikProps.values[_key]);
    const labelKey = _key + "label";
    return (
      <FormControl
        variant="outlined"
        size="small"
        disabled={_isReadOnly}
        style={{ minWidth: _minwidth }}
        className={_classes.dialogControl}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
      >
        <InputLabel id={labelKey}>{_label}</InputLabel>
        <Select
          id={_key}
          labelId={labelKey}
          disabled={_isReadOnly}
          MenuProps={{ classes: { paper: _classes.menuPaper } }}
          value={_formikProps.values[_key]}
          onChange={(e) => {
            _formikProps.setFieldValue(_key, e.target.value, true);
            _formikProps.setFieldTouched(_key, true, false);
          }}
        >
          {_showEmpty ? (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          ) : null}
          {(_options || []).map((option) => (
            <MenuItem key={option[_optionKey]} value={option[_optionKey]}>
              {option[_optionLabel]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{_formikProps.errors[_key]}</FormHelperText>
      </FormControl>
    );
  }

  static getSwitch(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _isRequired = true,
    _minwidth = "47%",
    _size = "medium",
    _onChangeCallback = null
  ) {
    const labelKey = _key + "_switch";
    const formControlKey = _key + "_formControl";

    return (
      <FormControl key={formControlKey} disabled={_isReadOnly} component="fieldset">
        <FormControlLabel
          style={{ paddingLeft: "4px" }}
          label={_label}
          control={
            <Switch
              id={_key}
              name={labelKey}
              disabled={_isReadOnly}
              required={_isRequired}
              size={_size}
              checked={_formikProps.values[_key]}
              //color="primary"
              onChange={(e) => {
                _formikProps.setFieldValue(_key, e.target.checked, true);
                _formikProps.setFieldTouched(_key, true, false);
                if (_onChangeCallback) { _onChangeCallback(e.target.checked); }
              }}
            />
          }
        />
        <FormHelperText>{_formikProps.errors[_key]}</FormHelperText>
      </FormControl>
    );
  }
  static getChipSelect(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _options,
    _optionKey,
    _optionLabel,
    _onChangeCallback,
    _minwidth = "47%",
    _noOptionsText = "Not Found",
  ) {
    const labelKey = _key + "label";
    return (
      <FormControl
        disabled={_isReadOnly}
        style={{ minWidth: _minwidth }}
        className={_classes.dialogControl}
        error={
          _formikProps.errors[_key] !== undefined &&
          _formikProps.errors[_key] !== null
        }
      >
        <Autocomplete
          labelid={labelKey}
          id={_key}
          disabled={_isReadOnly}
          multiple
          // default value
          defaultValue={_formikProps.values[_key]}
          value={_formikProps.values[_key]}
          // options
          options={_options}
          noOptionsText={_noOptionsText}
          // set selected values
          getOptionLabel={(option) => option[_optionLabel]}
          getOptionSelected={(option) =>
            _formikProps.values[_key].find(
              (v) => v[_optionKey] === option[_optionKey]
            )
          }
          // handle on change callback
          onChange={async (e, newValues, reason) => {
            await _formikProps.setFieldValue(_key, newValues, true);
            await _formikProps.setFieldTouched(_key, true, false);
            /** At this point <_onChangeCallback()> don't rely on _formikProps for new Values, instead use the _newValues
             * because formik's setFieldValue is a promise and is  not updated yet even with await,
             * a console.log(_formikProps) at this point will still show the oldValues,
             * so use the newValues directly instead of relying on the formik props,
             */
            _onChangeCallback(_formikProps, newValues);
          }}
          // render in the text in the chip
          renderInput={(params) => (
            <TextField {...params} variant={MatThemeService.inputVariant} label={_label} />
          )}
        />
        <FormHelperText>{_formikProps.errors[_key]}</FormHelperText>
      </FormControl>
    );
  }

  static getChipSelect2(
    _isReadOnly,
    _classes,
    _formikProps,
    _key,
    _label,
    _options,
    _optionKey,
    _optionLabel,
    _defaultValue,
    stateHandler,
    _minwidth = "47%",
  ) {
    const labelKey = _key + "label";
    return (
      <FormControl
        disabled={_isReadOnly}
        style={{ minWidth: _minwidth }}
        className={_classes.dialogControl}
      >
        <Autocomplete
          labelid={labelKey}
          id={_key}
          disabled={_isReadOnly}
          multiple
          options={_options}
          noOptionsText="Not Found"
          defaultValue={_defaultValue}
          value={_defaultValue}
          getOptionLabel={(option) => option[_optionLabel]}
          onChange={(e, newVal, reason) => {
            stateHandler(newVal);
          }}
          renderInput={(params) => (
            <TextField {...params} variant={MatThemeService.inputVariant} label={_label} />
          )}
        />
        {/* <FormHelperText>Chip Select Error</FormHelperText> */}
      </FormControl>
    );
  }

}