
export const textFilterCriteria = [
  {
    lovId: 'equals', lovKey: 'equals'
  }, {
    lovId: 'notequals', lovKey: 'not equals'
  },
  {
    lovId: 'contains', lovKey: 'contains'
  }];

export const lovFilterCriteria = [{
  lovId: 'equals', lovKey: 'equals'
}, {
  lovId: 'notequals', lovKey: 'not equals'
}];

export const valueFilterCriteria = [
  {
    lovId: 'equals', lovKey: 'equals'
  },
  {
    lovId: 'lessthan', lovKey: 'less than'
  },
  {
    lovId: 'greaterthan', lovKey: 'greater than'
  }, {

    lovId: 'between', lovKey: 'between'
  }];

export const dateFilterCriteria = [{
  lovId: 'between', lovKey: 'between'
}];

export const usMiamiHcpValues = [
  {
    lovId: 'yes', lovKey: 'Yes'
  },
  {
    lovId: 'no', lovKey: 'No'
  }
]