import React from "react";
import { combineLatest, } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  TextField,
  Grid,
  FormGroup,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../shared/store/authProvider";
import {
  ResultStatus,
  CrudAction,
  API_ENDPOINT,
} from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import LayoutService from "../../../shared/services/layoutService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../shared/services/apiService";
import StudyDetailsService from "./studyDetailsService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import { MatIconService } from "../../../shared/services/theme/matIconService";

class StudyDetailsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  lastValidatedCompanyStudyId = null;
  lastValidatedCompanyStudyResult = false;

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      fetchResult: ResultStatus.NOT_LOADED,
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        StudyDetailsService.getNonEntityLOVsByIDAsOBS(this.context.user.tenantId, 5),
        StudyDetailsService.getNonEntityLOVsByIDAsOBS(this.context.user.tenantId, 4),
      ]).subscribe(
        // success
        ([_researchTypeData, _delayIndicatorData,]) => {
          this.setState(
            {
              researchTypeData: _researchTypeData,
              delayIndicatorData: _delayIndicatorData,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  handleSubmit = async (_formikProps) => {
    console.log("_formikProps", _formikProps);
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema
        .validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            companyStudyId: _formikProps.values.companyStudyId,
            studyName: _formikProps.values.studyName,
            delayIndicator: String(_formikProps.values.delayIndicator - 9),
            researchType: _formikProps.values.researchType,
            clinicalTrailId: _formikProps.values.clinicalTrailId,
            isActive: _formikProps.values.isActive,
            infoLink: _formikProps.values.infoLink,
            comments: _formikProps.values.comments,
            startDate: _formikProps.values.startDate,
            approvalDate: _formikProps.values.approvalDate,
            researchDate: _formikProps.values.researchDate,  // RESEARCHTERMDATE is the corresponding Database name
          };

          // 3) determine the action and assign the appropriate props
          let apiUrl = "";
          var actionVerb = "";
          const dtNow = new Date();
          if (this.state.isEditing) {
            // UPDATE
            actionVerb = "PUT";
            apiUrl = `/Study/EditStudy/${this.context.user.tenantId}`;
            mappedObj.studyId = this.props.modalAgNode.studyId;
            mappedObj.createdDate = this.props.modalAgNode.createdDate;
            mappedObj.createdBy = this.props.modalAgNode.createdBy;
            mappedObj.updatedDate = dtNow;
            mappedObj.updatedBy = this.context.user.userId;
          } else {
            // CREATE
            actionVerb = "POST";
            apiUrl = `/Study/AddStudy/${this.context.user.tenantId}`;
            mappedObj.studyId = 0;
            mappedObj.createdDate = dtNow;
            mappedObj.createdBy = this.context.user.userId;
            mappedObj.updatedDate = dtNow;
            mappedObj.updatedBy = this.context.user.userId;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            apiUrl,
            JSON.stringify(mappedObj)
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshGrid(true);
                this.props.onClose(false);
              } else {
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Study Details", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          if (erroObj.inner) {
            erroObj.inner.forEach((err) => {
              _formikProps.setFieldError(err.path, err.message);
            });
          }
        });
    }
  };

  formatDate = (_strDate) => {
    console.log("_strDate", _strDate);
    if (Date.parse(_strDate)) {
      const dt = new Date(_strDate);
      console.log("dt1", dt);
      return dt.toISOString();
    } else {
      const dt = new Date();
      console.log("dt2", dt);
      return dt.toISOString();
    }
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.UPDATE) {
      return {
        studyName: this.props.modalAgNode.studyName,
        companyStudyId: this.props.modalAgNode.companyStudyId,
        delayIndicator: Number(this.props.modalAgNode.delayIndicator) + 9,
        researchType: this.props.modalAgNode.researchType,
        clinicalTrailId: this.props.modalAgNode.clinicalTrailId,
        infoLink: this.props.modalAgNode.infoLink,
        comments: this.props.modalAgNode.comments,
        startDate: this.formatDate(this.props.modalAgNode.startDate),
        approvalDate: this.props.modalAgNode.approvalDate !== undefined ? this.formatDate(this.props.modalAgNode.approvalDate) : null,
        researchDate: this.props.modalAgNode.researchDate !== undefined ? this.formatDate(this.props.modalAgNode.researchDate) : null,
        isActive: this.props.modalAgNode.isActive,
      };
    } else {
      return {
        studyName: "",
        companyStudyId: this.props.modalAgNode.companyStudyId,
        delayIndicator: "",
        researchType: "",
        clinicalTrailId: "",
        infoLink: "",
        comments: "",
        startDate: null,
        approvalDate: null,
        researchDate: null,
        isActive: true,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      studyName: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(500, "Must be 500 characters or less"),
      companyStudyId: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less")
        .test(
          "checkDuplicateCompanyStudyId",
          "Company Study Id already exists",
          async (_formikValue) => {
            if (this.props.inputAction === CrudAction.UPDATE) {
              if (_formikValue === this.props.modalAgNode.companyStudyId) {
                return true;
              }
            }
            try {
              const result = await fetch(
                `${process.env.REACT_APP_TENANT_HTTP}/Study/CompanyStudyExists/${this.context.user.tenantId}/${_formikValue}`
              );
              const isExists = await result.json();
              return !isExists;
            } catch (error) {
              this.setState({
                fetchResult: ResultStatus.ERROR,
              });
            }
          }
        ),
      delayIndicator: Yup.number().required("Required").typeError('Required'),
      researchType: Yup.string().required("Required").typeError('Required'),
      startDate: Yup.date().required("Required").typeError('Required').min("1/1/2000", "On or after 1/1/2000").max("1/1/2099", "On or before 1/1/2099"),
      clinicalTrailId: Yup.string()
        .test(
          "checkLengthOfClinicalTrialId",
          "Must be exactly 11 characters or empty",
          (val) => {
            console.log("val", val);
            if (val === undefined) {
              return true
            } else {
              if (val === "" || val.length === 11) {
                return true;
              } else {
                return false;
              }
            }
          }),
    });
    return this.validationSchema;
  }

  render() {
    const { classes } = this.props;
    if (RolePermissionService.STUDY_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Study Details"
            />
          );
        case ResultStatus.SAVING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Saving Study Details"
            />
          );
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <>
              <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }} >
                {(fProps) => (
                  <form>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* Dialog Title */}
                      <DialogTitle disableTypography id="dialogTitle">
                        <AppBar position="static">
                          <Toolbar variant="dense">
                            <Typography variant="h6" className={classes.root}>Study Details</Typography>
                            {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.STUDY_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                              () => { this.props.onClose(false) }, "secondary")
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            }
                          </Toolbar>
                        </AppBar>
                      </DialogTitle>
                      {/* Dialog Content */}
                      <DialogContent>
                        <Box
                          style={{
                            paddingLeft: 16,
                            paddingRight: 32,
                            paddingTop: 16,
                            paddingBottom: 32,
                            minWidth: "80vh",
                            maxWidth: "80vh",
                          }}
                        >
                          {this.state.fetchResult === ResultStatus.SUCCESS ? (
                            <h1>Your Study has been updated!!</h1>
                          ) : null}
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "companyStudyId",
                                  "Study Id"
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "studyName",
                                  "Study Name"
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "infoLink",
                                  "Info Link",
                                  false,
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {LayoutService.getSelectControl(this.state.isReadOnly,
                                classes,
                                fProps,
                                "delayIndicator",
                                "Delay Indicator *",
                                this.state.delayIndicatorData,
                                "lovId",
                                "lovKey",
                                "98%"
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {LayoutService.getSelectControl(this.state.isReadOnly,
                                classes,
                                fProps,
                                "researchType",
                                "Research Type *",
                                this.state.researchTypeData.sort((a, b) => a.lovKey.localeCompare(b.lovKey)),
                                "lovKey",
                                "lovKey",
                                "98%"
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormGroup
                                style={{ paddingLeft: "4px", paddingTop: "8px" }}
                              >
                                {LayoutService.getSwitch(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "isActive",
                                  "Active"
                                )}
                              </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                {...LayoutService.getDateProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "startDate",
                                  "Study Start Date",
                                  true
                                )}
                                format="yyyy-MM-dd"
                                style={{ minWidth: "47%" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                {...LayoutService.getDateProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "approvalDate",
                                  "FDA Approval Date",
                                  false
                                )}
                                format="yyyy-MM-dd"
                                style={{ minWidth: "47%" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <DatePicker
                                {...LayoutService.getDateProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "researchDate",
                                  "Early Termination Date",
                                  false
                                )}
                                format="yyyy-MM-dd"
                                style={{ minWidth: "47%" }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "clinicalTrailId",
                                  "Clinicaltrials.gov ID",
                                  false,
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "comments",
                                  "Comments",
                                  false,
                                )}
                                fullWidth
                              />
                            </Grid>

                            {/* <Grid item xs={12} sm={6}><pre>{JSON.stringify(this.props.modalAgNode,null,2)}</pre></Grid> */}

                          </Grid>
                        </Box>
                      </DialogContent>
                    </MuiPickersUtilsProvider>
                  </form>
                )}
              </Formik>
            </>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent
              title="Error"
              description="Error in Study Details"
              classes={classes}
              onClose={() => {
                this.props.onClose(false);
              }}
              onRetry={() => {
                console.log("Retry Clicked");
              }}
            />
          );
      }
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(StudyDetailsComponent);
