import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppBar, Box, DialogTitle, DialogContent, FormHelperText, Grid, Input, TextField, Toolbar, Typography } from "@material-ui/core";
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, CrudAction, API_ENDPOINT, } from "../../../../shared/types/enums";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import ApiService from "../../../../shared/services/apiService";
import ToastService from "../../../../shared/services/toastService";
import EventDetailsService from "./eventDetailsService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

import { format } from "date-fns";

class EventDetailsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      eventById: {},
      userTenantList: [],
      isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      fetchResult: ResultStatus.LOADED,
      isEdit: this.props.inputAction !== CrudAction.CREATE
    };
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    // this.setState({ fetchResult: ResultStatus.LOADED });
    console.log(this.props);
    this.context.user.tenantId === 1 ? this.setState({ isPorzio: true }) : this.setState({ isPorzio: false });
    if (this.state.isEdit) {
      this.setState({ fetchResult: ResultStatus.LOADING });
      this.fetchEventDetailsById();
    }
    this.fetchTenants();

  }

  fetchTenants = async () => {
    if (this.context.user.tenantId === 1) {
      const getUserTenantsResult = await fetch(`${process.env.REACT_APP_IDENTITY_HTTP}/User/GetUserTenants?userId=${this.context.user.userId}`);
      var getUserTenantsJson = await getUserTenantsResult.json();
      getUserTenantsJson = DataService.getKeyValueCollection(getUserTenantsJson, "tenantid", "tenantName", true);
      this.setState({ userTenantList: getUserTenantsJson }, () => {
        console.log(this.state.userTenantList);
      });
    }
    else {
      this.setState({ selEventVisibilityList: [this.context.user.tenantId] });
    }
  }

  fetchEventDetailsById = async () => {
    try {
      this.oSubscriptions.add(
        EventDetailsService.getEventByIdAsOBS(this.context, this.props.updateData.eventId)
          .subscribe((_eventById) => {
            _eventById.eventStartTime = format(new Date(_eventById.eventStartDate.substring(0, 4), _eventById.eventStartDate.substring(5, 7), _eventById.eventStartDate.substring(8, 10), _eventById.eventStartTime.hours, _eventById.eventStartTime.minutes, _eventById.eventStartTime.seconds), "yyyy-MM-dd HH:mm:ss");
            _eventById.eventEndTime = format(new Date(_eventById.eventEndDate.substring(0, 4), _eventById.eventEndDate.substring(5, 7), _eventById.eventEndDate.substring(8, 10), _eventById.eventEndTime.hours, _eventById.eventEndTime.minutes, _eventById.eventEndTime.seconds), "yyyy-MM-dd HH:mm:ss");
            this.setState(
              {
                eventById: _eventById,
              },
              () => {
                console.log(this.state.eventById);
                var now = new Date();
                console.log(now);
                console.log();

                this.setState({
                  fetchResult: ResultStatus.LOADED,
                });
              }
            );
          })
      );
    } catch {
      this.setState({
        fetchResult: ResultStatus.ERROR,
      });
    }
  }
  getInitialValues() {
    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    return {
      selEventVisibilityList: this.state.isEdit ? [] : [],//TODO
      eventTypeId: this.state.isEdit ? this.state.eventById.eventTypeId : 365,
      eventTitle: this.state.isEdit ? this.state.eventById.eventTitle : "",
      eventStartDate: this.state.isEdit ? new Date(this.state.eventById.eventStartDate) : null,
      eventEndDate: this.state.isEdit ? new Date(this.state.eventById.eventEndDate) : null,
      eventStartTime: this.state.isEdit ? this.state.eventById.eventStartTime : null,
      eventEndTime: this.state.isEdit ? this.state.eventById.eventEndTime : null,
      alldayevent: this.state.isEdit ? this.state.eventById.alldayevent : false,
      eventBody: this.state.isEdit ? this.state.eventById.eventBody : "",
      attachmentName: this.state.isEdit ? this.state.eventById.attachmentName : "",
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      //selEventVisibilityList: Yup.array(),
      eventTypeId: Yup.number().required("Required").typeError("Required"),
      eventTitle: Yup.string().required("Required").max(256, "Must be 256 characters or less"),
      eventStartDate: Yup.date().nullable(true).required("Required"),
      eventEndDate: Yup.date()
        .when("eventStartDate",
          (startDate, yup) => {
            if (startDate) {
              var d = startDate;
              d.setHours(0, 0, 0, 0);
              return yup.nullable(true).required("Required").min(d, "Due date cannot be before Start date")
            } else {
              return yup.nullable(true).required("Required")
            }
          }),
      eventStartTime: Yup.string().nullable(true).required("Required"),
      eventEndTime: Yup.string().nullable(true).required("Required"),
      //alldayevent: Yup.boolean().required("Required"),
      eventBody: Yup.string(),
      attachmentName: Yup.string(),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    console.log("_formikProps", _formikProps);

    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING, });

          var selEventVisibilityListIds = [];

          const itemAll = DataService.getFirstOrDefault(_formikProps.values.selEventVisibilityList.filter((x) => x.id === LookupService.allElement.id));
          if (itemAll) {
            selEventVisibilityListIds = DataService.removeElementAll(this.state.userTenantList, "id").map((x) => x.id);
          } else {
            selEventVisibilityListIds = _formikProps.values.selEventVisibilityList.map(x => x.id);
          }

          console.log(selEventVisibilityListIds.join());
          //console.log(this.state.eventById);
          console.log(_formikProps.values.eventEndTime);
          // 2) extract the formik values into a FormData object
          const formData = new FormData();
          formData.append("EventVisibilityId", this.state.isPorzio ? selEventVisibilityListIds.join() : this.context.user.tenantId);
          formData.append("EventTypeId", _formikProps.values.eventTypeId);
          formData.append("EventTitle", _formikProps.values.eventTitle);
          formData.append("EventStartDate", this.state.eventById.eventStartDate !== _formikProps.values.eventStartDate ?
            _formikProps.values.eventStartDate.toISOString().substring(0, 10) : this.state.eventById.eventStartDate);
          formData.append("EventEndDate", this.state.eventById.eventEndDate !== _formikProps.values.eventEndDate ?
            _formikProps.values.eventEndDate.toISOString().substring(0, 10) : this.state.eventById.eventEndDate);

          formData.append("EventStartTime", new Date(_formikProps.values.eventStartTime).toTimeString().substring(0, 8));
          formData.append("EventEndTime", new Date(_formikProps.values.eventEndTime).toTimeString().substring(0, 8));

          formData.append("ALLDAYEVENT", _formikProps.values.alldayevent);
          formData.append("EventBody", _formikProps.values.eventBody);
          formData.append("CreateBy", this.context.user.userId);
          formData.append("IsActive", true);

          formData.append("AttachmentName", this.state.selectedFileName ? this.state.selectedFileName : "");
          formData.append("BlobName", "");
          //formData.append("efile", this.state.selectedFile ? this.state.selectedFile : null);
          if (this.state.selectedFile) {
            //formData.append("AttachmentName", this.state.selectedFileName);
            formData.append("efile", this.state.selectedFile);
          }

          // 3) determine the action and assign the appropriate props
          const actionVerb = "POST";
          const targetUrl = `${process.env.REACT_APP_TENANT_HTTP}/Calendar/CreateOrUpdateEvent/${this.context.user.tenantId}`;
          if (this.props.inputAction === CrudAction.CREATE) {
            // CREATE
            formData.append("EventId", 0);
          } else {
            // UPDATE
            formData.append("EventId", this.props.updateData.eventId);
          }

          // Do not set the Content-type in the headers below.  Let the browser set it. For example
          // Content-Type: multipart/form-data; boundary=----WebKitFormBoundary3sy40ozPAfPAnNRy
          // The browser handles and controls the random string at the end of the boundary
          const headers = {
            Authorization: `Bearer ${this.context.authToken}`
          };

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            targetUrl,
            formData,
            headers
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshEventList(true,
                  this.state.eventById.eventStartDate !== _formikProps.values.eventStartDate ?
                    new Date(_formikProps.values.eventStartDate) : new Date(this.state.eventById.eventStartDate)
                );
                this.props.onClose(false);
                ToastService.showSuccess("Event Saved");
              } else {
                console.error("Error: falsey successResult while saving Calender Event", successResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
                ToastService.showError("An Error occured while saving the Event");
              }
            },
            (errorResult) => {
              console.error("Error while saving Calender Event", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
              ToastService.showError("An Error occured while saving the Event");
            }
          );
        })
        .catch((erroObj) => {
          console.log("erroObj", erroObj);
          erroObj.inner && erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }

  onEventVisibilityChange = async (_formikProps, _newValues) => {
    //console.log(_newValues);
    // check for <All> Item
    const itemAll = DataService.getFirstOrDefault(_newValues.filter((x) => x.id === LookupService.allElement.id));
    // if <all> is selected and is not the only selected item, then clear others
    if (itemAll && _newValues.length !== 1) {
      // then clear the others except All
      await _formikProps.setFieldValue("selEventVisibilityList", [itemAll], true);
      this.setState({ selEventVisibilityList: [itemAll] }, () => {
        console.log(this.state.selEventVisibilityList)
      });
    }
  }
  /**Render */
  render() {
    const { classes } = this.props;

    if (RolePermissionService.EVENT_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label="Loading Task Details" />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label="Saving Task Details" />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }} >
              {(fProps) => (
                <form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* Dialog Title */}
                    <DialogTitle disableTypography id="dialogTitle">
                      <AppBar position="static">
                        <Toolbar variant="dense">
                          <Typography variant="h6" className={classes.root}>Event Detail</Typography>
                          {/* <IconButton color="inherit" onClick={() => { this.handleSubmit(fProps); }}> {MatIconService.OK} </IconButton>
                        <IconButton color="secondary" onClick={() => { this.props.onClose(false); }}> {MatIconService.CANCEL} </IconButton> */}
                          {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.EVENT_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                            () => { this.props.onClose(false) }, "secondary")
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                          }
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    {/* Dialog Content */}
                    <DialogContent>
                      <Box
                        style={{
                          paddingLeft: 16,
                          paddingRight: 32,
                          paddingTop: 16,
                          paddingBottom: 32,
                          minWidth: "80vh",
                          maxWidth: "80vh"
                        }}>
                        <Grid container spacing={1}>
                          {this.state.isPorzio ? <Grid item xs={12} sm={6}>{LayoutService.getChipSelect(this.state.isReadOnly, classes, fProps, "selEventVisibilityList", "Event Visibility", this.state.userTenantList, "id", "text", this.onEventVisibilityChange, "90%", true)}</Grid> : <></>}
                          <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "eventTypeId", "Event Type", LookupService.EVENTTYPES, "id", "name", "90%", false)}</Grid>

                          <Grid item xs={12} sm={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "eventTitle", "Event Title")} fullWidth /></Grid>


                          <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "eventStartDate", "Event Start Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                          <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "eventEndDate", "Event End Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>

                          <Grid item xs={12} sm={6}><TimePicker {...LayoutService.getTimeProps(this.state.isReadOnly || fProps.values.alldayevent, classes, fProps, "eventStartTime", "Event Start Time", !fProps.values.alldayevent)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}><TimePicker {...LayoutService.getTimeProps(this.state.isReadOnly || fProps.values.alldayevent, classes, fProps, "eventEndTime", "Event End Time", !fProps.values.alldayevent)} fullWidth /></Grid>

                          <Grid item xs={12} sm={4}> {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "alldayevent", "All Day Event", false)} </Grid>

                          <Grid item xs={12} sm={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "eventBody", "Event Body", false)} fullWidth multiline rows={3} /></Grid>

                          <Grid item xs={12}>
                            <FormHelperText>Attachment</FormHelperText>
                            <Input type="file" onChange={(event) => this.setState({ selectedFile: event.target.files[0], selectedFileName: event.target.files[0].name })} fullWidth />
                            <div style={{ display: "flex" }}>
                              <Typography variant="body1" className={classes.root} color="secondary" display="inline">Attached File:</Typography>
                              <Typography variant="body1" className={classes.root} display="inline">{this.props.inputAction === CrudAction.UPDATE ? this.state.eventById.attachmentName : ""}</Typography>
                            </div>
                          </Grid>

                        </Grid>
                      </Box>
                    </DialogContent>
                  </MuiPickersUtilsProvider>
                </form>
              )}
            </Formik>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent small label="Error Loading Task Details" classes={classes} onRetry={() => {
              //this.SERVICE.fetchData(this, true);
            }}
            />
          );
      }
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(EventDetailsComponent);