import React from "react";
import { combineLatest, of } from "rxjs";
import { DialogTitle, DialogContent, Box, Typography, AppBar, Toolbar, IconButton, InputBase } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../../shared/types/enums";
import LayoutService from "../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import ReportReferencesService from "./reportReferencesService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class ReportReferencesComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      porzioSearch: "",
      agGridUtils: new AgGridUtil("lastname", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
      }),
    };
  }
  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }
  componentDidMount() {
    if (this.props.inputAction === CrudAction.UPDATE) {
      this.setState({ fetchResult: ResultStatus.LOADING });
      this.fetchData();
    } else {
      this.setState({ fetchResult: ResultStatus.LOADED });
    }
  }

  /** API Fetch */
  fetchData = () => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
        ReportReferencesService.getRuleSummaryAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode,),
      ]).subscribe(
        // success
        ([_data,]) => {
          this.setState(
            {
              data: _data,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({ modalAgNode: node });
    if (this.state.agGridUtils.isNotEditing()) {
      this.setState({ showProfileDetailDialog: true });
    }
  };

  // render
  TAB_PERMISSIONS = RolePermissionService.TRANSACTION_REPORT_REFERENCES;
  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component


    if (this.TAB_PERMISSIONS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    }
    else {
      const componentType = "Report References";
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle" />
              <DialogContent>
                <Box style={{ padding: "8px",paddingTop:"48px", minHeight: "70vh", maxHeight: "70vh", }}>
                  <div {...LayoutService.getAgGridStyles(320)}>
                    <AgGridReact
                      pagination={true}
                      paginationPageSize={50}
                      rowData={this.state.data}
                      columnDefs={ReportReferencesService.getColumnDefs(this)}
                      frameworkComponents={this.state.agGridUtils.frameworkComponents}
                      suppressClickEdit={true}

                      gridOptions={{
                        context: { componentParent: this },
                        ...AgGridColumnExt.getGridOptions(40),
                        ...this.state.agGridUtils.bindInlineEditEvents(),
                      }}
                      onGridReady={(params) => {
                        //this.state.agGridUtils.sizeColumnsToFit();
                        this.state.agGridUtils.setGridParams(params, false);
                      }}></AgGridReact>
                  </div>

                </Box>
              </DialogContent>
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
              onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
          );
      }
    }
  }

  /** 2/4 Required in Parent */
  isDirtyCallback = () => {
    return false;
    // do any additional checkings if needed
    // if (this.fPropsDynamic) {
    //   return this.fPropsDynamic.dirty;
    // } else {
    //   return false;
    // }
  }
  /** 3/4 Required in Parent */
  resetCallback = () => {
    // if (this.fPropsDynamic) {
    //   this.fPropsDynamic.resetForm();
    // }
    // do any additional resetting if needed
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    return of(null);
    // if (DataService.isNullOrUndefined(this.fPropsDynamic)) { return of(null); }
    // else {

    // }
  }

}

/** HOC */
export default LayoutService.getHocComponenet(ReportReferencesComponent);
