import { of, ReplaySubject } from "rxjs";
import { DataService } from "../../../shared/services/dataService";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT, TenantSelectList } from "../../../shared/types/enums";

export default class SalesRepService {
  /** Step-1/3
   * make sure the lookup observable is mentioned here,
   * this is used to fetch commonly used observables after the user logs in,
   * using the rxJs's combine latest operator
   *  */
  static fetchEditRulesLookupsOBS() {
    return [
      SalesRepService.getSalesRepsAsOBS(),
      SalesRepService.getCountriesAsOBS(),
    ];
  }

  /** STEP-2/3
   * Clear the cache
   * This function should be called after the user Login/Logout,
   * The reson for clering the cache on user login is the lookups are populated for context.user.tenantId,
   */
  static clearAll() {
    SalesRepService._SALESREPS = [];
    SalesRepService._COUNTRIES = [];
  }

  /** STEP 3/3
   * Define the fetch logic below
   */
  // _BUSINESSRULE
  static _SALESREPS = [];
  static getSalesRepsAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(SalesRepService._SALESREPS)) {
      oSubject.next(SalesRepService._SALESREPS);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/SalesRep/GetAllSalesRep/${_context.user.tenantId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        SalesRepService._SALESREPS = _data;
        oSubject.next(SalesRepService._SALESREPS);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _JURISDICTION
  static _COUNTRIES = [];
  static getCountriesAsOBS = (_context) => {
    var oSubject = new ReplaySubject(); // 1st

    // if local_cache is not Empty
    if (DataService.hasElements(SalesRepService._COUNTRIES)) {
      oSubject.next(SalesRepService._COUNTRIES); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.COUNTRY}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        SalesRepService._COUNTRIES = _data;
        oSubject.next(SalesRepService._COUNTRIES);
      });
    }

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "SalesRep Id",
        field: "companySalesRepId",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        cellRenderer: "salesRepLinkRendererComponent",
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "First Name",
        field: "salesrepfirstname",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Last Name",
        field: "salesreplastname",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Province",
        field: "province",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Country",
        field: "countryName",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "License Oregon",
        field: "licenseOregon",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "License Chicago",
        field: "licenseChicago",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "License Connecticut",
        field: "licenseConnecticut",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "License DC",
        field: "licenseDC",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "License Nevada",
        field: "licenseNevada",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 100,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "License Miami Indicator",
        field: "licenseMiamiIndicator",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 50,
        cellRenderer: "isActiveIconRendererComponent",
      },
      {
        headerName: "Active",
        field: "isActive",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 50,
        cellRenderer: "isActiveIconRendererComponent",
      },
    ];
  }
}
