import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import SelectedRulesService from "./selectedRulesService";
import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { withStyles, Dialog } from "@material-ui/core";
import { ResultStatus } from "../../shared/types/enums";
import IsActiveIconRendererComponent from "../../shared/components/ag-grid/isActiveIconRendererComponent";
import EditRulesDialogComponent from "./edit-rules/editRulesDialogComponent";
import ChildMessageRendererComponent from "../field-management/childMessageRendererComponent";
import LayoutService from "../../shared/services/layoutService";
import LookupService from "../../shared/services/lookupService";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";

class SelectedRulesComponent extends Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      modalShow: false,
      modalAgNode: null,
      selectedEntityId: 0,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchRulesData(this);
  }

  fetchRulesData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
        SelectedRulesService.getBusinessRuleListAsOBS(this.context.user.tenantId,),
        LookupService.getEntityListAsOBS(this.context.user.tenantId)
      ]).subscribe(
        // success
        ([_data, _entityList]) => {
          this.setState(
            {
              data: _data.map(o => { return { ...o, selectedCountryListString: o.selectedCountryList.length === 0 ? "All" : o.selectedCountryList.join() } }),
              entityList: [{ entityid: 0, entityname: "-- All --" }, ..._entityList.filter(x => x.isPorzioEntity === true)],
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  onGridReady = (event) => {
    event.api.closeToolPanel();
    event.api.sizeColumnsToFit();
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  };

  onEntitySelectClick = (event) => {
    this.setState({ selectedEntityId: event.target.value });
    this.gridApi.setFilterModel({
      entityName: {
        filterType: 'text',
        type: 'equals',
        filter: event.target.value === 0 ? null : this.state.entityList.find(x => x.entityid === event.target.value).entityname,
      },
    });
  }

  getFileName() {
    return `Porzio_Business_Rules Entity-${(Object.keys(this.gridApi.getFilterModel()).length > 0 ? this.gridApi.getFilterModel().entityName.filter : `All`)} ${new Date().toDateString()}`;
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
      fileName: this.getFileName()
    };
  }

  methodFromParent = (cell, data) => {
    if (RolePermissionService.BUSINESS_RULE_DETAIL.cannotView) {
      RolePermissionService.showAccessDeniedToast();
    } else {
      this.setState({ modalShow: true });
      this.setState({ modalAgNode: data });
    }
  };

  handleHide() {
    this.setState({ modalShow: false });
  }

  render() {
    const { classes } = this.props;

    if (RolePermissionService.BUSINESS_RULE_LIST.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent classes={classes} label="Loading Porzio Business Rules" />);
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>

              {/* Header Componenet */}
              <PageHeaderComponent label="Porzio Business Rules" back history={this.props.history} classes={classes} noDivider
                entitySelect selectedEntityId={this.state.selectedEntityId} entitySelectData={this.state.entityList}
                onEntitySelectClick={(event) => { this.onEntitySelectClick(event); }}
                download onDownloadClick={this.onDownloadClick} isDownloadReadOnly={RolePermissionService.BUSINESS_RULE_LIST_EXPORT.cannotView}
                reload onReloadClick={() => { this.fetchRulesData(true); }}
              />

              {/* Grid */}
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={SelectedRulesService.getColumnDefs(this)}
                  rowData={this.state.data}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                  onGridReady={(event) => {
                    this.onGridReady(event);
                  }}
                  sideBar={true}
                ></AgGridReact>
                <Dialog open={this.state.modalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
                  <EditRulesDialogComponent onClose={this.handleHide} modalAgNode={this.state.modalAgNode} refreshParentGrid={this.fetchRulesData} />
                </Dialog>
              </div>
            </React.Fragment>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent label="Error Loading Porzio Rules" classes={classes} onRetry={() => { this.fetchRulesData(true); }} />
          );
      }
    }
  }
}
export default LayoutService.getHocComponenet(SelectedRulesComponent);
