import { of, ReplaySubject, combineLatest } from "rxjs";
import { DataService } from "../services/dataService";
import ApiService from "../services/apiService";
import { API_ENDPOINT, TenantSelectList } from "../types/enums";

export default class LookupService {


  /** Step-1/3
   * make sure the lookup observable is mentioned here,
   * this is used to fetch commonly used observables after the user logs in,
   * using the rxJs's combine latest operator
   *  */
  static fetchCommonLookupsOBS = (_context) => {
    return [
      LookupService.getAffiliationsAsOBS(_context),
      LookupService.getCountriesAsOBS(_context),
      LookupService.getFormattedCountriesAsOBS(_context, null),
      LookupService.getLanguagesAsOBS(_context),
      LookupService.getCurrenciesAsOBS(_context),
      LookupService.getFormattedCurrenciesAsOBS(_context, null),
      LookupService.getTimezonesAsOBS(_context),
      LookupService.getPermissionActionsAsOBS(_context.user.tenantId),
      LookupService.getContactTypesAsOBS(_context),
      LookupService.getTransactionStatusesAsOBS(_context),
      LookupService.getProfileStatusesAsOBS(_context),
      LookupService.getAgreementStatusesAsOBS(_context),
      LookupService.getVendorNamesAsOBS(_context),
      LookupService.getProductsAsOBS(_context),
      LookupService.getMaterialItemNamesAsOBS(_context),
      LookupService.getFullUserPreferencesAsOBS(_context),
      LookupService.getPermissionSectionsAsOBS(),
      LookupService.getProfileCategoriesAsOBS(_context)
    ];
  }

  static fetchCommonLookups = (_context) => {
    combineLatest(LookupService.fetchCommonLookupsOBS(_context)).subscribe();
  }

  /** STEP-2/3
   * Clear the cache
   * This function should be called after the user Login/Logout,
   * The reson for clering the cache on user login is the lookups are populated for context.user.tenantId,
   */
  static clearAll() {
    LookupService.clearAFFILIATIONS();
    LookupService.clearCOUNTRIES();
    LookupService.clearCOUNTRIES_FORMATTED();
    LookupService.clearLANGUAGES();
    LookupService.clearCURRENCIES();
    LookupService.clearCURRENCIES_FORMATTED();
    LookupService.clearTIME_ZONES();
    LookupService.clearPERMISSION_ACTIONS();
    LookupService.clearCONTACT_TYPES();
    LookupService.clearTRANSACTION_STATUSES();
    LookupService.clearPROFILE_STATUSES();
    LookupService.clearAGREEMENT_STATUSES();
    LookupService.clearVENDOR_NAMES();
    LookupService.clearPRODUCTS();
    LookupService.clearMATERIAL_ITEM_NAMES();
    LookupService.clearFULL_USER_PREFERENCES_OBJ();
    LookupService.clearPERMISSION_SECTIONS();
    LookupService.clearROLES();
    LookupService.clearREPORT_FILTER_VALUES();
    LookupService.clearPROFILE_CATEGORIES();
  }

  /** STEP 3/3
   * Define the fetch logic below
   */
  // AFFILIATION_DATA
  static _AFFILIATIONS = [];
  static clearAFFILIATIONS() { LookupService._AFFILIATIONS = []; }
  static getAffiliationsAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._AFFILIATIONS)) {
      oSubject.next(LookupService._AFFILIATIONS);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Affiliate/FetchActiveAffiliates/${_context.user.tenantId}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._AFFILIATIONS = _data;
        oSubject.next(LookupService._AFFILIATIONS);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  static reportTemplateSubscriptionId = 1151;

  static allElement = { id: 0, text: "All" };

  static getAffiliationsBySystemAsOBS = (_tenantId, _sourceSystemId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/SourceSystems/GetAffiliations/${_tenantId}/${_sourceSystemId}`
    );
  };
  static getVendorsBySystemAsOBS = (_tenantId, _sourceSystemId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/SourceSystems/GetVendors/${_tenantId}/${_sourceSystemId}`
    );
  };
  static getUsersBySystemAsOBS = (_tenantId, _sourceSystemId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/SourceSystems/GetUsers/${_tenantId}/${_sourceSystemId}`
    );
  };

  // allvendorlist
  static getAllVendorsAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Vendor/GetAllVendors/${_tenantId}`
    );
  };

  // vendorlist
  static getVendorsByAffiliationsAsOBS = (_tenantId, _affiliations) => {
    if (DataService.hasNoElements(_affiliations)) {
      return of([]);
    } else {
      const affiliationIdString = _affiliations.map((o) => o.id).join(",");
      return ApiService.getOBS(
        API_ENDPOINT.CORE,
        `/SourceSystems/GetVendorByCompanyAffiliates/${_tenantId}/${affiliationIdString}`
      );
    }
  };

  // userlist
  static getUsersByVendorsAsOBS = (_tenantId, _affiliations, _vendors) => {
    if (
      DataService.hasNoElements(_affiliations) ||
      DataService.hasNoElements(_vendors)
    ) {
      return of([]);
    } else {
      const affiliationIdString = _affiliations.map((o) => o.id).join(",");
      const vendorIdString = _vendors.map((o) => o.id).join(",");
      return ApiService.getOBS(
        API_ENDPOINT.CORE,
        `/SourceSystems/GetUserByAffiliateVendor/${affiliationIdString}/${vendorIdString}?tenantId=${_tenantId}`
      );
    }
  };

  // sourceSystes
  static getSourceSystemsByEntityAsOBS(_tenantId, _entityId) {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/SourceSystems/GetSourceByEntity/${_tenantId}/${_entityId}`
    );
  }

  // getTemplateBySourceId
  static getTemplateBySourceIdAsOBS(_tenantId, _sourceSystemId, _languageId = 1) {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/SourceSystems/GetTemplateBySourceId?tenantId=${_tenantId}&sourceId=${_sourceSystemId}&languageId=${_languageId}`
    );
  }

  // getProfileTab Details by ProfileId
  static getProfileTabDetailsAsOBS(_tenantId, _profileId) {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/Profiles/GetProfileWithAddrAndContactsSlim?tenantId=${_tenantId}&profileId=${_profileId}`
    );
  }
  // getProfileTab Details by ProfileId
  static getProfileTabDetailsWithErrorInfoAsOBS(_tenantId, _profileId, _source = "") {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/Profiles/GetProfileWithAddrContactsAndErrSlim?tenantId=${_tenantId}&profileId=${_profileId}&source=${_source}`
    );
  }

  // ProfileTab Affiliations
  static getProfileAffiliationsAsOBS(_tenantId, _profileId) {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/Profiles/GetCompanyAffiliates?tenantId=${_tenantId}&profileId=${_profileId}`
    );
  }

  // getAgreementTab Details by AgreementId
  static getAgreementTabDetailsWithErrorInfoAsOBS(_tenantId, _agreementId) {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/Agreement/GetAgreementById/${_tenantId}/${_agreementId}`
    );
  }

  // getTransactionTab Details by ProfileId
  static getTransactionTabDetailsAsOBS(_tenantId, _transactionId, _porzioGSTId = "") {
    if (_porzioGSTId !== "") {
      return ApiService.getOBS(API_ENDPOINT.CORE,
        `/Transactions/GetTransactionDetailsSlim?tenantId=${_tenantId}&transactionId=${_transactionId}&porzioGSTID=${_porzioGSTId}`
      );
    }
    return ApiService.getOBS(API_ENDPOINT.CORE,
      `/Transactions/GetTransactionDetailsSlim?tenantId=${_tenantId}&transactionId=${_transactionId}`
    );
  }

  // _ENTITY_LIST
  static _ENTITY_LIST = [];
  static clearENTITY_LIST() { LookupService._ENTITY_LIST = []; }
  static getEntityListAsOBS = (_tenantId) => {
    var oSubject = new ReplaySubject(); // 1st

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._ENTITY_LIST)) {
      oSubject.next(LookupService._ENTITY_LIST); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.CORE, `/Entity/${_tenantId}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._ENTITY_LIST = _data;
        oSubject.next(LookupService._ENTITY_LIST);
      });
    }

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };


  // _permissionACTIONS
  static _PERMISSION_ACTIONS = [];
  static clearPERMISSION_ACTIONS() { LookupService._PERMISSION_ACTIONS = []; }
  static getPermissionActionsAsOBS = (_tenantId) => {
    var oSubject = new ReplaySubject(); // 1st

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._PERMISSION_ACTIONS)) {
      oSubject.next(LookupService._PERMISSION_ACTIONS); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Permission/FetchPermissionActions?tenantId=${_tenantId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._PERMISSION_ACTIONS = _data;
        oSubject.next(LookupService._PERMISSION_ACTIONS);
      });
    }

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  static getProfileConsentLovAsOBS = (_tenantId) => {
    return ApiService.getOBS(API_ENDPOINT.TENANT, `/Util/GetFieldLOVByIds/${_tenantId}/120`);
  };

  static getConsentAsOBS = (_tenantId, _prId) => {
    return ApiService.getOBS(API_ENDPOINT.CORE, `/Profiles/GetProfileConsents/${_tenantId}/${_prId}?pageNum=1&pageSize=100`);
  };

  static getIdentifierTypes = (_tenantId,) => {
    const targetUrl = `/Entity/FetchEntityLOVData?fieldNames=PorzioGSTIdentifierType&tenantId=${_tenantId}&entityId=1&screenName=PorzioFieldsLOV_1376`;
    return ApiService.getOBS(API_ENDPOINT.CORE, targetUrl);
  };

  // _permissionSECTIONs
  static _PERMISSION_SECTIONS = [];
  static clearPERMISSION_SECTIONS() { LookupService._PERMISSION_SECTIONS = []; }
  static getPermissionSectionsAsOBS = () => {
    var oSubject = new ReplaySubject(); // 1st

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._PERMISSION_SECTIONS)) {
      oSubject.next(LookupService._PERMISSION_SECTIONS); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Permission/FetchSections`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._PERMISSION_SECTIONS = _data;
        oSubject.next(LookupService._PERMISSION_SECTIONS);
      });
    }

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  // _COUNTRIES
  static _COUNTRIES = [];
  static clearCOUNTRIES() { LookupService._COUNTRIES = []; }
  static getCountriesAsOBS = (_context) => {
    var oSubject = new ReplaySubject(); // 1st
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._COUNTRIES)) {
      oSubject.next(LookupService._COUNTRIES); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.COUNTRY}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        
        LookupService._COUNTRIES = DataService.formatCountryList(_data);
        oSubject.next(LookupService._COUNTRIES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  // _COUNTRIES_FORMATTED
  static _COUNTRIES_FORMATTED = [];
  static clearCOUNTRIES_FORMATTED() { LookupService._COUNTRIES_FORMATTED = []; }
  static getFormattedCountriesAsOBS = (_context, _allId = 0) => {
    var oSubject = new ReplaySubject(); // 1st
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._COUNTRIES_FORMATTED)) {
      oSubject.next(LookupService._COUNTRIES_FORMATTED); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.COUNTRY}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        // LookupService._COUNTRIES_FORMATTED = _data;
        LookupService._COUNTRIES_FORMATTED = DataService.formatCountryList(_data, _allId);
        oSubject.next(LookupService._COUNTRIES_FORMATTED);
      });
    }
    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  // CONTACT_TYPES
  static _CONTACT_TYPES = [];
  static clearCONTACT_TYPES() { LookupService._CONTACT_TYPES = []; }
  static getContactTypesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._CONTACT_TYPES)) {
      oSubject.next(LookupService._CONTACT_TYPES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Util/GetFieldLOVByIds/${_context.user.tenantId}/${TenantSelectList.CONTACT_TYPE}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._CONTACT_TYPES = _data;
        oSubject.next(LookupService._CONTACT_TYPES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // TRANSACTION_STATUS
  static _TRANSACTION_STATUSES = [];
  static clearTRANSACTION_STATUSES() { LookupService._TRANSACTION_STATUSES = []; }
  static getTransactionStatusesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // TODO: after api is implemented remove the hardcoded value
    LookupService._TRANSACTION_STATUSES = [
      { "id": 1, "value": "Draft", "ordinal": 1 },
      { "id": 2, "value": "Remediation Required", "ordinal": 2 },
      { "id": 3, "value": "Approved", "ordinal": 3 },
      { "id": 4, "value": "Rejected", "ordinal": 4 },
      { "id": 5, "value": "Eligible", "ordinal": 5 },
      { "id": 6, "value": "Ineligible", "ordinal": 6 }
    ];

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._TRANSACTION_STATUSES)) {
      oSubject.next(LookupService._TRANSACTION_STATUSES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.TRANSACTION_STATUS}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._TRANSACTION_STATUSES = _data;
        oSubject.next(LookupService._TRANSACTION_STATUSES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // PROFILE_STATUS
  static _PROFILE_STATUSES = [];
  static clearPROFILE_STATUSES() { LookupService._PROFILE_STATUSES = []; }
  static getProfileStatusesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // TODO: after api is implemented remove the hardcoded value
    LookupService._PROFILE_STATUSES = [
      { "id": 1, "value": "Draft", "ordinal": 1 },
      { "id": 2, "value": "Active", "ordinal": 2 },
      { "id": 3, "value": "Not Active", "ordinal": 3 },
      { "id": 4, "value": "Pending Approval", "ordinal": 4 },
      { "id": 5, "value": "Rejected", "ordinal": 5 },
      { "id": 6, "value": "Remediation Required", "ordinal": 6 }
    ];

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._PROFILE_STATUSES)) {
      oSubject.next(LookupService._PROFILE_STATUSES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.PROFILE_STATUS}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._PROFILE_STATUSES = _data;
        oSubject.next(LookupService._PROFILE_STATUSES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // AGREEMENT_STATUS
  static _AGREEMENT_STATUSES = [];
  static clearAGREEMENT_STATUSES() { LookupService._AGREEMENT_STATUSES = []; }
  static getAgreementStatusesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // TODO: after api is implemented remove the hardcoded value
    LookupService._AGREEMENT_STATUSES = [
      { "id": 1, "value": "Draft", "ordinal": 1 },
      { "id": 2, "value": "Active", "ordinal": 2 },
      { "id": 3, "value": "Not Active", "ordinal": 3 },
    ];

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._AGREEMENT_STATUSES)) {
      oSubject.next(LookupService._AGREEMENT_STATUSES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.AGREEMENT_STATUS}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._AGREEMENT_STATUSES = _data;
        oSubject.next(LookupService._AGREEMENT_STATUSES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };


  // _REPORT_IDENTIFIERS
  static _REPORT_IDENTIFIERS = [];
  static clearREPORT_IDENTIFIERS() { LookupService._REPORT_IDENTIFIERS = []; }
  static getReportIdentifiersAsOBS = (_context, _companyAffiliateId) => {
    var oSubject = new ReplaySubject(); // 1st

    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._REPORT_IDENTIFIERS)) {
      oSubject.next(LookupService._REPORT_IDENTIFIERS); // 2nd
    } else {
      //oSubject.next(LookupService._REPORT_IDENTIFIERS); // TODO:implement Below API
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Affiliate/ReportIdentifiersAffiliateById/${_context.user.tenantId}/${_companyAffiliateId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._REPORT_IDENTIFIERS = _data;
        oSubject.next(LookupService._REPORT_IDENTIFIERS);
      });
    }

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  // _LANGUAGES
  static _LANGUAGES = [];
  static clearLANGUAGES() { LookupService._LANGUAGES = []; }
  static getLanguagesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._LANGUAGES)) {
      oSubject.next(LookupService._LANGUAGES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.LANGUAGE}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._LANGUAGES = _data;
        oSubject.next(LookupService._LANGUAGES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _CURRENCIES
  static _CURRENCIES = [];
  static clearCURRENCIES() { LookupService._CURRENCIES = []; }
  static getCurrenciesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._CURRENCIES)) {
      oSubject.next(LookupService._CURRENCIES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.CURRENCY}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._CURRENCIES = _data;
        oSubject.next(LookupService._CURRENCIES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _CURRENCIES_FORMATTED
  static _CURRENCIES_FORMATTED = [];
  static clearCURRENCIES_FORMATTED() { LookupService._CURRENCIES_FORMATTED = []; }
  static getFormattedCurrenciesAsOBS = (_context, _allId = 0) => {
    var oSubject = new ReplaySubject(); // 1st
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._CURRENCIES_FORMATTED)) {
      oSubject.next(LookupService._CURRENCIES_FORMATTED); // 2nd
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.CURRENCY}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._CURRENCIES_FORMATTED = DataService.formatCurrencyList(_data, _allId);
        oSubject.next(LookupService._CURRENCIES_FORMATTED);
      });
    }
    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  // _TIMEZONES
  static _TIMEZONES = [];
  static clearTIME_ZONES() { LookupService._TIMEZONES = []; }
  static getTimezonesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    if (DataService.hasElements(LookupService._TIMEZONES)) {
      oSubject.next(LookupService._TIMEZONES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetMasterSubSet/${_context.user.tenantId}/${TenantSelectList.TIME_ZONE}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._TIMEZONES = _data;
        oSubject.next(LookupService._TIMEZONES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _SOURCE_TYPES
  static _SOURCE_TYPES = [];
  static clearSOURCE_TYPES() { LookupService._SOURCE_TYPES = []; }
  static getSourceTypesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    if (DataService.hasElements(LookupService._SOURCE_TYPES)) {
      oSubject.next(LookupService._SOURCE_TYPES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.CORE, `/Entity/${_context.user.tenantId}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._SOURCE_TYPES = _data;
        oSubject.next(LookupService._SOURCE_TYPES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };


  // _PRODUCTS
  static _PRODUCTS = [];
  static clearPRODUCTS() { LookupService._PRODUCTS = []; }
  static getProductsAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    if (DataService.hasElements(LookupService._PRODUCTS)) {
      oSubject.next(LookupService._PRODUCTS);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Products/${_context.user.tenantId}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._PRODUCTS = _data;
        oSubject.next(LookupService._PRODUCTS);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _VENDOR_NAMES
  static _VENDOR_NAMES = [];
  static clearVENDOR_NAMES() { LookupService._VENDOR_NAMES = []; }
  static getVendorNamesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    if (DataService.hasElements(LookupService._VENDOR_NAMES)) {
      oSubject.next(LookupService._VENDOR_NAMES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Vendor/GetAllVendors/${_context.user.tenantId}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._VENDOR_NAMES = _data;
        oSubject.next(LookupService._VENDOR_NAMES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _MATERIAL_ITEM_NAMES
  static _MATERIAL_ITEM_NAMES = [];
  static clearMATERIAL_ITEM_NAMES() { LookupService._MATERIAL_ITEM_NAMES = []; }
  static getMaterialItemNamesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    if (DataService.hasElements(LookupService._MATERIAL_ITEM_NAMES)) {
      oSubject.next(LookupService._MATERIAL_ITEM_NAMES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/Materials/GetAllMaterials/${_context.user.tenantId}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._MATERIAL_ITEM_NAMES = _data;
        oSubject.next(LookupService._MATERIAL_ITEM_NAMES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // _FULL_USER_PREFERENCES_OBJ
  static _FULL_USER_PREFERENCES_OBJ = {};
  static clearFULL_USER_PREFERENCES_OBJ() { LookupService._FULL_USER_PREFERENCES_OBJ = {}; }
  static getFullUserPreferencesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    if (DataService.hasElements(LookupService._FULL_USER_PREFERENCES_OBJ)) {
      oSubject.next(LookupService._FULL_USER_PREFERENCES_OBJ);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(API_ENDPOINT.TENANT, `/User/GetFullUser?tenantId=${_context.user.tenantId}&userid=${_context.user.userId}`).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._FULL_USER_PREFERENCES_OBJ = _data;
        oSubject.next(LookupService._FULL_USER_PREFERENCES_OBJ);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  // UTILS
  static getValuesByKey(_array, _key = "id") {
    let oRET = [];
    _array.forEach((obj) => {
      oRET.push(obj[_key]);
    });
    return oRET;
  }

  //Roles
  static _ROLES = [];
  static clearROLES() { LookupService._ROLES = []; }
  static getRolesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._ROLES)) {
      oSubject.next(LookupService._ROLES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Role/GetAllRoles/${_context.user.tenantId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._ROLES = _data;
        oSubject.next(LookupService._ROLES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  //TransactionTypes
  static _TRANSACTIONTYPES = [];
  static getTransactionTypesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._TRANSACTIONTYPES)) {
      oSubject.next(LookupService._TRANSACTIONTYPES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Util/GetFieldLOVByIds/${_context.user.tenantId}/25`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._TRANSACTIONTYPES = _data;
        oSubject.next(LookupService._TRANSACTIONTYPES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  //NewsfeedTypes
  static _NEWSFEEDTYPES = [];
  static getNewsfeedTypesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._NEWSFEEDTYPES)) {
      oSubject.next(LookupService._NEWSFEEDTYPES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.TENANT,
        `/Newsfeed/FetchNewsFeedTypes/${_context.user.tenantId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._NEWSFEEDTYPES = _data;
        oSubject.next(LookupService._NEWSFEEDTYPES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  //NewsfeedTypes
  static _COMPANIES = [];
  static getUserTenantsAsOBS = (_context) => {
    var oSubject = new ReplaySubject();
    if (_context.user.tenantId !== 1) {
      oSubject.next(LookupService._COMPANIES);
      return oSubject.asObservable();
    }
    // if local_cache is not Empty
    if (DataService.hasElements(LookupService._COMPANIES)) {
      oSubject.next(LookupService._COMPANIES);
    } else {
      // fetch and subscribe to the result
      ApiService.getOBS(
        API_ENDPOINT.IDENTITY,
        `/User/GetUserTenants?userId=${_context.user.userId}`
      ).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._COMPANIES = _data;
        oSubject.next(LookupService._COMPANIES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };


  static _ACTIVE_TENANTS = [];
  static getActiveTenantsAsObs = (_includePorzio) => {
    var oSubject = new ReplaySubject();
    const targetUrl = `/GetActiveTenants/${_includePorzio}`;
    ApiService.getOBS(
      API_ENDPOINT.TENANT,
      targetUrl,
    ).subscribe((_data) => {
      LookupService._ACTIVE_TENANTS = _data;
      oSubject.next(LookupService._ACTIVE_TENANTS);
    });
    return oSubject.asObservable();
  }

  // _PROFILE_CATEGORIES
  static _PROFILE_CATEGORIES = [];
  static clearPROFILE_CATEGORIES() { LookupService._PROFILE_CATEGORIES = []; }
  static getProfileCategoriesAsOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // if local_cache is Empty
    if (DataService.hasElements(LookupService._PROFILE_CATEGORIES)) {
      oSubject.next(LookupService._PROFILE_CATEGORIES);
    } else {
      // fetch and subscribe to the result
      LookupService.getFieldLOVByIdsAsOBS(_context.user.tenantId, 6).subscribe((_data) => {
        // store it in the local cache, then trigger the subscription at the client side
        LookupService._PROFILE_CATEGORIES = _data;
        oSubject.next(LookupService._PROFILE_CATEGORIES);
      });
    }
    // return the subject as observable
    return oSubject.asObservable();
  };

  static getFieldLOVByIdsAsOBS = (_tenantId, _fieldId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetFieldLOVByIds/${_tenantId}/${_fieldId}`
    );
  };

  static getUserCurrencyAsOBS = (_userId) => {
    return ApiService.getOBS(API_ENDPOINT.CORE, `/Transactions/GetUserCurrency?userId=${_userId}`);
  };

  static fetchCurrenciesAsOBS = (_userId) => {
    return ApiService.getOBS(API_ENDPOINT.CORE, `/Transactions/FetchCurrencies`);
  };

  static getConvertedTotalAmountAsOBS = (_tenantId, _sourceCurrency, _targetCurrency, _totalAmount) => {
    const _exchangeDate = new Date().toISOString().substring(0, 10)
    return ApiService.getOBS(API_ENDPOINT.CORE, `/Transactions/GetConvertedTotalAmount?tenantId=${_tenantId}&sourceCurrency=${_sourceCurrency}&targetCurrency=${_targetCurrency}&totalAmount=${_totalAmount}&exchangeDate=${_exchangeDate}`);
  };

  static STATUSES = [
    { id: 208, seq: 1, name: "Complete" },
    { id: 209, seq: 2, name: "Deferred" },
    { id: 207, seq: 3, name: "Draft" },
    { id: 210, seq: 4, name: "In Progress" },
    { id: 211, seq: 5, name: "Not Started" },
    { id: 212, seq: 6, name: "Reassigned" },
  ];

  static EVENTTYPES = [{ id: 365, seq: 1, name: "Company Announcement" }];

  static COMPLIANCE_CONSENT_REPORTABLE = [
    { reportableId: 250, reportableName: "Reportable - Direct" },
    { reportableId: 251, reportableName: "Reportable - Aggregate" },
  ];

  static REPORTS_TO_BE_REMOVED_CONSENT = ["Australia Third Party Meetings and Symposia Report", "Belgium Report", "Denmark Report", "Japan Report",
    "Netherlands Report", "Portugal Report", "Slovakia Report", "USFederal General Payments Report", "USFederal Ownership Report",
    "USFederal Research Report", "USState Massachusetts Report"];


  // REPORT FILTER VALUES
  static _REPORT_FILTER_VALUES = {};
  static clearREPORT_FILTER_VALUES() { LookupService._REPORT_FILTER_VALUES = {}; }
  static getReportFiltersValuesAsOBS = (_context) => {
    let oSubject = new ReplaySubject(); // 1st
    // fetch and subscribe to the result
    ApiService.getOBS(
      API_ENDPOINT.REPORTS,
      `/Reports/FetchIzendaFilters?tenantId=${_context.user.tenantId}`
    ).subscribe((_data) => {
      // store it in the local cache, then trigger the subscription at the client side
      LookupService._REPORT_FILTER_VALUES = _data;
      oSubject.next(LookupService._REPORT_FILTER_VALUES);
    });
    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };



  //---
}