import React from "react";
import { ResultStatus, CrudAction } from "../../../../shared/types/enums";
import {
  withStyles,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Formik } from "formik";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../../shared/services/layoutService";
import {
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { AuthContext } from "../../../../shared/store/authProvider";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import ToastService from "../../../../shared/services/toastService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class EditRoleModalComponent extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    // bind
    this.onValueChange = this.onValueChange.bind(this);
    this.handleDeleteSubmit = this.handleDeleteSubmit.bind(this);

    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      data: [],
      fetchResult: ResultStatus.SUCCESS,
      selectedTabIndex: 0,
      isActive: this.props.modalAgNode.isActive,
      isPlsRequired: this.props.modalAgNode.isPlsRequired,
    };
  }

  onValueChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  getInitialValues = () => {
    const obj = {
      roleType: "Customer",
      tenantId: this.context.user.tenantId,
      isPlsRequired: this.props.modalAgNode.isPlsRequired,
      roleName: this.props.modalAgNode.roleName,
      roleDescription: this.props.modalAgNode.roleDescription,
      isActive: this.props.modalAgNode.isActive,
      effectiveDate: this.props.modalAgNode.effectiveDate,
    };
    return obj;
  };

  validationSchema = Yup.object().shape({});
  getValidationSchema = () => {
    this.validationSchema = Yup.object({
      //roleType: Yup.string().required("Required"),
      roleName: Yup.string()
        .min(3, "Must be atleast 3 characters long")
        .max(256, "Must be 256 characters or less")
        .required("Required")
        .test(
          "checkDuplicateRoleName",
          "Role Name already exists",
          async (_formikValue) => {
            try {
              const result = await fetch(
                `${process.env.REACT_APP_TENANT_HTTP}/Role/GetAllRoles/${this.context.user.tenantId}`
              );
              const roleList = await result.json();
              const roleListWithoutCurrentRole = roleList.filter(
                (role) =>
                  role.roleName.toLowerCase() !==
                  this.props.modalAgNode.roleName.toLowerCase()
              );
              const role = roleListWithoutCurrentRole.find(
                (role) =>
                  role.roleName.toLowerCase() === _formikValue.toLowerCase()
              );
              if (role) {
                return false;
              }
              return true;
            } catch (error) {
              console.log("Role Exists Error:", error);
              this.setState({
                fetchResult: ResultStatus.ERROR,
              });
            }
          }
        ),
      roleDescription: Yup.string().required("Required"),
      effectiveDate: Yup.date()
        .min("01/01/2019", "On or after 01/01/2019")
        .max("01/01/2051", "On or before 01/01/2051")
        .required("Required")
        .nullable(),
    });
    return this.validationSchema;
  };

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting) {
      await this.validationSchema
        .validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          this.fetchRoleUpdatePut(_formikProps);
          this.setState({ fetchResult: ResultStatus.LOADING });
          _formikProps.setSubmitting(true);
          setTimeout(() => {
            this.setState({ fetchResult: ResultStatus.SUCCESS });
            _formikProps.setSubmitting(false);
          }, 3000);
        })
        .catch((erroObj) => {
          erroObj.inner &&
            erroObj.inner.forEach((err) => {
              _formikProps.setFieldError(err.path, err.message);
            });
        });
    }
  };

  handleDeleteSubmit() {
    this.setState({ fetchResult: ResultStatus.LOADING });
    try {
      const roleId = this.props.modalAgNode.roleId;
      const url = `${process.env.REACT_APP_TENANT_HTTP}/Role/EditRole/${this.context.user.tenantId}/${roleId}`;
      const result = fetch(
        `${process.env.REACT_APP_TENANT_HTTP}/Role/EditRole/${this.context.user.tenantId}/${roleId}`,
        {
          method: "DELETE",
          headers: { accept: "*/*" },
        }
      );
      this.setState({ fetchResult: ResultStatus.SUCCESS });
      this.props.onClose(false);
      this.props.refreshRolesList(true);
    } catch (error) {
      ToastService.showError("Error while creating Role Post.");
      this.setState({ fetchResult: ResultStatus.ERROR });
    }
  }

  fetchRoleUpdatePut = async (_formikProps) => {
    this.setState({ fetchResult: ResultStatus.SAVING });
    try {
      const roleObj = JSON.stringify({
        roleId: this.props.modalAgNode.roleId,
        tenantId: _formikProps.values.tenantId,
        roleName: _formikProps.values.roleName,
        roleDescription: _formikProps.values.roleDescription,
        roleType: "Customer",
        isPlsRequired: this.state.isPlsRequired,
        isActive: this.state.isActive,
        effectiveDate: "2022-01-01",
      });
      const result = await fetch(
        `${process.env.REACT_APP_TENANT_HTTP}/Role/EditRole/${this.context.user.tenantId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: roleObj,
        }
      );
      if ((result.status === 201) | (result.status === 204)) {
        this.setState({ fetchResult: ResultStatus.SUCCESS });
        this.props.refreshRolesList(true);
        this.props.onClose(false);
      } else {
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    } catch (error) {
      this.setState({ fetchResult: ResultStatus.ERROR });
    }
  };
  /**Render */
  render() {
    const { classes } = this.props;
    if (RolePermissionService.ROLE_DETAIL.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => {
        this.props.onClose(false);
      });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Role Details"
            />
          );
        case ResultStatus.SAVING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Saving Role Details"
            />
          );
        case ResultStatus.SUCCESS:
          return (
            <Formik
              initialValues={this.getInitialValues()}
              validationSchema={this.getValidationSchema()}
            >
              {(fProps) => (
                <form>
                  {/* Dialog Title */}
                  <DialogTitle disableTypography id="dialogTitle">
                    <AppBar position="static">
                      <Toolbar variant="dense">
                        <Typography variant="h6" className={classes.root}>
                          Role Details
                        </Typography>
                        {RolePermissionService.ROLE_DETAIL.cannotEdit ? (
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              this.props.onClose(false);
                            }}
                          >
                            {MatIconService.CANCEL}
                          </IconButton>
                        ) : (
                          LayoutService.getReadOnlyActions(
                            this,
                            !this.state.isEditing,
                            () => {
                              this.props.onClose(false);
                            },
                            () => {
                              this.handleSubmit(fProps);
                            }
                          )
                        )}
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  {/* Dialog Content */}
                  <DialogContent>
                    <Box
                      style={{
                        paddingLeft: 16,
                        paddingRight: 32,
                        paddingTop: 16,
                        paddingBottom: 32,
                        minWidth: "40vh",
                        maxWidth: "60vh",
                      }}
                    >
                      <TextField
                        {...LayoutService.getInputProps(
                          this.state.isReadOnly,
                          classes,
                          fProps,
                          "roleName",
                          "Role Name"
                        )}
                        fullWidth
                      />
                      <TextField
                        {...LayoutService.getInputProps(
                          this.state.isReadOnly,
                          classes,
                          fProps,
                          "roleDescription",
                          "Role Description"
                        )}
                        fullWidth
                      />

                      {/* {RolePermissionService.ROLE_DETAIL_TYPE_CUSTOMER
                        .cannotView ? null : (
                        <>
                          <InputLabel
                            {...LayoutService.getInputProps(
                              this.state.isReadOnly,
                              classes,
                              fProps,
                              "roleTypeLabel",
                              "Role Type"
                            )}
                            fullWidth
                          >
                            { Role Type }
                          </InputLabel>
                          <Select
                            disabled={
                              this.state.isReadOnly ||
                              RolePermissionService.ROLE_DETAIL_TYPE_CUSTOMER
                                .cannotEdit
                            }
                            value={fProps.values["roleType"]}
                            className="form-control"
                            fullWidth
                          >
                            <option value={"Customer"}>Customer</option>
                          </Select>
                        </>
                      )} */}

                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          {...LayoutService.getDateProps(
                            this.state.isReadOnly,
                            classes,
                            fProps,
                            "effectiveDate",
                            "Effective Date",
                            true
                          )}
                          format="yyyy-MM-dd"
                          fullWidth
                        />
                      </MuiPickersUtilsProvider> */}

                      {/* {RolePermissionService.ROLE_DETAIL_PORZIO_REQUIRED
                        .cannotView ? null : (
                        <FormControl
                          disabled={
                            this.state.isReadOnly ||
                            RolePermissionService.ROLE_DETAIL_PORZIO_REQUIRED
                              .cannotEdit
                          }
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.isPlsRequired}
                                onChange={this.onValueChange}
                                name="isPlsRequired"
                                color="secondary"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            }
                            label="PLS Required"
                          />
                        </FormControl>
                      )} */}
                      <FormControl disabled={this.state.isReadOnly}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.isActive}
                              onChange={this.onValueChange}
                              name="isActive"
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                          }
                          label="Active"
                        />
                      </FormControl>
                    </Box>
                  </DialogContent>
                </form>
              )}
            </Formik>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading Role Details"
              classes={classes}
              onRetry={() => {
                this.fetchData(this, true);
              }}
            />
          );
      }
    }
  }

  //---
}
/** HOC */
export default withStyles(MatClassService)(EditRoleModalComponent);
