import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import { MatIconService } from "../../shared/services/theme/matIconService";
import ApiService from "../../shared/services/apiService";
import Cache from "../../shared/store/cache";
import { ENTITY_TYPE, GST_API } from "../../shared/types/enums";
import ToastService from "../../shared/services/toastService";
import { DataService } from "../../shared/services/dataService";

export default class dateCenterService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    let url = `/DataCenter/FileHistory/${clientAuthContext.user.tenantId}/${clientAuthContext.user.userId}?pageNo=1&pageSize=5000`;
    return ApiService.fetchHTTP_API(GST_API.Core, url);
  }

  static getRows() {
    return [];
  }

  static getActionMenuParams = (_componentThisRef) => {
    return {
      menuIcon: MatIconService.MORE_VERT_16,
      menuMaxHeight: 60,

      menuOptions: [
        {
          text: "Add/Edit a File Comment", icon: MatIconService.FILE_COMMENT, onClickCallback: (_cellRef) => {
            _componentThisRef.handleAddEditCommentFile(_cellRef);
          }
        },
        // {
        //   text: "Re-run Matching", icon: MatIconService.RERUN_MATCHING, onClickCallback: (_cellRef) => {
        //     ToastService.showInfo("Under Construction");
        //   }
        // },
        // {
        //   text: "Run Health Reports", icon: MatIconService.RUN_REPORTS, onClickCallback: (_cellRef) => {
        //     ToastService.showInfo("Under Construction");
        //   }
        // },
      ]
    }
  }

  static getColumnDefs(_componentThisRef) {
    return [
      AgGridColumnExt.GET(false, null, null, "").fixedWidth(48).headerClass("center-text").cellRenderer("actionCellRendererComponent", this.getActionMenuParams(_componentThisRef)).SET(),
      AgGridColumnExt.GET(false, "text", "dates", "Processed Date").comparator(DataService.dateComparator).fixedWidth(160).headerClass("center-text").tooltip().centerAlignText().SET(),
      AgGridColumnExt.GET(false, "set", "sourceName", "Source").flexWeight(1).minWidth(160).headerClass("center-text").tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "fileName", "File Name").flexWeight(6).minWidth(162).cellRenderer("downloadFileAgRenderer").tooltip().SET(),
      AgGridColumnExt.GET(false, "set", "url", "File Status").fixedWidth(96).headerClass("center-text").cellRenderer("dataCenterFileStatusImageRenderer").SET(),
      AgGridColumnExt.GET(false, "number", "totalCount", "Total Rows").fixedWidth(96).headerClass("center-text").centerAlignText().cellRenderer("dataCountCellRendererComponent", {
        isCellEnabledCallback: (_cellRef) => {
          return _cellRef.props.data.sourceTypeId != ENTITY_TYPE.SALES_REP;
        }
      }).SET(),
      AgGridColumnExt.GET(false, "number", "errorCount", "Errors").fixedWidth(96).headerClass("center-text").cellRenderer("dataErrorCellRendererComponent", {
        isCellEnabledCallback: (_cellRef) => {
          return _cellRef.props.data.sourceTypeId != ENTITY_TYPE.SALES_REP;
        }
      }).SET(),
      AgGridColumnExt.GET(false, "number", "warningCount", "Warnings").fixedWidth(112).headerClass("center-text").cellRenderer("dataWarningCellRendererComponent", {
        isCellEnabledCallback: (_cellRef) => {
          return _cellRef.props.data.sourceTypeId != ENTITY_TYPE.SALES_REP;
        }
      }).SET(),
      AgGridColumnExt.GET(false, "number", "unmatchedCount", "Unmatched").fixedWidth(136).headerClass("center-text").cellRenderer("dataUnmatchedCellRendererComponent", {
        isCellEnabledCallback: (_cellRef) => {
          return _cellRef.props.data.sourceTypeId != ENTITY_TYPE.SALES_REP;
        }
      }).SET(),
      AgGridColumnExt.GET(false, "text", "lastLogin", "Import Summary").fixedWidth(128).headerClass("center-text").cellRenderer("dataCenterImportSummaryRenderer").SET(),
    ];
  }
}
