import ApiService from "../../shared/services/apiService";
import { DataService } from "../../shared/services/dataService";
import { API_ENDPOINT } from "../../shared/types/enums";

export default class StudiesListService {

  static getAllStudiesAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Study/GetAllStudies/${_tenantId}`
    );
  };
  

  static getNonEntityLOVsByIDAsOBS = (_tenantId, _lovEntityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetNonEntityLOVsByID/${_tenantId}/${_lovEntityId}`
    );
  };

  static getRows() {
    return [];
  }

  static dateFormatter(params) {
    return new Date(params.value).toLocaleDateString();
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Study Id",
        field: "companyStudyId",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        cellRenderer: "childMessageRendererComponent",
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Study Name",
        field: "studyName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Info Link",
        field: "infoLink",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Delay Indicator",
        field: "delayIndicatorName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Research Type",
        field: "researchType",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Start Date",
        field: "startDate",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        valueFormatter: (params) => {
          return DataService.formatDateString(params.data.startDate)
        }
      },
      {
        headerName: "FDA Approval Date",
        field: "approvalDate",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        valueFormatter: (params) => {
          return DataService.formatDateString(params.data.approvalDate)
        }
      },
      {
        headerName: "Early Termination Date",
        field: "researchDate",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        valueFormatter: (params) => {
          return DataService.formatDateString(params.data.researchDate)
        }
      },
      {
        headerName: "Clinical Trial Id",
        field: "clinicalTrailId",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Comments",
        field: "comments",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Active?",
        field: "isActive",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        cellRenderer: "isActiveIconRendererComponent",
      },
    ];
  }
}
