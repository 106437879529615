import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";
import { GST_API } from "../../../shared/types/enums";

export default class EventCalendarStore {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext, date) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext, date);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  // static fetchFromApiObsOld(clientAuthContext) {
  //   // clear the cache before returning the actual subscription
  //   this.CACHE.CLEAR();
  //   // replace this._jsonData with reacl ApiCall
  //   return ApiService.fetchObs(`/Calendar/GetCalendarToolTipDetails?tenantId=${clientAuthContext.user.tenantId}&eventVisiblityId=${clientAuthContext.user.tenantId}&id=82`);
  // }

  static fetchFromApiObs(clientAuthContext, oDate) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    return ApiService.fetchHTTP_API(GST_API.TenantConfig, `/Calendar/GetCalendar/${clientAuthContext.user.tenantId}?date=${oDate.toISOString().substring(0, 10)}`);
  }
}
