import config from '../../config';
import OktaSignIn from '@okta/okta-signin-widget';
import ApplicationService from '../../shared/services/applicationService';
import porzio_gst_icon from "../../public/images/porzio_global_spend_transparency_logo_small.png";

export default class OktaAuthService {

    static authWidget = {};

    static INIT = () => {
        const { issuer, clientId, redirectUri, scopes } = config.oidc;

        OktaAuthService.authWidget = new OktaSignIn({
            baseUrl: issuer.split('/oauth2')[0],
            clientId: clientId,
            redirectUri: redirectUri,
            logo: porzio_gst_icon,
            authParams: { issuer, scopes },
            features: { idpDiscovery: true },
            idpDiscovery: { requestContext: ApplicationService.OktaRequestContext },
            i18n: { en: { 'primaryauth.title': `Sign in to ${ApplicationService.applicationName} - ${ApplicationService.appGstVersionString}`, }, }
        });

        /** subscription required by okta for not throwing warnings in the console */
        OktaAuthService.authWidget.authClient.authStateManager.subscribe((resultObj) => {
            // console.log("authstate changed", resultObj);
        });

    }

    static checkSingleSignOn = (onSuccessCallback, onErrorCallback) => {
        OktaAuthService.authWidget.authClient.token.getWithoutPrompt({
            responseType: 'id_token', // or array of types
        })
            .then((successObj) => {
                // ToastService.showSuccess('Session Exists.');
                OktaAuthService.authWidget.authClient.tokenManager.setTokens(successObj.tokens);
                onSuccessCallback(successObj);
            })
            .catch((errorObj) => {
                console.log(errorObj);
                // ToastService.showError('Session Not Exists.');
                onErrorCallback(errorObj);
            });
    }


    static showSignInWidget = (_renderEl, onSuccessCallback, onErrorCallback) => {
        OktaAuthService.authWidget.renderEl({ el: _renderEl },
            (successObj) => {
                onSuccessCallback(successObj);
            },
            (errorObj) => {
                console.log(errorObj);
                onErrorCallback(errorObj);
            }
        );
    }

    //---
}