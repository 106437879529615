import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { API_ENDPOINT } from "../../../../shared/types/enums";

export default class ComplianceReportDetailsDialogService {

    static getEntityListAsOBS = (_tenantId) => {
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/FetchEntitiesForDefaultMapping/${_tenantId}`
        );
    };

    static getYearsByReportId = (_tenantId, _reportId) => {
        let $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}`
        if (_reportId !== undefined) {
            $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}?reportId=${_reportId}`
        }
        return ApiService.getOBS(API_ENDPOINT.TENANT, $url);
    };

    static getComplianceEntityFieldsAsOBS = (_tenantId, _entityId) => {
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/FetchEntityFields/${_tenantId}/${_entityId}?entityId=${_entityId}`
        );
    };

    static getReportMappings = (_tenantId, _reportId, _yearId, _fieldId) => {
        return ApiService.postOBS(
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/FetchReportMappingsListByReportId/${_tenantId}`,
            JSON.stringify({
                pageNumber: 1,
                pageSize: 5000,
                searchText: "",
                entityId: "",
                fieldId: String(_fieldId),
                reportId: String(_reportId),//This should be the report path ID
                year: String(_yearId),
            }),
        );
    };

    static getReportNameDropdownAsOBS = (_tenantId) => {
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/FetchReports/${_tenantId}`
        );
    };

    static getReportValueDropdownAsOBS = (_tenantId, _fieldId, _reportPathId) => {
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            `/ComplianceMapping/FetchReportValueList/${_tenantId}/${_fieldId}/${_reportPathId}`
        );
    };

    static setReportsList = (_componentThisRef, _reportsList) => {
        let tempData = [];
        let visited = [];
        _reportsList.forEach(x => {
            if (_componentThisRef.state.selectedReportId === x["reportId"]) {
                if (tempData.find(t => t["reportName"] === x["reportName"])) {
                    //tempData.find(t => t["reportName"] === x["reportName"]).reportId = _componentThisRef.state.selectedReportId;
                    tempData = tempData.filter(t => t.reportName !== x.reportName);
                    tempData.push(x);
                    visited.push(x["reportName"]);
                }
                else {
                    tempData.push(x);
                    visited.push(x["reportName"]);
                }
            }
            else if (!visited.includes(x["reportName"])) {
                visited.push(x["reportName"]);
                tempData.push(x);
            }
        })
        return tempData;
    }


    static getColumnDefs(_componentThisRef) {
        return [
            AgGridColumnExt.GET(false, "text", "customerFieldValue", "Field Value").flexWeight(3).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "porzioGSTFieldValue", "Porzio GST Field Value").flexWeight(3).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "reportMappingId", "Report Value").comparator(_componentThisRef.reportValueComparator).cellRenderer("dropDownRenderer", {
                options: _componentThisRef.state.reportValueDropdownList, optionKey: "reportMappingId", optionLabel: "reportMappingName",
                statusIcon: MatIconService.REPORT,
                showStatusCallBack: (_cellRef) => {
                    if (_cellRef.props.data["reportMappingId"] !== 0 && _cellRef.props.data["isPZMapping"] !== undefined) { return !_cellRef.props.data["isPZMapping"]; }
                    else { return false; }
                },
                showEmpty: true, emptyValue: 0,
                onChangeCallback: (_cellRef, params) => {
                    _cellRef.props.data.isMappingChanged = true;
                    _componentThisRef.handleReportValueChanged(_cellRef, params);
                },
            }).SET(),
            AgGridColumnExt.GET(false, "text", "reportable", "Reportable").flexWeight(3).tooltip().SET(),
        ];
    }
}
