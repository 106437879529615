import React, { Component } from "react";
import clsx from "clsx";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MatSpecService from "../../shared/services/theme/matSpecService";
import TopBarComponent from "./topBarComponent";
import SideNavComponent from "./sideNavComponent";
import FooterComponent from "./footerComponent";
import HomeComponent from "../home/homeComponent";
import DataCenterComponent from "../data-center/dataCenterComponent";
import ProfileCenterComponent from "../profile-center/profileCenterComponent";
import TenantconfigurationComponent from "../tenant/tenantConfigurationComponent";
import SourceComponent from "../source/sourceComponent";
import UnderContructionComponent from "../../public/under-construction/UnderContructionComponent";
import UserListComponent from "../user-management/users/userListComponent";
import RoleManagementComponent from "../user-management/roles/roleManagementComponent";
import TransactionCenterComponent from "../transaction-center/transactionCenterComponent";
import ProductMasterComponent from "../product-master/productMasterComponent";
import PorzioFieldsListComponent from "../field-management/porzio-fields/porzioFieldsListComponent";
import CustomFieldsListComponent from "../field-management/custom-fields/customFieldsListComponent";
import CompanyAffiliateListComponent from "../tenant-profile-setup/affiliate-companies/affiliateCompaniesListComponent";
import VendorSetupComponent from "../tenant-profile-setup/vendor-setup/vendorSetupComponent";
import ComplianceDefaultMappingComponent from "../tenant-profile-setup/compliance-mapping/complianceDefaultMappingComponent";
import ComplianceReportMappingComponent from "../tenant-profile-setup/compliance-mapping/complianceReportMappingComponent";
import ComplianceConsentMappingComponent from "../tenant-profile-setup/compliance-consent-mapping/complianceConsentMappingComponent";
import ReportsCenterComponent from "../reports-center/reportsCenterComponent";
import AgreementListComponent from "../agreements/agreementListComponent";
import SelectedRulesComponent from "../porzio-rules/selectedRulesComponent";
import SalesRepComponent from "../tenant-profile-setup/sales-rep/salesRepComponent";
import MaterialsListComponent from "../materials/materialsListComponent";
import StudiesListComponent from "../study-master/studiesListComponent";
import DocumentComponent from "../documents/documentComponent";
import ContactUsComponent from "../../public/contact-us/contactUsComponent";
import RolePermissionsComponent from "../user-management/roles/permissions/rolePermissionsComponent";
import WorkflowTemplateListComponent from "../workflow-template/workflowTemplateListComponent";
import ReportingHome from "../reporting/reportingHome";
import ReportingViewer from "../reporting/reportingViewer";
import ReportingExport from "../reporting/reportingExport";
import PowerBIDemoContainerComponent from "../power-bi/powerBIDemoContainerComponent"

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import ToastService from "../../shared/services/toastService";

import TenantReportTemplateSubscriptionComponent from "../tenant-profile-setup/tenant-report-template-subscriptions/tenantReportTemplateSubscriptionComponent";
import ProfileExpandedViewComponent from "../profile-center/expanded-view/profileExpandedViewComponent";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import { ResultStatus } from "../../shared/types/enums";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../shared/components/page/dialogErrorFragmentComponent";
import { AuthContext } from "../../shared/store/authProvider";
import SessionService from "../../shared/services/sessionService";
import TenantStatusComponent from "../tenant-creation/tenantStatusComponent";
import UserMappingsComponent from "../user-mappings/userMappingsComponent";
import { Typography, Grid, Box, Tooltip, Popper, Paper, Dialog, Button, DialogContent, DialogContentText, Toolbar, AppBar, DialogTitle } from "@material-ui/core";
import LayoutService from "../../shared/services/layoutService";
import { MatIconService } from "../../shared/services/theme/matIconService";
import UserActivityService from "../../shared/services/userActivityService";
import { withOktaAuth } from '@okta/okta-react';
import HcpSearchDialogComponent from "../hcp-master/hcp-search-dialog/hcpSearchDialogComponent";
import NonEntityComponent from "../nonentity-master/nonEntityComponent";
import FolderComponent from "../security/email/ftpFolderComponent";
import FileStatusDetailsComponent from "../admin-task-details/fileStatusDetailsComponent";
import ReportStatusDetailsComponent from "../admin-report-task-details/reportStatusDetailsComponent";

class ContainerComponent extends Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      expandSideNav: false,
      utcPopperAnchorEl: null,
      fetchResult: ResultStatus.NOT_LOADED,
      showSessionTimeoutDialog: false
    };
  }

  componentDidMount() {
    ToastService.setComponentREF(this);
    this.setFetchStatus();
    UserActivityService.trackUserActivity(this, this.props.oktaAuth);
  }


  retry = () => {
    SessionService.configureOBS(this.context.user).subscribe((_result) => {
      this.setFetchStatus();
    })
  }

  setFetchStatus = () => {
    if (RolePermissionService.IS_CONFIGURED) {
      this.setState({ fetchResult: ResultStatus.SUCCESS });
    } else {
      ToastService.showError("Roles And Permissions are not configured.");
      this.setState({ fetchResult: ResultStatus.ERROR });
    }
  }

  getHomeComponenet = () => {
    return RolePermissionService.hasOnlyPowerBIAccess ?
      <PowerBIDemoContainerComponent sideNavIsOpen={this.state.expandSideNav} />
      : <HomeComponent sideNavIsOpen={this.state.expandSideNav} />
  }

  handleContinueClick = () => {
    this.props.oktaAuth.session.refresh()
      .then(function (session) {
        console.log("Renewed session", session);
        // existing session is now refreshed
      })
      .catch(function (err) {
        console.log("Error refreshing user session", err);
        // not logged in
      });
    this.setState({
      showSessionTimeoutDialog: false
    }, () => {
      clearInterval(UserActivityService.sessionLogoutTimerRef);
      UserActivityService.startTracking();
    });
  }

  handleLogoutClick = () => {
    this.setState({
      showSessionTimeoutDialog: false
    }, () => {
      UserActivityService.logout();
    });
  }

  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent classes={classes} label="Loading User details..." />);

      case ResultStatus.SUCCESS:
        return (
          <BrowserRouter>
            {/* !!!.Don't Remove the Snack Bar!!! */}
            {/* It is required to show the Toast across the application */}
            <Snackbar open={this.state.showToast} anchorOrigin={ToastService.anchorOrigin} autoHideDuration={ToastService.autoHideDuration} onClose={ToastService.closeToast}>
              <MuiAlert elevation={ToastService.elevation} variant={ToastService.variant} onClose={ToastService.closeToast} severity={ToastService.toastType}>{ToastService.message}</MuiAlert>
            </Snackbar>

            {this.state.showSessionTimeoutDialog ?
              <Dialog open={this.state.showSessionTimeoutDialog || false} onClose={() => this.handleLogoutClick()}>
                <DialogTitle id="alert-dialog-title">
                  <AppBar position="static">
                    <Toolbar>
                      <Typography variant="h6" className={classes.root} style={{ flexGrow: 1 }}>Session Expire Warning</Typography>
                      {LayoutService.getIconButton(false, MatIconService.OK, "OK", this.handleContinueClick, "inherit", "keyActionContinue")}
                      {LayoutService.getIconButton(false, MatIconService.CANCEL, "Logout now", this.handleLogoutClick, "secondary", "keyActionCancel")}
                    </Toolbar>
                  </AppBar>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" className={classes.root} align="center">Your session will expire in {UserActivityService.sessionExpiryWarningTimeInSecs} seconds. Do you want to extend the session?</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
              : null}

            {/* TopBar */}
            <TopBarComponent topBarColor={this.props.topBarColor}
              toggleSideNav={() => { this.setState({ expandSideNav: !this.state.expandSideNav }); }} />

            {/* SideNav */}
            <SideNavComponent expandSideNav={this.state.expandSideNav} />

            {/* Dynamic content Area */}
            <main className={clsx(classes.content, { [classes.contentShift]: this.state.expandSideNav, })}>
              <div className={classes.drawerHeader} />
              <div id="divContainerWrapper" name="divContainerWrapper" key="divContainerWrapper" style={{ position: 'relative' }}>
                <Switch>
                  <Route exact path="/">{this.getHomeComponenet()}</Route>
                  <Route exact path="/home">{this.getHomeComponenet()}</Route>
                  <Route exact path="/tenant-config"><TenantconfigurationComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/data-center"><DataCenterComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/profile-center"><ProfileCenterComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/profile-center/profile-expanded-view"><ProfileExpandedViewComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/transaction-center"> <TransactionCenterComponent sideNavIsOpen={this.state.expandSideNav} /> </Route>
                  <Route exact path="/source-files"><SourceComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/workflow-template"><WorkflowTemplateListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/porzio-rules"><SelectedRulesComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/users"><UserListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/role-management"><RoleManagementComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/role-permissions"><RolePermissionsComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/transactions"><TransactionCenterComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/porzio-fields"><PorzioFieldsListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/custom-fields"><CustomFieldsListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/affiliate-companies"><CompanyAffiliateListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/vendor-setup"><VendorSetupComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/default-mapping"><ComplianceDefaultMappingComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/report-mapping"><ComplianceReportMappingComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/consent-mapping"><ComplianceConsentMappingComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/tenant-report-template-subscriptions"><TenantReportTemplateSubscriptionComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/product-master"><ProductMasterComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/sales-rep"><SalesRepComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/materials"><MaterialsListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/studies"><StudiesListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/hcp-master"><HcpSearchDialogComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/nonentity-master"><NonEntityComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/ftp-folders"><FolderComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/reports-center"><ReportsCenterComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/agreements"><AgreementListComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/documents"><DocumentComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/contact-us"><ContactUsComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/tenant-status"><TenantStatusComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/user-mappings"><UserMappingsComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/admin-task-details"><FileStatusDetailsComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  <Route exact path="/admin-report-task-details"><ReportStatusDetailsComponent sideNavIsOpen={this.state.expandSideNav} /></Route>
                  {/* Izenda Reporting Route */}
                  <Route exact path="/reporting" component={ReportingHome} />
                  {/* Izenda Report Viewer Route */}
                  <Route exact path="/reporting/viewer/:id" component={ReportingViewer}></Route>
                  {/* Izenda Export Route. TODO GET THIS ROUTE TO TOP LEVEL */}
                  <Route exact path="/reporting/viewer/reportpart/:id" component={ReportingExport}></Route>
                  {/* Izenda Subreport Route. TODO GET THIS ROUTE TO THE TOP LEVEL */}
                  <Route exact path="/reporting/report/view/:id" component={ReportingViewer}></Route>
                  {/* default route */}
                  <Route exact path="/power-bi" component={PowerBIDemoContainerComponent}></Route>
                  <Route component={UnderContructionComponent} />
                </Switch>
              </div>
            </main>

            {/* Footer Compoenent */}
            <FooterComponent onThemeChange={this.props.onThemeChange} />

          </BrowserRouter >
        );

      case ResultStatus.ERROR:
      default:
        return (<DialogErrorFragmentComponent title="Error" label="Error loading User Details." classes={classes}
          onRetry={() => { this.retry(); }} onClose={() => { }}
        />);
    }


  }
}

const styles = (_theme) => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: _theme.spacing(0, 1),
    // necessary for content to be below app bar
    ..._theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    paddingLeft: _theme.spacing(0),
    paddingRight: _theme.spacing(2),
    paddingTop: _theme.spacing(3),
    paddingBottom: _theme.spacing(0),
    transition: _theme.transitions.create("margin", {
      easing: _theme.transitions.easing.sharp,
      duration: _theme.transitions.duration.leavingScreen,
    }),
    marginLeft: _theme.spacing(12) + 1,
  },
  contentShift: {
    transition: _theme.transitions.create("margin", {
      easing: _theme.transitions.easing.easeOut,
      duration: _theme.transitions.duration.enteringScreen,
    }),
    marginLeft: MatSpecService.drawerWidth,
  },
});

export default withStyles(styles)(withOktaAuth(ContainerComponent));
