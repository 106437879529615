import React from "react";
import { Formik } from "formik";
import { AgGridReact } from "ag-grid-react";
import { Button, Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, Grid, TextField, Box, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from "@material-ui/core";
import { AuthContext } from "../../../../../shared/store/authProvider";
import { ResultStatus, PROFILE_CATEGORY } from "../../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../../shared/services/dataService";
import LayoutService from "../../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../../shared/components/page/dialogErrorFragmentComponent";
import { MatIconService } from "../../../../../shared/services/theme/matIconService";
import ReferenceDataSearchDialogService from "./referenceDataSearchDialogService";
import AgGridRadioButtonCellRendererComponent from "../../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import { AgGridUtil } from "../../../../../shared/services/ag-grid/agGridUtil";
import MatThemeService from "../../../../../shared/services/theme/matThemeService";
import ToastService from "../../../../../shared/services/toastService";
import { AgGridColumnExt } from "../../../../../shared/services/ag-grid/agGridColumnExt";

class ReferenceDataSearchDialogComponent extends React.Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        
        this.state = {
            searchResult: ResultStatus.NOT_LOADED,
            isIndividualProfileCategory: this.props.selectedProfileCategory === PROFILE_CATEGORY.ORGANIZATION ? false : true,
            errorMessage: null,
            
            vplAndNppl: {
                firstName: this.props.selectedProfile.firstname,
                middleName: this.props.selectedProfile.middlename,
                lastName: this.props.selectedProfile.lastname,
                suffix: this.props.selectedProfile.suffix,
                npi: this.props.selectedProfile.usnpinumber,
                licenseState: this.props.selectedProfile.uslicensestate,
                licenseNumber: this.props.selectedProfile.usstatelicensenumber
            },
            
            teachingHospital: {
                year: new Date().getFullYear(),
                organizationName: this.props.selectedProfile.organizationname,
                tin: this.props.selectedProfile.ustaxidnumber,    
                city : this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().city:[]:[],
                state: this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().province:[]:[],
            },
            individual: {
                firstName: this.props.selectedProfile.firstname,
                middleName: this.props.selectedProfile.middlename,
                lastName: this.props.selectedProfile.lastname,
                suffix: this.props.selectedProfile.suffix,
                npi: this.props.selectedProfile.usnpinumber,
                licenseState: this.props.selectedProfile.uslicensestate,
                licenseNumber: this.props.selectedProfile.usstatelicensenumber
            },
            organization: {
                organizationName: this.props.selectedProfile.organizationname,
                npi: this.props.selectedProfile.usnpinumber,
                city : this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().city:[]:[],
                state: this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().province:[]:[],
            },
            referenceDataList: [],
            selectedReferenceData: null,
            showSearchResults: false,
            selectedPorzioGSTProfileId: this.props.selectedPorzioGSTProfileId,
            agGridUtils: new AgGridUtil("firstName", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent
            }),
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        //this.resetFields();
    }

    handleGoBack = () => {
        this.setState({
            searchResult: ResultStatus.NOT_LOADED,
            showSearchResults: false,
            vplAndNppl: {
                firstName: "",
                middleName: "",
                lastName: "",
                suffix: "",
                npi: "",
                licenseState: "",
                licenseNumber: ""
            },
            teachingHospital: {
                year: "",
                organizationName: "",
                tin: "",
                city: "",
                state: ""
            },
            individual: {
                firstName: "",
                middleName: "",
                lastName: "",
                suffix: "",
                npi: "",
                licenseState: "",
                licenseNumber: ""
            },
            organization: {
                organizationName: "",
                npi: "",
                city: "",
                state: ""
            },
            referenceDataList: [],
            selectedReferenceData: null,            
            selectedPorzioGSTProfileId: this.props.selectedPorzioGSTProfileId,
            agGridUtils: new AgGridUtil("firstName", {
                isSelectedCellRenderer: AgGridRadioButtonCellRendererComponent
            }),
        });
    }

    searchReferenceData = (_formikProps, _isIndividual) => {
        this.apiSubscriptions.cancelAll();

        //TODO : check if atleast one search value entered
        if (true) {
            this.setState({
                searchResult: ResultStatus.LOADING,
            });
            this.apiSubscriptions.add(
                ReferenceDataSearchDialogService.searchReferenceData(this.context.user.tenantId, _isIndividual, _formikProps, this.props.isNppes).subscribe(
                    (_referenceDataList) => {
                        if (DataService.hasElements(_referenceDataList)) {
                            _referenceDataList.forEach(x => { x.isSelected = false; });
                        }
                        this.setState({
                            showSearchResults: true,
                            //searchResult: ResultStatus.LOADED,
                            searchResult: DataService.hasElements(_referenceDataList) ? ResultStatus.SUCCESS : ResultStatus.LOADED,//-> no records
                            referenceDataList: _referenceDataList
                        });
                    },
                    (err) => { this.setState({ searchResult: ResultStatus.ERROR }); }
                )
            );
        } else {
            this.setState({ searchResult: ResultStatus.NOT_LOADED });
        }
        return [];
    }

    getInitialValues(isIndividual, isNppes) {
        if (!isNppes) {
            if (isIndividual) {
                return this.state.vplAndNppl;
            } else {
                return this.state.teachingHospital;
            }
        } else {
            if (isIndividual) {
                return this.state.individual;
            } else {
                return this.state.organization;
            }
        }
    }

    handleSubmit = (_isIndividualProfileCategory, _isNppes) => {
        let selectedData = this.state.selectedReferenceData;
        this.resetFields(() => {
            this.props.onSubmitClick(_isIndividualProfileCategory, selectedData, _isNppes);
        })
    }

    onClose = (_reloadRequired) => {
        this.resetFields(() => this.props.onClose(_reloadRequired));
    }

    resetFields(_callback) {
        //var activeaddr  = this.props.selectedProfile.addresslist.length>0?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}):[];
        this.setState({
            referenceDataList: [],
            selectedReferenceData: null,
            errorMessage: null,
            searchResult: ResultStatus.NOT_LOADED,
            showSearchResults: false,
            vplAndNppl: {
                firstName: this.props.selectedProfile.firstname,
                middleName: this.props.selectedProfile.middlename,
                lastName: this.props.selectedProfile.lastname,
                suffix: this.props.selectedProfile.suffix,
                npi: this.props.selectedProfile.usnpinumber,
                licenseState: this.props.selectedProfile.uslicensestate,
                licenseNumber: this.props.selectedProfile.usstatelicensenumber
            },
            teachingHospital: {
                year: new Date().getFullYear(),
                organizationName: this.props.selectedProfile.organizationname,
                tin: this.props.selectedProfile.ustaxidnumber,
                city : this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().city:[]:[],
                state: this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().province:[]:[],
            },
            individual: {
                firstName: this.props.selectedProfile.firstname,
                middleName: this.props.selectedProfile.middlename,
                lastName: this.props.selectedProfile.lastname,
                suffix: this.props.selectedProfile.suffix,
                npi: this.props.selectedProfile.usnpinumber,
                licenseState: this.props.selectedProfile.uslicensestate,
                licenseNumber: this.props.selectedProfile.usstatelicensenumber
            },
            organization: {
                organizationName: this.props.selectedProfile.organizationname,
                npi: this.props.selectedProfile.usnpinumber,
                city : this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().city:[]:[],
                state: this.props.selectedProfile.addresslist.length>0 ? (this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift() )!=undefined?this.props.selectedProfile.addresslist.filter(function (address){ return address.isactive === true}).shift().province:[]:[],
            },
        }, _callback);
    }

    render() {
        const { classes } = this.props;
        //Reevaluating 'isIndividualProfileCategory' here and not taking it from state 
        //as render function is being called after the Main profile tab is loaded and so state's property is not updated
        const profileCategory = DataService.hasElements(this.props.profileCategoryList) ? this.props.profileCategoryList.find(p => p.lovId == this.props.selectedProfile?.profilecategoryid)?.lovKey : "";
        let isIndividualProfileCategory = profileCategory === PROFILE_CATEGORY.ORGANIZATION ? false : true;

        return (
            <Dialog open={this.props.open || false} fullWidth={true} scroll={false ? "paper" : "body"} maxWidth={"lg"} onClose={() => { this.onClose(false) }}
                style={{ maxHeight: "100vh", }}>
                <Formik initialValues={this.getInitialValues(isIndividualProfileCategory, this.props.isNppes)} >
                    {(fProps) => (
                        <form>
                            <DialogTitle style={{ padding: 0 }} id="dialog-title">
                                <AppBar position="static">
                                    <Toolbar>
                                        {this.state.showSearchResults === true ?
                                            <React.Fragment>
                                                {LayoutService.getIconButton(!this.state.showSearchResults, MatIconService.BACK, "Go Back", () => {
                                                    this.handleGoBack()
                                                }, "inherit")}
                                            </React.Fragment>
                                            : <></>}
                                        <Typography variant="h6" className={classes.root}>Reference Data Search</Typography>
                                        {LayoutService.getIconButton(!this.state.selectedReferenceData, MatIconService.OK, "OK", () => {
                                            this.handleSubmit(isIndividualProfileCategory, this.props.isNppes)
                                        }, "inherit")}
                                        {LayoutService.getIconButton(false, MatIconService.CANCEL, "Discard Changes", () => { this.onClose(false); }, "inherit")}
                                    </Toolbar>
                                </AppBar>
                            </DialogTitle>
                            <DialogContent>
                                <Box style={{
                                    paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15,
                                    //minWidth: "80vh",
                                    backgroundColor: MatThemeService.getReverseAlternatingBG(3)
                                }}>
                                    {this.renderContent(classes, isIndividualProfileCategory, fProps)}
                                </Box>
                            </DialogContent>
                        </form>
                    )}
                </Formik>
            </Dialog >
        );
    }

    renderContent = (classes, isIndividualProfileCategory, fProps) => {
        switch (this.state.searchResult) {
            case ResultStatus.SAVING:
                return <PageLoadingComponent small classes={classes} label="Saving..." />;
            case ResultStatus.LOADING:
                return <PageLoadingComponent small classes={classes} label="Searching..." />;
            case ResultStatus.NOT_LOADED:
                return (<React.Fragment>
                    {isIndividualProfileCategory ?
                        //VPL and NPPL
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "firstName", "First Name", false)} style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "middleName", "Middle Name", false)} style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "lastName", "Last Name", false)} style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "suffix", "Suffix", false)} style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "npi", "NPI", false)} style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "licenseState", "License State", false)} style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "licenseNumber", "License Number", false)} style={{ minWidth: "90%" }} /></Grid>
                        </Grid>
                        :
                        !this.props.isNppes ?
                            //Teaching Hospital
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "year", "Year", false)} style={{ minWidth: "90%" }} /></Grid>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "organizationName", "Organization Name", false)} style={{ minWidth: "90%" }} /></Grid>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "tin", "TIN", false)} style={{ minWidth: "90%" }} /></Grid>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "city", "City", false)} style={{ minWidth: "90%" }} /></Grid>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "state", "State", false)} style={{ minWidth: "90%" }} /></Grid>
                            </Grid>
                            :
                            //Teaching Hospital
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "organizationName", "Organization Name", false)} style={{ minWidth: "90%" }} /></Grid>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "npi", "NPI", false)} style={{ minWidth: "90%" }} /></Grid>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "city", "City", false)} style={{ minWidth: "90%" }} /></Grid>
                                <Grid item xs={12} sm={4}><TextField {...LayoutService.getInputProps(false, classes, fProps, "state", "State", false)} style={{ minWidth: "90%" }} /></Grid>
                            </Grid>
                    }
                    <Grid container justify="center" style={{ padding: 15 }}>
                        <Button variant="outlined" color="secondary" onClick={() => this.searchReferenceData(fProps, isIndividualProfileCategory)}>Search</Button>
                    </Grid>
                </React.Fragment>
                );
            case ResultStatus.LOADED:
                return <DialogErrorFragmentComponent minHeight={'10vh'} small classes={classes} description="No Records Found" />;
            case ResultStatus.SUCCESS:
                return (<div id="ReferenceDataGrid" style={{ padding: 15 }} {...LayoutService.getAgGridStyles()}>
                    <AgGridReact
                        rowData={this.state.referenceDataList}
                        columnDefs={ReferenceDataSearchDialogService.getColumnDefs(this, isIndividualProfileCategory, (_data) => {
                            this.setState({ selectedReferenceData: _data });
                        }, this.props.isNppes)}
                        pagination={true}
                        paginationPageSize={50}
                        suppressClickEdit={true}
                        frameworkComponents={this.state.agGridUtils.frameworkComponents}
                        onGridReady={(params) => {
                            this.state.agGridUtils.setGridParams(params, true);
                        }}
                        gridOptions={{
                            context: { componentParent: this },
                            suppressContextMenu: true,
                            rowHeight: 32,
                            ...AgGridColumnExt.getGridOptions(56)
                        }}
                    >
                    </AgGridReact>
                </div>);
            case ResultStatus.ERROR:
                return (<DialogErrorFragmentComponent classes={classes} minHeight={'10vh'} description="An error occured while Searching"
                    onRetry={() => { this.searchReferenceData(this.state.lastSearchedValue); }} />);
            default: return <></>;
        }
    }

}
export default LayoutService.getHocComponenet(ReferenceDataSearchDialogComponent);