import React from "react";
import { of, combineLatest } from "rxjs";
import { DialogTitle, DialogContent, Box, Grid, Tooltip, Toolbar, IconButton, InputBase } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { CrudAction, ENTITY_TYPE, ResultStatus } from "../../../../shared/types/enums";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import MatSpecService from "../../../../shared/services/theme/matSpecService";
import LayoutService from "../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import AgreementAdvancedSearchService from "./agreementAdvancedSearchService";
import AgreementDetailCellRendererComponent from "../../../../private/agreements/agreementDetailCellRendererComponent";
import AgreementDetailDialogComponent from "../../../../private/agreements/agreement-detail/agreementDetailDialogComponent";
import AdvancedSearchContainerService from "../advancedSearchContainerService";
class AgreementAdvancedSearchComponent extends React.Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            data: [],
            fetchResult: ResultStatus.LOADED,
            isEditing: true,
            porzioSearch: "",
            agGridUtils: new AgGridUtil("lastname", {
                inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
                agreementDetailCellRendererComponent: AgreementDetailCellRendererComponent
            }),
        };
    }
    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }
    componentDidMount() {
        // if (this.state.isEditing) {
        //     this.setState({ fetchResult: ResultStatus.LOADING });
        //     this.fetchData();
        // }
        AdvancedSearchContainerService.advancedSearchSubject.subscribe(
            (success) => {
                this.setState({ fetchResult: ResultStatus.LOADING });
                this.fetchData();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /** API Fetch */
    fetchData = () => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

        /**
         * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
         * this operator is very handy as we don't have to independently wait for each result
         */
        this.apiSubscriptions.add(
            combineLatest([
                // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
                AgreementAdvancedSearchService.searchOBS(this.context.user.tenantId, this.props.searchValue),
            ]).subscribe(
                // success
                ([_data]) => {
                    this.setState(
                        {
                            data: DataService.hasElements(_data) ? _data : [],
                        },
                        // change the state after all the above are assigned
                        () => {
                            //this.state.agGridUtils.sizeColumnsToFit();
                            this.setState({ fetchResult: ResultStatus.SUCCESS });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    onDownloadClick = () => {
        let api = this.gridApi, params = this.getParams();
        api.exportDataAsExcel(params);
    }

    getParams() {
        return {
            // allColumns: true,
            columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
            fileName: this.getFileName()
        };
    }

    getFileName() {
        return `Agreements ${new Date().toDateString()}`;
    }

    // called on row-cell click
    methodFromParent = (row_col, node) => {
        this.setState({ modalAgNode: node });
        if (this.state.agGridUtils.isNotEditing()) {
            this.setState({ showAgreementDetailDialog: true, inputAction: CrudAction.UPDATE, modalAgNode: node });
        }
    };

    render() {
        const { classes } = this.props;
        //this.props.tabConfig.ref = this; // 1/4) required by parent component
        const componentType = "Agreements";

        switch (this.state.fetchResult) {
            case ResultStatus.LOADING:
                return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
            case ResultStatus.SAVING:
                return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
            case ResultStatus.LOADED:
            case ResultStatus.SUCCESS:
                return (
                    <React.Fragment>
                        <DialogTitle disableTypography id="dialogTitle" />
                        <DialogContent>
                            <Box style={{ padding: "8px" }}>
                                <Grid className={classes.flexNoShrink} container justify="flex-end">
                                    <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                                        <IconButton onClick={this.onDownloadClick} color="secondary">
                                            {MatIconService.DOWNLOAD}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <div {...LayoutService.getAgGridStyles(320)}>
                                    <AgGridReact
                                        pagination={true}
                                        paginationPageSize={100}
                                        rowData={this.state.data}
                                        columnDefs={AgreementAdvancedSearchService.getColumnDefs(this)}
                                        frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                        suppressClickEdit={true}

                                        gridOptions={{
                                            context: { componentParent: this },
                                            ...AgGridColumnExt.getGridOptions(40),
                                            ...this.state.agGridUtils.bindInlineEditEvents(),
                                        }}
                                        onGridReady={(params) => {
                                            this.gridApi = params.api;
                                            this.gridColumnApi = params.columnApi;
                                            this.state.agGridUtils.setGridParams(params, false);
                                        }}>
                                    </AgGridReact>
                                </div>
                            </Box>
                        </DialogContent>
                        {this.state.showAgreementDetailDialog ?
                            <AgreementDetailDialogComponent inputAction={this.state.inputAction} modalAgNode={this.state.modalAgNode}
                                open={this.state.showAgreementDetailDialog || false}
                                onClose={(_reloadRequired) => {
                                    this.setState({ showAgreementDetailDialog: false });
                                }}
                            /> : null}
                    </React.Fragment>
                );

            case ResultStatus.ERROR:
            default:
                return (
                    <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
                        onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
                );
        }
    }

    /** 2/4 Required in Parent */
    isDirtyCallback = () => {
        return false;
        // do any additional checkings if needed
        // if (this.fPropsDynamic) {
        //   return this.fPropsDynamic.dirty;
        // } else {
        //   return false;
        // }
    }

    /** 3/4 Required in Parent */
    resetCallback = () => {
        // if (this.fPropsDynamic) {
        //   this.fPropsDynamic.resetForm();
        // }
        // do any additional resetting if needed
    }

    /** 4/4 Required in Parent */
    postCallbackOBS = () => {
        return of(null);
        // if (DataService.isNullOrUndefined(this.fPropsDynamic)) { return of(null); }
        // else {

        // }
    }

}

/** HOC */
export default LayoutService.getHocComponenet(AgreementAdvancedSearchComponent);
