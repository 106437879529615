import React, { Component } from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { AgGridColumnExt } from "../../../services/ag-grid/agGridColumnExt";
import { DataService } from "../../../services/dataService";
import MatSpecService from "../../../services/theme/matSpecService";
import ToastService from "../../../services/toastService";

/**
 * Author : Pradeep_Rajendran@epam.com
 */
export class AgGridBulkEditUtil {

    static selectColumnName = "is_selected";

    static getSelectColumn = (_isEditable, _callbackOnChange) => {
        return AgGridColumnExt.GET(_isEditable, "text", AgGridBulkEditUtil.selectColumnName, "Edit").fixedWidth(88).headerClass("center-text")
            .cellRenderer("isCheckedCellRenderer", { onChange: _callbackOnChange }).SET();
    }


    constructor() {
        this.clearUpdateTrackers();
    }

    clearUpdateTrackers = () => {
        this.updatedRowIds = [];
        this.updatedCells = [];
        this.updatedRowIdsCoumnNamesMap = new Map();
    }

    updatedRowIdsCoumnNamesMap = new Map();
    updatedRowIds = [];
    updatedCells = [];
    hasUpdates = () => { return this.updatedRowIds.length > 0; }
    markUpdatedRowIds = (_rowId, _columnName) => {
        if (!this.updatedRowIds.includes(_rowId)) {
            this.updatedRowIds.push(_rowId);
        }

        const cellInfo = AgGridBulkEditUtil._getCellInfo(_rowId, _columnName);
        if (!this.updatedCells.includes(cellInfo)) { this.updatedCells.push(cellInfo); }


        if (!this.updatedRowIdsCoumnNamesMap.has(_rowId)) {
            this.updatedRowIdsCoumnNamesMap.set(_rowId, []);
        }
        let rowColumnNames = this.updatedRowIdsCoumnNamesMap.get(_rowId);
        if (!rowColumnNames.includes(_columnName)) {
            rowColumnNames.push(_columnName);
            this.updatedRowIdsCoumnNamesMap.set(_rowId, rowColumnNames);
        }
    }

    static _getCellInfo = (_rowId, _columnName) => {
        return `rowId:${_rowId}|columnName:${_columnName}`;
    }

    /**
     * 
     * @param {*} _parentCellRef 
     * @param {*} _newValue for lov its the id.
     * @param {*} _lovTextColumnName applies to lov only (text column name for lov)
     * @param {*} _lovText applies to lov only (pass the text value for lov)
     */
    bulkApply = (_parentCellRef, _newValue, _lovTextColumnName = null, _lovText = null) => {
        const parentCellProps = _parentCellRef.props;

        const currentCell = parentCellProps.api.getFocusedCell();
        if (currentCell.column.colDef.field === AgGridBulkEditUtil.selectColumnName) {
            parentCellProps.node.setSelected(parentCellProps.node.data[AgGridBulkEditUtil.selectColumnName] === true);
        } else {
            var rowNodesToUpdate = [parentCellProps.node];
            this.markUpdatedRowIds(parentCellProps.node.id, parentCellProps.column.colDef.field); //TODO

            parentCellProps.api.forEachNodeAfterFilter((rowNode) => {
                // !!! this is very important as we cannot rely on the rowNode.rowIndex after a sort, so we are using the id to localte the correct index of the row
                // const sortAndFilterAwareRowNode = parentCellProps.api.getRowNode(rowNode.id);

                if (parentCellProps.node.id !== rowNode.id && rowNode.data[AgGridBulkEditUtil.selectColumnName] === true) {
                    // applies the new value
                    rowNode.setDataValue(parentCellProps.column.colDef.field, _newValue);
                    // updates the underlying text property of lov
                    if (!DataService.isStringNullOrEmpty(_lovTextColumnName)) {
                        rowNode.data[_lovTextColumnName] = _lovText;
                    }
                    //---

                    rowNodesToUpdate.push(rowNode);
                    this.markUpdatedRowIds(rowNode.id, parentCellProps.column.colDef.field);
                }
            });
            parentCellProps.api.flashCells({ rowNodes: rowNodesToUpdate, columns: [parentCellProps.column.colDef.field] });
        }
    }



    /**
     * 
     * @param {*} _cellRef 
     * @param {*} _newValue for lov its the id.
     * @param {*} _lovTextColumnName applies to lov only (text column name for lov)
     * @param {*} _newValueText applies to lov only (pass the text value for lov)
     */
    static applyChange = (_cellRef, _newValue, _lovTextColumnName = null, _lovText = null) => {
        const cellProps = _cellRef.props;

        // for transaction, todo: check for profile
        if ((_lovTextColumnName || "").startsWith("vendor")) { _lovTextColumnName = "vendorname"; }

        // this.props.node.setDataValue(this.props.column.colDef.field, e);
        //--- the entire cell-component will reload if setDataValue() is used,.. so avoid it and update the underlying datasource directly
        cellProps.node.data[cellProps.column.colDef.field] = _newValue;
        cellProps.data[cellProps.column.colDef.field] = _newValue;
        // apply the text value for Lov
        if (!DataService.isStringNullOrEmpty(_lovTextColumnName)) {
            cellProps.node.data[_lovTextColumnName] = _lovText;
            cellProps.data[_lovTextColumnName] = _lovText;
        }
        //---

        // bulk apply
        _cellRef.state.parentRef.state.agGridBulkEditUtil.bulkApply(_cellRef, _newValue, _lovTextColumnName, _lovText);

        _cellRef.setState({
            isEditing: false,
            hasErrorOrWarning: AgGridBulkEditUtil.hasErrorOrWarning(_cellRef.state.parentRef.state.agGridBulkEditUtil, _cellRef.props, _cellRef.state.errorOrWarningConfig),
            value: _newValue,
        })
    }

    static handleClose = (_cellRef) => {
        _cellRef.setState({ isEditing: false });
    }


    static getRenderer = (_cellRef, _formattedValueCallback, _editorElementCallback, _parentContainerOptions = {}, _formattedValueOptions = {}) => {

        const showErrorOrWarningElement = _cellRef.state.hasErrorOrWarning && !_cellRef.state.isEditing;
        const showEditorElement = _cellRef.state.isEditing;
        const showFormattedElement = !_cellRef.state.hasErrorOrWarning && !_cellRef.state.isEditing;

        return (
            <div onDoubleClick={(e) => { AgGridBulkEditUtil._validateEdit(_cellRef, e) }} style={{ ..._parentContainerOptions }}
                onBlur={(e) => {
                    if (!_cellRef.state.onBlurHandled) { AgGridBulkEditUtil._cancelEditing(_cellRef, e); }
                }}>
                {showErrorOrWarningElement ? AgGridBulkEditUtil._getErrorOrWarningElement(_cellRef.state.errorOrWarningConfig) : null}
                {showEditorElement ? _editorElementCallback() : null}
                {showFormattedElement ? AgGridBulkEditUtil._getFormattedElement(_formattedValueCallback, _formattedValueOptions) : null}
            </div>
        );
    }

    static _getErrorOrWarningElement = (_errorOrWarningConfig) => {
        let _style = { color: _errorOrWarningConfig.isWarning ? "orange" : "red", fontSize: 14, fontWeight: 400, lineHeight: 1.9, textDecoration: "line-through" };

        return (
            <Tooltip arrow enterDelay={MatSpecService.tooltipEnterDelay} interactive
                title={
                    <React.Fragment>
                        <Typography color="inherit">{_errorOrWarningConfig.value}</Typography>
                        {_errorOrWarningConfig.message}
                    </React.Fragment>
                }>
                <Typography style={_style} noWrap={true}>
                    {_errorOrWarningConfig.value}
                </Typography>
            </Tooltip >
        );
    }

    static _getFormattedElement = (_formattedValueCallback, _formattedOptions) => {
        return (<Typography color="inherit" style={{ minHeight: 32, fontSize: 14, fontWeight: 400, lineHeight: 1.9, ..._formattedOptions }} noWrap={true}>
            {_formattedValueCallback()}
        </Typography>);
    }


    static hasErrorOrWarning = (_agGridBulkEditUtilRef, _props, _errorOrWarningConfig) => {
        const _cellInfo = AgGridBulkEditUtil._getCellInfo(_props.node.id, _props.column.colDef.field);
        const _hasErrorOrWarning = (
            _errorOrWarningConfig && DataService.hasValidValue(_errorOrWarningConfig.message) // has error message
            && !_agGridBulkEditUtilRef.updatedCells.includes(_cellInfo) // error value is not yet updated
        );

        return _hasErrorOrWarning;
    }

    static _validateEdit = (_cellRef, e) => {
        if (_cellRef.props.node.data[AgGridBulkEditUtil.selectColumnName] !== true) { ToastService.showWarning("Please select this row to Edit."); }
        else if (_cellRef.props.isParentReadOnly) { ToastService.showWarning("The Component is in Readonly mode."); }
        else if (_cellRef.props.isColumnReadOnly) { ToastService.showWarning("The Column is marked as Readonly."); }
        else {
            _cellRef.setState({ isEditing: true })
        }
    }

    static _cancelEditing = (_cellRef, e) => {
        const currentCell = _cellRef.props.api.getFocusedCell();
        if (currentCell && (currentCell.node.id !== _cellRef.props.node.id || currentCell.column.colDef.field !== _cellRef.props.column.colDef.field)) {
            _cellRef.setState({ isEditing: false });
        }
    }




    //---

}