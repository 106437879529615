import React from "react";
import { combineLatest, } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
  Grid,
  FormHelperText,
  Input,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import LayoutService from "../../../shared/services/layoutService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../shared/services/apiService";
import DocumentDetailsService from "./documentDetailsService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";

class DocumentDetailsComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();
  lastValidatedCompanyStudyId = null;
  lastValidatedCompanyStudyResult = false;

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      fetchResult: ResultStatus.NOT_LOADED,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
    this.checkPermissionsToViewDocDetails();
  }
  checkPermissionsToViewDocDetails = () => {
    if (this.props.documentType === "SystemDocuments") {
      this.setState({
        canNotView: RolePermissionService.SYSTEM_DOCUMENTS_DETAILS.cannotView,
        canNotEdit: RolePermissionService.SYSTEM_DOCUMENTS_DETAILS.cannotEdit
      });
    } else if (this.props.documentType === "ComplianceDocuments") {
      this.setState({
        canNotView: RolePermissionService.COMPLIANCE_DOCUMENTS_DETAILS.cannotView,
        canNotEdit: RolePermissionService.COMPLIANCE_DOCUMENTS_DETAILS.cannotEdit
      });
    } else {
      this.setState({
        canNotView: RolePermissionService.CUSTOMER_DOCUMENTS_DETAILS.cannotView,
        canNotEdit: RolePermissionService.CUSTOMER_DOCUMENTS_DETAILS.cannotEdit
      });
    }
  }

  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        DocumentDetailsService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 268),
      ]).subscribe(
        // success
        ([_documentCategoryList]) => {
          this.setState(
            {
              documentCategoryList: _documentCategoryList,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };



  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING, });

          // 2) extract the formik values into a FormData object
          const formData = new FormData();

          // 3) determine the action and assign the appropriate props
          let actionVerb = "";
          let targetUrl = "";
          if (this.props.inputAction === CrudAction.CREATE) {
            // CREATE
            formData.append("documentId", 0);
            formData.append("documentCategoryId", _formikProps.values.documentCategory);
            formData.append("fileType", "csv");
            formData.append("documentName", _formikProps.values.documentName);
            formData.append("documentDescription", _formikProps.values.documentDescription);
            formData.append("isDeleted", false);
            formData.append("isActive", true);
            formData.append("startDate", "2020-08-19T20:06:07Z");
            formData.append("endDate", "2021-08-19T20:06:07Z");
            formData.append("filePath", this.state.selectedFileName);
            formData.append("efile", this.state.selectedFile);
            actionVerb = "POST";
            targetUrl = `/Document/SaveDocument/${this.context.user.tenantId}?userId=${this.context.user.userId}`;
          } else {
            // UPDATE
            // formData.append("TransactionId", 0); //int
            // formData.append("TenantId", this.context.user.tenantId); //int
            // formData.append("TransactionDocumentId", String(this.props.modalAgNode.documentID)); //string
            // formData.append("PorzioGSTDocumentID", "0"); //string
            // formData.append("EntityId", 0); //int
            // formData.append("DocumentName", _formikProps.values.documentName); //string
            // formData.append("DocumentDescription", _formikProps.values.documentDescription); //string
            // formData.append("DocumentType", "csv"); //string
            // formData.append("DocumentTypeId", _formikProps.values.documentCategory); //int

            // formData.append("CreatedBy", this.context.user.userId); //int
            // formData.append("ModifiedDate", "2020-12-28"); //string

            // // formData.append("DocumentTypes", this.props.modalAgNode.documentID); //array
            // // formData.append("Document", this.props.modalAgNode.documentID); //string
            // // formData.append("ContainerType", this.props.modalAgNode.documentID); //string

            // if (this.state.selectedFileName) {
            //   formData.append("FilePath", this.state.selectedFileName);
            //   formData.append("BlobName", this.state.selectedFileName);
            //   formData.append("efile", this.state.selectedFile);
            // } else {
            //   formData.append("FilePath", "");
            //   formData.append("BlobName", "");
            //   formData.append("efile", "");
            // }
            // actionVerb = "POST";
            // targetUrl = `/Document/SaveDocument/${this.context.user.tenantId}`;
            formData.append("documentId", this.props.modalAgNode.documentID);
            formData.append("documentCategoryId", _formikProps.values.documentCategory);
            formData.append("fileType", this.state.selectedFileName.split(".").pop());
            formData.append("documentName", _formikProps.values.documentName);
            formData.append("documentDescription", _formikProps.values.documentDescription);
            formData.append("isDeleted", false);
            formData.append("isActive", true);
            formData.append("startDate", "2020-08-19T20:06:07Z");
            formData.append("endDate", "2021-08-19T20:06:07Z");
            formData.append("filePath", this.state.selectedFileName);
            formData.append("fileName", this.props.modalAgNode.fileName);
            formData.append("blobName", this.props.modalAgNode.blobName);
            formData.append("efile", this.state.selectedFile);
            actionVerb = "POST";
            targetUrl = `/Document/SaveDocument/${this.context.user.tenantId}?userId=${this.context.user.userId}`;
          }

          // Do not set the Content-type in the headers below.  Let the browser set it. For example
          // Content-Type: multipart/form-data; boundary=----WebKitFormBoundary3sy40ozPAfPAnNRy
          // The browser handles and controls the random string at the end of the boundary
          const headers = {
            Authorization: `Bearer ${this.context.authToken}`
          };

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.CORE,
            targetUrl,
            formData,
            headers
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshGrid(true);
                this.props.onClose(false);
              } else {
                console.error("Error: falsey successResult while saving Document Details", successResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Document Details", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }

  getInitialValues() {
    return {
      documentName: this.props.modalAgNode.documentName,
      documentDescription: this.props.modalAgNode.documentDescription,
      documentCategory: this.props.modalAgNode.documentCategoryId,
    };
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      documentName: Yup.string()
        .required("Required")
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less"),
      documentCategory: Yup.number().required("Required"),
    });
    return this.validationSchema;
  }

  render() {
    const { classes } = this.props;

    if (this.state.canNotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Document Details"
            />
          );
        case ResultStatus.SAVING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Saving Document Details"
            />
          );
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <>
              <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                {(fProps) => (
                  <form>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* Dialog Title */}
                      <DialogTitle disableTypography id="dialogTitle">
                        <AppBar position="static">
                          <Toolbar variant="dense">
                            <Typography variant="h6" className={classes.root}>
                              Document Details
                            </Typography>
                            {this.props.inputAction === CrudAction.UPDATE ? this.state.canNotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                              () => { this.props.onClose(false) }, "secondary")
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                              : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            }
                          </Toolbar>
                        </AppBar>
                      </DialogTitle>
                      {/* Dialog Content */}
                      <DialogContent>
                        <Box
                          style={{
                            paddingLeft: 16,
                            paddingRight: 32,
                            paddingTop: 16,
                            paddingBottom: 32,
                            minWidth: "80vh",
                            maxWidth: "80vh",
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "documentName",
                                  "Document Name"
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                {...LayoutService.getInputProps(this.state.isReadOnly,
                                  classes,
                                  fProps,
                                  "documentDescription",
                                  "Document Description"
                                )}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {LayoutService.getSelectControl(this.state.isReadOnly,
                                classes,
                                fProps,
                                "documentCategory",
                                "Document Category",
                                this.state.documentCategoryList,
                                "lovId",
                                "lovKey",
                                "98%"
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormHelperText>Attachment</FormHelperText>
                              <Input
                                type="file"
                                onChange={(event) =>
                                  this.setState({
                                    selectedFile: event.target.files[0],
                                    selectedFileName: event.target.files[0].name,
                                  })
                                }
                                fullWidth
                              />
                              <div style={{ display: "flex" }}>
                                <Typography
                                  variant="body1"
                                  className={classes.root}
                                  color="secondary"
                                  display="inline"
                                >
                                  Attached File:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className={classes.root}
                                  display="inline"
                                >
                                  {this.props.inputAction === CrudAction.UPDATE
                                    ? this.props.modalAgNode.fileName
                                    : ""}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </DialogContent>
                    </MuiPickersUtilsProvider>
                  </form>
                )}
              </Formik>
            </>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <DialogErrorFragmentComponent
              title="Error"
              description="Error in Document Details"
              classes={classes}
              onClose={() => {
                this.props.onClose(false);
              }}
              onRetry={() => {
                console.log("Retry Clicked");
              }}
            />
          );
      }
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(DocumentDetailsComponent);
