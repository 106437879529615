import React, { Component } from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../shared/store/authProvider";
import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { ResultStatus, } from "../../shared/types/enums";
import { MatClassService } from "../../shared/services/theme/matClassService";

class ContactUsComponent extends Component {
  static contextType = AuthContext;

  state = {
    fetchResult: ResultStatus.SUCCESS,
  };


  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent classes={classes} label="Loading Data Center" />
        );
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            {/* Header Componenet */}
            <PageHeaderComponent
              classes={classes}
              label="Contact Us"
            />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">Porzio Life Sciences, LLC | Porzio Life Sciences, LLC is an RLDatix Company.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>The Help Desk is open 8 a.m. to 8 p.m. ET.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>For assistance, please contact your Customer Service Liaison.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>You can also reach us at:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Phone: 877-477-7411</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Email: <a href="mailto:gstsupport@porziols.com">gstsupport@porziols.com</a></Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent
            label="Error Loading Data Center List"
            classes={classes}
            onRetry={() => {
              this.fetchData(true);
            }}
          />
        );
    }
  }
}

export default withStyles(MatClassService)(ContactUsComponent);
