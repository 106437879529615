import { ReplaySubject } from 'rxjs';
import { AgGridColumnExt } from '../../../../shared/services/ag-grid/agGridColumnExt';
import { AgGridService } from "../../../../shared/services/ag-grid/agGridService";
import { DataService } from "../../../../shared/services/dataService";
import DynamicControlService from '../../../../shared/services/dynamicControlService';
import ToastService from '../../../../shared/services/toastService';
import LookupService from "../../../../shared/services/lookupService";

export default class AgreementTabService {

    static baseCurrencyFieldId = 176;  // for splicing Currency after

    static otherFieldGroupKey = 100;
    static filterFieldGroupKey = 1;
    static staticFieldGroupKey = 2;
    static customFieldGroupKey = 5;
    static eventFieldGroupKey = 4;

    static readonlyFieldIds = []; // filled in the INIT method

    static porzioGSTAgreementID = 140;
    static companyAgreementId = 306;
    static agreementCategory = 88;
    static agreementStatusFieldId = 82;

    // RecipinetInformation
    static recipientInformationFieldGroupKey = 3;
    static recipientInformationFieldIds = []; // filled in the INIT method
    static recipientColCompanyProfileId = 178;
    static recipientColPorzioGstProfileId = 179;
    static recipientColLastNameFieldId = 153;
    static recipientColFirstNameFieldId = 154;
    static recipientColOrganizationName = 158;
    static recipientColRecipientIdentifierCountryFieldId = 165;
    static recipientColProfileTypeId = 168;
    static recipientColProfileStatusFieldId = -100; // TODO: not in template

    // Currency Information (should go with Custom Fields)
    static userPersonalCurrencyFieldId = 1024;
    static convertedCurrencyTotalAmountFieldId = 1025;

    static INIT = () => {

        // readonly fields
        this.readonlyFieldIds = [this.porzioGSTAgreementID];

        // recipientInformation columns
        this.recipientInformationFieldIds = [
            this.recipientColCompanyProfileId, this.recipientColPorzioGstProfileId,
            this.recipientColLastNameFieldId, this.recipientColFirstNameFieldId, this.recipientColOrganizationName,
            this.recipientColRecipientIdentifierCountryFieldId, this.recipientColProfileStatusFieldId,
            this.recipientColProfileTypeId
            //this.recipientColTransactionConsent,
        ];
    }

    static getGroupFieldsMap = (_compThisRef, _isNew, _templateConfigData, _sortProp1, _sortProp2) => {
        // hardcode the prfile-status dropdown, etc,...
        this.hardcodeFields(_isNew, _templateConfigData);

        if (!_isNew) { this.readonlyFieldIds.push(this.companyAgreementId); }

        // reCreate map
        var groupInfoMap = new Map();
        groupInfoMap.set(this.filterFieldGroupKey, { "name": "", "fieldConfigs": [] });
        groupInfoMap.set(this.staticFieldGroupKey, { "name": "", "fieldConfigs": [] });
        //---
        groupInfoMap.set(this.customFieldGroupKey, { "name": "Custom Fields", "fieldConfigs": [] });
        groupInfoMap.set(this.eventFieldGroupKey, { "name": "Event Information", "fieldConfigs": [] });
        groupInfoMap.set(this.recipientInformationFieldGroupKey, { "name": "Recipient Information", "fieldConfigs": [] });
        groupInfoMap.set(this.otherFieldGroupKey, { "name": "Other Fields", "fieldConfigs": [] });


        // sort
        _templateConfigData = DataService.sortObjectByEntries(_templateConfigData, _sortProp1);
        // extract keys
        const keys = Object.keys(_templateConfigData);
        if (DataService.hasNoElements(keys)) {
            groupInfoMap = new Map(); // clear the Map
        } else {
            let nonGroupedControls = [];
            // parse through each key
            keys.forEach(key => {
                const fieldConfig = DynamicControlService.transformFieldConfig(_templateConfigData[key]);

                this.setLinkPropertiesIfAny(_compThisRef, fieldConfig);
                if (fieldConfig.fieldID === 21) {
                    fieldConfig.customLOVList = fieldConfig.customLOVList.slice().filter(k => k.lovKey.substring(0, 3) !== "US:").sort((a, b) => (a.lovKey > b.lovKey) ? 1 : ((b.lovKey > a.lovKey) ? -1 : 0));
                }

                var groupInfo = {};

                if (fieldConfig.isCustomField) { groupInfo = groupInfoMap.get(this.customFieldGroupKey); } // CUSTOM
                else if (groupInfoMap.has(fieldConfig.grouprenderid)) { groupInfo = groupInfoMap.get(fieldConfig.grouprenderid); }
                else {
                    // TODO: Enable if others is needed
                    // nonGroupedControls.push(fieldConfig);
                    // groupInfo = groupInfoMap.get(this.unmatchedFieldGroupKey);
                } // add to unmatched

                // push the field to the corresponding group
                if (groupInfo.fieldConfigs) {
                    let _push = false;

                    // hide Porzio GST Agreement if new
                    if (fieldConfig.fieldID === this.porzioGSTAgreementID) {
                        _push = !_isNew; // can see only in edit
                    } else { _push = true; }

                    // readonly
                    if (this.readonlyFieldIds.includes(fieldConfig.fieldID)) {
                        fieldConfig.isReadOnly = true;
                    }

                    // add the field
                    if (_push) { groupInfo.fieldConfigs.push(fieldConfig); }
                }
            });

            // show warnings
            if (DataService.hasElements(nonGroupedControls)) { console.warn("Controls with no GroupRendererId", nonGroupedControls); }
        }

        //sort controls based on ordinal
        groupInfoMap.forEach(value => {
            value.fieldConfigs = value.fieldConfigs.sort((a, b) => a.ordinal - b.ordinal);
        });

        //|---> rearranging order for Converted Currencies
        let staticGroupFields = groupInfoMap.get(this.staticFieldGroupKey).fieldConfigs;
        let indexToInsertAfter = staticGroupFields.findIndex(x => Number(x.fieldID) === Number(this.baseCurrencyFieldId));
        if (indexToInsertAfter < 0) indexToInsertAfter = staticGroupFields.length - 1;

        let indexConvertedCurrencyTotalAmount = staticGroupFields.findIndex(x => x.fieldID === this.convertedCurrencyTotalAmountFieldId);
        let fieldConfigConvertedCurrencyTotalAmount = staticGroupFields.splice(indexConvertedCurrencyTotalAmount, 1);
        DataService.insertAt(staticGroupFields, indexToInsertAfter + 1, fieldConfigConvertedCurrencyTotalAmount[0]);

        let indexUserPersonalCurrency = staticGroupFields.findIndex(x => x.fieldID === this.userPersonalCurrencyFieldId);
        let fieldConfigUserPersonalCurrency = staticGroupFields.splice(indexUserPersonalCurrency, 1);
        DataService.insertAt(staticGroupFields, indexToInsertAfter + 1, fieldConfigUserPersonalCurrency[0]);
        //<---|



        // return the Array of Arrays
        return groupInfoMap;
    }
    static setLinkPropertiesIfAny = (_compThisRef, _fieldConfig) => {

        switch (_fieldConfig.fieldID) {
            case AgreementTabService.recipientColPorzioGstProfileId:
                _fieldConfig[DynamicControlService.onClickCallbackKey] = (e) => {
                    AgreementTabService.showProfileDetailDialog(_compThisRef, _fieldConfig, e.value, _compThisRef.state.prid,_compThisRef.state.porziogstprofileid);
                }
                break;
            default: break;
        }

    }
    static showProfileDetailDialog = (_compThisRef, _fieldConfig, _value, _profileId,_porziogstprofileid) => {
        if (_value && DataService.isValidNumber(_profileId)) {
            _compThisRef.setState({
                targetPidFieldConfig: _fieldConfig,
                profileIdForDetailsDialog: _profileId,
                gstProfileIdForDetailsDialog:_porziogstprofileid,
                showProfileDetailDialog: true,
            });
            // }
        } else { ToastService.showError("Not a valid Profile"); }
    }

    static hardcodeFields = (isNew, _templateData) => {
        if (DataService.hasValue(_templateData)) {
            let index = 1000;
            // delete duplicate key
            const duplicateAgreementStatusKey = DataService.getFirstOrDefault((Object.keys(_templateData) || []).filter(x => x.toLowerCase().includes("agreement status")));
            if (duplicateAgreementStatusKey) { delete _templateData[duplicateAgreementStatusKey]; }

            // template status
            _templateData.AgreementStatus = {
                "isReadOnly": false,
                "fieldID": this.agreementStatusFieldId,
                "fielD_NAME": "Agreement Status",
                "fielD_DESCRIPTION": "Hardcoded field",
                "fieldlength": 100,
                "field_Type_ID": 5,
                "entityName": "agreementstatusid",
                "fielD_ALIASNAME": "agreementstatusid",
                "iS_PLSREQUIRED": true,
                "isCustomField": false,
                "lookUpList": "",
                "toolTip": "Agreement Status",
                "mappedlovidfield": "agreementstatusid",
                "autoSelect": isNew, // autoselect for new
                "hideEmpty": true, // don't show none
                "customLOVList": DynamicControlService.transformToCustomLovList(
                    isNew ? [LookupService._AGREEMENT_STATUSES[1]] : LookupService._AGREEMENT_STATUSES, "id", "value", "agreementstatusid"),
                "customAgreementLOVList": [],
                "customBoolean": false,
                "grouprenderid": 1
            }

            _templateData.UserPersonalCurrency = {
                "isReadOnly": true,
                "fieldID": this.userPersonalCurrencyFieldId,
                "fielD_NAME": "Converted Currency",
                "fielD_DESCRIPTION": "Hardcoded filed",
                "fieldlength": 100,
                "field_Type_ID": 2,
                "entityName": "userpersonalcurrency",
                "fielD_ALIASNAME": "userpersonalcurrency",
                "iS_PLSREQUIRED": false,
                "isCustomField": false,
                "lookUpList": "",
                "toolTip": "Converted Currency",
                // "mappedlovidfield": "null",  If this has a value it will be the Control Name for Fomik; otherwise fielD_ALIASNAME
                "autoSelect": isNew, // autoselect for new
                "hideEmpty": true, // don't show none
                "customLOVList": null,
                "customProfileLOVList": [],
                "customBoolean": false,
                "grouprenderid": this.staticFieldGroupKey
            }

            _templateData.ConvertedCurrencyTotalAmount = {
                "isReadOnly": true,
                "fieldID": this.convertedCurrencyTotalAmountFieldId,
                "fielD_NAME": "Converted Total Amount",
                "fielD_DESCRIPTION": "Hardcoded filed",
                "fieldlength": 100,
                "field_Type_ID": 2,
                "entityName": "convertedcurrencytotalamount",
                "fielD_ALIASNAME": "convertedcurrencytotalamount",
                "iS_PLSREQUIRED": false,
                "isCustomField": false,
                "lookUpList": "",
                "toolTip": "Converted Total Amount",
                // "mappedlovidfield": "null",
                "autoSelect": isNew, // autoselect for new
                "hideEmpty": true, // don't show none
                "customLOVList": null,
                "customProfileLOVList": [],
                "customBoolean": false,
                "grouprenderid": this.staticFieldGroupKey
            }

        }
    }
}