import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import LayoutService from "../../../shared/services/layoutService";
import { AuthContext } from "../../../shared/store/authProvider";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, API_ENDPOINT } from "../../../shared/types/enums";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppBar, DialogContent, DialogTitle, Grid, TextField, Toolbar, Typography, } from "@material-ui/core";
import ApiService from "../../../shared/services/apiService";
import EmailService from "./emailService";

class EmailComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: true,
      isEditing: true,
      typeOfEmail: [],
      selType: null,
      emailData: {},
      fetchResult: ResultStatus.NOT_LOADED
    };
  }

  setConfigs = (_selType) => {
    if (this.state.typeOfEmail.some(t => t.id == _selType)) {
      this.setState({
        selType: _selType,
        fetchResult: ResultStatus.LOADING,
      }, () => {
        this.fetchData();
      });
    } else {
      this.setState({
        selType: _selType,
        emailData: {
          type: 0,
          to: "",
          cc: "",
          bcc: "",
          subject: "",
          body: "",
        },
      });
    }
  };

  componentDidMount() {
    this.fetchEmailSetupTypes();
  }

  fetchEmailSetupTypes = () => {
    this.oSubscriptions.add(
      EmailService.getEmailSetupTypes(this.context.user.tenantId)
        .subscribe(
          (_typeOfEmail) => {
            this.setState({
              typeOfEmail: _typeOfEmail,
              selType: _typeOfEmail[0].id
            }, () => {
              this.fetchData();
            });
          },
          (error) => {
            console.log(error);
            this.setState({
              fetchResult: ResultStatus.ERROR,
            });
          }
        ));
  }

  fetchData = async () => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, emailData: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        EmailService.getEmailSetupAsOBS(this.context.user.tenantId, this.state.selType),
      ]).subscribe(
        // success
        ([_emailData,]) => {
          this.setState(
            {
              emailData: _emailData,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  getInitialValues() {
    return {
      type: this.state.selType,
      to: this.state.emailData?.to,
      cc: this.state.emailData?.cc,
      bcc: this.state.emailData?.bcc,
      subject: this.state.emailData?.subject,
      body: this.state.emailData?.body,
    };
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      type: Yup.string().required("Required"),
      to: Yup.array()
        .transform(function (value, originalValue) {
          if (this.isType(value) && value !== null) {
            return value;
          }
          return originalValue ? originalValue.split(/[\s,]+/) : [];
        })
        .of(Yup.string().email(({ value }) => `${value} is not a valid email, separate multiple emails by comma`)),
      cc: Yup.array()
        .transform(function (value, originalValue) {
          if (this.isType(value) && value !== null) {
            return value;
          }
          return originalValue ? originalValue.split(/[\s,]+/) : [];
        })
        .of(Yup.string().email(({ value }) => `${value} is not a valid email, separate multiple emails by comma`)),
      bcc: Yup.array()
        .transform(function (value, originalValue) {
          if (this.isType(value) && value !== null) {
            return value;
          }
          return originalValue ? originalValue.split(/[\s,]+/) : [];
        })
        .of(Yup.string().email(({ value }) => `${value} is not a valid email, separate multiple emails by comma`)),
      subject: Yup.string()
        .min(3, "Must be at least 3 characters long")
        .max(256, "Must be 256 characters or less")
        .required("Required"),
      body: Yup.string()
        //.min(3, "Must be at least 3 characters long")
        .max(4000, "Must be 4000 characters or less")
      //.required("Required"),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {//TODO
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema
        .validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            to: _formikProps.values.to,
            cc: _formikProps.values.cc,
            bcc: _formikProps.values.bcc,
            subject: _formikProps.values.subject,
            body: _formikProps.values.body,
            createdBy: this.state.emailData.createdBy,
            contentType: this.state.emailData?.contentType,
            emailType: this.state.emailData?.emailType,
            isActive: this.state.emailData?.isActive
          };

          // 3) determine the action and assign the appropriate props
          let apiUrl = "";
          var actionVerb = "";
          // UPDATE
          actionVerb = "POST";
          apiUrl = `/Email/SaveEmailSetUp/${this.context.user.tenantId}`;
          mappedObj.emailId = this.state.emailData.emailId;

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            apiUrl,
            JSON.stringify(mappedObj)
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.onClose(false);
              } else {
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Email Details", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          if (erroObj.inner) {
            erroObj.inner.forEach((err) => {
              _formikProps.setFieldError(err.path, err.message);
            });
          }
        });
    }
  };

  render() {
    const { classes, history } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent classes={classes} label="Loading" />;
      case ResultStatus.SAVING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Saving Email Details"
          />
        );
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={this.getValidationSchema()}
            validationSchemaOptions={{ showMultipleFieldErrors: true }}
          >
            {(fProps) => (
              <form>
                <DialogTitle disableTypography id="dialogTitle">
                  <AppBar position="static">
                    <Toolbar variant="dense">
                      <Typography variant="h6" className={classes.root}>Email Setup</Typography>
                      {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })}
                    </Toolbar>
                  </AppBar>
                </DialogTitle>
                <DialogContent style={{ paddingLeft: "25px", height: "50vh" }}>

                  <Grid container spacing={1}>

                    <Grid item xs={12} sm={6}>
                      {LayoutService.getDropDown(this.state.isReadOnly, classes.dialogControl, classes.menuPaper, fProps, this.validationSchema, "type", "Type Of Email",
                        this.state.typeOfEmail, "id", "id", null, true, "80%", (_formikProps, _newType) => { this.setConfigs(_newType); }
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "to", "To", false)}
                        style={{ minWidth: "80%" }} rows={2} multiline
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "cc", "CC", false)}
                        style={{ minWidth: "80%" }} rows={2} multiline
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "bcc", "BCC", false)}
                        style={{ minWidth: "80%" }} rows={2} multiline
                      />
                    </Grid>

                    <Grid item xs={12} >
                      <TextField
                        {...LayoutService.getInputProps(this.state.isReadOnly,
                          classes,
                          fProps,
                          "subject",
                          "Subject",
                          true
                        )}
                        style={{ minWidth: "80%" }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        {...LayoutService.getInputProps(this.state.isReadOnly,
                          classes,
                          fProps,
                          "body",
                          "Body",
                          true
                        )}
                        style={{ minWidth: "80%" }}
                        rows={4}
                        multiline
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography>To Login:</Typography>
                      <Typography>1) Go to https://www{"<Tenant URL>"}</Typography>
                      <Typography>
                        2) Use the following credentials to sign in:
                        <br />
                        User ID: {"<user name>"}
                        <br />
                        Password: {"<temporary password>"}
                      </Typography>
                    </Grid>

                  </Grid>
                </DialogContent>
              </form>
            )}
          </Formik>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent
            label="Error Loading Email Details"
            classes={classes}
            onRetry={() => {
              //this.fetchData(true);
            }}
          />
        );
    }
  }
}
export default LayoutService.getHocComponenet(EmailComponent);
