import { Observable } from "rxjs";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { PROFILE_CATEGORY } from "../../../../shared/types/enums";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import { DataService } from "../../../../shared/services/dataService";
export default class ReferenceDataService {
  static getMatchedReferenceDataForProfile(tenantId, profileid) {
    const targetUrl = `/Profiles/GetReferenceDataGetMatchedProfileFromProfileXref?tenantId=${tenantId}&porzioGstProfileId=${profileid}&pageNumber=1&rowsPerPage=5000`;
    return ApiService.getOBS(API_ENDPOINT.CORE, targetUrl);
  }

  static saveReferenceDataManually = (_tenantId, _isIndividualProfileCategory, _selectedReferenceData, selectedPorzioGSTProfileId) => {
    const vPlAndNPPL = "VPL and NPPL", teachingHospital = "TEACHING HOSPITAL";
    let profileXrefType = _isIndividualProfileCategory ? vPlAndNPPL : teachingHospital;
    let profileXrefSaveParams = "";

    for (const propName in _selectedReferenceData) {
      if (propName !== 'isSelected')
        profileXrefSaveParams += `${propName}=${_selectedReferenceData[propName]},`;
    }
    //profileXrefSaveParams = profileXrefSaveParams.replace(/(^,)|(,$)/g, "");

    let url = `/Profiles/SaveReferenceDataProfileXrefManual?tenantId=${_tenantId}&profileXrefType=${profileXrefType}&profileXrefSaveParams=${encodeURIComponent(profileXrefSaveParams)}PORZIOGSTPROFILEID=${selectedPorzioGSTProfileId}`;
    return ApiService.getOBS(API_ENDPOINT.CORE, url);
  }

  static saveNppesReferenceDataManually = (_tenantId, _isIndividualProfileCategory, _selectedReferenceData, selectedPorzioGSTProfileId) => {
    const individual = "Individual", organization = "Organization";
    let profileXrefType = _isIndividualProfileCategory ? individual : organization;
    let profileXrefSaveParams = "";

    for (const propName in _selectedReferenceData) {
      if (propName !== 'isSelected')
        profileXrefSaveParams += `${propName}=${_selectedReferenceData[propName]},`;
    }
    //profileXrefSaveParams = profileXrefSaveParams.replace(/(^,)|(,$)/g, "");

    let url = `/Profiles/SaveReferenceDataProfileXrefManual_Nppes?tenantId=${_tenantId}&profileXrefType=${profileXrefType}&profileXrefSaveParams=${encodeURIComponent(profileXrefSaveParams)}PORZIOGSTPROFILEID=${selectedPorzioGSTProfileId}`;
    return ApiService.postOBS(API_ENDPOINT.CORE, url);
  }

  static deleteMatchedProfile = (tenantId, porzioGSTProfileId) => {
    return ApiService.deleteOBS(API_ENDPOINT.CORE,
      `/Profiles/DeleteMatchedProfiles?tenantId=${tenantId}&porzioGSTProfileId=${porzioGSTProfileId}`);
  }
  static deleteMatchedProfileNppes = (tenantId, porzioGSTProfileId) => {
    return ApiService.deleteOBS(API_ENDPOINT.CORE,
      `/Profiles/DeleteMatchedProfilesNppes?tenantId=${tenantId}&porzioGSTProfileId=${porzioGSTProfileId}`);
  }

  static changeProfileFromReviewedToUnmatch = (tenantId, porziogstprofileid) => {
    return ApiService.postOBS(API_ENDPOINT.CORE,
      `/Profiles/ReferenceDataSaveUnmatchedProfiles?tenantId=${tenantId}&porziogstprofileid=${porziogstprofileid}`);
  }

  static getNppesColumns = (_isNppes) => {
    let nppesArr = [
      AgGridColumnExt.GET(false, "text", "credentials", "Credentials").minAndDefaultWidth(128).SET(),
      AgGridColumnExt.GET(false, "text", "street_address", "Address").minAndDefaultWidth(128).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "city", "City").minAndDefaultWidth(128).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "state", "Province / State").minAndDefaultWidth(112).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "po_box", "Postal Code").minAndDefaultWidth(112).SET(),
      AgGridColumnExt.GET(false, "text", "country", "Country").minAndDefaultWidth(128).SET(),
      AgGridColumnExt.GET(false, "text", "state_2", "US License State 2").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "license_2", "US License Number 2").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "state_3", "US License State 3").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "license_3", "US License Number 3").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "state_4", "US License State 4").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "license_4", "US License Number 4").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "state_5", "US License State 5").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "license_5", "US License Number 5").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
    ];
    return _isNppes ? nppesArr : [];
  }

  static getColumnDefs = (_parentThis, _isNppes) => {
    let vPlAndNPPLArr = [
      AgGridColumnExt.GET(false, "text", "statelicense", "US License State").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "statelicensenum", "US License Number").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET()
    ];
    let checkBoxCellRef = !_isNppes ? AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
      .cellRenderer("isCheckedCellRenderer", {
        onChange: _parentThis.toggleSubmitButton, isCellEnabledCallback: (_cellRef) => {
          return _cellRef.props.data['isactive'];
        }
      }).SET() : AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
        .cellRenderer("isCheckedCellRenderer", {
          onChange: _parentThis.toggleSubmitButtonForNppes, isCellEnabledCallback: (_cellRef) => {
            return _cellRef.props.data['isactive'];
          }
        }).SET();
    const profileCategory = DataService.hasElements(_parentThis.state.profileCategoryList) ? _parentThis.state.profileCategoryList.find(p => p.lovId == _parentThis.props.selectedProfile?.profilecategoryid)?.lovKey : "";
    
    if (profileCategory === PROFILE_CATEGORY.ORGANIZATION) {
      return [
        checkBoxCellRef,
        AgGridColumnExt.GET(false, "text", "isactive", "Active").minAndDefaultWidth(80).headerClass("center-text").centerAlignText().cellRenderer("isActiveIconRendererComponent").SET(),
        AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().cellRenderer("profileDetailCellRendererComponent").SET(),
        AgGridColumnExt.GET(false, "text", "source_file", "Source File").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        !_isNppes ? AgGridColumnExt.GET(false, "text", "tin", "TIN").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET()
          : AgGridColumnExt.GET(false, "text", "npinumber", "NPI").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(), ,
        AgGridColumnExt.GET(false, "text", "organization_name", "Organization Name").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "street_address", "Address").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "city", "City").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "state", "State").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(true, "text", "po_box", "Postal Code").minAndDefaultWidth(112).SET(),
        AgGridColumnExt.GET(false, "text", "country", "Country").minAndDefaultWidth(128).SET(),
        AgGridColumnExt.GET(false, "text", "match_date", "Last Modified Date").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
      ];
    } else {
      let cols =  [
        checkBoxCellRef,
        AgGridColumnExt.GET(false, "text", "isactive", "Active").minAndDefaultWidth(80).headerClass("center-text").centerAlignText().cellRenderer("isActiveIconRendererComponent").SET(),
        AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").flexWeight(1).minAndDefaultWidth(128).headerClass("center-text").centerAlignText().cellRenderer("profileDetailCellRendererComponent").SET(),
        AgGridColumnExt.GET(false, "text", "npinumber", "NPI Number").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "source_file", "Source File").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "firstname", "First Name").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "middlename", "Middle Name").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "lastname", "Last Name").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "suffix", "Suffix").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "prmry_txnmy_code", "Taxonomy Code").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "prmry_txnmy_prvdr_type", "Taxonomy Name").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "statelicense", "US License State").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
        AgGridColumnExt.GET(false, "text", "statelicensenum", "US License Number").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
       !_isNppes ? AgGridColumnExt.GET(false, "text", "additional cms licenses", "Additional CMS Licenses").minAndDefaultWidth(128).tooltip().headerClass("center-text").centerAlignText().SET() : "",
        ...this.getNppesColumns(_isNppes),
        AgGridColumnExt.GET(false, "text", "match_date", "Last Modified Date").minAndDefaultWidth(128).headerClass("center-text").centerAlignText().SET(),
       
      ];
      cols = cols.filter(x => x !== "");
      return cols;
    }
   
  }
}
