import React from "react";
import { combineLatest } from "rxjs";
import { DialogTitle, DialogContent, Box, Grid, Dialog } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import AgGridRadioButtonCellRendererComponent from "../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { CrudAction, ResultStatus, API_ENDPOINT, ENTITY_TYPE } from "../../../../shared/types/enums";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import LayoutService from "../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import ProfileCountCellRendererComponent from "../../expanded-view/profileCountCellRendererComponent";
import ProfileDetailCellRendererComponent from "../../profile-detail/profileDetailCellRendererComponent";
import LinkedProfilesService from "./linkedProfilesService";
import LinkedProfilesSearchDialogComponent from "./linked-profiles-search-dialog/linkedProfilesSearchDialogComponent";
import ApiService from "../../../../shared/services/apiService";
import ToastService from "../../../../shared/services/toastService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ActionDialog from "../../../../shared/components/dialog/actionDialog";
import ChildMessageRendererComponent from "../../../../shared/components/childMessageRendererComponent";
import ProfileDetailDialogComponent from "../profileDetailDialogComponent";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class LinkedProfilesComponent extends React.Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        // init state
        this.state = {
            data: [],
            fetchResult: ResultStatus.NOT_LOADED,
            isEditing: this.props.inputAction === CrudAction.UPDATE,
            porzioSearch: "",
            showSearchLinkedProfilesDialog: false,
            showConfirmDialog: false,
            showUnlinkConfirmDialog: false,
            profilesToBeUnlinked: "",
            parentProfile: "",
            selectedProfile: "",
            warningDesc: "",
            showUnlinkWarningDialog: false,
            currentLinkedProfiles: {},
            agGridUtils: new AgGridUtil("lastname", {
                inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
                profileCountCellRendererComponent: ProfileCountCellRendererComponent,
                profileDetailCellRendererComponent: ProfileDetailCellRendererComponent,
                isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
                childMessageRendererComponent: ChildMessageRendererComponent,
            }),
        };
    }
    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }
    componentDidMount() {
        if (this.state.isEditing) {
            this.setState({ fetchResult: ResultStatus.LOADING });
            this.fetchData();
        }
    }

    /** API Fetch */
    fetchData = () => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

        this.apiSubscriptions.add(
            combineLatest([
                // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
                LinkedProfilesService.getLinkedProfilesAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode, this.state.parentProfile, this.state.selectedProfile),
            ]).subscribe(
                // success
                ([_data]) => {
                    this.setState(
                        {
                            data: DataService.hasElements(_data) ? _data : [],
                            currentLinkedProfiles: _data.map((x) => { return x.PORZIOGSTPROFILEID })
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.state.agGridUtils.sizeColumnsToFit();
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    ToastService.showError("Error Occured");
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    // called on row-cell click
    methodFromParent = (row_col, node) => {
        node = Object.keys(node).reduce((acc, key) => {
            acc[key.toLowerCase()] = node[key];
            return acc;
        }, {});
        this.setState({ modalAgNode: node }, () => {
            this.setState({ showProfileDetailDialog: true })
        });
        // if (this.state.agGridUtils.isNotEditing()) {
        //     this.setState({ showProfileDetailDialog: true });
        // }
    };
    searchClick = () => {
        if (this.props.isReadOnly) {
            this.setState({ showSearchLinkedProfilesDialog: true });
        }
    }
    unlinkClicked = (_ids, _prid) => {
        this.apiSubscriptions.cancelAll();
        this.apiSubscriptions.add(
            combineLatest([
                ApiService.postOBS(API_ENDPOINT.CORE,
                    `/Profiles/UnLinkProfiles?tenantId=${this.context.user.tenantId}&userId=${this.context.user.userId}&ids=${_ids}&prid=${_prid}`)
            ]).subscribe(
                ([_data]) => {
                    this.fetchData();
                },
                (error) => {
                    ToastService.showError("Error Occured");
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
        this.props.changeToReadOnlyMode();
    }

    TAB_PERMISSIONS = RolePermissionService.PROFILE_LINKED_PROFILES;
    render() {
        const { classes } = this.props;
        this.props.tabConfig.ref = this; // 1/4) required by parent component

        if (this.TAB_PERMISSIONS.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
        }
        else {
            const componentType = "Linked Profiles";
            const isReadOnly = this.props.isReadOnly || this.TAB_PERMISSIONS.cannotEdit;
            this.state.agGridUtils.setEditingMode(isReadOnly);

            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
                case ResultStatus.SAVING:
                    return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <React.Fragment>
                            <ActionDialog showDialog={this.state.showConfirmDialog} title="Discard changes?" description="There are unsaved changes" actions={[
                                {
                                    color: "secondary", text: "Submit", icon: null,
                                    callback: () => {
                                        this.setState({ showConfirmDialog: false, showSearchLinkedProfilesDialog: true });
                                    }
                                },
                                {
                                    color: "inherit", text: "Cancel", icon: null,
                                    callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
                                }
                            ]} />
                            <ActionDialog showDialog={this.state.showUnlinkConfirmDialog} title="Unlink Confirmation" description="Are you sure you want to unlink the selected Profile(S)?"
                                content={this.state.profilesToBeUnlinked} actions={[
                                    {
                                        color: "secondary", text: "Submit", icon: null,
                                        callback: () => {
                                            this.setState({ showUnlinkConfirmDialog: false }, () => {
                                                var gridData = this.state.agGridUtils.getUpdatedRowData();
                                                var ids = gridData.filter(x => x.isSelected).map(x => x.PRID);
                                                var prid = this.state.data.filter(x => x.RELATIONSHIP === "Parent").map(x => x.PRID);
                                                this.unlinkClicked(DataService.arrayToString(ids), ids.includes(prid[0]) ? DataService.arrayToString(prid) : "");
                                            });
                                        }
                                    },
                                    {
                                        color: "inherit", text: "Cancel", icon: null,
                                        callback: () => { this.setState({ showUnlinkConfirmDialog: false }, () => { }) }
                                    }
                                ]} />
                            <ActionDialog showDialog={this.state.showUnlinkWarningDialog} title="Unlink Confirmation" description={this.state.warningDesc}
                                actions={[
                                    {
                                        color: "inherit", text: "Cancel", icon: null,
                                        callback: () => { this.setState({ showUnlinkWarningDialog: false }, () => { }) }
                                    }
                                ]} />
                            <DialogTitle disableTypography id="dialogTitle" />
                            <DialogContent>
                                <Box style={{ padding: "0px", minHeight: "70vh", maxHeight: "70vh", }}>
                                    <Grid container direction="row" justify="flex-end" alignItems="center">
                                        {LayoutService.getIconButton((this.props.isReadOnly || this.TAB_PERMISSIONS.cannotCreate), MatIconService.SEARCH, "search", () => {
                                            this.state.selectedProfile.length !== 0 ? this.setState({ showConfirmDialog: true }) : this.setState({ showSearchLinkedProfilesDialog: true })
                                        }, "inherit")}
                                        {LayoutService.getIconButton((this.props.isReadOnly || this.TAB_PERMISSIONS.cannotEdit), MatIconService.SWAP, "Promote to Parent", () => {
                                            var parentRowNode = null;
                                            var selRowNode = null;
                                            var selCount = 0;
                                            this.state.agGridUtils.gridParams.api.forEachNode((rowNode) => {
                                                if (rowNode.data["RELATIONSHIP"] === "Parent") { parentRowNode = rowNode; }
                                                if (rowNode.data["isSelected"] === true) {
                                                    selCount++;
                                                    //if (rowNode.data["RELATIONSHIP"] !== "Parent") {
                                                    selRowNode = rowNode;
                                                    //}
                                                }
                                            });

                                            if (!parentRowNode) { ToastService.showError("No Parent Node Exists"); }
                                            else if (selCount > 1) { ToastService.showWarning("Please select only one Profile to promote as Parent"); }
                                            else if (selCount !== 1) { ToastService.showWarning("Please select one child"); }
                                            else if (selRowNode.data["RELATIONSHIP"] === "Parent") { ToastService.showWarning("The selected Profile is already a Parent"); }
                                            else {
                                                parentRowNode.data["RELATIONSHIP"] = selRowNode.data["RELATIONSHIP"];
                                                selRowNode.data["RELATIONSHIP"] = "Parent";
                                                selRowNode.data["isSelected"] = false;
                                                this.state.agGridUtils.updateRows([parentRowNode.data, selRowNode.data]);//update rows in aggrid
                                                ToastService.showInfo("Promoted to parent");
                                                this.setState({ parentProfile: selRowNode.data["PRID"] });
                                            }
                                        }, "inherit",undefined, "bottom", {height:"48px"})}
                                        {LayoutService.getIconButton((this.props.isReadOnly || this.TAB_PERMISSIONS.cannotDelete), MatIconService.UNLINK, "Unlink", () => {
                                            var ids = DataService.arrayToString(this.state.agGridUtils.getUpdatedRowData().filter(x => x.isSelected).map(x => x.PORZIOGSTPROFILEID));
                                            if (ids.length === 0) {
                                                this.setState({ showUnlinkWarningDialog: true, warningDesc: "Please select at least one profile to unlink" });
                                            }
                                            else {
                                                if (this.state.selectedProfile.length !== 0) {
                                                    this.setState({ showUnlinkWarningDialog: true, warningDesc: "Please save changes before unlink" });
                                                } else {
                                                    this.setState({ profilesToBeUnlinked: ids, showUnlinkConfirmDialog: true });
                                                }
                                            }
                                        }, "primary")}
                                    </Grid>
                                    <div {...LayoutService.getAgGridStyles(320)}>
                                        <AgGridReact
                                            pagination={true}
                                            paginationPageSize={100}
                                            rowData={this.state.data}
                                            columnDefs={LinkedProfilesService.getColumnDefs(this)}
                                            frameworkComponents={this.state.agGridUtils.frameworkComponents}
                                            suppressClickEdit={true}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                ...AgGridColumnExt.getGridOptions(56),
                                                ...this.state.agGridUtils.bindInlineEditEvents(),
                                            }}
                                            onGridReady={(params) => {
                                                this.state.agGridUtils.setGridParams(params, true);
                                                this.state.agGridUtils.setEditingMode(isReadOnly);
                                            }}></AgGridReact>
                                    </div>
                                </Box>
                                <LinkedProfilesSearchDialogComponent loadedProfile={this.props.modalAgNode.porziogstprofileid}
                                    open={this.state.showSearchLinkedProfilesDialog || false}
                                    currentLinkedProfiles={this.state.currentLinkedProfiles}
                                    onClose={(_parentProfile, _selectedProfile) => {
                                        if (_selectedProfile.length !== 0) {
                                            this.setState({ showSearchLinkedProfilesDialog: false, parentProfile: _parentProfile, selectedProfile: _selectedProfile }, () => {
                                                this.fetchData();
                                            });
                                        }
                                        else {
                                            this.setState({ showSearchLinkedProfilesDialog: false })
                                        }
                                    }}
                                />
                            </DialogContent>

                            <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                                open={this.state.showProfileDetailDialog || false}
                                onClose={() => this.setState({ showProfileDetailDialog: false })} />

                        </React.Fragment>
                    );

                case ResultStatus.ERROR:
                default:
                    return (
                        <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
                            onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
                    );
            }
        }

    }


    linkSelectedProfile = (_selectedProfile) => {
        this.setState({ parentProfile: "" + _selectedProfile["prid"], selectedProfile: "" + _selectedProfile["prid"] });
        this.fetchData();
    }

    /** 2/4 Required in Parent */
    isDirtyCallback = () => {
        return false;
        // do any additional checkings if needed
        // if (this.fPropsDynamic) {
        //   return this.fPropsDynamic.dirty;
        // } else {
        //   return false;
        // }
    }

    /** 3/4 Required in Parent */
    resetCallback = () => {
        // if (this.fPropsDynamic) {
        //   this.fPropsDynamic.resetForm();
        // }
        // do any additional resetting if needed
    }

    /** 4/4 Required in Parent */
    postCallbackOBS = () => {
        //return of(null);
        // if (DataService.isNullOrUndefined(this.fPropsDynamic)) { return of(null); }
        // else {

        // }
        var dataToPost = this.state.data;

        const stringifiedJson = DataService.addEscapeCharactersToJsonString(dataToPost);
        return ApiService.postOBS(
            API_ENDPOINT.CORE,
            `/Profiles/SaveLinkedProfiles?prid=${this.state.parentProfile}&tenantId=${this.context.user.tenantId}&userId=${this.context.user.userId}`,
            stringifiedJson
        );
    }
}

/** HOC */
export default LayoutService.getHocComponenet(LinkedProfilesComponent);
