import React from "react";
import * as Yup from "yup";
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import LayoutService from "./layoutService";
import { DataService } from "./dataService";
import { ENTITY_FIELD_TYPE } from "../types/enums";
import LookupService from "./lookupService";
import { AgGridColumnExt } from "./ag-grid/agGridColumnExt";
import ToastService from "./toastService";
import { MatIconService } from "./theme/matIconService";
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";

export default class DynamicControlService {

  // #region Declarations & INIT

  static _debounceMap = new Map();
  static _controlCache = new Map();
  static onClickCallbackKey = "onClickCallbackKey";

  static CLEAR() {
    // control cache
    this._controlCache = new Map();

    // clear the debouce subscriptions
    this._debounceMap.forEach(map => {
      if (DataService.hasValue(map.subscription)) {
        map.subscription.unsubscribe();
        map.subscription = null;
      }
    });
    this._debounceMap = new Map();
  }

  //#endregion

  static caseSensitiveFieldNames = ["uslicensestatE2", "uslicensestatE3", "uslicensestatE4", "uslicensestatE5", "uslicensestatE6",
    "usstatelicensenumbeR2", "usstatelicensenumbeR3", "usstatelicensenumbeR4", "usstatelicensenumbeR5", "usstatelicensenumbeR6"];

  static getIntitialValues = (_isNew, _groupInfoMap, _initialValuesOrNull) => {
    let oRET = {};
    if (DataService.mapHasElements(_groupInfoMap)) {
      const isNew = DataService.isNullOrUndefined(_initialValuesOrNull); // determine is new or an edit
      _groupInfoMap.forEach(_groupInfo => { // each group
        if (DataService.hasElements(_groupInfo.fieldConfigs)) {
          _groupInfo.fieldConfigs.forEach(_fieldConfig => { // group->field
            let fieldName = this.getMappedFieldName(_fieldConfig);

            // till case sensitive issue is resolved we will be using this
            let caseSensitiveFieldName = this.caseSensitiveFieldNames.find(x => x.toLowerCase() === fieldName.toLowerCase());
            fieldName = DataService.isStringNullOrEmpty(caseSensitiveFieldName) ? fieldName : caseSensitiveFieldName;
            const formikFieldName = DataService.isStringNullOrEmpty(caseSensitiveFieldName) ? fieldName : caseSensitiveFieldName.toLowerCase();

            let initialValue = '';
            let isCustomField = false;

            if (DataService.hasValue(_initialValuesOrNull)) {
              if (!_fieldConfig.isCustomField) { // not custom field
                initialValue = _initialValuesOrNull[fieldName];
              } else { // custom field
                isCustomField = true;
                let customObj = (_initialValuesOrNull.customfields || []).find(x => x.FIELDID === _fieldConfig.fieldID);
                if (customObj && DataService.hasValue(customObj.FIELDVALUE)) {
                  initialValue = customObj.FIELDVALUE;
                }
              }
            }

            // if combobox and id is 0, then make it empty string
            if (_fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.LOV && initialValue === 0) { initialValue = ''; }
            else if (_fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.BOOLEAN) {
              if (isCustomField && _isNew)
                initialValue = _fieldConfig.customBoolean;

              initialValue = DataService.parseBoolean(initialValue);
            }
            else if (_fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE_TIME || _fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE) {
              if (isCustomField) {
                //_fieldConfig.customDateTime comes with an inconsistent format having 'T' appended to it hence this sloppy code. Need a better 
                //way to handle it. Lookulist has the Date in correct format but since this is called from different places not sure if it will 
                //be available always
                let fieldValue = _isNew ?
                  (_fieldConfig.customDateTime && _fieldConfig.customDateTime.indexOf('T') != -1 ?
                    _fieldConfig.customDateTime.split('T')[0] : _fieldConfig.customDateTime)
                  : initialValue;

                if (DataService.hasValue(fieldValue))
                  initialValue = DataService.getDateStringForDisplay(fieldValue);
              }
            } else { } // do nothing : keep the initial value as it is and proceed below

            // finalize initialValue (handling undefined)
            initialValue = isNew || DataService.isNullOrUndefined(initialValue) ? '' : initialValue;
            if ((_fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE || _fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.DATE_TIME)
              && DataService.isStringNullOrEmpty(initialValue)) {
              initialValue = null; // for date time
            }

            // assign the initial value
            oRET[formikFieldName] = initialValue;
          });
        }
      });
    }
    return oRET;
  }

  static getValidationSchema = (_groupInfoMap) => {
    let oRET = {};
    if (DataService.mapHasElements(_groupInfoMap)) {
      _groupInfoMap.forEach(_groupInfo => { // each group
        if (DataService.hasElements(_groupInfo.fieldConfigs)) {
          _groupInfo.fieldConfigs.forEach(_fieldConfig => { // group->field
            const fieldName = this.getMappedFieldName(_fieldConfig);
            oRET[fieldName] = this.__getValidation(_fieldConfig);
          });
        }
      });
    }
    return Yup.object().shape({ ...oRET });
  }

  static parseCustomFields = (_initialValuesOrNull) => {
    if (_initialValuesOrNull) {
      if (!Array.isArray(_initialValuesOrNull.customfields) && !DataService.isStringNullOrEmpty(_initialValuesOrNull.customfields)) {
        _initialValuesOrNull.customfields = JSON.parse(_initialValuesOrNull.customfields);
      }
    }
    return _initialValuesOrNull;
  }

  static parseErrorFields = (_initialValuesOrNull) => {
    if (_initialValuesOrNull) {
      if (!Array.isArray(_initialValuesOrNull.erroredFields) && !DataService.isStringNullOrEmpty(_initialValuesOrNull.erroredFields)) {
        _initialValuesOrNull.erroredFields = JSON.parse(_initialValuesOrNull.erroredFields);
      }
    }
    return _initialValuesOrNull;
  }

  static setErrors = (_fPropsDynamic, _initialValuesOrNull) => {
    if (_initialValuesOrNull && DataService.hasElements(_initialValuesOrNull.erroredFields)) {
      var errorsObj = {};
      _initialValuesOrNull.erroredFields.forEach(errorInfo => {
        // !!!Note: Its an object, not an array, so the keys are auto created like below
        errorsObj[(errorInfo["fieldName"] + "").toLowerCase()] = `[${errorInfo["fieldValue"]}] ` + errorInfo["businessRuleMessage"];
      });
      // errorsObj["profiletypeid"] = "wrong profile type";
      // errorsObj["physicianownership"] = "Invalid physical ownership";
      _fPropsDynamic.errors = errorsObj;
    }
  }

  static setFieldValues = (_fPropsDynamic, _object, _validate) => {
    if (_fPropsDynamic && _object) {
      const objKeys = Object.keys(_object);
      objKeys.forEach(_key => {
        _fPropsDynamic.setFieldValue(_key, _object[_key], _validate)
      });
    }
  }


  // #region CONTROLs

  static getControl(_isReadOnly, _classes, _fromikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig) {

    // if (DataService.isNullOrUndefined(this._controlCache)) { this._controlCache = new Map(); }
    // if (this._controlCache.has(_fieldConfig.entityName)) {
    if (false) {
      return this._controlCache.get(_fieldConfig.entityName);
    } else {
      var _control = null;
      switch (_fieldConfig.field_Type_ID) {
        // dropdown
        case ENTITY_FIELD_TYPE.LOV: _control = this._getDropDown(_isReadOnly, _classes, _fromikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig); break;
        // switch/checkbox
        case ENTITY_FIELD_TYPE.BOOLEAN: _control = this._getCheckBox(_isReadOnly, _classes, _fromikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig); break;
        // date-time
        case ENTITY_FIELD_TYPE.DATE:
        case ENTITY_FIELD_TYPE.DATE_TIME: _control = this._getDateTimePicker(_isReadOnly, _classes, _fromikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig); break;
        // text
        case ENTITY_FIELD_TYPE.TEXT: _control = this._getTextBox(_isReadOnly, _classes, _fromikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig, "text"); break;
        // number
        case ENTITY_FIELD_TYPE.NUMERIC:
        case ENTITY_FIELD_TYPE.DECIMAL:
          _control = this._getTextBox(_isReadOnly, _classes, _fromikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig, "number");
          break;
        // others
        default:
          console.warn("getControl() -> ControlType not Handled", _fieldConfig);
          // _control = this._getTextBox(_isReadOnly, _classes, _fromikProps, _validationSchema, _index, _fieldConfig);
          break;
        // _control = null;
      }

      // cache the control
      // if (_control !== null) { this._controlCache.set(_fieldConfig.entityName, _control); }
      // return the control
      return _control;
    }
  }

  static _getErrorClassName = (_classes, _fieldName, _initialValuesOrNull) => {
    let isWarning = false;
    const _erroredFields = _initialValuesOrNull ? (_initialValuesOrNull.erroredFields ? _initialValuesOrNull.erroredFields : []) : [];
    _erroredFields.forEach(errorInfo => {
      if (errorInfo["fieldName"].toLowerCase() === _fieldName && errorInfo["businessRuleSeverity"].toLowerCase() === "warning") {
        isWarning = true;
      }
    });
    // return
    return isWarning ? _classes.warningStyles : _classes.dialogControl;
  }

  // TextBox
  static _getTextBox = (_isReadOnly, _classes, _formikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig, _type) => {
    // this will ensure template readonly fields are readonly despite of the components edit mode
    const _isParentReadOnly = _isReadOnly;
    const _controlReadOnly = _fieldConfig.isReadOnly;
    if (!_isParentReadOnly && _controlReadOnly) { _isReadOnly = true; }

    const fieldName = this.getMappedFieldName(_fieldConfig);
    const controlClass = this._getErrorClassName(_classes, fieldName, _initialValuesOrNull);

    let inputProps = {};
    if (!_isParentReadOnly) {
      inputProps.startAdornment = DynamicControlService.getInputAdornments(true, _isParentReadOnly, _fieldConfig);
      inputProps.endAdornment = DynamicControlService.getInputAdornments(false, _isParentReadOnly, _fieldConfig);
    }

    // return
    return LayoutService.getTextBox(_isReadOnly, controlClass, _formikProps, _validationSchema,
      fieldName, _fieldConfig.label, _type, "32%", this.__getDebouncedTextFieldFormikValidatorMap(fieldName),
      _fieldConfig[DynamicControlService.onClickCallbackKey],
      () => { }, "small", inputProps, {}
    );

  }

  // DropDown
  static _getDropDown = (_isReadOnly, _classes, _formikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig) => {
    // this will ensure template readonly fields are readonly despite of the components edit mode
    const _isParentReadOnly = _isReadOnly;
    const _controlReadOnly = _fieldConfig.isReadOnly;
    if (!_isParentReadOnly && _controlReadOnly) { _isReadOnly = true; }

    const fieldName = this.getMappedFieldName(_fieldConfig);
    const controlClass = this._getErrorClassName(_classes, fieldName, _initialValuesOrNull);
    const lovOptions = _fieldConfig.customLOVList.filter(x => x.lovId !== 0); //autoSelect

    let _otherSelectOptions = {};
    if (!_isParentReadOnly) {
      _otherSelectOptions.startAdornment = DynamicControlService.getInputAdornments(true, _isParentReadOnly, _fieldConfig);
      _otherSelectOptions.endAdornment = DynamicControlService.getInputAdornments(false, _isParentReadOnly, _fieldConfig, 24);
    }

    var _defaultValue = null;
    if (_fieldConfig.autoSelect === true && DataService.hasElements(lovOptions) && lovOptions.length === 1) {
      _defaultValue = lovOptions[0]["lovId"];
    }

    return LayoutService.getDropDown(_isReadOnly, controlClass, _classes.menuPaper, _formikProps, _validationSchema,
      fieldName, _fieldConfig.label,
      lovOptions, "lovId", "localLovKey", _defaultValue,
      (_fieldConfig.hideEmpty === true ? false : true),
      DataService.hasValue(_fieldConfig.secondaryActionIcon) ? `calc(32% - 56px)` : "32%",
      () => { }, "small",
      _otherSelectOptions
    );
  }

  // DatePicker
  static _getDateTimePicker = (_isReadOnly, _classes, _formikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig) => {
    // this will ensure template readonly fields are readonly despite of the components edit mode
    if (!_isReadOnly && _fieldConfig.isReadOnly) { _isReadOnly = true; }
    const fieldName = this.getMappedFieldName(_fieldConfig);
    const controlClass = this._getErrorClassName(_classes, fieldName, _initialValuesOrNull);

    return LayoutService.getDatePicker(_isReadOnly, controlClass, _formikProps, _validationSchema,
      fieldName, _fieldConfig.label, null, null, "32%", () => { });
  }

  // Checkbox
  static _getCheckBox = (_isReadOnly, _classes, _formikProps, _initialValuesOrNull, _validationSchema, _index, _fieldConfig) => {
    // this will ensure template readonly fields are readonly despite of the components edit mode
    if (!_isReadOnly && _fieldConfig.isReadOnly) { _isReadOnly = true; }
    const fieldName = this.getMappedFieldName(_fieldConfig);
    const controlClass = this._getErrorClassName(_classes, fieldName, _initialValuesOrNull);

    return LayoutService.getCheckBox(_isReadOnly, controlClass, _formikProps, _validationSchema,
      fieldName, _fieldConfig.label, "32%", () => { });
  }

  //#endregion
  static getInputAdornments = (_atStart, _isReadOnly, _fieldConfig, _marginRight = 0) => {
    const _adornmentActions = _atStart ? _fieldConfig["startAdornmentActions"] : _fieldConfig["endAdornmentActions"];
    if (DataService.hasNoElements(_adornmentActions)) {
      return null;
    } else {
      const _position = _atStart ? "start" : "end";
      return (
        <InputAdornment position={_position} style={{ marginRight: _marginRight }}>
          {_adornmentActions.map((_action, index) => {
            const _key = `ipadorn_${_fieldConfig.fieldID}_${_position}_${index}`;
            return (
              <Tooltip key={_key} title={_action.tooltip}>
                <IconButton disabled={_isReadOnly || _action.isReadOnly} color={_action.iconColor} onClick={(e) => {
                  _fieldConfig.clickHandled = true;
                  _action.callback(_fieldConfig)
                }}>{_action.icon}</IconButton>
              </Tooltip>
            )
          })}
        </InputAdornment>
      );
    }
  }

  // #region AgGridColumns

  static getAgGridColumn(_componentThisRef, _isParentReadOnly, _colFieldConfig, _ruleSummaries, _sortableCols) {

    // create the column with basic info,
    // readonly is handled via isReadOnlyColumn Prop
    var colProps = AgGridColumnExt.GET(false, "text", _colFieldConfig.mappedFieldNameLower, _colFieldConfig.label, true);

    // if (_sortableCols && _sortableCols.includes(_colFieldConfig.mappedFieldNameLower)) {
    //   colProps.sortable(true);
    // }

    // if (_colFieldConfig.comparator !== undefined && _colFieldConfig.comparator !== null) {
    //   colProps.comparator(_colFieldConfig.comparator);
    // }

    switch (_colFieldConfig.field_Type_ID) {
      // dropdown
      case ENTITY_FIELD_TYPE.LOV:
        const lovOptions = _colFieldConfig.customLOVList.filter(x => x.lovId !== 0); // remove the -select- object with key 0
        colProps.cellRenderer("erroredDropdownCellRenderer", {
          colFieldConfig: _colFieldConfig,
          componentThisRef: _componentThisRef, isParentReadOnly: _isParentReadOnly, isColumnReadOnly: _colFieldConfig.isReadOnly,
          dataSource: lovOptions, valueProp: "lovId", displayProp: "localLovKey",
          getErrorOrWarningInfoCallback: (_cellRef) => { return this.getCellErrorOrWarning(_cellRef, _colFieldConfig, _ruleSummaries); }
        });

        // this is a callback that applies to the filter, since the value is number(id) we need to find the matching lovKey
        //---
        colProps.filterParams("text", (filterType, cellValue, filterText) => {
          cellValue = DataService.getNumberOrDefault(cellValue);
          if (!cellValue) { return false; } {
            const lov = DataService.getFirstOrDefault(lovOptions.filter(x => x.lovId === cellValue), null);
            if (!lov) { return false; } else {
              return DataService.applyFilter(filterType, lov.localLovKey, filterText);
            }
          }
        });
        // ---
        break;
      // switch/checkbox
      case ENTITY_FIELD_TYPE.BOOLEAN:
        colProps.headerClass("center-text").cellRenderer("erroredCheckboxCellRenderer", {
          colFieldConfig: _colFieldConfig,
          componentThisRef: _componentThisRef, isParentReadOnly: _isParentReadOnly, isColumnReadOnly: _colFieldConfig.isReadOnly,
          getErrorOrWarningInfoCallback: (_cellRef) => { return this.getCellErrorOrWarning(_cellRef, _colFieldConfig, _ruleSummaries); }
        });
        break;
      // date-time
      case ENTITY_FIELD_TYPE.DATE:
      case ENTITY_FIELD_TYPE.DATE_TIME:
        colProps.minWidth(128).headerClass("center-text").cellRenderer("erroredDatePickerCellRenderer", {
          colFieldConfig: _colFieldConfig,
          componentThisRef: _componentThisRef, isParentReadOnly: _isParentReadOnly, isColumnReadOnly: _colFieldConfig.isReadOnly,
          getErrorOrWarningInfoCallback: (_cellRef) => { return this.getCellErrorOrWarning(_cellRef, _colFieldConfig, _ruleSummaries); }
        });
        break;
      // text
      case ENTITY_FIELD_TYPE.TEXT:
        colProps.cellRenderer("erroredTextCellRenderer", {
          colFieldConfig: _colFieldConfig,
          inputType: "text", componentThisRef: _componentThisRef, isParentReadOnly: _isParentReadOnly, isColumnReadOnly: _colFieldConfig.isReadOnly,
          getErrorOrWarningInfoCallback: (_cellRef) => { return this.getCellErrorOrWarning(_cellRef, _colFieldConfig, _ruleSummaries); }
        });
        break;
      // number
      case ENTITY_FIELD_TYPE.NUMERIC:
      case ENTITY_FIELD_TYPE.DECIMAL:
        colProps.cellRenderer("erroredTextCellRenderer", {
          colFieldConfig: _colFieldConfig,
          inputType: "number", componentThisRef: _componentThisRef, isParentReadOnly: _isParentReadOnly, isColumnReadOnly: _colFieldConfig.isReadOnly,
          getErrorOrWarningInfoCallback: (_cellRef) => { return this.getCellErrorOrWarning(_cellRef, _colFieldConfig, _ruleSummaries); }
        },colProps.comparator(_componentThisRef.customComparator));
        break;
      // others
      default:
        console.warn("getColumn() -> ColumnType not Handled", _colFieldConfig);
        ToastService.showError("ColumnType not Handled");
        // colProps = this._getTextBox(_isReadOnly, _classes, _fromikProps, _validationSchema, _index, _colFieldConfig);
        break;
      // colProps = null;
    }

    return colProps;
  }

  static getCellErrorOrWarning = (_cellRef, _fieldConfig, _ruleSummaries) => {
    if (DataService.hasNoElements(_ruleSummaries)) {
      return {};
    } else {
      var oRET = { "value": null, "message": null, "isWarning": false };
      _ruleSummaries.forEach(ruleSummary => {
        if (ruleSummary.recordId === _cellRef.props.data.recordid && ruleSummary.fieldName.toLowerCase() === _fieldConfig.mappedFieldNameLower) {// _fieldConfig.fielD_ALIASNAME.toLowerCase()) {
          oRET = { "value": DataService.isStringNullOrEmpty(ruleSummary.fieldValue) ? "[null]" : ruleSummary.fieldValue, "message": ruleSummary.businessRuleMessage, "isWarning": ruleSummary.businessRuleSeverity === "Warning" };
          return oRET;
        }
      });
      return oRET;
    }
  }
  //#endregion

  //#region LOV's


  // Lov's to populate for both (Transaction, Profile & Agreement)
  static affiliationLovFieldIds = [29, 5, 170];
  static vendorNameLovFieldIds = [30, 171];
  static productLovFieldIds = [31, 302, 323, 324, 325, 326, 614, 615, 616, 617];
  static formLovFieldIds = [35];
  static currencyLovFieldIds = [43, 176];
  static countryLovFieldIds = [64, 80, 17, 125, 165, 501, 613];
  static materialItemNameLovFieldIds = [463];

  // 34-> EngagementRelated TODO: is returned as text, ask them to return it as boolean
  static booleanTransformFieldIds = [34];

  /**
  * ensure to call in the below in the component's componentDidMount
  *  combineLatest(LookupService.fetchCommonLookupsOBS(this.context)).subscribe();
  * @param {*} _fieldConfig
  */
  static transformFieldConfig = (_fieldConfig) => {

    // parses the label
    _fieldConfig.label = _fieldConfig.fielD_NAME;//this.__parseFieldName(_fieldConfig.fielD_NAME);
    // parses the FieldName
    _fieldConfig.mappedFieldNameLower = this.getMappedFieldName(_fieldConfig);


    if (this.booleanTransformFieldIds.includes(_fieldConfig.fieldID)) {
      _fieldConfig.field_Type_ID = ENTITY_FIELD_TYPE.BOOLEAN;
      delete _fieldConfig.fieldlength;
    }
    else if (_fieldConfig.field_Type_ID === ENTITY_FIELD_TYPE.LOV) {

      // eg: profilestatusid will become profilestatus
      if (_fieldConfig.mappedFieldNameLower.endsWith("id")) {
        _fieldConfig.lovTextColumnName = _fieldConfig.mappedFieldNameLower.substring(0, _fieldConfig.mappedFieldNameLower.length - 2); // <field> eg: profilestatus
      }
      // eg: country lov field is not suffixed by id
      else if (_fieldConfig.mappedFieldNameLower === "country") {
        _fieldConfig.lovTextColumnName = "countryname";
      }




      // COUNTRY
      if (this.countryLovFieldIds.includes(_fieldConfig.fieldID)) {
        _fieldConfig.customLOVList = this.transformToCustomLovList(LookupService._COUNTRIES, "id", "value", _fieldConfig.entityName);
      }
      // AFFILIATE
      else if (this.affiliationLovFieldIds.includes(_fieldConfig.fieldID)) {
        _fieldConfig.customLOVList = this.transformToCustomLovList(LookupService._AFFILIATIONS, "companyAffiliateId", "affiliateName", _fieldConfig.entityName);
      }
      // Currency
      else if (this.currencyLovFieldIds.includes(_fieldConfig.fieldID)) {
        _fieldConfig.customLOVList = this.transformToCustomLovList(LookupService._CURRENCIES_FORMATTED, "id", "value", _fieldConfig.entityName);
      }
      // VendorName
      else if (this.vendorNameLovFieldIds.includes(_fieldConfig.fieldID)) {
        _fieldConfig.customLOVList = this.transformToCustomLovList(LookupService._VENDOR_NAMES, "vendorId", "vendorName", _fieldConfig.entityName);
      }
      // Product
      else if (this.productLovFieldIds.includes(_fieldConfig.fieldID)) {
        _fieldConfig.customLOVList = this.transformToCustomLovList(LookupService._PRODUCTS, "id", "name", _fieldConfig.entityName);
      }
      // MaterialItemName
      else if (this.materialItemNameLovFieldIds.includes(_fieldConfig.fieldID)) {
        _fieldConfig.customLOVList = this.transformToCustomLovList(LookupService._MATERIAL_ITEM_NAMES, "materialId", "materialName", _fieldConfig.entityName);
      }

    }

    //RETURN
    return _fieldConfig;
  }

  static transformToCustomLovList = (_array, _keyProp, _textProp, _fieldName) => {
    if (DataService.hasNoElements(_array)) {
      return [];
    } else {
      return _array.map((x, index) => {
        return {
          "lovId": x[_keyProp],
          "lovKey": x[_textProp],
          "ordinal": index + 1,
          "isdefault": false,
          "fieldName": _fieldName,
          "localLovKey": x[_textProp]
        };
      });
    }
  }


  //#endregion


  // #region Internal-UTILs

  static __getValidation = (_fieldConfig) => {
    // _fieldConfig.iS_PLSREQUIRED = false;

    switch (_fieldConfig.field_Type_ID) {
      case ENTITY_FIELD_TYPE.LOV:
        {
          let oRET = LayoutService.getNullableValidation(Yup.number());
          // required
          if (_fieldConfig.iS_PLSREQUIRED) { oRET = oRET.required("Required"); }
          // return
          return oRET;
        };
      case ENTITY_FIELD_TYPE.BOOLEAN:
        {
          let oRET = LayoutService.getNullableValidation(Yup.boolean());
          // required
          if (_fieldConfig.iS_PLSREQUIRED) { oRET = oRET.required("Required"); } // required
          // return
          return oRET;
        };
      case ENTITY_FIELD_TYPE.DATE:
      case ENTITY_FIELD_TYPE.DATE_TIME:
        {
          let oRET = Yup.date().nullable(true);
          if (_fieldConfig.iS_PLSREQUIRED) { oRET = oRET.required("Required"); }  // required

          // return
          return oRET;
        };
      case ENTITY_FIELD_TYPE.TEXT:
        {
          let oRET = Yup.string();
          if (_fieldConfig.iS_PLSREQUIRED) { oRET = oRET.required("Required"); } // required
          if (_fieldConfig.fieldlength > 0) { oRET = oRET.max(_fieldConfig.fieldlength); } // length
          if (_fieldConfig.asyncValidationCallback) {
            oRET = _fieldConfig.asyncValidationCallback(oRET);
          }
          return oRET;
        };
      // number
      case ENTITY_FIELD_TYPE.NUMERIC:
      case ENTITY_FIELD_TYPE.DECIMAL:
        {
          let oRET = Yup.number()
            // Fix for Issue: https://github.com/jquense/yup/issues/500
            .nullable(true).transform((n, o) => { return (o === '' || o === "" || n === NaN) ? null : n; });
          if (_fieldConfig.iS_PLSREQUIRED) { oRET = oRET.required("Required"); } // required
          // validations
          if (DataService.hasValidValue(_fieldConfig.fieldValueDetails)) {
            const validationObj = JSON.parse(_fieldConfig.fieldValueDetails);
            if (DataService.hasValue(validationObj)) {
              if (validationObj.Min) { oRET = oRET.min(validationObj.Min, `minimum ${validationObj.Min} is only allowed`); }
              if (validationObj.Max) { oRET = oRET.max(validationObj.Max, `maximum ${validationObj.Max} is only allowed`); }
              if (validationObj.DecimalPlaces > 0) {
                oRET = oRET.test('decimal-digits', `${validationObj.DecimalPlaces} decimal places are only allowed`,
                  (value) => {
                    const strValue = (value + "");
                    if (strValue.includes(".")) {
                      const parts = strValue.split(".");
                      return parts[1].length <= validationObj.DecimalPlaces;
                    } else {
                      return true;
                    }
                  },
                );
              } else { oRET = oRET.integer(`Integers are only allowed`); }   // integer
            }
          }
          // return
          return oRET;
        };

      default:
        console.warn("_getValidation() -> ControlType not Handled", _fieldConfig);
        return null;
    }
  }

  static getMappedFieldName = (_fieldConfig) => {
    return (DataService.hasValidValue(_fieldConfig.mappedlovidfield) ? _fieldConfig.mappedlovidfield : _fieldConfig.entityName).toLowerCase();
  }

  static __parseFieldName = (_fielD_NAME) => {
    var tt = _fielD_NAME + "";
    if (tt.length > 4) {
      return tt.substring(4);
    } else {
      return tt;
    }
  }

  static __getDebouncedTextFieldFormikValidatorMap = (_fieldName) => {
    var oRET = null;
    if (this._debounceMap.has(_fieldName)) {
      oRET = this._debounceMap.get(_fieldName);
    } else {
      oRET = { subscription: null, observable: (new Subject()).pipe(debounceTime(500)) };
      // oRET = {subscription: null, observable: (new Subject()).pipe(debounceTime(500), tap(res => console.log('debounceTap:', res))) };
      oRET.subscription = oRET.observable.subscribe(({ _formikProps, _key, _value }) => {
        _formikProps.validateField(_key);
      });
      this._debounceMap.set(_fieldName, oRET);
    }
    return oRET;
  }

  //#endregion

}