
/**
 * Author : Pradeep_Rajendran@epam.com
 */
export class TemplateFieldConfigUtil {

    /**
     * holds the true column definintion
     */
    objRef = {};

    constructor(_groupRendererId, _ordinal, _filedTypeEnum, _fieldId, _fieldName, _entityName, _fieldAliasName, _tooltip, _fieldDesc) {

        this.groupRendererId(_groupRendererId);
        this.ordinal(_ordinal);
        this.fieldTypeId(_filedTypeEnum);
        this.fieldId(_fieldId);
        this.fieldName(_fieldName);
        this.entityName(_entityName);
        this.fieldAliasName(_fieldAliasName);
        this.toolTip(_tooltip);
        this.fieldDesc(_fieldDesc); 

        // optionals
        this.fieldValueDetails(null);
        this.fieldLength(256);
        this.isPlsRequired(false);
        this.isCustomField(false);
        this.lookUpList(null);
        this.mappedlOVIdField(null);
        this.mask(null);

        this.customBoolean(false);
        this.customDateTime(false);
        this.customLOVList(null);
        this.customProfileLOVList([]);
        this.customField(null);
        this.encryption(null);

    }

    /**
     * Shorthand for crearing a new instance
     */
    static GET = (_groupRendererId, _ordinal, _filedTypeEnum, _fieldId, _fieldName, _entityName, _fieldAliasName, _tooltip, _fieldDesc) => {
        return new TemplateFieldConfigUtil(_groupRendererId, _ordinal, _filedTypeEnum, _fieldId, _fieldName, _entityName, _fieldAliasName, _tooltip, _fieldDesc);
    }

    /**
      * Resolves any conflicts that are chained,
      * Finalises and returns the actual reference
      */
    SET = () => {
        return this.objRef;
    }


    ordinal = (_value) => { this.objRef["ordinal"] = _value; return this; }
    customBoolean = (_value) => { this.objRef["customBoolean"] = _value; return this; }
    customDateTime = (_value) => { this.objRef["customDateTime"] = _value; return this; }
    customfield = (_value) => { this.objRef["customfield"] = _value; return this; }

    encryption = (_value) => { this.objRef["encryption"] = _value; return this; }

    entityName = (_value) => { this.objRef["entityName"] = _value; return this; }
    fieldAliasName = (_value) => { this.objRef["fielD_ALIASNAME"] = _value; return this; }
    fieldDesc = (_value) => { this.objRef["fielD_DESCRIPTION"] = _value; return this; }
    fieldName = (_value) => { this.objRef["fielD_NAME"] = _value; return this; }
    fieldId = (_value) => { this.objRef["fieldID"] = _value; return this; }
    fieldValueDetails = (_value) => { this.objRef["fieldValueDetails"] = _value; return this; }
    fieldTypeId = (_value) => { this.objRef["field_Type_ID"] = _value; return this; }
    fieldLength = (_value) => { this.objRef["fieldlength"] = _value; return this; }

    groupRendererId = (_value) => { this.objRef["grouprenderid"] = _value; return this; }

    isPlsRequired = (_value) => { this.objRef["iS_PLSREQUIRED"] = _value; return this; }
    isCustomField = (_value) => { this.objRef["isCustomField"] = _value; return this; }
    customField = (_value) => { this.objRef["customfield"] = _value; return this; }
    mask = (_value) => { this.objRef["mask"] = _value; return this; }

    ordinal = (_value) => { this.objRef["ordinal"] = _value; return this; }
    toolTip = (_value) => { this.objRef["toolTip"] = _value; return this; }

    isHidden = (_value = true) => { this.objRef["isHidden"] = _value; return this; }
    isVisible = (_value = true) => { return this.isHidden(!_value); }

    isReadOnly = (_value = true) => { this.objRef["isReadOnly"] = _value; return this; }
    isEditable = (_value = true) => { return this.isHidden(!_value); }

    autoSelect = (_value) => { this.objRef["autoSelect"] = _value; return this; }
    hideEmpty = (_value = true) => { this.objRef["hideEmpty"] = _value; return this; }

    mappedlOVIdField = (_value) => { this.objRef["mappedlovidfield"] = _value; return this; }
    lookUpList = (_value) => { this.objRef["lookUpList"] = _value; return this; }
    customLOVList = (_value) => { this.objRef["customLOVList"] = _value; return this; }
    customProfileLOVList = (_value) => { this.objRef["customProfileLOVList"] = _value; return this; }

    /**
     * 
     * @param {*} _icon 
     * @param {*} _tooltip 
     * @param {*} _onClick
     * @returns 
     */
    secondaryAction = (_icon, _tooltip, _onClick = () => { }) => {
        this.objRef["secondaryActionIcon"] = _icon;
        this.objRef["secondaryActionTooltip"] = _tooltip;
        this.objRef["onSecondaryActionClick"] = _onClick;
        return this;
    }

    endAdornmentActions = (_value) => { this.objRef["endAdornmentActions"] = _value; return this; }


    //---
}