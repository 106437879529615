import ApiService from "../../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../../shared/types/enums"
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";

export default class AgreementAdvancedSearchService {

    static searchOBS = (_tenantId, _searchValue = "") => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Agreement/SearchAgreements/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: "",
            firstName: "",
            lastName: "",
            organizationName: "",
            companyId: "",
            country: "",
            isAdvance: true
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            AgGridColumnExt.GET(false, "text", "porzioGstProfileId", "Porzio GST Profile ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "number", "companyProfileId", "Company Profile ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porzioGstAgreementId", "Porzio GST Agreement ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("agreementDetailCellRendererComponent").SET(),
            AgGridColumnExt.GET(false, "text", "companyAgreementId", "Company Agreement ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            // AgGridColumnExt.GET(false, "text", "agreementType", "Agreement Type").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            // AgGridColumnExt.GET(false, "text", "startDate", "Start Date").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            // AgGridColumnExt.GET(false, "text", "endDate", "End Date").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "fileName", "File Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "affiliatedCompany", "Affiliated Company").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "set", "sourceName", "Source").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "agreementStatus", "Agreement Status").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "middleName", "Middle Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "profileCategory", "Profile Category").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "profileType", "Profile Type").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "credentials", "Credentials").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usnpiNumber", "US NPI Number").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "usLicenseState", "US License State").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usStateLicenseNumber", "US License Number").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "agreementConsent", "Agreement Consent").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "city", "City").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "province", "Province/State").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "country", "Country").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
        ];
    }
}