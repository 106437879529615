import React, { Component, createRef } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";
import { DataService } from "../../services/dataService";

export default class AgGridDropdownWithStatusCellRenderer extends Component {
    link = createRef();

    constructor(props) {
        super(props);
    }

    render() {

        const _disabled = AgGridUtil.isEditingDisabled(this.props);

        return (
            <div style={{ display: "flex" }}>
                <Select
                    style={{ minWidth: this.props.showStatusCallBack ? `calc(100% - 32px)` : "100%" }}
                    disabled={_disabled}
                    value={DataService.isNullOrUndefined(this.props.value) ? "" : this.props.value}
                    onChange={(params) => {
                        const _value = params.target.value;
                        this.props.node.setDataValue(this.props.colDef.field, _value);
                        if (this.props.onChangeCallback) { this.props.onChangeCallback(this, this.props); }
                    }}
                >
                    {/* show or hide empty */}
                    {this.props.showEmpty ? (<MenuItem value={this.props.emptyValue || ""}><em>-select-</em></MenuItem>) : null}

                    {/* options */}
                    {this.props.options.map((option) => (
                        <MenuItem key={option[this.props.optionKey]} value={option[this.props.optionKey]}>
                            {option[this.props.optionLabel]}
                        </MenuItem>
                    ))}
                </Select>
                {/* Status Icon if any */}
                {(this.props.showStatusCallBack && this.props.showStatusCallBack(this)) ? <div style={{ paddingLeft: 4 }}>{this.props.statusIcon}</div> : null}
            </div>
        );
    }
}
