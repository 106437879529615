import React from 'react';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Toolbar, Typography } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";

class AgreementDetailDeleteConfirmComponent extends React.Component {
    render() {
        const { classes } = this.props;

        return (
                <Dialog
                    open={this.props.open || false}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" className={classes.root}>Delete Confirmation</Typography>
                                {LayoutService.getIconButton(false, MatIconService.OK, "Delete", this.props.handleDeleteConfirm, "inherit", "keyActionDelete")}
                                {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", this.props.handleDeleteDismiss, "secondary", "keyActionCancel")}
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, }} >
                            <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" className={classes.root} align="center">Are you sure you want to delete the selected Agreement?</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6"className={classes.root} align="center">{this.props.porzioGstAgreementId}</Typography>
                                    </Grid>
                            </Grid>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
        );
    }
}

export default LayoutService.getHocComponenet(AgreementDetailDeleteConfirmComponent);