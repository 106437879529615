import { AgGridColumnExt } from "../../../services/ag-grid/agGridColumnExt";
import ApiService from "../../../services/apiService"
import { API_ENDPOINT } from "../../../types/enums"

export default class AgreementsSearchDialogService {

    static searchOBS = (_tenantId, _searchValue) => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Agreement/SearchAgreements/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: null,
            firstName: null,
            lastName: null,
            organizationName: null,
            companyId: null,
            country: null,
            porzioGSTProfileId:null
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            {
                headerName: "Select",
                field: "isSelected",
                editable: true,
                width: 176,
                cellStyle: { "border-right": "1px solid lightgray" },
                cellRenderer: "isSelectedCellRenderer",
                cellRendererParams: { onChange: (_cellRef, _data) => { _onSelectCallback(_data); } }
            },
            AgGridColumnExt.GET(false, "text", "porzioGstAgreementId", "Porzio GST Agreement ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("agreementDetailCellRendererComponent").SET(),
            AgGridColumnExt.GET(false, "text", "agreementType", "Agreement Type").flexWeight(1).headerClass("center-text").centerAlignText().SET(),//TODO: pending
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "country", "Country").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "startDate", "Agreement Start Date").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "endDate", "Agreement End Date").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
        ];
    }
}