import ApiService from "../../../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../../../shared/types/enums"
import { AgGridColumnExt } from "../../../../../shared/services/ag-grid/agGridColumnExt";

export default class ReferenceDataSearchDialogService {

    static searchReferenceData = (_tenantId, _isIndividualProfileCategory, _formikProps, _isNppes) => {
        if (!_isNppes) {
            const vPlAndNPPL = "VPL and NPPL", teachingHospital = "TEACHING HOSPITAL";
            let searchOption = _isIndividualProfileCategory ? vPlAndNPPL : teachingHospital;
            let parentObjPropName = _isIndividualProfileCategory ? 'vplAndNppl' : 'teachingHospital';
            let obj = _formikProps.values; //_formikProps.values[parentObjPropName];
            let formFieldParams = "";
            obj = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
            for (const property in obj) {
                formFieldParams += `${property}=${obj[property]},`;
            }
            formFieldParams = formFieldParams.replace(/(^,)|(,$)/g, "");

            let url = `/Profiles/GetReferenceDataGetSearchDataByParam?tenantId=${_tenantId}&searchOption=${searchOption}&searchSequence=${encodeURIComponent(formFieldParams)}&pageNumber=1&rowsPerPage=5000`;
            return ApiService.getOBS(API_ENDPOINT.CORE, url);
        } else {
            const individual = "Individual", organization = "Organization";
            let searchOption = _isIndividualProfileCategory ? individual : organization;
            let parentObjPropName = _isIndividualProfileCategory ? 'individual' : 'organization';
            let obj = _formikProps.values; //_formikProps.values[parentObjPropName];
            let formFieldParams = "";
            obj = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
            for (const property in obj) {
                formFieldParams += `${property}=${obj[property]},`;
            }
            formFieldParams = formFieldParams.replace(/(^,)|(,$)/g, "");

            let url = `/Profiles/GetReferenceDataGetSearchDataByParam_Nppes?tenantId=${_tenantId}&searchOption=${searchOption}&searchSequence=${encodeURIComponent(formFieldParams)}&pageNumber=1&rowsPerPage=5000`;
            return ApiService.getOBS(API_ENDPOINT.CORE, url);
        }
    }

    static getColumnDefs = (_componentThisRef, isIndividualProfileCategory, _onSelectCallback, _isNppes) => {
        let colDefs = [
            {
                headerName: "Select",
                field: "isSelected",
                editable: true,
                width: 176,
                cellStyle: { "border-right": "1px solid lightgray" },
                cellRenderer: "isSelectedCellRenderer",
                cellRendererParams: {
                    onChange: (_cellRef, _data) => { _onSelectCallback(_data); }
                }
            }];
        if (isIndividualProfileCategory) {
            colDefs.push(
                AgGridColumnExt.GET(false, "text", "SOURCE_FILE", "Source File").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                AgGridColumnExt.GET(false, "text", "NPI_ID", "NPI ID").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                AgGridColumnExt.GET(false, "text", "FIRST_NAME", "First Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                AgGridColumnExt.GET(false, "text", "MIDDLE_NAME", "Middle Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                AgGridColumnExt.GET(false, "text", "LAST_NAME", "Last Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                AgGridColumnExt.GET(false, "text", "SUFFIX", "Suffix").flexWeight(1).headerClass("center-text").centerAlignText().SET(), 
                AgGridColumnExt.GET(false, "text", "PRMRY_TXNMY_CODE", "Prmry Txnmy Code").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                AgGridColumnExt.GET(false, "text", "PRMRY_TXNMY_PRVDR_TYPE", "Prmry Txnmy Prvdr type").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                );
               
            if (!_isNppes) {
                        colDefs.push(
                        AgGridColumnExt.GET(false, "text", "STATE_1", "State 1").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                        AgGridColumnExt.GET(false, "text", "LICENSE_1", "License 1").flexWeight(1).headerClass("center-text").centerAlignText().SET(), 
                        AgGridColumnExt.GET(false, "text", "STATE_2", "State 2").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                        AgGridColumnExt.GET(false, "text", "LICENSE_2", "License 2").flexWeight(1).headerClass("center-text").centerAlignText().SET(), 
                        AgGridColumnExt.GET(false, "text", "STATE_3", "State 3").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                        AgGridColumnExt.GET(false, "text", "LICENSE_3", "License 3").flexWeight(1).headerClass("center-text").centerAlignText().SET(), 
                        AgGridColumnExt.GET(false, "text", "STATE_4", "State 4").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                        AgGridColumnExt.GET(false, "text", "LICENSE_4", "License 4").flexWeight(1).headerClass("center-text").centerAlignText().SET(), 
                        AgGridColumnExt.GET(false, "text", "STATE_5", "State 5").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                        AgGridColumnExt.GET(false, "text", "LICENSE_5", "License 5").flexWeight(1).headerClass("center-text").centerAlignText().SET(), 
                    );
             }
             else {
                colDefs.push(
                    AgGridColumnExt.GET(false, "text", "state", "State").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "license", "License").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                );
             }
        } else {
            if (!_isNppes) {
                colDefs.push(
                    AgGridColumnExt.GET(false, "text", "SOURCE_FILE", "Source File").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "TH_YEAR", "TH Year").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "TIN", "TIN").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "HOSPITAL_NAME", "Hospital Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "PECOS_Legal_Business_Name", "PECOS Legal Business Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "Street_Address", "Street Address").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "PO_Box", "PO Box").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "City", "City").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "State", "State").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "Zip_Code", "Zip Code").flexWeight(1).headerClass("center-text").centerAlignText().SET()
                );
            } else {
                colDefs.push(
                    AgGridColumnExt.GET(false, "text", "SOURCE_FILE", "Source File").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "NPI_ID", "NPI").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "HOSPITAL_NAME", "Organization Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    // Remove below line as per PP2-949
                    //AgGridColumnExt.GET(false, "text", "PECOS_Legal_Business_Name", "PECOS Legal Business Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "Street_Address", "Street Address").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "PO_Box", "PO Box").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "City", "City").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "State", "State").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
                    AgGridColumnExt.GET(false, "text", "Zip_Code", "Zip Code").flexWeight(1).headerClass("center-text").centerAlignText().SET()
                );
            }
        }
        return colDefs;
    }
}