import ApiService from "../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../shared/types/enums"
import { Subject } from 'rxjs';

export default class AdvancedSearchContainerService {
    static advancedSearchSubject = new Subject();

    static fetchFilterData = (_tenantId) => {
        return ApiService.getOBS(
            API_ENDPOINT.CORE,
            `/AdvancedSearch/FetchAdvSearchFilterDetails/${_tenantId}`
        );
    }
}