import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { ResultStatus, CrudAction } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { withStyles } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import MaterialsListService from "./materialsListService";
import IsActiveIconRendererComponent from "../../shared/components/ag-grid/isActiveIconRendererComponent";
import ChildMessageRendererComponent from "../../shared/components/childMessageRendererComponent";
import { Dialog } from "@material-ui/core";
import MaterialDetailsComponent from "./material-details/materialDetailsComponent";
import LayoutService from "../../shared/services/layoutService";
import LookupService from "../../shared/services/lookupService";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";

class MaterialsListComponent extends Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = MaterialsListService;
  constructor(props) {
    super(props);
    this.fetchData.bind(this);
    this.handleHide = this.handleHide.bind(this);

    this.state = {
      rowData: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      modalShow: false,
      modalAgNode: null,
      inputAction: CrudAction.NONE,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, rowData: [] });

    this.oSubscriptions.add(
      combineLatest([
        this.STORE.getObs(_ignoreCache, this.context),
        LookupService.getFormattedCurrenciesAsOBS(this.context, null),
      ]).subscribe(
        ([_data, _currencyList]) => {
          if (!Array.isArray(_data)) {
            _data = new Array(_data);
          }
          _data.sort(this.compareFn);
          this.STORE.CACHE.SET(_data, 0);
          this.setState(
            {
              rowData: _data.map(r => (parseInt(r.currencyId) ? { ...r, currencyName: _currencyList.find(c => c.id === r.currencyId).value } : "")),
              currencyList: [{ id: 0, value: "Select" }, ..._currencyList],
            },
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        () => {
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });

        }));
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  };
  methodFromParent = (cell, node) => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.UPDATE });
    this.setState({ modalAgNode: node });
  };

  handleCreateModal = () => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.CREATE });
    this.setState({ modalAgNode: { srId: 0 } });
  };

  handleHide() {
    this.setState({ modalShow: false });
  }
  render() {
    const { classes } = this.props;
    if (RolePermissionService.MATERIAL.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent classes={classes} label="Loading Materials" />
          );
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <div id="MainRoleGrid">
              <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>
              <div className="IconBg marginTop10px tableTitleHead">
                {<PageDynamicHeaderComponent classes={classes} label="Materials Info" rightPadding={16}
                  leftActions={[{ icon: MatIconService.BACK, title: "Back", iconColor: "secondary", onClick: this.props.history.goBack }]}
                  rightActions={[
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.handleCreateModal, isReadOnly: RolePermissionService.MATERIAL_DETAIL.cannotCreate },
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                />}
              </div>
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.rowData}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    tooltipShowDelay: 0,
                    context: { componentParent: this },
                  }}
                  onGridReady={this.onGridReady}
                ></AgGridReact>
              </div>
              <Dialog
                open={this.state.modalShow || false}
                scroll={true ? "paper" : "body"}
                maxWidth="md"
              >
                <MaterialDetailsComponent
                  onClose={this.handleHide}
                  modalAgNode={this.state.modalAgNode}
                  inputAction={this.state.inputAction}
                  refreshGrid={this.fetchData}
                />
              </Dialog>
            </div>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              label="Error Loading Materials"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }
  }
}

export default LayoutService.getHocComponenet(MaterialsListComponent);
