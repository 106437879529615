import React from "react";
import Radio from '@material-ui/core/Radio';
import { DataService } from "../../services/dataService";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";

export default class AgGridRadioButtonCellRendererComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isChecked: this.props.data[this.props.colDef.field] };
    }

    render() {
        const specialObj = this.props.data[AgGridUtil.specialProperty];
        const notHasSpecialProperty = DataService.isNullOrUndefined(specialObj);
        const isEditing = notHasSpecialProperty ? true : specialObj[AgGridUtil.editingProperty] === true;
        const isReadOnly = notHasSpecialProperty ? false : specialObj[AgGridUtil.readOnlyProperty] === true;

        return (<Radio disabled={isReadOnly || !isEditing} color="secondary" size="small" style={{ padding: 0 }}
            checked={this.state.isChecked} onClick={() => { this.onStateChangeRequested(); }}
        />);
    }

    onStateChangeRequested = () => {
        if (!this.state.isChecked || this.props.allowUnCheck) { // act only if not checked
            // allowUncheck caused this to fire, then inverse the state, else the above condition logic, so true
            const _newState = this.props.allowUnCheck ? !this.state.isChecked : true;

            var itemsToUpdate = [];

            const fieldName = this.props.colDef.field;
            this.props.api.forEachNodeAfterFilterAndSort((rowNode, rowIndex) => {
                // TODO: rowIndex will not be correct if sorted, so search this app for sortAndFilterAwareRowNode and see the impl
                var rowData = rowNode.data;
                if (rowIndex != this.props.rowIndex && rowData[fieldName]) {
                    rowData[fieldName] = false; // cancel otehrs
                    itemsToUpdate.push(rowData); // mark for update
                }
            });

            this.props.data[fieldName] = _newState; // update current
            itemsToUpdate.push(this.props.data); // mark current for update

            // update
            if (DataService.hasElements(itemsToUpdate)) {
                var res = this.props.api.applyTransaction({ update: itemsToUpdate });
                if (this.props.onChange) { // call the callback if exists
                    this.props.onChange(this, this.props.data);
                }
            }
        }
    }

}