import React from "react";
import {
  withStyles,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
} from "@material-ui/core";
import { from, BehaviorSubject } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";

import { AuthContext } from "../../../../shared/store/authProvider";
import { ResultStatus } from "../../../../shared/types/enums";
import LayoutService from "../../../../shared/services/layoutService";

import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { SubscriptionArray } from "../../../../shared/services/dataService";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import DataTransactionService from "./dataTransactionService";

const fetchProfilesBySearchBoxText = async (searchBoxParams) => {
  const rowData = await fetch(
    `${process.env.REACT_APP_CORE_HTTP}/Profiles/GetProfileCount`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourceid: searchBoxParams.sourceId,
        fileid: String(searchBoxParams.fileId),
        healthindicator: "",
        tenantid: searchBoxParams.user.tenantId,
        searchvalue: searchBoxParams.newValue,
        pageNumber: 1,
        rowsperPage: 250,
        isActive: 1,
        screenId: 0,
        userId: searchBoxParams.user.userId,
        searchString: "",
        taskid: 0,
        source: "Profiles"
      }),
    }
  ).then((result) => result.json());
  return rowData;
};
let searchSubject = new BehaviorSubject("");
let SearchResultObservable = searchSubject.pipe(
  filter((val) => val.hasOwnProperty('newValue')),
  debounceTime(750),
  distinctUntilChanged(),
  mergeMap((val) => from(fetchProfilesBySearchBoxText(val)))
);

class DataTransactionComponent extends React.Component {
  static contextType = AuthContext;

  oSubscriptions = new SubscriptionArray();
  STORE = DataTransactionService;
  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      porzioSearch: "",
      // frameworkComponents: {
      //   // dataCountCellRendererComponent: DataCountCellRendererComponent,
      // },
    };
    this.fetchData.bind(this);
    this.handleFilterChange.bind(this);
    this.columnMap = {
      trid: "Company Transaction ID",
      porziogsttransactionid: "Porzio GST Transaction ID",
      form: "Form",
      purpose: "Purpose",
      transactiondate: "Transaction Date",
      convertedtotalamount: "Converted Total Amount",
      transactionconsent: "Transaction Consent",
      transactiondocuments: "Transaction Documents",
    };
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
    this.subscription = SearchResultObservable.subscribe((searchResultJson) => {
      this.setState({ data: searchResultJson });
    });
  }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.props.modalAgNode, this.context).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          this.STORE.CACHE.SET(o, 0);
          this.setState({
            data: o,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          console.log("Error:", o);
          // onResultStatus.ERROR
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };
  handleFilterChange = () => {
    fetch(`${process.env.REACT_APP_BASE_HTTP}/Profiles/${this.context.user.tenantId}/FetchProfiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pageNum: 1,
        pageSize: 25,
        source: "Profiles",
        searchBoxText: "", //searchBoxText,
        recycle: "1",
        sourceId: 24,
        permissions: null,
        userType: 3,
        fileId: 5069,
        healthIndicatorId: null,
        SearchString: "",
        UserId: this.context.user.userId,
        ScreenId: 43,
        TaskId: 0,
      }),
    })
      .then((result) => result.json())
      .then((rowData) => this.setState({ data: rowData }))
      .then((rowData) => console.log(rowData))
      .catch((err) => console.log(err));
  };
  handleSearchChange = (e) => {
    const newValue = e.target.value;
    this.setState({ porzioSearch: newValue });
    searchSubject.next({ newValue, ...this.props.modalAgNode, ...this.context });
  };

  render() {
    const { classes } = this.props;
    console.log("render", this.state.data);

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            small
            classes={classes}
            label="Loading Transaction Expanded View"
          />
        );
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            <DialogTitle disableTypography id="dialogTitle">
              {/* <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6" className={classes.root}>
                    Profile Transaction Details
                  </Typography>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>{MatIconService.SEARCH}</div>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                      value={this.state.porzioSearch}
                      onChange={this.handleSearchChange}
                    />
                  </div>
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      this.props.onClose(true);
                    }}
                  >
                    {MatIconService.OK}
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      this.props.onClose(false);
                    }}
                  >
                    {MatIconService.CANCEL}
                  </IconButton>
                </Toolbar>
              </AppBar> */}
            </DialogTitle>
            <DialogContent>
              <Box
                style={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 8,
                  paddingBottom: 32,
                  minHeight: "60vh",
                  maxHeight: "60vh",
                  minWidth: "150vh",
                  maxWidth: "150vh",
                }}
              >
                <div {...LayoutService.getAgGridStyles(550, 100, "60%")} >
                  <AgGridReact
                    columnDefs={this.STORE.getColumnDefs(this)}
                    rowData={this.state.data}
                    pagination={true}
                    paginationPageSize={100}
                    // frameworkComponents={this.state.frameworkComponents}
                    gridOptions={{
                      context: { componentParent: this },
                    }}
                    onGridReady={event => { event.api.sizeColumnsToFit() }}
                  ></AgGridReact>
                </div>
              </Box>
            </DialogContent>
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent
            small
            label="Error Loading Profile Transaction Details"
            classes={classes}
            onRetry={() => {
              this.fetchData(true);
            }}
          />
        );
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(DataTransactionComponent);
