import React from "react";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import { withStyles, Typography, List, ListItem, ListItemText, ListItemIcon, Divider, Dialog, } from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import { SubscriptionArray } from "../../../shared/services/dataService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import NewsFeedService from "./newsFeedService";
import NewsfeedDetailsComponent from "./news-feed-details/newsFeedDetailsComponent";
import NewsfeedSummaryComponent from "./news-feed-details/newsFeedSummaryComponent";
import { combineLatest } from "rxjs";
import LookupService from "../../../shared/services/lookupService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class NewsFeedComponent extends React.Component {

  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = NewsFeedService;

  constructor(props) {
    super(props);

    this.state = {
      fetchResult: ResultStatus.NOT_LOADED,
      data: [],
      modalShow: false,
      summaryModalShow: false,
      inputAction: CrudAction.CREATE,
      updateData: null,
      newsfeedTypeList: [],
    };
    // bindings
    this.fetchData.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      combineLatest([
        this.STORE.getObs(_ignoreCache, this.context),
        LookupService.getNewsfeedTypesAsOBS(this.context),
      ])
        .subscribe(
          ([o, _newsfeedTypeList]) => {
            // onResultStatus.SUCCESS
            if (!Array.isArray(o)) {
              o = new Array(o);
            }
            this.STORE.CACHE.SET(o);
            this.setState({
              data: o,
              newsfeedTypeList: _newsfeedTypeList,
              fetchResult: ResultStatus.SUCCESS,
            });
          },
          (o) => {
            console.log("Error:", o);
            // onResultStatus.ERROR
            this.setState({
              fetchResult: ResultStatus.ERROR,
            });
          }
        )
    );
  };

  handleCreateModal = () => {
    this.setState({
      modalShow: true,
      inputAction: CrudAction.CREATE,
      updateData: null,
    });
  };
  handleHide() {
    this.setState({ modalShow: false });
  }
  handleUpdate = (_updateData) => {
    this.setState({
      modalShow: true,
      inputAction: CrudAction.UPDATE,
      updateData: _updateData,
    });
  }
  handleSummaryShow = (_updateData) => {
    this.setState({ summaryModalShow: true, updateData: _updateData })
  }
  handleSummaryHide = () => {
    this.setState({ summaryModalShow: false })
  }
  /**Render */
  render() {
    const { classes } = this.props;
    if (RolePermissionService.NEWSFEED.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent small classes={classes} label="Loading Newsfeed" />
          );
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <PageDynamicHeaderComponent classes={classes} label="Newsfeed" divider rightPadding={16}
                leftActions={[
                  { icon: MatIconService.MENU, onClick: () => { }, title: "Menu", iconColor: "primary" },
                ]}
                rightActions={[
                  { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Create", iconColor: "secondary", onClick: this.handleCreateModal, isReadOnly: RolePermissionService.NEWSFEED_DETAIL.cannotCreate },
                  { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                ]}
              />

              {/* Component Body */}
              <List dense={MatSpecService.useDenseLayout} style={{ maxHeight: 'calc(90vh - 102px)', overflow: 'auto' }}>
                {this.state.data.map((o, index, array) => (
                  <React.Fragment key={LayoutService.getKey(o.newsfeedId)}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon onClick={() => this.handleUpdate(o)}>{MatIconService.NEWS_FEED}</ListItemIcon>
                      <ListItemText onClick={() => this.handleSummaryShow(o)} primary={this.state.newsfeedTypeList.find(x => x.newsfeedTypeId === o.newsfeedTypeId)?.newsfeedTypeText}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body1" className={classes.inline} color="textPrimary">{o.newsfeedTitle}</Typography><br />
                            <Typography component="span" variant="body2" className={classes.inline} color="textSecondary">{o.newsfeedStartDate.substring(0, 10)}</Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                ))}
              </List>
              <Dialog open={this.state.modalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
                <NewsfeedDetailsComponent onClose={this.handleHide}
                  onRefresh={() => { this.fetchData(true); }}
                  inputAction={this.state.inputAction}
                  updateData={this.state.updateData}
                />
              </Dialog>
              <Dialog open={this.state.summaryModalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
                <NewsfeedSummaryComponent onClose={this.handleSummaryHide} updateData={this.state.updateData} />
              </Dialog>
            </React.Fragment>
          );
        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent small label="Error Loading Newsfeed" classes={classes} onRetry={() => { this.fetchData(true); }} />
          );
      }
    }
  }
}
/** HOC */
export default withStyles(MatClassService)(NewsFeedComponent);