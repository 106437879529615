import React, { Component } from "react";
import { IconButton, } from "@material-ui/core";
import { MatIconService } from "../../shared/services/theme/matIconService";

export default class ReportLaunchAgGridRenderer extends Component {
    invokeParentSaveReport = () => {
        this.props.context.componentParent.handleSaveReport(
            `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`, this.props.node.data
        );
    }

    render() {
        let isCellDisabled = false;
        if (this.props.isCellEnabledCallback && !this.props.isCellEnabledCallback(this)) {
            isCellDisabled= true;
        }
        return (
            <>
                <IconButton disabled={isCellDisabled}  color="inherit" onClick={this.invokeParentSaveReport}>{MatIconService.PERM_DATA_SETTING}</IconButton>
            </>);
    }
}
