import React, { Component } from "react";
import { withStyles, Dialog } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../shared/store/authProvider";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import AgGridActionCellRendererComponent from "../../shared/components/ag-grid/agGridActionCellRendererComponent";
import DownloadFileAgRenderer from "./download-file/downloadFileAgRenderer";
import DataCenterFileStatusImageRenderer from "./dataCenterFileStatusImageRenderer";
import DataExpandedViewComponent from "./expanded-view/dataExpandedViewComponent";
import DataCountCellRendererComponent from "./expanded-view/dataCountCellRendererComponent";
import DataErrorCellRendererComponent from "./expanded-view/dataErrorCellRendererComponent";
import DataWarningCellRendererComponent from "./expanded-view/dataWarningCellRendererComponent";
import DataUnmatchedCellRendererComponent from "./expanded-view/dataUnmatchedCellRendererComponent";
import DataCenterImportSummaryRenderer from "./dataCenterImportSummaryRenderer";
import { SubscriptionArray } from "../../shared/services/dataService";
import { ResultStatus, ENTITY_TYPE } from "../../shared/types/enums";
import { MatClassService } from "../../shared/services/theme/matClassService";
import AddEditCommentComponent from "../../shared/components/add-edit-comments/addEditCommentComponent";
import DataCenterService from "./dataCenterService";
import ImportTemplateComponent from "./import-template/importTemplateComponent";
import UploadFileComponent from "./upload-file/uploadFileComponent";
import FileStatusComponent from "./file-status/fileStatusComponent";
import LayoutService from "../../shared/services/layoutService";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import ToastService from "../../shared/services/toastService";
import FileSummaryComponent from "../data-center/file-summary/fileSummaryComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";

class dataCenterComponent extends Component {
  static contextType = AuthContext;

  oSubscriptions = new SubscriptionArray();
  STORE = DataCenterService;
  state = {
    data: [],
    fetchResult: ResultStatus.NOT_LOADED,
    frameworkComponents: {
      actionCellRendererComponent: AgGridActionCellRendererComponent,
      downloadFileAgRenderer: DownloadFileAgRenderer,
      dataCenterFileStatusImageRenderer: DataCenterFileStatusImageRenderer,
      dataCountCellRendererComponent: DataCountCellRendererComponent,
      dataErrorCellRendererComponent: DataErrorCellRendererComponent,
      dataWarningCellRendererComponent: DataWarningCellRendererComponent,
      dataUnmatchedCellRendererComponent: DataUnmatchedCellRendererComponent,
      dataCenterImportSummaryRenderer: DataCenterImportSummaryRenderer,
    },
    createUsermodalShow: false,
    personalSettingsModalShow: false,
    showDialog: false,
    openImportTemplateDialog: false,
    openUploadDialog: false,
    openDeleteFile: false,
    openCommentFile: false,
    agCellData: null,
  };

  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.handleCreateUserModal = this.handleCreateUserModal.bind(this);
    this.handleHideCreateUserModal = this.handleHideCreateUserModal.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  handleImportTemplateDialogOpen(event) {
    this.setState({ openImportTemplateDialog: true });
  }
  handleImportTemplateDialogClose = (event) => {
    this.setState({ openImportTemplateDialog: false });
  };

  handleUploadDialogOpen(event) {
    this.setState({ openUploadDialog: true });
  }
  handleUploadDialogClose = (event) => {
    this.setState({ openUploadDialog: false });
  };

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          var o2 = o.map((r) => {
            return ({
              ...r,
              errorCount: r.errorCount ?? 0,
              warningCount: r.warningCount ?? 0,
              unmatchedCount: r.unmatchedCount ?? 0,
            });
          });

          this.STORE.CACHE.SET(o2, 0);
          this.setState({
            data: o2,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          console.log("Error:", o);
          // onResultStatus.ERROR
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  };
  methodFromParent = (cell, data) => {
    this.setState({ personalSettingsModalShow: true, userId: data.userId });
  };

  // these are called by the triple-dot Action Menu
  handleDeleteFile = (_cellRef) => {
    this.setState({
      openDeleteFile: true,
      agCellData: _cellRef.props.data,
    });
  }
  handleAddEditCommentFile = (_cellRef) => {
    this.setState({
      openCommentFile: true,
      agCellData: _cellRef.props.data,
    });
  }

  handleCreateUserModal() {
    console.log("handling modal");
    console.log(this.state.createUsermodalShow);
    this.setState({ createUsermodalShow: true }, () => {
      console.log(this.state.createUsermodalShow);
    });
  }
  handleHideCreateUserModal() {
    this.setState({ createUsermodalShow: false });
  }

  handleHide() {
    this.setState({ personalSettingsModalShow: false });
  }

  handleDownloadAg = async (cell, agNodeData, filename, linkRefAg, windowUrl) => {
      const result = await fetch(
      `${process.env.REACT_APP_CORE_HTTP}/File/Download/${this.context.user.tenantId}/${agNodeData.sourceId}/${agNodeData.fileId}/${encodeURIComponent(filename)}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${this.context.authToken}` },
      }
    );
    const blob = await result.blob();
    return blob;
  };
  canNotDownloadFile = (_download) => {
    this.setState({ download: _download });
  }

  dataFileStatusMethodFromParent(cell, node) {
    ToastService.showWarning("Under Contruction");
  }

  dataImportSummaryMethodFromParent(cell, node) {
    //ToastService.showWarning("Under Contruction");
    this.setState({ showSummaryDialog: true });
    this.setState({ modalAgNode: node });
  }

  dataCountMethodFromParent(cell, node) {
    this.setState({ healthIndicator: "" });
    this.setState({ showDataExpandedViewDialog: true });
    this.setState({ modalAgNode: node });
  }

  handleDataExpandedViewHide = () => {
    this.setState({ showDataExpandedViewDialog: false });
  };

  dataErrorMethodFromParent(cell, node) {
    this.setState({ healthIndicator: "1" });
    this.setState({ showDataExpandedViewDialog: true });
    this.setState({ modalAgNode: node });
  }

  dataWarningMethodFromParent(cell, node) {
    this.setState({ healthIndicator: "2" });
    this.setState({ showDataExpandedViewDialog: true });
    this.setState({ modalAgNode: node });
  }

  dataUnmatchedMethodFromParent(cell, node) {
    this.setState({ healthIndicator: "3" });
    this.setState({ showDataExpandedViewDialog: true });
    this.setState({ modalAgNode: node });
  }

  fileStatusMethodFromParent(cell, node) {
    this.setState({ showStatusDialog: true });
    this.setState({ modalAgNode: node });
  }

  handleFileStatusHide = () => {
    this.setState({ showStatusDialog: false });
  };

  handleFileSummaryHide = () => {
    this.setState({ showSummaryDialog: false });
  };

  render() {
    const { classes } = this.props;
    if (RolePermissionService.DATA_CENTER.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent classes={classes} label="Loading Data Center" />);
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              {/* Header Componenet */}
              <PageDynamicHeaderComponent classes={classes} label="Data Center" rightPadding={16}
                leftActions={[
                  { icon: MatIconService.RELOAD, onClick: () => { this.fetchData(true); }, title: "Reload", iconColor: "secondary" },
                ]}
                rightActions={[
                  { icon: MatIconService.IMPORT_TEMPLATE, onClick: (e) => { this.handleImportTemplateDialogOpen(e); }, title: "Import Template", iconColor: "primary" },
                  { icon: MatIconService.CLOUD_UPLOAD, onClick: (e) => { this.handleUploadDialogOpen(e); }, isReadOnly: RolePermissionService.UPLOAD_FILE_DATACENTER.cannotCreate, title: "Upload File", iconColor: "primary" },
                ]}
              />

              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={this.STORE.getColumnDefs(this)}
                  rowData={this.state.data}
                  pagination={true}
                  paginationPageSize={100}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    context: { componentParent: this },
                    suppressContextMenu: true,
                    ...AgGridColumnExt.getGridOptions(),
                  }}
                  onGridReady={(event) => { }}
                ></AgGridReact>
              </div>

              <Dialog open={this.state.showDataExpandedViewDialog || false} scroll={true ? "paper" : "body"} maxWidth="lg" fullWidth={true}>
                <DataExpandedViewComponent onClose={this.handleDataExpandedViewHide} modalAgNode={this.state.modalAgNode} healthIndicator={this.state.healthIndicator} screenId={54} />
              </Dialog>
              <Dialog open={this.state.download || false} scroll={true ? "paper" : "body"} maxWidth="lg" fullWidth={true}>
                {RolePermissionService.getAccessDeniedComponent(classes, () => { this.setState({ download: false }) })}
              </Dialog>

              <Dialog open={this.state.showStatusDialog || false} scroll={true ? "paper" : "body"} maxWidth="md" >
                <FileStatusComponent onClose={this.handleFileStatusHide} modalAgNode={this.state.modalAgNode} refreshGrid={this.fetchData} />
              </Dialog>

              <Dialog open={this.state.showSummaryDialog || false} scroll={true ? "paper" : "body"} maxWidth="md" >
                <FileSummaryComponent onClose={this.handleFileSummaryHide} modalAgNode={this.state.modalAgNode} refreshGrid={this.fetchData} />
              </Dialog>

              <Dialog open={this.state.openImportTemplateDialog || false} maxWidth="sm" fullWidth scroll={true ? "paper" : "body"}>
                <ImportTemplateComponent openImportTemplateDialog={this.state.openImportTemplateDialog || false} handleImportTemplateDialogClose={this.handleImportTemplateDialogClose} />
              </Dialog>

              <UploadFileComponent openUploadDialog={this.state.openUploadDialog || false} handleUploadDialogClose={this.handleUploadDialogClose} />

              {/* Action Menu Items */}
              {this.state.openCommentFile && this.state.agCellData ?
                <AddEditCommentComponent entityType={ENTITY_TYPE.DATA} open={this.state.openCommentFile || false}
                  agCellData={this.state.agCellData}
                  handleClose={(_reloadRequired) => {
                    this.setState({ openCommentFile: false });
                    if (_reloadRequired) { this.fetchData(true); }
                  }}
                />
                : null}
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent label="Error Loading Data Center List" classes={classes} onRetry={() => { this.fetchData(true); }} />
          );
      }
    }
  }
}

export default withStyles(MatClassService)(dataCenterComponent);
