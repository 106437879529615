import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../shared/store/authProvider";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import { withStyles, Dialog } from "@material-ui/core";
import ChildMessageRendererComponent from "../childMessageRendererComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import ActiveRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";
import ViewFieldDialogComponent from "../porzio-fields/view-field/viewFieldDialogComponent";
import LayoutService from "../../../shared/services/layoutService";

import CustomFieldListService from "./customFieldListService";
import LookupService from "../../../shared/services/lookupService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class CustomFieldsListComponent extends Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    this.handleCreateFieldModal = this.handleCreateFieldModal.bind(this);
    this.handleHideViewFieldModal = this.handleHideViewFieldModal.bind(this);
    this.state = {
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        activeRendererComponent: ActiveRendererComponent,
      },
      createFieldModalShow: false,
      viewFieldModalShow: false,
      modalAgNode: null,
      inputAction: CrudAction.NONE,
      selectedEntityId: 0,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    this.apiSubscriptions.add(
      combineLatest([
        // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
        CustomFieldListService.getCustomFieldListAsOBS(this.context.user.tenantId,),
        LookupService.getEntityListAsOBS(this.context.user.tenantId),
      ]).subscribe(
        // success
        ([_data, _entityList]) => {
          this.setState(
            {
              data: _data,
              entityList: [{ entityid: 0, entityname: "-- All --" }, ..._entityList.filter(x => x.isPorzioEntity === true)],
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  onGridReady = (event) => {
    event.api.closeToolPanel();
    event.api.sizeColumnsToFit();
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    setTimeout(() => {
      this.gridApi.setFilterModel({
        entityName: {
          filterType: 'text',
          type: 'equals',
          filter: this.state.selectedEntityId === 0 ? null : this.state.entityList.find(x => x.entityid === this.state.selectedEntityId).entityname,
        },
      });
    }, 150);
  };

  getFileName() {
    return `Custom_Fields Entity-${(Object.keys(this.gridApi.getFilterModel()).length > 0 ? this.gridApi.getFilterModel().entityName.filter : `All`)} ${new Date().toDateString()}`;
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
      fileName: this.getFileName()
    };
  }

  onEntitySelectClick = (event) => {
    this.setState({ selectedEntityId: event.target.value });
    this.gridApi.setFilterModel({
      entityName: {
        filterType: 'text',
        type: 'equals',
        filter: event.target.value === 0 ? null : this.state.entityList.find(x => x.entityid === event.target.value).entityname,
      },
    });
  }

  handleCreateFieldModal() {
    this.setState({ inputAction: CrudAction.CREATE });
    this.setState({ viewFieldModalShow: true });
  }

  methodFromParent(cell, node) {
    this.setState({ viewFieldModalShow: true });
    this.setState({ inputAction: CrudAction.UPDATE });
    this.setState({ modalAgNode: node }, () => {
      console.log(this.state.modalAgNode);
    });
  }

  handleHideViewFieldModal() {
    this.setState({ viewFieldModalShow: false });
  }


  validateDownload = () => {
    return (RolePermissionService.CUSTOMER_FIELD_LIST_EXPORT.cannotView) ? {}
      : { 'download': true, 'onDownloadClick': this.onDownloadClick };
  }
  validateAdd = () => {
    return (RolePermissionService.FIELD_DETAIL.cannotCreate) ? {}
      : { 'addCircle': true, 'onAddClick': this.handleCreateFieldModal };
  }

  render() {
    const { classes } = this.props;
    if (RolePermissionService.CUSTOMER_FIELD_LIST.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack(); });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent classes={classes} label="Loading Custom Fields" />);
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <div id="MainUsersGrid">
              <div className="IconBg marginTop10px tableTitleHead">
                <PageHeaderComponent back history={this.props.history} noDivider
                  classes={classes}
                  label="Custom Fields"
                  entitySelect
                  selectedEntityId={this.state.selectedEntityId}
                  entitySelectData={this.state.entityList}
                  onEntitySelectClick={(event) => {
                    this.onEntitySelectClick(event);
                  }}
                  reload onReloadClick={() => { this.fetchData(true); }}
                  {...this.validateDownload()}
                  {...this.validateAdd()}
                />
              </div>
              <div {...LayoutService.getAgGridStyles()}>
                <AgGridReact
                  columnDefs={CustomFieldListService.getColumnDefs(this)}
                  rowData={this.state.data}
                  pagination={true}
                  paginationPageSize={50}
                  frameworkComponents={this.state.frameworkComponents}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                  onGridReady={(event) => {
                    this.onGridReady(event);
                  }}
                ></AgGridReact>
              </div>
              <Dialog open={this.state.viewFieldModalShow || false} scroll={true ? "paper" : "body"} maxWidth="md">
                <ViewFieldDialogComponent
                  onClose={this.handleHideViewFieldModal}
                  modalAgNode={this.state.modalAgNode}
                  inputAction={this.state.inputAction}
                  refreshList={this.fetchData}
                  isPorzio={false}
                />
              </Dialog>
            </div>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent label="Error Loading Custom Fields" classes={classes} onRetry={() => { this.fetchData(true); }} />);
      }
    }
  }
}

export default LayoutService.getHocComponenet(CustomFieldsListComponent);
