import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../../shared/store/authProvider";
import ApiService from "../../../../shared/services/apiService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { ResultStatus, API_ENDPOINT, CrudAction, SaveStatus } from "../../../../shared/types/enums";
import LayoutService from "../../../../shared/services/layoutService";
import ToastService from "../../../../shared/services/toastService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridDropdownWithStatusCellRenderer from "../../../../shared/components/ag-grid/agGridDropdownWithStatusCellRenderer";
import ComplianceReportDetailsDialogService from './ComplianceReportDetailsDialogService';
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";

import { Typography, LinearProgress, Dialog, DialogTitle, AppBar, Toolbar, DialogContent } from "@material-ui/core";
import { combineLatest } from "rxjs";

class ComplianceReportDetailsDialogComponent extends Component {

    static contextType = AuthContext;

    apiSubscriptions = new SubscriptionArray();
    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    constructor(props) {
        super(props);


        this.state = {
            fetchResult: ResultStatus.LOADED,
            isNew: this.props.inputAction === CrudAction.CREATE,
            isUpdate: this.props.inputAction === CrudAction.UPDATE,

            isReadOnly: this.props.inputAction === CrudAction.UPDATE,
            saveStatus: SaveStatus.NONE,

            modalAgNode: this.props.modalAgNode,
            reportList: [],//this.props.reportList,
            yearList: [],
            fieldList: [],

            selectedReportId: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.reportId : '', // modalAgNode props -> reportId && reportName
            selectedFieldId: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.fieldId : '', // modalAgNode props -> fieldId && fieldName
            parentReportPathYearText: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.templateYear : '',
            selectedReportPathYearId: '', // modalAgNode props -> -> & templateYear (text) & templateYearId (recevied as is empty from the modalAgNode)  

            // final-grid-data
            gridData: [],

            // agGridUtils
            agGridUtils: new AgGridUtil("gstLovID", {
                dropDownRenderer: AgGridDropdownWithStatusCellRenderer,
            }),
        }
        console.clear();


    }

    componentDidMount() {
        this.fetchFields(false);

    }

    fetchFields = (cancelSubscriptions = true) => {
        if (cancelSubscriptions) { this.apiSubscriptions.cancelAll(); }

        this.setState({ fetchResult: ResultStatus.LOADING, fieldList: [], gridData: [] })

        this.apiSubscriptions.add(
            combineLatest([
                ComplianceReportDetailsDialogService.getReportNameDropdownAsOBS(this.context.user.tenantId),
                ComplianceReportDetailsDialogService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 1),
                ComplianceReportDetailsDialogService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 2),
            ]).subscribe(
                ([_reportList, _profileFieldList, _transactionFieldList]) => {
                    let tempData = ComplianceReportDetailsDialogService.setReportsList(this, _reportList);

                    let reportList = DataService.getKeyValueCollection(tempData, "reportId", "reportName", false);
                    // transform
                    _profileFieldList = _profileFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                    _transactionFieldList = _transactionFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                    const derivedFieldList = ([..._profileFieldList, ..._transactionFieldList]).sort((a, b) => a.fieldName.localeCompare(b.fieldName));

                    this.setState({
                        fieldList: derivedFieldList,
                        reportList: reportList
                    }, // don't set Laaded here
                        () => {
                            this.fetchYears(false); // call the fetch year
                        });

                },
                (error) => {
                    console.error(error);
                    ToastService.showError('Error Loading Fields.');
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    }

    fetchYears = (cancelSubscriptions = true) => {
        if (cancelSubscriptions) { this.apiSubscriptions.cancelAll(); }
        this.setState({ yearList: [], gridData: [] });

        if (this.state.selectedReportId > 0) {
            this.setState({ fetchResult: ResultStatus.LOADING });
            this.apiSubscriptions.add(
                combineLatest([
                    ComplianceReportDetailsDialogService.getYearsByReportId(this.context.user.tenantId, this.state.selectedReportId),
                ]).subscribe(
                    ([_yearList]) => {
                        let derivedReportPathYearId = '';
                        if (this.state.isUpdate && this.state.parentReportPathYearText) {
                            derivedReportPathYearId = _yearList.find(x => x.reportYear === this.state.parentReportPathYearText)?.reportPathId || '';
                        }

                        this.setState({
                            yearList: _yearList,
                            selectedReportPathYearId: derivedReportPathYearId,
                            parentReportPathYearText: '', // clear it so it won't interfer the 2ndTime
                            fetchResult: ResultStatus.LOADED
                        }, () => {
                            if (!cancelSubscriptions && this.state.isUpdate) {
                                this.fetchGridData(); // called via edit
                            }
                        });
                    },
                    (error) => {
                        console.error(error);
                        ToastService.showError('Error fetching Years for the selected Report.');
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                )

            );
        } else {
            this.setState({ fetchResult: ResultStatus.LOADED });
        }
    }

    handleReportChange = (e) => {
        this.setState({
            selectedReportId: e.target.value, // set the selected reportId
            selectedReportPathYearId: '', // clear the previously selected year
            gridData: []    // clear the grid
        },
            () => { this.fetchYears(true); } //-> reload the years
        )
    };

    handleYearChange = (e) => {
        this.setState({
            selectedReportPathYearId: e.target.value, // set the selected year
            gridData: [] // clear the grid
        },
            () => { this.fetchGridData() } // fetch the grid data
        );
    }

    handleFieldChange = (e) => {
        this.setState({
            selectedFieldId: e.target.value, // set the selected field id
            gridData: []    // clear the grid
        },
            () => { this.fetchGridData() } // fetch the grid data
        );
    }

    fetchGridData = () => {
        this.apiSubscriptions.cancelAll();
        this.setState({ gridData: [] });
        const { selectedReportId, selectedReportPathYearId, selectedFieldId } = this.state;

        if (selectedReportId > 0 && selectedReportPathYearId > 0 && selectedFieldId > 0) {
            this.setState({ fetchResult: ResultStatus.LOADING });
            this.apiSubscriptions.add(
                combineLatest([
                    ComplianceReportDetailsDialogService.getReportMappings(this.context.user.tenantId, selectedReportPathYearId, selectedReportPathYearId, selectedFieldId),
                    ComplianceReportDetailsDialogService.getReportValueDropdownAsOBS(this.context.user.tenantId, selectedFieldId, selectedReportPathYearId)
                ]).subscribe(
                    ([_gridData, _reportValueDropdownList]) => {
                        console.log(_gridData);
                        this.setState(
                            {
                                gridData: _gridData ?? [],
                                reportValueDropdownList: [{ 'reportMappingId': 0, 'reportMappingName': "" }, ..._reportValueDropdownList],
                            }, () => this.setState({ fetchResult: ResultStatus.LOADED })
                        )
                    },
                    (error) => {
                        console.error(error);
                        ToastService.showError('Error fetching Data.');
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                )
            );
        }
    }

    handleReportValueChanged = (_cellRef, _params) => {
        if (_cellRef.props.data.reportMappingId > 0) {
            const newReportableValue = this.state.reportValueDropdownList.find(r => r.reportMappingId === _cellRef.props.data.reportMappingId).reportable;
            _params.node.setDataValue('reportable', newReportableValue);
        } else {
            _params.node.setDataValue('reportable', "");
            _params.node.setDataValue('reportMappingId', 0);
        }
    }
    reportValueComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (DataService.isNullOrUndefined(nodeA.data.reportMappingName))
            return 1;
        else if (DataService.isNullOrUndefined(nodeB.data.reportMappingName))
            return -1;
        else if (nodeA.data.reportMappingName === nodeB.data.reportMappingName) return 0;
        return (nodeA.data.reportMappingName > nodeB.data.reportMappingName) ? 1 : -1;
    };

    handleClose = (isReloadRequired) => {
        this.props.onClose(isReloadRequired, this.state.selectedReportId);
    }

    render() {
        const { classes, open } = this.props;
        return (
            <Dialog open={open} scroll={true ? "paper" : "body"} fullWidth={true} maxWidth={"lg"} onClose={() => {
                this.onClose(false)
            }} >
                {/* DialogTitle */}
                < DialogTitle disableTypography id="dialogTitle" >
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Mapping Detail</Typography>
                            {LayoutService.getReadOnlyActions(this, this.state.isNew,
                                () => this.handleClose(false), // onClose
                                () => this.handleSave(), // on Save
                            )}
                        </Toolbar>
                    </AppBar>
                </DialogTitle >
                {/* DialogContent */}
                < DialogContent style={{ paddingInline: 24, paddingBottom: 24 }}>
                    {this.renderContent()}
                </DialogContent >
            </Dialog >
        );
    }

    renderContent = () => {
        const { classes } = this.props;
        const { fetchResult, isUpdate, isReadOnly, agGridUtils, gridData, saveStatus,
            reportList, selectedReportId, yearList, selectedReportPathYearId, fieldList, selectedFieldId,
        } = this.state;

        if ((isUpdate && RolePermissionService.COMPLIANCE_REPORT_MAPPING_DETAIL.cannotView)) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.handleClose(false); });
        } else {
            agGridUtils.disableEditability(isReadOnly);
            switch (fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return <PageLoadingComponent classes={classes} label='Loading Compliance Report Mapping Details...' />;
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <div id="MainUsersGrid">
                            {saveStatus === SaveStatus.START ? <LinearProgress color="secondary" /> : null}
                            {saveStatus === SaveStatus.ERROR ? <Typography style={{ backgroundColor: "#FF8A8A", color: "#6E0101" }}
                                variant="h6" align="center">{this.state.errorMessage}</Typography> : null
                            }

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBlock: 16 }}>
                                {LayoutService.getSimpleDropDown('reportName', selectedReportId, "Report Name", reportList, "id", "text", this.handleReportChange, { minWidth: '336px' })}
                                {LayoutService.getSimpleDropDown('year', selectedReportPathYearId, "Year", yearList, "reportPathId", "reportYear", this.handleYearChange, { width: '312px' })}
                                {LayoutService.getSimpleDropDown('field', selectedFieldId, "Field", fieldList, "fieldId", "fieldName", this.handleFieldChange, { width: '256px' })}
                            </div>

                            <div {...LayoutService.getAgGridStyles(300)}>
                                <AgGridReact
                                    pagination={true}
                                    paginationPageSize={50}
                                    frameworkComponents={agGridUtils.frameworkComponents}
                                    columnDefs={ComplianceReportDetailsDialogService.getColumnDefs(this)}
                                    rowData={gridData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    onGridReady={(e) => {
                                        agGridUtils.setGridParams(e, true);
                                        agGridUtils.disableEditability(isReadOnly);
                                    }}
                                />
                            </div>
                        </div>
                    );
                case ResultStatus.ERROR:
                default:
                    return (<PageErrorComponent classes={classes} onRetry={this.fetchGridData}
                        label={fetchResult === ResultStatus.ERROR ?
                            `Error Loading Compliance Report Mapping` : `fetchState (${fetchResult}) Not handled`}
                    />);
            }
        }

    }

    handleSave = async () => {

        if (this.state.saveStatus === SaveStatus.START) {
            ToastService.showWarning('Save Already In Progress...');
            return;
        } else if (this.state.selectedReportId > 0 && this.state.selectedReportPathYearId > 0 && this.state.selectedFieldId > 0) {
            this.setState({ saveStatus: SaveStatus.START }, () => {
                let _fieldId = 0;
                let _reportId = 0;
                let arrNodes = [];
                this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
                    _fieldId = rowNode.data.fieldId;
                    _reportId = rowNode.data.reportId;
                    arrNodes.push({
                        fieldId: rowNode.data.fieldId,
                        reportId: this.state.selectedReportPathYearId,
                        year: rowNode.data.year,
                        customerFieldId: rowNode.data.customerFieldId,
                        customerFieldValue: rowNode.data.customerFieldValue,
                        porzioGSTFieldId: rowNode.data.porzioGSTFieldId,
                        porzioGSTFieldValue: rowNode.data.porzioGSTFieldValue,
                        reportMappingId: rowNode.data.reportMappingId,
                        //reportMappingName: this.state.reportValueDropdownList[Number(rowNode.data.reportMappingId)],
                        isMappingChanged: rowNode.data.isMappingChanged,
                        reportable: rowNode.data.reportable,
                    });
                });

                // 2) extract the formik values into an object
                var mappedObj = {
                    fieldId: String(_fieldId),
                    // reportId: String(this.state.selectedReportId), // String(_reportId),
                    reportId: String(this.state.selectedReportPathYearId),
                    year: this.state.yearList.find(y => y.reportPathId === this.state.selectedReportPathYearId).reportYear, // String(this.state.selectedYearId),
                    complianceReportMapping: JSON.stringify(arrNodes),
                };

                // 4) save to Api and subscribe for the result
                ApiService.setOBS("POST", API_ENDPOINT.TENANT,
                    `/ComplianceMapping/SaveReportMappings/${this.context.user.tenantId}/${this.context.user.userId}`,
                    JSON.stringify(mappedObj)
                ).subscribe(
                    (successResult) => {
                        if (successResult) {
                            ToastService.showSuccess("Saved successfully.");
                            this.setState({ saveStatus: SaveStatus.SUCCESS, errorMessage: null });
                            this.props.onClose(true, this.state.selectedReportId);
                        } else { this.flashError('Error Occured while Saving.!!!'); }
                    },
                    (errorResult) => this.flashError(errorResult.message)
                );
            });
        } else {
            ToastService.showWarning('Nothing to Save.');
        }
    }

    flashError = (errorMessage) => {
        ToastService.showError("Error Occured.");
        this.setState({
            saveStatus: ResultStatus.ERROR,
            errorMessage: errorMessage
        });
        // hide the error message after 5 seconds
        setTimeout(() => { this.setState({ errorMessage: null }) }, 5000);
    }

}
export default LayoutService.getHocComponenet(ComplianceReportDetailsDialogComponent);