import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, GST_API } from "../../../../shared/types/enums";

export default class ProfileTransactionService {

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static getProfileTransactionsOBS(modalAgNode, clientAuthContext) {
    return ApiService.getOBS(API_ENDPOINT.CORE,
      `/Profiles/GetProfileTransactions?tenantId=${clientAuthContext.user.tenantId}&userId=${clientAuthContext.user.userId}&profileId=${modalAgNode.prid}&pageNumber=1&pageSize=500`
    );
  }

  static getColumnDefs = (parentThis) => {
    return [
      AgGridColumnExt.GET(false, "text", "companytransactionid", "Company Transaction Id").flexWeight(1).tooltip().headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, "text", "porziogsttransactionid", "Porzio GST Transaction ID").flexWeight(1).tooltip().headerClass("center-text").centerAlignText().cellRenderer("childMessageRendererComponent").SET(),
      AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").flexWeight(1).tooltip().headerClass("center-text").centerAlignText().cellRenderer("profileDetailCellRendererComponent").SET(),
      AgGridColumnExt.GET(false, "text", "form", "Form").flexWeight(1).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "purpose", "Purpose").flexWeight(1).tooltip().SET(),

      AgGridColumnExt.GET(false, true, "transactiondate", "Transaction Date").tooltip().SET(),
      AgGridColumnExt.GET(false, true, "convertedtotalamount", "Converted Total Amount").flexWeight(1).tooltip().headerClass("center-text").SET(),

      AgGridColumnExt.GET(false, true, "transactionconsent", "Transaction Consent").flexWeight(1).tooltip().headerClass("center-text").SET(),
      AgGridColumnExt.GET(false, true, "transactiondocuments", "Transaction Document(s)").flexWeight(1).tooltip().SET(),
    ];
  }

}
