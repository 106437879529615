import { of } from "rxjs";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../shared/services/apiService";
import { API_ENDPOINT, ReportCategory, GST_API } from "../../shared/types/enums";

export default class FileStatusDetailsService {

    // static fetchTenants(_userId) {
    //     return ApiService.getOBS(API_ENDPOINT.IDENTITY, `/User/GetUserTenants?userId=${_userId}`);
    // }

    static getActiveTenants = () => {
        const targetUrl = `/GetActiveTenants/false`;
        return ApiService.getOBS(
            API_ENDPOINT.TENANT,
            targetUrl,
        );
    }


    static fetchFileDetails(_selTenantId) {
        /**
         * As per the discussion with the DB Team
         *  
         */
        // const jsonObj = {
        // "tenantId": _selTenantId,
          
        // };
        // TODO: ensure that this  is fetching from ReportMaster Table for the selected tenant
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchTaskDetailsView/${_selTenantId}`);
    }
   
    

    // column-definitions
    static getFileColumnDefs = (_parentThisRef) => {
        
        let FileArr = [
         
            // AgGridColumnExt.GET(false, "text", "systemid", "System Id").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "taskId", "Task Id").flexWeight(1).headerClass("center-text").leftAlignText().SET(),// AgGridColumnExt.GET(false, "text", "azureDataFactoryName", "Azure Data Factory Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "filename", "Filename").flexWeight(1).headerClass("center-text").leftAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "fileStatus", "Status").fixedWidth(100).flexWeight(1).headerClass("center-text").tooltip().cellRenderer("FileStatusImageRenderer").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "date_Started", "StartedOn").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "date_Completed", "CompletedOn").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "createdUser", "Created User").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "createdDate", "CreatedOn").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "taskType", "Task Type").flexWeight(1).headerClass("center-text").leftAlignText().SET(),

            // AgGridColumnExt.GET(false, "text", "resourceGroupName", "Resource GroupName").flexWeight(1).headerClass("center-text").centerAlignText().SET(),// AgGridColumnExt.GET(false, "text", "azureDataFactoryName", "Azure Data Factory Name").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            // AgGridColumnExt.GET(false, "text", "azureDataFactoryName", "Azure DataFactoryName").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
            // AgGridColumnExt.GET(false, "text", "pipelineName", "PipelineName").flexWeight(1).headerClass("center-text").centerAlignText().SET(),
           
        ];
        //return _sourceSelected ? sourceArr : [];
        return FileArr;
      }


   
    
    }

    //---
