import ApiService from "../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../shared/types/enums"
import { Subject } from 'rxjs';
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridBulkEditUtil } from "../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";

export default class HCPSearchDialogService {
    static fetchFilterData = (_tenantId) => {
        return ApiService.getOBS(
            API_ENDPOINT.CORE,
            `/AdvancedSearch/FetchAdvSearchFilterDetails/${_tenantId}`
        );
    }

    static searchOBS = (_tenantId, _searchValue = "") => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Profiles/FetchProfilesBySearch/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: "",
            firstName: "",
            lastName: "",
            organizationName: "",
            companyId: "",
            country: "",
            isAdvance: true
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
            .cellRenderer("isCheckedCellRenderer", { onChange:  _componentThisRef.toggleSubmitButton }).SET(),
            AgGridColumnExt.GET(false, "text", "is_MiamiHcp", "Miami HCP").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "porziogstprofileid", "Porzio GST Profile ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("profileDetailCellRendererComponent").SET(),
            AgGridColumnExt.GET(false, "text", "companyProfileId", "Company Profile ID").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "fileName", "File Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "affiliatedCompany", "Affiliated Company").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "sourceName", "Source").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "profileStatus", "Profile Status").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "lastName", "Last Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "firstName", "First Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "middleName", "Middle Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "organizationName", "Organization Name").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "profileCategory", "Profile Category").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "profileType", "Profile Type").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "credentials", "Credentials").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usnpinumber", "US NPI Number").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "uslicensestate", "US License State").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usstatelicensenumber", "US License Number").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "usTaxIdNumber", "US Tax ID Number").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "profileConsent", "Profile Consent").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "city", "City").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, "text", "province", "Province/State").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
            AgGridColumnExt.GET(false, true, "country", "Country").flexWeight(1).minWidth(136).headerClass("center-text").centerAlignText().SET(),
        ];
    }


    static saveProfiles = (tenantId, idsToSave, isChecked) => {
        return ApiService.postOBS(API_ENDPOINT.CORE,
          `/Profiles/UpdateUSMiamiHCPStatus/${tenantId}/${idsToSave}/${isChecked}`);
      }
}