import React from "react";
import { from, BehaviorSubject } from "rxjs";
import { mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";
import { Typography, Dialog, DialogTitle, DialogContent, AppBar, Toolbar, InputBase, Box } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AuthContext } from "../../../../shared/store/authProvider";
import { ResultStatus, CrudAction } from "../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import LayoutService from "../../../../shared/services/layoutService";
import ActionDialog from "../../../../shared/components/dialog/actionDialog";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import TransactionSearchDialogService from "./transactionSearchDialogService";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import TransactionDetailDialogComponent from "../../transaction-detail/transactionDetailDialogComponent";
import ChildMessageRendererComponent from "../../childMessageRendererComponent";;


class TransactionsSearchDialogComponent extends React.Component {

    static contextType = AuthContext;

    // ept till component's unMount
    scopedSubscriptions = new SubscriptionArray();
    // apiSubscriptions are canclled before retry also
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);

        // init state
        this.state = {
            searchResult: ResultStatus.NOT_LOADED,
            transactionList: [],
            lastSearchedValue: '',
            errorMessage: null,
            showTransactionDetailDialog: false,
            agGridRecipientUtils: new AgGridUtil("lastName", {
                childMessageRendererComponent: ChildMessageRendererComponent,
            }),
        };
    }

    componentWillUnmount() {
        this.scopedSubscriptions.cancelAll();
        this.apiSubscriptions.cancelAll();
    }

    searchSubject = new BehaviorSubject("");

    componentDidMount() {
        var searchOBS = this.searchSubject.pipe(
            debounceTime(750),
            distinctUntilChanged(),
            mergeMap((_searchValue) => from(this.searchRecipient(_searchValue)))
        );

        this.scopedSubscriptions.add(searchOBS.subscribe());
    }

    // called on row-cell click
    methodFromParent = (row_col, node) => {
        this.setState({ modalAgNode: { ...node, porziogsttransactionid: node.porzioGstTransactionId, transactionid: node.trid, sourceid: node.sourceId } });
        this.setState({ showTransactionDetailDialog: true });
    };

    searchRecipient = (_searchValue) => {
        this.apiSubscriptions.cancelAll();

        if (DataService.stringShouldBe(_searchValue, 3)) {
            this.setState({ searchResult: ResultStatus.LOADING, transactionList: [], lastSearchedValue: _searchValue });
            this.apiSubscriptions.add(
                TransactionSearchDialogService.searchOBS(this.context.user.tenantId, _searchValue).subscribe(
                    (_transactionList) => {
                        this.setState({
                            searchResult: DataService.hasElements(_transactionList) ? ResultStatus.SUCCESS : ResultStatus.LOADED,//-> no records
                            transactionList: _transactionList,
                        });
                    },
                    (err) => { this.setState({ searchResult: ResultStatus.ERROR }); }
                )
            );
        } else {
            this.setState({ searchResult: ResultStatus.NOT_LOADED });
        }
        return [];
    }

    // render
    render() {
        const { classes } = this.props;
        return (
            <Dialog open={this.props.open || false} fullWidth={true} maxWidth='lg' scroll={false ? "paper" : "body"}>
                <DialogTitle style={{ padding: 0 }} id="dialog-title">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" className={classes.root}>Transaction Search</Typography>
                            {/* Search */}
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>{MatIconService.SEARCH}</div>
                                <InputBase autoFocus={true} placeholder="Search…" classes={{ root: classes.inputRoot, input: classes.inputInput }} inputProps={{ "aria-label": "search" }}
                                    onChange={(e) => { this.searchSubject.next(e.target.value); }} />
                            </div>
                            {/* {LayoutService.getIconButton(false, MatIconService.OK, "Submit", () => { alert("Under development") }, "inherit")} */}
                            {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", () => {
                                this.setState({
                                    transactionList: [],
                                    lastSearchedValue: '',
                                    searchResult: ResultStatus.NOT_LOADED,
                                });
                                this.props.onClose();
                            }, "secondary", "keyActionCancel1")}
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent>
                    <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, minHeight: "60vh", maxHeight: "60vh", overflowY :"hidden" }}>
                        {this.renderContent(classes)}
                    </Box>
                </DialogContent>
                {this.state.showTransactionDetailDialog ?
                    <TransactionDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                        open={this.state.showTransactionDetailDialog || false} onClose={() => { this.setState({ showTransactionDetailDialog: false }); /*this.fetchData(true);*/ }} />
                    : null}
            </Dialog >
        );
    }

    renderContent = (classes) => {
        switch (this.state.searchResult) {
            case ResultStatus.SAVING: return <PageLoadingComponent small classes={classes} label="Saving..." />;
            case ResultStatus.LOADING: return <PageLoadingComponent small classes={classes} label="Searching..." />;
            case ResultStatus.NOT_LOADED: return <DialogErrorFragmentComponent small classes={classes} description="Please enter atleast 3 characters to search" />;
            case ResultStatus.LOADED: return <DialogErrorFragmentComponent small classes={classes} description="No Records Found" />;
            case ResultStatus.SUCCESS:
                return (<div id="MainRoleGrid">
                    <div style={{ height: `59vh`, width: `100%` }} {...LayoutService.getAgGridTheme()}>
                        <AgGridReact
                            rowData={this.state.transactionList}
                            columnDefs={TransactionSearchDialogService.getColumnDefs(this)}
                            suppressClickEdit={true}
                            pagination={true}
                            paginationPageSize={100}
                            frameworkComponents={this.state.agGridRecipientUtils.frameworkComponents}
                            onGridReady={(params) => { this.state.agGridRecipientUtils.setGridParams(params, true); }}
                            gridOptions={{
                                context: { componentParent: this },
                                suppressContextMenu: true,
                                rowHeight: 32,
                            }}
                        />
                    </div>
                </div>);
            case ResultStatus.ERROR:
            default:
                return (<DialogErrorFragmentComponent classes={classes} description="An error occured while Searching" onRetry={() => { this.searchRecipient(this.state.lastSearchedValue); }} />);
        }
    }

}
export default LayoutService.getHocComponenet(TransactionsSearchDialogComponent);