import React, { Component } from "react";
import { combineLatest } from "rxjs";
import clsx from "clsx";
import { withStyles, Divider, Grid, FormGroup, FormControl, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from "@material-ui/core";
import { ResultStatus, CrudAction, API_ENDPOINT, ReportableMap, } from "../../../../shared/types/enums";
import { Formik } from "formik";
import * as Yup from "yup";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../../shared/services/layoutService";
import { AuthContext } from "../../../../shared/store/authProvider";
import ApiService from "../../../../shared/services/apiService";
import MatSpecService from "../../../../shared/services/theme/matSpecService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import SectionComponent from "../../../../shared/components/sectionComponent";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import EditConsentProcessingService from "./editConsentProcessingService";
import ActionDialog from "../../../../shared/components/dialog/actionDialog";
import LookupService from "../../../../shared/services/lookupService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class EditConsentProcessingComponent extends React.Component {
  static contextType = AuthContext;

  oSubscriptions = new SubscriptionArray();
  constructor(props) {
    super(props);

    this.state = {
      fetchResult: ResultStatus.SUCCESS,
      isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      reportable: 250,//this.props.inputAction === CrudAction.CREATE ? 250 : null,
      reportsListWithAll: [],
      yearList: [],
      selectedReportId: this.props.modalAgNode.reportId,
      selectedYearId: this.props.modalAgNode.reportPathId,
      selectedVerifiedYearId: this.props.modalAgNode.templateYearLov,
      selectedReportName: this.props.modalAgNode.reportName,
      showConfirmDialog: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {
    this.oSubscriptions.cancelAll();

    this.setState({ fetchResult: ResultStatus.LOADING, processingObj: {} });
    this.oSubscriptions.add(
      combineLatest([
        EditConsentProcessingService.getComplianceReportMappingProcessingAsOBS(this.context.user.tenantId, this.state.selectedReportName, this.state.selectedVerifiedYearId),
        EditConsentProcessingService.fetchReportMappings(this.context.user.tenantId,),
        EditConsentProcessingService.getYearsByReportId(this.context.user.tenantId, this.state.selectedReportId),
      ]).subscribe(
        ([_processingObj, _reportList, _yearList,]) => {
          const _arrReportsObjsDupes = _reportList.map(r => ({ id: r.reportId, text: r.reportName }));
          let _arrReportsObjs = _arrReportsObjsDupes.filter((dupeReport, index, self) =>
            index === self.findIndex((dedupedReport) => (
              dedupedReport.text === dupeReport.text)));
          _arrReportsObjs = _arrReportsObjs.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
          _arrReportsObjs = _arrReportsObjs.filter(x => !LookupService.REPORTS_TO_BE_REMOVED_CONSENT.includes(x.text));

          _yearList = _yearList.map(r => ({ id: r.reportPathId, text: r.reportYear }));
          _yearList = _yearList.filter((dupeReport, index, self) =>
            index === self.findIndex((dedupedReport) => (
              dedupedReport.text === dupeReport.text)));
          _yearList = _yearList.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
          this.setState(
            {
              processingObj: _processingObj,
              reportable: _processingObj.reportable,
              reportsListWithAll: [..._arrReportsObjs],
              yearList: [..._yearList],
            },
            () => {
              this.setState({ fetchResult: ResultStatus.SUCCESS });
            }
          );
        },
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );

  }

  fetchGetListData = async (tenantSelectList, isLoadingKey, dataKey) => {
    this.setState({ [isLoadingKey]: true });
    try {
      const result = await fetch(
        `${process.env.REACT_APP_TENANT_HTTP}/Util/GetMasterSubSet/${this.context.user.tenantId}/${tenantSelectList}`
      );
      const json = await result.json();
      this.setState({ [dataKey]: json, [isLoadingKey]: false });
    } catch (error) {
      console.log(`GetList ${tenantSelectList} Error:`, error);
    }
  };

  onReportSelectClick = (event) => {
    this.setState(
      {
        selectedReportName: event.target.value,
        selectedReportId: this.state.reportsListWithAll.find(x => x.text === event.target.value).id,
        selectedYearId: 0,
      },
      () => {
        // Note: This ia an ugly shortcut just for now
        this.fetchYear(this.state.selectedReportId, this.setVerifiedYear);
      }
    );
  }

  fetchYear = (_reportId, _callbackOnSuccess = null) => {
    let url = `/ComplianceMapping/FetchReportTemplateYears/${this.context.user.tenantId}`
    if (_reportId !== 0) {
      url = `/ComplianceMapping/FetchReportTemplateYears/${this.context.user.tenantId}?reportId=${_reportId}`
    }
    ApiService.getOBS(
      API_ENDPOINT.TENANT,
      url
    ).subscribe(
      (successResult) => {
        let _yearList = successResult;
        if (_yearList) {
          _yearList = _yearList.map(r => ({ id: r.reportPathId, text: r.reportYear }));
          _yearList = _yearList.filter((dupeReport, index, self) =>
            index === self.findIndex((dedupedReport) => (
              dedupedReport.text === dupeReport.text)));
          _yearList = _yearList.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
          this.setState({
            fetchResult: ResultStatus.SUCCESS,
            yearList: [..._yearList],
          }, () => {
            if (this.state.selectedYearId === 0) {
              this.setState({ selectedYearId: this.state.yearList[0].id }, () => {
                if (_callbackOnSuccess) { _callbackOnSuccess(this.state.yearList[0].text, this.fetchProcessingObj); }
              })
            }
            else {
              if (_callbackOnSuccess) {
                _callbackOnSuccess(this.state.yearList.find(x => x.id === this.state.selectedYearId).text,
                  this.fetchProcessingObj);
              }
            }
          });
        } else {
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      },
      (errorResult) => {
        console.error("Error while saving Product", errorResult);
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    );
  }

  setVerifiedYear = (_templateYear, _callbackOnSuccess = null) => {
    ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/VerifyTemplateYear/${this.context.user.tenantId}/${_templateYear}`
    ).subscribe(
      (successResult) => {
        this.setState({ selectedVerifiedYearId: successResult }, () => {
          if (_callbackOnSuccess) { _callbackOnSuccess(this.state.selectedVerifiedYearId); }
        });
      },
      (errorResult) => {
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    )
  }

  onYearSelectClick = (event) => {
    this.setState(
      {
        selectedYearId: event.target.value,
      },
      () => {
        this.setVerifiedYear(this.state.yearList.find(x => x.id === this.state.selectedYearId).text, this.fetchProcessingObj);
        //this.fetchProcessingObj(this.state.selectedVerifiedYearId);
      }
    );
  }

  fetchProcessingObj = (_verifiedYear) => {
    const reportObj = this.state.reportsListWithAll.find((reportObj) => (reportObj.id === this.state.selectedReportId));
    const _reportName = reportObj?.text ?? "";
    this.setState({ fetchResult: ResultStatus.LOADING, processingObj: {} });

    ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/GetProcessing/${this.context.user.tenantId}?reportName=${_reportName}&verifiedYear=${_verifiedYear}`
    ).subscribe(
      (successResult) => {
        let _processingObj = successResult;
        if (_processingObj) {
          this.setState({
            processingObj: _processingObj,
            reportable: _processingObj.reportable
          },
            () => {
              this.setState({ fetchResult: ResultStatus.SUCCESS });
            }
          );
        } else {
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      },
      (errorResult) => {
        console.error("Error while saving Product", errorResult);
        this.setState({ fetchResult: ResultStatus.ERROR });
      }
    );
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        isProfileConsent: false,
        isAgreementConsent: false,
        isTransactionConsent: false,
        isConsentGrouping: false,
        isLinkedProfles: false,
        reportable: 250,
      };
    } else {
      return {
        isProfileConsent: this.state.processingObj?.profileConsent,
        isAgreementConsent: this.state.processingObj?.agreementConsent,
        isTransactionConsent: this.state.processingObj?.transactionConsent,
        isConsentGrouping: this.state.processingObj?.consentGrouping,
        isLinkedProfles: this.state.processingObj?.linkedProfiles,
        reportable: this.state.reportable,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
    });
    return this.validationSchema;
  }

  handleReportableRadioChange = (event) => {
    console.log("handleReportableRadioChange", event);
    event.persist();
    this.setState({ reportable: Number(event.target.value) });

  }
  handleConsentGrouping = (_checked) => {
    if (!_checked) {
      this.setState({ reportable: 0 });
    } else {
      this.setState({ reportable: 250 });
    }
  }

  handleConfirmDialog = (_formikProps) => {
    if (Object.keys(_formikProps.touched).length !== 0) {
      this.setState({ showConfirmDialog: true });
    }
    else {
      this.handleSubmit(_formikProps);
    }
  }

  handleSubmit = async (_formikProps) => {
    // console.log(_formikProps);
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema
        .validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            reportDisplayName: this.state.processingObj.reportDisplayName,
            reportYear: this.state.processingObj.reportYear,
            profileConsent: _formikProps.values.isProfileConsent,
            agreementConsent: _formikProps.values.isAgreementConsent,
            transactionConsent: _formikProps.values.isTransactionConsent,
            consentGrouping: _formikProps.values.isConsentGrouping,
            reportable: _formikProps.values.isConsentGrouping ? this.state.reportable : 0,
            linkedProfiles: _formikProps.values.isLinkedProfles,
            reportableText: ReportableMap[this.state.reportable],
          };

          // 3) determine the action and assign the appropriate props
          const actionVerb = "POST";
          const targetUrl = `/ComplianceMapping/SaveConsentProcessing/${this.context.user.tenantId}/${this.context.user.userId}`;
          if (this.props.inputAction === CrudAction.UPDATE) {
            // UPDATE
            mappedObj.reportPathId = this.props.modalAgNode.reportPathId;
          } else {
            // CREATE
            mappedObj.reportPathId = 0;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            targetUrl,
            JSON.stringify(mappedObj)
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshList(true);
                this.props.onClose(false);
              } else {
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Product", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          if (erroObj.inner) {
            erroObj.inner.forEach((err) => {
              _formikProps.setFieldError(err.path, err.message);
            });
          }
        });
    }
  };

  /**Render */
  render() {
    const { classes } = this.props;
    const pageName = "Consent Mapping";

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent small classes={classes} label="Loading Consent Mapping Detail" />);
      case ResultStatus.SAVING:
        return (<PageLoadingComponent small classes={classes} label="Saving Consent Mapping Detail" />);
      case ResultStatus.SUCCESS:
        return (
          <Formik
            initialValues={this.getInitialValues()}
            validationSchema={this.getValidationSchema()}
            validationSchemaOptions={{ showMultipleFieldErrors: true }}
          >
            {(fProps) => (
              <form>
                <ActionDialog showDialog={this.state.showConfirmDialog} title="Custom Consent Warning"
                  description="You currently have custom consent mappings for this compliance report, and have chosen to change your processing options. Please confirm this change."
                  actions={[
                    {
                      color: "secondary", text: "Submit", icon: null,
                      callback: () => {
                        this.handleSubmit(fProps)
                        this.setState({ showConfirmDialog: false });
                      }
                    },
                    {
                      color: "inherit", text: "Cancel", icon: null,
                      callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
                    }
                  ]} />
                <CompliancePageHeaderComponent back={false} history={this.props.history}
                  classes={classes}
                  label={""}
                  reportComplianceSelect
                  selectedReportId={this.state.selectedReportName}
                  reportSelectData={this.state.reportsListWithAll}
                  onReportSelectClick={(event) => {
                    this.onReportSelectClick(event);
                  }}

                  yearComplianceSelect
                  selectedYearId={this.state.selectedYearId}
                  yearList={this.state.yearList}
                  onYearSelectClick={(event) => {
                    this.onYearSelectClick(event);
                  }}

                />

                <Grid container spacing={0} justify="center" alignItems="center" className={classes.root}>
                  <Grid item xs={12} sm={12}>
                    <SectionComponent label={"Consent Evaluation"} classes={classes}
                      enableEditJsx={
                        RolePermissionService.CONSENT_MAPPING_DETAIL.cannotEdit ? null :
                          LayoutService.getReadOnlyActionsSolo(this, false, () => { this.handleConfirmDialog(fProps) })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                      {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isProfileConsent", "Profile Consent")}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                      {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isAgreementConsent", "Agreement Consent")}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                      {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isTransactionConsent", "Transaction Consent")}
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={12}><SectionComponent classes={classes} label={"Consent Grouping Preference"} /></Grid>
                  <Grid item xs={12} sm={6}>
                    <FormGroup style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                      {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isConsentGrouping", "Group in Compliance Report by Consent Mapping", true, "47%", "medium", this.handleConsentGrouping)}
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="reportableRadio" name="reportableRadio" value={this.state.reportable} onChange={(e) => this.handleReportableRadioChange(e)}>
                        <FormControlLabel value={250} control={<Radio disabled={this.state.isReadOnly || !fProps.values.isConsentGrouping} />} label="Reportable - Direct" />
                        <FormControlLabel value={251} control={<Radio disabled={this.state.isReadOnly || !fProps.values.isConsentGrouping} />} label="Reportable - Aggregate" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12}><SectionComponent classes={classes} label={"Linked Profiles"} /></Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                      {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isLinkedProfles", "Report by linked parent Profile Consent")}
                    </FormGroup>
                  </Grid>

                  {/* <Grid item xs={12} sm={12} md={6}><pre>{JSON.stringify(this.props, null, 2)}</pre></Grid>
                  <Grid item xs={12} sm={12} md={6}><pre>{JSON.stringify(this.state, null, 2)}</pre></Grid> */}

                </Grid>
              </form>
            )}
          </Formik>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent
            small
            label="Error Consent Mapping Detail"
            classes={classes}
            onRetry={() => {
              this.props.onClose(false);
            }}
          />
        );
    }
  }
}

class CompliancePageHeaderComponent extends Component {
  render() {
    const { small, classes, history, label, back, menu, search, reportComplianceSelect, yearComplianceSelect, countrySelect, importTemplate, uploadFile, save, add, addCircle, view, reload, ok, cancel, noDivider, noDividerSpacing, download } = this.props;
    const showButtons = back || menu || search || countrySelect || importTemplate || uploadFile || save || add || addCircle || view || reload || ok || cancel || download;

    return (
      <React.Fragment>
        <Grid className={classes.flexNoShrink} container direction="column">
          <Grid container direction="row" alignItems="center">
            {back ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Back" placement="bottom" arrow>
                <IconButton onClick={() => { history.goBack(); }}>{MatIconService.BACK}</IconButton>
              </Tooltip>
            ) : null}

            <Typography variant={small ? "h6" : "h5"} className={classes.headerTitle}>
              {label}
            </Typography>

            {reportComplianceSelect ? (
              <>
                <Typography>Report Name:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                <Select
                  labelId="reportComplianceSelectLabel"
                  id="reportComplianceSelect"
                  value={this.props.selectedReportId}
                  onChange={this.props.onReportSelectClick}
                  style={{ width: 150 }}
                >
                  {this.props.reportSelectData.map((el) => (
                    <MenuItem key={el.id} value={el.text}>
                      {el.text}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}

            {yearComplianceSelect ? (
              <>
                <Typography>Year:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                <Select
                  labelId="yearComplianceSelectLabel"
                  id="yearComplianceSelect"
                  value={this.props.selectedYearId}
                  onChange={this.props.onYearSelectClick}
                  style={{ width: 150 }}
                >
                  {this.props.yearList.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.text}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}

            {download ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                <IconButton onClick={this.props.onDownloadClick} color="secondary">
                  {MatIconService.DOWNLOAD}
                </IconButton>
              </Tooltip>
            ) : null}

            {add || addCircle ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add" placement="bottom" arrow>
                <IconButton onClick={this.props.onAddClick} color="secondary">
                  {addCircle ? MatIconService.ADD_CIRCLE_OUTLINE : MatIconService.ADD}
                </IconButton>
              </Tooltip>
            ) : null}

            {reload ? (
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Reload" placement="bottom" arrow>
                <IconButton onClick={this.props.onReloadClick} color="secondary">
                  {MatIconService.RELOAD}
                </IconButton>
              </Tooltip>
            ) : null}
          </Grid>
          {noDivider || noDividerSpacing ? (
            <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
          ) : (
            <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}


/** HOC */
export default withStyles(MatClassService)(EditConsentProcessingComponent);
