import React, { Component } from "react";

export default class ReportYearAgGridRendererComponent extends Component {

  invokeParentMethod = () => {
    this.props.context.componentParent.showConsentMappingDialog(`Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`, this.props.node.data);
  }

  render() {
    return (
      <span style={{ textDecoration: "underline" }} onClick={this.invokeParentMethod}>
        {this.props.value}
      </span>
    );
  }
}
