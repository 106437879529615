import React, { Component } from "react";
import { Dialog, withStyles, Tabs, Tab, Divider, Box } from "@material-ui/core";
import { withRouter } from 'react-router-dom'
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import MatThemeService from "../../shared/services/theme/matThemeService";
import "./reportCenterStyles.css";
import { AuthContext } from "../../shared/store/authProvider";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatClassService } from "../../shared/services/theme/matClassService";
import ComplianceTemplatesComponent from "./report-detail/compliance-templates/complianceTemplatesComponent";
import StandardTemplatesComponent from "./report-detail/standard-templates/standardTemplatesComponenet";
import ReportLibraryViewComponent from "./report-detail/report-library-view/reportLibraryViewComponent";
import { ReportLibrary } from "../../shared/types/enums";

class ReportsCenterComponent extends Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.tabIndexMap = new Map();
    this.tabIndexMap.set(0, { ref: null });
    this.tabIndexMap.set(1, { ref: null });
    this.tabIndexMap.set(2, { ref: null });
    this.tabIndexMap.set(3, { ref: null });

    this.state = {
      selectedTabIndex: 0,
      isReadOnly: true,
      isDialog: true, //this.props.standAloneProps ? false : true,
      derivedProps: { fullWidth: true, maxWidth: "lg" },//this.props.standAloneProps ? this.props.standAloneProps : { fullWidth: true, maxWidth: "lg" },
    };
  }

  _changeTab = (_newTabIndex) => {
    this.setState({
      selectedTabIndex: _newTabIndex,
      isReadOnly: true // on tab change make it readonly
    });
  }

  onTabChange = (event, _newTabIndex) => {//TODO
    const currentTabRef = this.tabIndexMap.get(this.state.selectedTabIndex).ref;
    this.setState({ showConfirmDialog: false }, () => {
      this._changeTab(_newTabIndex);
    });
  };

  componentDidMount() {
  }

  //TODO
  onClose = (_reloadRequired) => {
    this.setState({ isReadOnly: true }, () => {
      //this.props.onClose(_reloadRequired);
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <PageDynamicHeaderComponent classes={classes} label="Reports Center" divider bottomPadding={16} />

        {/* DialogContent */}
        <Tabs value={this.state.selectedTabIndex} onChange={this.onTabChange} indicatorColor="secondary" textColor="inherit" variant="scrollable" scrollButtons="auto"
          style={{ backgroundColor: MatThemeService.getTabBG() }}>
          <Tab label="Compliance Templates" id="complianceTab" />
          <Tab label="Standard Templates" id="standardTemplatesTab" />
          <Tab label="In Review Library" id="inReviewLibraryTab" />
          <Tab label="Approved Library" id="approvedLibraryTab" />
        </Tabs>
        <div role="tabpanel" hidden={this.state.selectedTabIndex !== 0} id={"complianceTabPanel"} >
          <Divider />
          <Box style={{ padding: "0px" }} >
            <ComplianceTemplatesComponent tabConfig={this.tabIndexMap.get(0)} isReadOnly={this.state.isReadOnly} history={this.props.history}
              onClose={() => { this.onClose(false); }} />
          </Box>
        </div>
        <div role="tabpanel" hidden={this.state.selectedTabIndex !== 1} id={"standardTemplatesTabPanel"} >
          <Divider />
          <Box style={{ padding: "0px" }} >
            <StandardTemplatesComponent tabConfig={this.tabIndexMap.get(1)} isReadOnly={this.state.isReadOnly} history={this.props.history}
              onClose={() => { this.onClose(false); }} />
          </Box>
        </div>
        <div role="tabpanel" hidden={this.state.selectedTabIndex !== 2} id={"inReviewLibraryTabPanel"} >
          <Divider />
          <Box style={{ padding: "0px" }} >
            <ReportLibraryViewComponent tabConfig={this.tabIndexMap.get(2)} reportLibraryType={ReportLibrary.IN_REVIEW} />
          </Box>
        </div>
        <div role="tabpanel" hidden={this.state.selectedTabIndex !== 3} id={"approvedLibraryTabPanel"} >
          <Divider />
          <Box style={{ padding: "0px" }} >
            <ReportLibraryViewComponent tabConfig={this.tabIndexMap.get(3)} reportLibraryType={ReportLibrary.APPROVED} />
          </Box>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(MatClassService)(withRouter(ReportsCenterComponent));
