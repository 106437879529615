import { AgGridService } from "../../../../shared/services/ag-grid/agGridService";
import ApiService from "../../../../shared/services/apiService"
import { API_ENDPOINT } from "../../../../shared/types/enums"

export default class LinkedProfilesSearchDialogService {

    static searchOBS = (_tenantId, _searchValue) => {
        return ApiService.setOBS("POST", API_ENDPOINT.CORE, `/Profiles/FetchProfilesBySearch/${_tenantId}`, JSON.stringify({
            searchValue: _searchValue,
            porzioGSTId: "",
            firstName: "",
            lastName: "",
            organizationName: "",
            companyId: "",
            country: ""
        }));
    }

    static getColumnDefs = (_componentThisRef, _onSelectCallback) => {
        return [
            AgGridService.getTextColumn(false, "Company Profile ID", "companyProfileId"),
            AgGridService.getColumn(false, "Porzio GST Profile ID", "porziogstprofileid","profileDetailCellRendererComponent"),
            AgGridService.getTextColumn(false, "Last Name", "lastName"),
            AgGridService.getTextColumn(false, "First Name", "firstName"),
            AgGridService.getTextColumn(false, "Organization Name", "organizationName"),
            AgGridService.getTextColumn(false, "Country", "country"),
            AgGridService.getTextColumn(false, "Profile Status", "profileStatus"),
        ];
    }
}