import React from "react";
import { grey, red } from '@material-ui/core/colors';
import {
  Add,
  Assessment,
  AssignmentTurnedIn,
  CloudUpload,
  DirectionsRun,
  Home,
  LibraryAddCheck,
  LocalPharmacy,
  PermDataSetting,
  Launch,
  SpeakerNotes,
  Dashboard,
  Help,
  Folder,
  AccountCircle,
  Business,
  RecentActors,
  Store,
  Email,
  AlternateEmail,
  Security,
  Map,
  PeopleAlt,
  VpnKey,
  AssignmentInd,
  AccountTree,
  GroupWork,
  Polymer,
  PostAdd,
  NoteAdd,
  Ballot,
  BallotOutlined,
  ContactMail,
  ColorLens,
  Brightness6Outlined,
  Description,
  Person,
  Storage,
  ThumbUp,
  ThumbDown,
  Receipt,
  ViewCarousel,
  TrendingUp,
  Menu,
  Search,
  Lens,
  AddCircleOutline,
  ViewList,
  Refresh,
  EventNote,
  Event,
  Alarm,
  Chat,
  Assignment,
  Build,
  Close,
  Done,
  ArrowBack,
  Feedback,
  Widgets,
  Delete,
  Edit,
  CheckCircleOutlineOutlined,
  EditOutlined,
  Remove,
  Save,
  ExpandLess,
  ExpandMore,
  DeleteOutline,
  DeleteForever,
  Translate,
  AttachMoney,
  Settings,
  PowerSettingsNew,
  WbIncandescent,
  WbIncandescentOutlined,
  WbIncandescentTwoTone,
  EmojiObjects,
  Flare,
  Highlight,
  ZoomIn,
  SwapCalls,
  Link,
  LinkOff,
  MoreVert,
  MoreHoriz,
  SpeakerNotesOff,
  NotListedLocation,
  GetApp,
  People,
  ViewColumnTwoTone,
  ViewWeek,
  CalendarViewDaySharp,
  FormatListBulletedSharp,
  ArrowUpward,
  RateReview,
  Report,
  LowPriority,
  LockOpen,
  PriorityHigh,
  Gavel,
  Lock,
  Autorenew,
  HourglassFull,
  FileCopy,
  AccountCircleTwoTone,
  ViewCarouselOutlined,
  GroupAdd,
  CheckBoxOutlineBlankTwoTone,
  CheckBoxTwoTone,
  IndeterminateCheckBoxTwoTone,
  DoneAll,
  ClearAll,
  Exposure,
  FindInPageTwoTone,
  PlayArrow,
  WbSunny,
  WbSunnyTwoTone,
  Brightness2TwoTone,
  AccessTimeTwoTone,
  Beenhere,
  Note,
} from "@material-ui/icons";

export const MatIconService = {

  DONE_ALL: <DoneAll />,

  SELECT_NONE: <ClearAll />,
  SELECT_ALL: <DoneAll />,
  SELECT_PARTIAL: <Exposure />,
  SELECT_PAGE: <Note />,
  FIND_IN_PAGE: <FindInPageTwoTone style={{ fontSize: 32 }} />,

  ACCOUNT: <AccountCircle />,
  SETTINGS: <Settings />,

  COLUMNS_TT_32: <ViewColumnTwoTone style={{ fontSize: 32 }} />,
  COLUMNS_TT_24: <ViewColumnTwoTone style={{ fontSize: 24 }} />,
  COLUMNS_32: <FormatListBulletedSharp style={{ fontSize: 32, }} />,
  COLUMNS_24: <FormatListBulletedSharp style={{ fontSize: 24, }} />,

  DENIED: <Lock style={{ fontSize: 24, color: red[500] }} />,

  SWAP: <SwapCalls style={{ fontSize: 32, transform: "rotate(90deg)" }} />,
  LINK: <Link />,
  UNLINK: <LinkOff />,
  IDEA_BULB_32: <WbIncandescentTwoTone style={{ fontSize: 32, transform: "rotate(-180deg)" }} />,
  SEARCH_32: <Search style={{ fontSize: 30, marginTop: 4 }} />,

  // OVERRIDE: <LockOpen style={{ fontSize: 32 }} />,
  // OVERRIDE: <Gavel style={{ fontSize: 32 }} />,
  OVERRIDE_NORMAL: <LowPriority style={{ transform: "rotate(180deg)" }} />,
  OVERRIDE: <LowPriority style={{ fontSize: 32, transform: "rotate(180deg)" }} />,
  OVERRIDE_28: <LowPriority style={{ fontSize: 28, transform: "rotate(180deg)" }} />,

  DASH: <Remove color="disabled" />,
  PERSONAL: <Person color="primary" />,
  REFRESH_CACHE: <Refresh color="primary" />,
  IN_PROGRESS: <HourglassFull />,

  CONTACT_US: <Help />,
  LOGOUT: <PowerSettingsNew style={{ color: red[500] }} />,
  DELETE_RED: <Delete style={{ color: red[500] }} />,
  DELETE: <Delete />,
  DELETE_FOREVER: <DeleteForever />,
  DELETE_OUTLINE: <DeleteOutline style={{ color: red[500] }} />,

  LANGUAGE: <Translate />,
  COPY: <FileCopy />,
  CURRENCY: <AttachMoney />,
  MONEY: <AttachMoney />,
  DOLLAR: <AttachMoney />,
  SAVE: <Save />,
  RUN: <PlayArrow />,
  BACK: <ArrowBack />,
  EDIT: <Edit />,
  EDIT_OUTLINED: <EditOutlined />,
  ADD: <Add />,
  IMPORT_TEMPLATE: <Description />,
  CLOUD_UPLOAD: <CloudUpload />,
  CLOSE: <Close />,
  CLEAR_RED: <Close style={{ color: "orangered" }} />,
  OK: <Done />,
  ACCEPT: <CheckCircleOutlineOutlined />,
  CANCEL: <Close />,
  DATE: <Event color="disabled" />,
  EVENT: <Event style={{ fontSize: 32 }} />,
  EVENT_NOTE: <EventNote />,
  EVENT_ALARM: <Alarm style={{ fontSize: 32 }} />,
  TASK_32: <Assignment style={{ fontSize: 32 }} />,
  TASK_24: <Assignment style={{ fontSize: 24 }} />,
  TASK_ADD: <PostAdd style={{ fontSize: 24 }} />,
  PROFILE_BATCH_ADD: <GroupAdd style={{ fontSize: 24 }} />,
  NEWS_FEED: <Chat style={{ fontSize: 32 }} />, // <RssFeed style={{ fontSize: 32 }} />,
  RELOAD: <Refresh />,
  ADD_CIRCLE_OUTLINE: <AddCircleOutline />,
  VIEW_LIST: <ViewList />,
  MENU: <Menu />,
  SEARCH: <Search />,
  CONTRAST: <Brightness6Outlined />,
  SUN: <WbSunnyTwoTone />,
  MOON: <Brightness2TwoTone style={{ transform: "rotate(135deg)" }} />,
  CLOCK: <AccessTimeTwoTone style={{ color: 'white' }} />,
  CLOCK_SELECTED: <AccessTimeTwoTone style={{ color: 'red' }} />,

  THEME: <ColorLens />,
  NAV_HOME: <Home style={{ fontSize: 32 }} />,
  NAV_DASHBOARD: <Dashboard style={{ fontSize: 32 }} />,
  NAV_POWER_BI: <Assessment style={{ fontSize: 36 }} />,
  NAV_REPORT: <Receipt style={{ fontSize: 32 }} />,
  NAV_DATABASE: <Storage style={{ fontSize: 32 }} />,
  NAV_FILES: <ViewCarousel style={{ fontSize: 32 }} />,
  TRANSACTION_OUTLINED: <ViewCarouselOutlined />,
  NAV_USER: <Person style={{ fontSize: 32 }} />,
  PROFILE: <Person />,
  PROFILE_OUTLINED: <AccountCircleTwoTone />,
  NAV_HAND: <ThumbUp style={{ fontSize: 32 }} />,
  NAV_THUMB_DOWN: <ThumbDown style={{ fontSize: 32 }} />,
  NAV_DEMO: <TrendingUp style={{ fontSize: 32 }} />,
  NAV_DETAIL: <Description style={{ fontSize: 32 }} />,

  WARNING: <Build style={{ fontSize: 88 }} />,

  COMPANY: <Business />,
  AFFILIATE: <RecentActors />,
  STORE: <Store />,

  PASSWORD: <Security />,
  DRAFTS: <ContactMail />,
  EMAIL: <Email />,
  FTP: <Settings />,
  EMAIL_FAQ: <AlternateEmail />,

  USERS: <PeopleAlt />,
  ROLE: <AssignmentInd />,
  PERMISSION: <VpnKey />,

  DEFAULT_MAPPING: <Map />,
  REPORT_MAPPING: <AccountTree />,
  CONSENT_MAPPING: <People />,

  LAUNCH: <Launch style={{ fontSize: 18 }} />,
  PERM_DATA_SETTING: <PermDataSetting style={{ fontSize: 18 }} />,
  POWER_BI: <Assessment />,

  PORZIO_RULES: <NoteAdd />,
  CUSTOM_RULES: <PostAdd />,

  PORZIO_FILEDS: <Ballot />,
  CUSTOM_FIELDS: <BallotOutlined />,

  RERUN_VALIDATION: <AssignmentTurnedIn />,
  REVALIDATE_QUEUE: <Beenhere />,
  RERUN_MATCHING: <LibraryAddCheck />,
  FILE_COMMENT: <Edit />,
  FILE_COMMENT_ON: <Edit color="primary" style={{ fontSize: 20 }} />,
  FILE_COMMENT_OFF: <Edit style={{ color: grey[400], fontSize: 20 }} />,
  RUN_REPORTS: <DirectionsRun />,

  SOURCE: <Polymer />,

  FOLDER: <Folder />,
  CIRCLE: <Lens />,
  LOGIN_FEEDBACK: <Feedback style={{ fontSize: 32 }} />,
  WIDGETS: <Widgets />,
  NDC_PRODUCT: <LocalPharmacy />,

  MOVE_UP: <ExpandLess />,
  MOVE_DOWN: <ExpandMore />,
  MORE_VERT_16: <MoreVert style={{ fontSize: 16 }} />,
  MORE_HORZ: <MoreHoriz />,

  NOT_LISTED: <NotListedLocation />,
  DOWNLOAD: <GetApp />,

  PARENT: <ArrowUpward />,
  REVIEWED: <RateReview />,

  REPORT: <Report />
};
