import React from "react";
import { combineLatest } from "rxjs";
import { ResultStatus, CrudAction, TaskLauncher, ENTITY_TYPE } from "../../../shared/types/enums";
import { withStyles, Dialog } from "@material-ui/core";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import { SubscriptionArray } from "../../../shared/services/dataService";
import TaskDetailsComponent from "./task-details/taskDetailsComponent";
import TaskListService from "./taskListService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class TaskListComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      showTaskDialog: false,
      inputAction: CrudAction.CREATE,
      updateData: null,
    }
  }


  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {

    this.setState({ fetchResult: ResultStatus.LOADING });

    this.oSubscriptions.add(
      combineLatest([
        TaskListService.getObs(true, this.context),
        TaskListService.getAllUsersAsOBS(this.context),
      ]).subscribe(
        ([
          _data,
          _allUsersList,
        ]) => {
          const _allUsersListSorted = _allUsersList;
          _allUsersListSorted.sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
          this.setState(
            {
              data: _data,
              allUsersList: _allUsersListSorted.map(o => ({ ...o, fullName: o.firstName + " " + o.lastName })).filter(i => ["porzio@porzio.com", "porzio@porziols.com"].indexOf(i.loginName.toLowerCase()) === -1),
            },
            () => {
              // change the state after all the above are assigned
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        }
      )
    );
  }

  handleCreate = () => {
    this.setState({
      showTaskDialog: true,
      inputAction: CrudAction.CREATE,
      updateData: null,
    });
  }

  handleUpdate = (_updateData) => {
    this.setState({
      inputAction: CrudAction.UPDATE,
      updateData: _updateData,
    },
      () => {
        this.setState({ showTaskDialog: true, })
      }
    );
  }

  getFullName = (_allUsersList, _taskAssignedTo) => {
    const oFoundUser = _allUsersList.find(u => u.id === _taskAssignedTo);
    let oOutName = "";
    if (oFoundUser) {
      if (oFoundUser.hasOwnProperty('firstName')) {
        oOutName = oOutName + oFoundUser.firstName;
      }
      if (oFoundUser.hasOwnProperty('lastName')) {
        oOutName = oOutName + " " + oFoundUser.lastName;
      }
    }
    return oOutName;
  }

  /**Render */
  render() {
    const { classes } = this.props;

    if (RolePermissionService.TASK.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes);
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent small classes={classes} label="Loading Tasks" />
          );
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <>
              {/* Header */}
              <PageDynamicHeaderComponent classes={classes} label="Tasks" divider rightPadding={16}
                leftActions={[
                  { icon: MatIconService.MENU, onClick: () => { }, title: "Menu", iconColor: "primary" },
                ]}
                rightActions={[
                  { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Create", iconColor: "secondary", onClick: this.handleCreate, isReadOnly: RolePermissionService.TASK_DETAIL.cannotCreate },
                  { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                ]}
              />
              {/* Grid */}
              <List dense={MatSpecService.useDenseLayout} style={{ maxHeight: 'calc(90vh - 102px)', overflow: 'auto' }}>
                {this.state.data.map((o, index, array) => (
                  <React.Fragment key={LayoutService.getKey(o.porzioGSTTaskId)}>
                    <ListItem button onClick={() => this.handleUpdate(o)} alignItems="flex-start">
                      <ListItemIcon>{MatIconService.TASK_32}</ListItemIcon>
                      <ListItemText
                        primary={o.taskName}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body1"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {this.getFullName(this.state.allUsersList, o.taskAssignedTo)}
                            </Typography>
                            <br />
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="error"
                            >
                              {o.taskDueDate.substring(0, 10)}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    {/* {true && index < array.length - 1 ? <Divider variant="inset" component="li" /> : null} */}
                  </React.Fragment>
                ))}
              </List>

              {this.state.showTaskDialog ?
                <TaskDetailsComponent showTaskDialog={this.state.showTaskDialog} inputAction={this.state.inputAction} porzioGstIds={undefined}
                  taskLauncher={TaskLauncher.TASK_LIST}
                  updateData={this.state.updateData} refreshTaskList={this.fetchData}
                  onClose={() => this.setState({ showTaskDialog: false })}
                />
                : null}
            </>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading TaskList"
              classes={classes}
              onRetry={() => {
                this.fetchData(true, this.context);
              }}
            />
          );
      }
    }
  }
}

/** HOC */
export default withStyles(MatClassService)(TaskListComponent);
