//import { fade, makeStyles } from "@material-ui/core/styles";
// import { createStyles } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import MatThemeService from "./matThemeService";

/**
 *
 * @param {will be auto Injected by the calling component's withStyles} _theme
 */
export const MatClassService = (_theme) => ({
  root: { flexGrow: 1 },
  menuPaper: {
    maxHeight: 200,
  },
  themeColor: {
    color: MatThemeService.isDarkMode() ? null : _theme.palette.primary.main,
  },
  headerTitle: {
    color: MatThemeService.isDarkMode() ? null : _theme.palette.primary.main,
    flexGrow: 1,
  },

  pageHeaderDividerWithIcons: {
    // TOP:0, RIGHT:16, BOTTOM:16, LEFT:0
    margin: _theme.spacing(0, 2, 2, 0),
  },
  pageHeaderDividerWithoutIcons: {
    // TOP:16, RIGHT:16, BOTTOM:16, LEFT:0
    margin: _theme.spacing(2, 2, 2, 0),
  },
  pageLoadingHeader: {
    margin: _theme.spacing(2, 1, 4),
  },
  pageErrorHeader: {
    color: MatThemeService.isDarkMode() ? null : _theme.palette.error.dark,
    margin: _theme.spacing(2, 1, 4),
  },
  sectionHeader: {
    color: MatThemeService.isDarkMode() ? null : _theme.palette.primary.light,
    margin: _theme.spacing(2, 1, 4),
  },
  iconError: {
    color: MatThemeService.isDarkMode() ? _theme.palette.error.main : _theme.palette.error.dark,
  },
  buttonError: {
    background: MatThemeService.isDarkMode() ? _theme.palette.error.main : _theme.palette.error.dark,
    color: "white",
  },
  groupTitle: {
    margin: _theme.spacing(1, 1, 1),
  },
  listGroup: {
    backgroundColor: _theme.palette.background.default,
  },
  gridSpacing: {
    margin: _theme.spacing(2, 1, 4),
  },
  table: {
    minWidth: 750,
  },
  tableCellOverflow: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
    overflow: "hidden",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  // WARNING-STYLES
  // eg: <TextFiled className={_hasWarning ? classes.warningStyles : null}
  warningStyles: {
    "& .MuiFormLabel-root.Mui-error": {
      color: "orange !important"
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "orange !important"
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "orange !important"
    },
  },
  

  // SEARCH
  search: {
    position: "relative",
    borderRadius: _theme.shape.borderRadius,
    backgroundColor: fade(_theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(_theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: 16,
    width: "100%",
    [_theme.breakpoints.up("sm")]: {
      marginLeft: _theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: _theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: _theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${_theme.spacing(4)}px)`,
    transition: _theme.transitions.create("width"),
    width: "100%",
    [_theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },


  // SEARCH-Light theme
  searchOnBody: {
    position: "relative",
    borderRadius: _theme.shape.borderRadius,
    backgroundColor: fade(_theme.palette.background.paper, 0.56),
    "&:hover": {
      backgroundColor: fade(_theme.palette.background.paper, 1),
    },
    marginLeft: 0,
    marginRight: 16,
    width: "100%",
    [_theme.breakpoints.up("sm")]: {
      marginLeft: _theme.spacing(1),
      width: "auto",
    },
  },
  searchIconOnBody: {
    padding: _theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: fade(_theme.palette.common.black, 0.54),
  },
  inputRootOnBody: {
    color: "inherit",
  },
  inputInputOnBody: {
    padding: _theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${_theme.spacing(4)}px)`,
    transition: _theme.transitions.create("width"),
    width: "100%",
    [_theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },


});




// // TODO: move the commons to the materiel theme service and refer them ehere
// export const MatClassService = makeStyles((_theme) =>
//   createStyles({
//     root: { flexGrow: 1 },
//     themeColor: {
//       color: MatThemeService.isDarkMode() ? null : _theme.palette.primary.main,
//     },
//     pageTitleSpacing: {
//       margin: _theme.spacing(2, 1, 4),
//     },
//     groupTitle: {
//       margin: _theme.spacing(1, 1, 1),
//     },
//     listGroup: {
//       backgroundColor: _theme.palette.background.default,
//     },
//     gridSpacing: {
//       margin: _theme.spacing(2, 1, 4),
//     },
//   })
// );
