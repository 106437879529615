import React from "react";
import { from, of, combineLatest, BehaviorSubject, } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  DialogTitle,
  DialogContent,
  FormGroup,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  TextField,
} from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import LayoutService from "../../../shared/services/layoutService";
import ApiService from "../../../shared/services/apiService";
import NonEntityDetailsService from "./nonEntityDetailsService";
import LookupService from "../../../shared/services/lookupService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { SubscriptionsOutlined } from "@material-ui/icons";


class NonEntityDetailsComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();
  scopedSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      data: [],
      openDeleteConfirm: false,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
    this.scopedSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        NonEntityDetailsService.getNonEntityLOVsByIDAsOBS(this.context.user.tenantId, 0),
        NonEntityDetailsService.getNonEntityByTenantIdAsOBS(this.context.user.tenantId, 0),
      ]).subscribe(
        // success
        ([_nonEntityLovMaster, _nonEntity]) => {
          this.setState(
            {
              nonEntityLovMaster: _nonEntityLovMaster,
              nonEntity: _nonEntity,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        lovId: "",
        lovEntityId: 6,
        lovEntityDesc: "",
        lovKey: "",
        isActive: true,
      };

    } else {
      return {
        lovId: this.props.modalAgNode.lovId,
        lovEntityId: this.props.modalAgNode.lovEntityId,
        lovEntityDesc: this.props.modalAgNode.lovEntityDesc,
        lovKey: this.props.modalAgNode.lovKey,
        isActive: this.props.modalAgNode.isActive,
      };
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      lovKey: Yup.string()
        .required("Required")
        .max(256, "Must be 256 characters or less")
        .test(
          "checkDuplicateNonEntityName",
          "NonEntity Name already exists",
          async (_formikValue) => {
            if (this.props.inputAction === CrudAction.UPDATE) {
              if (_formikValue === this.props.modalAgNode.lovKey) {
                return true;
              }
            }
            try {
              const isExists = this.checkDuplicateNonEntityName(this.state.nonEntityLovMaster, _formikValue);
              console.log("is Entity Name exists: ", isExists);
              return !isExists;
            } catch (error) {
              console.log("Product Exists Error:", error);
              this.setState({
                fetchResult: ResultStatus.ERROR,
              });
            }
          }
        ),
      isActive: Yup.boolean().required("Required"),
    });
    return this.validationSchema;
  }



  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // 0) clear the CACHE,so next time if requried this new object will also be fetched
          // LookupService.clearPRODUCTS();

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            lovEntityId: _formikProps.values.lovEntityId,
            lovEntityDesc: this.state.nonEntity.filter(a => a.nonentityid === 6)[0].fielD_NAME,
            lovKey: _formikProps.values.lovKey,
            isActive: _formikProps.values.isActive,
          }
          console.log("Test before mapped");
          // 3) determine the action and assign the appropriate props
          let actionVerb = "";
          let targetUrl = "";
          if (this.props.inputAction === CrudAction.UPDATE) {
            // UPDATE
            actionVerb = "POST";
            targetUrl = `/Util/AddNonEntityLOVs/${this.context.user.tenantId}`;
            mappedObj.lovId = this.props.modalAgNode.lovId;
            mappedObj.createdBy = this.props.modalAgNode.createdBy;
            mappedObj.createdDate = this.props.modalAgNode.createdDate;
            mappedObj.updatedBy = this.context.user.userId;
            mappedObj.updatedDate = new Date().toISOString().slice(0, 10);
          } else {
            // CREATE
            actionVerb = "POST";
            targetUrl = `/Util/AddNonEntityLOVs/${this.context.user.tenantId}`;
            mappedObj.id = 0;
            mappedObj.createdBy = this.context.user.userId;
            mappedObj.createdDate = new Date().toISOString().slice(0, 10);
          }
          console.log("Before APi");
          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            targetUrl,
            JSON.stringify(mappedObj)
          ).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshNonEntityList(true);
                this.props.onClose(false);
              } else {
                console.error("Error: falsey successResult while saving NonEntity", successResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving NonEntity", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }
  PopulateType(nonEntity) {
    return nonEntity.filter(a => a.nonentityid === 6);
  }

  checkDuplicateNonEntityName(objNonEntity, lovKey) {
    if (lovKey === undefined) return false;

    for (const obj in objNonEntity) {
      if (objNonEntity[obj].lovKey === lovKey)
        return true;
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    if (RolePermissionService.PRODUCT_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading NonEntity Details"
            />
          );
        case ResultStatus.SAVING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Saving NonEntity Details"
            />
          );
        case ResultStatus.SUCCESS:
        case ResultStatus.LOADED:
          return (
            <>
              <Formik
                initialValues={this.getInitialValues()}
                validationSchema={this.getValidationSchema()}
                validationSchemaOptions={{ showMultipleFieldErrors: true }}
              >
                {(fProps) => (
                  <form>
                    <DialogTitle disableTypography id="dialogTitle">
                      <AppBar position="static">
                        <Toolbar variant="dense">
                          <Typography variant="h6" className={classes.root}>NonEntity Details</Typography>
                          {this.props.inputAction === CrudAction.UPDATE ? RolePermissionService.PRODUCT_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                            () => { this.props.onClose(false) }, "secondary")
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                          }
                          {/* {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })} */}
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    {/* Dialog Content */}
                    <DialogContent>
                      <Box
                        style={{
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 16,
                          paddingBottom: 32,
                          minWidth: "40vh",
                          maxWidth: "60vh",
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            {LayoutService.getSelectControl(this.state.isReadOnly,
                              classes,
                              fProps,
                              "lovEntityId",
                              "Entity Type",
                              this.PopulateType(this.state.nonEntity),
                              "nonentityid",
                              "fielD_NAME",
                              "95%",
                              false
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              {...LayoutService.getInputProps(this.state.isReadOnly,
                                classes,
                                fProps,
                                "lovKey",
                                "Entity Name"
                              )}
                              style={{ minWidth: "95%" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "isActive", "Active")}
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContent>
                  </form>
                )}
              </Formik>
            </>
          );

        case ResultStatus.ERROR:
          return (
            <PageErrorComponent
              small
              label="Error Loading NonEntity Details"
              classes={classes}
              onRetry={() => {
                this.SERVICE.fetchData(this, true);
              }}
              onClose={this.props.onClose}
            />
          );
        default:
          return (null);
      }
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(NonEntityDetailsComponent);