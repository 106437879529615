import React from "react";
import { combineLatest } from 'rxjs';
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router";

import { Grid, Paper, Divider, Typography, CircularProgress, Button, withStyles, Box } from "@material-ui/core";
import { ResultStatus } from "../../shared/types/enums";
import porzio_gst_icon from "../../public/images/porzio_global_spend_transparency_logo_small.png";
import ContainerComponent from "../../private/shell/containerComponent";
import UserInfoService from "./userInfoService";
import { DataService } from "../../shared/services/dataService";
import ToastService from "../../shared/services/toastService";
import { MatClassService } from "../../shared/services/theme/matClassService";
import SessionService from "../../shared/services/sessionService";
import { AuthContext } from "../../shared/store/authProvider";
import LayoutService from "../../shared/services/layoutService";
import ApplicationService from "../../shared/services/applicationService";
import MatThemeService from "../../shared/services/theme/matThemeService";
import ApiService from "../../shared/services/apiService";
import { API_ENDPOINT } from "../../shared/types/enums";


class MultiTenantSelectionComponent extends React.Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            statusMessage: '',
            userInfoObj: {},
            userTenantList: [],
            selTenantId: '',
            fetchState: ResultStatus.NOT_LOADED,
        }
    }

    getInitialValues() { return { selTenantId: this.state.selTenantId }; }

    validationSchema = Yup.object().shape({});
    getValidationSchema() {
        this.validationSchema = Yup.object().shape({
            selTenantId: Yup.number().required("Required").typeError('Must select a Tenancy'),
        });
        return this.validationSchema;
    }

    componentDidMount() {
        this.loadTenants();
    }

    loadTenants = () => {
        this.setState({ statusMessage: 'Loading Tenants...', userTenantList: [], fetchState: ResultStatus.LOADING });
        UserInfoService.getByEmail(this.props.userEmail).subscribe(
            (_userInfoObj) => {
                this.setState({ userInfoObj: _userInfoObj }); // set the userObject 
                //Updating the User Last Login info in DB                
                this.updateLastLogin(_userInfoObj.id);  
                if (DataService.stringNotEquals(_userInfoObj.userName, this.props.userEmail)) { this.showError('Applicaiton Login Error.'); }
                else if (!_userInfoObj.isActive) { this.showError('Please contact your Administrator for access.'); }
                else {
                    UserInfoService.getUserTenants(_userInfoObj.id).subscribe(
                        (_userTenantList) => {
                            if (DataService.hasNoElements(_userTenantList)) {
                                this.showError('No Tenants found for your Account.');
                            } else if (_userTenantList.length === 1) {
                                this.setTenancy(_userTenantList[0]);
                            } else {

                                this.setState({
                                    statusMessage: `Welcome ${_userInfoObj.firstName} ${_userInfoObj.lastName}`,
                                    userTenantList: _userTenantList,
                                    fetchState: ResultStatus.LOADED
                                })
                            }
                        },
                        (errorObj) => { this.showError('Error loading Tenants'); }
                    );
                }
            },
            (errorObj) => { this.showError('Error loading Tenants'); }
        );
    }

    handleTenantChange = async (fProps) => {
        if (!fProps.isSubmitting && fProps.isValid) {
            await this.validationSchema.validate(fProps.values, { abortEarly: false })
                .then((x) => {
                    this.setState({ fetchResult: ResultStatus.SAVING });
                    const selTenantObj = this.state.userTenantList.find(o => o.tenantid === fProps.values.selTenantId);
                    this.setTenancy(selTenantObj);
                })
                .catch((erroObj) => {
                    ToastService.showError("Pick your Tenancy.");
                    erroObj.inner.forEach(err => { fProps.setFieldError(err.path, err.message); });
                });
        }
    }

    setTenancy = (selTenantObj) => {
        this.setState({ statusMessage: `Fetching ${selTenantObj.tenantName} Details...`, fetchState: ResultStatus.LOADING });

        combineLatest([
            UserInfoService.getUserTenantInfo(this.state.userInfoObj.id, selTenantObj.tenantid),
            UserInfoService.getSessionInfo(this.state.userInfoObj.id, selTenantObj.tenantid)
        ]).subscribe(
            ([tenantUserInfoByIdJson, sessionObjJson]) => {
                if (tenantUserInfoByIdJson && sessionObjJson) {
                    SessionService.setLoginInfo(this.context, this.state.userInfoObj, selTenantObj, tenantUserInfoByIdJson, sessionObjJson, this.state.userTenantList)
                        .subscribe((_result) => {
                            if (_result) { this.setState({ fetchState: ResultStatus.SUCCESS }); }
                            else { this.showError('Unspecified Error occured.'); }
                        });
                } else { this.showError('Unspecified Error occured.'); }
            },
            (error) => { this.showError('Unspecified Error occured.'); }
        );
    }

    showError = (_message) => {
        ToastService.showError(_message);
        this.setState({ statusMessage: _message, fetchState: ResultStatus.ERROR });
    }

    render() {
        const { classes } = this.props;

        switch (this.state.fetchState) {
            case ResultStatus.SUCCESS:
                return <ContainerComponent topBarColor={this.props.topBarColor} onThemeChange={this.props.onThemeChange} />
            default:
                return (
                    <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }} >
                        {(fProps) => (
                            <form>
                                <Grid container direction="column" justifyContent="start" alignItems="center" style={{ height: '100vh' }}>
                                    <Paper style={{ border: MatThemeService.borderStyle, marginTop: 102, width: 440, height: 428, textAlign: "center", }} >
                                        <img src={porzio_gst_icon} style={{ marginBlock: 16, width: 252, height: 81 }} alt="Porzio Global Spend Transparency Logo" />
                                        <Divider />
                                        <Typography variant="h6" style={{ marginTop: 16 }} color="primary">{this.state.statusMessage}</Typography>
                                        {this.state.fetchState === ResultStatus.ERROR ? <Button style={{ marginTop: 16, height: 42, width: 364 }} variant="contained" color="secondary" onClick={() => { this.loadTenants(); }}>Retry</Button> : null}
                                        {this.state.fetchState === ResultStatus.LOADING ? <CircularProgress style={{ marginTop: 56, height: 128, width: 128 }} /> : null}
                                        {this.state.fetchState === ResultStatus.LOADED ?
                                            <Grid container direction="column" justifyContent="start" alignItems="center" style={{ paddingTop: 40 }}>
                                                {LayoutService.getSelectControlOutlined(this.state.fetchResult === ResultStatus.SAVING, classes, fProps, "selTenantId", "Pick Your Tenancy", this.state.userTenantList, "tenantid", "tenantName", "80%")}
                                                <Button style={{ marginTop: 16, height: 42, width: 364 }} disabled={this.state.fetchResult === ResultStatus.SAVING} variant="contained" color="primary" onClick={() => { this.handleTenantChange(fProps); }}>Next</Button>
                                            </Grid>
                                            : null}

                                        {/* <Box style={{ marginTop: this.state.fetchState === ResultStatus.LOADED ? "50px" : "25px", paddingBottom: "16px", display: "flex",  alignItems: "center", justifyContent: "center", wordWrap: "break-word" }}>
                                            <Typography variant="caption" style={{ fontStyle: "italic" }}>{ApplicationService.copyrightString}</Typography>
                                        </Box> */}

                                        <Box style={{ marginTop: this.state.fetchState === ResultStatus.LOADED ? "10px" : "10px", paddingBottom: "16px", display: "flex",  alignItems: "center", justifyContent: "center", wordWrap: "break-word" }}>
                                            <Typography variant="caption" style={{ fontStyle: "italic" }}>{ApplicationService.copyrightString}</Typography>
                                        </Box>
                                        <Box style={{  marginTop: this.state.fetchState === ResultStatus.LOADED ? "0px" : "0px", display: "flex",  alignItems: "center", justifyContent: "center", wordWrap: "break-word" }}>
                                            <Typography variant="caption" style={{ fontStyle: "italic" }}>{ApplicationService.copyrightStringRLDatix}</Typography>
                                        </Box>

                                    </Paper>
                                </Grid >
                            </form>
                        )}
                    </Formik>
                );
        }
    }

    updateLastLogin = (_userId) => {
        const targetUrl = `/User/SaveUserLastLogin?userId=${_userId}`
        ApiService.postOBS(API_ENDPOINT.IDENTITY, targetUrl,)
    }
    



    //---
}
export default withStyles(MatClassService)(withRouter(MultiTenantSelectionComponent));