import ApiService from "../../../../shared/services/apiService";
import Cache from "../../../../shared/store/cache";
import { CrudAction, API_ENDPOINT } from "../../../../shared/types/enums";
import util from "../../../../shared/utilities/xmlToJSON";
import { AgGridService } from "../../../../shared/services/ag-grid/agGridService";
import { ReplaySubject } from 'rxjs';
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import { DataService } from "../../../../shared/services/dataService";

export default class ViewFieldService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static fetchFieldValueAsObs(clientAuthContext, fieldId, isPorzio) {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/EntityField/GetFieldValueById?tenantId=${clientAuthContext.user.tenantId}&fieldId=${fieldId}&isPorzio=${isPorzio}`,
      {},
    );
  }

  static fetchEntityAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/Entity/${_tenantId}`
    );
  };

  static getInitialValues(props, state) {
    this.props = props;
    this.state = state;
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        fieldName: "",
        systemAlias: "",
        toolTip: "",
        description: "",
        switchPLSRequired: false,
        switchActive: true,
        switchTextSearchable: false,
        switchBooleanDefault: false,
        dateTimeDefault: null,
        dateDefault: null
      };
    } else {
      let fieldTypes = {};
      let tVal0, tVal1, tVal2, tVal3;
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(
        this.state.data.lookUpList,
        "text/xml"
      );
      let t = util.xmlToJson(xmlDoc);

      if (t.Field != undefined) {
        tVal0 =
          t.Field.Attribute[0] == undefined
            ? 2
            : t.Field.Attribute[0]["@attributes"].value;
        tVal1 =
          t.Field.Attribute[1] == undefined
            ? 0
            : t.Field.Attribute[1]["@attributes"].value;
        tVal2 =
          t.Field.Attribute[2] == undefined
            ? 100
            : t.Field.Attribute[2]["@attributes"].value;
        tVal3 =
          t.Field.Attribute[3] == undefined
            ? 10
            : t.Field.Attribute[3]["@attributes"].value;

        switch (this.state.field_Type_Id) {
          case 1:
            fieldTypes = {
              numericDecimalPlaces: tVal0,
              numericMin: tVal1,
              numericMax: tVal2,
              numericDefault: tVal3,
            };
            break;
          case 2:
            fieldTypes = {
              switchTextSearchable: tVal0 === "true" ? true : false,
              textDefault: t.Field.Attribute[0]["@attributes"].default,
            };
            break;
          case 3:
            fieldTypes = {
              dateTimeDefault: t.Field.Attribute["@attributes"].value === "null" ? null : new Date(t.Field.Attribute["@attributes"].value.split('-')),
            };
            break;
          case 4:
            fieldTypes = {
              switchBooleanDefault:
                t.Field.Attribute["@attributes"].default === "true"
                  ? true
                  : false,
            };
            break;
          case 5:
            fieldTypes = {
              LovDefault: "",
            };
            break;
          case 6:
            fieldTypes = {
              dateDefault: t.Field.Attribute["@attributes"].value === "null" ? null : new Date(t.Field.Attribute["@attributes"].value.split('-')),
            };
            break;
          case 7:
            fieldTypes = {
              decimalDecimalPlaces: tVal0,
              decimalMin: tVal1,
              decimalMax: tVal2,
              decimalDefault: tVal3,
            };
            break;
          default:
            fieldTypes = {
              numericDecimalPlaces: tVal0,
              numericMin: tVal1,
              numericMax: tVal2,
              numericDefault: tVal3,
            };
            break;
        }
      }
      return {
        fieldName: this.props.modalAgNode.field_Name,
        systemAlias: this.props.modalAgNode.fielD_ALIASNAME,
        toolTip: this.state.data.toolTip,
        description: this.props.modalAgNode.field_Description,
        fieldType: this.props.modalAgNode.fielD_TYPE,
        switchPLSRequired: this.props.modalAgNode.iS_PLSREQUIRED,
        switchActive: this.props.modalAgNode.active,
        switchTextSearchable: fieldTypes.switchTextSearchable ? fieldTypes.switchTextSearchable : false,
        switchBooleanDefault: fieldTypes.switchBooleanDefault ? fieldTypes.switchBooleanDefault : false,

        ...fieldTypes,
      };
    }
  }

  static getLookUpListValue(_fProps, _field_Type_Id) {
    let tVal0;
    switch (_field_Type_Id) {
      case 1:
        tVal0 = `<Field Type='Numeric'><Attribute name='DecimalPlaces' value='${_fProps.values.numericDecimalPlaces}' default='${_fProps.values.numericDecimalPlaces}' />
        <Attribute name='Min' value='${_fProps.values.numericMin}' /><Attribute name='Max' value='${_fProps.values.numericMax}' /><Attribute name='Default' default='${_fProps.values.numericDefault}' value='${_fProps.values.numericDefault}' /></Field>`;
        break;
      case 2:
        tVal0 = `<Field Type="Text"><Attribute name="Searchable" value="${_fProps.values.switchTextSearchable}" default="${_fProps.values.textDefault}" /><Attribute name="Default" /></Field>`;
        break;
      case 3:
        tVal0 = `<Field Type="DateTime"><Attribute name="Default" value="${_fProps.values.dateTimeDefault ? DataService.formatDate(_fProps.values.dateTimeDefault) : null}" /></Field>`;
        break;
      case 4:
        tVal0 = `<Field Type="CheckBox"><Attribute name="Default" value="${_fProps.values.switchBooleanDefault}" 
        default="${_fProps.values.switchBooleanDefault}" /></Field>`;
        break;
      case 5:
        tVal0 = null;
        break;
      case 6:
        tVal0 = `<Field Type="Date"><Attribute name="Default" value="${_fProps.values.dateDefault ? DataService.formatDate(_fProps.values.dateDefault) : null}" /></Field>`;
        break;
      case 7:
        tVal0 = `<Field Type='Numeric'><Attribute name='DecimalPlaces' value='${_fProps.values.decimalDecimalPlaces}' default='${_fProps.values.decimalDecimalPlaces}' />
        <Attribute name='Min' value='${_fProps.values.decimalMin}' /><Attribute name='Max' value='${_fProps.values.decimalMax}' />
        <Attribute name='Default' default='${_fProps.values.decimalDefault}' value='${_fProps.values.decimalDefault}' /></Field>`;
        break;
      default:
        break;
    }
    return tVal0;
  }

  //#region Address Grid
  static validateEntityFieldRowEditOBS = (_cellRef) => {
    var oSubject = new ReplaySubject(); // 1st

    // static/async validation logic should trigger the below
    oSubject.next({ isSuccess: true }); // true <- success

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  static trimText = (_params) => {
    const _newValue = _params.newValue?.trim();
    const _oldValue = _params.data.lovKey?.trim();

    // // check for duplicates
    // let hasDuplicates = false;
    // _cellRef.props.api.forEachNode((rowNode, rowIndex) => {
    //   if (_cellRef.props.node.rowIndex !== rowIndex && DataService.stringEquals(_cellRef.props.data["lovKey"], rowNode.data["lovKey"])) {
    //     hasDuplicates = true;
    //   }
    // })
    // if (hasDuplicates) {
    //   oSubject.next({ isSuccess: false, invalidCellRef: _cellRef, invalidMessage: 'Value Already exists' }); // false <- failure
    // } else {
    //   oSubject.next({ isSuccess: true }); // true <- success
    // }

    _params.data.lovKey = _newValue;
    return true;
  }

  static getColumnDefs = (_componentThisRef, _isReadOnly, _agGridUtils) => {
    let cols = [
      // edit button column
      _agGridUtils.getInlineEditActionColumn(false, this.validateEntityFieldRowEditOBS),
      // other columns   
      AgGridColumnExt.GET(false, "text", "isDefault", "Default").fixedWidth(96).cellRenderer("isPrimaryCellRenderer", { allowUnCheck: true }).SET(),
      AgGridColumnExt.GET(false, "text", "isActive", "Active").fixedWidth(96).cellRenderer("isActiveCellRenderer").SET(),
      AgGridColumnExt.GET(true, "text", "lovKey", "Key").valueSetter(_params => this.trimText(_params)).flexWeight(1).SET(),
    ];
    if (_componentThisRef.props.modalAgNode.fieldId === 11 )
      cols.splice(3, 0, AgGridColumnExt.GET(false, "text", "isLicensed", "Licensed").fixedWidth(96).cellRenderer("isActiveCellRenderer").SET(),)
    return cols;
  };
}
