import LookupService from "./lookupService";
import moment from "moment";

export class SubscriptionArray {
  _items = [];

  add(_subscription) {
    if (!this._items) {
      this._items = [];
    }
    this._items.push(_subscription);
  }
  cancelAll() {
    if (DataService.hasElements(this._items)) {
      this._items.forEach((o) => {
        o.unsubscribe();
      });
      this._items = [];
    }
  }
}

export class DataService {
  static cancelSubscriptions(_subscriptions = []) {
    if (this.hasElements(_subscriptions)) {
      _subscriptions.forEach((o) => {
        o.unsubscribe();
      });
    }
    _subscriptions = [];
  }

  static isNullOrUndefined(_obj) {
    return _obj === undefined || _obj === null;
  }
  static hasValue(_obj) {
    return !this.isNullOrUndefined(_obj);
  }

  static parseBoolean = (_value) => {
    return (_value === true);
  }

  static mapHasElements(_map) {
    return this.hasValue(_map) && _map.size > 0;
  }
  static mapHasNoElements(_map) {
    return !this.mapHasElements(_map);
  }
  static getMapKeys(_map) {
    return this.mapHasElements(_map) ? [..._map.keys()] : [];
  }
  static getMapByKeys(_map, _keys) {
    if (DataService.mapHasNoElements(_map)) {
      return _map;
    } else {
      let _oRET = new Map();
      _keys.forEach(_key => {
        if (_map.has(_key)) { _oRET.set(_key, _map.get(_key)); }
      });
      return _oRET;
    }
  }

  static caseInsensitiveComparator(valueA, valueB) {
    return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
  }

  static hasAnyTrue = (_booleanArray) => {
    if (DataService.hasNoElements(_booleanArray)) {
      return false;
    } else {
      _booleanArray.some(_bool => {
        if (_bool) { return true; }
      });
      return false;
    }
  }
  static hasAnyFalse = (_booleanArray) => {
    if (DataService.hasNoElements(_booleanArray)) {
      return true;
    } else {
      _booleanArray.some(_bool => {
        if (!_bool) { return true; }
      });
      return false;
    }
  }
  static isAllTrue = (_booleanArray) => {
    let oRET = false;
    if (DataService.hasElements(_booleanArray)) {
      oRET = true;
      _booleanArray.forEach(_bool => {
        if (oRET && !_bool) { oRET = false; }
      });
    }
    return oRET;
  }

  static hasElements(_array = []) {
    return this.hasValue(_array) && _array.length > 0;
  }

  static hasNoElements(_array = []) {
    return !this.hasElements(_array);
  }

  static isStringNullOrEmpty(_string = "") {
    return this.isNullOrUndefined(_string) || (_string + "").trim() === "";
  }
  static hasValidValue(_string = "") {
    return !this.isStringNullOrEmpty(_string);
  }
  static stringShouldBe(_string, _minLength = 3) {
    return this.isNullOrUndefined(_string) || (_string + "").trim().length >= _minLength;
  }

  /** Ignores Case */
  static stringEquals = (_source, _target) => {
    _source = (_source + "").trim().toLowerCase();
    _target = (_target + "").trim().toLowerCase();
    return (_source === _target);
  }

  /** Ignores Case */
  static stringNotEquals = (_source, _target) => {
    return !DataService.stringEquals(_source, _target);
  }

  static getFirstOrDefault(_array, _default = null) {
    if (DataService.hasElements(_array)) {
      return _array[0];
    }
    return _default;
  }

  static getLowerCaseElements(_array) {
    let oRET = [];
    if (this.hasElements(_array)) {
      _array.forEach(x => {
        oRET.push((x + "").toLowerCase());
      });
    }
    return oRET;
  }

  static getLastOrDefault(_array, _default = null) {
    if (DataService.hasElements(_array)) {
      return _array[_array.length - 1];
    }
    return _default;
  }

  static arrayToObject = (_array, _keyProp, _valueProp) => {
    if (DataService.hasNoElements(_array)) { return {}; }
    else {
      return _array.reduce((obj, item) => {
        obj[item[_keyProp]] = item[_valueProp];
        return obj;
      }, {});
    }
  }

  static stringToArray = (_string, _delim) => {
    if (DataService.isStringNullOrEmpty(_string) || DataService.isStringNullOrEmpty(_delim)) {
      return [];
    } else if (!_string.includes(_delim)) {
      return [_string];
    }
    else {
      let oRET = [];
      const splited = _string.split(_delim);
      splited.forEach(x => { oRET.push(x); });
      return oRET;
    }
  }

  static insertAt = (_targetArray, _targetIndex, _itemToInsert) => {
    if (this.hasValidValue(_targetArray)) {
      const maxIndex = _targetArray.length - 1;
      if (maxIndex <= _targetIndex) {
        _targetArray.push(_itemToInsert); // push at the end
      } else {
        _targetArray.splice(_targetIndex, 0, _itemToInsert);
      }
    }
    return _targetArray;
  }

  static getUniqueValues(_array, _uniqueProp) {
    if (DataService.hasNoElements(_array)) { return _array; }
    else {
      let oRET = [];

      _array.forEach(item => {
        const value = item[_uniqueProp];
        if (!oRET.includes(value)) {
          oRET.push(value);
        }
      });

      return oRET;
    }
  }

  static insertItems = (_targetArray, _targetIndex, _elementsToInsert) => {
    if (this.hasValidValue(_targetArray)) {
      const maxIndex = _targetArray.length - 1;
      if (_targetIndex > maxIndex) {
        _targetArray.push(..._elementsToInsert); // push at the end
      } else {
        _targetArray = [
          // part of the array before the specified index
          ..._targetArray.slice(0, _targetIndex),
          // items to insert
          ..._elementsToInsert,
          // part of the array after the specified index
          ..._targetArray.slice(_targetIndex)

        ];
      }
    }
    return _targetArray;
  }

  /**
   * False if <Null/Undefined/""/ invalid chars>
   * True if integer or decimal
   * @param {*} _value 
   */
  static isValidNumber = (_value) => {
    // null / undefined / ""
    if (this.isStringNullOrEmpty(_value)) {
      return false;
    } else if (Number.isNaN(_value)) {
      return false;
    } else {
      return true;
    }
  }

  static isNotNumber = (_value) => {
    return !this.isValidNumber(_value);
  }

  /**
   * 
   * @param {*} _value number/string/null/undefined
   * @param {*} _default returns the desired value if invalid number
   */
  static getNumberOrDefault = (_value, _default = null) => {
    return this.isValidNumber(_value) ? Number(_value) : _default;
  }

  static extractKeysAsNumbers = (_mappings) => {
    return Object.keys(_mappings).map((x) => parseInt(x));
  };
  static extractValues = (_mappings) => {
    return Object.values(_mappings);
  };
  static getLookupValue = (_mappings, _key) => {
    return _mappings[_key];
  };

  static sortArray(_array, _prop) {
    if (this.hasNoElements(_array)) {
      return _array;
    } else {
      return _array.sort((a, b) => (a[_prop] > b[_prop]) ? 1 : -1);
    }
  }

  static removeFromArray(_array, _index) {
    if (DataService.hasElements(_array) && _index >= 0 && _index < _array.length) {
      _array.splice(_index, 1);
      return _array;
    } else {
      return _array;
    }
  }

  static sortArrayBy2Props(_array, _prop1, _prop2) {
    if (this.hasNoElements(_array)) {
      return _array;
    } else {
      return _array.sort((a, b) => {
        return a[_prop1] < b[_prop1] ? -1 : a[_prop2] < b[_prop2] ? -1 : 1;
      });
    }
  }

  static sortObjectByEntries(_object, _sortProp) {
    if (this.isNullOrUndefined(_object)) {
      return _object;
    } else {
      const entries = Object.entries(_object);
      const keys = Object.keys(_object);
      if (keys.includes(_sortProp)) {
        return entries.sort((a, b) => a[1][_sortProp] - b[1][_sortProp]).map((x) => x[1]);
      }
      else {
        return _object;
      }
    }
  }

  static sortObjectByKeys = (_obj) => {
    if (this.isNullOrUndefined(_obj)) { return {}; }
    else {
      return Object.keys(_obj).sort().reduce(function (result, key) {
        result[key] = _obj[key];
        return result;
      }, {});
    }
  }
  static getEmptyStringIfNull = (_obj, key) => {
    if (this.isNullOrUndefined(_obj) || this.isNullOrUndefined(_obj[key])) { return ""; }
    else {
      return _obj[key];
    }
  }
  /**
   * returns the object with no values
   * @param _sampleObject if an array pass then pass only the first element
   */
  getEmptyObject = (_sampleObject) => {
    let oRET = {};

    if (this.hasValue(_sampleObject)) {
      const keys = Object.keys(_sampleObject);
      keys.forEach(_key => {
        const _value = _sampleObject[_key];
        if (Array.isArray(_value) && _value.length > 0) {
          oRET[_key] = this.getEmptyObject(_value[0]);
        } else {
          oRET[_key] = ""; // create an entry
        }
      });
    }
    return oRET;
  }

  /**
   * adds a key to an object
   * Link : https://gomakethings.com/how-to-add-a-new-item-to-an-object-at-a-specific-position-with-vanilla-js/
   * @param _targetObj 
   * @param _newKey 
   * @param _newValue 
   * @param _newIndex inserts to the specific position or to the end if not specified
   * @returns 
  */
  static addToObject = (_targetObj, _newKey, _newValue, _newIndex) => {

    // Create a temp object and index variable
    var temp = {};

    // Loop through the original object
    var index = 0;
    for (var objProp in _targetObj) {
      if (_targetObj.hasOwnProperty(objProp)) {

        // If the indexes match, add the new item
        if (index === _newIndex && _newKey && _newValue) {
          temp[_newKey] = _newValue;
        }

        // Add the current item in the loop to the temp obj
        temp[objProp] = _targetObj[objProp];

        // Increase the count
        index++;

      }
    }

    // If no index, add to the end
    if (!_newIndex && _newKey && _newValue) {
      temp[_newKey] = _newValue;
    }

    // return
    return temp;
  };

  /**
   * Add {all} in front of an array and transforms the objects into <id,text> pair 
   * eg: [ { id: 0, text: "All" }, { id: 1, text: "Affialite 1" }, ... ]
   * @param {*} _affiliationList
   */
  static getKeyValueCollection(_objectArray, _keyProp, _valueProp, _includeAll) {
    if (this.hasElements(_objectArray)) {
      // excludes "all" if present
      _objectArray = _objectArray.filter(x => x[_keyProp] !== 0).map((el) => ({
        id: el[_keyProp],
        text: el[_valueProp],
      }));

      // add all to the top
      if (_includeAll) {
        _objectArray.splice(0, 0, LookupService.allElement);
      }
    }
    // return
    return _objectArray || [];
  }

  //
  static removeElementAll(_objectArray, _keyProp) {
    if (this.hasNoElements(_objectArray)) {
      _objectArray = [];
    }
    return _objectArray.filter(x => x[_keyProp] !== LookupService.allElement.id);
  }

  static addElementAll(_objectArray, _keyProp) {
    if (this.hasNoElements(_objectArray)) {// is null or empty
      return [];
    } else {
      var allExcluded = _objectArray.filter(x => x[_keyProp] !== LookupService.allElement.id);
      if (DataService.hasNoElements(allExcluded)) { // or contains only <all> element
        return [];
      } else {
        return [LookupService.allElement, ...allExcluded];
      }
    }
  }

  static getScopeKeys(_selectedArray, _scopeArray, _keyProp) {
    var oRet = [];

    if (this.hasNoElements(_selectedArray)) {
      _selectedArray = [];
    }

    // if all is selected
    if (this.hasElements(_selectedArray.filter(x => x[_keyProp] === LookupService.allElement.id))) {
      // then get everything from the SCOPE except all
      oRet = _scopeArray.filter(x => x[_keyProp] !== LookupService.allElement.id).map(x => x[_keyProp]);
    } else { // if all is not selected
      // then return only SELECTED
      oRet = _selectedArray.map(x => x[_keyProp]);
    }

    return oRet;
  }

  static arrayToSingleQuotedJsonString = (_array, _defaultValue = "") => {
    if (this.hasNoElements(_array)) { return _defaultValue; }
    else {
      return this.toSingleQuotedJsonString(JSON.stringify(_array));
    }
  }
  static toSingleQuotedJsonString = (_stringified, _defaultValue = "") => {
    if (this.isStringNullOrEmpty(_stringified)) {
      return _defaultValue;
    } else {
      _stringified = _stringified.replace(/\\"/g, '"')
        .replace(/([\{|:|,])(?:[\s]*)(")/g, "$1'")
        .replace(/(?:[\s]*)(?:")([\}|,|:])/g, "'$1")
        .replace(/([^\{|:|,])(?:')([^\}|,|:])/g, "$1\\'$2");
      return _stringified;
    }

  }

  /**
   * this adds escape characters to the json object and stringifies it
   * @param {*} _jsonObj 
   */
  static addEscapeCharactersToJsonString = (_jsonObj) => {
    return JSON.stringify(JSON.stringify(_jsonObj));
  }


  static arrayToString = (_array, _delim = ",") => {
    if (this.hasNoElements(_array)) {
      return "";
    } else {
      return _array.length === 1 ? "" + _array[0] : _array.join(_delim);
    }
  }


  static formatCountryList = (_countryList, _allId = 0) => {
    let outList = [];
    outList = _countryList.slice().sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    let usaIndex = outList.map(c => c.value).indexOf("United States");
    let oUsa = {};
    if (usaIndex >= 0) {
      oUsa = outList[usaIndex];
      outList.splice(usaIndex, 1);
    }

    if (_allId === null) {
      return [oUsa, ...outList];
    } else {
      return [{ id: _allId, value: "All" }, oUsa, ...outList]
    }
  }

  static formatLanguageList = (_countryList, _allId = 0) => {
    let outList = [];
    outList = _countryList.slice().sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    let usaIndex = outList.map(c => c.value).indexOf("English");
    let oUsa = {};
    if (usaIndex >= 0) {
      oUsa = outList[usaIndex];
      outList.splice(usaIndex, 1);
    }

    if (_allId === null) {
      return [oUsa, ...outList];
    } else {
      return [{ id: _allId, value: "All" }, oUsa, ...outList]
    }
  }

  static formatCurrencyList = (_countryList, _allId = 0) => {
    let outList = [];
    outList = _countryList.slice().sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    let usaIndex = outList.map(c => c.value).indexOf("United States Dollar");
    let oUsa = {};
    if (usaIndex >= 0) {
      oUsa = outList[usaIndex];
      outList.splice(usaIndex, 1);
    }
    let euroIndex = outList.map(c => c.value).indexOf("Euro");
    let oEuro = {};
    if (euroIndex >= 0) {
      oEuro = outList[euroIndex];
      outList.splice(euroIndex, 1);
    }
    if (_allId === null) {
      return [oUsa, oEuro, ...outList];
    } else {
      return [{ id: _allId, value: "All" }, oUsa, oEuro, ...outList];
    }
  }

  //strips the timestamp details from Date
  static getDateFromDateTimeStamp = (_date, _defaultValue = new Date()) => {
    return this.isValidDate(_date) ? _date.toISOString().split('T')[0] :
      (_defaultValue != null ? _defaultValue.toISOString().split('T')[0] : null);
  }

  static isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  //Expects input in DateString format
  //Returns the date in YYYY-MM-DD
  static formatDate = (dateval) => {
    let date = this.isValidDate(dateval) ? dateval : new Date();
    return date.getFullYear() +
      "-" +
      ((date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate());
  }

  //Expects input in DateString format
  //Returns the date in YYYY-MM-DD
  static formatDateString = (dateString) => {
    if (DataService.isStringNullOrEmpty(dateString)) {
      return dateString;
    } else {
      const parsedDate = new Date(dateString);
      if (this.isValidDate(parsedDate)) {
        return parsedDate.getFullYear() +
          "-" +
          ((parsedDate.getMonth() + 1).toString().length === 1
            ? "0" + (parsedDate.getMonth() + 1)
            : parsedDate.getMonth() + 1) +
          "-" +
          (parsedDate.getDate().toString().length === 1
            ? "0" + parsedDate.getDate()
            : parsedDate.getDate());
      } else {
        return dateString;
      }
    }
  }

  //Some dates are coming in YYYY-MM-DDT00:00:00 
  static isISODateRegexMatch(date) {
    let iSODateRegex = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d/;
    return date.match(iSODateRegex) != null;
  }

  //Checks if Date in YYYY-MM-DD format
  static isDateRegexMatch(date) {
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    return date.match(regEx) != null;
  }

  //Used for showing already saved date from server
  //This function has been added as Material's DatePicker if passed a 
  //Date in either Datetimestring or YYYY-MM-DD format interprets it 
  //incorrcetly showing one day behind value. So, this function converts the Date from
  //YYYY-MM-DD format to 'new Date(YYYY, MM, DD)' format.
  static getDateStringForDisplay = (date) => {
    if (DataService.isNullOrUndefined(date) || DataService.isStringNullOrEmpty(date))
      return date;

    if (this.isISODateRegexMatch(date))
      date = date.split('T')[0];

    let dateVal = this.isDateRegexMatch(date) ? date : this.formatDate(date);
    return new Date(dateVal.split('-'));
  }

  /**
   * 
   * @param {*} _sampleModel 
   * @returns a Map with lowercase prop as the key and actual case key as the value
   */
  static getModelKeyMap = (_sampleModel) => {
    var modelKeyMap = new Map();
    const modelKeys = Object.keys(_sampleModel);
    modelKeys.forEach(modelKey => {
      modelKeyMap.set(modelKey.toLowerCase(), modelKey);
    });
    return modelKeyMap;
  }

  static getStringOrDefault = (_value, _defaultValue = "") => {
    return this.isStringNullOrEmpty(_value) ? _defaultValue : _value;
  }

  static convertToLowerCasedKeyObject = (_objArray) => {
    return _objArray.map(obj => Object.entries(obj).reduce((a, [key, value]) => {
      a[key.toLowerCase()] = value;
      return a;
    }, {}));
  }

  static applyFilter = (_filterType, _targetString, _filterString) => {
    const valueLowerCase = (_targetString + "").toLowerCase();
    const filterTextLowerCase = (_filterString + "").toLowerCase();
    switch (_filterType) {
      case 'contains':
        return valueLowerCase.indexOf(filterTextLowerCase) >= 0;
      case 'notContains':
        return valueLowerCase.indexOf(filterTextLowerCase) === -1;
      case 'equals':
        return valueLowerCase === filterTextLowerCase;
      case 'notEqual':
        return valueLowerCase != filterTextLowerCase;
      case 'startsWith':
        return valueLowerCase.indexOf(filterTextLowerCase) === 0;
      case 'endsWith':
        var index = valueLowerCase.lastIndexOf(filterTextLowerCase);
        return index >= 0 && index === (valueLowerCase.length - filterTextLowerCase.length);
      default:
        // should never happen
        console.warn('invalid filter type ' + _filterType);
        return false;
    }
  }

  /**
   * 
   * @param {*} _sampleObject 
   * @returns an object with lowercase prop as the key
   */
  static convertActualObjectToLowerCasedKeyObject = (_sampleObject) => {
    const keys = Object.keys(_sampleObject);
    let lowerCaseObj = {};
    keys.forEach(key => {
      lowerCaseObj[key.toLowerCase()] = _sampleObject[key];
    });
    return lowerCaseObj;
  }

  static dateComparator = (date1, date2) => {
    let bdt = moment(date2, "YYYY-MM-DD h:mm a");
    let adt = moment(date1, "YYYY-MM-DD h:mm a");
    return bdt > adt ? 1 : (bdt < adt ? -1 : 0);
  }
}