import React from "react";
import { withStyles, IconButton } from "@material-ui/core";
import { Typography, Button, Grid } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { Link } from "react-router-dom";
import { MatIconService } from "../../shared/services/theme/matIconService";

class UnderConstructionComponent extends React.Component {
  static PATH = "under-construction";
  homeLink = (props) => <Link to="/home" {...props} />;
  render() {
    const { classes } = this.props;
    return (
      <Grid direction="column" container justify="center" alignItems="center" style={{ minHeight: "50vh" }}>
        <IconButton>{MatIconService.WARNING}</IconButton>
        <Typography variant={"h3"} className={classes.pageErrorHeader}>
          Page Under Construction
        </Typography>
        <Button component={this.homeLink} variant="contained" color="secondary">
          Go Home
        </Button>
      </Grid>
    );
  }
}
/** HOC */
export default withStyles(MatClassService)(UnderConstructionComponent);
