import config from './izenda.config';
import IzendaSynergy from 'IzendaSynergy';
import SessionService from '../../../shared/services/sessionService';

export default class IzendaIntegrate {

    DoIzendaConfig() {
        IzendaSynergy.config(config);
    }

    setContext() {
        const currentUserContext = {
            token: SessionService.getUserModelFromStorage().sessionToken
        };
        IzendaSynergy.setCurrentUserContext(currentUserContext);
    }

    RenderIzenda() {
        this.setContext();
        const dom = document.getElementById('izenda-root');
        IzendaSynergy.render(dom);
        console.log('Rendering Izenda');
        return dom;
    }

    RenderReportViewer(reportId, filters) {
        this.setContext();
        const dom = document.getElementById('izenda-root');
        IzendaSynergy.renderReportViewerPage(dom, reportId || '[your report id]', filters);
        return dom;
    }

    RenderExport(dom, reportPartId) {
        this.DoIzendaConfig();
        this.setContext();
        IzendaSynergy.renderReportPart(dom, {
            'id': reportPartId,
            'useQueryParam': true,
        });
        console.log('Izenda Process | Exporting report id: ' + reportPartId);
    }

    DestroyDom(dom) {
        this.setContext();
        IzendaSynergy.unmountComponent(dom);
    }
}