import React, { Component } from "react";
import { combineLatest } from "rxjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AppBar, Box, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography } from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import ChildMessageRendererComponent from "../childMessageRendererComponent";
import EditAffiliateModalComponent from "./affiliate-companies-details/editAffiliateModalComponent";
import AffiliateCompaniesDialogComponent from "./affiliate-companies-details/affiliateCompaniesDialogComponent";
import IsActiveIconRendererComponent from "../../../shared/components/ag-grid/isActiveIconRendererComponent";
import LookupService from "../../../shared/services/lookupService";
import LayoutService from "../../../shared/services/layoutService";
import AffiliateCompaniesListService from "./affiliateCompaniesListService";
import ToastService from "../../../shared/services/toastService";

class AffiliateCompaniesListComponent extends Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    this.state = {
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      modalShow: false,
      showAffiliateCreateDialog: false,
      submitCreateWasClicked: false,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

    this.apiSubscriptions.add(
      combineLatest([
        AffiliateCompaniesListService.getAffiliateCompanyListAsOBS(this.context.user.tenantId),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
      ]).subscribe(
        // success
        ([_data, _countryList]) => {
          this.setState(
            {
              data: _data.map((d) => ({ ...d, country: _countryList.find((c) => c.id === d.countryId).value, })),
            },
            // change the state after all the above are assigned
            () => { this.setState({ fetchResult: ResultStatus.LOADED }); }
          );
        },
        // onError
        (error) => {
          ToastService.showError("Error Occured.");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };
  methodFromParent = (cell, data) => {
    this.setState({ modalShow: true, companyAffiliate: data });
  };
  handleHide = () => {
    this.setState({ modalShow: false });
  };
  handleHideCreateAffiliateCompanyModal = () => {
    this.setState({ showAffiliateCreateDialog: false });
  };

  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent classes={classes} label="Loading Affiliated Companies...." />);
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <div id="MainUsersGrid">
            <div className="IconBg marginTop10px tableTitleHead">
              <PageHeaderComponent back history={this.props.history} classes={classes} label="Affiliate Companies"
                addCircle onAddClick={() => { this.setState({ showAffiliateCreateDialog: true }); }}
                reload onReloadClick={() => { this.fetchData(true); }}
              />
            </div>
            <div {...LayoutService.getAgGridStyles()}>
              <AgGridReact
                columnDefs={AffiliateCompaniesListService.getColumnDefs(this)}
                rowData={this.state.data}
                pagination={true}
                paginationPageSize={50}
                frameworkComponents={this.state.frameworkComponents}
                gridOptions={{
                  context: { componentParent: this },
                }}
                onGridReady={this.onGridReady}
              ></AgGridReact>
            </div>

            <Dialog open={this.state.showAffiliateCreateDialog || false} scroll={true ? "paper" : "body"} maxWidth="md" >
              <DialogTitle disableTypography id="dialogTitle">
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6" className={classes.root}>Affiliate Company Details</Typography>
                    <IconButton color="secondary" onClick={() => {
                      this.setState({ submitCreateWasClicked: false });
                      this.setState({ showAffiliateCreateDialog: false });
                    }}>{MatIconService.CANCEL}</IconButton>
                  </Toolbar>
                </AppBar>
              </DialogTitle>
              <DialogContent>
                <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, minHeight: "70vh", maxHeight: "70vh", minWidth: "80vh", maxWidth: "80vh", }}>
                  <EditAffiliateModalComponent inputAction={CrudAction.CREATE} submitCreateWasClicked={this.state.submitCreateWasClicked} onClose={this.handleHideCreateAffiliateCompanyModal} refreshAfiliatesList={this.fetchData} />
                </Box>
              </DialogContent>
            </Dialog>

            <Dialog open={this.state.modalShow || false} scroll={true ? "paper" : "body"} maxWidth="md" >
              <AffiliateCompaniesDialogComponent inputAction={CrudAction.UPDATE} submitCreateWasClicked={false} onClose={this.handleHide} affiliate={this.state.companyAffiliate} refreshAfiliatesList={this.fetchData} />
            </Dialog>
          </div>
        );
      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent label="Error Loading Affiliated Companies" classes={classes} onRetry={() => { this.fetchData(true); }} />);
    }
  }
}
export default LayoutService.getHocComponenet(AffiliateCompaniesListComponent);;