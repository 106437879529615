import React, { Component } from "react";
import { ResultStatus } from "../../shared/types/enums";
import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { TextField, Grid, Button } from "@material-ui/core";

import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { withStyles, Dialog } from "@material-ui/core";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { CrudAction } from "../../shared/types/enums";
import LayoutService from "../../shared/services/layoutService";
import ToastService from "../../shared/services/toastService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";
import { MatIconService } from "../../shared/services/theme/matIconService";
import TenantStatusService from "./tenantStatusService";
import NewTenantComponent from "./new-tenant/newTenantDialog";
import { combineLatest } from "rxjs";
import LookupService from "../../shared/services/lookupService";


class TenantStatusComponent extends Component {

    static contextType = AuthContext;
    oSubscriptions = new SubscriptionArray();
    STORE = TenantStatusService;

    constructor(props) {
        super(props);
        this.state = {
            newTenantStatusData: [],
            deleteTenantStatusData: [],
            showPlusForNewStatus: false,
            showNewTenantStatusGrid: true,
            showPlusForDeleteStatus: true,
            fetchResult: ResultStatus.NOT_LOADED,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = (_ignoreCache = false) => {
        this.oSubscriptions.cancelAll();
        // this.setState({ fetchResult: ResultStatus.LOADING, rowData: [] });
        // save the subscription object
        this.oSubscriptions.add(
            combineLatest([
                this.STORE.fetchFromNewTenantStatusApiObs(this.context),
                this.STORE.fetchFromNewTenantStatusApiObs(this.context),
                LookupService.getActiveTenantsAsObs(true)
            ])
                .subscribe(([_newTenantStatusData, _deleteTenantStatusData, _tenantData]) => {
                    this.setState({
                        newTenantStatusData: _newTenantStatusData,
                        deleteTenantStatusData: _deleteTenantStatusData,
                        fetchResult: ResultStatus.SUCCESS,
                        tenantData: _tenantData,
                    });
                },
                    (error) => {
                        ToastService.showError("Error Occured.");
                        this.setState({ fetchResult: ResultStatus.ERROR });
                    }
                )
        );
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        //this.gridApi.setDomLayout("autoHeight");
        this.gridApi.sizeColumnsToFit();
    };

    getInitialValues() {
        return {
            environmentId: 1,
        };
    }
    getValidationSchema() {
        this.validationSchema = Yup.object().shape({
            environmentId: Yup.number().required("Required").typeError("Must be a number"),
        });
        return this.validationSchema;
    }

    getTenantIdAndStatus = (tenantData) => {
        let tenantIdAndStatus = [];
        for (let i = 1; i < tenantData.length; i++) {
            tenantIdAndStatus.push({
                tenantid: tenantData[i].tenantid,
                tenantName: tenantData[i].tenantName,
                tenantDescription: tenantData[i].tenantDescription,
                tenantAddress1: tenantData[i].tenantAddress1,
                tenantCity: tenantData[i].tenantCity,
                tenantProvince: tenantData[i].tenantProvince,
                tenantStatus: tenantData[i].tenantStatus,
            });
        }
        return tenantIdAndStatus;
    };

    getTenantStatus = (tenantData) => {
        let tenantIdAndStatus = this.getTenantIdAndStatus(tenantData);
        return (
            <Grid container spacing={2}>
                <Grid item xs= {6}>
                <TextField
                    id="tenantName"
                    select
                    label="Tenant Name"
                    name="tenantName"
                    value={this.state.tenantName}
                    onChange={(e) => {
                        this.setState({
                            tenantName: e.target.value,
                            tenantStatus: tenantIdAndStatus.find((x) => x.tenantName === e.target.value).tenantStatus,
                            tenantDescription: tenantIdAndStatus.find((x) => x.tenantName === e.target.value).tenantDescription,
                            tenantAddress1: tenantIdAndStatus.find((x) => x.tenantName === e.target.value).tenantAddress1,
                            tenantCity: tenantIdAndStatus.find((x) => x.tenantName === e.target.value).tenantCity,
                            tenantProvince: tenantIdAndStatus.find((x) => x.tenantName === e.target.value).tenantProvince,
                        });
                        this.fetchData(true);
                    }
                    }
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                >
                    {<option value="Select Tenant" disabled selected></option>}
                    {tenantIdAndStatus.map((option) => (
                        <option key={option.tenantName} value={option.tenantName}>
                            {option.tenantName}
                        </option>
                    ))}
                </TextField>
                <TextField
                    id="tenantStatus"
                    disabled
                    name="tenantStatus"
                    //show the initial status of the tenant
                    value={this.state.tenantStatus}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    id="tenantDescription"
                    disabled
                    name="tenantDescription"
                    //show the initial status of the tenant
                    value={this.state.tenantDescription}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                </Grid>
                <Grid container item xs= {6}>
                <TextField
                    id="tenantCity"
                    disabled
                    name="tenantCity"
                    //show the initial status of the tenant
                    value={this.state.tenantCity}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    id="tenantProvince"
                    disabled
                    name="tenantProvince"
                    //show the initial status of the tenant
                    value={this.state.tenantProvince}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    id="tenantAddress1"
                    disabled
                    name="tenantAddress1"
                    //show the initial status of the tenant
                    value={this.state.tenantAddress1}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
                </Grid>
                </Grid>
                
        );
    }




    onAddClick = () => { this.setState({ showNewTenantDialog: true, inputAction: CrudAction.CREATE }); }
    handleHide = () => { this.setState({ showNewTenantDialog: false }); }
    onOpenNewStatusClick = () => { this.setState({ showPlusForNewStatus: false, showNewTenantStatusGrid: true }); }
    onCloseNewStatusClick = () => { this.setState({ showPlusForNewStatus: true, showNewTenantStatusGrid: false }); }
    onOpenDeleteStatusClick = () => { this.setState({ showPlusForDeleteStatus: false, showDeleteTenantStatusGrid: true }); }
    onCloseDeleteStatusClick = () => { this.setState({ showPlusForDeleteStatus: true, showDeleteTenantStatusGrid: false }); }

    render() {
        const { classes } = this.props;
        switch (this.state.fetchResult) {
            case ResultStatus.NOT_LOADED:
            case ResultStatus.LOADING:
                return (
                    <PageLoadingComponent classes={classes} label="Loading Tenant Status" />
                );
            case ResultStatus.SUCCESS:
                return (
                    <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
                        {(fProps) => (
                            <form>
                                <div id="MainRoleGrid">
                                    <div style={{ backgroundColor: "#ddd", textAlign: "right" }}></div>

                                    <div className="IconBg marginTop10px tableTitleHead">
                                        {/* Header Componenet */}
                                        {<PageDynamicHeaderComponent classes={classes} label="Tenant Status" rightPadding={16}
                                            //   leftActions={[
                                            //     { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                                            //   ]}
                                            rightActions={[
                                                { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.onAddClick, isReadOnly: false },
                                            ]}
                                        />}
                                    </div>
                                    <div style={{ border: "groove" }}>
                                            {this.getTenantStatus(this.state.tenantData)}
                                        <br /><br /><br />
                                    </div>
                                    <div className="IconBg marginTop10px tableTitleHead">
                                        {/* Header Componenet */}
                                        {<PageDynamicHeaderComponent classes={classes} label="New Tenant Status" rightPadding={16}
                                            rightActions={[
                                                { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                                                this.state.showPlusForNewStatus ? { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "View New Status", iconColor: "secondary", onClick: this.onOpenNewStatusClick, isReadOnly: false }
                                                    : { icon: MatIconService.DASH, title: "Minimize", iconColor: "secondary", onClick: this.onCloseNewStatusClick, isReadOnly: false }
                                            ]}
                                        />}
                                    </div>
                                    {this.state.showNewTenantStatusGrid ? <div {...LayoutService.getAgGridStyles()}>
                    <AgGridReact
                        fullWidth
                        columnDefs={this.STORE.getColumnDefs(this)}
                        rowData={this.state.newTenantStatusData}
                        pagination={true}
                        paginationPageSize={10}
                        frameworkComponents={this.state.frameworkComponents}
                        gridOptions={{
                            headerHeight: 70,
                            tooltipShowDelay: 0,
                            context: { componentParent: this },
                        }}
                        onGridReady={this.onGridReady}
                    ></AgGridReact>
                </div> : <></>}

                                    <Dialog open={this.state.showNewTenantDialog || false} scroll={true ? "paper" : "body"} maxWidth="md">
                                        <NewTenantComponent onClose={this.handleHide} inputAction={this.state.inputAction} />
                                    </Dialog>
                                </div>
                            </form>
                        )
                        }
                    </Formik >
                );
            case ResultStatus.ERROR:
            default:
                return (
                    <PageErrorComponent label="Error Loading Tenant Status" classes={classes} onRetry={() => { this.fetchData(true); }} />
                );
        }
    }
}
export default withStyles(MatClassService)(TenantStatusComponent);



// for displaying delete status

{/* <div className="IconBg marginTop10px tableTitleHead">
{<PageDynamicHeaderComponent classes={classes} label="Delete Tenant Status" rightPadding={16}
    rightActions={[
        this.state.showPlusForDeleteStatus ? { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "View Delete Status", iconColor: "secondary", onClick: this.onOpenDeleteStatusClick, isReadOnly: false }
            : { icon: MatIconService.DASH, title: "Minimize", iconColor: "secondary", onClick: this.onCloseDeleteStatusClick, isReadOnly: false }
    ]}
/>}
</div>
{this.state.showDeleteTenantStatusGrid ? <div {...LayoutService.getAgGridStyles()}>
<AgGridReact
    columnDefs={this.STORE.getColumnDefs(this)}
    rowData={this.state.deleteTenantStatusData}
    pagination={true}
    paginationPageSize={10}
    frameworkComponents={this.state.frameworkComponents}
    gridOptions={{
        headerHeight: 70,
        tooltipShowDelay: 0,
        context: { componentParent: this },
    }}
    onGridReady={this.onGridReady}
></AgGridReact>
</div> : <></>} */}