import React from "react";
import {
  withStyles,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Divider,
} from "@material-ui/core";
import EditRulesComponent from "./editRulesComponent";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../../shared/services/layoutService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class EditRulesDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED
    };
  }
  componentWillUnmount() { }
  componentDidMount() {
    this.setState({
      fetchResult: ResultStatus.SUCCESS,
    });
  }

  /**Render */
  render() {
    const { classes } = this.props;
    console.log(this.props.modalAgNode);
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent small classes={classes} label="Loading Edit Rules" />);
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            {/* Header */}
            <DialogTitle disableTypography id="dialogTitle">
              <AppBar position="static">
                <Toolbar>
                  <Typography variant="h6" className={classes.root}>Rule Details</Typography>
                  <IconButton color="secondary" onClick={() => { this.props.onClose(false); }}>{MatIconService.CANCEL}</IconButton>
                </Toolbar>
              </AppBar>
            </DialogTitle>
            <DialogContent>
              <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 8, paddingBottom: 32, minHeight: "60vh", maxHeight: "60vh", minWidth: "80vh", maxWidth: "80vh", }}>
                <EditRulesComponent modalAgNode={this.props.modalAgNode} inputAction={CrudAction.UPDATE} onClose={this.props.onClose} refreshParentGrid={this.props.refreshParentGrid} />
              </Box>
            </DialogContent>
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <PageErrorComponent small label="Error Loading Task Details" classes={classes} onRetry={() => {
            //this.SERVICE.fetchData(this, true);
          }}
          />
        );
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(EditRulesDialogComponent);