import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";
import { GST_API } from "../../../shared/types/enums";

export default class NewsFeedService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();

    return ApiService.fetchHTTP_API(GST_API.TenantConfig, `/Newsfeed/FetchNewsfeeds?tenantId=${clientAuthContext.user.tenantId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderClause: "",
        whereClause: "",
        userId: clientAuthContext.user.userId,
        tenantId: clientAuthContext.user.tenantId,
        screenId: 0,
        rolePermissionId: 0,
        isPorzio: clientAuthContext.user.tenantId === 1 ? true : false,
        pageNumber: 1,
        pageSize: 1000
      }),
    });
  }
}
