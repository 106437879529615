import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class AffiliateCompaniesListService {
  
  static getAffiliateCompanyListAsOBS = (_tenantId) => {
    return ApiService.getOBS(API_ENDPOINT.TENANT, `/Affiliate/FetchAllAffiliates/${_tenantId}`);
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Affiliate Name",
        field: "affiliateName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Affiliate ID",
        field: "customerAffiliateId",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 225,
      },

      {
        headerName: "Address 1",
        field: "address1",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "City",
        field: "city",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Province/State",
        field: "province",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Country",
        field: "country",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Active",
        field: "isActive",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 75,
        cellRenderer: "isActiveIconRendererComponent",
      },
    ];
  }
}
