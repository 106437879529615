import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { CELL_ACTION } from "../../types/enums";
import { MatIconService } from "../../services/theme/matIconService";
import MatSpecService from "../../services/theme/matSpecService";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";
import { DataService } from "../../services/dataService";
import { red } from '@material-ui/core/colors';

export default class AgGridEditButtonCellRendererComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    const specialObj = this.props.data[AgGridUtil.specialProperty];
    const isEditing = DataService.isNullOrUndefined(specialObj) ? false : specialObj[AgGridUtil.editingProperty] === true;
    const isReadOnly = DataService.isNullOrUndefined(specialObj) ? false : specialObj[AgGridUtil.readOnlyProperty] === true;

    return (
      <>
        {isEditing ? // available and true
          (<>
            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Cancel Changes" placement="bottom" arrow>
              <IconButton size="small" color="secondary" onClick={() => { this.props.onClick(this, CELL_ACTION.CANCEL); }}> {MatIconService.CANCEL} </IconButton>
            </Tooltip>
            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Apply Changes" placement="bottom" arrow>
              <IconButton size="small" color="primary" onClick={() => { this.props.onClick(this, CELL_ACTION.ACCEPT); }}> {MatIconService.OK} </IconButton>
            </Tooltip>
          </>)
          : (
            <>
              <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Edit" placement="bottom" arrow>
                <span><IconButton disabled={isReadOnly} size="small" onClick={() => { this.props.onClick(this, CELL_ACTION.EDIT); }}> {MatIconService.EDIT_OUTLINED} </IconButton></span>
              </Tooltip>
              {
                !this.props.showDelete ? null : <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Delete" placement="bottom" arrow>
                  <span>
                    <IconButton disabled={isReadOnly} style={{ color: (isReadOnly ? '' : red[500]) }}
                      size="small" color="secondary" onClick={() => { this.props.onClick(this, CELL_ACTION.DELETE); }}> {MatIconService.DELETE_FOREVER} </IconButton>
                  </span>
                </Tooltip>
              }

            </>
          )
        }
      </>
    );
  }
}
