import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../shared/store/authProvider";
import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { withStyles, Dialog } from "@material-ui/core";
import ChildMessageRendererComponent from "./childMessageRendererComponent";
import IsActiveIconRendererComponent from "./../../shared/components/ag-grid/isActiveIconRendererComponent";
import WorkflowDetailsComponent from "./workflow-details/workflowDetailsComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import { DataService, SubscriptionArray } from "../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../shared/types/enums";
import LayoutService from "../../shared/services/layoutService";
import LookupService from "../../shared/services/lookupService";
import WorkflowTemplateListService from "./workflowTemplateListService";


class WorkflowTemplateListComponent extends Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();
  STORE = WorkflowTemplateListService;

  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.state = {
      frameworkComponents: {
        childMessageRendererComponent: ChildMessageRendererComponent,
        isActiveIconRendererComponent: IsActiveIconRendererComponent,
      },
      modalShow: false,
      modalAgNode: null,
      selectedCountryId: -1,
    };
  }

  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        WorkflowTemplateListService.getWorkflowTemplateListAsOBS(this.context.user.tenantId),
        LookupService.getFormattedCountriesAsOBS(this.context, -1),
      ]).subscribe(
        // success
        ([_data, _countryList]) => {
          this.setState(
            {
              data: _data,
              countryList: _countryList,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  onGridReady = (event) => {
    event.api.closeToolPanel();
    event.api.sizeColumnsToFit();
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.gridApi.setDomLayout("autoHeight");
    setTimeout(() => {
      this.gridApi.setFilterModel({
        jurisdictionName: {
          filterType: 'text',
          type: 'equals',
          filter: this.state.selectedCountryId === -1 ? null : this.state.countryList.find(x => x.id === this.state.selectedCountryId).value,
        },
      });
    }, 150);
  };

  onCountrySelectClick = (event) => {
    this.setState({ selectedCountryId: event.target.value });
    this.gridApi.setFilterModel({
      jurisdictionName: {
        filterType: 'text',
        type: 'equals',
        filter: event.target.value === -1 ? null : this.state.countryList.find(x => x.id === event.target.value).value,
      },
    });
  }

  handleCreateModal = () => {
    this.setState({ modalShow: true });
    this.setState({ inputAction: CrudAction.CREATE });
    this.setState({
      modalAgNode: {
        workflowTemplateId: 0,
      },
    });
  };

  methodFromParent = (cell, data) => {
    this.setState({
      modalShow: true,
      modalAgNode: data,
      inputAction: CrudAction.UPDATE
    });
  };

  handleHide() {
    this.setState({ modalShow: false });
  }

  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (
          <PageLoadingComponent
            classes={classes}
            label="Loading Workflow Templates"
          />
        );
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <div id="MainUsersGrid">
            <div className="IconBg marginTop10px tableTitleHead">
              <PageHeaderComponent back history={this.props.history}
                classes={classes}
                label="Workflow Templates"
                countrySelect
                selectedCountryId={this.state.selectedCountryId}
                countrySelectData={this.state.countryList}
                onCountrySelectClick={(event) => {
                  this.onCountrySelectClick(event);
                }}
                addCircle
                onAddClick={this.handleCreateModal}
                reload
                onReloadClick={() => {
                  this.fetchData(true);
                }}
              />
            </div>
            <div {...LayoutService.getAgGridStyles()}>
              <AgGridReact
                columnDefs={WorkflowTemplateListService.getColumnDefs(this)}
                rowData={this.state.data}
                pagination={true}
                paginationPageSize={50}
                frameworkComponents={this.state.frameworkComponents}
                gridOptions={{
                  context: { componentParent: this },
                }}
                onGridReady={(event) => {
                  this.onGridReady(event);
                }}
              ></AgGridReact>
            </div>{" "}
            <Dialog
              open={this.state.modalShow || false}
              scroll={true ? "paper" : "body"}
              maxWidth="md"
            >
              <WorkflowDetailsComponent
                onClose={this.handleHide}
                modalAgNode={this.state.modalAgNode}
                inputAction={this.state.inputAction}
                refreshVendorList={this.fetchData}
              />
            </Dialog>
          </div>
        );

      case ResultStatus.ERROR:
        return (
          <PageErrorComponent
            label="Error Loading Workflow Template"
            classes={classes}
            onRetry={() => {
              this.fetchData(true);
            }}
          />
        );
      default:
        return null;
    }
  }
}
export default LayoutService.getHocComponenet(WorkflowTemplateListComponent);
