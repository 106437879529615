import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ApiService from "../../../../shared/services/apiService";
import { DataService } from "../../../../shared/services/dataService";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import { API_ENDPOINT, ReportCategory } from "../../../../shared/types/enums";

export class RolePermissionsService {

    static getPermissionsViewAsOBS = (_tenantId, _sectionId, _roleId) => {
        return ApiService.getOBS(API_ENDPOINT.TENANT, `/Permission/FetchPermissionsView?tenantId=${_tenantId}&sectionId=${_sectionId}&roleId=${_roleId}`);
    }

    /**
    * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
    * @param _componentThisRef pass {this} keyword from the calling componenet
    */
    static getColumnDefs = (_componentThisRef) => {
        return [
            {
                headerName: "Permission Name",
                editable: false,
                resizable: true,
                flex: 1,
                cellRenderer: "hierarchicalTextboxRendererComponent",
                cellStyle: { "border-right": "1px solid lightgray" },
            },
            ...this.getPermissionActionsColumns(_componentThisRef.state.permissionActionsList)
        ]
    }

    static getPermissionActionsColumns = (_permissionActions) => {
        // excludes view-deleted column
        _permissionActions = (_permissionActions || []).filter(x => x.actionId !== 6);

        var oRET = [];
        let _isParent = false;
        if (DataService.hasElements(_permissionActions)) {
            _permissionActions.forEach(_action => {
                oRET.push({
                    headerName: _action.actionName, //Create
                    field: _action.actionName, //Create
                    sortable: false,
                    resizable: true,
                    width: 65,
                    cellRenderer: "dynamicCheckBoxRendererComponent",
                    cellRendererParams: {
                        getIsReadonly: (_cellRef, _data) => {
                            return _data.refferenceId > 0;
                        }
                    },
                    cellStyle: (_params) => {
                        let _cellStyle = { "border-right": "1px solid lightgray", "text-align": "center" };
                        const cellActionData = DataService.getFirstOrDefault((_params.data.actionsList || []).filter((x) => x.actionName === _params.colDef.headerName));
                        if (cellActionData?.isDriveChilds === true) {
                            _isParent = true;
                            _cellStyle['background-color'] = MatThemeService.getCellHeaderBG();
                        }

                        if (_params.data.refferenceId > 0) {
                            _cellStyle['background-color'] = MatThemeService.getCellHeaderBG();
                        }
                        return _cellStyle;
                    }
                });
            });
        }
        return oRET;
    }


    static fetchTemplates(_selectedTabIndex, _currrentTenantId, _roleId) {
        /**
         * As per the discussion with the DB Team
            --#2--Report Role permission level (Master Data) -->Specific role
                EXEC USP_RPT_REPORTBYCATEGORYIDGET @CATEGORYID = 1, @PageNumber = 1, @RowsperPage = 1000, @IsViewable = 1, @IsSubcribe = 1, @RoleID = 2
            --#2.1--Report Role permission -->ADMIN ROLE (No Scenario in our Application)
                EXEC USP_RPT_REPORTBYCATEGORYIDGET @CATEGORYID = 1, @PageNumber = 1, @RowsperPage = 1000, @IsViewable = 1,  @IsSubcribe = 0, @RoleID = NULL
         */

        const jsonObj = {
            "tenantId": _currrentTenantId,
            "categoryId": _selectedTabIndex === 1 ? ReportCategory.STANDARD_TEMPLATES : ReportCategory.COMPLIANCE_TEMPLATES,
            "roleId": _roleId, // handled at the api side
            "isViewable": true,
            "isSubscribe": true,
            "pageNo": 1,
            "pageSize": 1000,
        };

        return ApiService.postOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportPermissions`, JSON.stringify(jsonObj));
    }

    // save-api
    static saveReportTemplatesAsOBS(_selectedTabIndex, _currrentTenantId, _userId, _roleId, _selIdsAsString) {
        const jsonBody = JSON.stringify({
            "tenantId": _currrentTenantId,
            "userId": _userId,
            "categoryId": _selectedTabIndex === 1 ? ReportCategory.STANDARD_TEMPLATES : ReportCategory.COMPLIANCE_TEMPLATES,
            "roleId": _roleId,
            "reportIds": _selIdsAsString,
            "reportLibIds": ""
        });
        // TODO: ensure that this save is affecting ReportMaster Table for the seklected tenant
        return ApiService.postOBS(API_ENDPOINT.REPORTS, `/Reports/SaveReportSubscriptions`, jsonBody);
    }

    // column-definitions
    static getReportSubscriptionColumnDefs(_parentThisRef) {
        return [
            AgGridColumnExt.GET(!_parentThisRef.state.isReadOnly, "text", "isSubscription", "").cellRenderer("isSubscriptionCellRenderer").fixedWidth(56).SET(),
            AgGridColumnExt.GET(false, "text", "reportName", "Template Name").flexWeight(3).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "templateYear", "Template Year").flexWeight(2).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "jurisdiction", "Jurisdiction").flexWeight(1).tooltip().SET(),
            AgGridColumnExt.GET(false, "text", "izendaDisplayName", "Template Description").flexWeight(3).tooltip().SET(),

        ];
    }


}