import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";
import { GST_API, API_ENDPOINT } from "../../../shared/types/enums";

export default class VendorSetupService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    // replace this._jsonData with reacl ApiCall
    return ApiService.fetchHTTP_API(
      GST_API.TenantConfig,
      `/Vendor/GetAllVendors/${clientAuthContext.user.tenantId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Vendor Name",
        field: "vendorName",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        cellRenderer: "vendorLinkRendererComponent",
      },
      {
        headerName: "Affiliate Company",
        field: "affiliateName",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 175,
      },
      {
        headerName: "Address 1",
        field: "address1",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        filterOptions: "contains",
        width: 150,
      },
      {
        headerName: "City",
        field: "city",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Province/State",
        field: "province",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Country",
        field: "country",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Postal Code",
        field: "postalCode",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 150,
      },
      {
        headerName: "Active?",
        field: "active",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 75,
        cellRenderer: "isActiveIconRendererComponent",
      },
    ];
  }
}
