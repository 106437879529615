import React from 'react';
import { AppBar, Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Toolbar, Typography, Divider, Chip } from "@material-ui/core";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import LayoutService from "../../../../shared/services/layoutService";
import MatThemeService from '../../../../shared/services/theme/matThemeService';

class RelatedTransactionEditConfirmationDialog extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={this.props.open || false} onClose={this.props.onClose} fullWidth={true} maxWidth='md' scroll={false ? "paper" : "body"}>
                    <DialogTitle id="alert-dialog-title">
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" className={classes.root}>Group Expenses Confirmation?</Typography>
                                {LayoutService.getIconButton(false, MatIconService.OK, "Confirm", this.props.onConfirm, "inherit", "keyActionOk")}
                                {LayoutService.getIconButton(false, MatIconService.CANCEL, "Cancel", this.props.onClose, "secondary", "keyActionCancel")}
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Box style={{ paddingInline: 0 }}  >
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Typography style={{ width: "100%", paddingInline: 8, paddingBlock: 8, backgroundColor: "#ecc802", color: "#681501" }} variant="h6" align="center">
                                            Changes will be applied to all transactions in the group. Please confirm.
                                        </Typography>
                                    </Grid>
                                    <Divider style={{ marginTop: 24 }} />
                                    <Grid style={{ paddingInline: 24, paddingTop: 24, paddingBottom: 32, backgroundColor: MatThemeService.getAlternatingBG() }}>
                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                            {(this.props.relatedTransactions || []).map((x, index) => {
                                                return <Chip key={`relatedTransactionKey${index}`} style={{ marginLeft: 8, marginTop: 8 }} variant="outlined" color="secondary" label={x.PorzioTransactionId}
                                                    icon={MatIconService.TRANSACTION_OUTLINED} />
                                            })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
export default LayoutService.getHocComponenet(RelatedTransactionEditConfirmationDialog);