import React from "react";
import { of } from "rxjs"
import ApiService from "../services/apiService";
import { DataService } from "../services/dataService";
import ToastService from "../services/toastService";
import { API_ENDPOINT } from "../types/enums";
import AccessDeniedComponent from "./accessDeniedComponent";
import RolePermissionInfo from "./rolePermissionInfo";

/**
 * Author : Pradeep_Rajendran@epam.com
 */
export default class RolePermissionService {

    static PORZIO_TENANT_ID = 1;
    static TEANANT_ADMIN_ROLE_ID = 1;
    // user info
    static USER = {};
    static USER_ID = -1;
    static USER_TENANT_ID = -1;
    static USER_ROLE_ID = -1;
    static IS_PORZIO_USER = false;

    static IS_TENANT_ADMIN = false;
    static IS_NOT_TENANT_ADMIN = true;

    static IZENDA_REPORT_USER_TYPE_IDs = [4];

    // powerBi related configs
    static powerBIPermissionID = 292;
    static powerBIPermissionIDs = [RolePermissionService.powerBIPermissionID];
    static hasOnlyPowerBIAccess = true; // will be set if any other permission has view Permission

    // cache
    static configurationMAP = new Map();

    static fetchPermissionsOBS = (_USER) => {
        RolePermissionService.IS_CONFIGURED = false;
        // temp
        RolePermissionService.USER = _USER;
        RolePermissionService.USER_ID = _USER.userId;
        RolePermissionService.USER_TENANT_ID = _USER.tenantId;
        RolePermissionService.IS_PORZIO_USER = _USER.isPorzioUser;

        RolePermissionService.USER_ROLE_ID = _USER.roleId;
        // RolePermissionService.USER_ROLE_ID = 47; // TODO: Role Override 1/2 (enable/disable)

        // TODO: If the user userTypeID is 3, then consider the user as tenant admin and ignore the assigned role
        RolePermissionService.IS_TENANT_ADMIN = (RolePermissionService.USER_ROLE_ID === RolePermissionService.TEANANT_ADMIN_ROLE_ID);
        RolePermissionService.IS_NOT_TENANT_ADMIN = !RolePermissionService.IS_TENANT_ADMIN;
        if (RolePermissionService.IS_TENANT_ADMIN) {
            RolePermissionService.hasOnlyPowerBIAccess = false; // tenant admin can access anything
        }

        if ((RolePermissionService.USER_TENANT_ID === RolePermissionService.PORZIO_TENANT_ID)) {
            return of([]);
        } else {
            return ApiService.getOBS(API_ENDPOINT.TENANT, `/Permission/FetchPermissionsView?tenantId=${RolePermissionService.USER_TENANT_ID}&roleId=${RolePermissionService.USER_ROLE_ID}`);
        }
    }

    static CONFIGURE = (_permissionsArray) => {
        if (RolePermissionService.PORZIO_TENANT_ID === RolePermissionService.USER_TENANT_ID) {
            RolePermissionService.IS_TENANT_ADMIN = true;
            RolePermissionService.IS_NOT_TENANT_ADMIN = false;

            //preload permissions
            RolePermissionService.CACHE();
        } else {
            // sort
            _permissionsArray = _permissionsArray.sort((x, y) => x.permissionId - y.permissionId);
            // console.log("permissions", _permissionsArray)

            RolePermissionService.configurationMAP = new Map();
            _permissionsArray.forEach(_permission => {
                // build
                let actionMAP = new Map();
                _permission.actionsList.forEach(action => {
                    actionMAP.set(action.actionId, action.isApplicable && action.hasAccess);
                });
                RolePermissionService.configurationMAP.set(_permission.permissionId, actionMAP);
            });
            // console.log("Parsed", RolePermissionService.configurationMAP);

            //preload permissions
            RolePermissionService.CACHE();
        }
    }


    static createActionId = 1;
    static canCreate = (_permissionId) => { return RolePermissionService._canPerformAction(_permissionId, RolePermissionService.createActionId); }

    static viewActionId = 2;
    static canView = (_permissionId) => {
        const _canView = RolePermissionService._canPerformAction(_permissionId, RolePermissionService.viewActionId);

        // role has view permissions other than powerbi 
        if (!RolePermissionService.powerBIPermissionIDs.includes(_permissionId) && _canView) {
            RolePermissionService.hasOnlyPowerBIAccess = false;
        }

        return _canView;
    }

    static editActionId = 3;
    static canEdit = (_permissionId) => { return RolePermissionService._canPerformAction(_permissionId, RolePermissionService.editActionId); }

    static deleteActionId = 4;
    static canDelete = (_permissionId) => { return RolePermissionService._canPerformAction(_permissionId, RolePermissionService.deleteActionId); }

    static configureActionId = 5;
    static canConfigure = (_permissionId) => { return RolePermissionService._canPerformAction(_permissionId, RolePermissionService.configureActionId); }

    static viewDeletedActionId = 6;
    static canViewDeleted = (_permissionId) => { return RolePermissionService._canPerformAction(_permissionId, RolePermissionService.viewDeletedActionId); }


    //#region "Internals"

    static _canPerformAction = (_permissionId, _actionId) => {
        if (_actionId < 1 || _actionId > 6) { // should be between 1 to 6
            ToastService.showWarning("Invalid _actionId, check the console log");
            console.warn(`Invalid _actionId : ${_actionId}`, RolePermissionService.configurationMAP);
            return null;
        }
        else if (_permissionId === -1) { return false }
        else {
            const permissionActionsMap = RolePermissionService.configurationMAP.get(_permissionId);
            if (DataService.mapHasNoElements(permissionActionsMap) || !permissionActionsMap.has(_actionId)) {
                ToastService.showWarning("No Actions Mapped for this Permission, check the console log");
                console.warn(`No Actions Mapped for this PermissionId (${_permissionId}) & ActionId (${_actionId}).`, RolePermissionService.configurationMAP);
                return null;
            } else {
                // return true/false based on (action.isApplicable && action.hasAccess)
                return permissionActionsMap.get(_actionId);
            }
        }
    }

    static isValidPermissionId = (_permissionId) => {
        // check permission id
        if (!DataService.isValidNumber(_permissionId) || !RolePermissionService.configurationMAP.has(_permissionId)) {
            ToastService.showWarning("Invalid _permissionId, check the console log");
            console.warn(`Invalid _permissionId : ${_permissionId}`, RolePermissionService.configurationMAP);
            return false;
        } else {
            return true;
        }
    }

    //#endregion

    static getAccessDeniedComponent = (_classes, _onClose, _showCancel = true, _title = "Access Denied",
        _message = `Your Role has insufficient privilege to perform this action.`
    ) => {
        return (<AccessDeniedComponent classes={_classes} onClose={_onClose} title={_title} message={_message} showCancel={_showCancel} />);
    }

    static showAccessDeniedToast = (_message = "Your Role has insufficient privilege to perform this action.") => {
        ToastService.showWarning(_message);
    }

    //#region "CACHE"

    static IS_CONFIGURED = false;

    static isTenantReportSubscriptionsAllowed = () => {
        return RolePermissionService.USER_TENANT_ID === RolePermissionService.PORZIO_TENANT_ID;
    }
    static isTenantReportSubscriptionsNotAllowed = () => {
        return !RolePermissionService.isTenantReportSubscriptionsAllowed();
    }

    static isIzendaReportsNotAllowed = () => {
        return !RolePermissionService.isIzendaReportsAllowed();
    }

    static isIzendaReportsAllowed = () => {
        let oRET = false;
        if (
            RolePermissionService.USER_TENANT_ID === RolePermissionService.PORZIO_TENANT_ID &&
            RolePermissionService.IS_PORZIO_USER &&
            RolePermissionService.IZENDA_REPORT_USER_TYPE_IDs.includes(RolePermissionService.USER.userTypeId)
        ) {
            oRET = true;
        }

        return oRET;
    }

    // PROFILES
    static PROFILE_CENTER = RolePermissionInfo.INIT();
    static PROFILE_DETAILS = RolePermissionInfo.INIT();
    static PROFILE_DETAILS_STATUS = RolePermissionInfo.INIT();
    // static PROFILE_DETAILS_STATUS_ACTIVE = RolePermissionInfo.INIT();
    static PROFILE_DETAILS_ADDRESS = RolePermissionInfo.INIT();
    static PROFILE_DETAILS_CONTACT_METHOD = RolePermissionInfo.INIT();
    static PROFILE_TASK = RolePermissionInfo.INIT();
    static PROFILE_AGREEMENTS = RolePermissionInfo.INIT();
    static PROFILE_CONSENTS = RolePermissionInfo.INIT();
    static PROFILE_TRANSACTIONS = RolePermissionInfo.INIT();
    static PROFILE_DOCUMENTS = RolePermissionInfo.INIT();
    static PROFILE_LINKED_PROFILES = RolePermissionInfo.INIT();
    static PROFILE_IDENTITIFERS = RolePermissionInfo.INIT();
    static PROFILE_REFERENCE_DATA = RolePermissionInfo.INIT();
    static PROFILE_EXPANDED_VIEW = RolePermissionInfo.INIT();
    static PROFILE_EXPANDED_BULK_EDIT = RolePermissionInfo.INIT();
    static PROFILE_EXPANDED_BULK_STATUS_FIELD = RolePermissionInfo.INIT();
    static PROFILE_EXPANDED_VIEW_EXPORT = RolePermissionInfo.INIT();
    static PROFILE_EXPANDED_VIEW_TASK = RolePermissionInfo.INIT();

    // TRANSACTIONS
    static TRANSACTION_CENTER = RolePermissionInfo.INIT();
    static TRANSACTION_DETAILS = RolePermissionInfo.INIT();
    static TRANSACTION_STATUS = RolePermissionInfo.INIT();
    static TRANSACTION_RECIPINET_INFORMATION = RolePermissionInfo.INIT();
    static TRANSACTION_AGREEMENTS = RolePermissionInfo.INIT();
    static TRANSACTION_RELATED_DOCUMENTS = RolePermissionInfo.INIT();
    static TRANSACTION_REPORT_REFERENCES = RolePermissionInfo.INIT();
    static TRANSACTION_TASK = RolePermissionInfo.INIT();
    static TRANSACTION_CLONE_OR_COPY = RolePermissionInfo.INIT();
    static TRANSACTION_PRINT = RolePermissionInfo.INIT();
    static TRANSACTION_AUDIT = RolePermissionInfo.INIT();
    static TRANSACTION_EXPANDED_VIEW = RolePermissionInfo.INIT();
    static TRANSACTION_EXPANDED_BULK_EDIT = RolePermissionInfo.INIT();
    static TRANSACTION_EXPANDED_STATUS_FIELD = RolePermissionInfo.INIT();
    static TRANSACTION_EXPANDED_RECIPIENT_INFORMATION_FIELDS = RolePermissionInfo.INIT();
    static TRANSACTION_EXPANDED_EXPORT = RolePermissionInfo.INIT();
    static TRANSACTION_EXPANDED_TASK = RolePermissionInfo.INIT();

    // AGREEMENTS
    static AGREEMENTS = RolePermissionInfo.INIT();
    static AGREEMENT_DETAIL = RolePermissionInfo.INIT();
    static AGREEMENT_CONSENT = RolePermissionInfo.INIT();
    static AGREEMENT_DOCUMENTS = RolePermissionInfo.INIT();
    static AGREEMENT_TRANSACTIONS = RolePermissionInfo.INIT();

    // SOURCE_MAP - SOURCE_FILE
    static SOURCE_FILE_LIST = RolePermissionInfo.INIT();
    static SOURCE_FILE_LIST_EXPORT = RolePermissionInfo.INIT();
    static SOURCE_FILE_DETAIL = RolePermissionInfo.INIT();
    // static SOURCE_FILE_DETAIL_AUDIT = RolePermissionInfo.INIT();

    // BUSINESS-RULES
    static BUSINESS_RULE_LIST = RolePermissionInfo.INIT();
    static BUSINESS_RULE_LIST_EXPORT = RolePermissionInfo.INIT();
    static BUSINESS_RULE_DETAIL = RolePermissionInfo.INIT();
    // static BUSINESS_RULE_DETAIL_AUDIT = RolePermissionInfo.INIT();

    // POWER-BI
    static POWER_BI_REPORT = RolePermissionInfo.INIT();


    // USER-MANAGEMENT
    static ROLE_LIST = RolePermissionInfo.INIT();
    static ROLE_LIST_EXPORT = RolePermissionInfo.INIT();
    static ROLE_DETAIL = RolePermissionInfo.INIT();
    static ROLE_DETAIL_TYPE_PORZIO_LS = RolePermissionInfo.INIT();
    static ROLE_DETAIL_TYPE_CUSTOMER = RolePermissionInfo.INIT();
    static ROLE_DETAIL_PORZIO_REQUIRED = RolePermissionInfo.INIT();
    static ROLE_PERMISSIONS = RolePermissionInfo.INIT();
    // static ROLE_MEMBERS = RolePermissionInfo.INIT();
    // static ROLE_MEMBERS_EXPORT = RolePermissionInfo.INIT();
    static USER_LIST = RolePermissionInfo.INIT();
    static USER_LIST_EXPORT = RolePermissionInfo.INIT();
    static USER_DETAIL_PERSONAL_INFORMATION = RolePermissionInfo.INIT();
    static USER_DETAIL_PERSONAL_INFORMATION_PRIMARY_EMAIL = RolePermissionInfo.INIT();
    static USER_DETAIL_PERSONAL_INFORMATION_ROLES = RolePermissionInfo.INIT();
    static USER_DETAIL_PERSONAL_INFORMATION_ACTIVE_INACTIVE = RolePermissionInfo.INIT();
    static USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP = RolePermissionInfo.INIT();
    // static USER_DETAIL_AUTHENTICATION = RolePermissionInfo.INIT();
    static USER_DETAIL_COMMUNICATIONS = RolePermissionInfo.INIT();


    // FIELD-MANAGEMENT
    static PORZIO_FIELD_LIST = RolePermissionInfo.INIT();
    static PORZIO_FIELD_LIST_EXPORT = RolePermissionInfo.INIT();
    static PORZIO_FIELD_DETAIL = RolePermissionInfo.INIT();
    //---
    static CUSTOMER_FIELD_LIST = RolePermissionInfo.INIT();
    static CUSTOMER_FIELD_LIST_EXPORT = RolePermissionInfo.INIT();
    static FIELD_DETAIL = RolePermissionInfo.INIT();

    // FIELD-VALUE-MAPPING
    static DEFAULT_MAPPING_LIST = RolePermissionInfo.INIT();
    static DEFAULT_MAPPING_EXPORT = RolePermissionInfo.INIT();
    static DEFAULT_MAPPING_DETAIL = RolePermissionInfo.INIT();
    static COMPLIANCE_REPORT_MAPPING_LIST = RolePermissionInfo.INIT();
    static COMPLIANCE_REPORT_MAPPING_EXPORT = RolePermissionInfo.INIT();
    static COMPLIANCE_REPORT_MAPPING_DETAIL = RolePermissionInfo.INIT();
    static CONSENT_MAPPING_LIST = RolePermissionInfo.INIT();
    static CONSENT_MAPPING_EXPORT = RolePermissionInfo.INIT();
    static CONSENT_MAPPING_DETAIL = RolePermissionInfo.INIT();

    // TENANT SETUP
    static TENANT_DETAIL = RolePermissionInfo.INIT();

    // MASTER-DATA
    //---PRODUCT
    static PRODUCT = RolePermissionInfo.INIT();
    static PRODUCT_DETAIL = RolePermissionInfo.INIT();
    //---SALESREP
    static SALESREP = RolePermissionInfo.INIT();
    static SALESREP_DETAIL = RolePermissionInfo.INIT();
    //---MATERIALS-INFO
    static MATERIAL = RolePermissionInfo.INIT();
    static MATERIAL_DETAIL = RolePermissionInfo.INIT();
    //---STUDY-MASTER
    static STUDY = RolePermissionInfo.INIT();
    static STUDY_DETAIL = RolePermissionInfo.INIT();
    //---HCP-MASTER
    static HCP = RolePermissionInfo.INIT();
    //static HCP_DETAIL = RolePermissionInfo.INIT();

    //HOME-SCREEN
    //---NEWSFEED
    static NEWSFEED = RolePermissionInfo.INIT();
    static NEWSFEED_DETAIL = RolePermissionInfo.INIT();

    //---TASK
    static TASK = RolePermissionInfo.INIT();
    static TASK_DETAIL = RolePermissionInfo.INIT();
    static TASK_COMMENTS = RolePermissionInfo.INIT();

    //---EVENT
    static EVENT = RolePermissionInfo.INIT();
    static EVENT_DETAIL = RolePermissionInfo.INIT();

    //---DATACENTER
    static DATA_CENTER = RolePermissionInfo.INIT();
    static FILE_STATUS = RolePermissionInfo.INIT();
    static IMPORT_SUMMARY_REPORT = RolePermissionInfo.INIT();
    static TRANSACTION_EXPANDED_VIEW_DATACENTER = RolePermissionInfo.INIT();
    static PROFILE_EXPANDED_VIEW_DATACENTER = RolePermissionInfo.INIT();
    static DOWNLOAD_FILE_DATACENTER = RolePermissionInfo.INIT();
    static UPLOAD_FILE_DATACENTER = RolePermissionInfo.INIT();
    static IMPORT_TEMPLATE = RolePermissionInfo.INIT();
    static IMPORT_TEMPLATE_DETAIL = RolePermissionInfo.INIT();
    static DOWNLOAD_TEMPLATE = RolePermissionInfo.INIT();

    //---Documents
    static SYSTEM_DOCUMENTS = RolePermissionInfo.INIT();
    static SYSTEM_DOCUMENTS_DETAILS = RolePermissionInfo.INIT();
    static DOWNLOAD_SYSTEM_DOCUMENTS = RolePermissionInfo.INIT();
    static COMPLIANCE_DOCUMENTS = RolePermissionInfo.INIT();
    static COMPLIANCE_DOCUMENTS_DETAILS = RolePermissionInfo.INIT();
    static DOWNLOAD_COMPLIANCE_DOCUMENTS = RolePermissionInfo.INIT();
    static CUSTOMER_DOCUMENTS = RolePermissionInfo.INIT();
    static CUSTOMER_DOCUMENTS_DETAILS = RolePermissionInfo.INIT();
    static DOWNLOAD_CUSTOMER_DOCUMENTS = RolePermissionInfo.INIT();

    static CACHE = () => {

        // PROFILES
        RolePermissionService.PROFILE_CENTER = RolePermissionInfo.GET(98, "PROFILE_CENTER");
        RolePermissionService.PROFILE_DETAILS = RolePermissionInfo.GET(100, "PROFILE_DETAILS");
        RolePermissionService.PROFILE_DETAILS_STATUS = RolePermissionInfo.GET(243, "PROFILE_DETAILS_STATUS");
        // RolePermissionService.PROFILE_DETAILS_STATUS_ACTIVE = RolePermissionInfo.GET(244, "PROFILE_DETAILS_STATUS_ACTIVE");
        RolePermissionService.PROFILE_DETAILS_ADDRESS = RolePermissionInfo.GET(101, "PROFILE_DETAILS_ADDRESS");
        RolePermissionService.PROFILE_DETAILS_CONTACT_METHOD = RolePermissionInfo.GET(102, "PROFILE_DETAILS_CONTACT_METHOD");
        RolePermissionService.PROFILE_TASK = RolePermissionInfo.GET(290, "PROFILE_TASK");
        RolePermissionService.PROFILE_AGREEMENTS = RolePermissionInfo.GET(105, "PROFILE_AGREEMENTS");
        RolePermissionService.PROFILE_CONSENTS = RolePermissionInfo.GET(106, "PROFILE_CONSENTS");
        RolePermissionService.PROFILE_TRANSACTIONS = RolePermissionInfo.GET(78, "PROFILE_TRANSACTIONS");
        RolePermissionService.PROFILE_DOCUMENTS = RolePermissionInfo.GET(108, "PROFILE_DOCUMENTS");
        RolePermissionService.PROFILE_LINKED_PROFILES = RolePermissionInfo.GET(109, "PROFILE_LINKED_PROFILES");
        RolePermissionService.PROFILE_IDENTITIFERS = RolePermissionInfo.GET(110, "PROFILE_IDENTITIFERS");
        RolePermissionService.PROFILE_REFERENCE_DATA = RolePermissionInfo.GET(291, "PROFILE_REFERENCE_DATA");
        RolePermissionService.PROFILE_EXPANDED_VIEW = RolePermissionInfo.GET(113, "PROFILE_EXPANDED_VIEW");
        RolePermissionService.PROFILE_EXPANDED_BULK_EDIT = RolePermissionInfo.GET(114, "PROFILE_EXPANDED_BULK_EDIT");
        RolePermissionService.PROFILE_EXPANDED_BULK_STATUS_FIELD = RolePermissionInfo.GET(115, "PROFILE_EXPANDED_BULK_STATUS_FIELD");
        RolePermissionService.PROFILE_EXPANDED_VIEW_EXPORT = RolePermissionInfo.GET(116, "PROFILE_EXPANDED_VIEW_EXPORT");
        RolePermissionService.PROFILE_EXPANDED_VIEW_TASK = RolePermissionInfo.GET(117, "PROFILE_EXPANDED_VIEW_TASK");

        // TRANSACTIONS
        RolePermissionService.TRANSACTION_CENTER = RolePermissionInfo.GET(76, "TRANSACTION_CENTRE");
        RolePermissionService.TRANSACTION_DETAILS = RolePermissionInfo.GET(78, "TRANSACTION_DETAILS");
        RolePermissionService.TRANSACTION_STATUS = RolePermissionInfo.GET(79, "TRANSACTION_STATUS");
        RolePermissionService.TRANSACTION_RECIPINET_INFORMATION = RolePermissionInfo.GET(80, "TRANSACTION_RECIPINET_INFORMATION");
        RolePermissionService.TRANSACTION_AGREEMENTS = RolePermissionInfo.GET(81, "TRANSACTION_AGREEMENTS");
        RolePermissionService.TRANSACTION_RELATED_DOCUMENTS = RolePermissionInfo.GET(82, "TRANSACTION_RELATED_DOCUMENTS");
        RolePermissionService.TRANSACTION_REPORT_REFERENCES = RolePermissionInfo.GET(83, "TRANSACTION_REPORT_REFERENCES");
        RolePermissionService.TRANSACTION_TASK = RolePermissionInfo.GET(84, "TRANSACTION_TASK");
        RolePermissionService.TRANSACTION_CLONE_OR_COPY = RolePermissionInfo.GET(86, "TRANSACTION_CLONE_OR_COPY");
        RolePermissionService.TRANSACTION_PRINT = RolePermissionInfo.GET(241, "TRANSACTION_PRINT");
        RolePermissionService.TRANSACTION_AUDIT = RolePermissionInfo.GET(87, "TRANSACTION_AUDIT");
        RolePermissionService.TRANSACTION_EXPANDED_VIEW = RolePermissionInfo.GET(89, "TRANSACTION_EXPANDED_VIEW");
        RolePermissionService.TRANSACTION_EXPANDED_BULK_EDIT = RolePermissionInfo.GET(90, "TRANSACTION_EXPANDED_BULK_EDIT");
        RolePermissionService.TRANSACTION_EXPANDED_STATUS_FIELD = RolePermissionInfo.GET(91, "TRANSACTION_EXPANDED_STATUS_FIELD");
        RolePermissionService.TRANSACTION_EXPANDED_RECIPIENT_INFORMATION_FIELDS = RolePermissionInfo.GET(92, "TRANSACTION_EXPANDED_RECIPIENT_INFORMATION_FIELDS");
        RolePermissionService.TRANSACTION_EXPANDED_EXPORT = RolePermissionInfo.GET(93, "TRANSACTION_EXPANDED_EXPORT");
        RolePermissionService.TRANSACTION_EXPANDED_TASK = RolePermissionInfo.GET(94, "TRANSACTION_EXPANDED_TASK");

        // AGREEMENTS
        RolePermissionService.AGREEMENTS = RolePermissionInfo.GET(121, "AGREEMENT_CENTRE");
        RolePermissionService.AGREEMENT_DETAIL = RolePermissionInfo.GET(123, "AGREEMENT_DETAIL");
        RolePermissionService.AGREEMENT_CONSENT = RolePermissionInfo.GET(124, "AGREEMENT_CONSENT");
        RolePermissionService.AGREEMENT_DOCUMENTS = RolePermissionInfo.GET(125, "AGREEMENT_DOCUMENTS");
        RolePermissionService.AGREEMENT_TRANSACTIONS = RolePermissionInfo.GET(126, "AGREEMENT_TRANSACTIONS");

        // SOURCE-MAP
        RolePermissionService.SOURCE_FILE_LIST = RolePermissionInfo.GET(235, "SOURCE_FILE_LIST");
        RolePermissionService.SOURCE_FILE_LIST_EXPORT = RolePermissionInfo.GET(236, "SOURCE_FILE_LIST_EXPORT");
        RolePermissionService.SOURCE_FILE_DETAIL = RolePermissionInfo.GET(237, "SOURCE_FILE_DETAIL");
        // RolePermissionService.SOURCE_FILE_DETAIL_AUDIT = RolePermissionInfo.GET(238, "SOURCE_FILE_DETAIL_AUDIT");

        // BUSINESS-RULES
        RolePermissionService.BUSINESS_RULE_LIST = RolePermissionInfo.GET(223, "BUSINESS_RULE_LIST");
        RolePermissionService.BUSINESS_RULE_LIST_EXPORT = RolePermissionInfo.GET(224, "BUSINESS_RULE_LIST_EXPORT");
        RolePermissionService.BUSINESS_RULE_DETAIL = RolePermissionInfo.GET(226, "BUSINESS_RULE_DETAIL");
        // RolePermissionService.BUSINESS_RULE_DETAIL_AUDIT = RolePermissionInfo.GET(228, "BUSINESS_RULE_DETAIL_AUDIT");

        // POWER-BI
        RolePermissionService.POWER_BI_REPORT = RolePermissionInfo.GET(RolePermissionService.powerBIPermissionID, "POWER_BI_REPORT");

        // USER-MANAGEMENT
        RolePermissionService.ROLE_LIST = RolePermissionInfo.GET(23, "ROLE_LIST");
        RolePermissionService.ROLE_LIST_EXPORT = RolePermissionInfo.GET(24, "ROLE_LIST_EXPORT");
        RolePermissionService.ROLE_DETAIL = RolePermissionInfo.GET(26, "ROLE_DETAIL");
        RolePermissionService.ROLE_DETAIL_TYPE_PORZIO_LS = RolePermissionInfo.GET(27, "ROLE_DETAIL_TYPE_PORZIO_LS");
        RolePermissionService.ROLE_DETAIL_TYPE_CUSTOMER = RolePermissionInfo.GET(28, "ROLE_DETAIL_TYPE_CUSTOMER");
        RolePermissionService.ROLE_DETAIL_PORZIO_REQUIRED = RolePermissionInfo.GET(29, "ROLE_DETAIL_PORZIO_REQUIRED");
        RolePermissionService.ROLE_PERMISSIONS = RolePermissionInfo.GET(30, "ROLE_PERMISSIONS");
        // RolePermissionService.ROLE_MEMBERS = RolePermissionInfo.GET(31, "ROLE_MEMBERS");
        // RolePermissionService.ROLE_MEMBERS_EXPORT = RolePermissionInfo.GET(32, "ROLE_MEMBERS_EXPORT");
        RolePermissionService.USER_LIST = RolePermissionInfo.GET(34, "USER_LIST");
        RolePermissionService.USER_LIST_EXPORT = RolePermissionInfo.GET(35, "USER_LIST_EXPORT");
        RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION = RolePermissionInfo.GET(37, "USER_DETAIL_PERSONAL_INFORMATION");
        RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_PRIMARY_EMAIL = RolePermissionInfo.GET(38, "USER_DETAIL_PERSONAL_INFORMATION_PRIMARY_EMAIL");
        RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ROLES = RolePermissionInfo.GET(39, "USER_DETAIL_PERSONAL_INFORMATION_ROLES");
        RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ACTIVE_INACTIVE = RolePermissionInfo.GET(40, "USER_DETAIL_PERSONAL_INFORMATION_ACTIVE_INACTIVE");
        RolePermissionService.USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP = RolePermissionInfo.GET(41, "USER_DETAIL_PERSONAL_INFORMATION_ORG_MEMBERSHIP");
        // RolePermissionService.USER_DETAIL_AUTHENTICATION = RolePermissionInfo.GET(42, "USER_DETAIL_AUTHENTICATION");
        RolePermissionService.USER_DETAIL_COMMUNICATIONS = RolePermissionInfo.GET(43, "USER_DETAIL_COMMUNICATIONS");

        // FIELD-MANAGEMENT
        //------PORZIO-FIELDS
        RolePermissionService.PORZIO_FIELD_LIST = RolePermissionInfo.GET(193, "PORZIO_FIELD_LIST");
        RolePermissionService.PORZIO_FIELD_LIST_EXPORT = RolePermissionInfo.GET(194, "PORZIO_FIELD_LIST_EXPORT");
        RolePermissionService.PORZIO_FIELD_DETAIL = RolePermissionInfo.GET(196, "PORZIO_FIELD_DETAIL");
        // RolePermissionService.PORZIO_FIELD_DETAIL_ENTITY = RolePermissionInfo.GET(197, "PORZIO_FIELD_DETAIL_ENTITY");
        // RolePermissionService.PORZIO_FIELD_DETAIL_LABEL = RolePermissionInfo.GET(198, "PORZIO_FIELD_DETAIL_LABEL");
        // RolePermissionService.PORZIO_FIELD_DETAIL_PLS_REQUIRED = RolePermissionInfo.GET(199, "PORZIO_FIELD_DETAIL_PLS_REQUIRED");
        // RolePermissionService.PORZIO_FIELD_DETAIL_ACTIVE_INACTIVE = RolePermissionInfo.GET(200, "PORZIO_FIELD_DETAIL_ACTIVE_INACTIVE");
        // RolePermissionService.PORZIO_FIELD_DETAIL_FIELD_TYPE = RolePermissionInfo.GET(201, "PORZIO_FIELD_DETAIL_FIELD_TYPE");
        //------CUSTOM-FIELDS
        // RolePermissionService.PORZIO_FIELD_DETAIL_FIELD_SECURITY = RolePermissionInfo.GET(202, "PORZIO_FIELD_DETAIL_FIELD_SECURITY");
        RolePermissionService.CUSTOMER_FIELD_LIST = RolePermissionInfo.GET(204, "CUSTOMER_FIELD_LIST");
        RolePermissionService.CUSTOMER_FIELD_LIST_EXPORT = RolePermissionInfo.GET(205, "CUSTOMER_FIELD_LIST_EXPORT");
        RolePermissionService.FIELD_DETAIL = RolePermissionInfo.GET(207, "FIELD_DETAIL");
        // RolePermissionService.FIELD_DETAIL_ENTITY = RolePermissionInfo.GET(208, "FIELD_DETAIL_ENTITY");
        // RolePermissionService.FIELD_DETAIL_LABEL = RolePermissionInfo.GET(209, "FIELD_DETAIL_LABEL");
        // RolePermissionService.FIELD_DETAIL_PLS_REQUIRED = RolePermissionInfo.GET(210, "FIELD_DETAIL_PLS_REQUIRED");
        // RolePermissionService.FIELD_DETAIL_ACTIVE_INACTIVE = RolePermissionInfo.GET(211, "FIELD_DETAIL_ACTIVE_INACTIVE");
        // RolePermissionService.FIELD_DETAIL_FIELD_TYPE = RolePermissionInfo.GET(212, "FIELD_DETAIL_FIELD_TYPE");
        // RolePermissionService.FIELD_DETAIL_FIELD_SECURITY = RolePermissionInfo.GET(213, "FIELD_DETAIL_FIELD_SECURITY");

        // FIELD-VALUE-MAPPING
        RolePermissionService.DEFAULT_MAPPING_LIST = RolePermissionInfo.GET(215, "DEFAULT_MAPPING_LIST");
        RolePermissionService.DEFAULT_MAPPING_EXPORT = RolePermissionInfo.GET(216, "DEFAULT_MAPPING_EXPORT");
        RolePermissionService.DEFAULT_MAPPING_DETAIL = RolePermissionInfo.GET(217, "DEFAULT_MAPPING_DETAIL");
        RolePermissionService.COMPLIANCE_REPORT_MAPPING_LIST = RolePermissionInfo.GET(219, "COMPLIANCE_REPORT_MAPPING_LIST");
        RolePermissionService.COMPLIANCE_REPORT_MAPPING_EXPORT = RolePermissionInfo.GET(220, "COMPLIANCE_REPORT_MAPPING_EXPORT");
        RolePermissionService.COMPLIANCE_REPORT_MAPPING_DETAIL = RolePermissionInfo.GET(221, "COMPLIANCE_REPORT_MAPPING_DETAIL");
        RolePermissionService.CONSENT_MAPPING_LIST = RolePermissionInfo.GET(285, "CONSENT_MAPPING_LIST");
        RolePermissionService.CONSENT_MAPPING_EXPORT = RolePermissionInfo.GET(286, "CONSENT_MAPPING_EXPORT");
        RolePermissionService.CONSENT_MAPPING_DETAIL = RolePermissionInfo.GET(287, "CONSENT_MAPPING_DETAIL");

        // TENANT-SETUP
        RolePermissionService.TENANT_DETAIL = RolePermissionInfo.GET(175, "TENANT_DETAIL");

        //Product
        RolePermissionService.PRODUCT = RolePermissionInfo.GET(297, "PRODUCT");
        RolePermissionService.PRODUCT_DETAIL = RolePermissionInfo.GET(299, "PRODUCT_DETAIL");

        //SALESREP
        RolePermissionService.SALESREP = RolePermissionInfo.GET(300, "SALESREP");
        RolePermissionService.SALESREP_DETAIL = RolePermissionInfo.GET(302, "SALESREP_DETAIL");

        //MATERIALS
        RolePermissionService.MATERIAL = RolePermissionInfo.GET(303, "MATERIALS");
        RolePermissionService.MATERIAL_DETAIL = RolePermissionInfo.GET(305, "MATERIAL_DETAIL");

        //STUDY-MASTER
        RolePermissionService.STUDY = RolePermissionInfo.GET(306, "STUDY");
        RolePermissionService.STUDY_DETAIL = RolePermissionInfo.GET(308, "STUDY_DETAIL");

        //HCP-MASTER
        RolePermissionService.HCP = RolePermissionInfo.GET(306, "HCP"); //TODO : need permission ID for HCP
        //RolePermissionService.HCP_DETAIL = RolePermissionInfo.GET(308, "HCP_DETAIL");//TODO : need permission ID for HCP

        //NEWSFEED
        RolePermissionService.NEWSFEED = RolePermissionInfo.GET(294, "STUDY");
        RolePermissionService.NEWSFEED_DETAIL = RolePermissionInfo.GET(48, "STUDY_DETAIL");

        //TASK
        RolePermissionService.TASK = RolePermissionInfo.GET(295, "TASK");
        RolePermissionService.TASK_DETAIL = RolePermissionInfo.GET(54, "TASK_DETAIL");
        RolePermissionService.TASK_COMMENTS = RolePermissionInfo.GET(55, "TASK_COMMENTS");

        //EVENT
        RolePermissionService.EVENT = RolePermissionInfo.GET(296, "EVENT");
        RolePermissionService.EVENT_DETAIL = RolePermissionInfo.GET(58, "EVENT_DETAIL");

        //DATACENTER
        RolePermissionService.DATA_CENTER = RolePermissionInfo.GET(13, "DATA_CENTER");
        RolePermissionService.FILE_STATUS = RolePermissionInfo.GET(14, "FILE_STATUS");
        RolePermissionService.IMPORT_SUMMARY_REPORT = RolePermissionInfo.GET(15, "IMPORT_SUMMARY_REPORT");
        RolePermissionService.TRANSACTION_EXPANDED_VIEW_DATACENTER = RolePermissionInfo.GET(16, "TRANSACTION_EXPANDED_VIEW_DATACENTER");
        RolePermissionService.PROFILE_EXPANDED_VIEW_DATACENTER = RolePermissionInfo.GET(239, "PROFILE_EXPANDED_VIEW_DATACENTER");
        RolePermissionService.DOWNLOAD_FILE_DATACENTER = RolePermissionInfo.GET(289, "DOWNLOAD_FILE_DATACENTER");
        RolePermissionService.UPLOAD_FILE_DATACENTER = RolePermissionInfo.GET(310, "UPLOAD_FILE_DATACENTER");
        RolePermissionService.IMPORT_TEMPLATE = RolePermissionInfo.GET(4, "PROFILE_EXPANDED_VIEW_DATACENTER");
        RolePermissionService.IMPORT_TEMPLATE_DETAIL = RolePermissionInfo.GET(5, "DOWNLOAD_FILE_DATACENTER");
        RolePermissionService.DOWNLOAD_TEMPLATE = RolePermissionInfo.GET(309, "DOWNLOAD_TEMPLATE");

        //DOCUMENTS
        RolePermissionService.SYSTEM_DOCUMENTS = RolePermissionInfo.GET(162, "SYSTEM_DOCUMENTS");
        RolePermissionService.SYSTEM_DOCUMENTS_DETAILS = RolePermissionInfo.GET(163, "SYSTEM_DOCUMENTS_DETAILS");
        RolePermissionService.DOWNLOAD_SYSTEM_DOCUMENTS = RolePermissionInfo.GET(164, "DOWNLOAD_SYSTEM_DOCUMENTS");
        RolePermissionService.COMPLIANCE_DOCUMENTS = RolePermissionInfo.GET(165, "COMPLIANCE_DOCUMENTS");
        RolePermissionService.COMPLIANCE_DOCUMENTS_DETAILS = RolePermissionInfo.GET(166, "COMPLIANCE_DOCUMENTS_DETAILS");
        RolePermissionService.DOWNLOAD_COMPLIANCE_DOCUMENTS = RolePermissionInfo.GET(167, "DOWNLOAD_COMPLIANCE_DOCUMENTS");
        RolePermissionService.CUSTOMER_DOCUMENTS = RolePermissionInfo.GET(168, "CUSTOMER_DOCUMENTS");
        RolePermissionService.CUSTOMER_DOCUMENTS_DETAILS = RolePermissionInfo.GET(169, "CUSTOMER_DOCUMENTS_DETAILS");
        RolePermissionService.DOWNLOAD_CUSTOMER_DOCUMENTS = RolePermissionInfo.GET(170, "DOWNLOAD_CUSTOMER_DOCUMENTS");

        // finally set flag
        RolePermissionService.IS_CONFIGURED = true;
    }
    //#endregion

    //---
}