import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../shared/store/authProvider";
import ApiService from "../../../shared/services/apiService";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray, DataService } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction, API_ENDPOINT } from "../../../shared/types/enums";
import LayoutService from "../../../shared/services/layoutService";
import { Typography, Divider, Grid, Tooltip, IconButton, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import { MatIconService } from "../../../shared/services/theme/matIconService";

import ReportYearAgGridRendererComponent from "./compliance-consent-details/reportYearAgGridRendererComponent";
import ComplianceConsentMappingService from "./complianceConsentMappingService";
import ComplianceConsentDialogComponent from "./compliance-consent-details/complianceConsentDialogComponent";
import LookupService from "../../../shared/services/lookupService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class ComplianceConsentMappingComponent extends Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            frameworkComponents: {
                reportYearAgGridRendererComponent: ReportYearAgGridRendererComponent,
            },
            showDialog: false,
            modalAgNode: null,
            selectedReportId: 0,
            selectedYearId: 0,
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.fetchData();
    }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
        /**
         * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
         * this operator is very handy as we don't have to independently wait for each result
         */
        this.apiSubscriptions.add(
            combineLatest([
                ComplianceConsentMappingService.fetchConsentMappingsAsOBS(this.context.user.tenantId,),
                ComplianceConsentMappingService.fetchReportMappings(this.context.user.tenantId,),
                ComplianceConsentMappingService.getYearsByReportId(this.context.user.tenantId, this.state.selectedReportId),
            ]).subscribe(
                // success
                ([_data, _reportList, _yearList,]) => {
                    const _arrReportsObjsDupes = _reportList.map(r => ({ id: r.reportId, text: r.reportName }));
                    let _arrReportsObjs = _arrReportsObjsDupes.filter((dupeReport, index, self) =>
                        index === self.findIndex((dedupedReport) => (
                            dedupedReport.text === dupeReport.text)));

                    _arrReportsObjs = _arrReportsObjs.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
                    _arrReportsObjs = _arrReportsObjs.filter(x => !LookupService.REPORTS_TO_BE_REMOVED_CONSENT.includes(x.text));
                    //_arrReportsObjs.forEach(x => x.id = x.text);

                    _yearList = _yearList.map(r => ({ id: r.reportPathId, text: r.reportYear }));
                    _yearList = _yearList.filter((dupeReport, index, self) =>
                        index === self.findIndex((dedupedReport) => (
                            dedupedReport.text === dupeReport.text)));
                    _yearList = _yearList.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                    this.setState(
                        {
                            data: _data,
                            reportsListWithAll: [{ id: 0, text: "All" }, ..._arrReportsObjs],
                            yearList: [{ id: 0, text: "All" }, ..._yearList],
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    onGridReady = (event) => {
        event.api.closeToolPanel();
        event.api.sizeColumnsToFit();
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
    };

    onEntitySelectClick = (event) => {
        console.log(event);
        this.setState({ selectedEntityId: event.target.value });

        switch (event.target.value) {
            case 0:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "All" }, ...this.state.profileFieldList, ...this.state.transactionFieldList] });
                this.setState({ selectedFieldId: 0 });
                break;
            case 1:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "All" }, ...this.state.profileFieldList] });
                break;
            case 2:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "All" }, ...this.state.transactionFieldList] });
                break;
            default:
                this.setState({ selectedEntityId: event.target.value });
        }

        this.gridApi.setFilterModel(null);

        // this.gridApi.setFilterModel({
        //     entityName: {
        //         filterType: 'text',
        //         type: 'equals',
        //         filter: event.target.value === 0 ? null : this.state.entityList.find(x => x.entityId === event.target.value).entityName,
        //     },
        // });
    }

    onReportSelectClick = (event) => {
        this.setState(
            {
                selectedReportId: event.target.value,
                selectedYearId: 0,
            },
        );
        this.gridApi.setFilterModel({
            reportName: {
                filterType: 'text',
                type: 'equals',
                filter: event.target.value === 0 ? null : this.state.reportsListWithAll.find(x => x.id === event.target.value).text,
            },
        });
        this.fetchYear(event.target.value);
    }

    fetchYear = (_reportId) => {
        let url = `/ComplianceMapping/FetchReportTemplateYears/${this.context.user.tenantId}`
        if (_reportId !== 0) {
            url = `/ComplianceMapping/FetchReportTemplateYears/${this.context.user.tenantId}?reportId=${_reportId}`
        }
        ApiService.getOBS(
            API_ENDPOINT.TENANT,
            url
        ).subscribe(
            (successResult) => {
                let _yearList = successResult;
                if (_yearList) {
                    _yearList = _yearList.map(r => ({ id: r.reportPathId, text: r.reportYear }));
                    _yearList = _yearList.filter((dupeReport, index, self) =>
                        index === self.findIndex((dedupedReport) => (
                            dedupedReport.text === dupeReport.text)));
                    _yearList = _yearList.slice().sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
                    this.setState({
                        fetchResult: ResultStatus.SUCCESS,
                        yearList: [{ id: 0, text: "All" }, ..._yearList],
                    });
                    console.log("successResult: ", successResult);
                } else {
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            },
            (errorResult) => {
                console.error("Error while saving Product", errorResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
            }
        );
    }

    onYearSelectClick = (event) => {
        this.setState(
            {
                selectedYearId: event.target.value,
            },
        );
        this.gridApi.setFilterModel({
            reportName: {
                filterType: 'text',
                type: 'equals',
                filter: this.state.selectedReportId === 0 ? null : this.state.reportsListWithAll.find(x => x.id === this.state.selectedReportId).text,
            },
            templateYear: {
                filterType: 'text',
                type: 'equals',
                filter: event.target.value === 0 ? null : this.state.yearList.find(x => x.id === event.target.value).text,
            },
        });
    }

    onDownloadClick = () => {
        let api = this.gridApi, params = this.getParams();
        api.exportDataAsExcel(params);
    }

    getParams() {
        return {
            // allColumns: true,
            columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
            fileName: `Compliance_Default_Mapping ${new Date().toDateString()}`
        };
    }

    showConsentMappingDialog = (cell, data) => {
        console.log("showConsentMappingDialog", data);
        this.setState({
            showDialog: true,
            modalAgNode: data,
            selectedReportId: data.reportId,
            inputAction: CrudAction.UPDATE,
        });
    };

    render() {
        const { classes } = this.props;
        const pageName = "Consent Mapping";
        if (RolePermissionService.CONSENT_MAPPING_LIST.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack(); });
        } else {
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (
                        <PageLoadingComponent
                            classes={classes}
                            label={`Loading ${pageName}`}
                        />
                    );
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <div id="MainUsersGrid">
                            <div className="IconBg marginTop10px tableTitleHead">
                                <CompliancePageHeaderComponent back history={this.props.history}
                                    classes={classes}
                                    label={pageName}

                                    reportComplianceSelect
                                    selectedReportId={this.state.selectedReportId}
                                    reportSelectData={this.state.reportsListWithAll}
                                    onReportSelectClick={(event) => {
                                        this.onReportSelectClick(event);
                                    }}

                                    yearComplianceSelect
                                    selectedYearId={this.state.selectedYearId}
                                    yearList={this.state.yearList}
                                    onYearSelectClick={(event) => {
                                        this.onYearSelectClick(event);
                                    }}

                                    download onDownloadClick={this.onDownloadClick}
                                />
                            </div>
                            <div {...LayoutService.getAgGridStyles()}>
                                <AgGridReact
                                    columnDefs={ComplianceConsentMappingService.getColumnDefs(this)}
                                    rowData={this.state.data}
                                    pagination={true}
                                    paginationPageSize={50}
                                    frameworkComponents={this.state.frameworkComponents}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    onGridReady={(event) => {
                                        this.onGridReady(event);
                                    }}
                                ></AgGridReact>
                            </div>
                            <ComplianceConsentDialogComponent inputAction={this.state.inputAction} modalAgNode={this.state.modalAgNode}
                                open={this.state.showDialog || false} onClose={() => this.setState({ showDialog: false })} refreshList={this.fetchData} />
                        </div>
                    );

                case ResultStatus.ERROR:
                    return (
                        <PageErrorComponent
                            label={`Error Loading ${pageName}`}
                            classes={classes}
                            onRetry={() => {
                                this.fetchData(true);
                            }}
                        />
                    );
                default:
                    return null;
            }
        }
    }
}

class CompliancePageHeaderComponent extends Component {
    render() {
        const { small, classes, history, label, back, menu, search, reportComplianceSelect, yearComplianceSelect, countrySelect, importTemplate, uploadFile, save, add, addCircle, view, reload, ok, cancel, noDivider, noDividerSpacing, download } = this.props;
        const showButtons = back || menu || search || countrySelect || importTemplate || uploadFile || save || add || addCircle || view || reload || ok || cancel || download;

        return (
            <React.Fragment>
                <Grid className={classes.flexNoShrink} container direction="column">
                    <Grid container direction="row" alignItems="center">
                        {back ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Back" placement="bottom" arrow>
                                <IconButton onClick={() => { history.goBack(); }}>{MatIconService.BACK}</IconButton>
                            </Tooltip>
                        ) : null}

                        <Typography variant={small ? "h6" : "h5"} className={classes.headerTitle}>
                            {label}
                        </Typography>

                        {reportComplianceSelect ? (
                            <>
                                <Typography>Report Name:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="reportComplianceSelectLabel"
                                    id="reportComplianceSelect"
                                    value={this.props.selectedReportId}
                                    onChange={this.props.onReportSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.reportSelectData.map((el) => (
                                        <MenuItem key={el.id} value={el.id}>
                                            {el.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {yearComplianceSelect ? (
                            <>
                                <Typography>Year:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="yearComplianceSelectLabel"
                                    id="yearComplianceSelect"
                                    value={this.props.selectedYearId}
                                    onChange={this.props.onYearSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.yearList.map((el) => (
                                        <MenuItem key={el.id} value={el.id}>
                                            {el.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {download && RolePermissionService.CONSENT_MAPPING_EXPORT.canView ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                                <IconButton onClick={this.props.onDownloadClick} color="secondary">
                                    {MatIconService.DOWNLOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {(add || addCircle) ? ( // && RolePermissionService.CONSENT_MAPPING_DETAIL.canCreate
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add" placement="bottom" arrow>
                                <IconButton onClick={this.props.onAddClick} color="secondary">
                                    {addCircle ? MatIconService.ADD_CIRCLE_OUTLINE : MatIconService.ADD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {reload ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Reload" placement="bottom" arrow>
                                <IconButton onClick={this.props.onReloadClick} color="secondary">
                                    {MatIconService.RELOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Grid>
                    {noDivider || noDividerSpacing ? (
                        <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    ) : (
                        <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}

export default LayoutService.getHocComponenet(ComplianceConsentMappingComponent);
