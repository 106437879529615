import { AgGridColumnExt } from "../../services/ag-grid/agGridColumnExt";
import ApiService from "../../services/apiService";
import { API_ENDPOINT, ENTITY_TYPE } from "../../types/enums";
import { of } from "rxjs";
import { AgGridBulkEditUtil } from "../../components/ag-grid/bluk-edit/agGridBulkEditUtil";

export default class AgreementsService {

  static getAgreementsAsOBS = (_tenantId, _userId, _modalAgNode, entityType) => {
    let targetUrl = "";
    if (entityType === ENTITY_TYPE.PROFILE) {
      targetUrl = `/Agreement/GetAgreementsByProfileId/${_tenantId}/${_userId}/${_modalAgNode.prid}?isDelete=false`;
    } else if (entityType === ENTITY_TYPE.TRANSACTION) {
      targetUrl = `/Agreement/GetAgreementsByTransactionId/${_tenantId}/${_userId}/${_modalAgNode.trid}`;
    }
    return ApiService.getOBS(API_ENDPOINT.CORE, targetUrl);
  };

  static getDeleteOBS = (tenantId, idsToDelete, entityType) => {
    if (entityType === ENTITY_TYPE.PROFILE) {
      return ApiService.deleteOBS(API_ENDPOINT.CORE,
        `/Profiles/DeleteProfileAgreements?tenantId=${tenantId}&porzioGSTAgreementIds=${idsToDelete}`);
    } else if (entityType === ENTITY_TYPE.TRANSACTION) {
      return ApiService.deleteOBS(API_ENDPOINT.CORE,
        `/Transactions/DeleteTransactionAgreements?tenantId=${tenantId}&selectedTransactionId=${idsToDelete}`);
    } else return of([]);
  }

  static saveProfileAgreements = (tenantId, profileId, porzioGSTAgreementIds) => {
    let url = `/Profiles/SaveProfileAgreements?tenantId=${tenantId}&profileId=${profileId}&porzioGSTAgreementIds=${porzioGSTAgreementIds}`;
    return ApiService.postOBS(API_ENDPOINT.CORE, url);
  }

  static saveTransactionAgreements = (tenantId, transactionId, companyAgreementId, porzioGSTAgreementId) => {
    let url = `/Transactions/SaveTransactionAgreements?tenantId=${tenantId}&transactionId=${transactionId}&companyAgreementId=${companyAgreementId}&porzioGSTAgreementId=${porzioGSTAgreementId}`;
    return ApiService.postOBS(API_ENDPOINT.CORE, url);
  }

  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
   */
  static getColumnDefs(_componentThisRef) {
    let colDefs = [
      AgGridColumnExt.GET(false, true, "companyAgreementId", "Company Agreement Id").fixedWidth(144).headerClass("center-text").centerAlignText().SET(),
      AgGridColumnExt.GET(false, true, "porzioGSTAgreementID", "Porzio GST Agreement ID").minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("agreementDetailCellRendererComponent").SET(),
      AgGridColumnExt.GET(false, true, "agreementName", "Agreement Name").flexWeight(1).minWidth(136).tooltip().SET(),
      AgGridColumnExt.GET(false, true, "agreementType", "Agreement Type").flexWeight(1).minWidth(136).tooltip().SET(),
      AgGridColumnExt.GET(false, true, "startdate", "Start Date").flexWeight(1).minWidth(136).SET(),
      AgGridColumnExt.GET(false, true, "enddate", "End Date").flexWeight(1).minWidth(136).SET(),
      AgGridColumnExt.GET(false, true, "executeddate", "Executed Date").flexWeight(1).minWidth(136).SET(),
      AgGridColumnExt.GET(false, true, _componentThisRef.props.entityType === ENTITY_TYPE.TRANSACTION ? "disclosureConsent" : "agreementConsent", "Agreement Consent").flexWeight(1).minWidth(136).headerClass("center-text").tooltip().SET(),
      AgGridColumnExt.GET(false, true, "agreementdocuments", "Agreement Document(s)").flexWeight(1).minWidth(136).tooltip().SET()
    ];

    if (_componentThisRef.props.entityType === ENTITY_TYPE.TRANSACTION) {
      colDefs.unshift(AgGridColumnExt.GET(true, "text", AgGridBulkEditUtil.selectColumnName, "Select").fixedWidth(80).headerClass("center-text")
        .cellRenderer("isCheckedCellRenderer", { onChange: _componentThisRef.toggleSubmitButton }).SET());
    }
    return colDefs;
  }
}