import { ReplaySubject } from 'rxjs';
import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../../shared/types/enums";
import { AgGridService } from "../../../../shared/services/ag-grid/agGridService";

export default class EditConsentProcessingService {

  static getComplianceReportMappingProcessingAsOBS = (_tenantId, _reportName, _verifiedYear) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/GetProcessing/${_tenantId}?reportName=${_reportName}&verifiedYear=${_verifiedYear}`
    );
  };

  static fetchReportMappings = (_tenantId,) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/ComplianceMapping/FetchReportMappings/${_tenantId}`,
      JSON.stringify({
        pageNumber: 1,
        pageSize: 5000,
        searchText: "",
        entityId: "",
        fieldId: ""
      }),
    );
  };

  static getYearsByReportId = (_tenantId, _reportId) => {
    let $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}`
    if (_reportId !== 0) {
      $url = `/ComplianceMapping/FetchReportTemplateYears/${_tenantId}?reportId=${_reportId}`
    }
    return ApiService.getOBS(API_ENDPOINT.TENANT, $url);
  };

  //#region Address Grid
  static validateAffiliateRowEditOBS = (_cellRef) => {
    var oSubject = new ReplaySubject(); // 1st

    // static/async validation logic should trigger the below
    oSubject.next({ isSuccess: true }); // true <- success

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };
  static getColumnDefs = (_componentThisRef, _isReadOnly, _agGridUtils) => {
    return [
      // edit button column
      _agGridUtils.getInlineEditActionColumn(true, this.validateAffiliateRowEditOBS),
      // other columns

      AgGridService.getDropdownColumn(
        "Report Identifier",
        "reportIdentifierId",
        _componentThisRef.state.reportIdentifierList,
        true
      ),
      AgGridService.getTextColumn(
        true,
        "Report Identifier Value",
        "reportIdentifierValue"
      ),
    ];
  };
}
