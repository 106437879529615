import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";
import { GST_API } from "../../../shared/types/enums";

export default class ImportTemplateService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, modalAgNode, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(modalAgNode, clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(modalAgNode, clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();

    return ApiService.fetchHTTP_API(GST_API.TenantConfig, `/ImportTemplate/GetTemplates/${clientAuthContext.user.tenantId}`, {
        method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  static getColumnDefs() {
    return [
      {
        headerName: "Template Icon",
        field: "templateTypeId",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 75  ,
        cellRenderer: "importTemplateImageRenderer",
      },
      {
        headerName: "Template Name",
        field: "templateName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        // width: 455,
        cellRenderer: "importTemplateCellRendererComponent",
      },
    ];
  }
}
