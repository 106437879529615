import React, { Component } from "react";
import { ResultStatus } from "../../shared/types/enums";
import { Dialog } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../shared/store/authProvider";
import { SubscriptionArray } from "../../shared/services/dataService";
import ChildMessageRendererComponent from "./childMessageRendererComponent";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import SourceDialogComponent from "./source-dialog/sourceDialogComponent";
import SourceService from "./sourceService";
import { CrudAction } from "../../shared/types/enums";
import LayoutService from "../../shared/services/layoutService";
import IsActiveIconRendererComponent from "../../shared/components/ag-grid/isActiveIconRendererComponent";
import { AgGridColumnExt } from "../../shared/services/ag-grid/agGridColumnExt";
import ToastService from "../../shared/services/toastService";
import { MatIconService } from "../../shared/services/theme/matIconService";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../shared/components/page/pageDynamicHeaderComponent";

class SourceComponent extends Component {
  static contextType = AuthContext;

  oSubscriptions = new SubscriptionArray();
  STORE = SourceService;
  state = {
    data: [],
    fetchResult: ResultStatus.NOT_LOADED,
    frameworkComponents: {
      isActiveIconRendererComponent: IsActiveIconRendererComponent,
      childMessageRenderer: ChildMessageRendererComponent,
    },
    modalShow: false,
    showDetailsDialog: false,
    modalAgNode: null,
    inputAction: CrudAction.NONE,
  };

  constructor(props) {
    super(props);
    this.handleHide = this.handleHide.bind(this);
    this.fetchData.bind(this);
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (o) => {
          // onResultStatus.SUCCESS
          if (!Array.isArray(o)) { o = new Array(o); }
          this.setState({ data: o, fetchResult: ResultStatus.SUCCESS });
        },
        (o) => {
          ToastService.showError("Error occured");
          this.setState({ data: [], fetchResult: ResultStatus.ERROR, });
        }
      )
    );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  };

  handleDetailsDialogClose = (_result) => {
    if (_result) {
      // relod the componenet if needed
    }
    this.setState({ showDetailsDialog: false });
  };

  methodFromParent = (cell, node) => {
    this.setState({
      modalShow: true,
      showDetailsDialog: true,
      inputAction: CrudAction.UPDATE,
      systemId: node.systemId,
      modalAgNode: node,
    });
  };

  handleHide() {
    this.setState({ modalShow: false });
  }

  handleCreateSourceModal = () => {
    this.setState({
      inputAction: CrudAction.CREATE,
      showDetailsDialog: true,
      systemId: 1
    });
    this.setState({ modalAgNode: { systemId: 1 } });
  };

  render() {
    const { classes, history } = this.props;

    if (RolePermissionService.SOURCE_FILE_LIST.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() });
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent classes={classes} label="Loading Source Map" />);
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              {/* Header Componenet */}
              <PageDynamicHeaderComponent classes={classes} label="Source Map" rightPadding={16}
                leftActions={[
                  { title: "back", icon: MatIconService.BACK, onClick: () => { this.props.history.goBack() } },
                  { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                ]}
                rightActions={[
                  { icon: MatIconService.DOWNLOAD, title: "Export to Excel", iconColor: "primary", onClick: () => { }, isReadOnly: RolePermissionService.SOURCE_FILE_LIST_EXPORT.cannotView },
                  { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.handleCreateSourceModal, isReadOnly: RolePermissionService.SOURCE_FILE_DETAIL.cannotCreate },
                ]}
              />

              <div id="MainSourceFilesGrid">
                <div {...LayoutService.getAgGridStyles(160)}>
                  <AgGridReact
                    columnDefs={this.STORE.getColumnDefs(this)}
                    rowData={this.state.data}
                    pagination={true}
                    paginationPageSize={50}
                    frameworkComponents={this.state.frameworkComponents}
                    gridOptions={{
                      context: { componentParent: this },
                      ...AgGridColumnExt.getGridOptions()
                    }}
                    onGridReady={event => { }}
                  ></AgGridReact>
                </div>
                {/* Details-Modal */}
                <Dialog open={this.state.showDetailsDialog || false}  {...LayoutService.getContainedDialogProps()}>
                  <SourceDialogComponent
                    onClose={this.handleDetailsDialogClose}
                    systemId={this.state.systemId}
                    modalAgNode={this.state.modalAgNode}
                    inputAction={this.state.inputAction}
                    refreshSourceGrid={this.fetchData}
                  />
                </Dialog>
              </div>
            </React.Fragment>
          );
        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent label="Error Loading Source Map" classes={classes} onRetry={() => { this.fetchData(true); }} />
          );
      }
    }
  }
}

export default LayoutService.getHocComponenet(SourceComponent);
