import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import { ROLE_CELL_TYPE } from "../../types/enums";
import { MatIconService } from "../../services/theme/matIconService";
import { DataService } from "../../services/dataService";
import { AgGridUtil } from "../../services/ag-grid/agGridUtil";

export default class AgGridDynamicCheckboxCellRendererComponent extends React.Component {

  // indeterminate is always triggered by the childrens
  // if most of the child are true, then this is true & indeterminate
  // else if most of the child are false, then this is false & indeterminate
  constructor(props) {
    super(props);
    this.state = { reRender: false, isIndeterminate: false };
  }

  render() {
    const { classes, data, colDef } = this.props;
    let _disabled = AgGridUtil.isEditingDisabled(this.props);
    if (this.props.getIsReadonly && !_disabled) {
      _disabled = this.props.getIsReadonly(this, data);
    }

    const colName = colDef.headerName;
    const cellActionData = DataService.getFirstOrDefault((data.actionsList || []).filter((x) => x.actionName === colName));

    var roleType = ROLE_CELL_TYPE.NONE;
    if (!cellActionData) { return (<></>); }
    else if (!cellActionData.isApplicable) { roleType = ROLE_CELL_TYPE.NONE; }
    else {
      // determine parent or child here
      roleType = cellActionData.isDriveChilds ? ROLE_CELL_TYPE.PARENT : ROLE_CELL_TYPE.CHILD;
    }

    switch (roleType) {
      case ROLE_CELL_TYPE.PARENT:
        return (<Checkbox
          disabled={_disabled}
          color="primary"
          size="small"
          style={{ padding: 0 }}
          // indeterminate={this.state.isIndeterminate} // determined based on the child
          indeterminate={false}
          checked={cellActionData.hasAccess}
          onClick={() => { this.onStateChangeRequest(false, cellActionData); }}
        />);
      case ROLE_CELL_TYPE.CHILD:
        return (<Checkbox
          disabled={_disabled}
          size="small"
          style={{ padding: 0 }}
          indeterminate={false}                     // always false
          checked={cellActionData.hasAccess}
          onClick={() => { this.onStateChangeRequest(true, cellActionData); }}
        />);
      case ROLE_CELL_TYPE.NONE:
      default:
        return (MatIconService.DASH);
    }
  }

  onStateChangeRequest = (_isChild, _cellActionData) => {
    const { colDef, node, api, agGridReact } = this.props;

    // 1/2) update the current cells data
    _cellActionData.hasAccess = !_cellActionData.hasAccess;

    var rowsToRefersh = [];
    if (_isChild) {
      // find the prev node whose permissionId is this node's parentId
      const parentNodeInfo = this.getParentNodeInfo();
      if (parentNodeInfo) {
        // get all childrens of the parent
        const childrenNodesInfo = this.getChildrenInfo(parentNodeInfo);
        // if all children are checked: set the hasAccess of Parent to true else false
        parentNodeInfo.nodeAction.hasAccess = childrenNodesInfo.isAllChecked;
        // prepare the rows to referesh
        rowsToRefersh = [parentNodeInfo.node, ...childrenNodesInfo.nodes];
      } else {
        // if no parent then refresh only this
        rowsToRefersh = [node];
      }
    } else {
      // set this node as parent node info
      const parentNodeInfo = { rowIndex: node.rowIndex, node: node, nodeAction: _cellActionData };
      // get all childrens of this node (parent)
      const childrenNodesInfo = this.getChildrenInfo(parentNodeInfo);
      // if this is checked       : check all children
      childrenNodesInfo.nodeActions.forEach(nodeAction => { nodeAction.hasAccess = _cellActionData.hasAccess; });
      // prepare the rows to referesh
      rowsToRefersh = [parentNodeInfo.node, ...childrenNodesInfo.nodes];
    }

    // 2/2) refresh the specific cells only
    api.refreshCells({
      rowNodes: rowsToRefersh, // specify rows, or all rows by default
      columns: [_cellActionData.actionName], // specify columns, or all columns by default
      force: true,
    });
  };

  getParentNodeInfo = () => {
    const { data, colDef, node, api, agGridReact } = this.props;

    let oRET = null;
    for (var _rowIndex = node.rowIndex - 1; _rowIndex >= 0; _rowIndex--) {
      const _prevNode = api.getRowNode(_rowIndex);
      if (_prevNode.data.permissionId === data.parentId) {
        oRET = {
          rowIndex: _rowIndex,
          node: _prevNode,
          nodeAction: DataService.getFirstOrDefault((_prevNode.data.actionsList || []).filter((x) => x.actionName === colDef.headerName))
        };
        _rowIndex = -1; // cancel forLoop
      }
    }
    return oRET;
  }

  getChildrenInfo = (_parentNodeInfo) => {
    const { colDef, node, api, agGridReact } = this.props;

    // true will be reset before returning, if the nodes are empty
    let oRET = { isAllChecked: true, nodes: [], nodeActions: [] };

    if (_parentNodeInfo) {
      var rowData = agGridReact.props.rowData;
      for (var _rowIndex = _parentNodeInfo.rowIndex; _rowIndex < rowData.length; _rowIndex++) {
        const _nextNode = api.getRowNode(_rowIndex);
        if (_nextNode.data.parentId === _parentNodeInfo.node.data.permissionId) {
          const _nodeAction = DataService.getFirstOrDefault((_nextNode.data.actionsList || []).filter((x) => x.actionName === colDef.headerName));

          oRET.nodes.push(_nextNode); // push the node
          oRET.nodeActions.push(_nodeAction); // push the node

          // also check the below in this same loop
          if (!_nodeAction.hasAccess) { oRET.isAllChecked = false; }

        } else if (oRET.nodes.length > 0) { // no need to go further
          _rowIndex = rowData.length; // break the forloop
        }
      }
    }

    // reset the if nodes are empty
    if (oRET.nodes.length === 0) { oRET.isAllChecked = false; }
    // return
    return oRET;
  }

  //---
}
