import ApiService from "../../shared/services/apiService";
import Cache from "../../shared/store/cache";
import { GST_API } from "../../shared/types/enums";
import { DataService } from "../../shared/services/dataService";


export default class productMasterService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    // replace this._jsonData with reacl ApiCall
    return ApiService.fetchHTTP_API(GST_API.TenantConfig, `/Products/${clientAuthContext.user.tenantId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Product Name",
        field: "name",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 125,
        cellRenderer: "childMessageRendererComponent",
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Product Category",
        field: "productCategory",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 125,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Brand",
        field: "brand",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        filterOptions: "contains",
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "NDC",
        field: "ndc",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        
      },
      {
        headerName: "Primary Device Indicator",
        field: "udi",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Therapeutic Area",
        field: "therapeuticArea",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        comparator: DataService.caseInsensitiveComparator,
      },
      {
        headerName: "Active?",
        field: "isActive",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        cellRenderer: "productIsActiveRendererComponent",
        width: 65,
      },
      {
        headerName: "Us Federal Covered?",
        field: "isUSFederal",
        sortable: true,
        filter: true,
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        cellRenderer: "productIsActiveRendererComponent",
        width: 65,
      },
    ];
  }
}
