import ApiService from "../../../shared/services/apiService";
import Cache from "../../../shared/store/cache";
import { GST_API, API_ENDPOINT } from "../../../shared/types/enums";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

export default class BusinessRulesListService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, _sourceid, _typeOfRules, _authContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(_sourceid, _typeOfRules, _authContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(_sourceid, _typeOfRules, _authContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    let url =
      _typeOfRules === 1
        ? `/SourceSystems/GetRuleDetailsBySource/${_sourceid}?tenantid=${_authContext.user.tenantId}`
        : `/SourceSystems/GetUnassignedRuleDetailsBySource/${_sourceid}?tenantid=${_authContext.user.tenantId}`;
    // return ApiService.fetchHTTP_API(GST_API.Core, url);
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      url
    );
  }

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      AgGridColumnExt.GET(false, "text", "isChecked", "Select").fixedWidth(88)
        .cellRenderer("checkBoxCellRendererComponent", {
          checkDisabled: (_cellProps) => { return RolePermissionService.SOURCE_FILE_DETAIL.cannotEdit || _cellProps.data["categoryName"] === "File Validation"; }
        }).SET(),
      AgGridColumnExt.GET(false, "text", "ruleName", "Rule App Name").flexWeight(1).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "ruleDescription", "Rule Description").flexWeight(2).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "categoryName", "Rule Category").flexWeight(1).tooltip().SET(),
      AgGridColumnExt.GET(false, "text", "errorOrWarn", "Warning/ Error").cellRenderer("agGridChipCellRendererComponent").fixedWidth(112).SET(),
      AgGridColumnExt.GET(false, "text", "isFileImport", "File Import").cellRenderer("isActiveIconRendererComponent").fixedWidth(112).SET(),
      AgGridColumnExt.GET(false, "text", "isFileRevalidate", "File Revalidate").cellRenderer("isActiveIconRendererComponent").fixedWidth(112).SET(),
      AgGridColumnExt.GET(false, "text", "isManualEntry", "Manual Entry").cellRenderer("isActiveIconRendererComponent").fixedWidth(96).SET(),
    ];
  }
}
