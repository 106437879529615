import React from "react";
import { combineLatest, of } from "rxjs";
import { DialogTitle, DialogContent, Box, Grid } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { API_ENDPOINT, CrudAction, ResultStatus } from "../../../../shared/types/enums";
import LookupService from "../../../../shared/services/lookupService";
import LayoutService from "../../../../shared/services/layoutService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import RelatedTransactionsService from "./relatedTransactionsService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import ChildMessageRendererComponent from "../../childMessageRendererComponent";
import TransactionDetailDialogComponent from "../transactionDetailDialogComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import ActionDialog from "../../../../shared/components/dialog/actionDialog";
import RelatedTransactionsSearchDialogComponent from "./realted-transactions-search-dialog/relatedTransactionsSearchDialogComponent";
import ApiService from "../../../../shared/services/apiService";
import ToastService from "../../../../shared/services/toastService";

class RelatedTransactionsComponent extends React.Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      porzioSearch: "",
      selectedTransactions: [],
      currentLinkedTransactions: [],
      showUnlinkConfirmDialog: false,
      agGridUtils: new AgGridUtil("lastname", {
        childMessageRendererComponent: ChildMessageRendererComponent,
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
      }),
    };
  }
  componentWillUnmount() {
    this.apiSubscriptions.cancelAll();
  }
  componentDidMount() {
    if (this.props.inputAction === CrudAction.UPDATE) {
      this.setState({ fetchResult: ResultStatus.LOADING });
      this.fetchData();
    } else {
      this.setState({ fetchResult: ResultStatus.LOADED });
    }
  }

  /** API Fetch */
  fetchData = () => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        // ProfileExpandedViewService.getObs(true, this.props.modalAgNode, this.context),
        RelatedTransactionsService.getRelatedTransactionsAsOBS(this.context.user.tenantId, this.context.user.userId, this.props.modalAgNode,),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
      ]).subscribe(
        // success
        ([_data, _countryList]) => {
          this.setState(
            {
              data: _data.map((d) => ({
                ...d,
                CountryName: d.Country ? [{ id: 0, value: "All" }, ..._countryList].find(c => c.id === d.Country).value : "",
              })),
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ currentLinkedTransactions: this.state.data, fetchResult: ResultStatus.LOADED });
              this.state.agGridUtils.sizeColumnsToFit();
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({
      modalAgNode: {
        ...node,
        sourceid: node.SOURCEID,
        transactionid: node.TransactionId
      }
    });

    if (this.state.agGridUtils.isNotEditing()) {
      this.setState({ showTransactionDetailDialog: true });
    }
  }

  getNewlyLinkedTransactions = () => {
    let gstIds = this.state.data.map(y => y.PorzioTransactionId);

    let trids = this.state.currentLinkedTransactions.filter(x =>
      !gstIds.includes(x.PorzioTransactionId)).map(x => x.trid);
    return trids;
  }

  linkTransactionsToRelatedTransactions = (_link = true, _trids = []) => {

    let dataToPost = {
      tenantId: this.context.user.tenantId,
      uid: this.context.user.uid,
      userid: this.context.user.userId,
      companyEventId: this.props.modalAgNode.companyEventId === undefined ?this.props.modalAgNode.companyeventid:this.props.modalAgNode.companyEventId,
      transactionIds: _trids,
      link: _link
    }

    this.apiSubscriptions.cancelAll();
    this.props.setIsSaving(true);
    this.apiSubscriptions.add(
      combineLatest([
        ApiService.postOBS(
          API_ENDPOINT.CORE,
          `/Transactions/LinkOrUnlinkToRelatedTransactions`,
          JSON.stringify(dataToPost)
        )
      ]).subscribe(
        ([_data]) => {
          this.fetchData();
          this.props.setIsSaving(false);
        },
        (error) => {
          ToastService.showError("Error Occured");
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
    this.props.changeToReadOnlyMode();
  }

  render() {

    const { classes } = this.props;
    const isReadOnly = this.props.isReadOnly;
    this.state.agGridUtils.setEditingMode(isReadOnly);
    this.props.tabConfig.ref = this; // 1/4) required by parent component
    const componentType = "Related Transactions";

    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return <PageLoadingComponent small classes={classes} label={`Loading ${componentType}`} />;
      case ResultStatus.SAVING:
        return <PageLoadingComponent small classes={classes} label={`Saving ${componentType}`} />;
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            <ActionDialog showDialog={this.state.showConfirmDialog} title="Discard changes?" description="There are unsaved changes" actions={[
              {
                color: "secondary", text: "Submit", icon: null,
                callback: () => {
                  this.setState({ showConfirmDialog: false, showSearchLinkedTransactionsDialog: true });
                }
              },
              {
                color: "inherit", text: "Cancel", icon: null,
                callback: () => { this.setState({ showConfirmDialog: false }, () => { }) }
              }
            ]} />
            <ActionDialog showDialog={this.state.showUnlinkConfirmDialog} title="Unlink Confirmation" description="Are you sure you want to unlink the selected Transaction(S)?"
              content={this.state.transactionssToBeUnlinked} actions={[
                {
                  color: "secondary", text: "Submit", icon: null,
                  callback: () => {
                    this.setState({ showUnlinkConfirmDialog: false }, () => {
                      var gridData = this.state.agGridUtils.getUpdatedRowData();
                      var ids = gridData.filter(x => x.isSelected).map(x => x.TransactionId);
                      this.linkTransactionsToRelatedTransactions(false, ids);
                      this.props.changeToReadOnlyMode();
                    });
                  }
                },
                {
                  color: "inherit", text: "Cancel", icon: null,
                  callback: () => { this.setState({ showUnlinkConfirmDialog: false }, () => { }) }
                }
              ]} />
            <ActionDialog showDialog={this.state.showUnlinkWarningDialog} title="Unlink Confirmation" description={this.state.warningDesc}
              actions={[
                {
                  color: "inherit", text: "Cancel", icon: null,
                  callback: () => { this.setState({ showUnlinkWarningDialog: false }, () => { }) }
                }
              ]} />
            <DialogTitle disableTypography id="dialogTitle" />
            <DialogContent>
              <Box style={{ padding: "8px", paddingTop: "0px", minHeight: "70vh", maxHeight: "70vh" }}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                  {LayoutService.getIconButton((this.props.isReadOnly), MatIconService.SEARCH, "search", () => {
                    this.setState({ showSearchLinkedTransactionsDialog: true })
                  }, "inherit")}
                  {LayoutService.getIconButton((this.props.isReadOnly), MatIconService.UNLINK, "Unlink",
                    () => {
                      console.log(this.state.agGridUtils.getUpdatedRowData());
                      var ids = DataService.arrayToString(this.state.agGridUtils.getUpdatedRowData().filter(x => x.isSelected).map(x => x.PorzioTransactionId));
                      if (ids.length === 0) {
                        this.setState({ showUnlinkWarningDialog: true, warningDesc: "Please select at least one transaction to unlink" });
                      }
                      else {
                        if (this.getNewlyLinkedTransactions().length !== 0) {
                          this.setState({ showUnlinkWarningDialog: true, warningDesc: "Please save changes before unlink" });
                        } else {
                          this.setState({ transactionssToBeUnlinked: ids, showUnlinkConfirmDialog: true });
                        }
                      }
                    }, "primary")}
                </Grid>
                <div {...LayoutService.getAgGridStyles(320)}>
                  <AgGridReact
                    pagination={true}
                    paginationPageSize={50}
                    rowData={this.props.isReadOnly ? this.state.data : this.state.currentLinkedTransactions}
                    columnDefs={RelatedTransactionsService.getColumnDefs(this)}
                    frameworkComponents={this.state.agGridUtils.frameworkComponents}
                    suppressClickEdit={true}

                    gridOptions={{
                      context: { componentParent: this },
                      ...AgGridColumnExt.getGridOptions(40),
                      ...this.state.agGridUtils.bindInlineEditEvents(),
                    }}
                    onGridReady={(params) => {
                      this.state.agGridUtils.setGridParams(params, false);
                      this.state.agGridUtils.setEditingMode(isReadOnly);
                    }}></AgGridReact>
                </div>
              </Box>
              <RelatedTransactionsSearchDialogComponent loadedTransaction={this.props.modalAgNode.porziogsttransactionid}
                open={this.state.showSearchLinkedTransactionsDialog || false}
                currentLinkedTransactions={this.state.currentLinkedTransactions}
                // sourceId={this.props.modalAgNode.sourceid}
                transactionDate={this.props.modalAgNode.transactionDate === undefined ?this.props.modalAgNode.transactiondate : this.props.modalAgNode.transactionDate}
                onClose={(_selectedTransaction) => {
                  if (_selectedTransaction) {
                    this.setState((_prevState) => ({
                      showSearchLinkedTransactionsDialog: false,
                      currentLinkedTransactions: [..._prevState.currentLinkedTransactions, _selectedTransaction]
                    }));
                  }
                  else {
                    this.setState({ showSearchLinkedTransactionsDialog: false })
                  }
                }}
              />
            </DialogContent>
            <TransactionDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
              open={this.state.showTransactionDetailDialog || false} onClose={() => { this.setState({ showTransactionDetailDialog: false }); /*this.fetchData(true);*/ }} />
          </React.Fragment>
        );

      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent title={`Error Loading ${componentType}`} description={`Error Loading ${componentType}`} classes={classes}
            onClose={() => { this.props.onClose(false); }} onRetry={() => { this.fetchData(); }} />
        );
    }
  }

  /** 2/4 Required in Parent */
  isDirtyCallback = () => {
    //return false;
    // do any additional checkings if needed
    // if (this.fPropsDynamic) {
    //   return this.fPropsDynamic.dirty;
    // } else {
    //   return false;
    // }
    if (this.getNewlyLinkedTransactions().length === 0)
      return false;
    return true;
  }
  /** 3/4 Required in Parent */
  resetCallback = () => {
    // if (this.fPropsDynamic) {
    //   this.fPropsDynamic.resetForm();
    // }
    // do any additional resetting if needed
    this.setState((_prevState) => ({ currentLinkedTransactions: _prevState.data }));
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    //return of(null);
    // if (DataService.isNullOrUndefined(this.fPropsDynamic)) { return of(null); }
    // else {

    // }
    return this.linkTransactionsToRelatedTransactions(true, this.getNewlyLinkedTransactions());
  }


}

/** HOC */
export default LayoutService.getHocComponenet(RelatedTransactionsComponent);
