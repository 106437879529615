import React, { Component } from "react";

export default class AgreementDetailCellRendererComponent extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {
        this.props.context.componentParent.methodFromParent(
            `Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`,
            this.props.node.data
        );
    }

    render() {
        return (
            <span
                style={{ textDecoration: "underline" }}
                onClick={this.invokeParentMethod}
            >
                {this.props.value}
            </span>
        );
    }
}
