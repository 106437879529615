import { ReplaySubject } from "rxjs";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT, ENTITY_TYPE } from "../../../shared/types/enums";
import { AgGridColumnExt } from "../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridBulkEditUtil } from "../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import DynamicControlService from "../../../shared/services/dynamicControlService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import LookupService from "../../../shared/services/lookupService";
import ProfileTabService from "../profile-detail/tabs/profileTabService";

export default class ProfileExpandedViewService {

  static postProfileCenterExpandedViewAsOBS = (_tenantId, _userId, _modalAgNode, _healthIndicator, _searchvalue = "") => {

    let targetUrl = "/Profiles/";
    switch (_healthIndicator) {
      case "1": targetUrl += `GetProfilesErrorCount`; break;
      case "2": targetUrl += `GetProfilesWarningCount`; break;
      case "3": targetUrl += `GetProfilesUnmatchedCount`; break;
      default: targetUrl += `GetProfileCount`;
    }

    return ApiService.postOBS(API_ENDPOINT.CORE, targetUrl,
      JSON.stringify({
        sourceid: _modalAgNode.sourceId,
        fileid: String(_modalAgNode.fileId),
        healthindicator: _healthIndicator,
        tenantid: _tenantId,
        searchvalue: _searchvalue,
        pageNumber: 1,
        rowsperPage: 100000,
        isActive: 1,
        screenId: 43,
        userId: _userId,
        searchString: "",
        taskid: 0,
        source: "Profiles",
      }),
    );
  };


  static getDataCenterDisplayedFieldsAsOBS = (_tenantId, _sourceId, _entityId, _userId, _screenId) => {
    return ApiService.getOBS(
      API_ENDPOINT.CORE,
      `/DataCenter/DisplayedFields/${_tenantId}/${_sourceId}/${_entityId}/${_userId}/${_screenId}`
    );
  };

  static getProfileRuleSummaryAsOBS = (_tenantId, _fileId) => {
    return ApiService.getOBS(API_ENDPOINT.CORE, `/RuleSummary/FetchRuleSummaryByEntity/${_tenantId}/${ENTITY_TYPE.PROFILE}?id=0&fileId=${_fileId}&pageNumber=1&pageSize=5000`);
  }


  /**
   * Refer url for more details: https://www.ag-grid.com/javascript-grid-column-properties/
   * @param _componentThisRef pass {this} keyword from the calling componenet
   */
  static getColumnDefs(_componentThisRef, _isParentReadOnly) {

    let dynamicColumns = [];

    // edit button column
    if (RolePermissionService.PROFILE_EXPANDED_VIEW.canEdit) {
      dynamicColumns.push(AgGridBulkEditUtil.getSelectColumn(RolePermissionService.PROFILE_EXPANDED_VIEW.canEdit, _componentThisRef.toggleSubmitButton));
    }

    // other columns
    dynamicColumns.push(AgGridColumnExt.GET(false, "text", "healthindicator", "Health Indicator", false).fixedWidth(104).headerClass("center-text")
      .cellRenderer("healthIndicatorCellRenderer", {
        hasApprovalFlag: (_props) => {
          return _props.data.profilestatusid === 4; // 4 -> Pending Approval
        }
      }).SET());

    dynamicColumns.push(AgGridColumnExt.GET(false, "text", "ruleviolations", "Rule Violations").minWidth(122).valueSetter((params) => { params.data.ruleviolations = params.newValue; return true; }).SET());
    dynamicColumns.push(AgGridColumnExt.GET(false, "set", "sourcesystem", "Source System").minWidth(136).flexWeight(1).tooltip().SET());
    dynamicColumns.push(AgGridColumnExt.GET(false, "text", "filename", "File Name").minWidth(136).flexWeight(1).tooltip().SET());
    dynamicColumns.push(AgGridColumnExt.GET(false, "text", "profilestatus", "Profile Status").minWidth(122).flexWeight(1).tooltip().SET());

    // dynamic columns
    (_componentThisRef.state.displayedColumnConfigs || []).forEach(colFieldConfig => {

      // make address & contact fields not editable
      if (ProfileTabService.addressFieldNamesInLowerCase.includes(colFieldConfig.mappedFieldNameLower)
        || ProfileTabService.contactsFieldNamesInLowerCase.includes(colFieldConfig.mappedFieldNameLower)) {
        colFieldConfig.isReadOnly = true;
      }

      // common configurations
      var colProps = DynamicControlService.getAgGridColumn(_componentThisRef, _isParentReadOnly, colFieldConfig, _componentThisRef.state.profileRuleSummaries);

      // field specific configurations
      switch (colFieldConfig.mappedFieldNameLower) {
        case "porziogstprofileid": colProps.editable(false).minWidth(136).headerClass("center-text").centerAlignText().cellRenderer("profileDetailCellRendererComponent"); break;
        case "country": colProps.minWidth(136); break;
        // case "addresss1": colProps.setFieldName("addresS1"); break;
        // case "address1": colProps.setFieldName("addresS1"); break;
      }

      // finalize and push to the array
      dynamicColumns.push(colProps.SET());
    });

    // RETURN
    return dynamicColumns;
  }


  static postRowDataAsOBS = (_cellRef) => {
    var postSubject = new ReplaySubject(); // 1st

    // subscribe to the validationOBS
    this._validateRowEditOBS(_cellRef).subscribe(
      (_validationResult) => {
        if (_validationResult.isSuccess) {

          // make the api call to post, handle the below accordingly on success or failure
          postSubject.next({ isSuccess: true });

        } else {
          postSubject.next({ ..._validationResult });
        }
      },
      (_validationException) => {
        console.log("Validaiton Exception", _validationException);
        postSubject.next({ isSuccess: false, invalidCellRef: _cellRef, invalidMessage: "Validaiton Exception Occured" });
      },
    );

    // return the subject as observable
    return postSubject.asObservable(); // 3rd
  }


  static _validateRowEditOBS = (_cellRef) => {
    var oSubject = new ReplaySubject(); // 1st

    // static/async validation logic should trigger the below
    oSubject.next({ isSuccess: true }); // true <- success

    // return the subject as observable
    return oSubject.asObservable(); // 3rd
  };

  //---
}