import ApiService from "../../../../shared/services/apiService";
import { API_ENDPOINT, ReportCategory } from "../../../../shared/types/enums";

import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

export default class ComplianceTemplatesService {

  static getTemplates(_tenantId, _roleId) {

    /**
      * As per the discussion with the DB Team
        --#3 Report center for "Tenant Admin" Role
          EXEC USP_RPT_REPORTBYCATEGORYIDGET @CATEGORYID = 1, @PageNumber = 1, @RowsperPage = 1000, @IsViewable = 1, @RoleID = NULL, @IsSubcribe = 0
        --#3.1 Report center for "Specific role"
          EXEC USP_RPT_REPORTBYCATEGORYIDGET @CATEGORYID = 1, @PageNumber = 1, @RowsperPage = 1000, @IsViewable = 1, @RoleID = 2, @IsSubcribe = 0
     */

    // _roleId = RolePermissionService.IS_TENANT_ADMIN ? null : _roleId;
    const _isViewable = true;
    const _isSubscribe = false;

    return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/${_tenantId}/${ReportCategory.COMPLIANCE_TEMPLATES}/${_roleId}?pageNo=1&pageSize=1000&isViewable=${_isViewable}&isSubscribe=${_isSubscribe}`);
  }

  static getColumnDefs() {
    return [
      AgGridColumnExt.GET(false, true, "reportName", "").fixedWidth(70).headerClass("center-text").cellRenderer("reportLaunchAgGridRenderer", {
        isCellEnabledCallback: (_cellRef) => {
          return true; //TODO: uncomment laters
          // return _cellRef.props.data.izendaReportId != "";
        }
      }).pivot().SET(),
      AgGridColumnExt.GET(false, true, "reportName", "Template Name").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, true, "templateYear", "Year").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, true, "jurisdiction", "Jurisdiction").flexWeight(1).headerClass("center-text").pivot().SET(),
      AgGridColumnExt.GET(false, true, "reportDescription", "Description").flexWeight(1).minWidth(144).headerClass("center-text").pivot().SET(),
    ];
  }

  //---
}