import ApiService from "../../shared/services/apiService";
import { API_ENDPOINT } from "../../shared/types/enums";

export default class SelectedRulesService {

  static getBusinessRuleListAsOBS = (_tenantId,) => {
    return ApiService.postOBS(
      API_ENDPOINT.TENANT,
      `/BusinessRules/FetchBusinessRules?tenantId=${_tenantId}`,
      JSON.stringify({
        entityId: 0,
        fileStatus: false,
        isPorzio: true,
        pageNumber: 0,
        pageSize: 1000,
        searchText: "",
        tenantId: 0,
      }),
    );
  };

  static getRows() {
    return [];
  }

  static getColumnDefs() {
    return [
      {
        headerName: "Ruleapp Name",
        headerHeight: 35,
        field: "ruleName",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 250,
        height: 30,
        cellRenderer: "childMessageRendererComponent",
      },
      {
        headerName: "Description",
        headerHeight: 35,
        field: "description",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 450,
        height: 30,
        pivot: true,
        pivotMode: true,
        groupDefaultExpanded: 4,
        enableRowGroup: true,
      },
      {
        headerName: "Entity",
        headerHeight: 35,
        field: "entityName",
        sortable: true,
        filter: "agTextColumnFilter",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 250,
        height: 30,
      },
      {
        headerName: "Active",
        headerHeight: 35,
        field: "active",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 200,
        height: 30,
        cellRenderer: "isActiveIconRendererComponent",
      },
      {
        headerName: "Rule Last Modified",
        headerHeight: 35,
        field: "rulesLastModified",
        sortable: true,
        filter: "text",
        cellStyle: { "border-right": "1px solid lightgray" },
        resizable: true,
        width: 250,
        height: 30,
      },
    ];
  }

}