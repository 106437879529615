import React from "react";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,

} from "@material-ui/core";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import { MatClassService } from "../../../shared/services/theme/matClassService";
import PageHeaderComponent from "../../../shared/components/page/pageHeaderComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import { SubscriptionArray } from "../../../shared/services/dataService";
import ImportTemplateService from "./importTemplateService";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import ImportTemplateImageRenderer from "./importTemplateImageRenderer";
import ImportTemplateCellRendererComponent from "./importTemplateCellRendererComponent";
import ImportTemplateCreateComponent from "./import-create/importTemplateCreateComponent";
import LayoutService from "../../../shared/services/layoutService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import PageDynamicHeaderComponent from "../../../shared/components/page/pageDynamicHeaderComponent";

class ImportTemplateComponent extends React.Component {
  static contextType = AuthContext;

  oSubscriptions = new SubscriptionArray();
  STORE = ImportTemplateService;
  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      frameworkComponents: {
        importTemplateCellRendererComponent: ImportTemplateCellRendererComponent,
        importTemplateImageRenderer: ImportTemplateImageRenderer,
      },
      showCrudDialog: false,
    };
    this.fetchData.bind(this);
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
    this.checkPermissionsForFileDownload();
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setDomLayout("autoHeight");
  };

  importTemplateIconMethodFromParent() {
    alert("Under Construction");
    // this.setState({ showProfileExpandedDialog: true });
  }

  checkPermissionsForFileDownload = () => {
    this.setState({ canNotDownload: RolePermissionService.DOWNLOAD_TEMPLATE.cannotView });
  }
  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.props.modalAgNode, this.context).subscribe(
        (o) => {
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          this.STORE.CACHE.SET(o, 0);
          this.setState({
            data: o,
            fetchResult: ResultStatus.SUCCESS,
          });
        },
        (o) => {
          console.log("Error:", o);
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      )
    );
  };

  handleCreate = (cell) => {
    this.setState({ showCrudDialog: true });
    this.setState({ inputAction: CrudAction.CREATE });
  };

  handleUpdateFromAgCellRenderer = (cell, node) => {
    this.setState({ showCrudDialog: true });
    this.setState({ inputAction: CrudAction.UPDATE });
    this.setState({ modalAgNode: node });
  };

  handleDownloadAg = async (
    cell,
    agNodeData,
    filename,
    linkRefAg,
    windowUrl
  ) => {
    if (this.state.canNotDownload) {
      this.setState({ openAccessDenied: true });
      return;
    }
    else {
      const result = await fetch(
        `${process.env.REACT_APP_TENANT_HTTP}/ImportTemplate/Download/${this.context.user.tenantId}/${agNodeData.filePath}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.context.authToken}`,
          },
        }
      );
      const blob = await result.blob();
      return blob;
    }
  };

  render() {
    const { classes } = this.props;

    if (RolePermissionService.IMPORT_TEMPLATE.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.handleImportTemplateDialogClose() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (
            <PageLoadingComponent
              small
              classes={classes}
              label="Loading Import Template"
            />
          );
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>

              <DialogTitle disableTypography id="dialogTitle">
                <AppBar position="static">
                  <Toolbar>
                    <Typography variant="h6" className={classes.root}>Import Template</Typography>
                    <IconButton color="secondary" onClick={() => { this.props.handleImportTemplateDialogClose(); }}>{MatIconService.CANCEL}</IconButton>
                  </Toolbar>
                </AppBar>
              </DialogTitle>
              <DialogContent style={{ paddingLeft: 16, paddingRight: 16 }}>
                {<PageDynamicHeaderComponent classes={classes} rightPadding={16}
                  leftActions={[]}
                  rightActions={[
                    { icon: MatIconService.ADD_CIRCLE_OUTLINE, title: "Add New", iconColor: "secondary", onClick: this.handleCreate, isReadOnly: RolePermissionService.IMPORT_TEMPLATE_DETAIL.cannotCreate },
                    { icon: MatIconService.RELOAD, title: "Reload", iconColor: "secondary", onClick: () => { this.fetchData(true); }, },
                  ]}
                />}
                <div {...LayoutService.getAgGridStyles(300)}>
                  <AgGridReact
                    columnDefs={this.STORE.getColumnDefs(this)}
                    rowData={this.state.data}
                    pagination={true}
                    paginationPageSize={20}
                    frameworkComponents={this.state.frameworkComponents}
                    gridOptions={{
                      context: { componentParent: this },
                    }}
                    onGridReady={(event) => {
                      event.api.sizeColumnsToFit();
                    }}
                  ></AgGridReact>
                </div>
              </DialogContent>
              <Dialog open={this.state.showCrudDialog || false} scroll={true ? "paper" : "body"} maxWidth="sm" fullwidth>
                <ImportTemplateCreateComponent
                  onClose={() => this.setState({ showCrudDialog: false })} modalAgNode={this.state.modalAgNode} inputAction={this.state.inputAction}
                  refreshImportTemplateList={this.fetchData}
                />
              </Dialog>
              <Dialog open={this.state.openAccessDenied || false} scroll={true ? "paper" : "body"} maxWidth="sm" fullwidth>
                {RolePermissionService.getAccessDeniedComponent(classes, () => {
                  this.setState({ openAccessDenied: false });
                })}
              </Dialog>
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent
              small
              label="Error Loading Import Template"
              classes={classes}
              onRetry={() => {
                this.fetchData(true);
              }}
            />
          );
      }
    }
  }
}

/** HOC */
export default withStyles(MatClassService)(ImportTemplateComponent);