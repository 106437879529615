import React, { Component } from "react";
import { Formik } from "formik";
import { combineLatest } from "rxjs";
import * as Yup from "yup";
import {
  withStyles, AppBar, DialogContent, DialogTitle, FormControl, Grid,
  InputLabel, MenuItem, Select, TextField, Toolbar, Typography,
} from "@material-ui/core";

import { MatClassService } from "../../../shared/services/theme/matClassService";
import { AuthContext } from "../../../shared/store/authProvider";
import { ResultStatus, TenantSelectList } from "../../../shared/types/enums";
import { SubscriptionArray } from "../../../shared/services/dataService";
import LayoutService from "../../../shared/services/layoutService";
import TenantProfileSetupService from "./tenantProfileSetupService";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import LookupService from "../../../shared/services/lookupService";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";
import { MatIconService } from "../../../shared/services/theme/matIconService";

class TenantProfileSetupDialogComponent extends Component {

  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = TenantProfileSetupService;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      countryData: [],
      isLoadingCountryData: false,
      countryId: "",
      languageData: [],
      isLoadingLanguageData: false,
      languageId: "",
      timeZoneData: [],
      isLoadingTimeZoneData: false,
      timeZoneId: "",
      currencyData: [],
      isLoadingCurrencyData: false,
      currencyId: "",
      isReadOnly: true,
      isEditing: true,
    };
    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData(this);
    this.oSubscriptions.add(
      combineLatest([
        LookupService.getFormattedCountriesAsOBS(this.context, null),
        LookupService.getLanguagesAsOBS(this.context),
        LookupService.getFormattedCurrenciesAsOBS(this.context, null),
        LookupService.getTimezonesAsOBS(this.context)
      ]).subscribe(([_countryData, _languageData, _currencyData, _timeZoneData]) => {
        this.setState(
          {
            countryData: _countryData,
            languageData: _languageData,
            currencyData: _currencyData,
            timeZoneData: _timeZoneData,
          });
      }
      ),
      (error) => {
        console.log("Error:", error);
        this.setState({
          fetchResult: ResultStatus.ERROR,
        });
      }
    );
  }

  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    this.oSubscriptions.add(
      this.STORE.getObs(_ignoreCache, this.context).subscribe(
        (o) => {
          if (!Array.isArray(o)) {
            o = new Array(o);
          }
          this.STORE.CACHE.SET(o, 0);
          this.setState({
            data: o,
            fetchResult: ResultStatus.SUCCESS,
            countryId: o[0].country,
            languageId: o[0].language,
            timeZoneId: o[0].timeZone,
            currencyId: o[0].currency,
          });
        },
        (error) => {
          console.log("Error:", error);
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      ));
  }

  getInitialValues() {
    return {
      companyName: this.state.data[0].companyName,
      description: this.state.data[0].description,
      address1: this.state.data[0].address1,
      address2: this.state.data[0].address2,
      address3: this.state.data[0].address3,
      address4: this.state.data[0].address4,
      city: this.state.data[0].city,
      province: this.state.data[0].province,
      country: this.state.data[0].country,
      postalCode: this.state.data[0].postalCode,
      language: this.state.data[0].language,
      timeZone: this.state.data[0].timeZone,
      currency: this.state.data[0].currency,
    };
  }
  getValidationSchema() {
    return Yup.object({
      companyName: Yup.string().min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
      description: Yup.string().min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
      address1: Yup.string().min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
      address2: Yup.string(),
      address3: Yup.string(),
      address4: Yup.string(),
      city: Yup.string().min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
      province: Yup.string().min(2, "Must be at least 2 characters long").max(50, "Must be 50 characters or less").required("Required"),
      postalCode: Yup.string().min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
    });
  }

  handleSubmit = async (_formikProps) => {
    if (_formikProps.isSubmitting) {
      return;
    } else {
      if (_formikProps.isValid) {
        this.setState({
          fetchResult: ResultStatus.LOADING,
        });
        try {
          console.log("Product Update PUT fetch request started");
          const result = await fetch(
            `${process.env.REACT_APP_TENANT_HTTP}/CompanyProfile/${this.context.user.tenantId}`,
            {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                id: this.context.user.tenantId,
                companyName: _formikProps.values.companyName,
                description: _formikProps.values.description,
                address1: _formikProps.values.address1,
                address2: _formikProps.values.address2,
                address3: _formikProps.values.address3,
                address4: _formikProps.values.address4,
                city: _formikProps.values.city,
                province: _formikProps.values.province,
                country: String(this.state.countryId),
                postalCode: _formikProps.values.postalCode,
                language: this.state.languageId,
                timeZone: this.state.timeZoneId,
                currency: this.state.currencyId,
                createdBy: this.state.data.createdBy,
                createdDate: this.state.data.createdDate,
                updatedBy: this.context.user.userId,
                updatedDate: new Date()
              }),
            }
          );
          if (result.status === 204) {
            this.setState({
              fetchResult: ResultStatus.SUCCESS,
            });
            this.fetchData(this);
            this.props.onClose(false);
          } else {
            this.setState({
              fetchResult: ResultStatus.ERROR,
            });
          }
        } catch (error) {
          this.setState({
            fetchResult: ResultStatus.ERROR,
          });
        }
      } else {
        alert("Invalid values");
      }
    }
  };

  render() {
    const { classes } = this.props;
    if (RolePermissionService.TENANT_DETAIL.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent small classes={classes} label="Loading Tenant Profile" />);
        case ResultStatus.SUCCESS:
          return (
            <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} >
              {(fProps) => (
                <form>
                  {/* Header */}
                  <DialogTitle disableTypography id="dialogTitle" >
                    <AppBar position="static">
                      <Toolbar variant="dense">
                        <Typography variant="h6" className={classes.root}>Tenant Profile Setup</Typography>
                        {RolePermissionService.TENANT_DETAIL.cannotEdit ? LayoutService.getIconButton(false, MatIconService.CLOSE, "close",
                          () => { this.props.onClose(false) }, "secondary")
                          : LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                        }
                      </Toolbar>
                    </AppBar>
                  </DialogTitle>
                  <DialogContent style={{ paddingLeft: "25px", height: "50vh", overflowX: "hidden" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "companyName",
                            "Tenant Name"
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "description",
                            "Description",
                            false
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "address1",
                            "Address 1"
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "address2",
                            "Address 2",
                            false
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "address3",
                            "Address 3",
                            false
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "address4",
                            "Address 4",
                            false
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "city",
                            "City"
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "province",
                            "Province/State",
                            false
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...LayoutService.getInputProps(this.state.isReadOnly,
                            classes,
                            fProps,
                            "postalCode",
                            "Postal Code"
                          )}
                          style={{ minWidth: "80%" }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl style={{ minWidth: "80%" }} disabled={this.state.isReadOnly}>
                          <InputLabel
                            {...LayoutService.getInputProps(this.state.isReadOnly,
                              classes,
                              fProps,
                              "countryLabel",
                              "Country"
                            )}
                            fullWidth
                          >
                            Country
                          </InputLabel>
                          {!this.state.isLoadingCountryData ? (
                            <Select
                              labelId="country-select-label"
                              id="country-select"
                              value={this.state.countryId}
                              onChange={(event) =>
                                this.setState({ countryId: event.target.value })
                              }
                              fullWidth
                            >
                              {this.state.countryData.map((el, index) => (
                                <MenuItem key={"mitm" + index} value={el.id}>{el.value}</MenuItem>
                              ))}
                            </Select>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl style={{ minWidth: "80%" }} disabled={this.state.isReadOnly}>
                          <InputLabel
                            {...LayoutService.getInputProps(this.state.isReadOnly,
                              classes,
                              fProps,
                              "languageLabel",
                              "Language"
                            )}
                            fullWidth
                          >
                            Language
                          </InputLabel>
                          {!this.state.isLoadingLanguageData ? (
                            <Select
                              labelId="language-select-label"
                              id="language-select"
                              value={this.state.languageId}
                              onChange={(event) =>
                                this.setState({ languageId: event.target.value })
                              }
                              fullWidth
                            >
                              <MenuItem key={"key" + 0} value={1}>English</MenuItem>
                              {this.state.languageData.map((el, index) => (
                                el.id !== 1 ? <MenuItem key={"key" + index} value={el.id}>{el.value}</MenuItem> : null
                              ))}
                            </Select>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl style={{ minWidth: "80%" }} disabled={this.state.isReadOnly}>
                          <InputLabel
                            {...LayoutService.getInputProps(this.state.isReadOnly,
                              classes,
                              fProps,
                              "timeZoneLabel",
                              "TimeZone"
                            )}
                            fullWidth
                          >
                            Time Zone
                          </InputLabel>
                          {!this.state.isLoadingTimeZoneData ? (
                            <Select
                              labelId="timeZone-select-label"
                              id="timeZone-select"
                              value={this.state.timeZoneId}
                              onChange={(event) =>
                                this.setState({ timeZoneId: event.target.value })
                              }
                              fullWidth
                            >
                              {this.state.timeZoneData.map((el) => (
                                <MenuItem value={el.id}>{el.value}</MenuItem>
                              ))}
                            </Select>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl style={{ minWidth: "80%" }} disabled={this.state.isReadOnly}>
                          <InputLabel
                            {...LayoutService.getInputProps(this.state.isReadOnly,
                              classes,
                              fProps,
                              "currencyLabel",
                              "Currency"
                            )}
                            fullWidth
                          >
                            Currency
                          </InputLabel>
                          {!this.state.isLoadingCurrencyData ? (
                            <Select
                              labelId="currency-select-label"
                              id="currency-select"
                              value={this.state.currencyId}
                              onChange={(event) =>
                                this.setState({ currencyId: event.target.value })
                              }
                              fullWidth
                            >
                              {this.state.currencyData.map((el) => (
                                <MenuItem value={el.id}>{el.value}</MenuItem>
                              ))}
                            </Select>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* </Box> */}
                  </DialogContent>
                </form>
              )}
            </Formik>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent small label="Error Loading Tenant Profile" classes={classes} onRetry={() => { this.fetchData(this, true); }} />);
      }
    }
  }
}
export default withStyles(MatClassService)(TenantProfileSetupDialogComponent);