import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class ComplianceReportMappingService {

  static getProductDetailsAsOBS = (_tenantId, _productId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Products/${_tenantId}/${_productId}`
    );
  };

  static getBrandDataAsOBS = (_tenantId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetNonEntityLOVsByID/${_tenantId}/6`
    );
  };

  static getCategoryDataAsOBS = (_tenantId, _entityId) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Util/GetNonEntityLOVsByID/${_tenantId}/7`
    );
  };

  static getDuplicateNdcCode = (_tenantId, _ndcToCheck) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Products/NDCExists/${_tenantId}/${_ndcToCheck}`
    );
  };

  static getNdcCodeExistsInRegistry = (_tenantId, _ndcToCheck) => {
    return ApiService.getOBS(
      API_ENDPOINT.TENANT,
      `/Products/NDCMasterByCode/${_tenantId}/${_ndcToCheck}`
    );
  };

}
