import moment from "moment";
// import Moment from "react-moment";

/**
 * https://material-ui-pickers.dev/getting-started/installation
 * This is seperately availble inside the following Package with flixible customizations
 * npm i @material-ui/pickers
 * npm i @date-io/moment
 * import { DatePicker } from "@material-ui/pickers";
 *
 */

export default class MomentService {
  static lightModeStart = 6;  // 6AM
  static lightModeEnd = 22;   // 10PM

  /** (1AM -to- 11:59AM) 24hr time to split the morning */
  static morningStart = 1;
  static morningEnd = 11;
  /** (12PM -to- 4:59PM) 24hr time to split the afternoon */
  static afterNoonStart = 12;
  static afterNoonEnd = 16;
  /** (5PM -to- 6:59PM) 24hr time to split the evening */
  static eveningStart = 17;
  static eveningEnd = 18;
  /** (7PM -to- 11:59PM) 24hr time to split the night */
  static nightStart = 19;
  static nightEnd = 23;

  static checkIsDawn = () => {
    const _currentHour = parseFloat(moment().format("HH"));
    if (_currentHour >= this.lightModeStart && _currentHour < this.lightModeEnd) {
      // 6AM to 9PM
      return true;
    } else {
      return false;
    }
  };
  static checkIsDusk = () => {
    const _currentHour = parseFloat(moment().format("HH"));
    if (_currentHour >= this.lightModeEnd && _currentHour < this.lightModeStart) {
      // 9PM to 6AM
      return true;
    } else {
      return false;
    }
  };

  static getGreetingTime = () => {
    const currentHour = parseFloat(moment().format("HH"));

    if (currentHour >= this.afterNoonStart && currentHour <= this.afterNoonEnd) {
      // Between 12 PM and 5PM
      return "Good afternoon";
    } else if (currentHour >= this.eveningStart && currentHour <= this.eveningEnd) {
      // Between 5 PM and 7PM
      return "Good evening";
    } else if (currentHour >= this.nightStart && currentHour <= this.nightEnd) {
      // Between 7PM to 1AM
      return "Good night";
    } else {
      // Between night and dawn
      return "Good morning";
    }
  };

  static getTimeStamp = () => {
    return moment().valueOf();
  };

  static getUtcTime = () => {
    return moment().utc().format('YYYY-MM-DD  hh:mm a');
  }

  static getRandomTimeStamp = (min = 1, max = 999) => {
    const random = min + Math.random() * (max - min);
    return this.getTimeStamp() + "" + random;
  };
}
