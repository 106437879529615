import ApiService from "../../../../shared/services/apiService";
import Cache from "../../../../shared/store/cache";
import { GST_API } from "../../../../shared/types/enums";

export default class DataTransactionService {
  // cache
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, modalAgNode, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(modalAgNode, clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  /**
   * Replace the static data inside of(...) with real ApiCall
   * @returns observable
   */
  static fetchFromApiObs(modalAgNode, clientAuthContext) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();

    return ApiService.fetchHTTP_API(GST_API.Core, `/Profiles/GetProfileTransactions?tenantId=${clientAuthContext.user.tenantId}&userId=${clientAuthContext.user.userId}&profileId=${modalAgNode.prid}&pageNumber=1&pageSize=500`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static getColumnDefs(parentThis) {
    let arrOut = [];
    for (const [key, value] of Object.entries(parentThis.columnMap)) {
      arrOut.push({
        headerName: value,
        field: key,
        resizable: true,
        width: 125,
      });
    }
    return (arrOut);
  }

}
