import React from "react";
import { Link } from "react-router-dom";
import { ResultStatus } from "../../shared/types/enums";
import { withStyles } from "@material-ui/core";
import { Dialog, Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Paper, Divider, } from "@material-ui/core";

import MatSpecService from "../../shared/services/theme/matSpecService";
import { MatClassService } from "../../shared/services/theme/matClassService";
import { SubscriptionArray } from "../../shared/services/dataService";
import { AuthContext } from "../../shared/store/authProvider";
import PageLoadingComponent from "../../shared/components/page/pageLoadingComponent";
import PageHeaderComponent from "../../shared/components/page/pageHeaderComponent";
import PageErrorComponent from "../../shared/components/page/pageErrorComponent";
import LayoutService from "../../shared/services/layoutService";
import TenantConfigurationService from "./tenantConfigurationService";
import TenantProfileSetupDialogComponent from "../tenant-profile-setup/tenant-profile/tenantProfileSetupDialogComponent";
import EmailComponent from "../security/email/emailComponent";


class TenantConfigurationComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();
  STORE = TenantConfigurationService;
  state = { fetchResult: ResultStatus.NOT_LOADED, data: [] };

  constructor(props) {
    super(props);
    this.state = {
      openCompanyProfileDialog: false,
      openEmailSetupDialog: false,
    }
    this.fetchData.bind(this);
  }
  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }
  componentDidMount() {
    this.fetchData();
  }

  /** API Fetch */
  fetchData = (_considerCache = false) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // If cach exists, then
    if (_considerCache && this.STORE.CACHE.EXISTS()) {
      // load from cache
      this.setState({
        data: this.STORE.CACHE.GET(),
        fetchResult: ResultStatus.SUCCESS,
      });
    } else {
      // save the subscription object
      this.oSubscriptions.add(
        this.STORE.fetchFromApiObs().subscribe(
          // onResultStatus.SUCCESS
          (o) => {
            this.STORE.CACHE.SET(o);
            this.setState({ data: o, fetchResult: ResultStatus.SUCCESS });
          },
          // onResultStatus.ERROR
          (o) => { this.setState({ fetchResult: ResultStatus.ERROR }); }
        )
      );
    }
  };

  /**Render */
  render() {
    const { classes } = this.props;

    switch (this.state.fetchResult) {
      case ResultStatus.SUCCESS:
        return (
          <React.Fragment>
            <PageHeaderComponent classes={classes} label={'Tenant Configuration'} />
            {this.STORE.getGridNames(this.state.data).map(
              (oGridName, oGridIndex) => (
                <React.Fragment key={LayoutService.getKey(oGridIndex)}>
                  <Grid container spacing={3}>
                    {this.STORE.getGridGroupNames(this.state.data, oGridName).map((oGroupName, oGroupIndex) => (
                      <Grid item xs={12} sm={9} md={6} lg={4} xl={3} key={LayoutService.getKey(oGridIndex + "|" + oGroupIndex)}>
                        {this.context.user.tenantId !== 1 && oGroupName === "Administration" ? <></> : <Typography variant="h6" className={classes.groupTitle}>{oGroupName}</Typography>}
                        {MatSpecService.showGroupNameDivider ? (<Divider />) : null}
                        <Paper elevation={0} className={classes.listGroup}>
                          <List dense={MatSpecService.useDenseLayout}>
                            {this.STORE.getGridGroupControls(this.state.data, oGridName, oGroupName).map((o, index, array) => (
                              <React.Fragment key={LayoutService.getKey(o.id)}>
                                {o.id === 1 ?
                                  (
                                    <ListItem disabled={o.isReadOnly} button onClick={() => this.setState({ openCompanyProfileDialog: true })}>
                                      <ListItemIcon className={classes.themeColor}>{o.iconJsx}</ListItemIcon>
                                      <ListItemText primary={o.label} />
                                    </ListItem>
                                  )
                                  : o.id === 18 ?
                                    (
                                      <ListItem disabled={o.isReadOnly} button onClick={() => this.setState({ openEmailSetupDialog: true })}>
                                        <ListItemIcon className={classes.themeColor}>{o.iconJsx}</ListItemIcon>
                                        <ListItemText primary={o.label} />
                                      </ListItem>
                                    )
                                    : o.id === 20 ?
                                      (
                                        this.context.user.tenantId === 1 ?
                                          <ListItem disabled={o.isReadOnly} button component={Link} to={o.target}>
                                            <ListItemIcon className={classes.themeColor}>{o.iconJsx}</ListItemIcon>
                                            <ListItemText primary={o.label} />
                                          </ListItem> : <></>
                                      )
                                      :
                                      (
                                        <ListItem disabled={o.isReadOnly} button component={Link} to={o.target}>
                                          <ListItemIcon className={classes.themeColor}>{o.iconJsx}</ListItemIcon>
                                          <ListItemText primary={o.label} />
                                        </ListItem>
                                      )
                                }
                                {MatSpecService.showListItemDivider && index < array.length - 1 ? (<Divider variant="inset" component="li" />) : null}
                              </React.Fragment>
                            ))}
                          </List>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                  {MatSpecService.showGridDivider ? (<Divider className={classes.gridSpacing} xs={12} sm={12} md={12} lg={12} xl={12} />) : null}
                </React.Fragment>
              )
            )}
            <Dialog open={this.state.openCompanyProfileDialog || false} scroll={true ? "paper" : "body"} maxWidth="md" >
              <TenantProfileSetupDialogComponent onClose={() => this.setState({ openCompanyProfileDialog: false })} />
            </Dialog>
            <Dialog open={this.state.openEmailSetupDialog || false} scroll={true ? "paper" : "body"} maxWidth="md">
              <EmailComponent onClose={() => this.setState({ openEmailSetupDialog: false })} />
            </Dialog>
          </React.Fragment>
        );
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent classes={classes} label="Loading Tenant Configurations" />);
      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent label="Error Loading" classes={classes} onRetry={() => { this.fetchData(false); }} />);
    }
  }
}
/** HOC */
export default withStyles(MatClassService)((TenantConfigurationComponent));