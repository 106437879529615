import React from "react";
import { Dialog, AppBar, IconButton, Toolbar, FormGroup, Switch, withStyles, DialogTitle, DialogContent, Box, Divider, Typography, Grid, Tooltip, LinearProgress } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { combineLatest } from "rxjs";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AuthContext } from "../../../../shared/store/authProvider";
import { ResultStatus, CrudAction } from "../../../../shared/types/enums";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import UnmatchedProfileService from "./unmatchedProfileService";
import LayoutService from "../../../../shared/services/layoutService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import { AgGridBulkEditUtil } from "../../../../shared/components/ag-grid/bluk-edit/agGridBulkEditUtil";
import LookupService from "../../../../shared/services/lookupService";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import ProfileDetailCellRendererComponent from "../../profile-detail/profileDetailCellRendererComponent";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import ProfileDetailDialogComponent from "../../profile-detail/profileDetailDialogComponent";
import { AgGridErroredCheckboxCellRenderer } from "../../../../shared/components/ag-grid/error-cell-renderers/agGridErroredCheckboxCellRenderer";
import ToastService from "../../../../shared/services/toastService";
import AgGridSelectMenuComponent from "../../../../shared/components/agGridSelectMenuComponent";

class UnmatchedProfileComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: true,
      profileData: [],
      fetchResult: ResultStatus.NOT_LOADED,
      agGridBulkEditUtil: new AgGridBulkEditUtil(),
      agGridUtils: new AgGridUtil("porziogstprofileid", {
        profileDetailCellRendererComponent: ProfileDetailCellRendererComponent,
        isCheckedCellRenderer: AgGridCheckboxCellRendererComponent,
        erroredCheckboxCellRenderer: AgGridErroredCheckboxCellRenderer,
      }),
      selectedProfiles: [],
      unmatchedChecked: true,
      isSaving: false
    };

    this.fetchData.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });
    this.fetchData();
  }

  // called on row-cell click
  methodFromParent = (row_col, node) => {
    this.setState({ modalAgNode: node, showProfileDetailDialog: true, inputAction: CrudAction.UPDATE });
  };

  toggleSubmitButton = (_cellRef, _data) => {
    var hasSelected = false;
    let selectedProfiles = [];
    if (this.state.agGridBulkEditUtil.hasUpdates()) {
      hasSelected = true;
    } else {
      this.state.agGridUtils.gridApi.forEachNode((rowNode) => {
        hasSelected = hasSelected || rowNode.data[AgGridBulkEditUtil.selectColumnName] === true;
        if (rowNode.data && rowNode.data[AgGridBulkEditUtil.selectColumnName]) {
          selectedProfiles.push(rowNode.data);
        }
      });
    }
    // set the state
    this.setState({
      selectedProfiles: selectedProfiles,
      isReadOnly: !hasSelected,
      //isInit: false // only true for the first time 
    });
  }

  /** API Fetch */
  fetchData = (_ignoreCache = true) => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, profileData: [] });

    // save the subscription object
    this.oSubscriptions.add(
      combineLatest([
        LookupService.getFormattedCountriesAsOBS(this.context, null),
        //LookupService.getFieldLOVByIdsAsOBS(this.context.user.tenantId, 6),
        UnmatchedProfileService.getProfileByStatus(this.context.user.tenantId, this.state.unmatchedChecked)
      ]).subscribe(
        // success
        ([_countryList, _unmatchedReferenceData]) => {
          let convertedUnmatchedData = DataService.convertToLowerCasedKeyObject(_unmatchedReferenceData);
          let data = DataService.hasElements(convertedUnmatchedData) ? convertedUnmatchedData.map(r => ({
            ...r,
            countryname: _countryList.find(c => c.id === r.country)?.value,
            //profilecategoryname: _profileCategoryList.find(f => f.lovId === r.profilecategoryid) ?
            // _profileCategoryList.find(f => f.lovId === r.profilecategoryid).lovKey : null
          })) : [];
          this.setState({
            profileData: data
          },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  handleReviewedClick = () => {
    if (this.state.selectedProfiles.length > 5000) {
      ToastService.showInfo("Select up to 5000 records each occurrence");
      this.setState({ isReadOnly: true });
    }
    else {
      this.setState({ isSaving: true, isReadOnly: true }, () => {
        let selectedProfileIds = this.state.selectedProfiles.map(p => p.porziogstprofileid).join(",");
        UnmatchedProfileService.saveReviewedProfiles(this.context.user.tenantId, selectedProfileIds).subscribe(
          (_successResult) => {
            if (_successResult) {
              ToastService.showSuccess("Profile(s) marked as Reviewed successfully");
              this.setState({ isReadOnly: true, isSaving: false });
              this.fetchData(false);
            }

          },
          (_errorResult) => {
            ToastService.showError("Error occured while reviewing Profile(s)");
            this.setState({ isReadOnly: true, isSaving: false });
            console.log(_errorResult);
          }
        );
      });

    }
  }

  handleUnmatchedClick = () => {
    if (this.state.selectedProfiles.length > 5000) {
      ToastService.showInfo("Select up to 5000 records each occurrence");
      this.setState({ isReadOnly: true });
    }
    else {
      this.setState({ isSaving: true, isReadOnly: true }, () => {
        let selectedProfileIds = this.state.selectedProfiles.map(p => p.porziogstprofileid).join(",");
        UnmatchedProfileService.saveUnmatchedProfiles(this.context.user.tenantId, selectedProfileIds).subscribe(
          (_successResult) => {
            ToastService.showSuccess("Profile(s) marked as Unmatched successfully");
            this.setState({ isReadOnly: true, isSaving: false });
            this.fetchData(false);
          },
          (_errorResult) => {
            ToastService.showError("Error occured while saving Profile(s)");
            this.setState({ isReadOnly: true, isSaving: false });
            console.log(_errorResult);
          }
        );
      });

    }

  }


  handleSwitchChange = (event) => {
    this.setState({
      unmatchedChecked: event.target.checked
    }, () => {
      this.fetchData(true);
    });
  }

  onClose = () => {
    this.setState({
      unmatchedChecked: this.state.unmatchedChecked,
    }, () => {
      this.props.onClose();
    });
  }

  onDownloadClick = () => {
    let api = this.gridApi, params = this.getParams();
    api.exportDataAsExcel(params);
  }

  getParams() {
    return {
      // allColumns: true,
      columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== "Select"),
      fileName: this.getFileName()
    };
  }

  getFileName() {
    return this.state.unmatchedChecked ? `Unmatched_Profiles ${new Date().toDateString()}` : `Reviewed_Profiles ${new Date().toDateString()}`;
  }



  render() {
    const { classes } = this.props;
    return (
      <Dialog open={this.props.open || false} scroll={true ? "paper" : "body"} fullWidth={true} maxWidth={"lg"} onClose={() => { this.onClose() }} >
        <DialogTitle disableTypography id="dialogTitle">
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.root}>Unmatched to Reference Data</Typography>
              {this.state.unmatchedChecked ?
                <React.Fragment>
                  {LayoutService.getIconButton(this.state.isReadOnly, MatIconService.REVIEWED, "Reviewed", () => {
                    this.handleReviewedClick()
                  }, "inherit")}
                </React.Fragment>
                :
                <React.Fragment>
                  {LayoutService.getIconButton(this.state.isReadOnly, MatIconService.NOT_LISTED, "Unmatched", () => {
                    this.handleUnmatchedClick()
                  }, "inherit")}
                </React.Fragment>
              }
              {/* {LayoutService.getReadOnlyActions(this, true, () => { this.onClose() }, () => { this.onSave(); },
                () => { this.handleEdit(); })} */}
              <IconButton color="secondary" onClick={() => { this.onClose() }}>{MatIconService.CANCEL}</IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        {this.state.isSaving ? <LinearProgress color="secondary" /> : null}
        <DialogContent>
          <Box style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16, paddingBottom: 16 }}>
            <FormGroup disabled={this.state.isReadOnly} style={{ paddingBottom: 16 }}>
              <Typography component="div">
                <AgGridSelectMenuComponent anchorEl={this.state.anchorEl} openSelectMenu={this.state.openSelectMenu}
                  agGridUtils={this.state.agGridUtils} selectionColumnName={AgGridBulkEditUtil.selectColumnName}
                  onClose={() => { this.setState({ anchorEl: null, openSelectMenu: false }); }}
                  onSelectionChange={() => { this.toggleSubmitButton(); }}
                />
                <Grid container spacing={1}>
                  <Grid item>
                    <Tooltip key={"leftTooltip"} title={"selct"} placement="bottom" arrow>
                      <span>
                        <IconButton key={"leftIcon"} onClick={(e) => { this.setState({ anchorEl: e.currentTarget, openSelectMenu: true }); }}>{MatIconService.MENU}</IconButton>
                      </span>
                    </Tooltip>
                  </Grid>
                  <Grid component="label" item alignItems="center" style={{ margin: 'auto', display: 'flex', marginLeft: 0 }}>
                    <Grid item>Reviewed</Grid>
                    <Grid item>
                      <Switch disabled={this.state.isSaving} checked={this.state.unmatchedChecked} onChange={this.handleSwitchChange} name="unmatchedChecked" />
                    </Grid>
                    <Grid item>Unmatched</Grid>
                  </Grid>
                  <Grid item>
                    {LayoutService.getIconButton(false, MatIconService.DOWNLOAD, "Download to Excel", () => { this.onDownloadClick() }, "primary")}
                  </Grid>
                </Grid>
              </Typography>

            </FormGroup>
            {this.renderContent(classes)}
          </Box>
        </DialogContent>
        {/* Show Profile-Detail Dialog */}
        <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
          open={this.state.showProfileDetailDialog || false}
          onClose={() => {
            this.setState({ showProfileDetailDialog: false });
            this.fetchData(true);
          }}
          profileStatus={this.state.unmatchedChecked ? "UNMATCHED" : "REVIEWED"}
        />
      </Dialog >
    );
  }

  renderContent = (classes) => {
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING:
        return (<PageLoadingComponent small classes={classes} label="Loading Data" />);
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <div id="MainRoleGrid">
            <div {...LayoutService.getAgGridStyles(250)}>
              <AgGridReact
                rowData={this.state.profileData}
                columnDefs={UnmatchedProfileService.getColumnDefs(this)}
                suppressClickEdit={true}
                suppressRowClickSelection={true}
                rowSelection="multiple"
                pagination={true}
                paginationPageSize={100}
                frameworkComponents={this.state.agGridUtils.frameworkComponents}
                sideBar={true}
                gridOptions={{
                  context: { componentParent: this },
                  suppressContextMenu: true,
                  ...AgGridColumnExt.getGridOptions(56)
                }}
                onGridReady={event => {
                  this.gridApi = event.api;
                  this.gridColumnApi = event.columnApi;
                  this.state.agGridUtils.setGridParams(event, false);
                  this.state.agGridUtils.pinColumn(AgGridBulkEditUtil.selectColumnName); // pin the edit column to the left
                  event.api.closeToolPanel();
                }}
              />
            </div>
          </div>);
      case ResultStatus.ERROR:
      default:
        return (<PageErrorComponent small label="Error Loading Data" classes={classes}
          onRetry={() => this.fetchData(true)} />);
    }
  }
}

/** HOC */
export default withStyles(MatClassService)(UnmatchedProfileComponent);
