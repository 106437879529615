export default class MatSpecService {
  // view
  static useDenseLayout = false;
  // units
  static drawerWidth = 256;
  // durations
  static gridTooltipEnterDelay = 1000;
  static tooltipEnterDelay = 500;
  static tooltipExitDelay = 0;
  // toggles
  static showPageTitleDivider = true;
  static showGridDivider = false;
  static showGroupNameDivider = false;
  static showListItemDivider = false;
}
