import React from "react";
import { AppBar, Typography, Button, DialogTitle, Grid, Toolbar, IconButton } from "@material-ui/core";
import { MatIconService } from "../../../shared/services/theme/matIconService";
import LayoutService from "../../services/layoutService";
import { DataService } from "../../services/dataService";

class DialogErrorFragmentComponent extends React.Component {

  render() {
    const { classes, title, description, small, minHeight } = this.props;
    return (
      <React.Fragment>
        {DataService.isStringNullOrEmpty(title) ? null :
          <DialogTitle disableTypography id="dialogTitle">
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" className={classes.root}>{title}</Typography>
                <IconButton color="secondary" onClick={this.props.onClose}>{MatIconService.CANCEL}</IconButton>
              </Toolbar>
            </AppBar>
          </DialogTitle>
        }
        <Grid direction="column" container justify="center" alignItems="center" style={{ minHeight: minHeight ? minHeight :"50vh" }}>
          <Typography variant={small ? "h6" : "h4"} className={classes.pageErrorHeader}>{description}</Typography>
          {
            DataService.isNullOrUndefined(this.props.onRetry) ? null : <Button variant="contained" color="secondary" className={classes.buttonError} onClick={this.props.onRetry}>Retry</Button>
          }
        </Grid>
      </React.Fragment>
    );
  }
}

export default LayoutService.getHocComponenet(DialogErrorFragmentComponent);
