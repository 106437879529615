import React, { Component } from "react";
import icon_active from "../../../public/images/icon_active.png";
import icon_inactive from "../../../public/images/icon_inactive.png";

export default class IsActiveIconRendererComponent extends Component {

  render() {
    let active = null;
    if (this.props.value) {
      active = (
        <img src={icon_active}
             alt = "acive-icon"/>
      );
    } else {
      active = (
        <img src = {icon_inactive}
             alt = "inactive-icon" />
      );
    }
    return active;
  }
}
