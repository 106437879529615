import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Tooltip } from "@material-ui/core";
import { MatIconService } from "../../shared/services/theme/matIconService";
import MatSpecService from "../../shared/services/theme/matSpecService";
import LayoutService from "../../shared/services/layoutService";
import RolePermissionService from "../../shared/role-permissions/rolePermissionService";

class SideNavComponent extends Component {
  routeConfigs = [
    {
      icon: MatIconService.NAV_HOME,
      text: "Home",
      target: "/home",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess,
    },
    {
      icon: MatIconService.NAV_DASHBOARD,
      text: "Tenant Config",
      target: "/tenant-config",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess,
    },
    {
      icon: MatIconService.NAV_POWER_BI,
      text: "Analytics",
      target: "/power-bi",
      hasAccess: RolePermissionService.POWER_BI_REPORT.canView,
    },
    {
      icon: MatIconService.NAV_DATABASE,
      text: "Data Center",
      target: "/data-center",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess,
    },
    {
      icon: MatIconService.NAV_USER,
      text: "Profiles",
      target: "/profile-center",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess && RolePermissionService.PROFILE_CENTER.canView,
    },
    {
      icon: MatIconService.NAV_FILES,
      text: "Transactions",
      target: "/transaction-center",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess && RolePermissionService.TRANSACTION_CENTER.canView,
    },
    {
      icon: MatIconService.NAV_HAND,
      text: "Agreements",
      target: "/agreements",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess && RolePermissionService.AGREEMENTS.canView,
    },
    {
      icon: MatIconService.NAV_DEMO,
      text: "Reports",
      target: "/reports-center",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess,
    },
    {
      icon: MatIconService.NAV_DETAIL,
      text: "Documents",
      target: "/documents",
      hasAccess: !RolePermissionService.hasOnlyPowerBIAccess,
    },
  ];

  componentDidMount() {
    this.updateNavigation();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateNavigation();
    }
  }

  updateNavigation(dd) {
    if (this.props.location.pathname !== this.state.selectedItem) {
      var currentPath = this.routeConfigs.find(
        (o) => o.target === this.props.location.pathname
      );
      if (currentPath) {
        this.setState({
          selectedItem: currentPath.text,
        });
      }
    }
  }

  state = {
    isExpanded: false,
    selectedItem: "Home",
  };

  handleDrawerOpen = () => {
    this.setState({
      isExpanded: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      isExpanded: false,
    });
  };

  setSelectedItem = (_text) => {
    this.setState({
      selectedItem: _text,
    });
  };

  render() {
    const { classes, expandSideNav } = this.props;

    return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.sideNavExpand]: expandSideNav,
          [classes.sideNavCollapse]: !expandSideNav,
        })}
        classes={{
          paper: clsx({
            [classes.sideNavExpand]: expandSideNav,
            [classes.sideNavCollapse]: !expandSideNav,
          }),
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {this.routeConfigs.map((o, index) => (
              <React.Fragment key={LayoutService.getKey(o.text)}>
                <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title={o.text} placement="right" arrow >
                  <span>
                    <ListItem disabled={!o.hasAccess} component={Link} to={o.target} button
                      style={{ padding: 16 }}
                      selected={this.state.selectedItem === o.text}
                      onClick={() => this.setSelectedItem(o.text)}
                    >
                      <ListItemIcon className={this.state.selectedItem === o.text ? classes.selected : null}>{o.icon}</ListItemIcon>
                      {expandSideNav ? <ListItemText primary={o.text} /> : null}
                    </ListItem>
                  </span>
                </Tooltip>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </div>
      </Drawer>
    );
  }
}

const styles = (_theme) => ({
  selected: {
    color: "#FFF",
  },
  drawer: {
    width: MatSpecService.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerContainer: {
    overflow: "auto",
  },
  sideNavExpand: {
    width: MatSpecService.drawerWidth,
    transition: _theme.transitions.create("width", {
      easing: _theme.transitions.easing.sharp,
      duration: _theme.transitions.duration.enteringScreen,
    }),
  },
  sideNavCollapse: {
    transition: _theme.transitions.create("width", {
      easing: _theme.transitions.easing.sharp,
      duration: _theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: _theme.spacing(7) + 1,
    [_theme.breakpoints.up("sm")]: {
      width: _theme.spacing(9) + 1,
    },
  },
});

export default withStyles(styles)(withRouter(SideNavComponent));
