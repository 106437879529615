import ApiService from "../../shared/services/apiService";
import Cache from "../../shared/store/cache";
import { GST_API } from "../../shared/types/enums";

export default class PowerBIDemoService {
  static CACHE = new Cache(this.fetchFromApiObs);

  static getObs(_ignoreCache, clientAuthContext) {
    if (_ignoreCache || this.CACHE.NOT_EXISTS()) {
      return this.fetchFromApiObs(clientAuthContext);
    } else {
      return this.CACHE.getOBS();
    }
  }

  static fetchFromApiTokenObs(tenantId, workspaceId, reportId) {
    // clear the cache before returning the actual subscription
    this.CACHE.CLEAR();
    return ApiService.fetchHTTP_API(GST_API.Identity, `/User/GePowerBIAccessToken?tenantID=${tenantId}&workspaceID=${workspaceId}&reportId=${reportId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
