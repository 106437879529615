import React from "react";
import { combineLatest, ReplaySubject } from "rxjs";
import { userModel } from "../models/userModel";
import RolePermissionService from "../role-permissions/rolePermissionService";
import { API_ENDPOINT } from "../types/enums";
import ApiService from "./apiService";
import { DataService } from "./dataService";
import LookupService from "./lookupService";
import ToastService from "./toastService";
import UserActivityService from "./userActivityService";

/**
 * Author : Pradeep_Rajendran@epam.com
 */
export default class SessionService {

  static storageKey = "gstAuth";

  static isOktaAuthenticationInProgress = false;

  /*
  * ---------------------------------
  * userById : kulkarni@porziols.com
  * ---------------------------------
    {
         "id": 2129,
         "firstName": "Medha",
         "lastName": "Kulkarni",
         "userName": "kulkarni@porziols.com",
         "currencyId": 83,
         "defaultLanguageId": 1,
         "isPorzioUser": true,
         "userTypeId": 4,
         "userType": "Support User",
         "tenantId": 1376,
         "roleName": "Tenant Admin"
     }
   * ---------------------------------
   * tenantUserInfoById : ?userId=2129&tenantId=1376
   * ---------------------------------
    {
         "id": 2129,
         "loginName": "kulkarni@porziols.com",
         "firstName": "Medha",
         "lastName": "Kulkarni",
         "isActive": true,
         "currencyId": 83,
         "tenantId": 1376,
         "roleName": "Tenant Admin",
         "roleId": 1
     }
   *---------------------------------
   * tokenById
   * ---------------------------------
    eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Imt1bGthcm5pQHBvcnppb2xzLmNvbSIsInJvbGUiOiJUZW5hbnQgQWRtaW4iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoiMTM3NiIsIlRlbmFudElkIjoiMTM3NiIsIkZpcnN0TmFtZSI6Ik1lZGhhIiwiTGFzdE5hbWUiOiJLdWxrYXJuaSIsIlVzZXJJZCI6IjIxMjkiLCJJc1Bvcnppb1VzZXIiOiJUcnVlIiwiVXNlclR5cGVJZCI6IjQiLCJDdXJyZW5jeUlkIjoiODMiLCJEZWZhdWx0TGFuZ3VhZ2VJZCI6IjEiLCJuYmYiOjE2MTk4ODExMTgsImV4cCI6MTYyMDQ4NTkxOCwiaWF0IjoxNjE5ODgxMTE4LCJhdWQiOiJ0ZW5hbnQifQ.JLU9pCgXdvDhDrdHLeh_3-Ha8fmR5Uyg6b_Gn4dRnz4
   *---------------------------------
  */
  static setLoginInfo = (_context, _userByIdObj, _selTenantObj, _tenantUserInfoByIdObj, _sessionObj, _tenantList) => {

    // create the object
    const authUserObj = userModel.getByOBJs(_userByIdObj, _selTenantObj, _tenantUserInfoByIdObj, _sessionObj, _tenantList);
    // store the object in context
    _context.setViaLogin(authUserObj, _sessionObj.sessionToken);
    _context.user = authUserObj; // till the above async completes
    // save object in local storage
    localStorage.setItem(SessionService.storageKey, JSON.stringify({ authUserObj }));

    // cache
    return SessionService.configureOBS(_context);
  }

  /**
   * refetches & configures the roles and permissions
   * refetches the common lookups into cache
   * @param {*} _context 
   * @returns 
   */
  static configureOBS = (_context) => {
    var oSubject = new ReplaySubject();

    // clear the lookup Cache, so the cache will populate when required
    LookupService.clearAll();
    // ensures the common lov's are fetched and available in the lookup's private variables
    RolePermissionService.fetchPermissionsOBS(_context.user).subscribe(
      (rolePermissionsResult) => {
        if (DataService.hasElements(rolePermissionsResult) || _context.user.tenantId === RolePermissionService.PORZIO_TENANT_ID) {
          // configure roles and permissions
          RolePermissionService.CONFIGURE(rolePermissionsResult);

          // THEN fetch the common lookups
          combineLatest(LookupService.fetchCommonLookupsOBS(_context)).subscribe(
            (lookupsResult) => {
              oSubject.next(true);
            },
            (lookupsError) => {
              ToastService.showError("Error occured while fetching the Lookups. you may need to relogin");
              oSubject.next(false);
            }
          );

        } else {
          ToastService.showError("Error occured while fetching the permissions. you may need to relogin");
          oSubject.next(false);
        }
      },
      (rolePermissionsError) => {
        ToastService.showError("Error occured while fetching the permissions. you may need to relogin");
        oSubject.next(false);
      }
    );


    // return the subject as observable
    return oSubject.asObservable();

  }

  static getUserModelFromStorage = () => {
    const _userinfo = JSON.parse(localStorage.getItem(SessionService.storageKey)).authUserObj;

    return new userModel(

      _userinfo.userId, _userinfo.firstName, _userinfo.lastName, _userinfo.userName,
      _userinfo.currencyId, _userinfo.defaultLanguageId, _userinfo.isPorzioUser, _userinfo.userTypeId, _userinfo.userType,

      _userinfo.tenantId, _userinfo.tenantName, _userinfo.uid, _userinfo.workspaceID, _userinfo.oktaGroupID,

      _userinfo.isActive, _userinfo.roleId, _userinfo.roleName,

      _userinfo.sessionToken, _userinfo.sessionStart, _userinfo.warnSessionTimeout, _userinfo.logoutTimeout, _userinfo.refreshTokenTime,

      _userinfo.tenantList

    );
  }

  static clearAll = (_context) => {
    UserActivityService.untrackUserActivity(_context);
    localStorage.removeItem(SessionService.storageKey);
    _context.setViaLogin({ username: "elmo" });
  }

  static getTenantUserInfoAsync = async (_userId, _tenantId) => {
    const tenantUserInfoByIdResult = await fetch(`${process.env.REACT_APP_TENANT_HTTP}/User/GetTenantUserInfoById?userId=${_userId}&tenantId=${_tenantId}`);
    return await tenantUserInfoByIdResult.json();
  }

  static getTenantUserInfoOBS = (_userId, _tenantId) => {
    return ApiService.getOBS(API_ENDPOINT.TENANT, `/User/GetTenantUserInfoById?userId=${_userId}&tenantId=${_tenantId}`);
  }

  static getTokenByIdOBS = (_userId, _tenantId) => {
    return ApiService.getOBS(API_ENDPOINT.IDENTITY, `/User/GetTokenById/${_tenantId}/${_userId}`);
  }

  //---
}