import React from "react";
import { combineLatest } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, DialogTitle, DialogContent, Typography, AppBar, Toolbar, IconButton, TextField, Grid, FormGroup } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { AuthContext } from "../../../../shared/store/authProvider";
import { ResultStatus, CrudAction, API_ENDPOINT, } from "../../../../shared/types/enums";
import { SubscriptionArray } from "../../../../shared/services/dataService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import DialogErrorFragmentComponent from "../../../../shared/components/page/dialogErrorFragmentComponent";
import ApiService from "../../../../shared/services/apiService";

class VendorDetailsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  // vendor name validations
  lastValidatedVendorName = null;
  lastValidatedVendorResult = false;

  constructor(props) {
    super(props);
    // init state
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      fetchResult: ResultStatus.NOT_LOADED
    };
  }

  componentWillUnmount() { this.oSubscriptions.cancelAll(); }
  componentDidMount() {

    this.setState({ fetchResult: ResultStatus.LOADING });

    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.oSubscriptions.add(
      combineLatest([
        LookupService.getAffiliationsAsOBS(this.context),
        LookupService.getFormattedCountriesAsOBS(this.context, null),
        LookupService.getLanguagesAsOBS(this.context),
        LookupService.getFormattedCurrenciesAsOBS(this.context, null),
        LookupService.getTimezonesAsOBS(this.context)
      ]).subscribe(([_affiliationList, _countryList, _languageList, _currencyList, _timeZoneList]) => {
        this.setState(
          {
            affiliationList: _affiliationList,
            countryList: _countryList,
            languageList: _languageList,
            currencyList: _currencyList,
            timeZoneList: _timeZoneList,
          },
          () => {
            // change the state after all the above are assigned
            this.setState({ fetchResult: ResultStatus.LOADED });
          }
        );
      }
      )
    );
  }

  handleSubmit = async (_formikProps) => {
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {
          // 0) clear the CACHE,so next time if requried this new object will also be fetched
          LookupService.clearVENDOR_NAMES();

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2) extract the formik values into an object
          var mappedObj = {
            tenantID: this.context.user.tenantId,
            vendorName: _formikProps.values.vendorName,
            vendorDescription: _formikProps.values.vendorDescription,
            address1: _formikProps.values.address1,
            address2: _formikProps.values.address2,
            address3: _formikProps.values.address3,
            address4: _formikProps.values.address4,
            city: _formikProps.values.city,
            province: _formikProps.values.province,
            postalCode: _formikProps.values.postalCode,
            countryID: _formikProps.values.countryID,
            languageID: _formikProps.values.languageID,
            currencyID: _formikProps.values.currencyID,
            timeZoneID: _formikProps.values.timeZoneID,
            affiliateID: _formikProps.values.affiliateID,
            active: _formikProps.values.active,
          };

          // 3) determine the action and assign the appropriate props
          var actionVerb = "";
          if (this.state.isEditing) {
            // UPDATE
            actionVerb = "PUT";
            mappedObj.vendorId = this.props.modalAgNode.vendorId;
            mappedObj.updatedBy = this.context.user.userId;
          } else {
            // CREATE
            actionVerb = "POST";
            mappedObj.vendorId = 0;
            mappedObj.createdBy = this.context.user.userId;
          }

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, `/Vendor/${this.context.user.tenantId}`, JSON.stringify(mappedObj)).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ isReadOnly: true, fetchResult: ResultStatus.SUCCESS });
                this.props.refreshVendorList(true);
                this.props.onClose(false);
              } else {
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving vendor details", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          if (erroObj.inner) {
            erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
          }
        });
    }
  }
  getInitialValues() {
    return {
      vendorName: this.props.modalAgNode.vendorName,
      vendorDescription: this.props.modalAgNode.vendorDescription,
      address1: this.props.modalAgNode.address1,
      address2: this.props.modalAgNode.address2,
      address3: this.props.modalAgNode.address3,
      address4: this.props.modalAgNode.address4,
      city: this.props.modalAgNode.city,
      province: this.props.modalAgNode.province,
      postalCode: this.props.modalAgNode.postalCode,
      countryID: this.props.modalAgNode.countryID,
      languageID: this.props.modalAgNode.languageID,
      currencyID: this.props.modalAgNode.currencyID,
      timeZoneID: this.props.modalAgNode.timeZoneID,
      affiliateID: this.props.modalAgNode.affiliateID,
      active: this.props.inputAction === CrudAction.UPDATE ? this.props.modalAgNode.active : true,
    };
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      vendorDescription: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      address1: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      city: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      province: Yup.string().required("Required").min(2, "Must be at least 2 characters long").max(256, "Must be 256 characters or less"),
      postalCode: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less"),
      vendorName: Yup.string().required("Required").min(3, "Must be at least 3 characters long").max(256, "Must be 256 characters or less")
        .test("checkDuplicateVendorName", "Vendor name already exists", async (_formikValue) => {
          if (_formikValue) {
            if (this.lastValidatedVendorName === _formikValue) {
              return this.lastValidatedVendorResult;
            } else {
              this.lastValidatedVendorName = _formikValue;
              if (this.state.isEditing && _formikValue === this.props.modalAgNode.vendorName) {
                this.lastValidatedVendorResult = true;
                return this.lastValidatedVendorResult; // vendor name edited but okay"
              } else {
                // new vendor name (or) vendorName updated
                try {
                  const result = await fetch(`${process.env.REACT_APP_TENANT_HTTP}/Vendor/VendorExists/${this.context.user.tenantId}/${_formikValue}`);
                  const isExists = await result.json();
                  console.log("is vendor name exists: ", isExists);
                  this.lastValidatedVendorResult = !isExists;
                  return this.lastValidatedVendorResult;
                } catch {
                  this.setState({ fetchResult: ResultStatus.ERROR });
                  return false;
                }
              }
            }
          } else { return true; }
        }),
      affiliateID: Yup.number().required("Required").typeError("Must be a number"),
      countryID: Yup.number().required("Required").typeError("Must be a number"),
      languageID: Yup.number().required("Required").typeError("Must be a number"),
      currencyID: Yup.number().required("Required").typeError("Must be a number"),
      timeZoneID: Yup.number().required("Required").typeError("Must be a number"),
    });
    return this.validationSchema;
  }
    getLanguageList(list) {
    let english = list.find(x => x.id === 1);
    let newList = list.filter(x => x.id !== 1);
    newList.unshift(english);
    return newList;
  }

  render() {
    const { classes } = this.props;
    switch (this.state.fetchResult) {
      case ResultStatus.NOT_LOADED:
      case ResultStatus.LOADING: return (<PageLoadingComponent small classes={classes} label="Loading Vendor Details" />);
      case ResultStatus.SAVING: return (<PageLoadingComponent small classes={classes} label="Saving Vendor Details" />);
      case ResultStatus.LOADED:
      case ResultStatus.SUCCESS:
        return (
          <>
            <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }}>
              {(fProps) => (
                <form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* Dialog Title */}
                    <DialogTitle disableTypography id="dialogTitle">
                      <AppBar position="static">
                        <Toolbar variant="dense">
                          <Typography variant="h6" className={classes.root}>Vendor Details</Typography>
                          {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })}
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    {/* Dialog Content */}
                    <DialogContent>
                      <Box style={{ paddingLeft: 16, paddingRight: 32, paddingTop: 16, paddingBottom: 32, minWidth: "80vh", maxWidth: "80vh", }}>
                        {this.state.fetchResult === ResultStatus.SUCCESS ? (<h1>Your Vendor has been updated!!</h1>) : null}
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "vendorName", "Vendor Name")} fullWidth /> </Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "vendorDescription", "Vendor Description")} fullWidth />{" "}</Grid>
                          <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "affiliateID", "Affiliate Company*", this.state.affiliationList, "companyAffiliateId", "affiliateName", "100%")}</Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address1", "Address 1")} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address2", "Address 2", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address3", "Address 3", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "address4", "Address 4", false)} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "city", "City")} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "province", "Province/State")} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "postalCode", "Postal Code")} fullWidth /></Grid>
                          <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "countryID", "Country Name*", this.state.countryList, "id", "value", "98%")}</Grid>
                          <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "languageID", "Language*", this.getLanguageList(this.state.languageList), "id", "value", "98%")}</Grid>
                          <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "currencyID", "Currency*", this.state.currencyList, "id", "value", "98%")}</Grid>
                          <Grid item xs={12} sm={6}> {LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "timeZoneID", "Time Zone*", this.state.timeZoneList, "id", "value", "98%")}</Grid>
                          <Grid item xs={12} sm={6}> <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>{LayoutService.getSwitch(this.state.isReadOnly, classes, fProps, "active", "Active?")}</FormGroup></Grid>
                        </Grid>
                      </Box>
                    </DialogContent>
                  </MuiPickersUtilsProvider>
                </form>
              )}
            </Formik>
          </>
        );
      case ResultStatus.ERROR:
      default:
        return (
          <DialogErrorFragmentComponent title="Error" description="Error in Vendor details"
            classes={classes} onClose={() => { this.props.onClose(false); }} onRetry={() => { console.log("Retry Clicked"); }} />
        );
    }
  }
}

/** HOC */
export default LayoutService.getHocComponenet(VendorDetailsComponent);