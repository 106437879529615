import React, { Component } from "react";
import { combineLatest, of } from "rxjs";
import {
  withStyles,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormGroup,
  Box,
  Divider, DialogTitle, AppBar, Toolbar, DialogContent, Tooltip,IconButton
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../../shared/store/authProvider";
import {
  ResultStatus,
  CrudAction,
  FieldTypes,
  API_ENDPOINT,
  ENTITY_FIELD_TYPE
} from "../../../../shared/types/enums";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { MatClassService } from "../../../../shared/services/theme/matClassService";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import LayoutService from "../../../../shared/services/layoutService";
import ViewFieldService from "./viewFieldService";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import AgGridRadioButtonCellRendererComponent from "../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import ApiService from "../../../../shared/services/apiService";
import ToastService from "../../../../shared/services/toastService";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import MatSpecService from "../../../../shared/services/theme/matSpecService";


class ViewFieldComponent extends Component {
  static contextType = AuthContext;
  apiSubscriptions = new SubscriptionArray();
  oSubscriptions = new SubscriptionArray();

  STORE = ViewFieldService;
  CrudAction = CrudAction;
  constructor(props) {
    super(props);
    this.state = {
      isReadOnly: this.props.inputAction === CrudAction.UPDATE ? true : false,
      isEditing: this.props.inputAction === CrudAction.UPDATE ? true : false,
      enableClearDefault: false,
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      entityData: [],
      entityId:
        this.props.inputAction === CrudAction.UPDATE
          ? this.props.modalAgNode.entityId
          : 2,
      field_Type_Id:
        this.props.inputAction === this.CrudAction.CREATE
          ? 1
          : this.props.modalAgNode.field_Type_Id,
      hasAgGrid: false,

      agGridFieldUtils: new AgGridUtil("lovKey", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        isActiveCellRenderer: AgGridCheckboxCellRendererComponent,
        isPrimaryCellRenderer: AgGridRadioButtonCellRendererComponent,
      }),
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (_ignoreCache = false) => {
    this.apiSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    /**
     * rxjs <combineLatest> operator takes multiple obs as argument and emits their result in the same order
     * this operator is very handy as we don't have to independently wait for each result
     */
    this.apiSubscriptions.add(
      combineLatest([
        (this.props.inputAction === CrudAction.CREATE ? of(null) : ViewFieldService.fetchFieldValueAsObs(this.context, this.props.modalAgNode.fieldId, this.props.isPorzio)),
        ViewFieldService.fetchEntityAsOBS(this.context.user.tenantId),
      ]).subscribe(
        // success
        ([_data, _entityData]) => {
          this.setState(
            {
              data: _data,
              entityData: _entityData,
            },
            // change the state after all the above are assigned
            () => {
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        },
        // onError
        (error) => {
          console.log("Error:", error);
          this.setState({ fetchResult: ResultStatus.ERROR });
        }
      )
    );
  };

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    const optionsCustom = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        pageNumber: 1,
        pageSize: 5000,
        entityId: 0,
        searchText: "",
        isCustom: true,
        companyId: 0
      })
    };
    const optionsPorzio = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        pageNumber: 1,
        pageSize: 1000,
        entityId: 0,
        searchText: "",
        isCustom: false,
        companyId: 0
      })
    }
    this.validationSchema = Yup.object().shape({
      fieldName: Yup.string().min(3, "Must be atleast 3 characters long").max(256, "Must be 256 characters or less").required("Required"),
      systemAlias: Yup.string().required("Required")
        .test("checkDuplicateSystemAlias", "System Alias already exists", async (_formikValue) => {
          if (_formikValue) {
            try {
              const resultPorzio = await fetch(`${process.env.REACT_APP_TENANT_HTTP}/EntityField/FetchPagedFields?tenantId=${this.context.user.tenantId}`, optionsPorzio);
              const result = await fetch(`${process.env.REACT_APP_TENANT_HTTP}/EntityField/FetchPagedFields?tenantId=${this.context.user.tenantId}`, optionsCustom);
              const isExists = await result.json();
              const isExistsPorzio = await resultPorzio.json();
              if (this.props.inputAction==this.CrudAction.CREATE)
                return !(isExistsPorzio.filter((x) => x.fielD_ALIASNAME.toLowerCase() === _formikValue.toLowerCase()).length > 0 || isExists.filter((x) => x.fielD_ALIASNAME.toLowerCase() === _formikValue.toLowerCase()).length > 0);
              else  
                return true;
            } catch {
              this.setState({ fetchResult: ResultStatus.ERROR });
              return false;
            }
          } else { return true; }
        }),
    });
    return this.validationSchema;
  }

  removeDuplicateAndEmptyKeys = (_updatedRows) => {
    _updatedRows = _updatedRows.filter((oRow) => oRow.lovKey !== "" || oRow.lovKey !== null || oRow.lovKey !== undefined);

    _updatedRows = _updatedRows.filter((oRow, index, self) =>
      index === self.findIndex((t) => (
        String(t.lovKey).toLowerCase() === String(oRow.lovKey).toLowerCase()
      ))
    );
    return _updatedRows;
  }

  handleSubmit = async (_formikProps) => {

    if (this.state.data && !this.state.data.isEditableLov) {
      ToastService.showError("Field is not editable");
      return;
    }

    // check for duplicates
    if (this.state.hasAgGrid) {
      let lovKeys = [];
      let duplicateRowNodes = [];
      let emptyRowNodes = [];
      this.state.agGridFieldUtils.gridApi.forEachNode((rowNode, rowIndex) => {
        const _value = (rowNode.data["lovKey"] + "").trim().toLowerCase();
        if (DataService.isStringNullOrEmpty(_value) || _value === 'undefined') {
          emptyRowNodes.push(rowNode);
        } else {
          if (lovKeys.includes(_value)) {
            duplicateRowNodes.push(rowNode);
          }
          else { lovKeys.push(_value); }
        }
      });

      if (emptyRowNodes.length > 0) {
        ToastService.showError("Please Remove the empty keys.");
        this.state.agGridFieldUtils.gridApi.flashCells({ rowNodes: emptyRowNodes, columns: ["lovKey"] });
        return;
      }

      if (duplicateRowNodes.length > 0) {
        ToastService.showError("Please Remove the duplicate keys.");
        this.state.agGridFieldUtils.gridApi.flashCells({ rowNodes: duplicateRowNodes, columns: ["lovKey"] });
        return;
      }
    }


    if (_formikProps.values.systemAlias === "PORZIOGSTIDENTIFIERTYPE") {
      ToastService.showWarning("PORZIOGSTIDENTIFIERTYPE is not editable");
      return;
    }

    let updatedRows = [];
    let showDupMessage = false;
    if (this.state.hasAgGrid) {
      updatedRows = this.state.agGridFieldUtils.getUpdatedRowData();
      const preRemoveLen = updatedRows.length;
      updatedRows = this.removeDuplicateAndEmptyKeys(updatedRows);
      showDupMessage = (preRemoveLen !== updatedRows.length);
      let i = 1;
      updatedRows.map((x) => {
        x.isActive = DataService.parseBoolean(x.isActive);
        x.isLicensed = DataService.parseBoolean(x.isLicensed);
        x.isDefault = DataService.parseBoolean(x.isDefault);
        x.lovKeyId =
          x.lovKeyId !== undefined
            ? "" + x.lovKeyId
            : this.props.inputAction === CrudAction.CREATE
              ? 0 + "Key" + i
              : this.state.data.fieldId + "Key" + i;
        x.order = false;
        x.ordinal = i;
        x.lovId = x.lovId !== undefined ? x.lovId : 0;
        i++;
        return x;
      })
    }

    let date = new Date();
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          let lookupLst = this.STORE.getLookUpListValue(_formikProps, this.state.field_Type_Id);
          let lovDictionary = {};

          if (this.state.hasAgGrid) {
            updatedRows.forEach((x) => {
              lovDictionary[x.lovKeyId] = x.lovKey;
            })
          }

          // 2) extract the formik values into an object
          let fieldValue = {
            fieldId:
              this.props.inputAction === CrudAction.CREATE
                ? 0
                : this.props.modalAgNode.fieldId,
            entityId:
              this.props.inputAction === CrudAction.CREATE
                ? this.state.entityId
                : this.props.modalAgNode.entityId,
            tenantId: this.context.user.tenantId,
            entityName:
              this.props.inputAction === CrudAction.CREATE
                ? this.state.entityData[this.state.entityId].entityname
                : this.props.modalAgNode.entityName,
            field_Name: _formikProps.values.fieldName,
            field_Description: _formikProps.values.description,
            iS_PLSREQUIRED: _formikProps.values.switchPLSRequired,
            isCustomField:
              this.props.inputAction === CrudAction.CREATE
                ? true
                : this.props.modalAgNode.isCustomField,
            fielD_ALIASNAME: _formikProps.values.systemAlias,
            field_Type_Id:
              this.props.inputAction === CrudAction.CREATE
                ? this.state.field_Type_Id
                : this.props.modalAgNode.field_Type_Id,
            active: _formikProps.values.switchActive,
            toolTip: _formikProps.values.toolTip,
            lookUpList: lookupLst,
            createdBy: this.context.user.userId,
            createdDate:
              this.props.inputAction === CrudAction.CREATE ? DataService.formatDate(date) : null,
            updatedBy: this.context.user.userId,
            updatedDate: DataService.formatDate(date),
            mask:
              this.props.inputAction === CrudAction.CREATE
                ? false
                : this.state.data.mask,
            encryption:
              this.props.inputAction === CrudAction.CREATE
                ? false
                : this.state.data.encryption,
            isEditableLov:
              this.props.inputAction === CrudAction.CREATE
                ? true
                : this.state.data.isEditableLov,
            entityFieldLOVs: updatedRows
          };

          // 3) determine the action and assign the appropriate props
          let actionVerb = "POST";
          let targetUrl = `/EntityField/SaveFieldValue?tenantId=${this.context.user.tenantId}`;

          // 4) save to Api and subscribe for the result
          ApiService.setOBS(
            actionVerb,
            API_ENDPOINT.TENANT,
            targetUrl,
            JSON.stringify({ "fieldValue": fieldValue, "lovDictionary": lovDictionary })
          ).subscribe(
            (successResult) => {
              if (successResult === 0 || successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshList(true);
                this.props.onClose(false);
                if (showDupMessage) {
                  ToastService.showWarning("Duplicate or empty keys were found and removed");
                }
              } else {
                console.error("Error: falsey successResult while saving Product", successResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
              }
            },
            (errorResult) => {
              console.error("Error while saving Product", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
            }
          );
        })
        .catch((erroObj) => {
          erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }

  handleAliasValueChange(event, formikProps) {
    const input = event.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    formikProps.setFieldValue('systemAlias', input.value.toUpperCase().replace(/\s/g, ''), true);
    formikProps.setFieldTouched('systemAlias', true, false);
    input.setSelectionRange(start, end);
  } 

  onDownloadClick = () => {
    let api = this.gridApi;
    const params = this.getParams();
    api.exportDataAsExcel(params);
}

getParams() {
  const colIds = this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== "" && c.colDef.headerName !== "Edit").map(c => c.colId);
    return {
        
        columnKeys: colIds,
        fileName: this.getFileName(),
        processCellCallback: this.onProcessCellCallback
    };
}


getFileName() {
  return `Lovs- ${(this.props.modalAgNode.entityName)} - ${(this.props.modalAgNode.fielD_ALIASNAME)}  ${new Date().toDateString()}`;
}


 onProcessCellCallback = (params) => {
 switch (params.column.colId) {
    case 'isDefault': return params.node.data['isDefault'] ? 'Yes' : 'No';
    case 'isActive': return params.node.data['isActive'] ? 'Yes' : 'No';
    case 'lovKey' : return params.node.data['lovKey'];
    case 'isLicensed' :  return params.node.data['isLicensed'] ? 'Yes' : 'No';
 }
}

  render() {
    const { classes } = this.props;
    if ((this.props.isPorzio && RolePermissionService.PORZIO_FIELD_DETAIL.cannotView) ||
      (!this.props.isPorzio && RolePermissionService.FIELD_DETAIL.cannotView)) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.onClose(); });
    } else {
      this.state.agGridFieldUtils.setReadOnlyMode(this.state.isReadOnly);
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent small classes={classes} label="Loading View Field" />);
        case ResultStatus.SAVING:
          return (<PageLoadingComponent small classes={classes} label="Saving View Field" />);
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <Formik
              initialValues={this.STORE.getInitialValues(this.props, this.state)}
              validationSchema={this.getValidationSchema()}
              validationSchemaOptions={{ showMultipleFieldErrors: true }}>
              {(fProps) => (
                <form>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DialogTitle disableTypography id="dialogTitle">
                      <AppBar position="static">
                        <Toolbar>
                          <Typography variant="h6" className={classes.root}>
                            {this.props.inputAction === CrudAction.VIEW_DISABLED ? "View Field" : (this.props.inputAction === CrudAction.CREATE ? "Add Field" : "Edit Field")}
                          </Typography>
                          {
                            (this.props.isPorzio && this.state.field_Type_Id !== ENTITY_FIELD_TYPE.LOV) ? (
                              <IconButton color="secondary" onClick={() => { this.props.onClose(false); }} >{MatIconService.CANCEL}</IconButton>
                            ) : (
                              LayoutService.getControlledReadOnlyActions(this,
                                this.props.isPorzio ? RolePermissionService.PORZIO_FIELD_DETAIL.canEdit : RolePermissionService.FIELD_DETAIL.canEdit,
                                !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })
                            )}
                        </Toolbar>
                      </AppBar>
                    </DialogTitle>
                    <DialogContent>
                      <Box style={{ padding: 16, minWidth: "80vh", maxWidth: "80vh" }}>
                        <Grid container spacing={1} justify="center" alignItems="center" className={classes.root} >
                          <Grid item xs={12} sm={6}>
                            <FormControl style={{ minWidth: "90%" }} className={classes.dialogControl}>
                              <InputLabel id="entity-label">Entity</InputLabel>
                              {this.state.entityData.length > 0 ? (
                                <Select fullWidth labelId="entity-label" id="entity-id" value={this.state.entityId}
                                  onChange={(event) => this.setState({ entityId: event.target.value })}
                                  disabled={this.props.inputAction === CrudAction.CREATE ? false : true}>
                                  {this.state.entityData.map((el) => (<MenuItem value={el.entityid}>{el.entityname}</MenuItem>))}
                                </Select>
                              ) : null}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly || this.props.isPorzio, classes, fProps, "fieldName", "Field Name")} style={{ minWidth: "90%" }} /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly || this.props.isPorzio || this.props.inputAction === CrudAction.UPDATE, classes, fProps, "systemAlias", "System Alias")} style={{ minWidth: "90%" }} pattern="[^\s]+" onChange={(event) => this.handleAliasValueChange(event, fProps)} /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly || this.props.isPorzio, classes, fProps, "toolTip", "Tool Tip", false)} style={{ minWidth: "90%" }} /></Grid>
                          <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly || this.props.isPorzio, classes, fProps, "description", "Description", false)} style={{ minWidth: "90%" }} /></Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly || this.props.isPorzio, classes, fProps, "switchPLSRequired", "PLS Required")}
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormControl style={{ minWidth: "90%" }} className={classes.dialogControl}>
                              <InputLabel id="field-type-label">Field Type</InputLabel>
                              <Select fullWidth labelId="entity-label" id="entity-id" value={this.state.field_Type_Id}
                                onChange={(event) => this.setState({ field_Type_Id: event.target.value })}
                                disabled={this.props.inputAction === CrudAction.CREATE ? false : true}>
                                {Object.keys(FieldTypes).sort().map((key, index) => (<MenuItem value={FieldTypes[key]}>{key}</MenuItem>))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px", minWidth: "90%" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly || this.props.isPorzio, classes, fProps, "switchActive", "Active?")}
                            </FormGroup>
                          </Grid>

                        </Grid>

                        {this.state.field_Type_Id === 1 && (this.props.inputAction === CrudAction.CREATE || this.props.inputAction === CrudAction.UPDATE) ? (
                          <Grid container spacing={1} justify="center" alignItems="center" className={classes.root}>
                            <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "numericDecimalPlaces", "Decimal Places", false)} style={{ minWidth: "80%" }} /></Grid>
                            <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "numericMin", "Min", false)} style={{ minWidth: "80%" }} /></Grid>
                            <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "numericMax", "Max", false)} style={{ minWidth: "80%" }} /></Grid>
                            <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "numericDefault", "Default", false)} style={{ minWidth: "80%" }} /></Grid>
                          </Grid>
                        ) : null}
                        {this.state.field_Type_Id === 2 && (this.props.inputAction === CrudAction.CREATE || this.props.inputAction === CrudAction.UPDATE) ? (
                          <>
                            <Grid item xs={12} sm={12}>
                              <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                                {LayoutService.getSwitch(this.state.isReadOnly || this.props.isPorzio, classes, fProps, "switchTextSearchable", "Searchable")}
                              </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "textDefault", "Default", false)} style={{ minWidth: "80%" }} /></Grid>
                          </>
                        ) : null}
                        {this.state.field_Type_Id === 3 && (this.props.inputAction === CrudAction.CREATE || this.props.inputAction === CrudAction.UPDATE) ? (
                          <Grid item xs={12} sm={12}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "dateTimeDefault", "Default", false)} format="yyyy-MM-dd" style={{ minWidth: "47%" }} /></Grid>
                        ) : null}
                        {this.state.field_Type_Id === 4 && (this.props.inputAction === CrudAction.CREATE || this.props.inputAction === CrudAction.UPDATE) ? (
                          <Grid item xs={12} sm={12}>
                            <FormGroup style={{ paddingLeft: "4px", paddingTop: "8px" }}>
                              {LayoutService.getSwitch(this.state.isReadOnly || this.props.isPorzio, classes, fProps, "switchBooleanDefault", "Default")}
                            </FormGroup>
                          </Grid>
                        ) : null}
                        {this.state.field_Type_Id === 5 &&
                          (this.props.inputAction === CrudAction.CREATE || this.props.inputAction === CrudAction.UPDATE) ? (
                          <React.Fragment key={"addressFragment"}>
                            <Box key={"complianceBox"} style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 0, paddingBottom: 8, backgroundColor: MatThemeService.getReverseAlternatingBG(3), }}>
                              <div id="MainRoleGrid">
                               
                                <Grid container direction="row" justify="flex-end"  position = "left">
                                  <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8 }}></Typography>
                                  
                                  {LayoutService.getIconButton(this.state.isReadOnly, MatIconService.ADD_CIRCLE_OUTLINE, "Add New Entity Field LOV",
                                    () => {
                                      if (this.state.agGridFieldUtils.isNotEditing()) {
                                        this.state.agGridFieldUtils.addNewRow({ isDefault: "", isActive: "", isLicensed: "", lovKey: "", });
                                      }
                                    }
                                  )}
                                </Grid>
                                <Grid className={classes.flexNoShrink} container justify="flex-end">
                                <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="left" arrow>
                                    <IconButton onClick={this.onDownloadClick} color="secondary" >
                                        {MatIconService.DOWNLOAD}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            


                                <div style={{ height: `324px`, width: `100%` }} {...LayoutService.getAgGridTheme()} >
                                  <AgGridReact
                                    rowData={this.state.data ? this.state.data.entityFieldLOVs : []}
                                    columnDefs={ViewFieldService.getColumnDefs(this, this.state.isReadOnly, this.state.agGridFieldUtils)}
                                    frameworkComponents={this.state.agGridFieldUtils.frameworkComponents}
                                    suppressClickEdit={true}
                                    gridOptions={{
                                      context: { componentParent: this },
                                      suppressContextMenu: true,
                                      rowHeight: 32,
                                      defaultColDef: { sortable: true },
                                      ...this.state.agGridFieldUtils.bindInlineEditEvents(),
                                      ...AgGridUtil.truncateOnMaxCharLengthExceeds({ 'lovKey': (this.props.modalAgNode || {}).fieldlength }),
                                    }}
                                    onGridReady={(params) => {
                                      this.gridApi = params.api;
                                      this.gridColumnApi = params.columnApi;
                                      this.state.agGridFieldUtils.setGridParams(params, true);
                                      this.state.agGridFieldUtils.setReadOnlyMode(this.state.isReadOnly);
                                      this.setState({ hasAgGrid: true });
                                     
                                    }}
                                  />
                                </div>
                              </div>
                            </Box>
                            <Divider />
                          </React.Fragment>
                        ) : null}
                        {this.state.field_Type_Id === 6 &&
                          (this.props.inputAction === CrudAction.CREATE ||
                            this.props.inputAction === CrudAction.UPDATE) ? (
                          <Grid item xs={12} sm={12}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "dateDefault", "Default", false)} format="yyyy-MM-dd" style={{ minWidth: "47%" }} /></Grid>
                        ) : null}
                        {this.state.field_Type_Id === 7 && (this.props.inputAction === CrudAction.CREATE || this.props.inputAction === CrudAction.UPDATE) ?
                          (
                            <Grid container spacing={1} justify="center" alignItems="center" className={classes.root} >
                              <Grid item xs={12} sm={6}> <TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "decimalDecimalPlaces", "Decimal Places", false)} style={{ minWidth: "80%" }} /></Grid>
                              <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "decimalMin", "Min", false)} style={{ minWidth: "80%" }} /></Grid>
                              <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "decimalMax", "Max", false)} style={{ minWidth: "80%" }} /></Grid>
                              <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "decimalDefault", "Default", false)} style={{ minWidth: "80%" }} /></Grid>
                            </Grid>
                          ) : null}
                      </Box>
                    </DialogContent>
                  </MuiPickersUtilsProvider>
                </form>
              )}
            </Formik>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent small label="Error Loading Field Details" classes={classes}
              onClose={() => { this.props.refreshList(true); this.props.onClose(false); }}
              onRetry={() => { this.fetchData() }}
            />
          );
      }
    }
  }
}

function parseXml(xmlStr) {
  console.log(new window.DOMParser().parseFromString(xmlStr, "text/xml"));
  return new window.DOMParser().parseFromString(xmlStr, "text/xml");
}

export default withStyles(MatClassService)(ViewFieldComponent);