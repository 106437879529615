import React from "react";
import { combineLatest, of } from "rxjs";
import { Formik } from "formik";
import * as Yup from "yup";
import { Dialog, DialogTitle, DialogContent, Divider, Grid, Box, Typography, AppBar, Toolbar, TextField, FormHelperText, Input, Chip, LinearProgress } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridCheckboxCellRendererComponent from "../../../../shared/components/elements/agGridCheckboxCellRendererComponent";
import AgGridRadioButtonCellRendererComponent from "../../../../shared/components/elements/agGridRadioButtonCellRendererComponent";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";

import { AuthContext } from "../../../../shared/store/authProvider";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import { ResultStatus, CrudAction, API_ENDPOINT, TaskLauncher, ENTITY_TYPE, } from "../../../../shared/types/enums";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import MatThemeService from "../../../../shared/services/theme/matThemeService";
import LayoutService from "../../../../shared/services/layoutService";
import LookupService from "../../../../shared/services/lookupService";
import ApiService from "../../../../shared/services/apiService";
import DownloadFileComponent from "../../../../shared/components/download/downloadFileComponent";
import ToastService from "../../../../shared/services/toastService";
import TaskDetailsService from "./taskDetailsService";
import ProfileDetailDialogComponent from "../../../profile-center/profile-detail/profileDetailDialogComponent";
import TransactionDetailDialogComponent from "../../../transaction-center/transaction-detail/transactionDetailDialogComponent";
import AgGridDatePickerCellRendererComponent from "../../../../shared/components/elements/agGridDatePickerCellRendererComponent";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";

class TaskDetailsComponent extends React.Component {
  static contextType = AuthContext;
  oSubscriptions = new SubscriptionArray();

  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      taskById: {},
      taskCommentsById: [],
      isReadOnly: this.props.inputAction === CrudAction.UPDATE, // default state
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      fetchResult: ResultStatus.NOT_LOADED,
      showProfileDetailDialog: false,

      agGridFieldUtils: new AgGridUtil("description", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        isActiveCellRenderer: AgGridCheckboxCellRendererComponent,
        isPrimaryCellRenderer: AgGridRadioButtonCellRendererComponent,
        dateCellRenderer: AgGridDatePickerCellRendererComponent,
      }),
    };
  }


  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    this.setState({ fetchResult: ResultStatus.LOADING });
    TaskDetailsService.clearAll();

    this.oSubscriptions.add(
      combineLatest([
        (this.props.inputAction === CrudAction.CREATE ? of(null) : TaskDetailsService.getTaskByIdAsOBS(this.context, this.props.updateData.taskId)),
        (this.props.inputAction === CrudAction.CREATE ? of(null) : TaskDetailsService.getTaskGetCommentsByIdAsOBS(this.context, this.props.updateData.taskId)),
        (this.props.inputAction === CrudAction.CREATE ? of(null) : TaskDetailsService.getAssignedToFullUserAsOBS(this.context, this.props.updateData.taskAssignedTo)),
        TaskDetailsService.getAllUsersAsOBS(this.context),
        TaskDetailsService.getTaskPrioritiesAsOBS(this.context, "task priority"),
      ]).subscribe(
        ([
          _taskById,
          _taskCommentsById,
          _assignedToFullUser,
          _allUsersList,
          _taskPriorities
        ]) => {
          _taskPriorities = DataService.getKeyValueCollection(_taskPriorities, "lovId", "lovKey");
          const _allUsersListSorted = _allUsersList;

          _allUsersListSorted.sort((a, b) => {
            const nameA = a.firstName.toUpperCase();
            const nameB = b.firstName.toUpperCase();
            return nameA.localeCompare(nameB);
          });
          
          let _sourceEntityType = 12;
          if (this.props.inputAction === CrudAction.UPDATE) {
            _sourceEntityType = _taskById.sourceEntityID; // get it from the task
          } else {
            if (this.props.taskLauncher === TaskLauncher.PROFILE_EXPANDED_VIEW || this.props.taskLauncher === TaskLauncher.PROFILE_DETAILS) {
              _sourceEntityType = ENTITY_TYPE.PROFILE;
            }
            else if (this.props.taskLauncher === TaskLauncher.TRANSACTION_EXPANDED_VIEW || this.props.taskLauncher === TaskLauncher.TRANSACTION_DETAILS) {
              _sourceEntityType = ENTITY_TYPE.TRANSACTION;
            }
          }

          this.setState(
            {
              taskById: _taskById,
              sourceEntityType: _sourceEntityType,
              // taskCommentsById: _taskCommentsById ?? [],
              taskCommentsById: _taskCommentsById ? _taskCommentsById.map(r => ({
                ...r,
                date: DataService.getDateStringForDisplay(r.date),
                user: _allUsersListSorted.find(u => u.id === r.userId)?.firstName + " " + _allUsersListSorted.find(u => u.id === r.userId)?.lastName,
              })) : [],
              assignedToFullUser: _assignedToFullUser,
              allUsersList: _allUsersListSorted.map(o => ({ ...o, fullName: o.firstName + " " + o.lastName })).filter(i => ["porzio@porzio.com", "porzio@porziols.com"].indexOf(i.loginName.toLowerCase()) === -1),
              taskPriorities: _taskPriorities
            },
            () => {
              // change the state after all the above are assigned
              this.setState({ fetchResult: ResultStatus.LOADED });
            }
          );
        }
      )
    );
  }

  getInitialValues() {
    if (this.props.inputAction === CrudAction.CREATE) {
      return {
        taskName: "",
        taskPriority: "",
        taskAssignedTo: "",
        startDate: null,
        dueDate: null,
        status: "",
        percentComplete: "",
        taskDescription: "",
        porzioGstIds: DataService.hasElements(this.props.porzioGstIds) ? this.props.porzioGstIds : [],
      };
    } else {
      return {
        taskName: this.state.taskById.taskName,
        taskPriority: this.state.taskById.taskPriorityId,
        taskAssignedTo: this.state.taskById.taskAssignedTo,
        startDate: DataService.getDateStringForDisplay(this.state.taskById.taskStartDate),
        dueDate: DataService.getDateStringForDisplay(this.state.taskById.taskDueDate),
        status: this.state.taskById.taskStatusId,// >= 207 ? this.state.taskById.taskStatusId : this.state.taskById.taskStatusId + 206,
        percentComplete: this.state.taskById.percentageComplete,
        taskDescription: this.state.taskById.taskDescription,
        porzioGstIds: DataService.stringToArray(this.state.taskById.sourceGSTID?.replace(" ", ""), ","), // DataService.stringToArray(`${this.state.taskById.sourceGSTID},${this.state.taskById.sourceGSTID}`, ","),
      }
    }
  }

  validationSchema = Yup.object().shape({});
  getValidationSchema() {
    this.validationSchema = Yup.object().shape({
      taskName: Yup.string().required("Required").min(3, "Must be atleast 3 characters long").max(256, "Must be 256 characters or less"),
      taskPriority: Yup.number().required("Required").typeError("Required"),
      taskAssignedTo: Yup.number().required("Required").typeError("Required"),
      startDate: Yup.date().nullable(true).required("Required"),
      dueDate: Yup.date()
        .when("startDate",
          (startDate, yup) => {
            if (startDate) {
              var d = startDate;
              d.setHours(0, 0, 0, 0);
              return yup.nullable(true).required("Required").min(d, "Due date cannot be before Start date")
            } else {
              return yup.nullable(true).required("Required")
            }
          }),
      status: Yup.number().required("Required").typeError("Required"),
      percentComplete: Yup.number().min(0, "Must be above 0").max(100, "Must be below 100").transform((_, val) => val === "" ? 0 : Number(val)),
      taskDescription: Yup.string(),
    });
    return this.validationSchema;
  }

  handleSubmit = async (_formikProps) => {
    console.log("_formikProps", _formikProps);
    if (!_formikProps.isSubmitting && _formikProps.isValid) {
      await this.validationSchema.validate(_formikProps.values, { abortEarly: false })
        .then((x) => {

          // 1) set the status
          this.setState({ fetchResult: ResultStatus.SAVING });

          // 2.1) extract the formik values into an object
          let updatedRows = this.state.agGridFieldUtils.getUpdatedRowData();
          console.log("updatedRows: ", updatedRows);

          const _stringifiedPorzioGstIds = DataService.arrayToString(_formikProps.values["porzioGstIds"], ",");
          const _taskAssignedTo = this.state.allUsersList.find(o => o.id === _formikProps.values.taskAssignedTo);

          // 2.2) extract the formik values into an object
          const formData = new FormData();
          for (let i = 0; i < updatedRows.length; i++) {
            formData.append(`taskComments[${i}][commentId]`, this.props.inputAction === CrudAction.CREATE ? 0 : updatedRows[i].commentId ?? 0);
            formData.append(`taskComments[${i}][description]`, updatedRows[i].description);
            formData.append(`taskComments[${i}][date]`, DataService.getDateFromDateTimeStamp(updatedRows[i].date));
            formData.append(`taskComments[${i}][eventType]`, updatedRows[i].eventType);
            formData.append(`taskComments[${i}][userId]`, updatedRows[i].userId ?? this.context.user.userId);
            formData.append(`taskComments[${i}][taskId]`, updatedRows[i].taskId ?? this.props.inputAction === CrudAction.CREATE ? 0 : this.props.updateData.taskId);
            formData.append(`taskComments[${i}][tenantId]`, updatedRows[i].tenantId ?? this.context.user.tenantId);
            formData.append(`taskComments[${i}][rowS_COUNT]`, updatedRows.length);
            formData.append(`taskComments[${i}][eventTypeId]`, updatedRows[i].eventTypeId ?? 0);
            formData.append(`taskComments[${i}][user]`, updatedRows[i].user);
            formData.append(`taskComments[${i}][wfTaskId]`, this.props.inputAction === CrudAction.CREATE ? 0 : this.props.updateData.taskId);
            formData.append(`taskComments[${i}][initiatorUserId]`, this.context.user.userId);
            formData.append(`taskComments[${i}][firstName]`, _taskAssignedTo?.firstName);
            formData.append(`taskComments[${i}][lastName]`, _taskAssignedTo?.lastName);
            formData.append(`taskComments[${i}][middleName]`, _taskAssignedTo?.middleName ?? "");
            formData.append(`taskComments[${i}][sourcePorzioGSTId]`, _stringifiedPorzioGstIds);
          }
          // let rowsToPost = updatedRows.forEach((u, i) => ({
          //   commentId: this.props.inputAction === CrudAction.CREATE ? 0 : this.props.updateData.commentId,
          //   description: u.description,
          //   date: new Date(),
          //   eventType: u.eventType,
          //   userId: this.context.user.userId,
          //   taskId: this.props.inputAction === CrudAction.CREATE ? 0 : this.props.updateData.taskId,
          //   tenantId: this.context.user.tenantId,
          //   rowS_COUNT: 0,
          //   eventTypeId: 0,
          //   user: u.user,
          //   wfTaskId: this.props.inputAction === CrudAction.CREATE ? 0 : this.props.updateData.taskId,
          //   initiatorUserId: this.context.user.userId,
          //   firstName: _taskAssignedTo?.firstName,
          //   lastName: _taskAssignedTo?.lastName,
          //   middleName: _taskAssignedTo?.middleName,
          //   sourcePorzioGSTId: _stringifiedPorzioGstIds
          // }));
          // console.log("rowsToPost: ", rowsToPost);

          //var mappedObj = {
          formData.append("TaskName", _formikProps.values.taskName);
          formData.append("taskPriorityId", _formikProps.values.taskPriority);
          formData.append("taskPriority", "High");
          formData.append("taskAssignedTo", _formikProps.values.taskAssignedTo);
          formData.append("taskStartDate", _formikProps.values.startDate.toISOString().substring(0, 10));
          formData.append("taskStatusId", _formikProps.values.status);
          formData.append("taskStatus", "High");
          formData.append("taskDueDate", _formikProps.values.dueDate.toISOString().substring(0, 10));
          formData.append("percentComplete", _formikProps.values.percentComplete ? Number(_formikProps.values.percentComplete) : 0);
          formData.append("taskDescription", _formikProps.values.taskDescription);
          formData.append("isTaskAssignedToMe", 0);
          formData.append("tenantId", this.context.user.tenantId);
          formData.append("isActive", true)
          formData.append("createdBy", this.context.user.userId);
          formData.append("createdDate", new Date().toISOString().substring(0, 10));
          formData.append("updatedBy", this.context.user.userId);
          formData.append("updatedDate", new Date().toISOString().substring(0, 10));
          formData.append("sourceEntityID", this.state.sourceEntityType)
          formData.append("sourcePorzioGstid", _stringifiedPorzioGstIds);
          formData.append("porzioGSTTaskId", "DEVTKB4DD40073");
          formData.append("firstName", this.state.allUsersList.find(o => o.id === _formikProps.values.taskAssignedTo).firstName);
          // formData.append("middleInitial", "");
          formData.append("lastName", this.state.allUsersList.find(o => o.id === _formikProps.values.taskAssignedTo).lastName);
          formData.append("userName", this.state.allUsersList.find(o => o.id === _formikProps.values.taskAssignedTo).loginName);
          formData.append("rows_Count", 0);
          formData.append("taskAttachments", JSON.stringify([
            {
              attachmentId: 284,
              attachmentFileName: "Netherland-2018-Submitted.xls",
              blobName: "1140"
            }
          ]));
          formData.append("assignedTo", [
            _formikProps.values.taskAssignedTo
          ]);
          if (this.state.selectedFileName) {
            formData.append("attachmentFileName", this.state.selectedFileName);
          } else {
            formData.append("attachmentFileName", "");
          }
          if (this.state.selectedFile) {
            formData.append("efile", this.state.selectedFile);
          } else {
            formData.append("efile", "");
          }
          formData.append("blobName", "");
          formData.append("fileName", "");

          // formData.append("taskComments", JSON.stringify([
          //   {
          //     commentId: 0,
          //     description: "string",
          //     date: "2020-09-25T11:22:48.445Z",
          //     eventType: "New Comment",
          //     userId: this.context.user.userId,
          //     taskId: 0,
          //     tenantId: this.context.user.tenantId,
          //     rowS_COUNT: 0,
          //     eventTypeId: 0,
          //     user: "Mustafa Alure",
          //     wfTaskId: 782,
          //     initiatorUserId: this.context.user.userId,
          //     firstName: "string",
          //     lastName: "string",
          //     middleName: "M",
          //     sourcePorzioGSTId: "DEVTK21682647B"
          //   }
          // ]));
          //formData.append("taskComments", JSON.stringify(rowsToPost));

          formData.append("userId", _formikProps.values.taskAssignedTo);
          formData.append("isApproved", true);
          formData.append("records", "");
          formData.append("isWorkFlowTask", true);
          formData.append("sourceGSTID", "DEVWFAFF475756");
          formData.append("customfielddata", JSON.stringify({}));
          formData.append("customfieldvalues", JSON.stringify({}));
          formData.append("customfields", "string");

          // 3) determine the action and assign the appropriate props
          const actionVerb = "POST";
          let targetUrl = "";
          if (this.props.inputAction === CrudAction.CREATE) {
            targetUrl = `/Task/SaveAndEditTask?tenantId=${this.context.user.tenantId}&userId=${this.context.user.userId}&uId=${this.context.user.uid}`;
            formData.append("taskId", 0);
          } else {
            // UPDATE
            targetUrl = `/Task/SaveAndEditTask?tenantId=${this.context.user.tenantId}&userId=${this.context.user.userId}&uId=${this.context.user.uid}`;
            formData.append("taskId", this.props.updateData.taskId);
          }

          const headers = {
            Authorization: `Bearer ${this.context.authToken}`
          };
          // 4) save to Api and subscribe for the result
          ApiService.setOBS(actionVerb, API_ENDPOINT.TENANT, targetUrl, formData, headers).subscribe(
            (successResult) => {
              if (successResult) {
                this.setState({ fetchResult: ResultStatus.SUCCESS });
                this.props.refreshTaskList(true, this.context);
                this.props.onClose(false);
                ToastService.showSuccess("Task Saved");
              } else {
                console.error("Error: falsey successResult while saving Task", successResult);
                this.setState({ fetchResult: ResultStatus.ERROR });
                ToastService.showError("An Error occured while saving the Task");
              }
            },
            (errorResult) => {
              console.error("Error while saving Task", errorResult);
              this.setState({ fetchResult: ResultStatus.ERROR });
              ToastService.showError("An Error occured while saving the Task");
            }
          );
        })
        .catch((erroObj) => {
          console.log(erroObj);
          erroObj.inner && erroObj.inner.forEach(err => { _formikProps.setFieldError(err.path, err.message); });
        });
    }
  }

  showProfileDialog = (_prId) => {
    this.setState({
      selectedProfileId: _prId, // skip the first 5 chars to get the prid
      showProfileDetailDialog: true,
      showTransactionDetailDialog: false,
      selectedTransactionObj: null
    });
  }

  showTransactionDialog = (_trId) => {
    this.setState({
      isLoading: true,
      showProfileDetailDialog: false, selectedProfileId: null,
      showTransactionDetailDialog: false, selectedTransactionObj: null
    });

    LookupService.getTransactionTabDetailsAsOBS(this.context.user.tenantId, _trId)
      .subscribe(
        (successObj) => {
          this.setState({
            isLoading: false,
            selectedTransactionObj: successObj,
            showTransactionDetailDialog: true,
          });
        },
        (errorObj) => {
          ToastService.showError("An Error Occrred while fetching the Transaction.");
          this.setState({ isLoading: false });
        }
      );
  }

  /**Render */
  render() {
    const { classes } = this.props;
    this.state.agGridFieldUtils.setReadOnlyMode(this.state.isReadOnly);

    if (RolePermissionService.TASK_DETAIL.cannotView && this.props.inputAction === CrudAction.UPDATE) {
      return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }); // this is required to prevent Url navigation
    } else {
      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return <PageLoadingComponent small classes={classes} label="Loading Task Details" />;
        case ResultStatus.SAVING:
          return <PageLoadingComponent small classes={classes} label="Saving Task Details" />;
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <Dialog
              open={this.props.showTaskDialog || false}
              scroll={true ? "paper" : "body"}
              maxWidth="md"
            >
              <Formik initialValues={this.getInitialValues()} validationSchema={this.getValidationSchema()} validationSchemaOptions={{ showMultipleFieldErrors: true }} >
                {(fProps) => (
                  <form>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      {/* Dialog Title */}
                      <DialogTitle disableTypography id="dialogTitle">
                        <AppBar position="static">
                          <Toolbar variant="dense">
                            <Typography variant="h6" className={classes.root}>Task Detail</Typography>
                            {/* <IconButton color="inherit" onClick={() => { this.handleSubmit(fProps); }}> {MatIconService.OK} </IconButton>
                        <IconButton color="secondary" onClick={() => { this.props.onClose(false); }}> {MatIconService.CANCEL} </IconButton> */}
                            {LayoutService.getReadOnlyActions(this, !this.state.isEditing, () => { this.props.onClose(false) }, () => { this.handleSubmit(fProps) })}
                          </Toolbar>
                        </AppBar>
                      </DialogTitle>
                      {/* Dialog Content */}
                      <DialogContent>
                        <Box style={{ padding: 16, minWidth: "80vh", maxWidth: "150vh" }}>
                          {this.state.isLoading ? <LinearProgress color="secondary" /> : null}
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "taskName", "Task Name")} fullWidth /></Grid>
                            <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "taskPriority", "Task Priority *", this.state.taskPriorities, "id", "text", "90%", true)}</Grid>
                            <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "taskAssignedTo", "Task Assigned To *", this.state.allUsersList, "id", "fullName", "90%", true)}</Grid>

                            <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "startDate", "Start Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={6}><DatePicker {...LayoutService.getDateProps(this.state.isReadOnly, classes, fProps, "dueDate", "Due Date", true)} format="yyyy-MM-dd" style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={6}>{LayoutService.getSelectControl(this.state.isReadOnly, classes, fProps, "status", "Status *", LookupService.STATUSES, "id", "name", "90%", true)}</Grid>
                            <Grid item xs={12} sm={6}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "percentComplete", "% Complete", false)} style={{ minWidth: "90%" }} /></Grid>
                            <Grid item xs={12} sm={12}><TextField {...LayoutService.getInputProps(this.state.isReadOnly, classes, fProps, "taskDescription", "Task Description", false)} fullWidth multiline rows={3} /></Grid>

                            {this.state.sourceEntityType === ENTITY_TYPE.PROFILE || this.state.sourceEntityType === ENTITY_TYPE.TRANSACTION ?
                              <Grid item xs={12} sm={12}>
                                <Typography variant="h6" className={classes.sectionHeader} style={{ marginLeft: 8, marginTop: 0, marginBottom: 8 }}>{this.state.sourceEntityType === ENTITY_TYPE.PROFILE ? "Profiles" : "Transactions"}</Typography>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                  {fProps.values["porzioGstIds"].map((x, index) => {
                                    return <Chip key={`keyPorzioGstId${index}`} style={{ marginLeft: 8 }} variant="outlined" color="secondary" label={x}
                                      icon={this.state.sourceEntityType === ENTITY_TYPE.PROFILE ? MatIconService.PROFILE_OUTLINED : MatIconService.TRANSACTION_OUTLINED}
                                      onClick={() => {
                                        const entityId = Number(x.substring(5)); // skip the first 5 chars to get the prid/trid
                                        if (this.state.sourceEntityType === ENTITY_TYPE.TRANSACTION) { // when implemented simply remove this if part ank keep the else part
                                          this.showTransactionDialog(entityId);
                                        } else {
                                          this.showProfileDialog(entityId);
                                        }
                                      }} />
                                  })}
                                </Grid>
                              </Grid>
                              : null}

                            {this.props.inputAction === CrudAction.CREATE ?
                              <Grid item xs={12} sm={6}>
                                <FormHelperText>Attachment</FormHelperText>
                                <Input type="file" onChange={(event) => this.setState({ selectedFile: event.target.files[0], selectedFileName: event.target.files[0].name })} fullWidth />
                              </Grid>
                              : null}
                            {this.state.taskById?.blobName ?
                              <Grid item xs={12} sm={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <FormHelperText>Attached File</FormHelperText>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <DownloadFileComponent
                                      fileName={this.props.inputAction === CrudAction.UPDATE ? this.state.taskById.attachmentFileName : ""}
                                      apiServer={process.env.REACT_APP_TENANT_HTTP}
                                      porzioApiPath={"/Task/DownloadDocument"}
                                      blobName={this.state.taskById.blobName}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              : null}

                            <Grid item xs={12}>
                              <Box
                                key={"complianceBox"}
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  paddingTop: 8,
                                  paddingBottom: 16,
                                  backgroundColor: MatThemeService.getReverseAlternatingBG(
                                    3
                                  ),
                                }}
                              >
                                <div id="MainRoleGrid">
                                  <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="h6"
                                      className={classes.sectionHeader}
                                      style={{ margin: 8 }}
                                    >
                                      Comments
                                    </Typography>
                                    {LayoutService.getIconButton(this.state.isReadOnly ? this.state.isReadOnly : RolePermissionService.TASK_COMMENTS.cannotEdit, MatIconService.ADD_CIRCLE_OUTLINE, "Add New Comment",
                                      () => {
                                        if (this.state.agGridFieldUtils.isNotEditing()) {
                                          this.state.agGridFieldUtils.addNewRow({
                                            date: DataService.getDateFromDateTimeStamp(new Date()),
                                            eventType: "New Comment", user: this.state.allUsersList.find(o => o.id === this.context.user.userId)?.firstName + " " + this.state.allUsersList.find(o => o.id === this.context.user.userId)?.lastName,
                                          });
                                        }
                                      }
                                    )}
                                  </Grid>
                                  <div
                                    style={{ height: `160px`, width: `100%` }}
                                    {...LayoutService.getAgGridTheme()}
                                  >
                                    {RolePermissionService.TASK_COMMENTS.cannotView ? RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack() }, false)
                                      :
                                      <AgGridReact
                                        rowData={this.state.taskCommentsById}
                                        columnDefs={TaskDetailsService.getColumnDefs(this, this.state.isReadOnly, this.state.agGridFieldUtils)}
                                        frameworkComponents={
                                          this.state.agGridFieldUtils
                                            .frameworkComponents
                                        }
                                        suppressClickEdit={true}
                                        gridOptions={{
                                          context: { componentParent: this },
                                          suppressContextMenu: true,
                                          rowHeight: 32,
                                          ...this.state.agGridFieldUtils.bindInlineEditEvents(),
                                        }}
                                        onGridReady={(params) => {
                                          this.state.agGridFieldUtils.setGridParams(params, true);
                                          this.state.agGridFieldUtils.setReadOnlyMode(this.state.isReadOnly);
                                          this.setState({ hasAgGrid: true });
                                        }}
                                      />}
                                  </div>
                                </div>
                              </Box>
                              <Divider />
                            </Grid>

                          </Grid>
                        </Box>
                      </DialogContent>

                      {/* Show Profile-Detail Dialog */}
                      {this.state.showProfileDetailDialog ?
                        <ProfileDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={{ "prid": this.state.selectedProfileId }}
                          open={this.state.showProfileDetailDialog || false}
                          onClose={() => { this.setState({ showProfileDetailDialog: false, showTransactionDetailDialog: false }); }}
                        />
                        : <></>}

                      {/* Show Transaction-Detail Dialog */}
                      {this.state.showTransactionDetailDialog ?
                        <TransactionDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.selectedTransactionObj}
                          open={this.state.showTransactionDetailDialog || false}
                          onClose={() => { this.setState({ showProfileDetailDialog: false, showTransactionDetailDialog: false }); }}
                        />
                        : <></>}
                    </MuiPickersUtilsProvider>
                  </form>
                )}
              </Formik>
            </Dialog>
          );

        case ResultStatus.ERROR:
        default:
          return (
            <PageErrorComponent small label="Error Loading Task Details" classes={classes} onRetry={() => {
              //this.SERVICE.fetchData(this, true);
            }}
            />
          );
      }
    }
  }
}
/** HOC */
export default LayoutService.getHocComponenet(TaskDetailsComponent);